/* tslint:disable */
/* eslint-disable */
/**
 * Frontend API
 * Frontend API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration.js';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common.js';
import type { RequestArgs } from './base.js';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base.js';

/**
 * 
 * @export
 * @interface APIConstant
 */
export interface APIConstant {
    [key: string]: any;

    /**
     * 
     * @type {boolean}
     * @memberof APIConstant
     */
    'isUsed'?: boolean;
    /**
     * 
     * @type {APIConstantExpectedType}
     * @memberof APIConstant
     */
    'expectedType'?: APIConstantExpectedType;
    /**
     * 
     * @type {Array<string>}
     * @memberof APIConstant
     */
    'expectedProps'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof APIConstant
     */
    'key': string;
    /**
     * From https://github.com/sindresorhus/type-fest/ Matches any valid JSON value.
     * @type {unknown}
     * @memberof APIConstant
     */
    'json': unknown;
    /**
     * 
     * @type {number}
     * @memberof APIConstant
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof APIConstant
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof APIConstant
     */
    'type': APIConstantTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof APIConstant
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof APIConstant
     */
    'version': number;
    /**
     * 
     * @type {string}
     * @memberof APIConstant
     */
    'createdBy': string;
    /**
     * 
     * @type {string}
     * @memberof APIConstant
     */
    'disabledAt': string;
    /**
     * 
     * @type {string}
     * @memberof APIConstant
     */
    'disabledBy': string;
}

export const APIConstantTypeEnum = {
    Boolean: 'BOOLEAN',
    Number: 'NUMBER',
    Numberarray: 'NUMBERARRAY',
    Object: 'OBJECT',
    Objectarray: 'OBJECTARRAY',
    String: 'STRING',
    Stringarray: 'STRINGARRAY'
} as const;

export type APIConstantTypeEnum = typeof APIConstantTypeEnum[keyof typeof APIConstantTypeEnum];

/**
 * @type APIConstantExpectedType
 * @export
 */
export type APIConstantExpectedType = null | string;

/**
 * 
 * @export
 * @interface APInvoiceListRequest
 */
export interface APInvoiceListRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof APInvoiceListRequest
     */
    'InvoiceNumber'?: string;
}
/**
 * 
 * @export
 * @interface APInvoiceListResponse
 */
export interface APInvoiceListResponse extends Array<APInvoiceListResponseInner> {
}
/**
 * 
 * @export
 * @interface APInvoiceListResponseInner
 */
export interface APInvoiceListResponseInner {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof APInvoiceListResponseInner
     */
    'APDocumentID': number;
    /**
     * 
     * @type {string}
     * @memberof APInvoiceListResponseInner
     */
    'Supplier': string;
    /**
     * 
     * @type {number}
     * @memberof APInvoiceListResponseInner
     */
    'SupplierID': number;
    /**
     * 
     * @type {string}
     * @memberof APInvoiceListResponseInner
     */
    'InvoiceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof APInvoiceListResponseInner
     */
    'InvoiceDate': string;
    /**
     * 
     * @type {string}
     * @memberof APInvoiceListResponseInner
     */
    'DueDate': string;
    /**
     * 
     * @type {number}
     * @memberof APInvoiceListResponseInner
     */
    'AmountDue': number;
    /**
     * 
     * @type {string}
     * @memberof APInvoiceListResponseInner
     */
    'DocumentMemo': string;
}
/**
 * 
 * @export
 * @interface APInvoiceListResponseItem
 */
export interface APInvoiceListResponseItem {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof APInvoiceListResponseItem
     */
    'APDocumentID': number;
    /**
     * 
     * @type {string}
     * @memberof APInvoiceListResponseItem
     */
    'Supplier': string;
    /**
     * 
     * @type {number}
     * @memberof APInvoiceListResponseItem
     */
    'SupplierID': number;
    /**
     * 
     * @type {string}
     * @memberof APInvoiceListResponseItem
     */
    'InvoiceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof APInvoiceListResponseItem
     */
    'InvoiceDate': string;
    /**
     * 
     * @type {string}
     * @memberof APInvoiceListResponseItem
     */
    'DueDate': string;
    /**
     * 
     * @type {number}
     * @memberof APInvoiceListResponseItem
     */
    'AmountDue': number;
    /**
     * 
     * @type {string}
     * @memberof APInvoiceListResponseItem
     */
    'DocumentMemo': string;
}
/**
 * 
 * @export
 * @interface AccessLevel
 */
export interface AccessLevel {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof AccessLevel
     */
    'level': number;
    /**
     * 
     * @type {string}
     * @memberof AccessLevel
     */
    'accesslevel': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AccessRole = {
    Constant: 'CONSTANT',
    Erp: 'ERP',
    Monitor: 'MONITOR',
    MrpPick: 'MRP_PICK'
} as const;

export type AccessRole = typeof AccessRole[keyof typeof AccessRole];


/**
 * 
 * @export
 * @enum {number}
 */

export const AccessRoleLevel = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type AccessRoleLevel = typeof AccessRoleLevel[keyof typeof AccessRoleLevel];


/**
 * 
 * @export
 * @interface AdditionalCharge
 */
export interface AdditionalCharge {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof AdditionalCharge
     */
    'ChargeType': string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalCharge
     */
    'Amount': string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalCharge
     */
    'TaxCode': string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalCharge
     */
    'DistributionMethod': string;
}
/**
 * 
 * @export
 * @interface AdditionalDataValue
 */
export interface AdditionalDataValue {
    [key: string]: any;

    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof AdditionalDataValue
     */
    'ID': ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {string}
     * @memberof AdditionalDataValue
     */
    'Name': string;
    /**
     * 
     * @type {string}
     * @memberof AdditionalDataValue
     */
    'Value': string;
}
/**
 * Addresses
 * @export
 * @interface Addresses
 */
export interface Addresses {
    [key: string]: any;

    /**
     * 
     * @type {AddressesPost}
     * @memberof Addresses
     */
    'Post'?: AddressesPost;
    /**
     * 
     * @type {AddressesDelivery}
     * @memberof Addresses
     */
    'Delivery'?: AddressesDelivery;
    /**
     * 
     * @type {AddressesVisit}
     * @memberof Addresses
     */
    'Visit'?: AddressesVisit;
    /**
     * 
     * @type {AddressesInvoice}
     * @memberof Addresses
     */
    'Invoice'?: AddressesInvoice;
}
/**
 * Delivery
 * @export
 * @interface AddressesDelivery
 */
export interface AddressesDelivery {
    [key: string]: any;

    /**
     * s:string
     * @type {string}
     * @memberof AddressesDelivery
     */
    'Street'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof AddressesDelivery
     */
    'State'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof AddressesDelivery
     */
    'PostalCode'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof AddressesDelivery
     */
    'PostalArea'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof AddressesDelivery
     */
    'Name'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof AddressesDelivery
     */
    'City'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof AddressesDelivery
     */
    'Country'?: string;
}
/**
 * Invoice
 * @export
 * @interface AddressesInvoice
 */
export interface AddressesInvoice {
    [key: string]: any;

    /**
     * s:string
     * @type {string}
     * @memberof AddressesInvoice
     */
    'Street'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof AddressesInvoice
     */
    'State'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof AddressesInvoice
     */
    'PostalCode'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof AddressesInvoice
     */
    'PostalArea'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof AddressesInvoice
     */
    'Name'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof AddressesInvoice
     */
    'City'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof AddressesInvoice
     */
    'Country'?: string;
}
/**
 * Post
 * @export
 * @interface AddressesPost
 */
export interface AddressesPost {
    [key: string]: any;

    /**
     * s:string
     * @type {string}
     * @memberof AddressesPost
     */
    'Street'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof AddressesPost
     */
    'State'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof AddressesPost
     */
    'PostalCode'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof AddressesPost
     */
    'PostalArea'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof AddressesPost
     */
    'Name'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof AddressesPost
     */
    'City'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof AddressesPost
     */
    'Country'?: string;
}
/**
 * Visit
 * @export
 * @interface AddressesVisit
 */
export interface AddressesVisit {
    [key: string]: any;

    /**
     * s:string
     * @type {string}
     * @memberof AddressesVisit
     */
    'Street'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof AddressesVisit
     */
    'State'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof AddressesVisit
     */
    'PostalCode'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof AddressesVisit
     */
    'PostalArea'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof AddressesVisit
     */
    'Name'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof AddressesVisit
     */
    'City'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof AddressesVisit
     */
    'Country'?: string;
}
/**
 * 
 * @export
 * @interface AlertIncidentCommand
 */
export interface AlertIncidentCommand {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof AlertIncidentCommand
     */
    'version': string;
    /**
     * 
     * @type {AlertIncidentCommandIncident}
     * @memberof AlertIncidentCommand
     */
    'incident': AlertIncidentCommandIncident;
}
/**
 * 
 * @export
 * @interface AlertIncidentCommandIncident
 */
export interface AlertIncidentCommandIncident {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof AlertIncidentCommandIncident
     */
    'incident_id': string;
    /**
     * 
     * @type {string}
     * @memberof AlertIncidentCommandIncident
     */
    'scoping_project_id': string;
    /**
     * 
     * @type {number}
     * @memberof AlertIncidentCommandIncident
     */
    'scoping_project_number': number;
    /**
     * 
     * @type {string}
     * @memberof AlertIncidentCommandIncident
     */
    'url': string;
    /**
     * 
     * @type {number}
     * @memberof AlertIncidentCommandIncident
     */
    'started_at': number;
    /**
     * 
     * @type {number}
     * @memberof AlertIncidentCommandIncident
     */
    'ended_at': number;
    /**
     * 
     * @type {string}
     * @memberof AlertIncidentCommandIncident
     */
    'state': string;
    /**
     * 
     * @type {string}
     * @memberof AlertIncidentCommandIncident
     */
    'summary': string;
    /**
     * 
     * @type {string}
     * @memberof AlertIncidentCommandIncident
     */
    'severity'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertIncidentCommandIncident
     */
    'apigee_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertIncidentCommandIncident
     */
    'observed_value'?: string;
    /**
     * 
     * @type {AlertIncidentCommandIncidentResource}
     * @memberof AlertIncidentCommandIncident
     */
    'resource': AlertIncidentCommandIncidentResource;
    /**
     * 
     * @type {string}
     * @memberof AlertIncidentCommandIncident
     */
    'resource_type_display_name': string;
    /**
     * 
     * @type {string}
     * @memberof AlertIncidentCommandIncident
     */
    'resource_id': string;
    /**
     * 
     * @type {string}
     * @memberof AlertIncidentCommandIncident
     */
    'resource_display_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof AlertIncidentCommandIncident
     */
    'resource_name': string;
    /**
     * 
     * @type {AlertIncidentCommandIncidentMetric}
     * @memberof AlertIncidentCommandIncident
     */
    'metric': AlertIncidentCommandIncidentMetric;
    /**
     * 
     * @type {AlertIncidentCommandIncidentMetadata}
     * @memberof AlertIncidentCommandIncident
     */
    'metadata': AlertIncidentCommandIncidentMetadata;
    /**
     * 
     * @type {string}
     * @memberof AlertIncidentCommandIncident
     */
    'policy_name': string;
    /**
     * 
     * @type {object}
     * @memberof AlertIncidentCommandIncident
     */
    'policy_user_labels'?: object;
    /**
     * 
     * @type {AlertIncidentCommandIncidentDocumentation}
     * @memberof AlertIncidentCommandIncident
     */
    'documentation'?: AlertIncidentCommandIncidentDocumentation;
    /**
     * 
     * @type {AlertIncidentCommandIncidentCondition}
     * @memberof AlertIncidentCommandIncident
     */
    'condition': AlertIncidentCommandIncidentCondition;
    /**
     * 
     * @type {string}
     * @memberof AlertIncidentCommandIncident
     */
    'condition_name': string;
    /**
     * 
     * @type {string}
     * @memberof AlertIncidentCommandIncident
     */
    'threshold_value': string;
}
/**
 * 
 * @export
 * @interface AlertIncidentCommandIncidentCondition
 */
export interface AlertIncidentCommandIncidentCondition {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof AlertIncidentCommandIncidentCondition
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AlertIncidentCommandIncidentCondition
     */
    'displayName': string;
    /**
     * 
     * @type {AlertIncidentCommandIncidentConditionConditionMatchedLog}
     * @memberof AlertIncidentCommandIncidentCondition
     */
    'conditionMatchedLog'?: AlertIncidentCommandIncidentConditionConditionMatchedLog;
    /**
     * 
     * @type {AlertIncidentCommandIncidentConditionConditionThreshold}
     * @memberof AlertIncidentCommandIncidentCondition
     */
    'conditionThreshold'?: AlertIncidentCommandIncidentConditionConditionThreshold;
}
/**
 * 
 * @export
 * @interface AlertIncidentCommandIncidentConditionConditionMatchedLog
 */
export interface AlertIncidentCommandIncidentConditionConditionMatchedLog {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof AlertIncidentCommandIncidentConditionConditionMatchedLog
     */
    'filter': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AlertIncidentCommandIncidentConditionConditionMatchedLog
     */
    'resourceContainers': Array<string>;
}
/**
 * 
 * @export
 * @interface AlertIncidentCommandIncidentConditionConditionThreshold
 */
export interface AlertIncidentCommandIncidentConditionConditionThreshold {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof AlertIncidentCommandIncidentConditionConditionThreshold
     */
    'filter': string;
    /**
     * 
     * @type {string}
     * @memberof AlertIncidentCommandIncidentConditionConditionThreshold
     */
    'comparison': string;
    /**
     * 
     * @type {number}
     * @memberof AlertIncidentCommandIncidentConditionConditionThreshold
     */
    'thresholdValue': number;
    /**
     * 
     * @type {string}
     * @memberof AlertIncidentCommandIncidentConditionConditionThreshold
     */
    'duration': string;
    /**
     * 
     * @type {AlertIncidentCommandIncidentConditionConditionThresholdTrigger}
     * @memberof AlertIncidentCommandIncidentConditionConditionThreshold
     */
    'trigger': AlertIncidentCommandIncidentConditionConditionThresholdTrigger;
}
/**
 * 
 * @export
 * @interface AlertIncidentCommandIncidentConditionConditionThresholdTrigger
 */
export interface AlertIncidentCommandIncidentConditionConditionThresholdTrigger {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof AlertIncidentCommandIncidentConditionConditionThresholdTrigger
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface AlertIncidentCommandIncidentDocumentation
 */
export interface AlertIncidentCommandIncidentDocumentation {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof AlertIncidentCommandIncidentDocumentation
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof AlertIncidentCommandIncidentDocumentation
     */
    'mime_type': string;
    /**
     * 
     * @type {string}
     * @memberof AlertIncidentCommandIncidentDocumentation
     */
    'subject': string;
}
/**
 * 
 * @export
 * @interface AlertIncidentCommandIncidentMetadata
 */
export interface AlertIncidentCommandIncidentMetadata {
    [key: string]: any;

    /**
     * 
     * @type {object}
     * @memberof AlertIncidentCommandIncidentMetadata
     */
    'system_labels': object;
    /**
     * 
     * @type {object}
     * @memberof AlertIncidentCommandIncidentMetadata
     */
    'user_labels': object;
}
/**
 * 
 * @export
 * @interface AlertIncidentCommandIncidentMetric
 */
export interface AlertIncidentCommandIncidentMetric {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof AlertIncidentCommandIncidentMetric
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof AlertIncidentCommandIncidentMetric
     */
    'displayName': string;
    /**
     * 
     * @type {object}
     * @memberof AlertIncidentCommandIncidentMetric
     */
    'labels': object;
}
/**
 * 
 * @export
 * @interface AlertIncidentCommandIncidentResource
 */
export interface AlertIncidentCommandIncidentResource {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof AlertIncidentCommandIncidentResource
     */
    'type': string;
    /**
     * 
     * @type {AlertIncidentCommandIncidentResourceLabels}
     * @memberof AlertIncidentCommandIncidentResource
     */
    'labels': AlertIncidentCommandIncidentResourceLabels;
}
/**
 * 
 * @export
 * @interface AlertIncidentCommandIncidentResourceLabels
 */
export interface AlertIncidentCommandIncidentResourceLabels {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof AlertIncidentCommandIncidentResourceLabels
     */
    'configuration_name': string;
    /**
     * 
     * @type {string}
     * @memberof AlertIncidentCommandIncidentResourceLabels
     */
    'location': string;
    /**
     * 
     * @type {string}
     * @memberof AlertIncidentCommandIncidentResourceLabels
     */
    'project_id': string;
    /**
     * 
     * @type {string}
     * @memberof AlertIncidentCommandIncidentResourceLabels
     */
    'revision_name': string;
    /**
     * 
     * @type {string}
     * @memberof AlertIncidentCommandIncidentResourceLabels
     */
    'service_name': string;
}
/**
 * 
 * @export
 * @interface AlertPoliciesResponse
 */
export interface AlertPoliciesResponse extends Array<AlertPoliciesResponseInner> {
}
/**
 * 
 * @export
 * @interface AlertPoliciesResponseInner
 */
export interface AlertPoliciesResponseInner {
    /**
     * 
     * @type {string}
     * @memberof AlertPoliciesResponseInner
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof AlertPoliciesResponseInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AlertPoliciesResponseInner
     */
    'documentation': string;
    /**
     * 
     * @type {boolean}
     * @memberof AlertPoliciesResponseInner
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof AlertPoliciesResponseInner
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPoliciesResponseInner
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPoliciesResponseInner
     */
    'modified': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPoliciesResponseInner
     */
    'validity': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPoliciesResponseInner
     */
    'combiner': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPoliciesResponseInner
     */
    'severity': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPoliciesResponseInner
     */
    'notificationRateLimit': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPoliciesResponseInner
     */
    'autoClose': string;
    /**
     * 
     * @type {AlertPoliciesResponseInnerConditions}
     * @memberof AlertPoliciesResponseInner
     */
    'conditions': AlertPoliciesResponseInnerConditions;
    /**
     * 
     * @type {AlertPoliciesResponseInnerConditions}
     * @memberof AlertPoliciesResponseInner
     */
    'notificationChannels': AlertPoliciesResponseInnerConditions;
}
/**
 * @type AlertPoliciesResponseInnerConditions
 * @export
 */
export type AlertPoliciesResponseInnerConditions = ConstantCreateInputJsonOneOf1 | object;

/**
 * 
 * @export
 * @interface AlertPolicyIncidentJsonFix
 */
export interface AlertPolicyIncidentJsonFix {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentJsonFix
     */
    'summary': string;
    /**
     * 
     * @type {number}
     * @memberof AlertPolicyIncidentJsonFix
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentJsonFix
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentJsonFix
     */
    'documentation': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentJsonFix
     */
    'status': AlertPolicyIncidentJsonFixStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentJsonFix
     */
    'severity': string;
    /**
     * 
     * @type {number}
     * @memberof AlertPolicyIncidentJsonFix
     */
    'alertPolicyId': number;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentJsonFix
     */
    'incidentId': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentJsonFix
     */
    'incidentUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentJsonFix
     */
    'resourceName': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentJsonFix
     */
    'serviceName': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentJsonFix
     */
    'revisionName': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentJsonFix
     */
    'conditionName': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentJsonFix
     */
    'logFilter': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentJsonFix
     */
    'notified': string;
    /**
     * 
     * @type {AlertPolicyJsonFixConditions}
     * @memberof AlertPolicyIncidentJsonFix
     */
    'logs': AlertPolicyJsonFixConditions;
}

export const AlertPolicyIncidentJsonFixStatusEnum = {
    Acknowledged: 'ACKNOWLEDGED',
    Muted: 'MUTED',
    Open: 'OPEN',
    Resolved: 'RESOLVED'
} as const;

export type AlertPolicyIncidentJsonFixStatusEnum = typeof AlertPolicyIncidentJsonFixStatusEnum[keyof typeof AlertPolicyIncidentJsonFixStatusEnum];

/**
 * 
 * @export
 * @interface AlertPolicyIncidentNullJsonFix
 */
export interface AlertPolicyIncidentNullJsonFix {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentNullJsonFix
     */
    'summary': string;
    /**
     * 
     * @type {number}
     * @memberof AlertPolicyIncidentNullJsonFix
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentNullJsonFix
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentNullJsonFix
     */
    'documentation': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentNullJsonFix
     */
    'status': AlertPolicyIncidentNullJsonFixStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentNullJsonFix
     */
    'severity': string;
    /**
     * 
     * @type {number}
     * @memberof AlertPolicyIncidentNullJsonFix
     */
    'alertPolicyId': number;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentNullJsonFix
     */
    'incidentId': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentNullJsonFix
     */
    'incidentUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentNullJsonFix
     */
    'resourceName': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentNullJsonFix
     */
    'serviceName': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentNullJsonFix
     */
    'revisionName': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentNullJsonFix
     */
    'conditionName': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentNullJsonFix
     */
    'logFilter': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentNullJsonFix
     */
    'notified': string;
    /**
     * 
     * @type {AlertPolicyJsonFixConditions}
     * @memberof AlertPolicyIncidentNullJsonFix
     */
    'logs': AlertPolicyJsonFixConditions;
}

export const AlertPolicyIncidentNullJsonFixStatusEnum = {
    Acknowledged: 'ACKNOWLEDGED',
    Muted: 'MUTED',
    Open: 'OPEN',
    Resolved: 'RESOLVED'
} as const;

export type AlertPolicyIncidentNullJsonFixStatusEnum = typeof AlertPolicyIncidentNullJsonFixStatusEnum[keyof typeof AlertPolicyIncidentNullJsonFixStatusEnum];

/**
 * 
 * @export
 * @interface AlertPolicyIncidentsResponse
 */
export interface AlertPolicyIncidentsResponse extends Array<AlertPolicyIncidentsResponseInner> {
}
/**
 * 
 * @export
 * @interface AlertPolicyIncidentsResponseInner
 */
export interface AlertPolicyIncidentsResponseInner {
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentsResponseInner
     */
    'summary': string;
    /**
     * 
     * @type {number}
     * @memberof AlertPolicyIncidentsResponseInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentsResponseInner
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentsResponseInner
     */
    'documentation': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentsResponseInner
     */
    'status': AlertPolicyIncidentsResponseInnerStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentsResponseInner
     */
    'severity': string;
    /**
     * 
     * @type {number}
     * @memberof AlertPolicyIncidentsResponseInner
     */
    'alertPolicyId': number;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentsResponseInner
     */
    'incidentId': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentsResponseInner
     */
    'incidentUrl': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentsResponseInner
     */
    'resourceName': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentsResponseInner
     */
    'serviceName': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentsResponseInner
     */
    'revisionName': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentsResponseInner
     */
    'conditionName': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentsResponseInner
     */
    'logFilter': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyIncidentsResponseInner
     */
    'notified': string;
    /**
     * 
     * @type {AlertPoliciesResponseInnerConditions}
     * @memberof AlertPolicyIncidentsResponseInner
     */
    'logs': AlertPoliciesResponseInnerConditions;
}

export const AlertPolicyIncidentsResponseInnerStatusEnum = {
    Acknowledged: 'ACKNOWLEDGED',
    Muted: 'MUTED',
    Open: 'OPEN',
    Resolved: 'RESOLVED'
} as const;

export type AlertPolicyIncidentsResponseInnerStatusEnum = typeof AlertPolicyIncidentsResponseInnerStatusEnum[keyof typeof AlertPolicyIncidentsResponseInnerStatusEnum];

/**
 * 
 * @export
 * @interface AlertPolicyJsonFix
 */
export interface AlertPolicyJsonFix {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof AlertPolicyJsonFix
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof AlertPolicyJsonFix
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyJsonFix
     */
    'documentation': string;
    /**
     * 
     * @type {boolean}
     * @memberof AlertPolicyJsonFix
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyJsonFix
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyJsonFix
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyJsonFix
     */
    'modified': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyJsonFix
     */
    'validity': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyJsonFix
     */
    'combiner': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyJsonFix
     */
    'severity': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyJsonFix
     */
    'notificationRateLimit': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyJsonFix
     */
    'autoClose': string;
    /**
     * 
     * @type {AlertPolicyJsonFixConditions}
     * @memberof AlertPolicyJsonFix
     */
    'conditions': AlertPolicyJsonFixConditions;
    /**
     * 
     * @type {AlertPolicyJsonFixConditions}
     * @memberof AlertPolicyJsonFix
     */
    'notificationChannels': AlertPolicyJsonFixConditions;
}
/**
 * @type AlertPolicyJsonFixConditions
 * @export
 */
export type AlertPolicyJsonFixConditions = ConstantCreateInputJsonOneOf1 | { [key: string]: any | undefined; };

/**
 * 
 * @export
 * @interface AlertPolicyNullJsonFix
 */
export interface AlertPolicyNullJsonFix {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof AlertPolicyNullJsonFix
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof AlertPolicyNullJsonFix
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyNullJsonFix
     */
    'documentation': string;
    /**
     * 
     * @type {boolean}
     * @memberof AlertPolicyNullJsonFix
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyNullJsonFix
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyNullJsonFix
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyNullJsonFix
     */
    'modified': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyNullJsonFix
     */
    'validity': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyNullJsonFix
     */
    'combiner': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyNullJsonFix
     */
    'severity': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyNullJsonFix
     */
    'notificationRateLimit': string;
    /**
     * 
     * @type {string}
     * @memberof AlertPolicyNullJsonFix
     */
    'autoClose': string;
    /**
     * 
     * @type {AlertPolicyJsonFixConditions}
     * @memberof AlertPolicyNullJsonFix
     */
    'conditions': AlertPolicyJsonFixConditions;
    /**
     * 
     * @type {AlertPolicyJsonFixConditions}
     * @memberof AlertPolicyNullJsonFix
     */
    'notificationChannels': AlertPolicyJsonFixConditions;
}
/**
 * 
 * @export
 * @interface AllProduct
 */
export interface AllProduct {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof AllProduct
     */
    'deleted': string;
    /**
     * 
     * @type {number}
     * @memberof AllProduct
     */
    'id': number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof AllProduct
     */
    'properties': Array<AnswerPropertiesInner>;
}
/**
 * 
 * @export
 * @interface Answer
 */
export interface Answer {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof Answer
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof Answer
     */
    'created': number;
    /**
     * 
     * @type {number}
     * @memberof Answer
     */
    'parent_id': number;
    /**
     * 
     * @type {number}
     * @memberof Answer
     */
    'item_id': number;
    /**
     * 
     * @type {number}
     * @memberof Answer
     */
    'checklist_id': number;
    /**
     * 
     * @type {number}
     * @memberof Answer
     */
    'seq_no': number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof Answer
     */
    'properties': Array<AnswerPropertiesInner>;
}
/**
 * 
 * @export
 * @interface AnswerPropertiesInner
 */
export interface AnswerPropertiesInner {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof AnswerPropertiesInner
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof AnswerPropertiesInner
     */
    'parent_id': number;
    /**
     * 
     * @type {number}
     * @memberof AnswerPropertiesInner
     */
    'type': number;
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof AnswerPropertiesInner
     */
    'value': ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {string}
     * @memberof AnswerPropertiesInner
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnswerPropertiesInner
     */
    'strvalue'?: string;
    /**
     * 
     * @type {number}
     * @memberof AnswerPropertiesInner
     */
    'numvalue'?: number;
}
/**
 * 
 * @export
 * @enum {number}
 */

export const AnswerPropertyType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7
} as const;

export type AnswerPropertyType = typeof AnswerPropertyType[keyof typeof AnswerPropertyType];


/**
 * 
 * @export
 * @interface ApiErpAcCalendarDeliveryDateChangePostRequest
 */
export interface ApiErpAcCalendarDeliveryDateChangePostRequest {
    /**
     * 
     * @type {any}
     * @memberof ApiErpAcCalendarDeliveryDateChangePostRequest
     */
    'cpproId'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiErpAcCalendarDeliveryDateChangePostRequest
     */
    'cpproInstallId'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiErpAcCalendarDeliveryDateChangePostRequest
     */
    'acErpId'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiErpAcCalendarDeliveryDateChangePostRequest
     */
    'deliveryDate'?: any;
    /**
     * 
     * @type {any}
     * @memberof ApiErpAcCalendarDeliveryDateChangePostRequest
     */
    'demo'?: any;
}
/**
 * APIException
 * @export
 * @interface ApiException
 */
export interface ApiException {
    [key: string]: any;

    /**
     * s:string
     * @type {string}
     * @memberof ApiException
     */
    'Type'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof ApiException
     */
    'Message'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof ApiException
     */
    'StackTrace'?: string;
}
/**
 * 
 * @export
 * @interface Attempt
 */
export interface Attempt {
    [key: string]: any;

    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof Attempt
     */
    'scheduleTime'?: InstallItemUsageAlternativeCreateInputCachedAt;
    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof Attempt
     */
    'dispatchTime'?: InstallItemUsageAlternativeCreateInputCachedAt;
    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof Attempt
     */
    'responseTime'?: InstallItemUsageAlternativeCreateInputCachedAt;
    /**
     * 
     * @type {AttemptResponseStatus}
     * @memberof Attempt
     */
    'responseStatus'?: AttemptResponseStatus;
}
/**
 * 
 * @export
 * @interface AttemptResponseStatus
 */
export interface AttemptResponseStatus {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof AttemptResponseStatus
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof AttemptResponseStatus
     */
    'message'?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof AttemptResponseStatus
     */
    'details'?: Array<any>;
}
/**
 * 
 * @export
 * @interface CRMCustomerLockErrorResponse
 */
export interface CRMCustomerLockErrorResponse {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CRMCustomerLockErrorResponse
     */
    'error': string;
    /**
     * 
     * @type {CRMCustomerLockErrorResponseLock}
     * @memberof CRMCustomerLockErrorResponse
     */
    'lock': CRMCustomerLockErrorResponseLock;
}
/**
 * 
 * @export
 * @interface CRMCustomerLockErrorResponseLock
 */
export interface CRMCustomerLockErrorResponseLock {
    /**
     * 
     * @type {number}
     * @memberof CRMCustomerLockErrorResponseLock
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof CRMCustomerLockErrorResponseLock
     */
    'customerId': number;
    /**
     * 
     * @type {string}
     * @memberof CRMCustomerLockErrorResponseLock
     */
    'locked': string;
    /**
     * 
     * @type {string}
     * @memberof CRMCustomerLockErrorResponseLock
     */
    'agent': string;
}
/**
 * 
 * @export
 * @interface CRMCustomerLockSuccessResponse
 */
export interface CRMCustomerLockSuccessResponse {
    [key: string]: any;

    /**
     * 
     * @type {boolean}
     * @memberof CRMCustomerLockSuccessResponse
     */
    'success': CRMCustomerLockSuccessResponseSuccessEnum;
    /**
     * 
     * @type {CRMCustomerLockSuccessResponseLock}
     * @memberof CRMCustomerLockSuccessResponse
     */
    'lock': CRMCustomerLockSuccessResponseLock;
}

export const CRMCustomerLockSuccessResponseSuccessEnum = {
    True: true
} as const;

export type CRMCustomerLockSuccessResponseSuccessEnum = typeof CRMCustomerLockSuccessResponseSuccessEnum[keyof typeof CRMCustomerLockSuccessResponseSuccessEnum];

/**
 * 
 * @export
 * @interface CRMCustomerLockSuccessResponseLock
 */
export interface CRMCustomerLockSuccessResponseLock {
    /**
     * 
     * @type {number}
     * @memberof CRMCustomerLockSuccessResponseLock
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof CRMCustomerLockSuccessResponseLock
     */
    'customerId': number;
    /**
     * 
     * @type {string}
     * @memberof CRMCustomerLockSuccessResponseLock
     */
    'locked': string;
    /**
     * 
     * @type {string}
     * @memberof CRMCustomerLockSuccessResponseLock
     */
    'agent': string;
}
/**
 * 
 * @export
 * @interface CalendarNotificationChannelPostRequest
 */
export interface CalendarNotificationChannelPostRequest {
    /**
     * 
     * @type {any}
     * @memberof CalendarNotificationChannelPostRequest
     */
    'calendarId'?: any;
}
/**
 * 
 * @export
 * @interface CalendarNotificationHandleUpdatedEventPostRequest
 */
export interface CalendarNotificationHandleUpdatedEventPostRequest {
    /**
     * 
     * @type {any}
     * @memberof CalendarNotificationHandleUpdatedEventPostRequest
     */
    'calendarId'?: any;
    /**
     * 
     * @type {any}
     * @memberof CalendarNotificationHandleUpdatedEventPostRequest
     */
    'eventId'?: any;
}
/**
 * 
 * @export
 * @interface Checklist
 */
export interface Checklist {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof Checklist
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof Checklist
     */
    'parent_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Checklist
     */
    '_orderid'?: number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof Checklist
     */
    'properties': Array<AnswerPropertiesInner>;
    /**
     * 
     * @type {Array<ChecklistQuestionInner>}
     * @memberof Checklist
     */
    'question': Array<ChecklistQuestionInner>;
}
/**
 * 
 * @export
 * @enum {number}
 */

export const ChecklistPropertyType = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type ChecklistPropertyType = typeof ChecklistPropertyType[keyof typeof ChecklistPropertyType];


/**
 * 
 * @export
 * @interface ChecklistQuestionInner
 */
export interface ChecklistQuestionInner {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof ChecklistQuestionInner
     */
    '_seqno': number;
    /**
     * 
     * @type {string}
     * @memberof ChecklistQuestionInner
     */
    '_checklist'?: string;
    /**
     * 
     * @type {number}
     * @memberof ChecklistQuestionInner
     */
    '_checklistid'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChecklistQuestionInner
     */
    '_itemid'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChecklistQuestionInner
     */
    '_product'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChecklistQuestionInner
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ChecklistQuestionInner
     */
    'parent_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ChecklistQuestionInner
     */
    '_order_id'?: number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof ChecklistQuestionInner
     */
    'properties': Array<AnswerPropertiesInner>;
    /**
     * 
     * @type {Array<QuestionAnswerInner>}
     * @memberof ChecklistQuestionInner
     */
    'answer'?: Array<QuestionAnswerInner>;
    /**
     * 
     * @type {number}
     * @memberof ChecklistQuestionInner
     */
    '_internal_use'?: number;
}
/**
 * 
 * @export
 * @interface CloudTask
 */
export interface CloudTask {
    [key: string]: any;

    /**
     * From https://github.com/sindresorhus/type-fest/ Matches any valid JSON value.
     * @type {unknown}
     * @memberof CloudTask
     */
    'body': unknown;
    /**
     * 
     * @type {number}
     * @memberof CloudTask
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof CloudTask
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof CloudTask
     */
    'hash': string;
    /**
     * 
     * @type {string}
     * @memberof CloudTask
     */
    'taskName': string;
    /**
     * 
     * @type {string}
     * @memberof CloudTask
     */
    'queueName': string;
    /**
     * 
     * @type {string}
     * @memberof CloudTask
     */
    'originalETA': string;
    /**
     * 
     * @type {string}
     * @memberof CloudTask
     */
    'nextETA': string;
    /**
     * 
     * @type {string}
     * @memberof CloudTask
     */
    'method': string;
    /**
     * 
     * @type {number}
     * @memberof CloudTask
     */
    'retryCount': number;
    /**
     * 
     * @type {number}
     * @memberof CloudTask
     */
    'executionCount': number;
    /**
     * 
     * @type {string}
     * @memberof CloudTask
     */
    'previousResponse': string;
    /**
     * 
     * @type {string}
     * @memberof CloudTask
     */
    'retryReason': string;
    /**
     * 
     * @type {boolean}
     * @memberof CloudTask
     */
    'isCreatedInQueue': boolean;
    /**
     * 
     * @type {string}
     * @memberof CloudTask
     */
    'finishedAt': string;
    /**
     * 
     * @type {string}
     * @memberof CloudTask
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface CommonQueryParams
 */
export interface CommonQueryParams {
    /**
     * 
     * @type {string}
     * @memberof CommonQueryParams
     */
    'dryRun'?: CommonQueryParamsDryRunEnum;
    /**
     * 
     * @type {string}
     * @memberof CommonQueryParams
     */
    'manual'?: CommonQueryParamsManualEnum;
    /**
     * 
     * @type {string}
     * @memberof CommonQueryParams
     */
    'enableDelete'?: CommonQueryParamsEnableDeleteEnum;
}

export const CommonQueryParamsDryRunEnum = {
    True: 'true'
} as const;

export type CommonQueryParamsDryRunEnum = typeof CommonQueryParamsDryRunEnum[keyof typeof CommonQueryParamsDryRunEnum];
export const CommonQueryParamsManualEnum = {
    True: 'true'
} as const;

export type CommonQueryParamsManualEnum = typeof CommonQueryParamsManualEnum[keyof typeof CommonQueryParamsManualEnum];
export const CommonQueryParamsEnableDeleteEnum = {
    True: 'true'
} as const;

export type CommonQueryParamsEnableDeleteEnum = typeof CommonQueryParamsEnableDeleteEnum[keyof typeof CommonQueryParamsEnableDeleteEnum];

/**
 * 
 * @export
 * @interface ConfiguratorData
 */
export interface ConfiguratorData {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ConfiguratorData
     */
    'ID': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorData
     */
    'Name': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorData
     */
    'Value': string;
}
/**
 * 
 * @export
 * @interface ConfiguratorDetail
 */
export interface ConfiguratorDetail {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof ConfiguratorDetail
     */
    'ConfiguratorTabID': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguratorDetail
     */
    'RowOrder': number;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorDetail
     */
    'ControlID': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorDetail
     */
    'ControlName': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorDetail
     */
    'ControlType': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorDetail
     */
    'ConfigurationJSON': string;
}
/**
 * 
 * @export
 * @interface ConfiguratorListRequest
 */
export interface ConfiguratorListRequest {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof ConfiguratorListRequest
     */
    'ConfiguratorIDNumber'?: number;
}
/**
 * 
 * @export
 * @interface ConfiguratorListResponse
 */
export interface ConfiguratorListResponse extends Array<ConfiguratorListResponseInner> {
}
/**
 * 
 * @export
 * @interface ConfiguratorListResponseInner
 */
export interface ConfiguratorListResponseInner {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof ConfiguratorListResponseInner
     */
    'ConfiguratorID': number;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorListResponseInner
     */
    'ConfiguratorName': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorListResponseInner
     */
    'Status': string;
    /**
     * 
     * @type {Array<ConfiguratorListResponseItemConfiguratorDetailsInner>}
     * @memberof ConfiguratorListResponseInner
     */
    'ConfiguratorDetails': Array<ConfiguratorListResponseItemConfiguratorDetailsInner>;
}
/**
 * 
 * @export
 * @interface ConfiguratorListResponseItem
 */
export interface ConfiguratorListResponseItem {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof ConfiguratorListResponseItem
     */
    'ConfiguratorID': number;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorListResponseItem
     */
    'ConfiguratorName': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorListResponseItem
     */
    'Status': string;
    /**
     * 
     * @type {Array<ConfiguratorListResponseItemConfiguratorDetailsInner>}
     * @memberof ConfiguratorListResponseItem
     */
    'ConfiguratorDetails': Array<ConfiguratorListResponseItemConfiguratorDetailsInner>;
}
/**
 * 
 * @export
 * @interface ConfiguratorListResponseItemConfiguratorDetailsInner
 */
export interface ConfiguratorListResponseItemConfiguratorDetailsInner {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof ConfiguratorListResponseItemConfiguratorDetailsInner
     */
    'ConfiguratorTabID': number;
    /**
     * 
     * @type {number}
     * @memberof ConfiguratorListResponseItemConfiguratorDetailsInner
     */
    'RowOrder': number;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorListResponseItemConfiguratorDetailsInner
     */
    'ControlID': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorListResponseItemConfiguratorDetailsInner
     */
    'ControlName': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorListResponseItemConfiguratorDetailsInner
     */
    'ControlType': string;
    /**
     * 
     * @type {string}
     * @memberof ConfiguratorListResponseItemConfiguratorDetailsInner
     */
    'ConfigurationJSON': string;
}
/**
 * 
 * @export
 * @interface ConstantCreateInput
 */
export interface ConstantCreateInput {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ConstantCreateInput
     */
    'key': string;
    /**
     * 
     * @type {ConstantCreateInputJson}
     * @memberof ConstantCreateInput
     */
    'json'?: ConstantCreateInputJson;
    /**
     * 
     * @type {string}
     * @memberof ConstantCreateInput
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof ConstantCreateInput
     */
    'type'?: ConstantCreateInputTypeEnum;
}

export const ConstantCreateInputTypeEnum = {
    Boolean: 'BOOLEAN',
    Number: 'NUMBER',
    Numberarray: 'NUMBERARRAY',
    Object: 'OBJECT',
    Objectarray: 'OBJECTARRAY',
    String: 'STRING',
    Stringarray: 'STRINGARRAY'
} as const;

export type ConstantCreateInputTypeEnum = typeof ConstantCreateInputTypeEnum[keyof typeof ConstantCreateInputTypeEnum];

/**
 * @type ConstantCreateInputJson
 * @export
 */
export type ConstantCreateInputJson = ConstantCreateInputJsonOneOf | ConstantCreateInputJsonOneOf1 | object | { [key: string]: any | undefined; };

/**
 * Matches a JSON array. Unlike \\`JsonArray\\`, readonly arrays are assignable to this type.
 * @export
 * @interface ConstantCreateInputJsonOneOf
 */
export interface ConstantCreateInputJsonOneOf {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof ConstantCreateInputJsonOneOf
     */
    'length': number;
    /**
     * 
     * @type {ConstantCreateInputJsonOneOfUnscopables3003}
     * @memberof ConstantCreateInputJsonOneOf
     */
    '__@unscopables@3003': ConstantCreateInputJsonOneOfUnscopables3003;
}
/**
 * 
 * @export
 * @interface ConstantCreateInputJsonOneOf1
 */
export interface ConstantCreateInputJsonOneOf1 {
}
/**
 * 
 * @export
 * @interface ConstantCreateInputJsonOneOfUnscopables3003
 */
export interface ConstantCreateInputJsonOneOfUnscopables3003 {
    [key: string]: any;

    /**
     * 
     * @type {boolean}
     * @memberof ConstantCreateInputJsonOneOfUnscopables3003
     */
    'length'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstantCreateInputJsonOneOfUnscopables3003
     */
    'toString'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstantCreateInputJsonOneOfUnscopables3003
     */
    'toLocaleString'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstantCreateInputJsonOneOfUnscopables3003
     */
    'concat'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstantCreateInputJsonOneOfUnscopables3003
     */
    'join'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstantCreateInputJsonOneOfUnscopables3003
     */
    'slice'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstantCreateInputJsonOneOfUnscopables3003
     */
    'indexOf'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstantCreateInputJsonOneOfUnscopables3003
     */
    'lastIndexOf'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstantCreateInputJsonOneOfUnscopables3003
     */
    'every'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstantCreateInputJsonOneOfUnscopables3003
     */
    'some'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstantCreateInputJsonOneOfUnscopables3003
     */
    'forEach'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstantCreateInputJsonOneOfUnscopables3003
     */
    'map'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstantCreateInputJsonOneOfUnscopables3003
     */
    'filter'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstantCreateInputJsonOneOfUnscopables3003
     */
    'reduce'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstantCreateInputJsonOneOfUnscopables3003
     */
    'reduceRight'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstantCreateInputJsonOneOfUnscopables3003
     */
    'find'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstantCreateInputJsonOneOfUnscopables3003
     */
    'findIndex'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstantCreateInputJsonOneOfUnscopables3003
     */
    'entries'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstantCreateInputJsonOneOfUnscopables3003
     */
    'keys'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstantCreateInputJsonOneOfUnscopables3003
     */
    'values'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstantCreateInputJsonOneOfUnscopables3003
     */
    'includes'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstantCreateInputJsonOneOfUnscopables3003
     */
    'flatMap'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstantCreateInputJsonOneOfUnscopables3003
     */
    'flat'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstantCreateInputJsonOneOfUnscopables3003
     */
    '__@iterator@3001'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstantCreateInputJsonOneOfUnscopables3003
     */
    '__@unscopables@3003'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ConstantCreateInputJsonOneOfUnscopables3003
     */
    'at'?: boolean;
}
/**
 * 
 * @export
 * @interface ConstantDynamicProps
 */
export interface ConstantDynamicProps {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ConstantDynamicProps
     */
    'type': string;
    /**
     * 
     * @type {Array<Array<string>>}
     * @memberof ConstantDynamicProps
     */
    'dynamicProperties': Array<Array<string>>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ConstantKey = {
    InvoiceTextFj: 'INVOICE_TEXT_FJ',
    SmsReminderBefaringDynText: 'SMS_REMINDER_BEFARING_DYN_TEXT',
    SmsReminderMonteringText: 'SMS_REMINDER_MONTERING_TEXT',
    SmsReminderRepText: 'SMS_REMINDER_REP_TEXT',
    SmsReminderServiceDynText: 'SMS_REMINDER_SERVICE_DYN_TEXT'
} as const;

export type ConstantKey = typeof ConstantKey[keyof typeof ConstantKey];


/**
 * @type ConstantProps
 * @export
 */
export type ConstantProps = ConstantPropsOneOf | ConstantPropsOneOf1;

/**
 * 
 * @export
 * @interface ConstantPropsOneOf
 */
export interface ConstantPropsOneOf {
    [key: string]: any;

    /**
     * 
     * @type {Array<string>}
     * @memberof ConstantPropsOneOf
     */
    'key': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ConstantPropsOneOf
     */
    'type': string;
    /**
     * 
     * @type {Array<Array<string>>}
     * @memberof ConstantPropsOneOf
     */
    'dynamicProperties': Array<Array<string>>;
}
/**
 * 
 * @export
 * @interface ConstantPropsOneOf1
 */
export interface ConstantPropsOneOf1 {
    [key: string]: any;

    /**
     * 
     * @type {Array<string>}
     * @memberof ConstantPropsOneOf1
     */
    'key': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ConstantPropsOneOf1
     */
    'type': ConstantPropsOneOf1TypeEnum;
}

export const ConstantPropsOneOf1TypeEnum = {
    Boolean: 'BOOLEAN',
    Null: 'NULL',
    Number: 'NUMBER',
    Numberarray: 'NUMBERARRAY',
    Object: 'OBJECT',
    Objectarray: 'OBJECTARRAY',
    String: 'STRING',
    Stringarray: 'STRINGARRAY'
} as const;

export type ConstantPropsOneOf1TypeEnum = typeof ConstantPropsOneOf1TypeEnum[keyof typeof ConstantPropsOneOf1TypeEnum];

/**
 * 
 * @export
 * @interface ConstantRecord
 */
export interface ConstantRecord {
    [key: string]: any;

    /**
     * 
     * @type {ConstantRecordINVOICETEXTFJ}
     * @memberof ConstantRecord
     */
    'INVOICE_TEXT_FJ': ConstantRecordINVOICETEXTFJ;
    /**
     * 
     * @type {ConstantRecordSMSREMINDERSERVICEDYNTEXT}
     * @memberof ConstantRecord
     */
    'SMS_REMINDER_SERVICE_DYN_TEXT': ConstantRecordSMSREMINDERSERVICEDYNTEXT;
    /**
     * 
     * @type {ConstantRecordSMSREMINDERBEFARINGDYNTEXT}
     * @memberof ConstantRecord
     */
    'SMS_REMINDER_BEFARING_DYN_TEXT': ConstantRecordSMSREMINDERBEFARINGDYNTEXT;
    /**
     * 
     * @type {ConstantRecordSMSREMINDERREPTEXT}
     * @memberof ConstantRecord
     */
    'SMS_REMINDER_REP_TEXT': ConstantRecordSMSREMINDERREPTEXT;
    /**
     * 
     * @type {ConstantRecordSMSREMINDERMONTERINGTEXT}
     * @memberof ConstantRecord
     */
    'SMS_REMINDER_MONTERING_TEXT': ConstantRecordSMSREMINDERMONTERINGTEXT;
}
/**
 * 
 * @export
 * @interface ConstantRecordINVOICETEXTFJ
 */
export interface ConstantRecordINVOICETEXTFJ {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ConstantRecordINVOICETEXTFJ
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof ConstantRecordINVOICETEXTFJ
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ConstantRecordSMSREMINDERBEFARINGDYNTEXT
 */
export interface ConstantRecordSMSREMINDERBEFARINGDYNTEXT {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ConstantRecordSMSREMINDERBEFARINGDYNTEXT
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof ConstantRecordSMSREMINDERBEFARINGDYNTEXT
     */
    'type': string;
    /**
     * 
     * @type {Array<any>}
     * @memberof ConstantRecordSMSREMINDERBEFARINGDYNTEXT
     */
    'dynamicProperties': Array<any>;
}
/**
 * 
 * @export
 * @interface ConstantRecordSMSREMINDERMONTERINGTEXT
 */
export interface ConstantRecordSMSREMINDERMONTERINGTEXT {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ConstantRecordSMSREMINDERMONTERINGTEXT
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof ConstantRecordSMSREMINDERMONTERINGTEXT
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ConstantRecordSMSREMINDERREPTEXT
 */
export interface ConstantRecordSMSREMINDERREPTEXT {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ConstantRecordSMSREMINDERREPTEXT
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof ConstantRecordSMSREMINDERREPTEXT
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ConstantRecordSMSREMINDERSERVICEDYNTEXT
 */
export interface ConstantRecordSMSREMINDERSERVICEDYNTEXT {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ConstantRecordSMSREMINDERSERVICEDYNTEXT
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof ConstantRecordSMSREMINDERSERVICEDYNTEXT
     */
    'type': string;
    /**
     * 
     * @type {Array<any>}
     * @memberof ConstantRecordSMSREMINDERSERVICEDYNTEXT
     */
    'dynamicProperties': Array<any>;
}
/**
 * 
 * @export
 * @interface CreateAPInvoiceRequest
 */
export interface CreateAPInvoiceRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreateAPInvoiceRequest
     */
    'CompanyCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAPInvoiceRequest
     */
    'AccountNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAPInvoiceRequest
     */
    'InvoiceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAPInvoiceRequest
     */
    'InvoiceDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAPInvoiceRequest
     */
    'PostingDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAPInvoiceRequest
     */
    'DueDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAPInvoiceRequest
     */
    'AmountDue': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAPInvoiceRequest
     */
    'GLAccount': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAPInvoiceRequest
     */
    'DocumentMemo': string;
}
/**
 * 
 * @export
 * @interface CreateAPInvoiceResponse
 */
export interface CreateAPInvoiceResponse {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreateAPInvoiceResponse
     */
    'Error': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAPInvoiceResponse
     */
    'Success': string;
    /**
     * 
     * @type {number}
     * @memberof CreateAPInvoiceResponse
     */
    'APDocumentID': number;
    /**
     * 
     * @type {string}
     * @memberof CreateAPInvoiceResponse
     */
    'AccountNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAPInvoiceResponse
     */
    'InvoiceNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAPInvoiceResponse
     */
    'InvoiceDate': string;
}
/**
 * 
 * @export
 * @interface CreateARInvoiceRequest
 */
export interface CreateARInvoiceRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreateARInvoiceRequest
     */
    'CompanyCode': string;
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof CreateARInvoiceRequest
     */
    'SalesOrderID': ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {string}
     * @memberof CreateARInvoiceRequest
     */
    'PostingDate': string;
}
/**
 * @type CreateARInvoiceResponse
 * @export
 */
export type CreateARInvoiceResponse = CreateARInvoiceResponseOneOf | CreateARInvoiceResponseOneOf1 | CreateARInvoiceResponseOneOf2;

/**
 * 
 * @export
 * @interface CreateARInvoiceResponseOneOf
 */
export interface CreateARInvoiceResponseOneOf {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreateARInvoiceResponseOneOf
     */
    'ARInvoiceID': string;
}
/**
 * 
 * @export
 * @interface CreateARInvoiceResponseOneOf1
 */
export interface CreateARInvoiceResponseOneOf1 {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreateARInvoiceResponseOneOf1
     */
    'Error': string;
}
/**
 * 
 * @export
 * @interface CreateARInvoiceResponseOneOf2
 */
export interface CreateARInvoiceResponseOneOf2 {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreateARInvoiceResponseOneOf2
     */
    'Invoice Error': string;
}
/**
 * 
 * @export
 * @interface CreateAlertPolicyCommand
 */
export interface CreateAlertPolicyCommand {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreateAlertPolicyCommand
     */
    'documentation': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateAlertPolicyCommand
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateAlertPolicyCommand
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAlertPolicyCommand
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAlertPolicyCommand
     */
    'modified': string;
    /**
     * 
     * @type {AlertPoliciesResponseInnerConditions}
     * @memberof CreateAlertPolicyCommand
     */
    'conditions': AlertPoliciesResponseInnerConditions;
    /**
     * 
     * @type {string}
     * @memberof CreateAlertPolicyCommand
     */
    'validity': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAlertPolicyCommand
     */
    'combiner': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAlertPolicyCommand
     */
    'severity': string;
    /**
     * 
     * @type {AlertPoliciesResponseInnerConditions}
     * @memberof CreateAlertPolicyCommand
     */
    'notificationChannels': AlertPoliciesResponseInnerConditions;
    /**
     * 
     * @type {string}
     * @memberof CreateAlertPolicyCommand
     */
    'notificationRateLimit': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAlertPolicyCommand
     */
    'autoClose': string;
}
/**
 * 
 * @export
 * @interface CreateAlertPolicyIncidentCommand
 */
export interface CreateAlertPolicyIncidentCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateAlertPolicyIncidentCommand
     */
    'summary': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAlertPolicyIncidentCommand
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAlertPolicyIncidentCommand
     */
    'documentation': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAlertPolicyIncidentCommand
     */
    'status': CreateAlertPolicyIncidentCommandStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateAlertPolicyIncidentCommand
     */
    'severity': string;
    /**
     * 
     * @type {number}
     * @memberof CreateAlertPolicyIncidentCommand
     */
    'alertPolicyId': number;
    /**
     * 
     * @type {string}
     * @memberof CreateAlertPolicyIncidentCommand
     */
    'incidentId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAlertPolicyIncidentCommand
     */
    'incidentUrl': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAlertPolicyIncidentCommand
     */
    'resourceName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAlertPolicyIncidentCommand
     */
    'serviceName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAlertPolicyIncidentCommand
     */
    'revisionName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAlertPolicyIncidentCommand
     */
    'conditionName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAlertPolicyIncidentCommand
     */
    'logFilter': string;
    /**
     * 
     * @type {AlertPoliciesResponseInnerConditions}
     * @memberof CreateAlertPolicyIncidentCommand
     */
    'logs': AlertPoliciesResponseInnerConditions;
    /**
     * 
     * @type {string}
     * @memberof CreateAlertPolicyIncidentCommand
     */
    'notified': string;
}

export const CreateAlertPolicyIncidentCommandStatusEnum = {
    Acknowledged: 'ACKNOWLEDGED',
    Muted: 'MUTED',
    Open: 'OPEN',
    Resolved: 'RESOLVED'
} as const;

export type CreateAlertPolicyIncidentCommandStatusEnum = typeof CreateAlertPolicyIncidentCommandStatusEnum[keyof typeof CreateAlertPolicyIncidentCommandStatusEnum];

/**
 * 
 * @export
 * @interface CreateBillOfMaterialRequest
 */
export interface CreateBillOfMaterialRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreateBillOfMaterialRequest
     */
    'ParentItemNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBillOfMaterialRequest
     */
    'ParentRevision': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBillOfMaterialRequest
     */
    'SubItemNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBillOfMaterialRequest
     */
    'SubItemRevision': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBillOfMaterialRequest
     */
    'Description': string;
    /**
     * 
     * @type {number}
     * @memberof CreateBillOfMaterialRequest
     */
    'Operation': number;
    /**
     * 
     * @type {string}
     * @memberof CreateBillOfMaterialRequest
     */
    'Unit': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBillOfMaterialRequest
     */
    'IssueMethod': string;
    /**
     * 
     * @type {number}
     * @memberof CreateBillOfMaterialRequest
     */
    'Quantity': number;
    /**
     * 
     * @type {number}
     * @memberof CreateBillOfMaterialRequest
     */
    'ScrapPercent': number;
    /**
     * 
     * @type {string}
     * @memberof CreateBillOfMaterialRequest
     */
    'QuantityUOM': string;
    /**
     * 
     * @type {string}
     * @memberof CreateBillOfMaterialRequest
     */
    'Supplier': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateBillOfMaterialRequest
     */
    'RequireRFQ': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateBillOfMaterialRequest
     */
    'CostingMethod': string;
    /**
     * 
     * @type {number}
     * @memberof CreateBillOfMaterialRequest
     */
    'UnitPrice': number;
    /**
     * 
     * @type {number}
     * @memberof CreateBillOfMaterialRequest
     */
    'DimA': number;
    /**
     * 
     * @type {number}
     * @memberof CreateBillOfMaterialRequest
     */
    'DimB': number;
    /**
     * 
     * @type {number}
     * @memberof CreateBillOfMaterialRequest
     */
    'DimC': number;
    /**
     * 
     * @type {number}
     * @memberof CreateBillOfMaterialRequest
     */
    'DimD': number;
    /**
     * 
     * @type {number}
     * @memberof CreateBillOfMaterialRequest
     */
    'DimE': number;
}
/**
 * 
 * @export
 * @interface CreateBillOfMaterialResponse
 */
export interface CreateBillOfMaterialResponse {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof CreateBillOfMaterialResponse
     */
    'myID': number;
}
/**
 * 
 * @export
 * @interface CreateCustomerContactRequest
 */
export interface CreateCustomerContactRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreateCustomerContactRequest
     */
    'AccountNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerContactRequest
     */
    'DefaultContact': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerContactRequest
     */
    'FirstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerContactRequest
     */
    'LastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerContactRequest
     */
    'ContactTitle': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerContactRequest
     */
    'EmailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerContactRequest
     */
    'OfficeNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerContactRequest
     */
    'MobileNumber': string;
}
/**
 * 
 * @export
 * @interface CreateCustomerContactResponse
 */
export interface CreateCustomerContactResponse {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof CreateCustomerContactResponse
     */
    'ContactID': number;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerContactResponse
     */
    'FirstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerContactResponse
     */
    'LastName': string;
}
/**
 * 
 * @export
 * @interface CreateCustomerRequest
 */
export interface CreateCustomerRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'CompanyCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'AccountNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'CompanyName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'CreateDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'LastModifiedDateTime': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'WebAddress': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'FederalTaxID': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'EmailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'PhoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'FaxNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'InternalNotes': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'BillToAddress1': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'BillToAddress2': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'BillToCity': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'BillToState': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'BillToZipCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'BillToCounty': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'BillToCountry': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'ShipToAddress1': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'ShipToAddress2': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'ShipToCity': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'ShipToState': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'ShipToZipCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'ShipToCounty': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'ShipToCountry': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'ContactFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'ContactLastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'ContactTitle': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'ContactEmailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerRequest
     */
    'ContactPhoneNumber': string;
}
/**
 * 
 * @export
 * @interface CreateCustomerResponse
 */
export interface CreateCustomerResponse {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof CreateCustomerResponse
     */
    'CompanyID': number;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerResponse
     */
    'CompanyName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateCustomerResponse
     */
    'AccountNumber': string;
}
/**
 * 
 * @export
 * @interface CreateItem
 */
export interface CreateItem {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof CreateItem
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof CreateItem
     */
    'parent_id': number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof CreateItem
     */
    'properties': Array<AnswerPropertiesInner>;
}
/**
 * 
 * @export
 * @interface CreateItemRequest
 */
export interface CreateItemRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'ItemNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'Procurement': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'ItemType': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'ProductCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'ReplenishMethod': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'Category': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'SubCategory': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'ItemName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'Revision': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'Manufacturer': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'ManufacturerPartNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'IssueMethod': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'CostingMethod': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'PricingMethod': string;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'PricingPercent': number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'LastCost': number;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'LastPriceChangeDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'LastOrderDate': string;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'LastPriceQuoted': number;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'LastQuotedDate': string;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'StandardCost': number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'UnitPrice': number;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'Description': string;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'DimA': number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'DimB': number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'DimC': number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'DimD': number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'DimE': number;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'Notes': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateItemRequest
     */
    'TrackHeatNumbers': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateItemRequest
     */
    'TrackSerialNumbers': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateItemRequest
     */
    'TrackLotNumbers': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateItemRequest
     */
    'TrackExpirationDates': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateItemRequest
     */
    'InspectionRequired': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateItemRequest
     */
    'RFQRequired': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateItemRequest
     */
    'PhantomBOM': boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'StandardDelivery': number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'DockToShelf': number;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'ShippingInstructions': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'PreferedSupplierNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'PreferredSupplierName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'Engineer': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'MRPController': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'PlanningMethod': string;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'OrderMultiple': number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'MinimumOrderQty': number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'SafetyStock': number;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'OrderInterval': string;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'LeadTimeDays': number;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'CheckATPRule': string;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'ShelfLife': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateItemRequest
     */
    'IsStockable': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'InsertionDateTime': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'LastModifiedDateTime': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'Status': string;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'DaysRFQGoodFor': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateItemRequest
     */
    'CustomerMaterial': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'UOM': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'PurchasingQtyUOM': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'PurchasingPriceUOM': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'EngineeringChange': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateItemRequest
     */
    'EstimateRequired': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'WeightUOM': string;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'Weight': number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'GrossWeight': number;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'ForecastControl': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'BucketType': string;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'AcceptableEarlyDays': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateItemRequest
     */
    'RevisionControl': boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'StartingSerialNumber': number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'StartingLotNumber': number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'PreviousStandardCost': number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'FutureStandardCost': number;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'AltItemMaster': string;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'LotSize': number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'LastLotSize': number;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'DrawingNumber': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateItemRequest
     */
    'Reprice': boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'PaperworkTime': number;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'AdditionalDataValues': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'HTSCode': string;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'LegacyID': number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'PeriodOrderQty': number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemRequest
     */
    'InspectionPlanID': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateItemRequest
     */
    'TrackDateOfManufactured': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateItemRequest
     */
    'TrackConditionCodes': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateItemRequest
     */
    'CreateItemOnly': boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'Configurable': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemRequest
     */
    'GlobalTradeItemNumber': string;
}
/**
 * 
 * @export
 * @interface CreateItemResponse
 */
export interface CreateItemResponse {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof CreateItemResponse
     */
    'ItemMasterID': number;
    /**
     * 
     * @type {string}
     * @memberof CreateItemResponse
     */
    'ItemNumber': string;
}
/**
 * 
 * @export
 * @interface CreateMamutRequest
 */
export interface CreateMamutRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreateMamutRequest
     */
    'cpproName'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateMamutRequest
     */
    'acErpId'?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateMamutRequest
     */
    'cpproId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateMamutRequest
     */
    'isACT'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateNotificationChannelCommand
 */
export interface CreateNotificationChannelCommand {
    /**
     * 
     * @type {string}
     * @memberof CreateNotificationChannelCommand
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof CreateNotificationChannelCommand
     */
    'type': CreateNotificationChannelCommandTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateNotificationChannelCommand
     */
    'rateLimit': number;
    /**
     * 
     * @type {AlertPoliciesResponseInnerConditions}
     * @memberof CreateNotificationChannelCommand
     */
    'extraConfig': AlertPoliciesResponseInnerConditions;
}

export const CreateNotificationChannelCommandTypeEnum = {
    Calendar: 'CALENDAR',
    Email: 'EMAIL',
    Sms: 'SMS',
    Webhook: 'WEBHOOK'
} as const;

export type CreateNotificationChannelCommandTypeEnum = typeof CreateNotificationChannelCommandTypeEnum[keyof typeof CreateNotificationChannelCommandTypeEnum];

/**
 * 
 * @export
 * @interface CreateNotificationChannelRequest
 */
export interface CreateNotificationChannelRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateNotificationChannelRequest
     */
    'calendarId': string;
}
/**
 * 
 * @export
 * @interface CreateOrder
 */
export interface CreateOrder {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof CreateOrder
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof CreateOrder
     */
    'parent_id'?: number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof CreateOrder
     */
    'properties': Array<AnswerPropertiesInner>;
}
/**
 * 
 * @export
 * @interface CreatePickListRequest
 */
export interface CreatePickListRequest {
    [key: string]: any;

    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof CreatePickListRequest
     */
    'LineItemID': ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof CreatePickListRequest
     */
    'SalesOrderID': ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {string}
     * @memberof CreatePickListRequest
     */
    'IdentifiersToPick': string;
}
/**
 * 
 * @export
 * @interface CreatePickListRequestArray
 */
export interface CreatePickListRequestArray extends Array<CreatePickListRequestArrayInner> {
}
/**
 * 
 * @export
 * @interface CreatePickListRequestArrayInner
 */
export interface CreatePickListRequestArrayInner {
    [key: string]: any;

    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof CreatePickListRequestArrayInner
     */
    'LineItemID': ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof CreatePickListRequestArrayInner
     */
    'SalesOrderID': ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {string}
     * @memberof CreatePickListRequestArrayInner
     */
    'IdentifiersToPick': string;
}
/**
 * @type CreatePickListResponse
 * @export
 */
export type CreatePickListResponse = CreatePickListResponseOneOf | CreatePickListResponseOneOf1 | CreatePickListResponseOneOf2;

/**
 * 
 * @export
 * @interface CreatePickListResponseOneOf
 */
export interface CreatePickListResponseOneOf {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreatePickListResponseOneOf
     */
    'PickListID': string;
}
/**
 * 
 * @export
 * @interface CreatePickListResponseOneOf1
 */
export interface CreatePickListResponseOneOf1 {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreatePickListResponseOneOf1
     */
    'SOReleaseID': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePickListResponseOneOf1
     */
    'Error': string;
}
/**
 * 
 * @export
 * @interface CreatePickListResponseOneOf2
 */
export interface CreatePickListResponseOneOf2 {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreatePickListResponseOneOf2
     */
    'PickQuantity': string;
    /**
     * 
     * @type {string}
     * @memberof CreatePickListResponseOneOf2
     */
    'Error': string;
}
/**
 * 
 * @export
 * @interface CreatePolicyNotificationCommand
 */
export interface CreatePolicyNotificationCommand {
    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof CreatePolicyNotificationCommand
     */
    'lastTriggered'?: InstallItemUsageAlternativeCreateInputCachedAt;
    /**
     * 
     * @type {number}
     * @memberof CreatePolicyNotificationCommand
     */
    'rateLimit'?: number;
}
/**
 * 
 * @export
 * @interface CreateProjectRequest
 */
export interface CreateProjectRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequest
     */
    'ProjectName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequest
     */
    'ProjectNumber': string;
}
/**
 * 
 * @export
 * @interface CreateProjectResponse
 */
export interface CreateProjectResponse {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof CreateProjectResponse
     */
    'ProjectId': number;
}
/**
 * 
 * @export
 * @interface CreateProjectTimeRequest
 */
export interface CreateProjectTimeRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreateProjectTimeRequest
     */
    'ProjectNumber': string;
    /**
     * 
     * @type {number}
     * @memberof CreateProjectTimeRequest
     */
    'EmployeeID': number;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectTimeRequest
     */
    'WorkCenter': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectTimeRequest
     */
    'WeekBeginning': string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectTimeRequest
     */
    'Notes': string;
    /**
     * 
     * @type {number}
     * @memberof CreateProjectTimeRequest
     */
    'SundayHours': number;
    /**
     * 
     * @type {number}
     * @memberof CreateProjectTimeRequest
     */
    'MondayHours': number;
    /**
     * 
     * @type {number}
     * @memberof CreateProjectTimeRequest
     */
    'TuesdayHours': number;
    /**
     * 
     * @type {number}
     * @memberof CreateProjectTimeRequest
     */
    'WednesdayHours': number;
    /**
     * 
     * @type {number}
     * @memberof CreateProjectTimeRequest
     */
    'ThursdayHours': number;
    /**
     * 
     * @type {number}
     * @memberof CreateProjectTimeRequest
     */
    'FridayHours': number;
    /**
     * 
     * @type {number}
     * @memberof CreateProjectTimeRequest
     */
    'SaturdayHours': number;
    /**
     * 
     * @type {number}
     * @memberof CreateProjectTimeRequest
     */
    'SundayOT': number;
    /**
     * 
     * @type {number}
     * @memberof CreateProjectTimeRequest
     */
    'MondayOT': number;
    /**
     * 
     * @type {number}
     * @memberof CreateProjectTimeRequest
     */
    'TuesdayOT': number;
    /**
     * 
     * @type {number}
     * @memberof CreateProjectTimeRequest
     */
    'WednesdayOT': number;
    /**
     * 
     * @type {number}
     * @memberof CreateProjectTimeRequest
     */
    'ThursdayOT': number;
    /**
     * 
     * @type {number}
     * @memberof CreateProjectTimeRequest
     */
    'FridayOT': number;
    /**
     * 
     * @type {number}
     * @memberof CreateProjectTimeRequest
     */
    'SaturdayOT': number;
    /**
     * 
     * @type {number}
     * @memberof CreateProjectTimeRequest
     */
    'ProductionOrderID': number;
    /**
     * 
     * @type {number}
     * @memberof CreateProjectTimeRequest
     */
    'Operation': number;
}
/**
 * 
 * @export
 * @interface CreateProjectTimeResponse
 */
export interface CreateProjectTimeResponse {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof CreateProjectTimeResponse
     */
    'ProjectTimeID': number;
}
/**
 * 
 * @export
 * @interface CreateQuoteRequest
 */
export interface CreateQuoteRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'CompanyCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'QuoteID': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'CompanyName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'CustomerRFQ': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'ProjectNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'RFQDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'ValidityDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'Currency': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'PaymentTerms': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'DeliveryMethod': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'DeliveryTerm': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'SalesRep': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'Message': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'OfficeNotes': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'BillingAccountNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'BillingCompanyID': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'BillToSiteID': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'BillToAddress1': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'BillToAddress2': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'BillToCity': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'BillToState': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'BillToZipCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'BillToCounty': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'BillToCountry': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'ShipToSiteID': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'ShipToAddress1': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'ShipToAddress2': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'ShipToCity': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'ShipToState': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'ShipToZipCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'ShipToCounty': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'ShipToCountry': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'ContactFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'ContactLastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'ContactEmailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'ContactPhoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'ContactFaxNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequest
     */
    'ContactTitle': string;
    /**
     * 
     * @type {Array<CreateQuoteRequestLineItemsInner>}
     * @memberof CreateQuoteRequest
     */
    'LineItems': Array<CreateQuoteRequestLineItemsInner>;
    /**
     * 
     * @type {Array<CreateQuoteRequestAdditionalChargesInner>}
     * @memberof CreateQuoteRequest
     */
    'AdditionalCharges': Array<CreateQuoteRequestAdditionalChargesInner>;
    /**
     * 
     * @type {Array<ItemListResponseItemAdditionalDataValuesInner>}
     * @memberof CreateQuoteRequest
     */
    'AdditionalDataValues': Array<ItemListResponseItemAdditionalDataValuesInner>;
}
/**
 * 
 * @export
 * @interface CreateQuoteRequestAdditionalChargesInner
 */
export interface CreateQuoteRequestAdditionalChargesInner {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequestAdditionalChargesInner
     */
    'ChargeType': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequestAdditionalChargesInner
     */
    'Amount': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequestAdditionalChargesInner
     */
    'TaxCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequestAdditionalChargesInner
     */
    'DistributionMethod': string;
}
/**
 * 
 * @export
 * @interface CreateQuoteRequestLineItemsInner
 */
export interface CreateQuoteRequestLineItemsInner {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof CreateQuoteRequestLineItemsInner
     */
    'LineItemID': number;
    /**
     * 
     * @type {number}
     * @memberof CreateQuoteRequestLineItemsInner
     */
    'Quantity': number;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequestLineItemsInner
     */
    'ItemNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequestLineItemsInner
     */
    'Revision': string;
    /**
     * 
     * @type {Array<CreateQuoteRequestLineItemsInnerConfiguratorDataInner>}
     * @memberof CreateQuoteRequestLineItemsInner
     */
    'ConfiguratorData': Array<CreateQuoteRequestLineItemsInnerConfiguratorDataInner>;
}
/**
 * 
 * @export
 * @interface CreateQuoteRequestLineItemsInnerConfiguratorDataInner
 */
export interface CreateQuoteRequestLineItemsInnerConfiguratorDataInner {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequestLineItemsInnerConfiguratorDataInner
     */
    'ID': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequestLineItemsInnerConfiguratorDataInner
     */
    'Name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateQuoteRequestLineItemsInnerConfiguratorDataInner
     */
    'Value': string;
}
/**
 * 
 * @export
 * @interface CreateQuoteResponse
 */
export interface CreateQuoteResponse {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreateQuoteResponse
     */
    'QuoteID': string;
}
/**
 * 
 * @export
 * @interface CreateSalesOrderChecklistRequest
 */
export interface CreateSalesOrderChecklistRequest {
    [key: string]: any;

    /**
     * 
     * @type {CreateSalesOrderChecklistRequestCustomer}
     * @memberof CreateSalesOrderChecklistRequest
     */
    'customer': CreateSalesOrderChecklistRequestCustomer;
}
/**
 * 
 * @export
 * @interface CreateSalesOrderChecklistRequestCustomer
 */
export interface CreateSalesOrderChecklistRequestCustomer {
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderChecklistRequestCustomer
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderChecklistRequestCustomer
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderChecklistRequestCustomer
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderChecklistRequestCustomer
     */
    'zip': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderChecklistRequestCustomer
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderChecklistRequestCustomer
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface CreateSalesOrderFromQuoteRequest
 */
export interface CreateSalesOrderFromQuoteRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderFromQuoteRequest
     */
    'CompanyCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderFromQuoteRequest
     */
    'QuoteID': string;
    /**
     * 
     * @type {Array<CreateQuoteRequestLineItemsInner>}
     * @memberof CreateSalesOrderFromQuoteRequest
     */
    'LineItems': Array<CreateQuoteRequestLineItemsInner>;
}
/**
 * 
 * @export
 * @interface CreateSalesOrderFromQuoteResponse
 */
export interface CreateSalesOrderFromQuoteResponse {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderFromQuoteResponse
     */
    'SalesOrderID': string;
}
/**
 * 
 * @export
 * @interface CreateSalesOrderRequest
 */
export interface CreateSalesOrderRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderRequest
     */
    'CompanyCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderRequest
     */
    'AccountNumber': string;
    /**
     * 
     * @type {number}
     * @memberof CreateSalesOrderRequest
     */
    'SalesOrderID': number;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderRequest
     */
    'PurchaseOrder': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderRequest
     */
    'ProjectNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderRequest
     */
    'OrderDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderRequest
     */
    'Currency': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderRequest
     */
    'Warehouse': string;
    /**
     * 
     * @type {Array<CreateSalesOrderRequestLineItemsInner>}
     * @memberof CreateSalesOrderRequest
     */
    'LineItems': Array<CreateSalesOrderRequestLineItemsInner>;
}
/**
 * 
 * @export
 * @interface CreateSalesOrderRequestLineItemsInner
 */
export interface CreateSalesOrderRequestLineItemsInner {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof CreateSalesOrderRequestLineItemsInner
     */
    'LineItemID': number;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderRequestLineItemsInner
     */
    'ItemNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderRequestLineItemsInner
     */
    'Revision': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderRequestLineItemsInner
     */
    'PromiseDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderRequestLineItemsInner
     */
    'RequestedDate': string;
    /**
     * 
     * @type {number}
     * @memberof CreateSalesOrderRequestLineItemsInner
     */
    'Quantity': number;
    /**
     * 
     * @type {number}
     * @memberof CreateSalesOrderRequestLineItemsInner
     */
    'UnitPrice': number;
    /**
     * 
     * @type {number}
     * @memberof CreateSalesOrderRequestLineItemsInner
     */
    'TotalPrice': number;
}
/**
 * 
 * @export
 * @interface CreateSalesOrderResponse
 */
export interface CreateSalesOrderResponse {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreateSalesOrderResponse
     */
    'SalesOrderID': string;
}
/**
 * 
 * @export
 * @interface CreateServiceOrderChecklistData
 */
export interface CreateServiceOrderChecklistData {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreateServiceOrderChecklistData
     */
    'calendar'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceOrderChecklistData
     */
    'serviceText'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceOrderChecklistData
     */
    'dateAndClock'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceOrderChecklistData
     */
    'slotLength'?: string;
    /**
     * 
     * @type {CreateServiceOrderChecklistDataCustomer}
     * @memberof CreateServiceOrderChecklistData
     */
    'customer': CreateServiceOrderChecklistDataCustomer;
    /**
     * 
     * @type {Array<CreateServiceOrderChecklistDataServiceOrdersInner>}
     * @memberof CreateServiceOrderChecklistData
     */
    'serviceOrders': Array<CreateServiceOrderChecklistDataServiceOrdersInner>;
}
/**
 * 
 * @export
 * @interface CreateServiceOrderChecklistDataCustomer
 */
export interface CreateServiceOrderChecklistDataCustomer {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreateServiceOrderChecklistDataCustomer
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceOrderChecklistDataCustomer
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceOrderChecklistDataCustomer
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceOrderChecklistDataCustomer
     */
    'zip': string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceOrderChecklistDataCustomer
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceOrderChecklistDataCustomer
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface CreateServiceOrderChecklistDataServiceOrdersInner
 */
export interface CreateServiceOrderChecklistDataServiceOrdersInner {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreateServiceOrderChecklistDataServiceOrdersInner
     */
    'product': string;
    /**
     * 
     * @type {number}
     * @memberof CreateServiceOrderChecklistDataServiceOrdersInner
     */
    'discount'?: number;
}
/**
 * 
 * @export
 * @interface CreateServiceOrderChecklistRequest
 */
export interface CreateServiceOrderChecklistRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreateServiceOrderChecklistRequest
     */
    'calendar'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceOrderChecklistRequest
     */
    'serviceText'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceOrderChecklistRequest
     */
    'dateAndClock'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceOrderChecklistRequest
     */
    'slotLength'?: string;
    /**
     * 
     * @type {CreateServiceOrderChecklistRequestCustomer}
     * @memberof CreateServiceOrderChecklistRequest
     */
    'customer': CreateServiceOrderChecklistRequestCustomer;
    /**
     * 
     * @type {Array<CreateServiceOrderChecklistRequestServiceOrdersInner>}
     * @memberof CreateServiceOrderChecklistRequest
     */
    'serviceOrders': Array<CreateServiceOrderChecklistRequestServiceOrdersInner>;
}
/**
 * 
 * @export
 * @interface CreateServiceOrderChecklistRequestCustomer
 */
export interface CreateServiceOrderChecklistRequestCustomer {
    /**
     * 
     * @type {string}
     * @memberof CreateServiceOrderChecklistRequestCustomer
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceOrderChecklistRequestCustomer
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceOrderChecklistRequestCustomer
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceOrderChecklistRequestCustomer
     */
    'zip': string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceOrderChecklistRequestCustomer
     */
    'city': string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceOrderChecklistRequestCustomer
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface CreateServiceOrderChecklistRequestServiceOrdersInner
 */
export interface CreateServiceOrderChecklistRequestServiceOrdersInner {
    /**
     * 
     * @type {string}
     * @memberof CreateServiceOrderChecklistRequestServiceOrdersInner
     */
    'product': string;
    /**
     * 
     * @type {number}
     * @memberof CreateServiceOrderChecklistRequestServiceOrdersInner
     */
    'discount'?: number;
}
/**
 * 
 * @export
 * @interface CreateShipmentRequest
 */
export interface CreateShipmentRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreateShipmentRequest
     */
    'CompanyCode': string;
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof CreateShipmentRequest
     */
    'SalesOrderID': ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {string}
     * @memberof CreateShipmentRequest
     */
    'DocumentDate': string;
}
/**
 * @type CreateShipmentResponse
 * @export
 */
export type CreateShipmentResponse = CreateARInvoiceResponseOneOf1 | CreateShipmentResponseOneOf;

/**
 * 
 * @export
 * @interface CreateShipmentResponseOneOf
 */
export interface CreateShipmentResponseOneOf {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreateShipmentResponseOneOf
     */
    'ShipmentID': string;
}
/**
 * 
 * @export
 * @interface CreateStandardRoutingRequest
 */
export interface CreateStandardRoutingRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreateStandardRoutingRequest
     */
    'ItemNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateStandardRoutingRequest
     */
    'Revision': string;
    /**
     * 
     * @type {string}
     * @memberof CreateStandardRoutingRequest
     */
    'Description': string;
    /**
     * 
     * @type {number}
     * @memberof CreateStandardRoutingRequest
     */
    'Operation': number;
    /**
     * 
     * @type {string}
     * @memberof CreateStandardRoutingRequest
     */
    'WorkCenter': string;
    /**
     * 
     * @type {string}
     * @memberof CreateStandardRoutingRequest
     */
    'Department': string;
    /**
     * 
     * @type {number}
     * @memberof CreateStandardRoutingRequest
     */
    'SetupTime': number;
    /**
     * 
     * @type {string}
     * @memberof CreateStandardRoutingRequest
     */
    'SetupTimeUnit': string;
    /**
     * 
     * @type {number}
     * @memberof CreateStandardRoutingRequest
     */
    'RunTime': number;
    /**
     * 
     * @type {string}
     * @memberof CreateStandardRoutingRequest
     */
    'RunTimeUnit': string;
    /**
     * 
     * @type {number}
     * @memberof CreateStandardRoutingRequest
     */
    'QueueTime': number;
    /**
     * 
     * @type {string}
     * @memberof CreateStandardRoutingRequest
     */
    'QueueTimeUnit': string;
    /**
     * 
     * @type {number}
     * @memberof CreateStandardRoutingRequest
     */
    'MoveTime': number;
    /**
     * 
     * @type {string}
     * @memberof CreateStandardRoutingRequest
     */
    'MoveimeUnit': string;
    /**
     * 
     * @type {string}
     * @memberof CreateStandardRoutingRequest
     */
    'RevisionStatus': string;
    /**
     * 
     * @type {number}
     * @memberof CreateStandardRoutingRequest
     */
    'RevisionStatusID': number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateStandardRoutingRequest
     */
    'RevisionControl': boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateStandardRoutingRequest
     */
    'VendorID': number;
    /**
     * 
     * @type {string}
     * @memberof CreateStandardRoutingRequest
     */
    'WorkCenterGroup': string;
    /**
     * 
     * @type {number}
     * @memberof CreateStandardRoutingRequest
     */
    'Efficiency': number;
    /**
     * 
     * @type {number}
     * @memberof CreateStandardRoutingRequest
     */
    'WorkCenterID': number;
    /**
     * 
     * @type {number}
     * @memberof CreateStandardRoutingRequest
     */
    'DepartmentID': number;
    /**
     * 
     * @type {string}
     * @memberof CreateStandardRoutingRequest
     */
    'AdditionalDataValues': string;
}
/**
 * 
 * @export
 * @interface CreateStandardRoutingResponse
 */
export interface CreateStandardRoutingResponse {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof CreateStandardRoutingResponse
     */
    'myID': number;
}
/**
 * 
 * @export
 * @interface CreateSupplierContactRequest
 */
export interface CreateSupplierContactRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreateSupplierContactRequest
     */
    'AccountNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierContactRequest
     */
    'DefaultContact': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierContactRequest
     */
    'FirstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierContactRequest
     */
    'LastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierContactRequest
     */
    'ContactTitle': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierContactRequest
     */
    'EmailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierContactRequest
     */
    'OfficeNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierContactRequest
     */
    'MobileNumber': string;
}
/**
 * 
 * @export
 * @interface CreateSupplierContactResponse
 */
export interface CreateSupplierContactResponse {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof CreateSupplierContactResponse
     */
    'ContactID': number;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierContactResponse
     */
    'FirstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierContactResponse
     */
    'LastName': string;
}
/**
 * 
 * @export
 * @interface CreateSupplierRequest
 */
export interface CreateSupplierRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CreateSupplierRequest
     */
    'CompanyCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierRequest
     */
    'AccountNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierRequest
     */
    'Supplier': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierRequest
     */
    'PaymentTerms': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierRequest
     */
    'CreateDate': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierRequest
     */
    'LastModifiedDateTime': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierRequest
     */
    'WebAddress': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierRequest
     */
    'FederalTaxID': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierRequest
     */
    'EmailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierRequest
     */
    'PhoneNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierRequest
     */
    'FaxNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierRequest
     */
    'InternalNotes': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierRequest
     */
    'GLAccountsPayable': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierRequest
     */
    'RemitToAddress1': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierRequest
     */
    'RemitToAddress2': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierRequest
     */
    'RemitToCity': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierRequest
     */
    'RemitToState': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierRequest
     */
    'RemitToZipCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierRequest
     */
    'RemitToCounty': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierRequest
     */
    'RemitToCountry': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierRequest
     */
    'ShipToAddress1': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierRequest
     */
    'ShipToAddress2': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierRequest
     */
    'ShipToCity': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierRequest
     */
    'ShipToState': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierRequest
     */
    'ShipToZipCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierRequest
     */
    'ShipToCounty': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierRequest
     */
    'ShipToCountry': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierRequest
     */
    'ContactFirstName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierRequest
     */
    'ContactLastName': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierRequest
     */
    'ContactTitle': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierRequest
     */
    'ContactEmailAddress': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierRequest
     */
    'ContactPhoneNumber': string;
}
/**
 * 
 * @export
 * @interface CreateSupplierResponse
 */
export interface CreateSupplierResponse {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof CreateSupplierResponse
     */
    'SupplierID': number;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierResponse
     */
    'Supplier': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSupplierResponse
     */
    'AccountNumber': string;
}
/**
 * Used for creating and updating leads, but not the response!
 * @export
 * @interface CreateUpdateLead
 */
export interface CreateUpdateLead {
    [key: string]: any;

    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof CreateUpdateLead
     */
    'properties': Array<AnswerPropertiesInner>;
    /**
     * 
     * @type {number}
     * @memberof CreateUpdateLead
     */
    'id': number;
}
/**
 * Currency
 * @export
 * @interface Currency
 */
export interface Currency {
    [key: string]: any;

    /**
     * s:decimal
     * @type {number}
     * @memberof Currency
     */
    'Rate'?: number;
    /**
     * s:short
     * @type {number}
     * @memberof Currency
     */
    'Unit'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof Currency
     */
    'Symbol'?: string;
}
/**
 * 
 * @export
 * @interface Customer
 */
export interface Customer {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof Customer
     */
    'id': number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof Customer
     */
    'properties': Array<AnswerPropertiesInner>;
    /**
     * 
     * @type {Array<CustomerDeliveredProductInner>}
     * @memberof Customer
     */
    'deliveredProduct'?: Array<CustomerDeliveredProductInner>;
    /**
     * 
     * @type {Array<CustomerOrderDetailInner>}
     * @memberof Customer
     */
    'orderDetail'?: Array<CustomerOrderDetailInner>;
}
/**
 * 
 * @export
 * @interface CustomerDeliveredProductInner
 */
export interface CustomerDeliveredProductInner {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof CustomerDeliveredProductInner
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerDeliveredProductInner
     */
    'parent_id': number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof CustomerDeliveredProductInner
     */
    'properties': Array<AnswerPropertiesInner>;
    /**
     * 
     * @type {Array<CustomerDeliveredProductInnerServiceInner>}
     * @memberof CustomerDeliveredProductInner
     */
    'service'?: Array<CustomerDeliveredProductInnerServiceInner>;
}
/**
 * 
 * @export
 * @interface CustomerDeliveredProductInnerServiceInner
 */
export interface CustomerDeliveredProductInnerServiceInner {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof CustomerDeliveredProductInnerServiceInner
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerDeliveredProductInnerServiceInner
     */
    'parent_id': number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof CustomerDeliveredProductInnerServiceInner
     */
    'properties': Array<AnswerPropertiesInner>;
}
/**
 * 
 * @export
 * @interface CustomerListRequest
 */
export interface CustomerListRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof CustomerListRequest
     */
    'CustomerName'?: string;
}
/**
 * 
 * @export
 * @interface CustomerListResponse
 */
export interface CustomerListResponse extends Array<CustomerListResponseInner> {
}
/**
 * 
 * @export
 * @interface CustomerListResponseInner
 */
export interface CustomerListResponseInner {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof CustomerListResponseInner
     */
    'CompanyID': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerListResponseInner
     */
    'AccountNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerListResponseInner
     */
    'CompanyName': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerListResponseInner
     */
    'Address': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerListResponseInner
     */
    'City': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerListResponseInner
     */
    'State': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerListResponseInner
     */
    'PostalCode': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerListResponseInner
     */
    'Country': string;
    /**
     * 
     * @type {number}
     * @memberof CustomerListResponseInner
     */
    'CompanyType': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerListResponseInner
     */
    'StatusID': number;
}
/**
 * 
 * @export
 * @interface CustomerListResponseItem
 */
export interface CustomerListResponseItem {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof CustomerListResponseItem
     */
    'CompanyID': number;
    /**
     * 
     * @type {string}
     * @memberof CustomerListResponseItem
     */
    'AccountNumber': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerListResponseItem
     */
    'CompanyName': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerListResponseItem
     */
    'Address': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerListResponseItem
     */
    'City': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerListResponseItem
     */
    'State': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerListResponseItem
     */
    'PostalCode': string;
    /**
     * 
     * @type {string}
     * @memberof CustomerListResponseItem
     */
    'Country': string;
    /**
     * 
     * @type {number}
     * @memberof CustomerListResponseItem
     */
    'CompanyType': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerListResponseItem
     */
    'StatusID': number;
}
/**
 * 
 * @export
 * @interface CustomerOrderDetailInner
 */
export interface CustomerOrderDetailInner {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof CustomerOrderDetailInner
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof CustomerOrderDetailInner
     */
    'parent_id': number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof CustomerOrderDetailInner
     */
    'properties': Array<AnswerPropertiesInner>;
    /**
     * 
     * @type {Array<CustomerDeliveredProductInnerServiceInner>}
     * @memberof CustomerOrderDetailInner
     */
    'orderLine'?: Array<CustomerDeliveredProductInnerServiceInner>;
}
/**
 * 
 * @export
 * @enum {number}
 */

export const CustomerPropertyType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_16: 16,
    NUMBER_17: 17,
    NUMBER_18: 18,
    NUMBER_19: 19
} as const;

export type CustomerPropertyType = typeof CustomerPropertyType[keyof typeof CustomerPropertyType];


/**
 * 
 * @export
 * @interface DB
 */
export interface DB {
    [key: string]: any;

    /**
     * 
     * @type {Array<DBOrderInner>}
     * @memberof DB
     */
    'order': Array<DBOrderInner>;
    /**
     * 
     * @type {Array<ItemProductInnerChecklistInner>}
     * @memberof DB
     */
    'checklist': Array<ItemProductInnerChecklistInner>;
    /**
     * 
     * @type {Array<DBUserInner>}
     * @memberof DB
     */
    'user': Array<DBUserInner>;
    /**
     * 
     * @type {Array<ChecklistQuestionInner>}
     * @memberof DB
     */
    'question': Array<ChecklistQuestionInner>;
    /**
     * 
     * @type {Array<ItemProductInner>}
     * @memberof DB
     */
    'product': Array<ItemProductInner>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DB
     */
    'questiontype': Array<DBQuestiontypeEnum>;
    /**
     * 
     * @type {string}
     * @memberof DB
     */
    'submenu': string;
}

export const DBQuestiontypeEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_16: 16,
    NUMBER_17: 17,
    NUMBER_18: 18,
    NUMBER_19: 19,
    NUMBER_20: 20,
    NUMBER_21: 21,
    NUMBER_22: 22,
    NUMBER_23: 23,
    NUMBER_24: 24,
    NUMBER_25: 25,
    NUMBER_26: 26,
    NUMBER_27: 27,
    NUMBER_28: 28,
    NUMBER_29: 29,
    NUMBER_30: 30,
    NUMBER_31: 31
} as const;

export type DBQuestiontypeEnum = typeof DBQuestiontypeEnum[keyof typeof DBQuestiontypeEnum];

/**
 * 
 * @export
 * @interface DBOrderInner
 */
export interface DBOrderInner {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof DBOrderInner
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof DBOrderInner
     */
    'parent_id'?: number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof DBOrderInner
     */
    'properties': Array<AnswerPropertiesInner>;
    /**
     * 
     * @type {Array<OrderItemInner>}
     * @memberof DBOrderInner
     */
    'item': Array<OrderItemInner>;
}
/**
 * 
 * @export
 * @interface DBUserInner
 */
export interface DBUserInner {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof DBUserInner
     */
    'id': number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof DBUserInner
     */
    'properties': Array<AnswerPropertiesInner>;
    /**
     * 
     * @type {Array<CustomerDeliveredProductInner>}
     * @memberof DBUserInner
     */
    'deliveredProduct'?: Array<CustomerDeliveredProductInner>;
    /**
     * 
     * @type {Array<CustomerOrderDetailInner>}
     * @memberof DBUserInner
     */
    'orderDetail'?: Array<CustomerOrderDetailInner>;
}
/**
 * 
 * @export
 * @interface Dataset
 */
export interface Dataset {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof Dataset
     */
    'orderid': string;
    /**
     * 
     * @type {string}
     * @memberof Dataset
     */
    'itemid': string;
    /**
     * 
     * @type {string}
     * @memberof Dataset
     */
    'productid': string;
    /**
     * 
     * @type {string}
     * @memberof Dataset
     */
    'checklistid': string;
    /**
     * 
     * @type {string}
     * @memberof Dataset
     */
    'checklist': string;
    /**
     * 
     * @type {string}
     * @memberof Dataset
     */
    'questionid': string;
}
/**
 * 
 * @export
 * @interface DateCommand
 */
export interface DateCommand {
    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof DateCommand
     */
    'startTime'?: InstallItemUsageAlternativeCreateInputCachedAt;
    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof DateCommand
     */
    'endTime'?: InstallItemUsageAlternativeCreateInputCachedAt;
}
/**
 * 
 * @export
 * @interface DateOptions
 */
export interface DateOptions {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof DateOptions
     */
    'startTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof DateOptions
     */
    'endTime'?: string;
}
/**
 * 
 * @export
 * @interface DbSmsOptions
 */
export interface DbSmsOptions {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof DbSmsOptions
     */
    'from': DbSmsOptionsFromEnum;
    /**
     * 
     * @type {number}
     * @memberof DbSmsOptions
     */
    'cpproOrderId'?: number;
    /**
     * 
     * @type {string}
     * @memberof DbSmsOptions
     */
    'type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DbSmsOptions
     */
    'retry'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DbSmsOptions
     */
    'reply'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DbSmsOptions
     */
    'deleteDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DbSmsOptions
     */
    'ignoreDeleted'?: boolean;
    /**
     * This is ignored using `reply: true`, and `to` is an array of `{ phone: string, customerId: number }`
     * @type {number}
     * @memberof DbSmsOptions
     */
    'customerId'?: number;
}

export const DbSmsOptionsFromEnum = {
    ACsenteret: 'ACsenteret',
    FjKlima: 'FJ Klima'
} as const;

export type DbSmsOptionsFromEnum = typeof DbSmsOptionsFromEnum[keyof typeof DbSmsOptionsFromEnum];

/**
 * 
 * @export
 * @interface DeliveredProduct
 */
export interface DeliveredProduct {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof DeliveredProduct
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof DeliveredProduct
     */
    'parent_id': number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof DeliveredProduct
     */
    'properties': Array<AnswerPropertiesInner>;
    /**
     * 
     * @type {Array<CustomerDeliveredProductInnerServiceInner>}
     * @memberof DeliveredProduct
     */
    'service'?: Array<CustomerDeliveredProductInnerServiceInner>;
}
/**
 * 
 * @export
 * @enum {number}
 */

export const DeliveredProductPropertyType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13,
    NUMBER_14: 14
} as const;

export type DeliveredProductPropertyType = typeof DeliveredProductPropertyType[keyof typeof DeliveredProductPropertyType];


/**
 * DeliveryMethod
 * @export
 * @interface DeliveryMethod
 */
export interface DeliveryMethod {
    [key: string]: any;

    /**
     * s:int
     * @type {number}
     * @memberof DeliveryMethod
     */
    'Id'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof DeliveryMethod
     */
    'Description'?: string;
}
/**
 * 
 * @export
 * @interface DepartmentStock
 */
export interface DepartmentStock {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof DepartmentStock
     */
    'department': string;
    /**
     * 
     * @type {number}
     * @memberof DepartmentStock
     */
    'stock': number;
}
/**
 * 
 * @export
 * @interface DepartmentZipArea
 */
export interface DepartmentZipArea {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof DepartmentZipArea
     */
    'department': string;
    /**
     * 
     * @type {Array<DepartmentZipAreaZipAreasInner>}
     * @memberof DepartmentZipArea
     */
    'zipAreas': Array<DepartmentZipAreaZipAreasInner>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DepartmentZipArea
     */
    'zips': Array<number>;
}
/**
 * 
 * @export
 * @interface DepartmentZipAreaZipAreasInner
 */
export interface DepartmentZipAreaZipAreasInner {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof DepartmentZipAreaZipAreasInner
     */
    'from': number;
    /**
     * 
     * @type {number}
     * @memberof DepartmentZipAreaZipAreasInner
     */
    'to': number;
}
/**
 * 
 * @export
 * @interface Duration
 */
export interface Duration {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof Duration
     */
    'seconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof Duration
     */
    'nanos'?: number;
}
/**
 * 
 * @export
 * @interface EndpointTask
 */
export interface EndpointTask {
    [key: string]: any;

    /**
     * 
     * @type {QueueWithTasksTasksInner}
     * @memberof EndpointTask
     */
    'task': QueueWithTasksTasksInner;
    /**
     * 
     * @type {string}
     * @memberof EndpointTask
     */
    'orderLink': string;
}
/**
 * 
 * @export
 * @interface ErpSyncOrderWithLinkAndTasks
 */
export interface ErpSyncOrderWithLinkAndTasks {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ErpSyncOrderWithLinkAndTasks
     */
    'error': string;
    /**
     * 
     * @type {number}
     * @memberof ErpSyncOrderWithLinkAndTasks
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof ErpSyncOrderWithLinkAndTasks
     */
    'deliveryDate': string;
    /**
     * 
     * @type {string}
     * @memberof ErpSyncOrderWithLinkAndTasks
     */
    'orderType': ErpSyncOrderWithLinkAndTasksOrderTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ErpSyncOrderWithLinkAndTasks
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof ErpSyncOrderWithLinkAndTasks
     */
    'orderId': number;
    /**
     * 
     * @type {number}
     * @memberof ErpSyncOrderWithLinkAndTasks
     */
    'fjErpId': number;
    /**
     * 
     * @type {number}
     * @memberof ErpSyncOrderWithLinkAndTasks
     */
    'cpproId': number;
    /**
     * 
     * @type {number}
     * @memberof ErpSyncOrderWithLinkAndTasks
     */
    'cpproInstallId': number;
    /**
     * 
     * @type {number}
     * @memberof ErpSyncOrderWithLinkAndTasks
     */
    'acErpId': number;
    /**
     * 
     * @type {number}
     * @memberof ErpSyncOrderWithLinkAndTasks
     */
    'mrpSaleId': number;
    /**
     * 
     * @type {string}
     * @memberof ErpSyncOrderWithLinkAndTasks
     */
    'mrpShipId': string;
    /**
     * 
     * @type {string}
     * @memberof ErpSyncOrderWithLinkAndTasks
     */
    'mrpInvoiceId': string;
    /**
     * 
     * @type {string}
     * @memberof ErpSyncOrderWithLinkAndTasks
     */
    'mrpInvoiceDate': string;
    /**
     * 
     * @type {number}
     * @memberof ErpSyncOrderWithLinkAndTasks
     */
    'finalErpId': number;
    /**
     * 
     * @type {string}
     * @memberof ErpSyncOrderWithLinkAndTasks
     */
    'acOrderStatus': string;
    /**
     * 
     * @type {number}
     * @memberof ErpSyncOrderWithLinkAndTasks
     */
    'acErpCustomerId': number;
    /**
     * 
     * @type {string}
     * @memberof ErpSyncOrderWithLinkAndTasks
     */
    'fjOrderStatus': string;
    /**
     * 
     * @type {string}
     * @memberof ErpSyncOrderWithLinkAndTasks
     */
    'finalFJOrderStatus': string;
    /**
     * 
     * @type {boolean}
     * @memberof ErpSyncOrderWithLinkAndTasks
     */
    'manualInvoicing': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ErpSyncOrderWithLinkAndTasks
     */
    'acErpInvoiced': boolean;
    /**
     * 
     * @type {string}
     * @memberof ErpSyncOrderWithLinkAndTasks
     */
    'acErpInvoiceDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof ErpSyncOrderWithLinkAndTasks
     */
    'fjErpInvoiced': boolean;
    /**
     * 
     * @type {string}
     * @memberof ErpSyncOrderWithLinkAndTasks
     */
    'fJErpInvoiceDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof ErpSyncOrderWithLinkAndTasks
     */
    'isACT': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ErpSyncOrderWithLinkAndTasks
     */
    'test': boolean;
    /**
     * 
     * @type {string}
     * @memberof ErpSyncOrderWithLinkAndTasks
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof ErpSyncOrderWithLinkAndTasks
     */
    'salesOrderLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof ErpSyncOrderWithLinkAndTasks
     */
    'installOrderLink'?: string;
    /**
     * 
     * @type {Array<QueueWithTasksTasksInner>}
     * @memberof ErpSyncOrderWithLinkAndTasks
     */
    'tasks': Array<QueueWithTasksTasksInner>;
}

export const ErpSyncOrderWithLinkAndTasksOrderTypeEnum = {
    CreditSale: 'CREDIT_SALE',
    Install: 'INSTALL',
    Partnerprog: 'PARTNERPROG',
    PartnerprogInternal: 'PARTNERPROG_INTERNAL',
    Repair: 'REPAIR',
    Service: 'SERVICE',
    Warranty: 'WARRANTY',
    WarrantyCreditsale: 'WARRANTY_CREDITSALE',
    WarrantyService: 'WARRANTY_SERVICE'
} as const;

export type ErpSyncOrderWithLinkAndTasksOrderTypeEnum = typeof ErpSyncOrderWithLinkAndTasksOrderTypeEnum[keyof typeof ErpSyncOrderWithLinkAndTasksOrderTypeEnum];

/**
 * 
 * @export
 * @interface ErrorConstantResponse
 */
export interface ErrorConstantResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorConstantResponse
     */
    'error': string;
}
/**
 * 
 * @export
 * @enum {number}
 */

export const FilePropertyType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;

export type FilePropertyType = typeof FilePropertyType[keyof typeof FilePropertyType];


/**
 * 
 * @export
 * @interface GetCRMCustomerLocksResponse
 */
export interface GetCRMCustomerLocksResponse {
    [key: string]: any;

    /**
     * 
     * @type {Array<CRMCustomerLockErrorResponseLock>}
     * @memberof GetCRMCustomerLocksResponse
     */
    'locks': Array<CRMCustomerLockErrorResponseLock>;
    /**
     * 
     * @type {Array<CRMCustomerLockErrorResponseLock>}
     * @memberof GetCRMCustomerLocksResponse
     */
    'agentLocks': Array<CRMCustomerLockErrorResponseLock>;
}
/**
 * 
 * @export
 * @interface GetCRMSMSTemplatesResponse
 */
export interface GetCRMSMSTemplatesResponse extends Array<GetCRMSMSTemplatesResponseInner> {
}
/**
 * 
 * @export
 * @interface GetCRMSMSTemplatesResponseInner
 */
export interface GetCRMSMSTemplatesResponseInner {
    /**
     * 
     * @type {string}
     * @memberof GetCRMSMSTemplatesResponseInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetCRMSMSTemplatesResponseInner
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface GetConstantResponse
 */
export interface GetConstantResponse {
    [key: string]: any;

    /**
     * 
     * @type {GetConstantResponseConstant}
     * @memberof GetConstantResponse
     */
    'constant': GetConstantResponseConstant;
}
/**
 * 
 * @export
 * @interface GetConstantResponseConstant
 */
export interface GetConstantResponseConstant {
    /**
     * 
     * @type {boolean}
     * @memberof GetConstantResponseConstant
     */
    'isUsed'?: boolean;
    /**
     * 
     * @type {APIConstantExpectedType}
     * @memberof GetConstantResponseConstant
     */
    'expectedType'?: APIConstantExpectedType;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetConstantResponseConstant
     */
    'expectedProps'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetConstantResponseConstant
     */
    'key': string;
    /**
     * From https://github.com/sindresorhus/type-fest/ Matches any valid JSON value.
     * @type {unknown}
     * @memberof GetConstantResponseConstant
     */
    'json': unknown;
    /**
     * 
     * @type {number}
     * @memberof GetConstantResponseConstant
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof GetConstantResponseConstant
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof GetConstantResponseConstant
     */
    'type': GetConstantResponseConstantTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetConstantResponseConstant
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof GetConstantResponseConstant
     */
    'version': number;
    /**
     * 
     * @type {string}
     * @memberof GetConstantResponseConstant
     */
    'createdBy': string;
    /**
     * 
     * @type {string}
     * @memberof GetConstantResponseConstant
     */
    'disabledAt': string;
    /**
     * 
     * @type {string}
     * @memberof GetConstantResponseConstant
     */
    'disabledBy': string;
}

export const GetConstantResponseConstantTypeEnum = {
    Boolean: 'BOOLEAN',
    Number: 'NUMBER',
    Numberarray: 'NUMBERARRAY',
    Object: 'OBJECT',
    Objectarray: 'OBJECTARRAY',
    String: 'STRING',
    Stringarray: 'STRINGARRAY'
} as const;

export type GetConstantResponseConstantTypeEnum = typeof GetConstantResponseConstantTypeEnum[keyof typeof GetConstantResponseConstantTypeEnum];

/**
 * 
 * @export
 * @interface GetConstantsResponse
 */
export interface GetConstantsResponse {
    [key: string]: any;

    /**
     * 
     * @type {Array<GetConstantsResponseConstantsInner>}
     * @memberof GetConstantsResponse
     */
    'constants': Array<GetConstantsResponseConstantsInner>;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetConstantsResponse
     */
    'keys': Array<string>;
    /**
     * 
     * @type {Array<GetConstantsResponseRecordsInner>}
     * @memberof GetConstantsResponse
     */
    'records': Array<GetConstantsResponseRecordsInner>;
}
/**
 * 
 * @export
 * @interface GetConstantsResponseConstantsInner
 */
export interface GetConstantsResponseConstantsInner {
    /**
     * 
     * @type {boolean}
     * @memberof GetConstantsResponseConstantsInner
     */
    'isUsed'?: boolean;
    /**
     * 
     * @type {APIConstantExpectedType}
     * @memberof GetConstantsResponseConstantsInner
     */
    'expectedType'?: APIConstantExpectedType;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetConstantsResponseConstantsInner
     */
    'expectedProps'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetConstantsResponseConstantsInner
     */
    'key': string;
    /**
     * From https://github.com/sindresorhus/type-fest/ Matches any valid JSON value.
     * @type {unknown}
     * @memberof GetConstantsResponseConstantsInner
     */
    'json': unknown;
    /**
     * 
     * @type {number}
     * @memberof GetConstantsResponseConstantsInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof GetConstantsResponseConstantsInner
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof GetConstantsResponseConstantsInner
     */
    'type': GetConstantsResponseConstantsInnerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetConstantsResponseConstantsInner
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof GetConstantsResponseConstantsInner
     */
    'version': number;
    /**
     * 
     * @type {string}
     * @memberof GetConstantsResponseConstantsInner
     */
    'createdBy': string;
    /**
     * 
     * @type {string}
     * @memberof GetConstantsResponseConstantsInner
     */
    'disabledAt': string;
    /**
     * 
     * @type {string}
     * @memberof GetConstantsResponseConstantsInner
     */
    'disabledBy': string;
}

export const GetConstantsResponseConstantsInnerTypeEnum = {
    Boolean: 'BOOLEAN',
    Number: 'NUMBER',
    Numberarray: 'NUMBERARRAY',
    Object: 'OBJECT',
    Objectarray: 'OBJECTARRAY',
    String: 'STRING',
    Stringarray: 'STRINGARRAY'
} as const;

export type GetConstantsResponseConstantsInnerTypeEnum = typeof GetConstantsResponseConstantsInnerTypeEnum[keyof typeof GetConstantsResponseConstantsInnerTypeEnum];

/**
 * 
 * @export
 * @interface GetConstantsResponseRecordsInner
 */
export interface GetConstantsResponseRecordsInner {
    /**
     * 
     * @type {string}
     * @memberof GetConstantsResponseRecordsInner
     */
    'key': string;
    /**
     * 
     * @type {GetConstantsResponseRecordsInnerProps}
     * @memberof GetConstantsResponseRecordsInner
     */
    'props': GetConstantsResponseRecordsInnerProps;
}
/**
 * 
 * @export
 * @interface GetConstantsResponseRecordsInnerProps
 */
export interface GetConstantsResponseRecordsInnerProps {
    /**
     * 
     * @type {string}
     * @memberof GetConstantsResponseRecordsInnerProps
     */
    'type': GetConstantsResponseRecordsInnerPropsTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetConstantsResponseRecordsInnerProps
     */
    'dynamicProperties'?: Array<string>;
}

export const GetConstantsResponseRecordsInnerPropsTypeEnum = {
    Boolean: 'BOOLEAN',
    Null: 'NULL',
    Number: 'NUMBER',
    Numberarray: 'NUMBERARRAY',
    Object: 'OBJECT',
    Objectarray: 'OBJECTARRAY',
    String: 'STRING',
    Stringarray: 'STRINGARRAY'
} as const;

export type GetConstantsResponseRecordsInnerPropsTypeEnum = typeof GetConstantsResponseRecordsInnerPropsTypeEnum[keyof typeof GetConstantsResponseRecordsInnerPropsTypeEnum];

/**
 * GetDeliveryMethods
 * @export
 * @interface GetDeliveryMethods
 */
export interface GetDeliveryMethods {
    [key: string]: any;

}
/**
 * GetDeliveryMethodsResponse
 * @export
 * @interface GetDeliveryMethodsResponse
 */
export interface GetDeliveryMethodsResponse {
    [key: string]: any;

    /**
     * 
     * @type {GetDeliveryMethodsResponseGetDeliveryMethodsResult}
     * @memberof GetDeliveryMethodsResponse
     */
    'GetDeliveryMethodsResult'?: GetDeliveryMethodsResponseGetDeliveryMethodsResult;
}
/**
 * GetDeliveryMethodsResult
 * @export
 * @interface GetDeliveryMethodsResponseGetDeliveryMethodsResult
 */
export interface GetDeliveryMethodsResponseGetDeliveryMethodsResult {
    [key: string]: any;

    /**
     * DeliveryMethod[]
     * @type {Array<GetDeliveryMethodsResponseGetDeliveryMethodsResultDeliveryMethodInner>}
     * @memberof GetDeliveryMethodsResponseGetDeliveryMethodsResult
     */
    'DeliveryMethod'?: Array<GetDeliveryMethodsResponseGetDeliveryMethodsResultDeliveryMethodInner>;
}
/**
 * DeliveryMethod
 * @export
 * @interface GetDeliveryMethodsResponseGetDeliveryMethodsResultDeliveryMethodInner
 */
export interface GetDeliveryMethodsResponseGetDeliveryMethodsResultDeliveryMethodInner {
    [key: string]: any;

    /**
     * s:int
     * @type {number}
     * @memberof GetDeliveryMethodsResponseGetDeliveryMethodsResultDeliveryMethodInner
     */
    'Id'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof GetDeliveryMethodsResponseGetDeliveryMethodsResultDeliveryMethodInner
     */
    'Description'?: string;
}
/**
 * GetDeliveryMethodsResult
 * @export
 * @interface GetDeliveryMethodsResult
 */
export interface GetDeliveryMethodsResult {
    [key: string]: any;

    /**
     * DeliveryMethod[]
     * @type {Array<GetDeliveryMethodsResponseGetDeliveryMethodsResultDeliveryMethodInner>}
     * @memberof GetDeliveryMethodsResult
     */
    'DeliveryMethod'?: Array<GetDeliveryMethodsResponseGetDeliveryMethodsResultDeliveryMethodInner>;
}
/**
 * 
 * @export
 * @interface GetDepartmentZipAreasResponse
 */
export interface GetDepartmentZipAreasResponse extends Array<GetDepartmentZipAreasResponseInner> {
}
/**
 * 
 * @export
 * @interface GetDepartmentZipAreasResponseInner
 */
export interface GetDepartmentZipAreasResponseInner {
    /**
     * 
     * @type {string}
     * @memberof GetDepartmentZipAreasResponseInner
     */
    'department': string;
    /**
     * 
     * @type {Array<GetDepartmentZipAreasResponseInnerZipAreasInner>}
     * @memberof GetDepartmentZipAreasResponseInner
     */
    'zipAreas': Array<GetDepartmentZipAreasResponseInnerZipAreasInner>;
    /**
     * 
     * @type {Array<number>}
     * @memberof GetDepartmentZipAreasResponseInner
     */
    'zips': Array<number>;
}
/**
 * 
 * @export
 * @interface GetDepartmentZipAreasResponseInnerZipAreasInner
 */
export interface GetDepartmentZipAreasResponseInnerZipAreasInner {
    /**
     * 
     * @type {number}
     * @memberof GetDepartmentZipAreasResponseInnerZipAreasInner
     */
    'from': number;
    /**
     * 
     * @type {number}
     * @memberof GetDepartmentZipAreasResponseInnerZipAreasInner
     */
    'to': number;
}
/**
 * 
 * @export
 * @interface GetEmailsCommand
 */
export interface GetEmailsCommand {
    /**
     * 
     * @type {string}
     * @memberof GetEmailsCommand
     */
    'status'?: GetEmailsCommandStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetEmailsCommand
     */
    'to'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetEmailsCommand
     */
    'from'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetEmailsCommand
     */
    'orderId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetEmailsCommand
     */
    'type'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetEmailsCommand
     */
    'retriesMoreThan'?: number;
    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof GetEmailsCommand
     */
    'startTime'?: InstallItemUsageAlternativeCreateInputCachedAt;
    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof GetEmailsCommand
     */
    'endTime'?: InstallItemUsageAlternativeCreateInputCachedAt;
}

export const GetEmailsCommandStatusEnum = {
    ApiError: 'API_ERROR',
    Error: 'ERROR',
    Resent: 'RESENT',
    Sent: 'SENT',
    Unsent: 'UNSENT'
} as const;

export type GetEmailsCommandStatusEnum = typeof GetEmailsCommandStatusEnum[keyof typeof GetEmailsCommandStatusEnum];

/**
 * 
 * @export
 * @interface GetEmailsResponse
 */
export interface GetEmailsResponse extends Array<GetEmailsResponseInner> {
}
/**
 * 
 * @export
 * @interface GetEmailsResponseInner
 */
export interface GetEmailsResponseInner {
    /**
     * 
     * @type {string}
     * @memberof GetEmailsResponseInner
     */
    'error': string;
    /**
     * 
     * @type {number}
     * @memberof GetEmailsResponseInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof GetEmailsResponseInner
     */
    'status': GetEmailsResponseInnerStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof GetEmailsResponseInner
     */
    'orderId': number;
    /**
     * 
     * @type {string}
     * @memberof GetEmailsResponseInner
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmailsResponseInner
     */
    'sent': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmailsResponseInner
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmailsResponseInner
     */
    'hash': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmailsResponseInner
     */
    'to': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmailsResponseInner
     */
    'cc': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmailsResponseInner
     */
    'bcc': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmailsResponseInner
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmailsResponseInner
     */
    'subject': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmailsResponseInner
     */
    'replyTo': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmailsResponseInner
     */
    'inReplyTo': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmailsResponseInner
     */
    'references': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmailsResponseInner
     */
    'deleteDate': string;
    /**
     * 
     * @type {number}
     * @memberof GetEmailsResponseInner
     */
    'retries': number;
    /**
     * 
     * @type {boolean}
     * @memberof GetEmailsResponseInner
     */
    'retry': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetEmailsResponseInner
     */
    'messageId': string;
    /**
     * 
     * @type {string}
     * @memberof GetEmailsResponseInner
     */
    'threadId': string;
    /**
     * 
     * @type {object}
     * @memberof GetEmailsResponseInner
     */
    'attachments': object;
}

export const GetEmailsResponseInnerStatusEnum = {
    ApiError: 'API_ERROR',
    Error: 'ERROR',
    Resent: 'RESENT',
    Sent: 'SENT',
    Unsent: 'UNSENT'
} as const;

export type GetEmailsResponseInnerStatusEnum = typeof GetEmailsResponseInnerStatusEnum[keyof typeof GetEmailsResponseInnerStatusEnum];

/**
 * 
 * @export
 * @interface GetErpErrorRequestQuery
 */
export interface GetErpErrorRequestQuery {
    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof GetErpErrorRequestQuery
     */
    'startTime'?: InstallItemUsageAlternativeCreateInputCachedAt;
    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof GetErpErrorRequestQuery
     */
    'endTime'?: InstallItemUsageAlternativeCreateInputCachedAt;
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof GetErpErrorRequestQuery
     */
    'cpproId'?: ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof GetErpErrorRequestQuery
     */
    'mrpId'?: ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof GetErpErrorRequestQuery
     */
    'acId'?: ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {boolean}
     * @memberof GetErpErrorRequestQuery
     */
    'isACT'?: boolean;
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof GetErpErrorRequestQuery
     */
    'fjId'?: ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof GetErpErrorRequestQuery
     */
    'fjManualOrder'?: ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof GetErpErrorRequestQuery
     */
    'extraACOrder'?: ItemListResponseItemAdditionalDataValuesInnerID;
}
/**
 * 
 * @export
 * @interface GetErpErrorResponse
 */
export interface GetErpErrorResponse {
    [key: string]: any;

    /**
     * 
     * @type {Array<GetErpErrorResponseErpErrorsInner>}
     * @memberof GetErpErrorResponse
     */
    'erpErrors': Array<GetErpErrorResponseErpErrorsInner>;
}
/**
 * 
 * @export
 * @interface GetErpErrorResponseErpErrorsInner
 */
export interface GetErpErrorResponseErpErrorsInner {
    /**
     * From https://github.com/sindresorhus/type-fest/ Matches any valid JSON value.
     * @type {unknown}
     * @memberof GetErpErrorResponseErpErrorsInner
     */
    'metadata': unknown;
    /**
     * 
     * @type {number}
     * @memberof GetErpErrorResponseErpErrorsInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof GetErpErrorResponseErpErrorsInner
     */
    'orderId': string;
    /**
     * 
     * @type {string}
     * @memberof GetErpErrorResponseErpErrorsInner
     */
    'type': GetErpErrorResponseErpErrorsInnerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetErpErrorResponseErpErrorsInner
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof GetErpErrorResponseErpErrorsInner
     */
    'orderIdType': GetErpErrorResponseErpErrorsInnerOrderIdTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetErpErrorResponseErpErrorsInner
     */
    'cause': string;
    /**
     * 
     * @type {string}
     * @memberof GetErpErrorResponseErpErrorsInner
     */
    'errorStack': string;
    /**
     * 
     * @type {string}
     * @memberof GetErpErrorResponseErpErrorsInner
     */
    'errorCode': string;
    /**
     * 
     * @type {string}
     * @memberof GetErpErrorResponseErpErrorsInner
     */
    'apiUrl': string;
    /**
     * 
     * @type {string}
     * @memberof GetErpErrorResponseErpErrorsInner
     */
    'requestUrl': string;
    /**
     * 
     * @type {string}
     * @memberof GetErpErrorResponseErpErrorsInner
     */
    'taskId': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetErpErrorResponseErpErrorsInner
     */
    'handled': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetErpErrorResponseErpErrorsInner
     */
    'handledAt': string;
    /**
     * 
     * @type {string}
     * @memberof GetErpErrorResponseErpErrorsInner
     */
    'timestamp': string;
}

export const GetErpErrorResponseErpErrorsInnerTypeEnum = {
    Error: 'ERROR',
    Info: 'INFO',
    Warning: 'WARNING'
} as const;

export type GetErpErrorResponseErpErrorsInnerTypeEnum = typeof GetErpErrorResponseErpErrorsInnerTypeEnum[keyof typeof GetErpErrorResponseErpErrorsInnerTypeEnum];
export const GetErpErrorResponseErpErrorsInnerOrderIdTypeEnum = {
    Ac: 'AC',
    Act: 'ACT',
    Cppro: 'CPPRO',
    ExtraAc: 'EXTRA_AC',
    Fj: 'FJ',
    FjManual: 'FJ_MANUAL',
    Mrp: 'MRP',
    Unknown: 'UNKNOWN'
} as const;

export type GetErpErrorResponseErpErrorsInnerOrderIdTypeEnum = typeof GetErpErrorResponseErpErrorsInnerOrderIdTypeEnum[keyof typeof GetErpErrorResponseErpErrorsInnerOrderIdTypeEnum];

/**
 * 
 * @export
 * @interface GetErpSyncOrderCommand
 */
export interface GetErpSyncOrderCommand {
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrderCommand
     */
    'saleOrder'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrderCommand
     */
    'installOrder'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrderCommand
     */
    'saleOrderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrderCommand
     */
    'installorderId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrderCommand
     */
    'acErpId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrderCommand
     */
    'fjErpId'?: string;
}
/**
 * 
 * @export
 * @interface GetErpSyncOrderResponse
 */
export interface GetErpSyncOrderResponse {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrderResponse
     */
    'error': string;
    /**
     * 
     * @type {number}
     * @memberof GetErpSyncOrderResponse
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrderResponse
     */
    'deliveryDate': string;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrderResponse
     */
    'orderType': GetErpSyncOrderResponseOrderTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrderResponse
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof GetErpSyncOrderResponse
     */
    'orderId': number;
    /**
     * 
     * @type {number}
     * @memberof GetErpSyncOrderResponse
     */
    'fjErpId': number;
    /**
     * 
     * @type {number}
     * @memberof GetErpSyncOrderResponse
     */
    'cpproId': number;
    /**
     * 
     * @type {number}
     * @memberof GetErpSyncOrderResponse
     */
    'cpproInstallId': number;
    /**
     * 
     * @type {number}
     * @memberof GetErpSyncOrderResponse
     */
    'acErpId': number;
    /**
     * 
     * @type {number}
     * @memberof GetErpSyncOrderResponse
     */
    'mrpSaleId': number;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrderResponse
     */
    'mrpShipId': string;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrderResponse
     */
    'mrpInvoiceId': string;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrderResponse
     */
    'mrpInvoiceDate': string;
    /**
     * 
     * @type {number}
     * @memberof GetErpSyncOrderResponse
     */
    'finalErpId': number;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrderResponse
     */
    'acOrderStatus': string;
    /**
     * 
     * @type {number}
     * @memberof GetErpSyncOrderResponse
     */
    'acErpCustomerId': number;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrderResponse
     */
    'fjOrderStatus': string;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrderResponse
     */
    'finalFJOrderStatus': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetErpSyncOrderResponse
     */
    'manualInvoicing': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetErpSyncOrderResponse
     */
    'acErpInvoiced': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrderResponse
     */
    'acErpInvoiceDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetErpSyncOrderResponse
     */
    'fjErpInvoiced': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrderResponse
     */
    'fJErpInvoiceDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetErpSyncOrderResponse
     */
    'isACT': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetErpSyncOrderResponse
     */
    'test': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrderResponse
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrderResponse
     */
    'salesOrderLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrderResponse
     */
    'installOrderLink'?: string;
    /**
     * 
     * @type {Array<GetErpSyncOrderResponseMrpPickListInner>}
     * @memberof GetErpSyncOrderResponse
     */
    'mrpPickList': Array<GetErpSyncOrderResponseMrpPickListInner>;
}

export const GetErpSyncOrderResponseOrderTypeEnum = {
    CreditSale: 'CREDIT_SALE',
    Install: 'INSTALL',
    Partnerprog: 'PARTNERPROG',
    PartnerprogInternal: 'PARTNERPROG_INTERNAL',
    Repair: 'REPAIR',
    Service: 'SERVICE',
    Warranty: 'WARRANTY',
    WarrantyCreditsale: 'WARRANTY_CREDITSALE',
    WarrantyService: 'WARRANTY_SERVICE'
} as const;

export type GetErpSyncOrderResponseOrderTypeEnum = typeof GetErpSyncOrderResponseOrderTypeEnum[keyof typeof GetErpSyncOrderResponseOrderTypeEnum];

/**
 * 
 * @export
 * @interface GetErpSyncOrderResponseMrpPickListInner
 */
export interface GetErpSyncOrderResponseMrpPickListInner {
    /**
     * 
     * @type {number}
     * @memberof GetErpSyncOrderResponseMrpPickListInner
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof GetErpSyncOrderResponseMrpPickListInner
     */
    'erpSyncOrderId': number;
    /**
     * 
     * @type {number}
     * @memberof GetErpSyncOrderResponseMrpPickListInner
     */
    'pickListId': number;
    /**
     * 
     * @type {number}
     * @memberof GetErpSyncOrderResponseMrpPickListInner
     */
    'lineItemId': number;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrderResponseMrpPickListInner
     */
    'identifiersPicked': string;
}
/**
 * 
 * @export
 * @interface GetErpSyncOrdersResponse
 */
export interface GetErpSyncOrdersResponse {
    [key: string]: any;

    /**
     * 
     * @type {Array<GetErpSyncOrdersResponseErpSyncOrdersInner>}
     * @memberof GetErpSyncOrdersResponse
     */
    'erpSyncOrders': Array<GetErpSyncOrdersResponseErpSyncOrdersInner>;
    /**
     * 
     * @type {Array<GetErpSyncOrdersResponseEndpointTasksInner>}
     * @memberof GetErpSyncOrdersResponse
     */
    'endpointTasks': Array<GetErpSyncOrdersResponseEndpointTasksInner>;
}
/**
 * 
 * @export
 * @interface GetErpSyncOrdersResponseEndpointTasksInner
 */
export interface GetErpSyncOrdersResponseEndpointTasksInner {
    /**
     * 
     * @type {GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInner}
     * @memberof GetErpSyncOrdersResponseEndpointTasksInner
     */
    'task': GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInner;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrdersResponseEndpointTasksInner
     */
    'orderLink': string;
}
/**
 * 
 * @export
 * @interface GetErpSyncOrdersResponseErpSyncOrdersInner
 */
export interface GetErpSyncOrdersResponseErpSyncOrdersInner {
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInner
     */
    'error': string;
    /**
     * 
     * @type {number}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInner
     */
    'deliveryDate': string;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInner
     */
    'orderType': GetErpSyncOrdersResponseErpSyncOrdersInnerOrderTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInner
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInner
     */
    'orderId': number;
    /**
     * 
     * @type {number}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInner
     */
    'fjErpId': number;
    /**
     * 
     * @type {number}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInner
     */
    'cpproId': number;
    /**
     * 
     * @type {number}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInner
     */
    'cpproInstallId': number;
    /**
     * 
     * @type {number}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInner
     */
    'acErpId': number;
    /**
     * 
     * @type {number}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInner
     */
    'mrpSaleId': number;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInner
     */
    'mrpShipId': string;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInner
     */
    'mrpInvoiceId': string;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInner
     */
    'mrpInvoiceDate': string;
    /**
     * 
     * @type {number}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInner
     */
    'finalErpId': number;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInner
     */
    'acOrderStatus': string;
    /**
     * 
     * @type {number}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInner
     */
    'acErpCustomerId': number;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInner
     */
    'fjOrderStatus': string;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInner
     */
    'finalFJOrderStatus': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInner
     */
    'manualInvoicing': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInner
     */
    'acErpInvoiced': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInner
     */
    'acErpInvoiceDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInner
     */
    'fjErpInvoiced': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInner
     */
    'fJErpInvoiceDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInner
     */
    'isACT': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInner
     */
    'test': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInner
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInner
     */
    'salesOrderLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInner
     */
    'installOrderLink'?: string;
    /**
     * 
     * @type {Array<GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInner>}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInner
     */
    'tasks': Array<GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInner>;
}

export const GetErpSyncOrdersResponseErpSyncOrdersInnerOrderTypeEnum = {
    CreditSale: 'CREDIT_SALE',
    Install: 'INSTALL',
    Partnerprog: 'PARTNERPROG',
    PartnerprogInternal: 'PARTNERPROG_INTERNAL',
    Repair: 'REPAIR',
    Service: 'SERVICE',
    Warranty: 'WARRANTY',
    WarrantyCreditsale: 'WARRANTY_CREDITSALE',
    WarrantyService: 'WARRANTY_SERVICE'
} as const;

export type GetErpSyncOrdersResponseErpSyncOrdersInnerOrderTypeEnum = typeof GetErpSyncOrdersResponseErpSyncOrdersInnerOrderTypeEnum[keyof typeof GetErpSyncOrdersResponseErpSyncOrdersInnerOrderTypeEnum];

/**
 * 
 * @export
 * @interface GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInner
 */
export interface GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInner {
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInner
     */
    'path': string;
    /**
     * 
     * @type {GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerCreateTime}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInner
     */
    'createTime'?: GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerCreateTime;
    /**
     * 
     * @type {GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerCreateTime}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInner
     */
    'scheduleTime'?: GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerCreateTime;
    /**
     * 
     * @type {number}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInner
     */
    'dispatchCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInner
     */
    'responseCount'?: number;
    /**
     * 
     * @type {GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerFirstAttempt}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInner
     */
    'firstAttempt'?: GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerFirstAttempt;
    /**
     * 
     * @type {GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerFirstAttempt}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInner
     */
    'lastAttempt'?: GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerFirstAttempt;
    /**
     * 
     * @type {GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerDispatchDeadline}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInner
     */
    'dispatchDeadline'?: GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerDispatchDeadline;
    /**
     * 
     * @type {GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerHttpRequest}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInner
     */
    'httpRequest'?: GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerHttpRequest;
}
/**
 * @type GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerCreateTime
 * @export
 */
export type GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerCreateTime = string;

/**
 * 
 * @export
 * @interface GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerDispatchDeadline
 */
export interface GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerDispatchDeadline {
    /**
     * 
     * @type {number}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerDispatchDeadline
     */
    'seconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerDispatchDeadline
     */
    'nanos'?: number;
}
/**
 * 
 * @export
 * @interface GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerFirstAttempt
 */
export interface GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerFirstAttempt {
    /**
     * 
     * @type {GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerCreateTime}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerFirstAttempt
     */
    'scheduleTime'?: GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerCreateTime;
    /**
     * 
     * @type {GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerCreateTime}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerFirstAttempt
     */
    'dispatchTime'?: GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerCreateTime;
    /**
     * 
     * @type {GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerCreateTime}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerFirstAttempt
     */
    'responseTime'?: GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerCreateTime;
    /**
     * 
     * @type {GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerFirstAttemptResponseStatus}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerFirstAttempt
     */
    'responseStatus'?: GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerFirstAttemptResponseStatus;
}
/**
 * 
 * @export
 * @interface GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerFirstAttemptResponseStatus
 */
export interface GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerFirstAttemptResponseStatus {
    /**
     * 
     * @type {number}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerFirstAttemptResponseStatus
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerFirstAttemptResponseStatus
     */
    'message'?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerFirstAttemptResponseStatus
     */
    'details'?: Array<any>;
}
/**
 * 
 * @export
 * @interface GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerHttpRequest
 */
export interface GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerHttpRequest {
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerHttpRequest
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerHttpRequest
     */
    'body'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerHttpRequest
     */
    'httpMethod'?: GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerHttpRequestHttpMethodEnum;
    /**
     * 
     * @type {object}
     * @memberof GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerHttpRequest
     */
    'headers'?: object;
}

export const GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerHttpRequestHttpMethodEnum = {
    Delete: 'DELETE',
    Get: 'GET',
    Head: 'HEAD',
    HttpMethodUnspecified: 'HTTP_METHOD_UNSPECIFIED',
    Options: 'OPTIONS',
    Patch: 'PATCH',
    Post: 'POST',
    Put: 'PUT'
} as const;

export type GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerHttpRequestHttpMethodEnum = typeof GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerHttpRequestHttpMethodEnum[keyof typeof GetErpSyncOrdersResponseErpSyncOrdersInnerTasksInnerHttpRequestHttpMethodEnum];

/**
 * 
 * @export
 * @interface GetIncidentsCommand
 */
export interface GetIncidentsCommand {
    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof GetIncidentsCommand
     */
    'startTime'?: InstallItemUsageAlternativeCreateInputCachedAt;
    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof GetIncidentsCommand
     */
    'endTime'?: InstallItemUsageAlternativeCreateInputCachedAt;
    /**
     * 
     * @type {number}
     * @memberof GetIncidentsCommand
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetIncidentsCommand
     */
    'page'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetIncidentsCommand
     */
    'status'?: GetIncidentsCommandStatusEnum;
}

export const GetIncidentsCommandStatusEnum = {
    Acknowledged: 'ACKNOWLEDGED',
    Muted: 'MUTED',
    Open: 'OPEN',
    Resolved: 'RESOLVED'
} as const;

export type GetIncidentsCommandStatusEnum = typeof GetIncidentsCommandStatusEnum[keyof typeof GetIncidentsCommandStatusEnum];

/**
 * 
 * @export
 * @interface GetInstallItemUsageResponse
 */
export interface GetInstallItemUsageResponse extends Array<GetInstallItemUsageResponseInner> {
}
/**
 * 
 * @export
 * @interface GetInstallItemUsageResponseInner
 */
export interface GetInstallItemUsageResponseInner {
    /**
     * 
     * @type {number}
     * @memberof GetInstallItemUsageResponseInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof GetInstallItemUsageResponseInner
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof GetInstallItemUsageResponseInner
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof GetInstallItemUsageResponseInner
     */
    'itemNumber': string;
    /**
     * 
     * @type {string}
     * @memberof GetInstallItemUsageResponseInner
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof GetInstallItemUsageResponseInner
     */
    'cachedAt': string;
    /**
     * 
     * @type {Array<GetInstallItemUsageResponseInnerChangelogInner>}
     * @memberof GetInstallItemUsageResponseInner
     */
    'changelog': Array<GetInstallItemUsageResponseInnerChangelogInner>;
    /**
     * 
     * @type {Array<GetInstallItemUsageResponseInnerQuantitiesInner>}
     * @memberof GetInstallItemUsageResponseInner
     */
    'quantities': Array<GetInstallItemUsageResponseInnerQuantitiesInner>;
    /**
     * 
     * @type {Array<GetInstallItemUsageResponseInnerAlternativesInner>}
     * @memberof GetInstallItemUsageResponseInner
     */
    'alternatives': Array<GetInstallItemUsageResponseInnerAlternativesInner>;
    /**
     * 
     * @type {number}
     * @memberof GetInstallItemUsageResponseInner
     */
    'staticQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetInstallItemUsageResponseInner
     */
    'flatAddition'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetInstallItemUsageResponseInner
     */
    'storageUnitConversion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetInstallItemUsageResponseInner
     */
    'discount'?: number | null;
}
/**
 * 
 * @export
 * @interface GetInstallItemUsageResponseInnerAlternativesInner
 */
export interface GetInstallItemUsageResponseInnerAlternativesInner {
    /**
     * 
     * @type {string}
     * @memberof GetInstallItemUsageResponseInnerAlternativesInner
     */
    'answer': string;
    /**
     * 
     * @type {number}
     * @memberof GetInstallItemUsageResponseInnerAlternativesInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof GetInstallItemUsageResponseInnerAlternativesInner
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof GetInstallItemUsageResponseInnerAlternativesInner
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof GetInstallItemUsageResponseInnerAlternativesInner
     */
    'itemNumber': string;
    /**
     * 
     * @type {number}
     * @memberof GetInstallItemUsageResponseInnerAlternativesInner
     */
    'questionId': number;
    /**
     * 
     * @type {string}
     * @memberof GetInstallItemUsageResponseInnerAlternativesInner
     */
    'questionName': string;
    /**
     * 
     * @type {string}
     * @memberof GetInstallItemUsageResponseInnerAlternativesInner
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof GetInstallItemUsageResponseInnerAlternativesInner
     */
    'cachedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetInstallItemUsageResponseInnerAlternativesInner
     */
    'useSalesOrder': boolean;
    /**
     * 
     * @type {number}
     * @memberof GetInstallItemUsageResponseInnerAlternativesInner
     */
    'itemUsageId': number;
    /**
     * 
     * @type {number}
     * @memberof GetInstallItemUsageResponseInnerAlternativesInner
     */
    'quantityFactor'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetInstallItemUsageResponseInnerAlternativesInner
     */
    'flatAddition'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetInstallItemUsageResponseInnerAlternativesInner
     */
    'storageUnitConversion'?: number | null;
}
/**
 * 
 * @export
 * @interface GetInstallItemUsageResponseInnerChangelogInner
 */
export interface GetInstallItemUsageResponseInnerChangelogInner {
    /**
     * 
     * @type {number}
     * @memberof GetInstallItemUsageResponseInnerChangelogInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof GetInstallItemUsageResponseInnerChangelogInner
     */
    'itemNumber': string;
    /**
     * 
     * @type {string}
     * @memberof GetInstallItemUsageResponseInnerChangelogInner
     */
    'timestamp': string;
    /**
     * 
     * @type {number}
     * @memberof GetInstallItemUsageResponseInnerChangelogInner
     */
    'itemUsageId': number;
    /**
     * 
     * @type {string}
     * @memberof GetInstallItemUsageResponseInnerChangelogInner
     */
    'field': string;
    /**
     * 
     * @type {string}
     * @memberof GetInstallItemUsageResponseInnerChangelogInner
     */
    'oldValue': string;
    /**
     * 
     * @type {string}
     * @memberof GetInstallItemUsageResponseInnerChangelogInner
     */
    'newValue': string;
    /**
     * 
     * @type {string}
     * @memberof GetInstallItemUsageResponseInnerChangelogInner
     */
    'changedBy': string;
}
/**
 * 
 * @export
 * @interface GetInstallItemUsageResponseInnerQuantitiesInner
 */
export interface GetInstallItemUsageResponseInnerQuantitiesInner {
    /**
     * 
     * @type {number}
     * @memberof GetInstallItemUsageResponseInnerQuantitiesInner
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof GetInstallItemUsageResponseInnerQuantitiesInner
     */
    'itemUsageId': number;
    /**
     * 
     * @type {number}
     * @memberof GetInstallItemUsageResponseInnerQuantitiesInner
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof GetInstallItemUsageResponseInnerQuantitiesInner
     */
    'meter': number;
}
/**
 * GetInvoiceDocument
 * @export
 * @interface GetInvoiceDocument
 */
export interface GetInvoiceDocument {
    [key: string]: any;

    /**
     * 
     * @type {GetInvoiceDocumentParameters}
     * @memberof GetInvoiceDocument
     */
    'Parameters'?: GetInvoiceDocumentParameters;
}
/**
 * Parameters
 * @export
 * @interface GetInvoiceDocumentParameters
 */
export interface GetInvoiceDocumentParameters {
    [key: string]: any;

    /**
     * s:int
     * @type {number}
     * @memberof GetInvoiceDocumentParameters
     */
    'InvoiceId'?: number;
    /**
     * guid|s:string|pattern
     * @type {string}
     * @memberof GetInvoiceDocumentParameters
     */
    'TemplateId'?: string;
}
/**
 * GetInvoiceDocumentResponse
 * @export
 * @interface GetInvoiceDocumentResponse
 */
export interface GetInvoiceDocumentResponse {
    [key: string]: any;

    /**
     * s:base64Binary
     * @type {string}
     * @memberof GetInvoiceDocumentResponse
     */
    'GetInvoiceDocumentResult'?: string;
}
/**
 * GetInvoiceTemplates
 * @export
 * @interface GetInvoiceTemplates
 */
export interface GetInvoiceTemplates {
    [key: string]: any;

}
/**
 * GetInvoiceTemplatesResponse
 * @export
 * @interface GetInvoiceTemplatesResponse
 */
export interface GetInvoiceTemplatesResponse {
    [key: string]: any;

    /**
     * 
     * @type {GetInvoiceTemplatesResponseGetInvoiceTemplatesResult}
     * @memberof GetInvoiceTemplatesResponse
     */
    'GetInvoiceTemplatesResult'?: GetInvoiceTemplatesResponseGetInvoiceTemplatesResult;
}
/**
 * GetInvoiceTemplatesResult
 * @export
 * @interface GetInvoiceTemplatesResponseGetInvoiceTemplatesResult
 */
export interface GetInvoiceTemplatesResponseGetInvoiceTemplatesResult {
    [key: string]: any;

    /**
     * TemplateModel[]
     * @type {Array<GetInvoiceTemplatesResponseGetInvoiceTemplatesResultTemplateModelInner>}
     * @memberof GetInvoiceTemplatesResponseGetInvoiceTemplatesResult
     */
    'TemplateModel'?: Array<GetInvoiceTemplatesResponseGetInvoiceTemplatesResultTemplateModelInner>;
}
/**
 * TemplateModel
 * @export
 * @interface GetInvoiceTemplatesResponseGetInvoiceTemplatesResultTemplateModelInner
 */
export interface GetInvoiceTemplatesResponseGetInvoiceTemplatesResultTemplateModelInner {
    [key: string]: any;

    /**
     * s:string
     * @type {string}
     * @memberof GetInvoiceTemplatesResponseGetInvoiceTemplatesResultTemplateModelInner
     */
    'FileName'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof GetInvoiceTemplatesResponseGetInvoiceTemplatesResultTemplateModelInner
     */
    'Name'?: string;
    /**
     * guid|s:string|pattern
     * @type {string}
     * @memberof GetInvoiceTemplatesResponseGetInvoiceTemplatesResultTemplateModelInner
     */
    'Id'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof GetInvoiceTemplatesResponseGetInvoiceTemplatesResultTemplateModelInner
     */
    'Language'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof GetInvoiceTemplatesResponseGetInvoiceTemplatesResultTemplateModelInner
     */
    'Country'?: string;
    /**
     * s:boolean
     * @type {boolean}
     * @memberof GetInvoiceTemplatesResponseGetInvoiceTemplatesResultTemplateModelInner
     */
    'IsSystemTemplate'?: boolean;
    /**
     * s:boolean
     * @type {boolean}
     * @memberof GetInvoiceTemplatesResponseGetInvoiceTemplatesResultTemplateModelInner
     */
    'IsDefault'?: boolean;
    /**
     * s:dateTime
     * @type {string}
     * @memberof GetInvoiceTemplatesResponseGetInvoiceTemplatesResultTemplateModelInner
     */
    'Modified'?: string;
    /**
     * 
     * @type {GetInvoiceTemplatesResponseGetInvoiceTemplatesResultTemplateModelInnerCustomers}
     * @memberof GetInvoiceTemplatesResponseGetInvoiceTemplatesResultTemplateModelInner
     */
    'Customers'?: GetInvoiceTemplatesResponseGetInvoiceTemplatesResultTemplateModelInnerCustomers;
}
/**
 * Customers
 * @export
 * @interface GetInvoiceTemplatesResponseGetInvoiceTemplatesResultTemplateModelInnerCustomers
 */
export interface GetInvoiceTemplatesResponseGetInvoiceTemplatesResultTemplateModelInnerCustomers {
    [key: string]: any;

    /**
     * s:int
     * @type {Array<number>}
     * @memberof GetInvoiceTemplatesResponseGetInvoiceTemplatesResultTemplateModelInnerCustomers
     */
    'int'?: Array<number>;
}
/**
 * GetInvoiceTemplatesResult
 * @export
 * @interface GetInvoiceTemplatesResult
 */
export interface GetInvoiceTemplatesResult {
    [key: string]: any;

    /**
     * TemplateModel[]
     * @type {Array<GetInvoiceTemplatesResponseGetInvoiceTemplatesResultTemplateModelInner>}
     * @memberof GetInvoiceTemplatesResult
     */
    'TemplateModel'?: Array<GetInvoiceTemplatesResponseGetInvoiceTemplatesResultTemplateModelInner>;
}
/**
 * GetInvoices
 * @export
 * @interface GetInvoices
 */
export interface GetInvoices {
    [key: string]: any;

    /**
     * 
     * @type {GetInvoicesSearchParams}
     * @memberof GetInvoices
     */
    'searchParams'?: GetInvoicesSearchParams;
    /**
     * 
     * @type {GetInvoicesInvoiceReturnProperties}
     * @memberof GetInvoices
     */
    'invoiceReturnProperties'?: GetInvoicesInvoiceReturnProperties;
    /**
     * 
     * @type {GetInvoicesRowReturnProperties}
     * @memberof GetInvoices
     */
    'rowReturnProperties'?: GetInvoicesRowReturnProperties;
}
/**
 * invoiceReturnProperties
 * @export
 * @interface GetInvoicesInvoiceReturnProperties
 */
export interface GetInvoicesInvoiceReturnProperties {
    [key: string]: any;

    /**
     * s:string
     * @type {Array<string>}
     * @memberof GetInvoicesInvoiceReturnProperties
     */
    'string'?: Array<string>;
}
/**
 * GetInvoicesResponse
 * @export
 * @interface GetInvoicesResponse
 */
export interface GetInvoicesResponse {
    [key: string]: any;

    /**
     * 
     * @type {GetInvoicesResponseGetInvoicesResult}
     * @memberof GetInvoicesResponse
     */
    'GetInvoicesResult'?: GetInvoicesResponseGetInvoicesResult;
}
/**
 * GetInvoicesResult
 * @export
 * @interface GetInvoicesResponseGetInvoicesResult
 */
export interface GetInvoicesResponseGetInvoicesResult {
    [key: string]: any;

    /**
     * InvoiceOrder[]
     * @type {Array<GetInvoicesResponseGetInvoicesResultInvoiceOrderInner>}
     * @memberof GetInvoicesResponseGetInvoicesResult
     */
    'InvoiceOrder'?: Array<GetInvoicesResponseGetInvoicesResultInvoiceOrderInner>;
}
/**
 * InvoiceOrder
 * @export
 * @interface GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
 */
export interface GetInvoicesResponseGetInvoicesResultInvoiceOrderInner {
    [key: string]: any;

    /**
     * s:int
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'OrderId'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'CustomerId'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'CustomerName'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'CustomerDeliveryName'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'CustomerDeliveryPhone'?: string;
    /**
     * 
     * @type {GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerAddresses}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'Addresses'?: GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerAddresses;
    /**
     * OrderSlipStateType|s:string|None,Offer,Pack,Invoiced,Web,Repeating,CollectingOrder,Return,Distribution,Rest,ForInvoicing,OvertimeRent,Confirmed,OrderState_Sent,Production,PaymentReminder,OnAccount,Lending,InvoicedCashAccount,InvoicedPack,Inactive,Hours,OrderState_SuperStore,Draft
     * @type {string}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'OrderStatus'?: GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerOrderStatusEnum;
    /**
     * s:int
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'InvoiceId'?: number;
    /**
     * 
     * @type {GetInvoicesSearchParamsChangedAfter}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'DateOrdered'?: GetInvoicesSearchParamsChangedAfter;
    /**
     * 
     * @type {GetInvoicesSearchParamsChangedAfter}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'DateInvoiced'?: GetInvoicesSearchParamsChangedAfter;
    /**
     * 
     * @type {GetInvoicesSearchParamsChangedAfter}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'DateChanged'?: GetInvoicesSearchParamsChangedAfter;
    /**
     * s:int
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'PaymentTime'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'CustomerReferenceNo'?: string;
    /**
     * s:int
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'ProjectId'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'OurReference'?: number;
    /**
     * s:boolean
     * @type {boolean}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'IncludeVAT'?: boolean;
    /**
     * s:string
     * @type {string}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'YourReference'?: string;
    /**
     * s:decimal
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'OrderTotalIncVat'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'OrderTotalVat'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'InvoiceTitle'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'InvoiceText'?: string;
    /**
     * s:dateTime
     * @type {string}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'Paid'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'OCR'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'CustomerOrgNo'?: string;
    /**
     * 
     * @type {GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerCurrency}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'Currency'?: GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerCurrency;
    /**
     * s:int
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'PaymentMethodId'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'PaymentAmount'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'ProductionManagerId'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'SalesOpportunityId'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'TypeOfSaleId'?: number;
    /**
     * Distributor|s:string|Default,Manual,Auto
     * @type {string}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'Distributor'?: GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerDistributorEnum;
    /**
     * DistributionMethod|s:string|Unchanged,Print,EMail,ElectronicInvoice
     * @type {string}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'DistributionMethod'?: GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerDistributionMethodEnum;
    /**
     * s:int
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'DepartmentId'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'ExternalStatus'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'InvoiceEmailAddress'?: string;
    /**
     * 
     * @type {GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRows}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'InvoiceRows'?: GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRows;
    /**
     * 
     * @type {GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerAPIException}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'APIException'?: GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerAPIException;
    /**
     * s:string
     * @type {string}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'ProductionNumber'?: string;
    /**
     * 
     * @type {GetInvoicesSearchParamsChangedAfter}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'DeliveryDate'?: GetInvoicesSearchParamsChangedAfter;
    /**
     * s:int
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'ReferenceInvoiceId'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'ReferenceOrderId'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'ReferenceNumber'?: string;
    /**
     * s:boolean
     * @type {boolean}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'SkipStock'?: boolean;
    /**
     * s:dateTime
     * @type {string}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'AccrualDate'?: string;
    /**
     * s:int
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'AccrualLength'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'RoundFactor'?: number;
    /**
     * guid|s:string|pattern
     * @type {string}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'InvoiceTemplateId'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'VippsNumber'?: string;
    /**
     * 
     * @type {GetDeliveryMethodsResponseGetDeliveryMethodsResultDeliveryMethodInner}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'DeliveryMethod'?: GetDeliveryMethodsResponseGetDeliveryMethodsResultDeliveryMethodInner;
    /**
     * s:string
     * @type {string}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'DeliveryAlternative'?: string;
    /**
     * s:boolean
     * @type {boolean}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'SendToFactoring'?: boolean;
    /**
     * s:decimal
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'Commission'?: number;
    /**
     * 
     * @type {GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerUserDefinedDimensions}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'UserDefinedDimensions'?: GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerUserDefinedDimensions;
    /**
     * s:string
     * @type {string}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'GLNNumber'?: string;
    /**
     * s:int
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInner
     */
    'CustomerDeliveryId'?: number;
}

export const GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerOrderStatusEnum = {
    CollectingOrder: 'CollectingOrder',
    Confirmed: 'Confirmed',
    Distribution: 'Distribution',
    Draft: 'Draft',
    ForInvoicing: 'ForInvoicing',
    Hours: 'Hours',
    Inactive: 'Inactive',
    Invoiced: 'Invoiced',
    InvoicedCashAccount: 'InvoicedCashAccount',
    InvoicedPack: 'InvoicedPack',
    Lending: 'Lending',
    None: 'None',
    Offer: 'Offer',
    OnAccount: 'OnAccount',
    OrderStateSent: 'OrderState_Sent',
    OrderStateSuperStore: 'OrderState_SuperStore',
    OvertimeRent: 'OvertimeRent',
    Pack: 'Pack',
    PaymentReminder: 'PaymentReminder',
    Production: 'Production',
    Repeating: 'Repeating',
    Rest: 'Rest',
    Return: 'Return',
    Web: 'Web'
} as const;

export type GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerOrderStatusEnum = typeof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerOrderStatusEnum[keyof typeof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerOrderStatusEnum];
export const GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerDistributorEnum = {
    Auto: 'Auto',
    Default: 'Default',
    Manual: 'Manual'
} as const;

export type GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerDistributorEnum = typeof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerDistributorEnum[keyof typeof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerDistributorEnum];
export const GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerDistributionMethodEnum = {
    EMail: 'EMail',
    ElectronicInvoice: 'ElectronicInvoice',
    Print: 'Print',
    Unchanged: 'Unchanged'
} as const;

export type GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerDistributionMethodEnum = typeof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerDistributionMethodEnum[keyof typeof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerDistributionMethodEnum];

/**
 * APIException
 * @export
 * @interface GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerAPIException
 */
export interface GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerAPIException {
    [key: string]: any;

    /**
     * s:string
     * @type {string}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerAPIException
     */
    'Type'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerAPIException
     */
    'Message'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerAPIException
     */
    'StackTrace'?: string;
}
/**
 * Addresses
 * @export
 * @interface GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerAddresses
 */
export interface GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerAddresses {
    [key: string]: any;

    /**
     * 
     * @type {AddressesPost}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerAddresses
     */
    'Post'?: AddressesPost;
    /**
     * 
     * @type {AddressesDelivery}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerAddresses
     */
    'Delivery'?: AddressesDelivery;
    /**
     * 
     * @type {AddressesVisit}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerAddresses
     */
    'Visit'?: AddressesVisit;
    /**
     * 
     * @type {AddressesInvoice}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerAddresses
     */
    'Invoice'?: AddressesInvoice;
}
/**
 * Currency
 * @export
 * @interface GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerCurrency
 */
export interface GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerCurrency {
    [key: string]: any;

    /**
     * s:decimal
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerCurrency
     */
    'Rate'?: number;
    /**
     * s:short
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerCurrency
     */
    'Unit'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerCurrency
     */
    'Symbol'?: string;
}
/**
 * InvoiceRows
 * @export
 * @interface GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRows
 */
export interface GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRows {
    [key: string]: any;

    /**
     * InvoiceRow[]
     * @type {Array<GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInner>}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRows
     */
    'InvoiceRow'?: Array<GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInner>;
}
/**
 * InvoiceRow
 * @export
 * @interface GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInner
 */
export interface GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInner {
    [key: string]: any;

    /**
     * s:int
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInner
     */
    'ProductId'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInner
     */
    'RowId'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInner
     */
    'VatRate'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInner
     */
    'Price'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInner
     */
    'Name'?: string;
    /**
     * s:decimal
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInner
     */
    'DiscountRate'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInner
     */
    'Quantity'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInner
     */
    'QuantityDelivered'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInner
     */
    'QuantityOrdered'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInner
     */
    'QuantityRest'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInner
     */
    'Cost'?: number;
    /**
     * ChangeState|s:string|None,Add,Edit,Delete
     * @type {string}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInner
     */
    'ChangeState'?: GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerChangeStateEnum;
    /**
     * s:int
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInner
     */
    'TypeGroupId'?: number;
    /**
     * s:short
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInner
     */
    'SequenceNumber'?: number;
    /**
     * s:boolean
     * @type {boolean}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInner
     */
    'Hidden'?: boolean;
    /**
     * RowType|s:string|Normal,Text,TextBold
     * @type {string}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInner
     */
    'Type'?: GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerTypeEnum;
    /**
     * s:decimal
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInner
     */
    'InPrice'?: number;
    /**
     * s:dateTime
     * @type {string}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInner
     */
    'AccrualDate'?: string;
    /**
     * s:int
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInner
     */
    'AccrualLength'?: number;
    /**
     * s:boolean
     * @type {boolean}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInner
     */
    'AccountProject'?: boolean;
    /**
     * s:int
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInner
     */
    'ProjectId'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInner
     */
    'DepartmentId'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInner
     */
    'ProductNo'?: string;
    /**
     * 
     * @type {GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerTaxSettings}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInner
     */
    'TaxSettings'?: GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerTaxSettings;
    /**
     * 
     * @type {GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerUserDefinedDimensions}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInner
     */
    'UserDefinedDimensions'?: GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerUserDefinedDimensions;
    /**
     * s:string
     * @type {string}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInner
     */
    'SerialNumber'?: string;
}

export const GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerChangeStateEnum = {
    Add: 'Add',
    Delete: 'Delete',
    Edit: 'Edit',
    None: 'None'
} as const;

export type GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerChangeStateEnum = typeof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerChangeStateEnum[keyof typeof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerChangeStateEnum];
export const GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerTypeEnum = {
    Normal: 'Normal',
    Text: 'Text',
    TextBold: 'TextBold'
} as const;

export type GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerTypeEnum = typeof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerTypeEnum[keyof typeof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerTypeEnum];

/**
 * TaxSettings
 * @export
 * @interface GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerTaxSettings
 */
export interface GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerTaxSettings {
    [key: string]: any;

    /**
     * s:int
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerTaxSettings
     */
    'TaxAccount'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerTaxSettings
     */
    'TaxCode'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerTaxSettings
     */
    'TaxRate'?: number;
}
/**
 * UserDefinedDimensions
 * @export
 * @interface GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerUserDefinedDimensions
 */
export interface GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerUserDefinedDimensions {
    [key: string]: any;

    /**
     * UserDefinedDimension[]
     * @type {Array<GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerUserDefinedDimensionsUserDefinedDimensionInner>}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerUserDefinedDimensions
     */
    'UserDefinedDimension'?: Array<GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerUserDefinedDimensionsUserDefinedDimensionInner>;
}
/**
 * UserDefinedDimension
 * @export
 * @interface GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerUserDefinedDimensionsUserDefinedDimensionInner
 */
export interface GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerUserDefinedDimensionsUserDefinedDimensionInner {
    [key: string]: any;

    /**
     * s:int
     * @type {number}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerUserDefinedDimensionsUserDefinedDimensionInner
     */
    'TypeId'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerUserDefinedDimensionsUserDefinedDimensionInner
     */
    'Name'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerUserDefinedDimensionsUserDefinedDimensionInner
     */
    'Value'?: string;
}
/**
 * GetInvoicesResult
 * @export
 * @interface GetInvoicesResult
 */
export interface GetInvoicesResult {
    [key: string]: any;

    /**
     * InvoiceOrder[]
     * @type {Array<GetInvoicesResponseGetInvoicesResultInvoiceOrderInner>}
     * @memberof GetInvoicesResult
     */
    'InvoiceOrder'?: Array<GetInvoicesResponseGetInvoicesResultInvoiceOrderInner>;
}
/**
 * rowReturnProperties
 * @export
 * @interface GetInvoicesRowReturnProperties
 */
export interface GetInvoicesRowReturnProperties {
    [key: string]: any;

    /**
     * s:string
     * @type {Array<string>}
     * @memberof GetInvoicesRowReturnProperties
     */
    'string'?: Array<string>;
}
/**
 * searchParams
 * @export
 * @interface GetInvoicesSearchParams
 */
export interface GetInvoicesSearchParams {
    [key: string]: any;

    /**
     * 
     * @type {GetInvoicesSearchParamsCustomerIds}
     * @memberof GetInvoicesSearchParams
     */
    'CustomerIds'?: GetInvoicesSearchParamsCustomerIds;
    /**
     * 
     * @type {GetInvoicesSearchParamsChangedAfter}
     * @memberof GetInvoicesSearchParams
     */
    'ChangedAfter'?: GetInvoicesSearchParamsChangedAfter;
    /**
     * 
     * @type {GetInvoicesSearchParamsOrderStates}
     * @memberof GetInvoicesSearchParams
     */
    'OrderStates'?: GetInvoicesSearchParamsOrderStates;
    /**
     * 
     * @type {GetInvoicesSearchParamsInvoiceIds}
     * @memberof GetInvoicesSearchParams
     */
    'InvoiceIds'?: GetInvoicesSearchParamsInvoiceIds;
    /**
     * 
     * @type {GetInvoicesSearchParamsOrderIds}
     * @memberof GetInvoicesSearchParams
     */
    'OrderIds'?: GetInvoicesSearchParamsOrderIds;
    /**
     * 
     * @type {GetInvoicesSearchParamsExternalStatus}
     * @memberof GetInvoicesSearchParams
     */
    'ExternalStatus'?: GetInvoicesSearchParamsExternalStatus;
}
/**
 * @type GetInvoicesSearchParamsChangedAfter
 * s:dateTime
 * @export
 */
export type GetInvoicesSearchParamsChangedAfter = string;

/**
 * CustomerIds
 * @export
 * @interface GetInvoicesSearchParamsCustomerIds
 */
export interface GetInvoicesSearchParamsCustomerIds {
    [key: string]: any;

    /**
     * s:int
     * @type {Array<number>}
     * @memberof GetInvoicesSearchParamsCustomerIds
     */
    'int'?: Array<number>;
}
/**
 * ExternalStatus
 * @export
 * @interface GetInvoicesSearchParamsExternalStatus
 */
export interface GetInvoicesSearchParamsExternalStatus {
    [key: string]: any;

    /**
     * s:int
     * @type {Array<number>}
     * @memberof GetInvoicesSearchParamsExternalStatus
     */
    'int'?: Array<number>;
}
/**
 * InvoiceIds
 * @export
 * @interface GetInvoicesSearchParamsInvoiceIds
 */
export interface GetInvoicesSearchParamsInvoiceIds {
    [key: string]: any;

    /**
     * s:int
     * @type {Array<number>}
     * @memberof GetInvoicesSearchParamsInvoiceIds
     */
    'int'?: Array<number>;
}
/**
 * OrderIds
 * @export
 * @interface GetInvoicesSearchParamsOrderIds
 */
export interface GetInvoicesSearchParamsOrderIds {
    [key: string]: any;

    /**
     * s:int
     * @type {Array<number>}
     * @memberof GetInvoicesSearchParamsOrderIds
     */
    'int'?: Array<number>;
}
/**
 * OrderStates
 * @export
 * @interface GetInvoicesSearchParamsOrderStates
 */
export interface GetInvoicesSearchParamsOrderStates {
    [key: string]: any;

    /**
     * OrderSlipStateType|s:string|None,Offer,Pack,Invoiced,Web,Repeating,CollectingOrder,Return,Distribution,Rest,ForInvoicing,OvertimeRent,Confirmed,OrderState_Sent,Production,PaymentReminder,OnAccount,Lending,InvoicedCashAccount,InvoicedPack,Inactive,Hours,OrderState_SuperStore,Draft
     * @type {Array<string>}
     * @memberof GetInvoicesSearchParamsOrderStates
     */
    'OrderSlipStateType'?: Array<GetInvoicesSearchParamsOrderStatesOrderSlipStateTypeEnum>;
}

export const GetInvoicesSearchParamsOrderStatesOrderSlipStateTypeEnum = {
    CollectingOrder: 'CollectingOrder',
    Confirmed: 'Confirmed',
    Distribution: 'Distribution',
    Draft: 'Draft',
    ForInvoicing: 'ForInvoicing',
    Hours: 'Hours',
    Inactive: 'Inactive',
    Invoiced: 'Invoiced',
    InvoicedCashAccount: 'InvoicedCashAccount',
    InvoicedPack: 'InvoicedPack',
    Lending: 'Lending',
    None: 'None',
    Offer: 'Offer',
    OnAccount: 'OnAccount',
    OrderStateSent: 'OrderState_Sent',
    OrderStateSuperStore: 'OrderState_SuperStore',
    OvertimeRent: 'OvertimeRent',
    Pack: 'Pack',
    PaymentReminder: 'PaymentReminder',
    Production: 'Production',
    Repeating: 'Repeating',
    Rest: 'Rest',
    Return: 'Return',
    Web: 'Web'
} as const;

export type GetInvoicesSearchParamsOrderStatesOrderSlipStateTypeEnum = typeof GetInvoicesSearchParamsOrderStatesOrderSlipStateTypeEnum[keyof typeof GetInvoicesSearchParamsOrderStatesOrderSlipStateTypeEnum];

/**
 * 
 * @export
 * @interface GetItemPricesRequest
 */
export interface GetItemPricesRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof GetItemPricesRequest
     */
    'ItemNumber': string;
    /**
     * 
     * @type {number}
     * @memberof GetItemPricesRequest
     */
    'Page'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetItemPricesRequest
     */
    'PerPage'?: number;
}
/**
 * @type GetItemPricesResponse
 * @export
 */
export type GetItemPricesResponse = Array<GetItemPricesResponseOneOfInner> | CreateARInvoiceResponseOneOf1;

/**
 * 
 * @export
 * @interface GetItemPricesResponseOneOfInner
 */
export interface GetItemPricesResponseOneOfInner {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof GetItemPricesResponseOneOfInner
     */
    'ItemNumber': string;
    /**
     * 
     * @type {number}
     * @memberof GetItemPricesResponseOneOfInner
     */
    'Quantity': number;
    /**
     * 
     * @type {number}
     * @memberof GetItemPricesResponseOneOfInner
     */
    'PriceEach': number;
    /**
     * 
     * @type {string}
     * @memberof GetItemPricesResponseOneOfInner
     */
    'PricingLevel': string;
    /**
     * 
     * @type {string}
     * @memberof GetItemPricesResponseOneOfInner
     */
    'Revision': string;
}
/**
 * 
 * @export
 * @interface GetMRPSalesOrderResponse
 */
export interface GetMRPSalesOrderResponse {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof GetMRPSalesOrderResponse
     */
    'SalesOrderID': number;
    /**
     * 
     * @type {string}
     * @memberof GetMRPSalesOrderResponse
     */
    'OrderDate': string;
    /**
     * 
     * @type {string}
     * @memberof GetMRPSalesOrderResponse
     */
    'Currency': string;
    /**
     * 
     * @type {number}
     * @memberof GetMRPSalesOrderResponse
     */
    'SubTotal': number;
    /**
     * 
     * @type {number}
     * @memberof GetMRPSalesOrderResponse
     */
    'Shipping': number;
    /**
     * 
     * @type {number}
     * @memberof GetMRPSalesOrderResponse
     */
    'SalesTax': number;
    /**
     * 
     * @type {number}
     * @memberof GetMRPSalesOrderResponse
     */
    'Total': number;
    /**
     * 
     * @type {string}
     * @memberof GetMRPSalesOrderResponse
     */
    'Term': string;
    /**
     * 
     * @type {string}
     * @memberof GetMRPSalesOrderResponse
     */
    'DeliveryMethod': string;
    /**
     * 
     * @type {number}
     * @memberof GetMRPSalesOrderResponse
     */
    'ProjectID': number;
    /**
     * 
     * @type {string}
     * @memberof GetMRPSalesOrderResponse
     */
    'SoldToCompanyName': string;
    /**
     * 
     * @type {string}
     * @memberof GetMRPSalesOrderResponse
     */
    'SoldToContact': string;
    /**
     * 
     * @type {string}
     * @memberof GetMRPSalesOrderResponse
     */
    'SoldToAddress1': string;
    /**
     * 
     * @type {string}
     * @memberof GetMRPSalesOrderResponse
     */
    'SoldToAddress2': string;
    /**
     * 
     * @type {string}
     * @memberof GetMRPSalesOrderResponse
     */
    'SoldToCity': string;
    /**
     * 
     * @type {string}
     * @memberof GetMRPSalesOrderResponse
     */
    'SoldToState': string;
    /**
     * 
     * @type {string}
     * @memberof GetMRPSalesOrderResponse
     */
    'SoldToPostalCode': string;
    /**
     * 
     * @type {string}
     * @memberof GetMRPSalesOrderResponse
     */
    'SoldToGLN': string;
    /**
     * 
     * @type {string}
     * @memberof GetMRPSalesOrderResponse
     */
    'ShipToCompanyName': string;
    /**
     * 
     * @type {string}
     * @memberof GetMRPSalesOrderResponse
     */
    'ShipToContact': string;
    /**
     * 
     * @type {string}
     * @memberof GetMRPSalesOrderResponse
     */
    'ShipToAddress1': string;
    /**
     * 
     * @type {string}
     * @memberof GetMRPSalesOrderResponse
     */
    'ShipToAddress2': string;
    /**
     * 
     * @type {string}
     * @memberof GetMRPSalesOrderResponse
     */
    'ShipToCity': string;
    /**
     * 
     * @type {string}
     * @memberof GetMRPSalesOrderResponse
     */
    'ShipToState': string;
    /**
     * 
     * @type {string}
     * @memberof GetMRPSalesOrderResponse
     */
    'ShipToPostalCode': string;
    /**
     * 
     * @type {string}
     * @memberof GetMRPSalesOrderResponse
     */
    'ShipToGLN': string;
    /**
     * 
     * @type {number}
     * @memberof GetMRPSalesOrderResponse
     */
    'StatusID': GetMRPSalesOrderResponseStatusIDEnum;
    /**
     * 
     * @type {string}
     * @memberof GetMRPSalesOrderResponse
     */
    'SalesRep': string;
    /**
     * 
     * @type {string}
     * @memberof GetMRPSalesOrderResponse
     */
    'ScopeOfWork': string;
    /**
     * 
     * @type {string}
     * @memberof GetMRPSalesOrderResponse
     */
    'CustomerOrderNumber': string;
    /**
     * 
     * @type {Array<GetMRPSalesOrderResponseLineItemsInner>}
     * @memberof GetMRPSalesOrderResponse
     */
    'LineItems': Array<GetMRPSalesOrderResponseLineItemsInner>;
}

export const GetMRPSalesOrderResponseStatusIDEnum = {
    NUMBER_1: 1,
    NUMBER_10: 10,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9
} as const;

export type GetMRPSalesOrderResponseStatusIDEnum = typeof GetMRPSalesOrderResponseStatusIDEnum[keyof typeof GetMRPSalesOrderResponseStatusIDEnum];

/**
 * 
 * @export
 * @interface GetMRPSalesOrderResponseLineItemsInner
 */
export interface GetMRPSalesOrderResponseLineItemsInner {
    /**
     * 
     * @type {number}
     * @memberof GetMRPSalesOrderResponseLineItemsInner
     */
    'LineItemID': number;
    /**
     * 
     * @type {number}
     * @memberof GetMRPSalesOrderResponseLineItemsInner
     */
    'ItemMasterID': number;
    /**
     * 
     * @type {string}
     * @memberof GetMRPSalesOrderResponseLineItemsInner
     */
    'ItemNumber': string;
    /**
     * 
     * @type {string}
     * @memberof GetMRPSalesOrderResponseLineItemsInner
     */
    'Revision': string;
    /**
     * 
     * @type {string}
     * @memberof GetMRPSalesOrderResponseLineItemsInner
     */
    'Description': string;
    /**
     * 
     * @type {number}
     * @memberof GetMRPSalesOrderResponseLineItemsInner
     */
    'Quantity': number;
    /**
     * 
     * @type {number}
     * @memberof GetMRPSalesOrderResponseLineItemsInner
     */
    'UnitPrice': number;
    /**
     * 
     * @type {Array<GetMRPSalesOrderResponseLineItemsInnerAdditionalDataValuesInner>}
     * @memberof GetMRPSalesOrderResponseLineItemsInner
     */
    'AdditionalDataValues': Array<GetMRPSalesOrderResponseLineItemsInnerAdditionalDataValuesInner>;
    /**
     * 
     * @type {object}
     * @memberof GetMRPSalesOrderResponseLineItemsInner
     */
    'ConfigurationData': object;
    /**
     * 
     * @type {string}
     * @memberof GetMRPSalesOrderResponseLineItemsInner
     */
    'Warehouse': string;
    /**
     * 
     * @type {string}
     * @memberof GetMRPSalesOrderResponseLineItemsInner
     */
    'RequestedDate': string;
    /**
     * 
     * @type {number}
     * @memberof GetMRPSalesOrderResponseLineItemsInner
     */
    'DiscountAmount': number;
    /**
     * 
     * @type {number}
     * @memberof GetMRPSalesOrderResponseLineItemsInner
     */
    'DiscountPercent': number;
    /**
     * 
     * @type {string}
     * @memberof GetMRPSalesOrderResponseLineItemsInner
     */
    'GTIN': string;
    /**
     * 
     * @type {string}
     * @memberof GetMRPSalesOrderResponseLineItemsInner
     */
    'LineItemStatus': GetMRPSalesOrderResponseLineItemsInnerLineItemStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetMRPSalesOrderResponseLineItemsInner
     */
    'PromiseDate': string;
    /**
     * 
     * @type {string}
     * @memberof GetMRPSalesOrderResponseLineItemsInner
     */
    'Category': string;
    /**
     * 
     * @type {string}
     * @memberof GetMRPSalesOrderResponseLineItemsInner
     */
    'SubCategory': string;
}

export const GetMRPSalesOrderResponseLineItemsInnerLineItemStatusEnum = {'---': '---',
    Canceled: 'Canceled',
    Invoiced: 'Invoiced',
    Loaded: 'Loaded',
    Pending: 'Pending',
    Picked: 'Picked',
    Production: 'Production',
    Released: 'Released',
    Shipped: 'Shipped',
    Staged: 'Staged'
} as const;

export type GetMRPSalesOrderResponseLineItemsInnerLineItemStatusEnum = typeof GetMRPSalesOrderResponseLineItemsInnerLineItemStatusEnum[keyof typeof GetMRPSalesOrderResponseLineItemsInnerLineItemStatusEnum];

/**
 * 
 * @export
 * @interface GetMRPSalesOrderResponseLineItemsInnerAdditionalDataValuesInner
 */
export interface GetMRPSalesOrderResponseLineItemsInnerAdditionalDataValuesInner {
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof GetMRPSalesOrderResponseLineItemsInnerAdditionalDataValuesInner
     */
    'ID': ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {string}
     * @memberof GetMRPSalesOrderResponseLineItemsInnerAdditionalDataValuesInner
     */
    'Name': string;
    /**
     * 
     * @type {string}
     * @memberof GetMRPSalesOrderResponseLineItemsInnerAdditionalDataValuesInner
     */
    'Value': string;
}
/**
 * 
 * @export
 * @interface GetMRPStockResponse
 */
export interface GetMRPStockResponse {
    [key: string]: any;

    /**
     * 
     * @type {Array<GetMRPStockResponseStockListInner>}
     * @memberof GetMRPStockResponse
     */
    'stockList': Array<GetMRPStockResponseStockListInner>;
}
/**
 * 
 * @export
 * @interface GetMRPStockResponseStockListInner
 */
export interface GetMRPStockResponseStockListInner {
    /**
     * 
     * @type {number}
     * @memberof GetMRPStockResponseStockListInner
     */
    'Available': number;
    /**
     * 
     * @type {number}
     * @memberof GetMRPStockResponseStockListInner
     */
    'Demand': number;
    /**
     * 
     * @type {number}
     * @memberof GetMRPStockResponseStockListInner
     */
    'OnHand': number;
    /**
     * 
     * @type {number}
     * @memberof GetMRPStockResponseStockListInner
     */
    'Planned': number;
    /**
     * 
     * @type {string}
     * @memberof GetMRPStockResponseStockListInner
     */
    'Warehouse': string;
    /**
     * 
     * @type {string}
     * @memberof GetMRPStockResponseStockListInner
     */
    'ItemNumber': string;
    /**
     * 
     * @type {number}
     * @memberof GetMRPStockResponseStockListInner
     */
    'ItemMasterID': number;
    /**
     * 
     * @type {number}
     * @memberof GetMRPStockResponseStockListInner
     */
    'AvailableNew': number;
}
/**
 * 
 * @export
 * @interface GetMrpOrderStatisticsResponse
 */
export interface GetMrpOrderStatisticsResponse {
    [key: string]: any;

    /**
     * 
     * @type {Array<GetMrpOrderStatisticsResponseOrdersInner>}
     * @memberof GetMrpOrderStatisticsResponse
     */
    'orders': Array<GetMrpOrderStatisticsResponseOrdersInner>;
}
/**
 * 
 * @export
 * @interface GetMrpOrderStatisticsResponseOrdersInner
 */
export interface GetMrpOrderStatisticsResponseOrdersInner {
    /**
     * 
     * @type {number}
     * @memberof GetMrpOrderStatisticsResponseOrdersInner
     */
    'mrpId': number;
    /**
     * 
     * @type {number}
     * @memberof GetMrpOrderStatisticsResponseOrdersInner
     */
    'cpproId': number;
    /**
     * 
     * @type {boolean}
     * @memberof GetMrpOrderStatisticsResponseOrdersInner
     */
    'isACT': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetMrpOrderStatisticsResponseOrdersInner
     */
    'createdDate': string;
    /**
     * 
     * @type {string}
     * @memberof GetMrpOrderStatisticsResponseOrdersInner
     */
    'mrpInvoicedDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetMrpOrderStatisticsResponseOrdersInner
     */
    'mrpInvoiced': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetMrpOrderStatisticsResponseOrdersInner
     */
    'shipped': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetMrpOrderStatisticsResponseOrdersInner
     */
    'inactive': boolean;
}
/**
 * 
 * @export
 * @interface GetMrpPickErrorsRequest
 */
export interface GetMrpPickErrorsRequest {
    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof GetMrpPickErrorsRequest
     */
    'startTime'?: InstallItemUsageAlternativeCreateInputCachedAt;
    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof GetMrpPickErrorsRequest
     */
    'endTime'?: InstallItemUsageAlternativeCreateInputCachedAt;
    /**
     * 
     * @type {string}
     * @memberof GetMrpPickErrorsRequest
     */
    'status'?: GetMrpPickErrorsRequestStatusEnum;
}

export const GetMrpPickErrorsRequestStatusEnum = {
    Error: 'ERROR',
    Inactive: 'INACTIVE',
    Ok: 'OK',
    Unknown: 'UNKNOWN'
} as const;

export type GetMrpPickErrorsRequestStatusEnum = typeof GetMrpPickErrorsRequestStatusEnum[keyof typeof GetMrpPickErrorsRequestStatusEnum];

/**
 * 
 * @export
 * @interface GetMrpPickErrorsResponse
 */
export interface GetMrpPickErrorsResponse {
    [key: string]: any;

    /**
     * 
     * @type {Array<GetMrpPickErrorsResponsePickErrorsInner>}
     * @memberof GetMrpPickErrorsResponse
     */
    'pickErrors': Array<GetMrpPickErrorsResponsePickErrorsInner>;
}
/**
 * 
 * @export
 * @interface GetMrpPickErrorsResponsePickErrorsInner
 */
export interface GetMrpPickErrorsResponsePickErrorsInner {
    /**
     * 
     * @type {number}
     * @memberof GetMrpPickErrorsResponsePickErrorsInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof GetMrpPickErrorsResponsePickErrorsInner
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof GetMrpPickErrorsResponsePickErrorsInner
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof GetMrpPickErrorsResponsePickErrorsInner
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof GetMrpPickErrorsResponsePickErrorsInner
     */
    'mrpId': number;
    /**
     * 
     * @type {number}
     * @memberof GetMrpPickErrorsResponsePickErrorsInner
     */
    'erpSyncOrderId': number;
    /**
     * 
     * @type {number}
     * @memberof GetMrpPickErrorsResponsePickErrorsInner
     */
    'lineItemId': number;
    /**
     * 
     * @type {string}
     * @memberof GetMrpPickErrorsResponsePickErrorsInner
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof GetMrpPickErrorsResponsePickErrorsInner
     */
    'fromWarehouse': string;
    /**
     * 
     * @type {string}
     * @memberof GetMrpPickErrorsResponsePickErrorsInner
     */
    'toWarehouse': string;
    /**
     * 
     * @type {string}
     * @memberof GetMrpPickErrorsResponsePickErrorsInner
     */
    'serialNumber': string;
    /**
     * 
     * @type {string}
     * @memberof GetMrpPickErrorsResponsePickErrorsInner
     */
    'identifier': string;
    /**
     * 
     * @type {string}
     * @memberof GetMrpPickErrorsResponsePickErrorsInner
     */
    'itemNumber': string;
    /**
     * 
     * @type {string}
     * @memberof GetMrpPickErrorsResponsePickErrorsInner
     */
    'lastErrorAt': string;
    /**
     * 
     * @type {number}
     * @memberof GetMrpPickErrorsResponsePickErrorsInner
     */
    'deviationReasonId': number;
    /**
     * 
     * @type {string}
     * @memberof GetMrpPickErrorsResponsePickErrorsInner
     */
    'comment': string;
    /**
     * 
     * @type {number}
     * @memberof GetMrpPickErrorsResponsePickErrorsInner
     */
    'mrpPickLineId': number;
    /**
     * 
     * @type {string}
     * @memberof GetMrpPickErrorsResponsePickErrorsInner
     */
    'status': GetMrpPickErrorsResponsePickErrorsInnerStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetMrpPickErrorsResponsePickErrorsInner
     */
    'orderType': GetMrpPickErrorsResponsePickErrorsInnerOrderTypeEnum;
}

export const GetMrpPickErrorsResponsePickErrorsInnerStatusEnum = {
    Error: 'ERROR',
    Inactive: 'INACTIVE',
    Ok: 'OK',
    Unknown: 'UNKNOWN'
} as const;

export type GetMrpPickErrorsResponsePickErrorsInnerStatusEnum = typeof GetMrpPickErrorsResponsePickErrorsInnerStatusEnum[keyof typeof GetMrpPickErrorsResponsePickErrorsInnerStatusEnum];
export const GetMrpPickErrorsResponsePickErrorsInnerOrderTypeEnum = {
    CreditSale: 'CREDIT_SALE',
    Install: 'INSTALL',
    Partnerprog: 'PARTNERPROG',
    PartnerprogInternal: 'PARTNERPROG_INTERNAL',
    Repair: 'REPAIR',
    Service: 'SERVICE',
    Warranty: 'WARRANTY',
    WarrantyCreditsale: 'WARRANTY_CREDITSALE',
    WarrantyService: 'WARRANTY_SERVICE'
} as const;

export type GetMrpPickErrorsResponsePickErrorsInnerOrderTypeEnum = typeof GetMrpPickErrorsResponsePickErrorsInnerOrderTypeEnum[keyof typeof GetMrpPickErrorsResponsePickErrorsInnerOrderTypeEnum];

/**
 * GetOrderSyncList
 * @export
 * @interface GetOrderSyncList
 */
export interface GetOrderSyncList {
    [key: string]: any;

    /**
     * 
     * @type {GetOrderSyncListSyncParams}
     * @memberof GetOrderSyncList
     */
    'syncParams'?: GetOrderSyncListSyncParams;
}
/**
 * GetOrderSyncListResponse
 * @export
 * @interface GetOrderSyncListResponse
 */
export interface GetOrderSyncListResponse {
    [key: string]: any;

    /**
     * 
     * @type {GetOrderSyncListResponseGetOrderSyncListResult}
     * @memberof GetOrderSyncListResponse
     */
    'GetOrderSyncListResult'?: GetOrderSyncListResponseGetOrderSyncListResult;
}
/**
 * GetOrderSyncListResult
 * @export
 * @interface GetOrderSyncListResponseGetOrderSyncListResult
 */
export interface GetOrderSyncListResponseGetOrderSyncListResult {
    [key: string]: any;

    /**
     * s:int
     * @type {Array<number>}
     * @memberof GetOrderSyncListResponseGetOrderSyncListResult
     */
    'int'?: Array<number>;
}
/**
 * GetOrderSyncListResult
 * @export
 * @interface GetOrderSyncListResult
 */
export interface GetOrderSyncListResult {
    [key: string]: any;

    /**
     * s:int
     * @type {Array<number>}
     * @memberof GetOrderSyncListResult
     */
    'int'?: Array<number>;
}
/**
 * syncParams
 * @export
 * @interface GetOrderSyncListSyncParams
 */
export interface GetOrderSyncListSyncParams {
    [key: string]: any;

    /**
     * s:dateTime
     * @type {string}
     * @memberof GetOrderSyncListSyncParams
     */
    'ChangedAfter'?: string;
    /**
     * s:dateTime
     * @type {string}
     * @memberof GetOrderSyncListSyncParams
     */
    'RegisteredAfter'?: string;
}
/**
 * GetPaymentMethods
 * @export
 * @interface GetPaymentMethods
 */
export interface GetPaymentMethods {
    [key: string]: any;

}
/**
 * GetPaymentMethodsResponse
 * @export
 * @interface GetPaymentMethodsResponse
 */
export interface GetPaymentMethodsResponse {
    [key: string]: any;

    /**
     * 
     * @type {GetPaymentMethodsResponseGetPaymentMethodsResult}
     * @memberof GetPaymentMethodsResponse
     */
    'GetPaymentMethodsResult'?: GetPaymentMethodsResponseGetPaymentMethodsResult;
}
/**
 * GetPaymentMethodsResult
 * @export
 * @interface GetPaymentMethodsResponseGetPaymentMethodsResult
 */
export interface GetPaymentMethodsResponseGetPaymentMethodsResult {
    [key: string]: any;

    /**
     * PaymentMethod[]
     * @type {Array<GetPaymentMethodsResponseGetPaymentMethodsResultPaymentMethodInner>}
     * @memberof GetPaymentMethodsResponseGetPaymentMethodsResult
     */
    'PaymentMethod'?: Array<GetPaymentMethodsResponseGetPaymentMethodsResultPaymentMethodInner>;
}
/**
 * PaymentMethod
 * @export
 * @interface GetPaymentMethodsResponseGetPaymentMethodsResultPaymentMethodInner
 */
export interface GetPaymentMethodsResponseGetPaymentMethodsResultPaymentMethodInner {
    [key: string]: any;

    /**
     * s:int
     * @type {number}
     * @memberof GetPaymentMethodsResponseGetPaymentMethodsResultPaymentMethodInner
     */
    'Id'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof GetPaymentMethodsResponseGetPaymentMethodsResultPaymentMethodInner
     */
    'Name'?: string;
}
/**
 * GetPaymentMethodsResult
 * @export
 * @interface GetPaymentMethodsResult
 */
export interface GetPaymentMethodsResult {
    [key: string]: any;

    /**
     * PaymentMethod[]
     * @type {Array<GetPaymentMethodsResponseGetPaymentMethodsResultPaymentMethodInner>}
     * @memberof GetPaymentMethodsResult
     */
    'PaymentMethod'?: Array<GetPaymentMethodsResponseGetPaymentMethodsResultPaymentMethodInner>;
}
/**
 * 
 * @export
 * @interface GetPickErrorDeviationReasonsResponse
 */
export interface GetPickErrorDeviationReasonsResponse {
    [key: string]: any;

    /**
     * 
     * @type {Array<GetPickErrorDeviationReasonsResponseReasonsInner>}
     * @memberof GetPickErrorDeviationReasonsResponse
     */
    'reasons': Array<GetPickErrorDeviationReasonsResponseReasonsInner>;
}
/**
 * 
 * @export
 * @interface GetPickErrorDeviationReasonsResponseReasonsInner
 */
export interface GetPickErrorDeviationReasonsResponseReasonsInner {
    /**
     * 
     * @type {number}
     * @memberof GetPickErrorDeviationReasonsResponseReasonsInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof GetPickErrorDeviationReasonsResponseReasonsInner
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface GetPublicHolidaysResponse
 */
export interface GetPublicHolidaysResponse {
    [key: string]: any;

    /**
     * 
     * @type {Array<GetPublicHolidaysResponsePublicHolidaysInner>}
     * @memberof GetPublicHolidaysResponse
     */
    'publicHolidays': Array<GetPublicHolidaysResponsePublicHolidaysInner>;
}
/**
 * 
 * @export
 * @interface GetPublicHolidaysResponsePublicHolidaysInner
 */
export interface GetPublicHolidaysResponsePublicHolidaysInner {
    /**
     * 
     * @type {string}
     * @memberof GetPublicHolidaysResponsePublicHolidaysInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetPublicHolidaysResponsePublicHolidaysInner
     */
    'day': string;
    /**
     * 
     * @type {number}
     * @memberof GetPublicHolidaysResponsePublicHolidaysInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof GetPublicHolidaysResponsePublicHolidaysInner
     */
    'date': string;
}
/**
 * 
 * @export
 * @interface GetSMSMessagesResponse
 */
export interface GetSMSMessagesResponse {
    [key: string]: any;

    /**
     * 
     * @type {Array<GetSMSMessagesResponseMessagesInner>}
     * @memberof GetSMSMessagesResponse
     */
    'messages': Array<GetSMSMessagesResponseMessagesInner>;
}
/**
 * 
 * @export
 * @interface GetSMSMessagesResponseMessagesInner
 */
export interface GetSMSMessagesResponseMessagesInner {
    /**
     * 
     * @type {string}
     * @memberof GetSMSMessagesResponseMessagesInner
     */
    'error': string;
    /**
     * 
     * @type {number}
     * @memberof GetSMSMessagesResponseMessagesInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof GetSMSMessagesResponseMessagesInner
     */
    'status': GetSMSMessagesResponseMessagesInnerStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof GetSMSMessagesResponseMessagesInner
     */
    'orderId': number;
    /**
     * 
     * @type {string}
     * @memberof GetSMSMessagesResponseMessagesInner
     */
    'created': string;
    /**
     * 
     * @type {number}
     * @memberof GetSMSMessagesResponseMessagesInner
     */
    'customerId': number;
    /**
     * 
     * @type {string}
     * @memberof GetSMSMessagesResponseMessagesInner
     */
    'sent': string;
    /**
     * 
     * @type {string}
     * @memberof GetSMSMessagesResponseMessagesInner
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof GetSMSMessagesResponseMessagesInner
     */
    'hash': string;
    /**
     * 
     * @type {string}
     * @memberof GetSMSMessagesResponseMessagesInner
     */
    'to': string;
    /**
     * 
     * @type {string}
     * @memberof GetSMSMessagesResponseMessagesInner
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof GetSMSMessagesResponseMessagesInner
     */
    'deleteDate': string;
    /**
     * 
     * @type {number}
     * @memberof GetSMSMessagesResponseMessagesInner
     */
    'retries': number;
    /**
     * 
     * @type {boolean}
     * @memberof GetSMSMessagesResponseMessagesInner
     */
    'retry': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSMSMessagesResponseMessagesInner
     */
    'reply': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetSMSMessagesResponseMessagesInner
     */
    'sveveId': string;
    /**
     * 
     * @type {string}
     * @memberof GetSMSMessagesResponseMessagesInner
     */
    'multiGUID': string;
    /**
     * 
     * @type {string}
     * @memberof GetSMSMessagesResponseMessagesInner
     */
    'replyMessage': string;
    /**
     * 
     * @type {string}
     * @memberof GetSMSMessagesResponseMessagesInner
     */
    'replyDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetSMSMessagesResponseMessagesInner
     */
    'replyHandled': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetSMSMessagesResponseMessagesInner
     */
    'message': string;
}

export const GetSMSMessagesResponseMessagesInnerStatusEnum = {
    ApiError: 'API_ERROR',
    Error: 'ERROR',
    Resent: 'RESENT',
    Sent: 'SENT',
    Unsent: 'UNSENT'
} as const;

export type GetSMSMessagesResponseMessagesInnerStatusEnum = typeof GetSMSMessagesResponseMessagesInnerStatusEnum[keyof typeof GetSMSMessagesResponseMessagesInnerStatusEnum];

/**
 * @type GetSMSRepliesCommand
 * @export
 */
export type GetSMSRepliesCommand = GetSMSRepliesCommandOneOf | GetSMSRepliesCommandOneOf1;

/**
 * 
 * @export
 * @interface GetSMSRepliesCommandOneOf
 */
export interface GetSMSRepliesCommandOneOf {
    /**
     * 
     * @type {boolean}
     * @memberof GetSMSRepliesCommandOneOf
     */
    'yesSearch': GetSMSRepliesCommandOneOfYesSearchEnum;
}

export const GetSMSRepliesCommandOneOfYesSearchEnum = {
    True: true
} as const;

export type GetSMSRepliesCommandOneOfYesSearchEnum = typeof GetSMSRepliesCommandOneOfYesSearchEnum[keyof typeof GetSMSRepliesCommandOneOfYesSearchEnum];

/**
 * 
 * @export
 * @interface GetSMSRepliesCommandOneOf1
 */
export interface GetSMSRepliesCommandOneOf1 {
    /**
     * 
     * @type {string}
     * @memberof GetSMSRepliesCommandOneOf1
     */
    'search': string;
}
/**
 * 
 * @export
 * @interface GetSMSesCommand
 */
export interface GetSMSesCommand {
    /**
     * 
     * @type {string}
     * @memberof GetSMSesCommand
     */
    'status'?: GetSMSesCommandStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GetSMSesCommand
     */
    'to'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSMSesCommand
     */
    'from'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetSMSesCommand
     */
    'orderId'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetSMSesCommand
     */
    'type'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetSMSesCommand
     */
    'retriesMoreThan'?: number;
    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof GetSMSesCommand
     */
    'startTime'?: InstallItemUsageAlternativeCreateInputCachedAt;
    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof GetSMSesCommand
     */
    'endTime'?: InstallItemUsageAlternativeCreateInputCachedAt;
}

export const GetSMSesCommandStatusEnum = {
    ApiError: 'API_ERROR',
    Error: 'ERROR',
    Resent: 'RESENT',
    Sent: 'SENT',
    Unsent: 'UNSENT'
} as const;

export type GetSMSesCommandStatusEnum = typeof GetSMSesCommandStatusEnum[keyof typeof GetSMSesCommandStatusEnum];

/**
 * 
 * @export
 * @interface GetSMSesResponse
 */
export interface GetSMSesResponse extends Array<GetSMSesResponseInner> {
}
/**
 * 
 * @export
 * @interface GetSMSesResponseInner
 */
export interface GetSMSesResponseInner {
    /**
     * 
     * @type {string}
     * @memberof GetSMSesResponseInner
     */
    'error': string;
    /**
     * 
     * @type {number}
     * @memberof GetSMSesResponseInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof GetSMSesResponseInner
     */
    'status': GetSMSesResponseInnerStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof GetSMSesResponseInner
     */
    'orderId': number;
    /**
     * 
     * @type {string}
     * @memberof GetSMSesResponseInner
     */
    'created': string;
    /**
     * 
     * @type {number}
     * @memberof GetSMSesResponseInner
     */
    'customerId': number;
    /**
     * 
     * @type {string}
     * @memberof GetSMSesResponseInner
     */
    'sent': string;
    /**
     * 
     * @type {string}
     * @memberof GetSMSesResponseInner
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof GetSMSesResponseInner
     */
    'hash': string;
    /**
     * 
     * @type {string}
     * @memberof GetSMSesResponseInner
     */
    'to': string;
    /**
     * 
     * @type {string}
     * @memberof GetSMSesResponseInner
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof GetSMSesResponseInner
     */
    'deleteDate': string;
    /**
     * 
     * @type {number}
     * @memberof GetSMSesResponseInner
     */
    'retries': number;
    /**
     * 
     * @type {boolean}
     * @memberof GetSMSesResponseInner
     */
    'retry': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetSMSesResponseInner
     */
    'reply': boolean;
    /**
     * 
     * @type {string}
     * @memberof GetSMSesResponseInner
     */
    'sveveId': string;
    /**
     * 
     * @type {string}
     * @memberof GetSMSesResponseInner
     */
    'multiGUID': string;
    /**
     * 
     * @type {string}
     * @memberof GetSMSesResponseInner
     */
    'replyMessage': string;
    /**
     * 
     * @type {string}
     * @memberof GetSMSesResponseInner
     */
    'replyDate': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetSMSesResponseInner
     */
    'replyHandled': boolean;
}

export const GetSMSesResponseInnerStatusEnum = {
    ApiError: 'API_ERROR',
    Error: 'ERROR',
    Resent: 'RESENT',
    Sent: 'SENT',
    Unsent: 'UNSENT'
} as const;

export type GetSMSesResponseInnerStatusEnum = typeof GetSMSesResponseInnerStatusEnum[keyof typeof GetSMSesResponseInnerStatusEnum];

/**
 * 
 * @export
 * @interface InstallItemUsageAlternativeCreateInput
 */
export interface InstallItemUsageAlternativeCreateInput {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof InstallItemUsageAlternativeCreateInput
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageAlternativeCreateInput
     */
    'questionId': number;
    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof InstallItemUsageAlternativeCreateInput
     */
    'cachedAt'?: InstallItemUsageAlternativeCreateInputCachedAt;
    /**
     * 
     * @type {boolean}
     * @memberof InstallItemUsageAlternativeCreateInput
     */
    'useSalesOrder'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageAlternativeCreateInput
     */
    'quantityFactor'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageAlternativeCreateInput
     */
    'flatAddition'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageAlternativeCreateInput
     */
    'storageUnitConversion'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InstallItemUsageAlternativeCreateInput
     */
    'itemNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof InstallItemUsageAlternativeCreateInput
     */
    'questionName'?: string;
    /**
     * 
     * @type {string}
     * @memberof InstallItemUsageAlternativeCreateInput
     */
    'answer'?: string;
}
/**
 * @type InstallItemUsageAlternativeCreateInputCachedAt
 * @export
 */
export type InstallItemUsageAlternativeCreateInputCachedAt = string;

/**
 * 
 * @export
 * @interface InstallItemUsageAlternativeUpdateInput
 */
export interface InstallItemUsageAlternativeUpdateInput {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof InstallItemUsageAlternativeUpdateInput
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageAlternativeUpdateInput
     */
    'questionId': number;
    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof InstallItemUsageAlternativeUpdateInput
     */
    'cachedAt'?: InstallItemUsageAlternativeCreateInputCachedAt;
    /**
     * 
     * @type {boolean}
     * @memberof InstallItemUsageAlternativeUpdateInput
     */
    'useSalesOrder'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageAlternativeUpdateInput
     */
    'quantityFactor'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageAlternativeUpdateInput
     */
    'flatAddition'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageAlternativeUpdateInput
     */
    'storageUnitConversion'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InstallItemUsageAlternativeUpdateInput
     */
    'itemNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof InstallItemUsageAlternativeUpdateInput
     */
    'questionName'?: string;
    /**
     * 
     * @type {string}
     * @memberof InstallItemUsageAlternativeUpdateInput
     */
    'answer'?: string;
}
/**
 * 
 * @export
 * @interface InstallItemUsageQuantityCreateInput
 */
export interface InstallItemUsageQuantityCreateInput {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageQuantityCreateInput
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageQuantityCreateInput
     */
    'meter': number;
}
/**
 * 
 * @export
 * @interface InstallItemUsageQuantityUpdateInput
 */
export interface InstallItemUsageQuantityUpdateInput {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageQuantityUpdateInput
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageQuantityUpdateInput
     */
    'meter': number;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageQuantityUpdateInput
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface InstallItemUsageUpdateInput
 */
export interface InstallItemUsageUpdateInput {
    [key: string]: any;

    /**
     * 
     * @type {Array<InstallItemUsageUpdateInputQuantitiesInner>}
     * @memberof InstallItemUsageUpdateInput
     */
    'quantities': Array<InstallItemUsageUpdateInputQuantitiesInner>;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageUpdateInput
     */
    'staticQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageUpdateInput
     */
    'discount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageUpdateInput
     */
    'flatAddition'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageUpdateInput
     */
    'storageUnitConversion'?: number | null;
}
/**
 * 
 * @export
 * @interface InstallItemUsageUpdateInputQuantitiesInner
 */
export interface InstallItemUsageUpdateInputQuantitiesInner {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageUpdateInputQuantitiesInner
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageUpdateInputQuantitiesInner
     */
    'meter': number;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageUpdateInputQuantitiesInner
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface InstallItemUsageWithAllMapped
 */
export interface InstallItemUsageWithAllMapped {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageWithAllMapped
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof InstallItemUsageWithAllMapped
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof InstallItemUsageWithAllMapped
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof InstallItemUsageWithAllMapped
     */
    'itemNumber': string;
    /**
     * 
     * @type {string}
     * @memberof InstallItemUsageWithAllMapped
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof InstallItemUsageWithAllMapped
     */
    'cachedAt': string;
    /**
     * 
     * @type {Array<InstallItemUsageWithAllMappedChangelogInner>}
     * @memberof InstallItemUsageWithAllMapped
     */
    'changelog': Array<InstallItemUsageWithAllMappedChangelogInner>;
    /**
     * 
     * @type {Array<InstallItemUsageWithAllMappedQuantitiesInner>}
     * @memberof InstallItemUsageWithAllMapped
     */
    'quantities': Array<InstallItemUsageWithAllMappedQuantitiesInner>;
    /**
     * 
     * @type {Array<InstallItemUsageWithAllMappedAlternativesInner>}
     * @memberof InstallItemUsageWithAllMapped
     */
    'alternatives': Array<InstallItemUsageWithAllMappedAlternativesInner>;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageWithAllMapped
     */
    'staticQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageWithAllMapped
     */
    'flatAddition'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageWithAllMapped
     */
    'storageUnitConversion'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageWithAllMapped
     */
    'discount'?: number | null;
}
/**
 * 
 * @export
 * @interface InstallItemUsageWithAllMappedAlternativesInner
 */
export interface InstallItemUsageWithAllMappedAlternativesInner {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof InstallItemUsageWithAllMappedAlternativesInner
     */
    'answer': string;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageWithAllMappedAlternativesInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof InstallItemUsageWithAllMappedAlternativesInner
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof InstallItemUsageWithAllMappedAlternativesInner
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof InstallItemUsageWithAllMappedAlternativesInner
     */
    'itemNumber': string;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageWithAllMappedAlternativesInner
     */
    'questionId': number;
    /**
     * 
     * @type {string}
     * @memberof InstallItemUsageWithAllMappedAlternativesInner
     */
    'questionName': string;
    /**
     * 
     * @type {string}
     * @memberof InstallItemUsageWithAllMappedAlternativesInner
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof InstallItemUsageWithAllMappedAlternativesInner
     */
    'cachedAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof InstallItemUsageWithAllMappedAlternativesInner
     */
    'useSalesOrder': boolean;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageWithAllMappedAlternativesInner
     */
    'itemUsageId': number;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageWithAllMappedAlternativesInner
     */
    'quantityFactor'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageWithAllMappedAlternativesInner
     */
    'flatAddition'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageWithAllMappedAlternativesInner
     */
    'storageUnitConversion'?: number | null;
}
/**
 * 
 * @export
 * @interface InstallItemUsageWithAllMappedChangelogInner
 */
export interface InstallItemUsageWithAllMappedChangelogInner {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageWithAllMappedChangelogInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof InstallItemUsageWithAllMappedChangelogInner
     */
    'itemNumber': string;
    /**
     * 
     * @type {string}
     * @memberof InstallItemUsageWithAllMappedChangelogInner
     */
    'timestamp': string;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageWithAllMappedChangelogInner
     */
    'itemUsageId': number;
    /**
     * 
     * @type {string}
     * @memberof InstallItemUsageWithAllMappedChangelogInner
     */
    'field': string;
    /**
     * 
     * @type {string}
     * @memberof InstallItemUsageWithAllMappedChangelogInner
     */
    'oldValue': string;
    /**
     * 
     * @type {string}
     * @memberof InstallItemUsageWithAllMappedChangelogInner
     */
    'newValue': string;
    /**
     * 
     * @type {string}
     * @memberof InstallItemUsageWithAllMappedChangelogInner
     */
    'changedBy': string;
}
/**
 * 
 * @export
 * @interface InstallItemUsageWithAllMappedQuantitiesInner
 */
export interface InstallItemUsageWithAllMappedQuantitiesInner {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageWithAllMappedQuantitiesInner
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageWithAllMappedQuantitiesInner
     */
    'itemUsageId': number;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageWithAllMappedQuantitiesInner
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageWithAllMappedQuantitiesInner
     */
    'meter': number;
}
/**
 * 
 * @export
 * @interface InstallItemUsageWithAlternativesCreateInput
 */
export interface InstallItemUsageWithAlternativesCreateInput {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof InstallItemUsageWithAlternativesCreateInput
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof InstallItemUsageWithAlternativesCreateInput
     */
    'itemNumber': string;
    /**
     * 
     * @type {Array<InstallItemUsageWithAlternativesCreateInputAlternativesInner>}
     * @memberof InstallItemUsageWithAlternativesCreateInput
     */
    'alternatives': Array<InstallItemUsageWithAlternativesCreateInputAlternativesInner>;
    /**
     * 
     * @type {Array<InstallItemUsageWithAlternativesCreateInputQuantitiesInner>}
     * @memberof InstallItemUsageWithAlternativesCreateInput
     */
    'quantities': Array<InstallItemUsageWithAlternativesCreateInputQuantitiesInner>;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageWithAlternativesCreateInput
     */
    'staticQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageWithAlternativesCreateInput
     */
    'discount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageWithAlternativesCreateInput
     */
    'flatAddition'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageWithAlternativesCreateInput
     */
    'storageUnitConversion'?: number | null;
}
/**
 * 
 * @export
 * @interface InstallItemUsageWithAlternativesCreateInputAlternativesInner
 */
export interface InstallItemUsageWithAlternativesCreateInputAlternativesInner {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof InstallItemUsageWithAlternativesCreateInputAlternativesInner
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageWithAlternativesCreateInputAlternativesInner
     */
    'questionId': number;
    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof InstallItemUsageWithAlternativesCreateInputAlternativesInner
     */
    'cachedAt'?: InstallItemUsageAlternativeCreateInputCachedAt;
    /**
     * 
     * @type {boolean}
     * @memberof InstallItemUsageWithAlternativesCreateInputAlternativesInner
     */
    'useSalesOrder'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageWithAlternativesCreateInputAlternativesInner
     */
    'quantityFactor'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageWithAlternativesCreateInputAlternativesInner
     */
    'flatAddition'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageWithAlternativesCreateInputAlternativesInner
     */
    'storageUnitConversion'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InstallItemUsageWithAlternativesCreateInputAlternativesInner
     */
    'itemNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof InstallItemUsageWithAlternativesCreateInputAlternativesInner
     */
    'questionName'?: string;
    /**
     * 
     * @type {string}
     * @memberof InstallItemUsageWithAlternativesCreateInputAlternativesInner
     */
    'answer'?: string;
}
/**
 * 
 * @export
 * @interface InstallItemUsageWithAlternativesCreateInputQuantitiesInner
 */
export interface InstallItemUsageWithAlternativesCreateInputQuantitiesInner {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageWithAlternativesCreateInputQuantitiesInner
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof InstallItemUsageWithAlternativesCreateInputQuantitiesInner
     */
    'meter': number;
}
/**
 * InvoiceOrder
 * @export
 * @interface InvoiceOrder
 */
export interface InvoiceOrder {
    [key: string]: any;

    /**
     * s:int
     * @type {number}
     * @memberof InvoiceOrder
     */
    'OrderId'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof InvoiceOrder
     */
    'CustomerId'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof InvoiceOrder
     */
    'CustomerName'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof InvoiceOrder
     */
    'CustomerDeliveryName'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof InvoiceOrder
     */
    'CustomerDeliveryPhone'?: string;
    /**
     * 
     * @type {GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerAddresses}
     * @memberof InvoiceOrder
     */
    'Addresses'?: GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerAddresses;
    /**
     * OrderSlipStateType|s:string|None,Offer,Pack,Invoiced,Web,Repeating,CollectingOrder,Return,Distribution,Rest,ForInvoicing,OvertimeRent,Confirmed,OrderState_Sent,Production,PaymentReminder,OnAccount,Lending,InvoicedCashAccount,InvoicedPack,Inactive,Hours,OrderState_SuperStore,Draft
     * @type {string}
     * @memberof InvoiceOrder
     */
    'OrderStatus'?: InvoiceOrderOrderStatusEnum;
    /**
     * s:int
     * @type {number}
     * @memberof InvoiceOrder
     */
    'InvoiceId'?: number;
    /**
     * 
     * @type {GetInvoicesSearchParamsChangedAfter}
     * @memberof InvoiceOrder
     */
    'DateOrdered'?: GetInvoicesSearchParamsChangedAfter;
    /**
     * 
     * @type {GetInvoicesSearchParamsChangedAfter}
     * @memberof InvoiceOrder
     */
    'DateInvoiced'?: GetInvoicesSearchParamsChangedAfter;
    /**
     * 
     * @type {GetInvoicesSearchParamsChangedAfter}
     * @memberof InvoiceOrder
     */
    'DateChanged'?: GetInvoicesSearchParamsChangedAfter;
    /**
     * s:int
     * @type {number}
     * @memberof InvoiceOrder
     */
    'PaymentTime'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof InvoiceOrder
     */
    'CustomerReferenceNo'?: string;
    /**
     * s:int
     * @type {number}
     * @memberof InvoiceOrder
     */
    'ProjectId'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof InvoiceOrder
     */
    'OurReference'?: number;
    /**
     * s:boolean
     * @type {boolean}
     * @memberof InvoiceOrder
     */
    'IncludeVAT'?: boolean;
    /**
     * s:string
     * @type {string}
     * @memberof InvoiceOrder
     */
    'YourReference'?: string;
    /**
     * s:decimal
     * @type {number}
     * @memberof InvoiceOrder
     */
    'OrderTotalIncVat'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof InvoiceOrder
     */
    'OrderTotalVat'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof InvoiceOrder
     */
    'InvoiceTitle'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof InvoiceOrder
     */
    'InvoiceText'?: string;
    /**
     * s:dateTime
     * @type {string}
     * @memberof InvoiceOrder
     */
    'Paid'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof InvoiceOrder
     */
    'OCR'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof InvoiceOrder
     */
    'CustomerOrgNo'?: string;
    /**
     * 
     * @type {GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerCurrency}
     * @memberof InvoiceOrder
     */
    'Currency'?: GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerCurrency;
    /**
     * s:int
     * @type {number}
     * @memberof InvoiceOrder
     */
    'PaymentMethodId'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof InvoiceOrder
     */
    'PaymentAmount'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof InvoiceOrder
     */
    'ProductionManagerId'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof InvoiceOrder
     */
    'SalesOpportunityId'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof InvoiceOrder
     */
    'TypeOfSaleId'?: number;
    /**
     * Distributor|s:string|Default,Manual,Auto
     * @type {string}
     * @memberof InvoiceOrder
     */
    'Distributor'?: InvoiceOrderDistributorEnum;
    /**
     * DistributionMethod|s:string|Unchanged,Print,EMail,ElectronicInvoice
     * @type {string}
     * @memberof InvoiceOrder
     */
    'DistributionMethod'?: InvoiceOrderDistributionMethodEnum;
    /**
     * s:int
     * @type {number}
     * @memberof InvoiceOrder
     */
    'DepartmentId'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof InvoiceOrder
     */
    'ExternalStatus'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof InvoiceOrder
     */
    'InvoiceEmailAddress'?: string;
    /**
     * 
     * @type {GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRows}
     * @memberof InvoiceOrder
     */
    'InvoiceRows'?: GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRows;
    /**
     * 
     * @type {GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerAPIException}
     * @memberof InvoiceOrder
     */
    'APIException'?: GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerAPIException;
    /**
     * s:string
     * @type {string}
     * @memberof InvoiceOrder
     */
    'ProductionNumber'?: string;
    /**
     * 
     * @type {GetInvoicesSearchParamsChangedAfter}
     * @memberof InvoiceOrder
     */
    'DeliveryDate'?: GetInvoicesSearchParamsChangedAfter;
    /**
     * s:int
     * @type {number}
     * @memberof InvoiceOrder
     */
    'ReferenceInvoiceId'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof InvoiceOrder
     */
    'ReferenceOrderId'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof InvoiceOrder
     */
    'ReferenceNumber'?: string;
    /**
     * s:boolean
     * @type {boolean}
     * @memberof InvoiceOrder
     */
    'SkipStock'?: boolean;
    /**
     * s:dateTime
     * @type {string}
     * @memberof InvoiceOrder
     */
    'AccrualDate'?: string;
    /**
     * s:int
     * @type {number}
     * @memberof InvoiceOrder
     */
    'AccrualLength'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof InvoiceOrder
     */
    'RoundFactor'?: number;
    /**
     * guid|s:string|pattern
     * @type {string}
     * @memberof InvoiceOrder
     */
    'InvoiceTemplateId'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof InvoiceOrder
     */
    'VippsNumber'?: string;
    /**
     * 
     * @type {GetDeliveryMethodsResponseGetDeliveryMethodsResultDeliveryMethodInner}
     * @memberof InvoiceOrder
     */
    'DeliveryMethod'?: GetDeliveryMethodsResponseGetDeliveryMethodsResultDeliveryMethodInner;
    /**
     * s:string
     * @type {string}
     * @memberof InvoiceOrder
     */
    'DeliveryAlternative'?: string;
    /**
     * s:boolean
     * @type {boolean}
     * @memberof InvoiceOrder
     */
    'SendToFactoring'?: boolean;
    /**
     * s:decimal
     * @type {number}
     * @memberof InvoiceOrder
     */
    'Commission'?: number;
    /**
     * 
     * @type {GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerUserDefinedDimensions}
     * @memberof InvoiceOrder
     */
    'UserDefinedDimensions'?: GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerUserDefinedDimensions;
    /**
     * s:string
     * @type {string}
     * @memberof InvoiceOrder
     */
    'GLNNumber'?: string;
    /**
     * s:int
     * @type {number}
     * @memberof InvoiceOrder
     */
    'CustomerDeliveryId'?: number;
}

export const InvoiceOrderOrderStatusEnum = {
    CollectingOrder: 'CollectingOrder',
    Confirmed: 'Confirmed',
    Distribution: 'Distribution',
    Draft: 'Draft',
    ForInvoicing: 'ForInvoicing',
    Hours: 'Hours',
    Inactive: 'Inactive',
    Invoiced: 'Invoiced',
    InvoicedCashAccount: 'InvoicedCashAccount',
    InvoicedPack: 'InvoicedPack',
    Lending: 'Lending',
    None: 'None',
    Offer: 'Offer',
    OnAccount: 'OnAccount',
    OrderStateSent: 'OrderState_Sent',
    OrderStateSuperStore: 'OrderState_SuperStore',
    OvertimeRent: 'OvertimeRent',
    Pack: 'Pack',
    PaymentReminder: 'PaymentReminder',
    Production: 'Production',
    Repeating: 'Repeating',
    Rest: 'Rest',
    Return: 'Return',
    Web: 'Web'
} as const;

export type InvoiceOrderOrderStatusEnum = typeof InvoiceOrderOrderStatusEnum[keyof typeof InvoiceOrderOrderStatusEnum];
export const InvoiceOrderDistributorEnum = {
    Auto: 'Auto',
    Default: 'Default',
    Manual: 'Manual'
} as const;

export type InvoiceOrderDistributorEnum = typeof InvoiceOrderDistributorEnum[keyof typeof InvoiceOrderDistributorEnum];
export const InvoiceOrderDistributionMethodEnum = {
    EMail: 'EMail',
    ElectronicInvoice: 'ElectronicInvoice',
    Print: 'Print',
    Unchanged: 'Unchanged'
} as const;

export type InvoiceOrderDistributionMethodEnum = typeof InvoiceOrderDistributionMethodEnum[keyof typeof InvoiceOrderDistributionMethodEnum];

/**
 * invoiceReturnProperties
 * @export
 * @interface InvoiceReturnProperties
 */
export interface InvoiceReturnProperties {
    [key: string]: any;

    /**
     * s:string
     * @type {Array<string>}
     * @memberof InvoiceReturnProperties
     */
    'string'?: Array<string>;
}
/**
 * InvoiceRow
 * @export
 * @interface InvoiceRow
 */
export interface InvoiceRow {
    [key: string]: any;

    /**
     * s:int
     * @type {number}
     * @memberof InvoiceRow
     */
    'ProductId'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof InvoiceRow
     */
    'RowId'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof InvoiceRow
     */
    'VatRate'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof InvoiceRow
     */
    'Price'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof InvoiceRow
     */
    'Name'?: string;
    /**
     * s:decimal
     * @type {number}
     * @memberof InvoiceRow
     */
    'DiscountRate'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof InvoiceRow
     */
    'Quantity'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof InvoiceRow
     */
    'QuantityDelivered'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof InvoiceRow
     */
    'QuantityOrdered'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof InvoiceRow
     */
    'QuantityRest'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof InvoiceRow
     */
    'Cost'?: number;
    /**
     * ChangeState|s:string|None,Add,Edit,Delete
     * @type {string}
     * @memberof InvoiceRow
     */
    'ChangeState'?: InvoiceRowChangeStateEnum;
    /**
     * s:int
     * @type {number}
     * @memberof InvoiceRow
     */
    'TypeGroupId'?: number;
    /**
     * s:short
     * @type {number}
     * @memberof InvoiceRow
     */
    'SequenceNumber'?: number;
    /**
     * s:boolean
     * @type {boolean}
     * @memberof InvoiceRow
     */
    'Hidden'?: boolean;
    /**
     * RowType|s:string|Normal,Text,TextBold
     * @type {string}
     * @memberof InvoiceRow
     */
    'Type'?: InvoiceRowTypeEnum;
    /**
     * s:decimal
     * @type {number}
     * @memberof InvoiceRow
     */
    'InPrice'?: number;
    /**
     * s:dateTime
     * @type {string}
     * @memberof InvoiceRow
     */
    'AccrualDate'?: string;
    /**
     * s:int
     * @type {number}
     * @memberof InvoiceRow
     */
    'AccrualLength'?: number;
    /**
     * s:boolean
     * @type {boolean}
     * @memberof InvoiceRow
     */
    'AccountProject'?: boolean;
    /**
     * s:int
     * @type {number}
     * @memberof InvoiceRow
     */
    'ProjectId'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof InvoiceRow
     */
    'DepartmentId'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof InvoiceRow
     */
    'ProductNo'?: string;
    /**
     * 
     * @type {GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerTaxSettings}
     * @memberof InvoiceRow
     */
    'TaxSettings'?: GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerTaxSettings;
    /**
     * 
     * @type {GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerUserDefinedDimensions}
     * @memberof InvoiceRow
     */
    'UserDefinedDimensions'?: GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerUserDefinedDimensions;
    /**
     * s:string
     * @type {string}
     * @memberof InvoiceRow
     */
    'SerialNumber'?: string;
}

export const InvoiceRowChangeStateEnum = {
    Add: 'Add',
    Delete: 'Delete',
    Edit: 'Edit',
    None: 'None'
} as const;

export type InvoiceRowChangeStateEnum = typeof InvoiceRowChangeStateEnum[keyof typeof InvoiceRowChangeStateEnum];
export const InvoiceRowTypeEnum = {
    Normal: 'Normal',
    Text: 'Text',
    TextBold: 'TextBold'
} as const;

export type InvoiceRowTypeEnum = typeof InvoiceRowTypeEnum[keyof typeof InvoiceRowTypeEnum];

/**
 * InvoiceRows
 * @export
 * @interface InvoiceRows
 */
export interface InvoiceRows {
    [key: string]: any;

    /**
     * InvoiceRow[]
     * @type {Array<GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInner>}
     * @memberof InvoiceRows
     */
    'InvoiceRow'?: Array<GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInner>;
}
/**
 * 
 * @export
 * @interface Item
 */
export interface Item {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof Item
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof Item
     */
    'parent_id': number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof Item
     */
    'properties': Array<AnswerPropertiesInner>;
    /**
     * 
     * @type {Array<ItemProductInner>}
     * @memberof Item
     */
    'product': Array<ItemProductInner>;
    /**
     * 
     * @type {Array<QuestionAnswerInner>}
     * @memberof Item
     */
    'answer'?: Array<QuestionAnswerInner>;
}
/**
 * 
 * @export
 * @interface ItemListRequest
 */
export interface ItemListRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ItemListRequest
     */
    'ItemNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemListRequest
     */
    'PerPage'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemListRequest
     */
    'Page'?: number;
}
/**
 * 
 * @export
 * @interface ItemListResponse
 */
export interface ItemListResponse extends Array<ItemListResponseInner> {
}
/**
 * 
 * @export
 * @interface ItemListResponseInner
 */
export interface ItemListResponseInner {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof ItemListResponseInner
     */
    'ItemMasterID': number;
    /**
     * 
     * @type {string}
     * @memberof ItemListResponseInner
     */
    'ItemNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ItemListResponseInner
     */
    'Revision': string;
    /**
     * 
     * @type {string}
     * @memberof ItemListResponseInner
     */
    'ItemName': string;
    /**
     * 
     * @type {string}
     * @memberof ItemListResponseInner
     */
    'Description': string;
    /**
     * 
     * @type {string}
     * @memberof ItemListResponseInner
     */
    'Category': string;
    /**
     * 
     * @type {number}
     * @memberof ItemListResponseInner
     */
    'LastCost': number;
    /**
     * 
     * @type {number}
     * @memberof ItemListResponseInner
     */
    'StandardCost': number;
    /**
     * 
     * @type {string}
     * @memberof ItemListResponseInner
     */
    'InventoryUOM': string;
    /**
     * 
     * @type {number}
     * @memberof ItemListResponseInner
     */
    'SafetyStock': number;
    /**
     * 
     * @type {string}
     * @memberof ItemListResponseInner
     */
    'HTSCode': string;
    /**
     * 
     * @type {number}
     * @memberof ItemListResponseInner
     */
    'UnitPrice': number;
    /**
     * 
     * @type {number}
     * @memberof ItemListResponseInner
     */
    'StatusID': number;
    /**
     * 
     * @type {string}
     * @memberof ItemListResponseInner
     */
    'SubCategory': string;
    /**
     * 
     * @type {Array<ItemListResponseItemAdditionalDataValuesInner>}
     * @memberof ItemListResponseInner
     */
    'AdditionalDataValues': Array<ItemListResponseItemAdditionalDataValuesInner>;
}
/**
 * 
 * @export
 * @interface ItemListResponseItem
 */
export interface ItemListResponseItem {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof ItemListResponseItem
     */
    'ItemMasterID': number;
    /**
     * 
     * @type {string}
     * @memberof ItemListResponseItem
     */
    'ItemNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ItemListResponseItem
     */
    'Revision': string;
    /**
     * 
     * @type {string}
     * @memberof ItemListResponseItem
     */
    'ItemName': string;
    /**
     * 
     * @type {string}
     * @memberof ItemListResponseItem
     */
    'Description': string;
    /**
     * 
     * @type {string}
     * @memberof ItemListResponseItem
     */
    'Category': string;
    /**
     * 
     * @type {number}
     * @memberof ItemListResponseItem
     */
    'LastCost': number;
    /**
     * 
     * @type {number}
     * @memberof ItemListResponseItem
     */
    'StandardCost': number;
    /**
     * 
     * @type {string}
     * @memberof ItemListResponseItem
     */
    'InventoryUOM': string;
    /**
     * 
     * @type {number}
     * @memberof ItemListResponseItem
     */
    'SafetyStock': number;
    /**
     * 
     * @type {string}
     * @memberof ItemListResponseItem
     */
    'HTSCode': string;
    /**
     * 
     * @type {number}
     * @memberof ItemListResponseItem
     */
    'UnitPrice': number;
    /**
     * 
     * @type {number}
     * @memberof ItemListResponseItem
     */
    'StatusID': number;
    /**
     * 
     * @type {string}
     * @memberof ItemListResponseItem
     */
    'SubCategory': string;
    /**
     * 
     * @type {Array<ItemListResponseItemAdditionalDataValuesInner>}
     * @memberof ItemListResponseItem
     */
    'AdditionalDataValues': Array<ItemListResponseItemAdditionalDataValuesInner>;
}
/**
 * 
 * @export
 * @interface ItemListResponseItemAdditionalDataValuesInner
 */
export interface ItemListResponseItemAdditionalDataValuesInner {
    [key: string]: any;

    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof ItemListResponseItemAdditionalDataValuesInner
     */
    'ID': ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {string}
     * @memberof ItemListResponseItemAdditionalDataValuesInner
     */
    'Name': string;
    /**
     * 
     * @type {string}
     * @memberof ItemListResponseItemAdditionalDataValuesInner
     */
    'Value': string;
}
/**
 * 
 * @export
 * @interface ItemListResponseItemAdditionalDataValuesInnerID
 */
export interface ItemListResponseItemAdditionalDataValuesInnerID {
}
/**
 * 
 * @export
 * @interface ItemPrices
 */
export interface ItemPrices {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ItemPrices
     */
    'ItemNumber': string;
    /**
     * 
     * @type {number}
     * @memberof ItemPrices
     */
    'Quantity': number;
    /**
     * 
     * @type {number}
     * @memberof ItemPrices
     */
    'PriceEach': number;
    /**
     * 
     * @type {string}
     * @memberof ItemPrices
     */
    'PricingLevel': string;
    /**
     * 
     * @type {string}
     * @memberof ItemPrices
     */
    'Revision': string;
}
/**
 * 
 * @export
 * @interface ItemProductInner
 */
export interface ItemProductInner {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof ItemProductInner
     */
    'id': number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof ItemProductInner
     */
    'properties': Array<AnswerPropertiesInner>;
    /**
     * 
     * @type {Array<ItemProductInnerChecklistInner>}
     * @memberof ItemProductInner
     */
    'checklist': Array<ItemProductInnerChecklistInner>;
}
/**
 * 
 * @export
 * @interface ItemProductInnerChecklistInner
 */
export interface ItemProductInnerChecklistInner {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof ItemProductInnerChecklistInner
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ItemProductInnerChecklistInner
     */
    'parent_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemProductInnerChecklistInner
     */
    '_orderid'?: number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof ItemProductInnerChecklistInner
     */
    'properties': Array<AnswerPropertiesInner>;
    /**
     * 
     * @type {Array<ChecklistQuestionInner>}
     * @memberof ItemProductInnerChecklistInner
     */
    'question': Array<ChecklistQuestionInner>;
}
/**
 * 
 * @export
 * @enum {number}
 */

export const ItemPropertyType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7
} as const;

export type ItemPropertyType = typeof ItemPropertyType[keyof typeof ItemPropertyType];


/**
 * 
 * @export
 * @interface ItemStockDetailRequest
 */
export interface ItemStockDetailRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailRequest
     */
    'ItemNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailRequest
     */
    'Warehouse'?: string;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailRequest
     */
    'SerialNumber'?: string;
}
/**
 * 
 * @export
 * @interface ItemStockDetailResponse
 */
export interface ItemStockDetailResponse extends Array<ItemStockDetailResponseInner> {
}
/**
 * 
 * @export
 * @interface ItemStockDetailResponseInner
 */
export interface ItemStockDetailResponseInner {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof ItemStockDetailResponseInner
     */
    'Identifier': number;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailResponseInner
     */
    'ItemNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailResponseInner
     */
    'Warehouse': string;
    /**
     * 
     * @type {number}
     * @memberof ItemStockDetailResponseInner
     */
    'Quantity': number;
    /**
     * 
     * @type {number}
     * @memberof ItemStockDetailResponseInner
     */
    'UnitCost': number;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailResponseInner
     */
    'ItemStatus': string;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailResponseInner
     */
    'Location': string;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailResponseInner
     */
    'SerialNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailResponseInner
     */
    'LotNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailResponseInner
     */
    'HeatNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailResponseInner
     */
    'ExpirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailResponseInner
     */
    'DimA': string;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailResponseInner
     */
    'DimB': string;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailResponseInner
     */
    'DimC': string;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailResponseInner
     */
    'DimD': string;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailResponseInner
     */
    'DimE': string;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailResponseInner
     */
    'Bin': string;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailResponseInner
     */
    'Category': string;
    /**
     * 
     * @type {Array<ItemListResponseItemAdditionalDataValuesInner>}
     * @memberof ItemStockDetailResponseInner
     */
    'AdditionalDataValues': Array<ItemListResponseItemAdditionalDataValuesInner>;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailResponseInner
     */
    'SubCategory': string;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailResponseInner
     */
    'Notes': string;
}
/**
 * 
 * @export
 * @interface ItemStockDetailResponseItem
 */
export interface ItemStockDetailResponseItem {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof ItemStockDetailResponseItem
     */
    'Identifier': number;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailResponseItem
     */
    'ItemNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailResponseItem
     */
    'Warehouse': string;
    /**
     * 
     * @type {number}
     * @memberof ItemStockDetailResponseItem
     */
    'Quantity': number;
    /**
     * 
     * @type {number}
     * @memberof ItemStockDetailResponseItem
     */
    'UnitCost': number;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailResponseItem
     */
    'ItemStatus': string;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailResponseItem
     */
    'Location': string;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailResponseItem
     */
    'SerialNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailResponseItem
     */
    'LotNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailResponseItem
     */
    'HeatNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailResponseItem
     */
    'ExpirationDate': string;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailResponseItem
     */
    'DimA': string;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailResponseItem
     */
    'DimB': string;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailResponseItem
     */
    'DimC': string;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailResponseItem
     */
    'DimD': string;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailResponseItem
     */
    'DimE': string;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailResponseItem
     */
    'Bin': string;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailResponseItem
     */
    'Category': string;
    /**
     * 
     * @type {Array<ItemListResponseItemAdditionalDataValuesInner>}
     * @memberof ItemStockDetailResponseItem
     */
    'AdditionalDataValues': Array<ItemListResponseItemAdditionalDataValuesInner>;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailResponseItem
     */
    'SubCategory': string;
    /**
     * 
     * @type {string}
     * @memberof ItemStockDetailResponseItem
     */
    'Notes': string;
}
/**
 * 
 * @export
 * @interface ItemStockRequest
 */
export interface ItemStockRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ItemStockRequest
     */
    'ItemNumber'?: string;
    /**
     * 
     * @type {number}
     * @memberof ItemStockRequest
     */
    'Page'?: number;
    /**
     * 
     * @type {number}
     * @memberof ItemStockRequest
     */
    'PerPage'?: number;
}
/**
 * 
 * @export
 * @interface ItemStockResponse
 */
export interface ItemStockResponse extends Array<ItemStockResponseInner> {
}
/**
 * 
 * @export
 * @interface ItemStockResponseInner
 */
export interface ItemStockResponseInner {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof ItemStockResponseInner
     */
    'ItemMasterID': number;
    /**
     * 
     * @type {string}
     * @memberof ItemStockResponseInner
     */
    'ItemNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ItemStockResponseInner
     */
    'Warehouse': string;
    /**
     * 
     * @type {number}
     * @memberof ItemStockResponseInner
     */
    'OnHand': number;
    /**
     * 
     * @type {string}
     * @memberof ItemStockResponseInner
     */
    'Status': string;
    /**
     * 
     * @type {number}
     * @memberof ItemStockResponseInner
     */
    'Available': number;
    /**
     * 
     * @type {number}
     * @memberof ItemStockResponseInner
     */
    'Demand': number;
    /**
     * 
     * @type {number}
     * @memberof ItemStockResponseInner
     */
    'Planned': number;
    /**
     * 
     * @type {string}
     * @memberof ItemStockResponseInner
     */
    'Location': string;
    /**
     * 
     * @type {string}
     * @memberof ItemStockResponseInner
     */
    'SerialNumber': string;
    /**
     * 
     * @type {number}
     * @memberof ItemStockResponseInner
     */
    'Identifier': number;
}
/**
 * 
 * @export
 * @interface ItemStockResponseItem
 */
export interface ItemStockResponseItem {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof ItemStockResponseItem
     */
    'ItemMasterID': number;
    /**
     * 
     * @type {string}
     * @memberof ItemStockResponseItem
     */
    'ItemNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ItemStockResponseItem
     */
    'Warehouse': string;
    /**
     * 
     * @type {number}
     * @memberof ItemStockResponseItem
     */
    'OnHand': number;
    /**
     * 
     * @type {string}
     * @memberof ItemStockResponseItem
     */
    'Status': string;
    /**
     * 
     * @type {number}
     * @memberof ItemStockResponseItem
     */
    'Available': number;
    /**
     * 
     * @type {number}
     * @memberof ItemStockResponseItem
     */
    'Demand': number;
    /**
     * 
     * @type {number}
     * @memberof ItemStockResponseItem
     */
    'Planned': number;
    /**
     * 
     * @type {string}
     * @memberof ItemStockResponseItem
     */
    'Location': string;
    /**
     * 
     * @type {string}
     * @memberof ItemStockResponseItem
     */
    'SerialNumber': string;
    /**
     * 
     * @type {number}
     * @memberof ItemStockResponseItem
     */
    'Identifier': number;
}
/**
 * 
 * @export
 * @interface Lead
 */
export interface Lead {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof Lead
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    'zipCode': string;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    'city': string;
    /**
     * 
     * @type {boolean}
     * @memberof Lead
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    'category': string;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    'agentIdLL': string;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    'agentLL': string;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    'alternativeAgentIdLL': string;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    'alternativeAgentLL': string;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    'agentIdLV': string;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    'agentLV': string;
    /**
     * 
     * @type {number}
     * @memberof Lead
     */
    'installerLyseElektroId': number;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    'installerLyseElektroMamutId': string;
    /**
     * 
     * @type {number}
     * @memberof Lead
     */
    'installerId': number;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    'installerMamutId': string;
    /**
     * 
     * @type {string}
     * @memberof Lead
     */
    'erpDepartment': string;
}
/**
 * 
 * @export
 * @interface LeadAPIRequests
 */
export interface LeadAPIRequests extends Array<LeadAPIRequestsInner> {
}
/**
 * 
 * @export
 * @interface LeadAPIRequestsInner
 */
export interface LeadAPIRequestsInner {
    /**
     * 
     * @type {number}
     * @memberof LeadAPIRequestsInner
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof LeadAPIRequestsInner
     */
    'status': string;
    /**
     * 
     * @type {number}
     * @memberof LeadAPIRequestsInner
     */
    'count': number;
    /**
     * 
     * @type {string}
     * @memberof LeadAPIRequestsInner
     */
    'statusMessage': string;
}
/**
 * 
 * @export
 * @interface LeadProperty
 */
export interface LeadProperty {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof LeadProperty
     */
    'id': number;
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof LeadProperty
     */
    'value': ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {string}
     * @memberof LeadProperty
     */
    'url'?: string;
    /**
     * 
     * @type {number}
     * @memberof LeadProperty
     */
    'type': number;
    /**
     * 
     * @type {string}
     * @memberof LeadProperty
     */
    'strvalue'?: string;
    /**
     * 
     * @type {number}
     * @memberof LeadProperty
     */
    'numvalue'?: number;
    /**
     * Only exists on Lead?
     * @type {string}
     * @memberof LeadProperty
     */
    'created'?: string;
    /**
     * Only exists on Lead?
     * @type {string}
     * @memberof LeadProperty
     */
    'deleted'?: string;
}
/**
 * 
 * @export
 * @enum {number}
 */

export const LeadPropertyType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_16: 16,
    NUMBER_17: 17,
    NUMBER_18: 18,
    NUMBER_19: 19,
    NUMBER_20: 20
} as const;

export type LeadPropertyType = typeof LeadPropertyType[keyof typeof LeadPropertyType];


/**
 * 
 * @export
 * @enum {string}
 */

export const LeadStatus = {
    Solgt: 'Solgt',
    TilbudSendt: 'Tilbud sendt'
} as const;

export type LeadStatus = typeof LeadStatus[keyof typeof LeadStatus];


/**
 * 
 * @export
 * @interface LogEntriesCommand
 */
export interface LogEntriesCommand {
    [key: string]: any;

    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof LogEntriesCommand
     */
    'startTime'?: InstallItemUsageAlternativeCreateInputCachedAt;
    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof LogEntriesCommand
     */
    'endTime'?: InstallItemUsageAlternativeCreateInputCachedAt;
}
/**
 * 
 * @export
 * @interface LogEntriesResponse
 */
export interface LogEntriesResponse extends Array<LogEntriesResponseInner> {
}
/**
 * 
 * @export
 * @interface LogEntriesResponseInner
 */
export interface LogEntriesResponseInner {
    /**
     * 
     * @type {string}
     * @memberof LogEntriesResponseInner
     */
    'message'?: string;
    /**
     * 
     * @type {any}
     * @memberof LogEntriesResponseInner
     */
    'data'?: any;
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof LogEntriesResponseInner
     */
    'severity'?: ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof LogEntriesResponseInner
     */
    'timestamp'?: InstallItemUsageAlternativeCreateInputCachedAt;
}
/**
 * 
 * @export
 * @interface LogEntry
 */
export interface LogEntry {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof LogEntry
     */
    'message'?: string;
    /**
     * 
     * @type {object}
     * @memberof LogEntry
     */
    'data'?: object;
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof LogEntry
     */
    'severity'?: ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof LogEntry
     */
    'timestamp'?: InstallItemUsageAlternativeCreateInputCachedAt;
}
/**
 * 
 * @export
 * @interface LogInRequest
 */
export interface LogInRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof LogInRequest
     */
    'Email': string;
    /**
     * 
     * @type {string}
     * @memberof LogInRequest
     */
    'Password': string;
}
/**
 * 
 * @export
 * @interface LogInResponse
 */
export interface LogInResponse {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof LogInResponse
     */
    'access_token': string;
    /**
     * 
     * @type {string}
     * @memberof LogInResponse
     */
    'token_type': string;
    /**
     * 
     * @type {number}
     * @memberof LogInResponse
     */
    'expires_in': number;
    /**
     * 
     * @type {string}
     * @memberof LogInResponse
     */
    'userName': string;
    /**
     * 
     * @type {string}
     * @memberof LogInResponse
     */
    'requireOTP': string;
    /**
     * 
     * @type {string}
     * @memberof LogInResponse
     */
    '.issued': string;
    /**
     * 
     * @type {string}
     * @memberof LogInResponse
     */
    '.expires': string;
    /**
     * 
     * @type {string}
     * @memberof LogInResponse
     */
    'apikey': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const MRPEnv = {
    Sandbox: 'sandbox',
    Test: 'test',
    Prod: 'prod'
} as const;

export type MRPEnv = typeof MRPEnv[keyof typeof MRPEnv];


/**
 * 
 * @export
 * @interface MrpPickErrorList
 */
export interface MrpPickErrorList extends Array<MrpPickErrorListInner> {
}
/**
 * 
 * @export
 * @interface MrpPickErrorListInner
 */
export interface MrpPickErrorListInner {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof MrpPickErrorListInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof MrpPickErrorListInner
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof MrpPickErrorListInner
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof MrpPickErrorListInner
     */
    'updatedAt': string;
    /**
     * 
     * @type {number}
     * @memberof MrpPickErrorListInner
     */
    'mrpId': number;
    /**
     * 
     * @type {number}
     * @memberof MrpPickErrorListInner
     */
    'erpSyncOrderId': number;
    /**
     * 
     * @type {number}
     * @memberof MrpPickErrorListInner
     */
    'lineItemId': number;
    /**
     * 
     * @type {string}
     * @memberof MrpPickErrorListInner
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof MrpPickErrorListInner
     */
    'fromWarehouse': string;
    /**
     * 
     * @type {string}
     * @memberof MrpPickErrorListInner
     */
    'toWarehouse': string;
    /**
     * 
     * @type {string}
     * @memberof MrpPickErrorListInner
     */
    'serialNumber': string;
    /**
     * 
     * @type {string}
     * @memberof MrpPickErrorListInner
     */
    'identifier': string;
    /**
     * 
     * @type {string}
     * @memberof MrpPickErrorListInner
     */
    'itemNumber': string;
    /**
     * 
     * @type {string}
     * @memberof MrpPickErrorListInner
     */
    'lastErrorAt': string;
    /**
     * 
     * @type {number}
     * @memberof MrpPickErrorListInner
     */
    'deviationReasonId': number;
    /**
     * 
     * @type {string}
     * @memberof MrpPickErrorListInner
     */
    'comment': string;
    /**
     * 
     * @type {number}
     * @memberof MrpPickErrorListInner
     */
    'mrpPickLineId': number;
    /**
     * 
     * @type {string}
     * @memberof MrpPickErrorListInner
     */
    'status': MrpPickErrorListInnerStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof MrpPickErrorListInner
     */
    'orderType': MrpPickErrorListInnerOrderTypeEnum;
}

export const MrpPickErrorListInnerStatusEnum = {
    Error: 'ERROR',
    Inactive: 'INACTIVE',
    Ok: 'OK',
    Unknown: 'UNKNOWN'
} as const;

export type MrpPickErrorListInnerStatusEnum = typeof MrpPickErrorListInnerStatusEnum[keyof typeof MrpPickErrorListInnerStatusEnum];
export const MrpPickErrorListInnerOrderTypeEnum = {
    CreditSale: 'CREDIT_SALE',
    Install: 'INSTALL',
    Partnerprog: 'PARTNERPROG',
    PartnerprogInternal: 'PARTNERPROG_INTERNAL',
    Repair: 'REPAIR',
    Service: 'SERVICE',
    Warranty: 'WARRANTY',
    WarrantyCreditsale: 'WARRANTY_CREDITSALE',
    WarrantyService: 'WARRANTY_SERVICE'
} as const;

export type MrpPickErrorListInnerOrderTypeEnum = typeof MrpPickErrorListInnerOrderTypeEnum[keyof typeof MrpPickErrorListInnerOrderTypeEnum];

/**
 * 
 * @export
 * @interface MrpPickList
 */
export interface MrpPickList extends Array<MrpPickListInner> {
}
/**
 * 
 * @export
 * @interface MrpPickListInner
 */
export interface MrpPickListInner {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof MrpPickListInner
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof MrpPickListInner
     */
    'erpSyncOrderId': number;
    /**
     * 
     * @type {number}
     * @memberof MrpPickListInner
     */
    'pickListId': number;
    /**
     * 
     * @type {number}
     * @memberof MrpPickListInner
     */
    'lineItemId': number;
    /**
     * 
     * @type {string}
     * @memberof MrpPickListInner
     */
    'identifiersPicked': string;
}
/**
 * 
 * @export
 * @interface NotificationChannelJsonFix
 */
export interface NotificationChannelJsonFix {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof NotificationChannelJsonFix
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof NotificationChannelJsonFix
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationChannelJsonFix
     */
    'type': NotificationChannelJsonFixTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof NotificationChannelJsonFix
     */
    'rateLimit': number;
    /**
     * 
     * @type {AlertPolicyJsonFixConditions}
     * @memberof NotificationChannelJsonFix
     */
    'extraConfig': AlertPolicyJsonFixConditions;
}

export const NotificationChannelJsonFixTypeEnum = {
    Calendar: 'CALENDAR',
    Email: 'EMAIL',
    Sms: 'SMS',
    Webhook: 'WEBHOOK'
} as const;

export type NotificationChannelJsonFixTypeEnum = typeof NotificationChannelJsonFixTypeEnum[keyof typeof NotificationChannelJsonFixTypeEnum];

/**
 * 
 * @export
 * @interface NotificationChannelNullJsonFix
 */
export interface NotificationChannelNullJsonFix {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof NotificationChannelNullJsonFix
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof NotificationChannelNullJsonFix
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationChannelNullJsonFix
     */
    'type': NotificationChannelNullJsonFixTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof NotificationChannelNullJsonFix
     */
    'rateLimit': number;
    /**
     * 
     * @type {AlertPolicyJsonFixConditions}
     * @memberof NotificationChannelNullJsonFix
     */
    'extraConfig': AlertPolicyJsonFixConditions;
}

export const NotificationChannelNullJsonFixTypeEnum = {
    Calendar: 'CALENDAR',
    Email: 'EMAIL',
    Sms: 'SMS',
    Webhook: 'WEBHOOK'
} as const;

export type NotificationChannelNullJsonFixTypeEnum = typeof NotificationChannelNullJsonFixTypeEnum[keyof typeof NotificationChannelNullJsonFixTypeEnum];

/**
 * 
 * @export
 * @interface NotificationChannelWithPolicyNotifs
 */
export interface NotificationChannelWithPolicyNotifs {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof NotificationChannelWithPolicyNotifs
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof NotificationChannelWithPolicyNotifs
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationChannelWithPolicyNotifs
     */
    'type': NotificationChannelWithPolicyNotifsTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof NotificationChannelWithPolicyNotifs
     */
    'rateLimit': number;
    /**
     * 
     * @type {AlertPolicyJsonFixConditions}
     * @memberof NotificationChannelWithPolicyNotifs
     */
    'extraConfig': AlertPolicyJsonFixConditions;
    /**
     * 
     * @type {Array<NotificationChannelWithPolicyNotifsPoliciesInner>}
     * @memberof NotificationChannelWithPolicyNotifs
     */
    'policies': Array<NotificationChannelWithPolicyNotifsPoliciesInner>;
}

export const NotificationChannelWithPolicyNotifsTypeEnum = {
    Calendar: 'CALENDAR',
    Email: 'EMAIL',
    Sms: 'SMS',
    Webhook: 'WEBHOOK'
} as const;

export type NotificationChannelWithPolicyNotifsTypeEnum = typeof NotificationChannelWithPolicyNotifsTypeEnum[keyof typeof NotificationChannelWithPolicyNotifsTypeEnum];

/**
 * 
 * @export
 * @interface NotificationChannelWithPolicyNotifsPoliciesInner
 */
export interface NotificationChannelWithPolicyNotifsPoliciesInner {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof NotificationChannelWithPolicyNotifsPoliciesInner
     */
    'notificationId': number;
    /**
     * 
     * @type {number}
     * @memberof NotificationChannelWithPolicyNotifsPoliciesInner
     */
    'policyId': number;
    /**
     * 
     * @type {string}
     * @memberof NotificationChannelWithPolicyNotifsPoliciesInner
     */
    'lastTriggered': string;
    /**
     * 
     * @type {number}
     * @memberof NotificationChannelWithPolicyNotifsPoliciesInner
     */
    'rateLimit': number;
}
/**
 * 
 * @export
 * @interface NotificationChannelsResponse
 */
export interface NotificationChannelsResponse extends Array<NotificationChannelsResponseInner> {
}
/**
 * 
 * @export
 * @interface NotificationChannelsResponseInner
 */
export interface NotificationChannelsResponseInner {
    /**
     * 
     * @type {number}
     * @memberof NotificationChannelsResponseInner
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof NotificationChannelsResponseInner
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationChannelsResponseInner
     */
    'type': NotificationChannelsResponseInnerTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof NotificationChannelsResponseInner
     */
    'rateLimit': number;
    /**
     * 
     * @type {AlertPoliciesResponseInnerConditions}
     * @memberof NotificationChannelsResponseInner
     */
    'extraConfig': AlertPoliciesResponseInnerConditions;
    /**
     * 
     * @type {Array<NotificationChannelsResponseInnerPoliciesInner>}
     * @memberof NotificationChannelsResponseInner
     */
    'policies': Array<NotificationChannelsResponseInnerPoliciesInner>;
}

export const NotificationChannelsResponseInnerTypeEnum = {
    Calendar: 'CALENDAR',
    Email: 'EMAIL',
    Sms: 'SMS',
    Webhook: 'WEBHOOK'
} as const;

export type NotificationChannelsResponseInnerTypeEnum = typeof NotificationChannelsResponseInnerTypeEnum[keyof typeof NotificationChannelsResponseInnerTypeEnum];

/**
 * 
 * @export
 * @interface NotificationChannelsResponseInnerPoliciesInner
 */
export interface NotificationChannelsResponseInnerPoliciesInner {
    /**
     * 
     * @type {number}
     * @memberof NotificationChannelsResponseInnerPoliciesInner
     */
    'notificationId': number;
    /**
     * 
     * @type {number}
     * @memberof NotificationChannelsResponseInnerPoliciesInner
     */
    'policyId': number;
    /**
     * 
     * @type {string}
     * @memberof NotificationChannelsResponseInnerPoliciesInner
     */
    'lastTriggered': string;
    /**
     * 
     * @type {number}
     * @memberof NotificationChannelsResponseInnerPoliciesInner
     */
    'rateLimit': number;
}
/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    'parent_id'?: number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof Order
     */
    'properties': Array<AnswerPropertiesInner>;
    /**
     * 
     * @type {Array<OrderItemInner>}
     * @memberof Order
     */
    'item': Array<OrderItemInner>;
}
/**
 * 
 * @export
 * @interface OrderDetail
 */
export interface OrderDetail {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof OrderDetail
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof OrderDetail
     */
    'parent_id': number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof OrderDetail
     */
    'properties': Array<AnswerPropertiesInner>;
    /**
     * 
     * @type {Array<CustomerDeliveredProductInnerServiceInner>}
     * @memberof OrderDetail
     */
    'orderLine'?: Array<CustomerDeliveredProductInnerServiceInner>;
}
/**
 * 
 * @export
 * @enum {number}
 */

export const OrderDetailPropertyType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13
} as const;

export type OrderDetailPropertyType = typeof OrderDetailPropertyType[keyof typeof OrderDetailPropertyType];


/**
 * 
 * @export
 * @interface OrderItemInner
 */
export interface OrderItemInner {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof OrderItemInner
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof OrderItemInner
     */
    'parent_id': number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof OrderItemInner
     */
    'properties': Array<AnswerPropertiesInner>;
    /**
     * 
     * @type {Array<ItemProductInner>}
     * @memberof OrderItemInner
     */
    'product': Array<ItemProductInner>;
    /**
     * 
     * @type {Array<QuestionAnswerInner>}
     * @memberof OrderItemInner
     */
    'answer'?: Array<QuestionAnswerInner>;
}
/**
 * 
 * @export
 * @interface OrderLine
 */
export interface OrderLine {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof OrderLine
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof OrderLine
     */
    'parent_id': number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof OrderLine
     */
    'properties': Array<AnswerPropertiesInner>;
}
/**
 * 
 * @export
 * @enum {number}
 */

export const OrderLinePropertyType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8
} as const;

export type OrderLinePropertyType = typeof OrderLinePropertyType[keyof typeof OrderLinePropertyType];


/**
 * 
 * @export
 * @enum {number}
 */

export const OrderLineStatus = {
    NUMBER_1: 1,
    NUMBER_10: 10,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9
} as const;

export type OrderLineStatus = typeof OrderLineStatus[keyof typeof OrderLineStatus];


/**
 * 
 * @export
 * @enum {string}
 */

export const OrderLineStatusKeys = {'---': '---',
    Canceled: 'Canceled',
    Invoiced: 'Invoiced',
    Loaded: 'Loaded',
    Pending: 'Pending',
    Picked: 'Picked',
    Production: 'Production',
    Released: 'Released',
    Shipped: 'Shipped',
    Staged: 'Staged'
} as const;

export type OrderLineStatusKeys = typeof OrderLineStatusKeys[keyof typeof OrderLineStatusKeys];


/**
 * 
 * @export
 * @interface OrderListRequest
 */
export interface OrderListRequest {
    [key: string]: any;

    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof OrderListRequest
     */
    'OrderNumber'?: ItemListResponseItemAdditionalDataValuesInnerID;
}
/**
 * 
 * @export
 * @interface OrderListResponse
 */
export interface OrderListResponse extends Array<OrderListResponseInner> {
}
/**
 * 
 * @export
 * @interface OrderListResponseInner
 */
export interface OrderListResponseInner {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof OrderListResponseInner
     */
    'SalesOrderID': number;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseInner
     */
    'OrderDate': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseInner
     */
    'Currency': string;
    /**
     * 
     * @type {number}
     * @memberof OrderListResponseInner
     */
    'SubTotal': number;
    /**
     * 
     * @type {number}
     * @memberof OrderListResponseInner
     */
    'Shipping': number;
    /**
     * 
     * @type {number}
     * @memberof OrderListResponseInner
     */
    'SalesTax': number;
    /**
     * 
     * @type {number}
     * @memberof OrderListResponseInner
     */
    'Total': number;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseInner
     */
    'Term': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseInner
     */
    'DeliveryMethod': string;
    /**
     * 
     * @type {number}
     * @memberof OrderListResponseInner
     */
    'ProjectID': number;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseInner
     */
    'SoldToCompanyName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseInner
     */
    'SoldToContact': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseInner
     */
    'SoldToAddress1': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseInner
     */
    'SoldToAddress2': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseInner
     */
    'SoldToCity': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseInner
     */
    'SoldToState': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseInner
     */
    'SoldToPostalCode': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseInner
     */
    'SoldToGLN': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseInner
     */
    'ShipToCompanyName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseInner
     */
    'ShipToContact': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseInner
     */
    'ShipToAddress1': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseInner
     */
    'ShipToAddress2': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseInner
     */
    'ShipToCity': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseInner
     */
    'ShipToState': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseInner
     */
    'ShipToPostalCode': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseInner
     */
    'ShipToGLN': string;
    /**
     * 
     * @type {number}
     * @memberof OrderListResponseInner
     */
    'StatusID': OrderListResponseInnerStatusIDEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseInner
     */
    'SalesRep': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseInner
     */
    'ScopeOfWork': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseInner
     */
    'CustomerOrderNumber': string;
    /**
     * 
     * @type {Array<OrderListResponseItemLineItemsInner>}
     * @memberof OrderListResponseInner
     */
    'LineItems': Array<OrderListResponseItemLineItemsInner>;
}

export const OrderListResponseInnerStatusIDEnum = {
    NUMBER_1: 1,
    NUMBER_10: 10,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9
} as const;

export type OrderListResponseInnerStatusIDEnum = typeof OrderListResponseInnerStatusIDEnum[keyof typeof OrderListResponseInnerStatusIDEnum];

/**
 * 
 * @export
 * @interface OrderListResponseItem
 */
export interface OrderListResponseItem {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof OrderListResponseItem
     */
    'SalesOrderID': number;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseItem
     */
    'OrderDate': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseItem
     */
    'Currency': string;
    /**
     * 
     * @type {number}
     * @memberof OrderListResponseItem
     */
    'SubTotal': number;
    /**
     * 
     * @type {number}
     * @memberof OrderListResponseItem
     */
    'Shipping': number;
    /**
     * 
     * @type {number}
     * @memberof OrderListResponseItem
     */
    'SalesTax': number;
    /**
     * 
     * @type {number}
     * @memberof OrderListResponseItem
     */
    'Total': number;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseItem
     */
    'Term': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseItem
     */
    'DeliveryMethod': string;
    /**
     * 
     * @type {number}
     * @memberof OrderListResponseItem
     */
    'ProjectID': number;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseItem
     */
    'SoldToCompanyName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseItem
     */
    'SoldToContact': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseItem
     */
    'SoldToAddress1': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseItem
     */
    'SoldToAddress2': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseItem
     */
    'SoldToCity': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseItem
     */
    'SoldToState': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseItem
     */
    'SoldToPostalCode': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseItem
     */
    'SoldToGLN': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseItem
     */
    'ShipToCompanyName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseItem
     */
    'ShipToContact': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseItem
     */
    'ShipToAddress1': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseItem
     */
    'ShipToAddress2': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseItem
     */
    'ShipToCity': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseItem
     */
    'ShipToState': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseItem
     */
    'ShipToPostalCode': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseItem
     */
    'ShipToGLN': string;
    /**
     * 
     * @type {number}
     * @memberof OrderListResponseItem
     */
    'StatusID': OrderListResponseItemStatusIDEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseItem
     */
    'SalesRep': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseItem
     */
    'ScopeOfWork': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseItem
     */
    'CustomerOrderNumber': string;
    /**
     * 
     * @type {Array<OrderListResponseItemLineItemsInner>}
     * @memberof OrderListResponseItem
     */
    'LineItems': Array<OrderListResponseItemLineItemsInner>;
}

export const OrderListResponseItemStatusIDEnum = {
    NUMBER_1: 1,
    NUMBER_10: 10,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9
} as const;

export type OrderListResponseItemStatusIDEnum = typeof OrderListResponseItemStatusIDEnum[keyof typeof OrderListResponseItemStatusIDEnum];

/**
 * 
 * @export
 * @interface OrderListResponseItemLineItemsInner
 */
export interface OrderListResponseItemLineItemsInner {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof OrderListResponseItemLineItemsInner
     */
    'LineItemID': number;
    /**
     * 
     * @type {number}
     * @memberof OrderListResponseItemLineItemsInner
     */
    'ItemMasterID': number;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseItemLineItemsInner
     */
    'ItemNumber': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseItemLineItemsInner
     */
    'Revision': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseItemLineItemsInner
     */
    'Description': string;
    /**
     * 
     * @type {number}
     * @memberof OrderListResponseItemLineItemsInner
     */
    'Quantity': number;
    /**
     * 
     * @type {number}
     * @memberof OrderListResponseItemLineItemsInner
     */
    'UnitPrice': number;
    /**
     * 
     * @type {Array<ItemListResponseItemAdditionalDataValuesInner>}
     * @memberof OrderListResponseItemLineItemsInner
     */
    'AdditionalDataValues': Array<ItemListResponseItemAdditionalDataValuesInner>;
    /**
     * 
     * @type {{ [key: string]: any | undefined; }}
     * @memberof OrderListResponseItemLineItemsInner
     */
    'ConfigurationData': { [key: string]: any | undefined; };
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseItemLineItemsInner
     */
    'Warehouse': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseItemLineItemsInner
     */
    'RequestedDate': string;
    /**
     * 
     * @type {number}
     * @memberof OrderListResponseItemLineItemsInner
     */
    'DiscountAmount': number;
    /**
     * 
     * @type {number}
     * @memberof OrderListResponseItemLineItemsInner
     */
    'DiscountPercent': number;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseItemLineItemsInner
     */
    'GTIN': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseItemLineItemsInner
     */
    'LineItemStatus': OrderListResponseItemLineItemsInnerLineItemStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseItemLineItemsInner
     */
    'PromiseDate': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseItemLineItemsInner
     */
    'Category': string;
    /**
     * 
     * @type {string}
     * @memberof OrderListResponseItemLineItemsInner
     */
    'SubCategory': string;
}

export const OrderListResponseItemLineItemsInnerLineItemStatusEnum = {'---': '---',
    Canceled: 'Canceled',
    Invoiced: 'Invoiced',
    Loaded: 'Loaded',
    Pending: 'Pending',
    Picked: 'Picked',
    Production: 'Production',
    Released: 'Released',
    Shipped: 'Shipped',
    Staged: 'Staged'
} as const;

export type OrderListResponseItemLineItemsInnerLineItemStatusEnum = typeof OrderListResponseItemLineItemsInnerLineItemStatusEnum[keyof typeof OrderListResponseItemLineItemsInnerLineItemStatusEnum];

/**
 * 
 * @export
 * @enum {number}
 */

export const OrderPropertyType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_16: 16,
    NUMBER_17: 17,
    NUMBER_18: 18,
    NUMBER_19: 19
} as const;

export type OrderPropertyType = typeof OrderPropertyType[keyof typeof OrderPropertyType];


/**
 * OrderStates
 * @export
 * @interface OrderStates
 */
export interface OrderStates {
    [key: string]: any;

    /**
     * OrderSlipStateType|s:string|None,Offer,Pack,Invoiced,Web,Repeating,CollectingOrder,Return,Distribution,Rest,ForInvoicing,OvertimeRent,Confirmed,OrderState_Sent,Production,PaymentReminder,OnAccount,Lending,InvoicedCashAccount,InvoicedPack,Inactive,Hours,OrderState_SuperStore,Draft
     * @type {Array<string>}
     * @memberof OrderStates
     */
    'OrderSlipStateType'?: Array<OrderStatesOrderSlipStateTypeEnum>;
}

export const OrderStatesOrderSlipStateTypeEnum = {
    CollectingOrder: 'CollectingOrder',
    Confirmed: 'Confirmed',
    Distribution: 'Distribution',
    Draft: 'Draft',
    ForInvoicing: 'ForInvoicing',
    Hours: 'Hours',
    Inactive: 'Inactive',
    Invoiced: 'Invoiced',
    InvoicedCashAccount: 'InvoicedCashAccount',
    InvoicedPack: 'InvoicedPack',
    Lending: 'Lending',
    None: 'None',
    Offer: 'Offer',
    OnAccount: 'OnAccount',
    OrderStateSent: 'OrderState_Sent',
    OrderStateSuperStore: 'OrderState_SuperStore',
    OvertimeRent: 'OvertimeRent',
    Pack: 'Pack',
    PaymentReminder: 'PaymentReminder',
    Production: 'Production',
    Repeating: 'Repeating',
    Rest: 'Rest',
    Return: 'Return',
    Web: 'Web'
} as const;

export type OrderStatesOrderSlipStateTypeEnum = typeof OrderStatesOrderSlipStateTypeEnum[keyof typeof OrderStatesOrderSlipStateTypeEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const OrderStatus = {
    CollectingOrder: 'CollectingOrder',
    Confirmed: 'Confirmed',
    Distribution: 'Distribution',
    Draft: 'Draft',
    ForInvoicing: 'ForInvoicing',
    Hours: 'Hours',
    Inactive: 'Inactive',
    Invoiced: 'Invoiced',
    InvoicedCashAccount: 'InvoicedCashAccount',
    InvoicedPack: 'InvoicedPack',
    Lending: 'Lending',
    None: 'None',
    Offer: 'Offer',
    OnAccount: 'OnAccount',
    OrderStateSent: 'OrderState_Sent',
    OrderStateSuperStore: 'OrderState_SuperStore',
    OvertimeRent: 'OvertimeRent',
    Pack: 'Pack',
    PaymentReminder: 'PaymentReminder',
    Production: 'Production',
    Repeating: 'Repeating',
    Rest: 'Rest',
    Return: 'Return',
    Web: 'Web'
} as const;

export type OrderStatus = typeof OrderStatus[keyof typeof OrderStatus];


/**
 * 
 * @export
 * @interface OrdersLineItem
 */
export interface OrdersLineItem {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof OrdersLineItem
     */
    'LineItemID': number;
    /**
     * 
     * @type {string}
     * @memberof OrdersLineItem
     */
    'ItemNumber': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersLineItem
     */
    'Revision': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersLineItem
     */
    'PromiseDate': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersLineItem
     */
    'RequestedDate': string;
    /**
     * 
     * @type {number}
     * @memberof OrdersLineItem
     */
    'Quantity': number;
    /**
     * 
     * @type {number}
     * @memberof OrdersLineItem
     */
    'UnitPrice': number;
    /**
     * 
     * @type {number}
     * @memberof OrdersLineItem
     */
    'TotalPrice': number;
}
/**
 * 
 * @export
 * @interface OrdersListLineItem
 */
export interface OrdersListLineItem {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof OrdersListLineItem
     */
    'LineItemID': number;
    /**
     * 
     * @type {number}
     * @memberof OrdersListLineItem
     */
    'ItemMasterID': number;
    /**
     * 
     * @type {string}
     * @memberof OrdersListLineItem
     */
    'ItemNumber': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersListLineItem
     */
    'Revision': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersListLineItem
     */
    'Description': string;
    /**
     * 
     * @type {number}
     * @memberof OrdersListLineItem
     */
    'Quantity': number;
    /**
     * 
     * @type {number}
     * @memberof OrdersListLineItem
     */
    'UnitPrice': number;
    /**
     * 
     * @type {Array<ItemListResponseItemAdditionalDataValuesInner>}
     * @memberof OrdersListLineItem
     */
    'AdditionalDataValues': Array<ItemListResponseItemAdditionalDataValuesInner>;
    /**
     * 
     * @type {{ [key: string]: any | undefined; }}
     * @memberof OrdersListLineItem
     */
    'ConfigurationData': { [key: string]: any | undefined; };
    /**
     * 
     * @type {string}
     * @memberof OrdersListLineItem
     */
    'Warehouse': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersListLineItem
     */
    'RequestedDate': string;
    /**
     * 
     * @type {number}
     * @memberof OrdersListLineItem
     */
    'DiscountAmount': number;
    /**
     * 
     * @type {number}
     * @memberof OrdersListLineItem
     */
    'DiscountPercent': number;
    /**
     * 
     * @type {string}
     * @memberof OrdersListLineItem
     */
    'GTIN': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersListLineItem
     */
    'LineItemStatus': OrdersListLineItemLineItemStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof OrdersListLineItem
     */
    'PromiseDate': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersListLineItem
     */
    'Category': string;
    /**
     * 
     * @type {string}
     * @memberof OrdersListLineItem
     */
    'SubCategory': string;
}

export const OrdersListLineItemLineItemStatusEnum = {'---': '---',
    Canceled: 'Canceled',
    Invoiced: 'Invoiced',
    Loaded: 'Loaded',
    Pending: 'Pending',
    Picked: 'Picked',
    Production: 'Production',
    Released: 'Released',
    Shipped: 'Shipped',
    Staged: 'Staged'
} as const;

export type OrdersListLineItemLineItemStatusEnum = typeof OrdersListLineItemLineItemStatusEnum[keyof typeof OrdersListLineItemLineItemStatusEnum];

/**
 * 
 * @export
 * @interface PaginationCommand
 */
export interface PaginationCommand {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof PaginationCommand
     */
    'size'?: number;
    /**
     * 
     * @type {number}
     * @memberof PaginationCommand
     */
    'page'?: number;
}
/**
 * Parameters
 * @export
 * @interface Parameters
 */
export interface Parameters {
    [key: string]: any;

    /**
     * s:int
     * @type {number}
     * @memberof Parameters
     */
    'InvoiceId'?: number;
    /**
     * guid|s:string|pattern
     * @type {string}
     * @memberof Parameters
     */
    'TemplateId'?: string;
}
/**
 * PaymentMethod
 * @export
 * @interface PaymentMethod
 */
export interface PaymentMethod {
    [key: string]: any;

    /**
     * s:int
     * @type {number}
     * @memberof PaymentMethod
     */
    'Id'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof PaymentMethod
     */
    'Name'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PdfType = {
    OrderConfirmation: 'OrderConfirmation',
    Tilbud: 'Tilbud'
} as const;

export type PdfType = typeof PdfType[keyof typeof PdfType];


/**
 * Post
 * @export
 * @interface Post
 */
export interface Post {
    [key: string]: any;

    /**
     * s:string
     * @type {string}
     * @memberof Post
     */
    'Street'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof Post
     */
    'State'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof Post
     */
    'PostalCode'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof Post
     */
    'PostalArea'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof Post
     */
    'Name'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof Post
     */
    'City'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof Post
     */
    'Country'?: string;
}
/**
 * InvoiceOrder
 * @export
 * @interface PostACInvoiceOrderResponse
 */
export interface PostACInvoiceOrderResponse {
    [key: string]: any;

    /**
     * s:int
     * @type {number}
     * @memberof PostACInvoiceOrderResponse
     */
    'OrderId'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof PostACInvoiceOrderResponse
     */
    'CustomerId'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponse
     */
    'CustomerName'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponse
     */
    'CustomerDeliveryName'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponse
     */
    'CustomerDeliveryPhone'?: string;
    /**
     * 
     * @type {PostACInvoiceOrderResponseAddresses}
     * @memberof PostACInvoiceOrderResponse
     */
    'Addresses'?: PostACInvoiceOrderResponseAddresses;
    /**
     * OrderSlipStateType|s:string|None,Offer,Pack,Invoiced,Web,Repeating,CollectingOrder,Return,Distribution,Rest,ForInvoicing,OvertimeRent,Confirmed,OrderState_Sent,Production,PaymentReminder,OnAccount,Lending,InvoicedCashAccount,InvoicedPack,Inactive,Hours,OrderState_SuperStore,Draft
     * @type {string}
     * @memberof PostACInvoiceOrderResponse
     */
    'OrderStatus'?: PostACInvoiceOrderResponseOrderStatusEnum;
    /**
     * s:int
     * @type {number}
     * @memberof PostACInvoiceOrderResponse
     */
    'InvoiceId'?: number;
    /**
     * 
     * @type {GetInvoicesSearchParamsChangedAfter}
     * @memberof PostACInvoiceOrderResponse
     */
    'DateOrdered'?: GetInvoicesSearchParamsChangedAfter;
    /**
     * 
     * @type {GetInvoicesSearchParamsChangedAfter}
     * @memberof PostACInvoiceOrderResponse
     */
    'DateInvoiced'?: GetInvoicesSearchParamsChangedAfter;
    /**
     * 
     * @type {GetInvoicesSearchParamsChangedAfter}
     * @memberof PostACInvoiceOrderResponse
     */
    'DateChanged'?: GetInvoicesSearchParamsChangedAfter;
    /**
     * s:int
     * @type {number}
     * @memberof PostACInvoiceOrderResponse
     */
    'PaymentTime'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponse
     */
    'CustomerReferenceNo'?: string;
    /**
     * s:int
     * @type {number}
     * @memberof PostACInvoiceOrderResponse
     */
    'ProjectId'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof PostACInvoiceOrderResponse
     */
    'OurReference'?: number;
    /**
     * s:boolean
     * @type {boolean}
     * @memberof PostACInvoiceOrderResponse
     */
    'IncludeVAT'?: boolean;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponse
     */
    'YourReference'?: string;
    /**
     * s:decimal
     * @type {number}
     * @memberof PostACInvoiceOrderResponse
     */
    'OrderTotalIncVat'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof PostACInvoiceOrderResponse
     */
    'OrderTotalVat'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponse
     */
    'InvoiceTitle'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponse
     */
    'InvoiceText'?: string;
    /**
     * s:dateTime
     * @type {string}
     * @memberof PostACInvoiceOrderResponse
     */
    'Paid'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponse
     */
    'OCR'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponse
     */
    'CustomerOrgNo'?: string;
    /**
     * 
     * @type {PostACInvoiceOrderResponseCurrency}
     * @memberof PostACInvoiceOrderResponse
     */
    'Currency'?: PostACInvoiceOrderResponseCurrency;
    /**
     * s:int
     * @type {number}
     * @memberof PostACInvoiceOrderResponse
     */
    'PaymentMethodId'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof PostACInvoiceOrderResponse
     */
    'PaymentAmount'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof PostACInvoiceOrderResponse
     */
    'ProductionManagerId'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof PostACInvoiceOrderResponse
     */
    'SalesOpportunityId'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof PostACInvoiceOrderResponse
     */
    'TypeOfSaleId'?: number;
    /**
     * Distributor|s:string|Default,Manual,Auto
     * @type {string}
     * @memberof PostACInvoiceOrderResponse
     */
    'Distributor'?: PostACInvoiceOrderResponseDistributorEnum;
    /**
     * DistributionMethod|s:string|Unchanged,Print,EMail,ElectronicInvoice
     * @type {string}
     * @memberof PostACInvoiceOrderResponse
     */
    'DistributionMethod'?: PostACInvoiceOrderResponseDistributionMethodEnum;
    /**
     * s:int
     * @type {number}
     * @memberof PostACInvoiceOrderResponse
     */
    'DepartmentId'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof PostACInvoiceOrderResponse
     */
    'ExternalStatus'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponse
     */
    'InvoiceEmailAddress'?: string;
    /**
     * 
     * @type {PostACInvoiceOrderResponseInvoiceRows}
     * @memberof PostACInvoiceOrderResponse
     */
    'InvoiceRows'?: PostACInvoiceOrderResponseInvoiceRows;
    /**
     * 
     * @type {PostACInvoiceOrderResponseAPIException}
     * @memberof PostACInvoiceOrderResponse
     */
    'APIException'?: PostACInvoiceOrderResponseAPIException;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponse
     */
    'ProductionNumber'?: string;
    /**
     * 
     * @type {GetInvoicesSearchParamsChangedAfter}
     * @memberof PostACInvoiceOrderResponse
     */
    'DeliveryDate'?: GetInvoicesSearchParamsChangedAfter;
    /**
     * s:int
     * @type {number}
     * @memberof PostACInvoiceOrderResponse
     */
    'ReferenceInvoiceId'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof PostACInvoiceOrderResponse
     */
    'ReferenceOrderId'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponse
     */
    'ReferenceNumber'?: string;
    /**
     * s:boolean
     * @type {boolean}
     * @memberof PostACInvoiceOrderResponse
     */
    'SkipStock'?: boolean;
    /**
     * s:dateTime
     * @type {string}
     * @memberof PostACInvoiceOrderResponse
     */
    'AccrualDate'?: string;
    /**
     * s:int
     * @type {number}
     * @memberof PostACInvoiceOrderResponse
     */
    'AccrualLength'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof PostACInvoiceOrderResponse
     */
    'RoundFactor'?: number;
    /**
     * guid|s:string|pattern
     * @type {string}
     * @memberof PostACInvoiceOrderResponse
     */
    'InvoiceTemplateId'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponse
     */
    'VippsNumber'?: string;
    /**
     * 
     * @type {PostACInvoiceOrderResponseDeliveryMethod}
     * @memberof PostACInvoiceOrderResponse
     */
    'DeliveryMethod'?: PostACInvoiceOrderResponseDeliveryMethod;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponse
     */
    'DeliveryAlternative'?: string;
    /**
     * s:boolean
     * @type {boolean}
     * @memberof PostACInvoiceOrderResponse
     */
    'SendToFactoring'?: boolean;
    /**
     * s:decimal
     * @type {number}
     * @memberof PostACInvoiceOrderResponse
     */
    'Commission'?: number;
    /**
     * 
     * @type {PostChecklistInstalledResponseAcDataUpdateAddRowsInnerUserDefinedDimensions}
     * @memberof PostACInvoiceOrderResponse
     */
    'UserDefinedDimensions'?: PostChecklistInstalledResponseAcDataUpdateAddRowsInnerUserDefinedDimensions;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponse
     */
    'GLNNumber'?: string;
    /**
     * s:int
     * @type {number}
     * @memberof PostACInvoiceOrderResponse
     */
    'CustomerDeliveryId'?: number;
}

export const PostACInvoiceOrderResponseOrderStatusEnum = {
    CollectingOrder: 'CollectingOrder',
    Confirmed: 'Confirmed',
    Distribution: 'Distribution',
    Draft: 'Draft',
    ForInvoicing: 'ForInvoicing',
    Hours: 'Hours',
    Inactive: 'Inactive',
    Invoiced: 'Invoiced',
    InvoicedCashAccount: 'InvoicedCashAccount',
    InvoicedPack: 'InvoicedPack',
    Lending: 'Lending',
    None: 'None',
    Offer: 'Offer',
    OnAccount: 'OnAccount',
    OrderStateSent: 'OrderState_Sent',
    OrderStateSuperStore: 'OrderState_SuperStore',
    OvertimeRent: 'OvertimeRent',
    Pack: 'Pack',
    PaymentReminder: 'PaymentReminder',
    Production: 'Production',
    Repeating: 'Repeating',
    Rest: 'Rest',
    Return: 'Return',
    Web: 'Web'
} as const;

export type PostACInvoiceOrderResponseOrderStatusEnum = typeof PostACInvoiceOrderResponseOrderStatusEnum[keyof typeof PostACInvoiceOrderResponseOrderStatusEnum];
export const PostACInvoiceOrderResponseDistributorEnum = {
    Auto: 'Auto',
    Default: 'Default',
    Manual: 'Manual'
} as const;

export type PostACInvoiceOrderResponseDistributorEnum = typeof PostACInvoiceOrderResponseDistributorEnum[keyof typeof PostACInvoiceOrderResponseDistributorEnum];
export const PostACInvoiceOrderResponseDistributionMethodEnum = {
    EMail: 'EMail',
    ElectronicInvoice: 'ElectronicInvoice',
    Print: 'Print',
    Unchanged: 'Unchanged'
} as const;

export type PostACInvoiceOrderResponseDistributionMethodEnum = typeof PostACInvoiceOrderResponseDistributionMethodEnum[keyof typeof PostACInvoiceOrderResponseDistributionMethodEnum];

/**
 * APIException
 * @export
 * @interface PostACInvoiceOrderResponseAPIException
 */
export interface PostACInvoiceOrderResponseAPIException {
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponseAPIException
     */
    'Type'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponseAPIException
     */
    'Message'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponseAPIException
     */
    'StackTrace'?: string;
}
/**
 * Addresses
 * @export
 * @interface PostACInvoiceOrderResponseAddresses
 */
export interface PostACInvoiceOrderResponseAddresses {
    /**
     * 
     * @type {PostACInvoiceOrderResponseAddressesPost}
     * @memberof PostACInvoiceOrderResponseAddresses
     */
    'Post'?: PostACInvoiceOrderResponseAddressesPost;
    /**
     * 
     * @type {PostACInvoiceOrderResponseAddressesDelivery}
     * @memberof PostACInvoiceOrderResponseAddresses
     */
    'Delivery'?: PostACInvoiceOrderResponseAddressesDelivery;
    /**
     * 
     * @type {PostACInvoiceOrderResponseAddressesVisit}
     * @memberof PostACInvoiceOrderResponseAddresses
     */
    'Visit'?: PostACInvoiceOrderResponseAddressesVisit;
    /**
     * 
     * @type {PostACInvoiceOrderResponseAddressesInvoice}
     * @memberof PostACInvoiceOrderResponseAddresses
     */
    'Invoice'?: PostACInvoiceOrderResponseAddressesInvoice;
}
/**
 * Delivery
 * @export
 * @interface PostACInvoiceOrderResponseAddressesDelivery
 */
export interface PostACInvoiceOrderResponseAddressesDelivery {
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponseAddressesDelivery
     */
    'Street'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponseAddressesDelivery
     */
    'State'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponseAddressesDelivery
     */
    'PostalCode'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponseAddressesDelivery
     */
    'PostalArea'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponseAddressesDelivery
     */
    'Name'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponseAddressesDelivery
     */
    'City'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponseAddressesDelivery
     */
    'Country'?: string;
}
/**
 * Invoice
 * @export
 * @interface PostACInvoiceOrderResponseAddressesInvoice
 */
export interface PostACInvoiceOrderResponseAddressesInvoice {
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponseAddressesInvoice
     */
    'Street'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponseAddressesInvoice
     */
    'State'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponseAddressesInvoice
     */
    'PostalCode'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponseAddressesInvoice
     */
    'PostalArea'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponseAddressesInvoice
     */
    'Name'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponseAddressesInvoice
     */
    'City'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponseAddressesInvoice
     */
    'Country'?: string;
}
/**
 * Post
 * @export
 * @interface PostACInvoiceOrderResponseAddressesPost
 */
export interface PostACInvoiceOrderResponseAddressesPost {
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponseAddressesPost
     */
    'Street'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponseAddressesPost
     */
    'State'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponseAddressesPost
     */
    'PostalCode'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponseAddressesPost
     */
    'PostalArea'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponseAddressesPost
     */
    'Name'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponseAddressesPost
     */
    'City'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponseAddressesPost
     */
    'Country'?: string;
}
/**
 * Visit
 * @export
 * @interface PostACInvoiceOrderResponseAddressesVisit
 */
export interface PostACInvoiceOrderResponseAddressesVisit {
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponseAddressesVisit
     */
    'Street'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponseAddressesVisit
     */
    'State'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponseAddressesVisit
     */
    'PostalCode'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponseAddressesVisit
     */
    'PostalArea'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponseAddressesVisit
     */
    'Name'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponseAddressesVisit
     */
    'City'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponseAddressesVisit
     */
    'Country'?: string;
}
/**
 * Currency
 * @export
 * @interface PostACInvoiceOrderResponseCurrency
 */
export interface PostACInvoiceOrderResponseCurrency {
    /**
     * s:decimal
     * @type {number}
     * @memberof PostACInvoiceOrderResponseCurrency
     */
    'Rate'?: number;
    /**
     * s:short
     * @type {number}
     * @memberof PostACInvoiceOrderResponseCurrency
     */
    'Unit'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponseCurrency
     */
    'Symbol'?: string;
}
/**
 * DeliveryMethod
 * @export
 * @interface PostACInvoiceOrderResponseDeliveryMethod
 */
export interface PostACInvoiceOrderResponseDeliveryMethod {
    /**
     * s:int
     * @type {number}
     * @memberof PostACInvoiceOrderResponseDeliveryMethod
     */
    'Id'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof PostACInvoiceOrderResponseDeliveryMethod
     */
    'Description'?: string;
}
/**
 * InvoiceRows
 * @export
 * @interface PostACInvoiceOrderResponseInvoiceRows
 */
export interface PostACInvoiceOrderResponseInvoiceRows {
    /**
     * InvoiceRow[]
     * @type {Array<PostChecklistInstalledResponseAcDataUpdateAddRowsInner>}
     * @memberof PostACInvoiceOrderResponseInvoiceRows
     */
    'InvoiceRow'?: Array<PostChecklistInstalledResponseAcDataUpdateAddRowsInner>;
}
/**
 * 
 * @export
 * @interface PostCRMCustomerAquireLockRequest
 */
export interface PostCRMCustomerAquireLockRequest {
    /**
     * 
     * @type {number}
     * @memberof PostCRMCustomerAquireLockRequest
     */
    'hoursLocked': number;
}
/**
 * 
 * @export
 * @interface PostChecklistInstalledResponse
 */
export interface PostChecklistInstalledResponse {
    [key: string]: any;

    /**
     * 
     * @type {PostChecklistInstalledResponseMrpData}
     * @memberof PostChecklistInstalledResponse
     */
    'mrpData'?: PostChecklistInstalledResponseMrpData;
    /**
     * 
     * @type {PostChecklistInstalledResponseAcData}
     * @memberof PostChecklistInstalledResponse
     */
    'acData'?: PostChecklistInstalledResponseAcData;
    /**
     * 
     * @type {Array<PostChecklistInstalledResponseProductLinesInner>}
     * @memberof PostChecklistInstalledResponse
     */
    'productLines': Array<PostChecklistInstalledResponseProductLinesInner>;
}
/**
 * 
 * @export
 * @interface PostChecklistInstalledResponseAcData
 */
export interface PostChecklistInstalledResponseAcData {
    /**
     * 
     * @type {Array<PostChecklistInstalledResponseAcDataUpdateAddRowsInner>}
     * @memberof PostChecklistInstalledResponseAcData
     */
    'updateAddRows': Array<PostChecklistInstalledResponseAcDataUpdateAddRowsInner>;
    /**
     * 
     * @type {Array<PostChecklistInstalledResponseAcDataUpdateAddRowsInner>}
     * @memberof PostChecklistInstalledResponseAcData
     */
    'deleteRows': Array<PostChecklistInstalledResponseAcDataUpdateAddRowsInner>;
    /**
     * 
     * @type {Array<PostChecklistInstalledResponseAcDataUpdateAddRowsInner>}
     * @memberof PostChecklistInstalledResponseAcData
     */
    'originalRows': Array<PostChecklistInstalledResponseAcDataUpdateAddRowsInner>;
    /**
     * 
     * @type {boolean}
     * @memberof PostChecklistInstalledResponseAcData
     */
    'deleteLines'?: boolean;
}
/**
 * InvoiceRow
 * @export
 * @interface PostChecklistInstalledResponseAcDataUpdateAddRowsInner
 */
export interface PostChecklistInstalledResponseAcDataUpdateAddRowsInner {
    /**
     * s:int
     * @type {number}
     * @memberof PostChecklistInstalledResponseAcDataUpdateAddRowsInner
     */
    'ProductId'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof PostChecklistInstalledResponseAcDataUpdateAddRowsInner
     */
    'RowId'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof PostChecklistInstalledResponseAcDataUpdateAddRowsInner
     */
    'VatRate'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof PostChecklistInstalledResponseAcDataUpdateAddRowsInner
     */
    'Price'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof PostChecklistInstalledResponseAcDataUpdateAddRowsInner
     */
    'Name'?: string;
    /**
     * s:decimal
     * @type {number}
     * @memberof PostChecklistInstalledResponseAcDataUpdateAddRowsInner
     */
    'DiscountRate'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof PostChecklistInstalledResponseAcDataUpdateAddRowsInner
     */
    'Quantity'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof PostChecklistInstalledResponseAcDataUpdateAddRowsInner
     */
    'QuantityDelivered'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof PostChecklistInstalledResponseAcDataUpdateAddRowsInner
     */
    'QuantityOrdered'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof PostChecklistInstalledResponseAcDataUpdateAddRowsInner
     */
    'QuantityRest'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof PostChecklistInstalledResponseAcDataUpdateAddRowsInner
     */
    'Cost'?: number;
    /**
     * ChangeState|s:string|None,Add,Edit,Delete
     * @type {string}
     * @memberof PostChecklistInstalledResponseAcDataUpdateAddRowsInner
     */
    'ChangeState'?: PostChecklistInstalledResponseAcDataUpdateAddRowsInnerChangeStateEnum;
    /**
     * s:int
     * @type {number}
     * @memberof PostChecklistInstalledResponseAcDataUpdateAddRowsInner
     */
    'TypeGroupId'?: number;
    /**
     * s:short
     * @type {number}
     * @memberof PostChecklistInstalledResponseAcDataUpdateAddRowsInner
     */
    'SequenceNumber'?: number;
    /**
     * s:boolean
     * @type {boolean}
     * @memberof PostChecklistInstalledResponseAcDataUpdateAddRowsInner
     */
    'Hidden'?: boolean;
    /**
     * RowType|s:string|Normal,Text,TextBold
     * @type {string}
     * @memberof PostChecklistInstalledResponseAcDataUpdateAddRowsInner
     */
    'Type'?: PostChecklistInstalledResponseAcDataUpdateAddRowsInnerTypeEnum;
    /**
     * s:decimal
     * @type {number}
     * @memberof PostChecklistInstalledResponseAcDataUpdateAddRowsInner
     */
    'InPrice'?: number;
    /**
     * s:dateTime
     * @type {string}
     * @memberof PostChecklistInstalledResponseAcDataUpdateAddRowsInner
     */
    'AccrualDate'?: string;
    /**
     * s:int
     * @type {number}
     * @memberof PostChecklistInstalledResponseAcDataUpdateAddRowsInner
     */
    'AccrualLength'?: number;
    /**
     * s:boolean
     * @type {boolean}
     * @memberof PostChecklistInstalledResponseAcDataUpdateAddRowsInner
     */
    'AccountProject'?: boolean;
    /**
     * s:int
     * @type {number}
     * @memberof PostChecklistInstalledResponseAcDataUpdateAddRowsInner
     */
    'ProjectId'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof PostChecklistInstalledResponseAcDataUpdateAddRowsInner
     */
    'DepartmentId'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof PostChecklistInstalledResponseAcDataUpdateAddRowsInner
     */
    'ProductNo'?: string;
    /**
     * 
     * @type {PostChecklistInstalledResponseAcDataUpdateAddRowsInnerTaxSettings}
     * @memberof PostChecklistInstalledResponseAcDataUpdateAddRowsInner
     */
    'TaxSettings'?: PostChecklistInstalledResponseAcDataUpdateAddRowsInnerTaxSettings;
    /**
     * 
     * @type {PostChecklistInstalledResponseAcDataUpdateAddRowsInnerUserDefinedDimensions}
     * @memberof PostChecklistInstalledResponseAcDataUpdateAddRowsInner
     */
    'UserDefinedDimensions'?: PostChecklistInstalledResponseAcDataUpdateAddRowsInnerUserDefinedDimensions;
    /**
     * s:string
     * @type {string}
     * @memberof PostChecklistInstalledResponseAcDataUpdateAddRowsInner
     */
    'SerialNumber'?: string;
}

export const PostChecklistInstalledResponseAcDataUpdateAddRowsInnerChangeStateEnum = {
    Add: 'Add',
    Delete: 'Delete',
    Edit: 'Edit',
    None: 'None'
} as const;

export type PostChecklistInstalledResponseAcDataUpdateAddRowsInnerChangeStateEnum = typeof PostChecklistInstalledResponseAcDataUpdateAddRowsInnerChangeStateEnum[keyof typeof PostChecklistInstalledResponseAcDataUpdateAddRowsInnerChangeStateEnum];
export const PostChecklistInstalledResponseAcDataUpdateAddRowsInnerTypeEnum = {
    Normal: 'Normal',
    Text: 'Text',
    TextBold: 'TextBold'
} as const;

export type PostChecklistInstalledResponseAcDataUpdateAddRowsInnerTypeEnum = typeof PostChecklistInstalledResponseAcDataUpdateAddRowsInnerTypeEnum[keyof typeof PostChecklistInstalledResponseAcDataUpdateAddRowsInnerTypeEnum];

/**
 * TaxSettings
 * @export
 * @interface PostChecklistInstalledResponseAcDataUpdateAddRowsInnerTaxSettings
 */
export interface PostChecklistInstalledResponseAcDataUpdateAddRowsInnerTaxSettings {
    /**
     * s:int
     * @type {number}
     * @memberof PostChecklistInstalledResponseAcDataUpdateAddRowsInnerTaxSettings
     */
    'TaxAccount'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof PostChecklistInstalledResponseAcDataUpdateAddRowsInnerTaxSettings
     */
    'TaxCode'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof PostChecklistInstalledResponseAcDataUpdateAddRowsInnerTaxSettings
     */
    'TaxRate'?: number;
}
/**
 * UserDefinedDimensions
 * @export
 * @interface PostChecklistInstalledResponseAcDataUpdateAddRowsInnerUserDefinedDimensions
 */
export interface PostChecklistInstalledResponseAcDataUpdateAddRowsInnerUserDefinedDimensions {
    /**
     * UserDefinedDimension[]
     * @type {Array<PostChecklistInstalledResponseAcDataUpdateAddRowsInnerUserDefinedDimensionsUserDefinedDimensionInner>}
     * @memberof PostChecklistInstalledResponseAcDataUpdateAddRowsInnerUserDefinedDimensions
     */
    'UserDefinedDimension'?: Array<PostChecklistInstalledResponseAcDataUpdateAddRowsInnerUserDefinedDimensionsUserDefinedDimensionInner>;
}
/**
 * UserDefinedDimension
 * @export
 * @interface PostChecklistInstalledResponseAcDataUpdateAddRowsInnerUserDefinedDimensionsUserDefinedDimensionInner
 */
export interface PostChecklistInstalledResponseAcDataUpdateAddRowsInnerUserDefinedDimensionsUserDefinedDimensionInner {
    /**
     * s:int
     * @type {number}
     * @memberof PostChecklistInstalledResponseAcDataUpdateAddRowsInnerUserDefinedDimensionsUserDefinedDimensionInner
     */
    'TypeId'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof PostChecklistInstalledResponseAcDataUpdateAddRowsInnerUserDefinedDimensionsUserDefinedDimensionInner
     */
    'Name'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostChecklistInstalledResponseAcDataUpdateAddRowsInnerUserDefinedDimensionsUserDefinedDimensionInner
     */
    'Value'?: string;
}
/**
 * 
 * @export
 * @interface PostChecklistInstalledResponseMrpData
 */
export interface PostChecklistInstalledResponseMrpData {
    /**
     * 
     * @type {Array<PostChecklistInstalledResponseMrpDataAddLineItemsInner>}
     * @memberof PostChecklistInstalledResponseMrpData
     */
    'addLineItems': Array<PostChecklistInstalledResponseMrpDataAddLineItemsInner>;
    /**
     * 
     * @type {Array<PostChecklistInstalledResponseMrpDataDeleteLineItemsInner>}
     * @memberof PostChecklistInstalledResponseMrpData
     */
    'deleteLineItems': Array<PostChecklistInstalledResponseMrpDataDeleteLineItemsInner>;
    /**
     * 
     * @type {Array<PostChecklistInstalledResponseMrpDataUpdateLineItemsInner>}
     * @memberof PostChecklistInstalledResponseMrpData
     */
    'updateLineItems': Array<PostChecklistInstalledResponseMrpDataUpdateLineItemsInner>;
    /**
     * 
     * @type {Array<PostChecklistInstalledResponseMrpDataOriginalLineItemsInner>}
     * @memberof PostChecklistInstalledResponseMrpData
     */
    'originalLineItems': Array<PostChecklistInstalledResponseMrpDataOriginalLineItemsInner>;
}
/**
 * 
 * @export
 * @interface PostChecklistInstalledResponseMrpDataAddLineItemsInner
 */
export interface PostChecklistInstalledResponseMrpDataAddLineItemsInner {
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof PostChecklistInstalledResponseMrpDataAddLineItemsInner
     */
    'LineItemID': ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof PostChecklistInstalledResponseMrpDataAddLineItemsInner
     */
    'SalesOrderID': ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof PostChecklistInstalledResponseMrpDataAddLineItemsInner
     */
    'Quantity': ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {string}
     * @memberof PostChecklistInstalledResponseMrpDataAddLineItemsInner
     */
    'PromiseDate': string;
    /**
     * 
     * @type {string}
     * @memberof PostChecklistInstalledResponseMrpDataAddLineItemsInner
     */
    'RequestedDate': string;
    /**
     * 
     * @type {string}
     * @memberof PostChecklistInstalledResponseMrpDataAddLineItemsInner
     */
    'ItemNumber': string;
    /**
     * 
     * @type {string}
     * @memberof PostChecklistInstalledResponseMrpDataAddLineItemsInner
     */
    'CustomerSupplierItemNumber': string;
    /**
     * 
     * @type {string}
     * @memberof PostChecklistInstalledResponseMrpDataAddLineItemsInner
     */
    'Notes': string;
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof PostChecklistInstalledResponseMrpDataAddLineItemsInner
     */
    'UnitPrice': ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof PostChecklistInstalledResponseMrpDataAddLineItemsInner
     */
    'DiscountPercent'?: ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {string}
     * @memberof PostChecklistInstalledResponseMrpDataAddLineItemsInner
     */
    'AdditionalDataValues': string;
    /**
     * 
     * @type {string}
     * @memberof PostChecklistInstalledResponseMrpDataAddLineItemsInner
     */
    'TaxCode': string;
    /**
     * 
     * @type {string}
     * @memberof PostChecklistInstalledResponseMrpDataAddLineItemsInner
     */
    'Warehouse': string;
}
/**
 * 
 * @export
 * @interface PostChecklistInstalledResponseMrpDataDeleteLineItemsInner
 */
export interface PostChecklistInstalledResponseMrpDataDeleteLineItemsInner {
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof PostChecklistInstalledResponseMrpDataDeleteLineItemsInner
     */
    'LineItemID': ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof PostChecklistInstalledResponseMrpDataDeleteLineItemsInner
     */
    'SalesOrderID': ItemListResponseItemAdditionalDataValuesInnerID;
}
/**
 * 
 * @export
 * @interface PostChecklistInstalledResponseMrpDataOriginalLineItemsInner
 */
export interface PostChecklistInstalledResponseMrpDataOriginalLineItemsInner {
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseMrpDataOriginalLineItemsInner
     */
    'LineItemID': number;
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseMrpDataOriginalLineItemsInner
     */
    'ItemMasterID': number;
    /**
     * 
     * @type {string}
     * @memberof PostChecklistInstalledResponseMrpDataOriginalLineItemsInner
     */
    'ItemNumber': string;
    /**
     * 
     * @type {string}
     * @memberof PostChecklistInstalledResponseMrpDataOriginalLineItemsInner
     */
    'Revision': string;
    /**
     * 
     * @type {string}
     * @memberof PostChecklistInstalledResponseMrpDataOriginalLineItemsInner
     */
    'Description': string;
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseMrpDataOriginalLineItemsInner
     */
    'Quantity': number;
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseMrpDataOriginalLineItemsInner
     */
    'UnitPrice': number;
    /**
     * 
     * @type {Array<PostChecklistInstalledResponseMrpDataOriginalLineItemsInnerAdditionalDataValuesInner>}
     * @memberof PostChecklistInstalledResponseMrpDataOriginalLineItemsInner
     */
    'AdditionalDataValues': Array<PostChecklistInstalledResponseMrpDataOriginalLineItemsInnerAdditionalDataValuesInner>;
    /**
     * 
     * @type {object}
     * @memberof PostChecklistInstalledResponseMrpDataOriginalLineItemsInner
     */
    'ConfigurationData': object;
    /**
     * 
     * @type {string}
     * @memberof PostChecklistInstalledResponseMrpDataOriginalLineItemsInner
     */
    'Warehouse': string;
    /**
     * 
     * @type {string}
     * @memberof PostChecklistInstalledResponseMrpDataOriginalLineItemsInner
     */
    'RequestedDate': string;
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseMrpDataOriginalLineItemsInner
     */
    'DiscountAmount': number;
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseMrpDataOriginalLineItemsInner
     */
    'DiscountPercent': number;
    /**
     * 
     * @type {string}
     * @memberof PostChecklistInstalledResponseMrpDataOriginalLineItemsInner
     */
    'GTIN': string;
    /**
     * 
     * @type {string}
     * @memberof PostChecklistInstalledResponseMrpDataOriginalLineItemsInner
     */
    'LineItemStatus': PostChecklistInstalledResponseMrpDataOriginalLineItemsInnerLineItemStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PostChecklistInstalledResponseMrpDataOriginalLineItemsInner
     */
    'PromiseDate': string;
    /**
     * 
     * @type {string}
     * @memberof PostChecklistInstalledResponseMrpDataOriginalLineItemsInner
     */
    'Category': string;
    /**
     * 
     * @type {string}
     * @memberof PostChecklistInstalledResponseMrpDataOriginalLineItemsInner
     */
    'SubCategory': string;
}

export const PostChecklistInstalledResponseMrpDataOriginalLineItemsInnerLineItemStatusEnum = {'---': '---',
    Canceled: 'Canceled',
    Invoiced: 'Invoiced',
    Loaded: 'Loaded',
    Pending: 'Pending',
    Picked: 'Picked',
    Production: 'Production',
    Released: 'Released',
    Shipped: 'Shipped',
    Staged: 'Staged'
} as const;

export type PostChecklistInstalledResponseMrpDataOriginalLineItemsInnerLineItemStatusEnum = typeof PostChecklistInstalledResponseMrpDataOriginalLineItemsInnerLineItemStatusEnum[keyof typeof PostChecklistInstalledResponseMrpDataOriginalLineItemsInnerLineItemStatusEnum];

/**
 * 
 * @export
 * @interface PostChecklistInstalledResponseMrpDataOriginalLineItemsInnerAdditionalDataValuesInner
 */
export interface PostChecklistInstalledResponseMrpDataOriginalLineItemsInnerAdditionalDataValuesInner {
    /**
     * 
     * @type {string}
     * @memberof PostChecklistInstalledResponseMrpDataOriginalLineItemsInnerAdditionalDataValuesInner
     */
    'ID': string;
    /**
     * 
     * @type {string}
     * @memberof PostChecklistInstalledResponseMrpDataOriginalLineItemsInnerAdditionalDataValuesInner
     */
    'Name': string;
    /**
     * 
     * @type {string}
     * @memberof PostChecklistInstalledResponseMrpDataOriginalLineItemsInnerAdditionalDataValuesInner
     */
    'Value': string;
}
/**
 * 
 * @export
 * @interface PostChecklistInstalledResponseMrpDataUpdateLineItemsInner
 */
export interface PostChecklistInstalledResponseMrpDataUpdateLineItemsInner {
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof PostChecklistInstalledResponseMrpDataUpdateLineItemsInner
     */
    'LineItemID': ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof PostChecklistInstalledResponseMrpDataUpdateLineItemsInner
     */
    'SalesOrderID': ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof PostChecklistInstalledResponseMrpDataUpdateLineItemsInner
     */
    'Quantity': ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {string}
     * @memberof PostChecklistInstalledResponseMrpDataUpdateLineItemsInner
     */
    'PromiseDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostChecklistInstalledResponseMrpDataUpdateLineItemsInner
     */
    'RequestedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostChecklistInstalledResponseMrpDataUpdateLineItemsInner
     */
    'Warehouse'?: string;
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof PostChecklistInstalledResponseMrpDataUpdateLineItemsInner
     */
    'UnitPrice'?: ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof PostChecklistInstalledResponseMrpDataUpdateLineItemsInner
     */
    'DiscountPercent'?: ItemListResponseItemAdditionalDataValuesInnerID;
}
/**
 * 
 * @export
 * @interface PostChecklistInstalledResponseProductLinesInner
 */
export interface PostChecklistInstalledResponseProductLinesInner {
    /**
     * The text line for quote/orderconfirmation
     * @type {string}
     * @memberof PostChecklistInstalledResponseProductLinesInner
     */
    'line': string;
    /**
     * 
     * @type {PostChecklistInstalledResponseProductLinesInnerProd}
     * @memberof PostChecklistInstalledResponseProductLinesInner
     */
    'prod': PostChecklistInstalledResponseProductLinesInnerProd;
    /**
     * The total price with VAT (priceWithoutVat * qty * vat)
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInner
     */
    'price': number;
    /**
     * The discounted total price if there was a discount, -1 if no discount
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInner
     */
    'discount': number;
    /**
     * The original price without VAT per unit
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInner
     */
    'priceWithoutVat': number;
    /**
     * The discount rate in percentage (0-100), undefined = no discount
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInner
     */
    'discountRate'?: number;
    /**
     * The VAT factor (to multiply with priceWithoutVat)
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInner
     */
    'vatFactor': number;
    /**
     * 
     * @type {PostChecklistInstalledResponseProductLinesInnerProdData}
     * @memberof PostChecklistInstalledResponseProductLinesInner
     */
    'prodData': PostChecklistInstalledResponseProductLinesInnerProdData;
    /**
     * 
     * @type {PostChecklistInstalledResponseProductLinesInnerCustomProduct}
     * @memberof PostChecklistInstalledResponseProductLinesInner
     */
    'customProduct'?: PostChecklistInstalledResponseProductLinesInnerCustomProduct;
}
/**
 * If the product is a custom product, this has the name of the custom product
 * @export
 * @interface PostChecklistInstalledResponseProductLinesInnerCustomProduct
 */
export interface PostChecklistInstalledResponseProductLinesInnerCustomProduct {
    /**
     * 
     * @type {string}
     * @memberof PostChecklistInstalledResponseProductLinesInnerCustomProduct
     */
    'name': string;
}
/**
 * @type PostChecklistInstalledResponseProductLinesInnerProd
 * The CPpro product
 * @export
 */
export type PostChecklistInstalledResponseProductLinesInnerProd = PostChecklistInstalledResponseProductLinesInnerProdOneOf | PostChecklistInstalledResponseProductLinesInnerProdOneOf1;

/**
 * Data from the productline in the actual order, JSONparsed
 * @export
 * @interface PostChecklistInstalledResponseProductLinesInnerProdData
 */
export interface PostChecklistInstalledResponseProductLinesInnerProdData {
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdData
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdData
     */
    'qty': number;
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdData
     */
    'discount'?: number;
    /**
     * 
     * @type {string}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdData
     */
    'note'?: string;
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdData
     */
    'price'?: number;
}
/**
 * 
 * @export
 * @interface PostChecklistInstalledResponseProductLinesInnerProdOneOf
 */
export interface PostChecklistInstalledResponseProductLinesInnerProdOneOf {
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOf
     */
    'id': number;
    /**
     * 
     * @type {Array<PostChecklistInstalledResponseProductLinesInnerProdOneOfPropertiesInner>}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOf
     */
    'properties': Array<PostChecklistInstalledResponseProductLinesInnerProdOneOfPropertiesInner>;
    /**
     * 
     * @type {Array<PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInner>}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOf
     */
    'checklist': Array<PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInner>;
}
/**
 * 
 * @export
 * @interface PostChecklistInstalledResponseProductLinesInnerProdOneOf1
 */
export interface PostChecklistInstalledResponseProductLinesInnerProdOneOf1 {
    /**
     * 
     * @type {string}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOf1
     */
    'deleted': string;
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOf1
     */
    'id': number;
    /**
     * 
     * @type {Array<PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerPropertiesInner>}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOf1
     */
    'properties': Array<PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerPropertiesInner>;
}
/**
 * 
 * @export
 * @interface PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInner
 */
export interface PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInner {
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInner
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInner
     */
    'parent_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInner
     */
    '_orderid'?: number;
    /**
     * 
     * @type {Array<PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerPropertiesInner>}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInner
     */
    'properties': Array<PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerPropertiesInner>;
    /**
     * 
     * @type {Array<PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerQuestionInner>}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInner
     */
    'question': Array<PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerQuestionInner>;
}
/**
 * 
 * @export
 * @interface PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerPropertiesInner
 */
export interface PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerPropertiesInner {
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerPropertiesInner
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerPropertiesInner
     */
    'parent_id': number;
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerPropertiesInner
     */
    'type': number;
    /**
     * 
     * @type {string}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerPropertiesInner
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerPropertiesInner
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerPropertiesInner
     */
    'strvalue'?: string;
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerPropertiesInner
     */
    'numvalue'?: number;
}
/**
 * 
 * @export
 * @interface PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerQuestionInner
 */
export interface PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerQuestionInner {
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerQuestionInner
     */
    '_seqno': number;
    /**
     * 
     * @type {string}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerQuestionInner
     */
    '_checklist'?: string;
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerQuestionInner
     */
    '_checklistid'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerQuestionInner
     */
    '_itemid'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerQuestionInner
     */
    '_product'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerQuestionInner
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerQuestionInner
     */
    'parent_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerQuestionInner
     */
    '_order_id'?: number;
    /**
     * 
     * @type {Array<PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerPropertiesInner>}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerQuestionInner
     */
    'properties': Array<PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerPropertiesInner>;
    /**
     * 
     * @type {Array<PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerQuestionInnerAnswerInner>}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerQuestionInner
     */
    'answer'?: Array<PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerQuestionInnerAnswerInner>;
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerQuestionInner
     */
    '_internal_use'?: number;
}
/**
 * 
 * @export
 * @interface PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerQuestionInnerAnswerInner
 */
export interface PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerQuestionInnerAnswerInner {
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerQuestionInnerAnswerInner
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerQuestionInnerAnswerInner
     */
    'created': number;
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerQuestionInnerAnswerInner
     */
    'parent_id': number;
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerQuestionInnerAnswerInner
     */
    'item_id': number;
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerQuestionInnerAnswerInner
     */
    'checklist_id': number;
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerQuestionInnerAnswerInner
     */
    'seq_no': number;
    /**
     * 
     * @type {Array<PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerPropertiesInner>}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerQuestionInnerAnswerInner
     */
    'properties': Array<PostChecklistInstalledResponseProductLinesInnerProdOneOfChecklistInnerPropertiesInner>;
}
/**
 * 
 * @export
 * @interface PostChecklistInstalledResponseProductLinesInnerProdOneOfPropertiesInner
 */
export interface PostChecklistInstalledResponseProductLinesInnerProdOneOfPropertiesInner {
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfPropertiesInner
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfPropertiesInner
     */
    'parent_id': number;
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfPropertiesInner
     */
    'type': number;
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfPropertiesInner
     */
    'value': ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {string}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfPropertiesInner
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfPropertiesInner
     */
    'strvalue'?: string;
    /**
     * 
     * @type {number}
     * @memberof PostChecklistInstalledResponseProductLinesInnerProdOneOfPropertiesInner
     */
    'numvalue'?: number;
}
/**
 * 
 * @export
 * @interface PostConstantRequest
 */
export interface PostConstantRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof PostConstantRequest
     */
    'key': string;
    /**
     * 
     * @type {PostConstantRequestJson}
     * @memberof PostConstantRequest
     */
    'json'?: PostConstantRequestJson;
    /**
     * 
     * @type {string}
     * @memberof PostConstantRequest
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof PostConstantRequest
     */
    'type'?: PostConstantRequestTypeEnum;
}

export const PostConstantRequestTypeEnum = {
    Boolean: 'BOOLEAN',
    Number: 'NUMBER',
    Numberarray: 'NUMBERARRAY',
    Object: 'OBJECT',
    Objectarray: 'OBJECTARRAY',
    String: 'STRING',
    Stringarray: 'STRINGARRAY'
} as const;

export type PostConstantRequestTypeEnum = typeof PostConstantRequestTypeEnum[keyof typeof PostConstantRequestTypeEnum];

/**
 * @type PostConstantRequestJson
 * @export
 */
export type PostConstantRequestJson = ConstantCreateInputJsonOneOf1 | PostConstantRequestJsonOneOf | object;

/**
 * Matches a JSON array. Unlike \\`JsonArray\\`, readonly arrays are assignable to this type.
 * @export
 * @interface PostConstantRequestJsonOneOf
 */
export interface PostConstantRequestJsonOneOf {
    /**
     * 
     * @type {number}
     * @memberof PostConstantRequestJsonOneOf
     */
    'length': number;
    /**
     * 
     * @type {PostConstantRequestJsonOneOfUnscopables3003}
     * @memberof PostConstantRequestJsonOneOf
     */
    '__@unscopables@3003': PostConstantRequestJsonOneOfUnscopables3003;
}
/**
 * 
 * @export
 * @interface PostConstantRequestJsonOneOfUnscopables3003
 */
export interface PostConstantRequestJsonOneOfUnscopables3003 {
    /**
     * 
     * @type {boolean}
     * @memberof PostConstantRequestJsonOneOfUnscopables3003
     */
    'length'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostConstantRequestJsonOneOfUnscopables3003
     */
    'toString'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostConstantRequestJsonOneOfUnscopables3003
     */
    'toLocaleString'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostConstantRequestJsonOneOfUnscopables3003
     */
    'concat'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostConstantRequestJsonOneOfUnscopables3003
     */
    'join'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostConstantRequestJsonOneOfUnscopables3003
     */
    'slice'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostConstantRequestJsonOneOfUnscopables3003
     */
    'indexOf'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostConstantRequestJsonOneOfUnscopables3003
     */
    'lastIndexOf'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostConstantRequestJsonOneOfUnscopables3003
     */
    'every'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostConstantRequestJsonOneOfUnscopables3003
     */
    'some'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostConstantRequestJsonOneOfUnscopables3003
     */
    'forEach'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostConstantRequestJsonOneOfUnscopables3003
     */
    'map'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostConstantRequestJsonOneOfUnscopables3003
     */
    'filter'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostConstantRequestJsonOneOfUnscopables3003
     */
    'reduce'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostConstantRequestJsonOneOfUnscopables3003
     */
    'reduceRight'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostConstantRequestJsonOneOfUnscopables3003
     */
    'find'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostConstantRequestJsonOneOfUnscopables3003
     */
    'findIndex'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostConstantRequestJsonOneOfUnscopables3003
     */
    'entries'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostConstantRequestJsonOneOfUnscopables3003
     */
    'keys'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostConstantRequestJsonOneOfUnscopables3003
     */
    'values'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostConstantRequestJsonOneOfUnscopables3003
     */
    'includes'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostConstantRequestJsonOneOfUnscopables3003
     */
    'flatMap'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostConstantRequestJsonOneOfUnscopables3003
     */
    'flat'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostConstantRequestJsonOneOfUnscopables3003
     */
    '__@iterator@3001'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostConstantRequestJsonOneOfUnscopables3003
     */
    '__@unscopables@3003'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostConstantRequestJsonOneOfUnscopables3003
     */
    'at'?: boolean;
}
/**
 * 
 * @export
 * @interface PostCreateOrderRequest
 */
export interface PostCreateOrderRequest {
    /**
     * 
     * @type {number}
     * @memberof PostCreateOrderRequest
     */
    'cpproId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PostCreateOrderRequest
     */
    'salesOrderName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostCreateOrderRequest
     */
    'deliveryDate'?: string;
}
/**
 * 
 * @export
 * @interface PostFindMRPOrderResponse
 */
export interface PostFindMRPOrderResponse {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof PostFindMRPOrderResponse
     */
    'fixedWarehouse': string;
    /**
     * 
     * @type {Array<PostChecklistInstalledResponseAcDataUpdateAddRowsInner>}
     * @memberof PostFindMRPOrderResponse
     */
    'acInpriceRows': Array<PostChecklistInstalledResponseAcDataUpdateAddRowsInner>;
}
/**
 * 
 * @export
 * @interface PostInactivateFJAndInvoiceACResponse
 */
export interface PostInactivateFJAndInvoiceACResponse {
    [key: string]: any;

    /**
     * 
     * @type {PostInactivateFJAndInvoiceACResponseAcUpdateData}
     * @memberof PostInactivateFJAndInvoiceACResponse
     */
    'acUpdateData': PostInactivateFJAndInvoiceACResponseAcUpdateData;
    /**
     * 
     * @type {number}
     * @memberof PostInactivateFJAndInvoiceACResponse
     */
    'newOrderId': number;
    /**
     * 
     * @type {number}
     * @memberof PostInactivateFJAndInvoiceACResponse
     */
    'inactivatedOldOrderId': number;
    /**
     * 
     * @type {PostInactivateFJAndInvoiceACResponseErpSyncUpdate}
     * @memberof PostInactivateFJAndInvoiceACResponse
     */
    'erpSyncUpdate': PostInactivateFJAndInvoiceACResponseErpSyncUpdate;
}
/**
 * 
 * @export
 * @interface PostInactivateFJAndInvoiceACResponseAcUpdateData
 */
export interface PostInactivateFJAndInvoiceACResponseAcUpdateData {
    /**
     * 
     * @type {PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateData
     */
    'updateData': PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData;
    /**
     * 
     * @type {PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateData
     */
    'originalData': PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData;
}
/**
 * InvoiceOrder
 * @export
 * @interface PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
 */
export interface PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData {
    /**
     * s:int
     * @type {number}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'OrderId'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'CustomerId'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'CustomerName'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'CustomerDeliveryName'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'CustomerDeliveryPhone'?: string;
    /**
     * 
     * @type {PostACInvoiceOrderResponseAddresses}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'Addresses'?: PostACInvoiceOrderResponseAddresses;
    /**
     * OrderSlipStateType|s:string|None,Offer,Pack,Invoiced,Web,Repeating,CollectingOrder,Return,Distribution,Rest,ForInvoicing,OvertimeRent,Confirmed,OrderState_Sent,Production,PaymentReminder,OnAccount,Lending,InvoicedCashAccount,InvoicedPack,Inactive,Hours,OrderState_SuperStore,Draft
     * @type {string}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'OrderStatus'?: PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateDataOrderStatusEnum;
    /**
     * s:int
     * @type {number}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'InvoiceId'?: number;
    /**
     * 
     * @type {GetInvoicesSearchParamsChangedAfter}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'DateOrdered'?: GetInvoicesSearchParamsChangedAfter;
    /**
     * 
     * @type {GetInvoicesSearchParamsChangedAfter}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'DateInvoiced'?: GetInvoicesSearchParamsChangedAfter;
    /**
     * 
     * @type {GetInvoicesSearchParamsChangedAfter}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'DateChanged'?: GetInvoicesSearchParamsChangedAfter;
    /**
     * s:int
     * @type {number}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'PaymentTime'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'CustomerReferenceNo'?: string;
    /**
     * s:int
     * @type {number}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'ProjectId'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'OurReference'?: number;
    /**
     * s:boolean
     * @type {boolean}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'IncludeVAT'?: boolean;
    /**
     * s:string
     * @type {string}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'YourReference'?: string;
    /**
     * s:decimal
     * @type {number}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'OrderTotalIncVat'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'OrderTotalVat'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'InvoiceTitle'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'InvoiceText'?: string;
    /**
     * s:dateTime
     * @type {string}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'Paid'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'OCR'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'CustomerOrgNo'?: string;
    /**
     * 
     * @type {PostACInvoiceOrderResponseCurrency}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'Currency'?: PostACInvoiceOrderResponseCurrency;
    /**
     * s:int
     * @type {number}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'PaymentMethodId'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'PaymentAmount'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'ProductionManagerId'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'SalesOpportunityId'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'TypeOfSaleId'?: number;
    /**
     * Distributor|s:string|Default,Manual,Auto
     * @type {string}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'Distributor'?: PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateDataDistributorEnum;
    /**
     * DistributionMethod|s:string|Unchanged,Print,EMail,ElectronicInvoice
     * @type {string}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'DistributionMethod'?: PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateDataDistributionMethodEnum;
    /**
     * s:int
     * @type {number}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'DepartmentId'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'ExternalStatus'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'InvoiceEmailAddress'?: string;
    /**
     * 
     * @type {PostACInvoiceOrderResponseInvoiceRows}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'InvoiceRows'?: PostACInvoiceOrderResponseInvoiceRows;
    /**
     * 
     * @type {PostACInvoiceOrderResponseAPIException}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'APIException'?: PostACInvoiceOrderResponseAPIException;
    /**
     * s:string
     * @type {string}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'ProductionNumber'?: string;
    /**
     * 
     * @type {GetInvoicesSearchParamsChangedAfter}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'DeliveryDate'?: GetInvoicesSearchParamsChangedAfter;
    /**
     * s:int
     * @type {number}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'ReferenceInvoiceId'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'ReferenceOrderId'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'ReferenceNumber'?: string;
    /**
     * s:boolean
     * @type {boolean}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'SkipStock'?: boolean;
    /**
     * s:dateTime
     * @type {string}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'AccrualDate'?: string;
    /**
     * s:int
     * @type {number}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'AccrualLength'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'RoundFactor'?: number;
    /**
     * guid|s:string|pattern
     * @type {string}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'InvoiceTemplateId'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'VippsNumber'?: string;
    /**
     * 
     * @type {PostACInvoiceOrderResponseDeliveryMethod}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'DeliveryMethod'?: PostACInvoiceOrderResponseDeliveryMethod;
    /**
     * s:string
     * @type {string}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'DeliveryAlternative'?: string;
    /**
     * s:boolean
     * @type {boolean}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'SendToFactoring'?: boolean;
    /**
     * s:decimal
     * @type {number}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'Commission'?: number;
    /**
     * 
     * @type {PostChecklistInstalledResponseAcDataUpdateAddRowsInnerUserDefinedDimensions}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'UserDefinedDimensions'?: PostChecklistInstalledResponseAcDataUpdateAddRowsInnerUserDefinedDimensions;
    /**
     * s:string
     * @type {string}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'GLNNumber'?: string;
    /**
     * s:int
     * @type {number}
     * @memberof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateData
     */
    'CustomerDeliveryId'?: number;
}

export const PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateDataOrderStatusEnum = {
    CollectingOrder: 'CollectingOrder',
    Confirmed: 'Confirmed',
    Distribution: 'Distribution',
    Draft: 'Draft',
    ForInvoicing: 'ForInvoicing',
    Hours: 'Hours',
    Inactive: 'Inactive',
    Invoiced: 'Invoiced',
    InvoicedCashAccount: 'InvoicedCashAccount',
    InvoicedPack: 'InvoicedPack',
    Lending: 'Lending',
    None: 'None',
    Offer: 'Offer',
    OnAccount: 'OnAccount',
    OrderStateSent: 'OrderState_Sent',
    OrderStateSuperStore: 'OrderState_SuperStore',
    OvertimeRent: 'OvertimeRent',
    Pack: 'Pack',
    PaymentReminder: 'PaymentReminder',
    Production: 'Production',
    Repeating: 'Repeating',
    Rest: 'Rest',
    Return: 'Return',
    Web: 'Web'
} as const;

export type PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateDataOrderStatusEnum = typeof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateDataOrderStatusEnum[keyof typeof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateDataOrderStatusEnum];
export const PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateDataDistributorEnum = {
    Auto: 'Auto',
    Default: 'Default',
    Manual: 'Manual'
} as const;

export type PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateDataDistributorEnum = typeof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateDataDistributorEnum[keyof typeof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateDataDistributorEnum];
export const PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateDataDistributionMethodEnum = {
    EMail: 'EMail',
    ElectronicInvoice: 'ElectronicInvoice',
    Print: 'Print',
    Unchanged: 'Unchanged'
} as const;

export type PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateDataDistributionMethodEnum = typeof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateDataDistributionMethodEnum[keyof typeof PostInactivateFJAndInvoiceACResponseAcUpdateDataUpdateDataDistributionMethodEnum];

/**
 * 
 * @export
 * @interface PostInactivateFJAndInvoiceACResponseErpSyncUpdate
 */
export interface PostInactivateFJAndInvoiceACResponseErpSyncUpdate {
    /**
     * 
     * @type {number}
     * @memberof PostInactivateFJAndInvoiceACResponseErpSyncUpdate
     */
    'finalErpId': number;
    /**
     * 
     * @type {boolean}
     * @memberof PostInactivateFJAndInvoiceACResponseErpSyncUpdate
     */
    'fjErpInvoiced': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PostInactivateFJAndInvoiceACResponseErpSyncUpdate
     */
    'acErpInvoiced': boolean;
}
/**
 * 
 * @export
 * @interface PostInstallItemUsageAlternativeRequest
 */
export interface PostInstallItemUsageAlternativeRequest {
    /**
     * 
     * @type {string}
     * @memberof PostInstallItemUsageAlternativeRequest
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof PostInstallItemUsageAlternativeRequest
     */
    'questionId': number;
    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof PostInstallItemUsageAlternativeRequest
     */
    'cachedAt'?: InstallItemUsageAlternativeCreateInputCachedAt;
    /**
     * 
     * @type {boolean}
     * @memberof PostInstallItemUsageAlternativeRequest
     */
    'useSalesOrder'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PostInstallItemUsageAlternativeRequest
     */
    'quantityFactor'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PostInstallItemUsageAlternativeRequest
     */
    'flatAddition'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PostInstallItemUsageAlternativeRequest
     */
    'storageUnitConversion'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PostInstallItemUsageAlternativeRequest
     */
    'itemNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostInstallItemUsageAlternativeRequest
     */
    'questionName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostInstallItemUsageAlternativeRequest
     */
    'answer'?: string | null;
}
/**
 * 
 * @export
 * @interface PostInstallItemUsageQuantityRequest
 */
export interface PostInstallItemUsageQuantityRequest {
    [key: string]: any;

    /**
     * 
     * @type {Array<InstallItemUsageUpdateInputQuantitiesInner>}
     * @memberof PostInstallItemUsageQuantityRequest
     */
    'quantities': Array<InstallItemUsageUpdateInputQuantitiesInner>;
}
/**
 * 
 * @export
 * @interface PostInstallItemUsageRequest
 */
export interface PostInstallItemUsageRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof PostInstallItemUsageRequest
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostInstallItemUsageRequest
     */
    'itemNumber': string;
    /**
     * 
     * @type {Array<PostInstallItemUsageRequestAlternativesInner>}
     * @memberof PostInstallItemUsageRequest
     */
    'alternatives': Array<PostInstallItemUsageRequestAlternativesInner>;
    /**
     * 
     * @type {Array<PostInstallItemUsageRequestQuantitiesInner>}
     * @memberof PostInstallItemUsageRequest
     */
    'quantities': Array<PostInstallItemUsageRequestQuantitiesInner>;
    /**
     * 
     * @type {number}
     * @memberof PostInstallItemUsageRequest
     */
    'staticQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PostInstallItemUsageRequest
     */
    'discount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PostInstallItemUsageRequest
     */
    'flatAddition'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PostInstallItemUsageRequest
     */
    'storageUnitConversion'?: number | null;
}
/**
 * 
 * @export
 * @interface PostInstallItemUsageRequestAlternativesInner
 */
export interface PostInstallItemUsageRequestAlternativesInner {
    /**
     * 
     * @type {string}
     * @memberof PostInstallItemUsageRequestAlternativesInner
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof PostInstallItemUsageRequestAlternativesInner
     */
    'questionId': number;
    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof PostInstallItemUsageRequestAlternativesInner
     */
    'cachedAt'?: InstallItemUsageAlternativeCreateInputCachedAt;
    /**
     * 
     * @type {boolean}
     * @memberof PostInstallItemUsageRequestAlternativesInner
     */
    'useSalesOrder'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PostInstallItemUsageRequestAlternativesInner
     */
    'quantityFactor'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PostInstallItemUsageRequestAlternativesInner
     */
    'flatAddition'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PostInstallItemUsageRequestAlternativesInner
     */
    'storageUnitConversion'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PostInstallItemUsageRequestAlternativesInner
     */
    'itemNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostInstallItemUsageRequestAlternativesInner
     */
    'questionName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostInstallItemUsageRequestAlternativesInner
     */
    'answer'?: string;
}
/**
 * 
 * @export
 * @interface PostInstallItemUsageRequestQuantitiesInner
 */
export interface PostInstallItemUsageRequestQuantitiesInner {
    /**
     * 
     * @type {number}
     * @memberof PostInstallItemUsageRequestQuantitiesInner
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof PostInstallItemUsageRequestQuantitiesInner
     */
    'meter': number;
}
/**
 * 
 * @export
 * @interface PostMRPInvoiceResponse
 */
export interface PostMRPInvoiceResponse {
    /**
     * 
     * @type {number}
     * @memberof PostMRPInvoiceResponse
     */
    'mrpSaleId': number;
    /**
     * 
     * @type {string}
     * @memberof PostMRPInvoiceResponse
     */
    'mrpInvoiceId': string;
    /**
     * 
     * @type {string}
     * @memberof PostMRPInvoiceResponse
     */
    'mrpInvoiceDate': string;
}
/**
 * 
 * @export
 * @interface PostMRPManuallyInvoicedRequest
 */
export interface PostMRPManuallyInvoicedRequest {
    /**
     * 
     * @type {string}
     * @memberof PostMRPManuallyInvoicedRequest
     */
    'invoiceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostMRPManuallyInvoicedRequest
     */
    'invoicedDateTime': string;
}
/**
 * @type PostMRPPickResponse
 * @export
 */
export type PostMRPPickResponse = Array<PostMRPPickResponseOneOfInner> | PostMRPPickResponseOneOf;

/**
 * 
 * @export
 * @interface PostMRPPickResponseError
 */
export interface PostMRPPickResponseError {
    [key: string]: any;

    /**
     * 
     * @type {boolean}
     * @memberof PostMRPPickResponseError
     */
    'error': PostMRPPickResponseErrorErrorEnum;
    /**
     * 
     * @type {PostMRPPickResponseErrorData}
     * @memberof PostMRPPickResponseError
     */
    'data': PostMRPPickResponseErrorData;
    /**
     * 
     * @type {string}
     * @memberof PostMRPPickResponseError
     */
    'message': string;
}

export const PostMRPPickResponseErrorErrorEnum = {
    True: true
} as const;

export type PostMRPPickResponseErrorErrorEnum = typeof PostMRPPickResponseErrorErrorEnum[keyof typeof PostMRPPickResponseErrorErrorEnum];

/**
 * 
 * @export
 * @interface PostMRPPickResponseErrorData
 */
export interface PostMRPPickResponseErrorData {
    [key: string]: any;

    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof PostMRPPickResponseErrorData
     */
    'orderLink': ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {Array<PostMRPPickResponseErrorDataMissingPickLinesInner>}
     * @memberof PostMRPPickResponseErrorData
     */
    'missingPickLines': Array<PostMRPPickResponseErrorDataMissingPickLinesInner>;
}
/**
 * 
 * @export
 * @interface PostMRPPickResponseErrorDataMissingPickLinesInner
 */
export interface PostMRPPickResponseErrorDataMissingPickLinesInner {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof PostMRPPickResponseErrorDataMissingPickLinesInner
     */
    'lineItemId': number;
    /**
     * 
     * @type {string}
     * @memberof PostMRPPickResponseErrorDataMissingPickLinesInner
     */
    'itemNumber': string;
    /**
     * 
     * @type {string}
     * @memberof PostMRPPickResponseErrorDataMissingPickLinesInner
     */
    'itemType': PostMRPPickResponseErrorDataMissingPickLinesInnerItemTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PostMRPPickResponseErrorDataMissingPickLinesInner
     */
    'serialNumber': string;
    /**
     * 
     * @type {string}
     * @memberof PostMRPPickResponseErrorDataMissingPickLinesInner
     */
    'error': string;
}

export const PostMRPPickResponseErrorDataMissingPickLinesInnerItemTypeEnum = {
    Indoor: 'Indoor',
    Outdoor: 'Outdoor'
} as const;

export type PostMRPPickResponseErrorDataMissingPickLinesInnerItemTypeEnum = typeof PostMRPPickResponseErrorDataMissingPickLinesInnerItemTypeEnum[keyof typeof PostMRPPickResponseErrorDataMissingPickLinesInnerItemTypeEnum];

/**
 * 
 * @export
 * @interface PostMRPPickResponseOneOf
 */
export interface PostMRPPickResponseOneOf {
    /**
     * 
     * @type {boolean}
     * @memberof PostMRPPickResponseOneOf
     */
    'error': PostMRPPickResponseOneOfErrorEnum;
    /**
     * 
     * @type {PostMRPPickResponseOneOfData}
     * @memberof PostMRPPickResponseOneOf
     */
    'data': PostMRPPickResponseOneOfData;
    /**
     * 
     * @type {string}
     * @memberof PostMRPPickResponseOneOf
     */
    'message': string;
}

export const PostMRPPickResponseOneOfErrorEnum = {
    True: true
} as const;

export type PostMRPPickResponseOneOfErrorEnum = typeof PostMRPPickResponseOneOfErrorEnum[keyof typeof PostMRPPickResponseOneOfErrorEnum];

/**
 * 
 * @export
 * @interface PostMRPPickResponseOneOfData
 */
export interface PostMRPPickResponseOneOfData {
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof PostMRPPickResponseOneOfData
     */
    'orderLink': ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {Array<PostMRPPickResponseOneOfDataMissingPickLinesInner>}
     * @memberof PostMRPPickResponseOneOfData
     */
    'missingPickLines': Array<PostMRPPickResponseOneOfDataMissingPickLinesInner>;
}
/**
 * 
 * @export
 * @interface PostMRPPickResponseOneOfDataMissingPickLinesInner
 */
export interface PostMRPPickResponseOneOfDataMissingPickLinesInner {
    /**
     * 
     * @type {number}
     * @memberof PostMRPPickResponseOneOfDataMissingPickLinesInner
     */
    'lineItemId': number;
    /**
     * 
     * @type {string}
     * @memberof PostMRPPickResponseOneOfDataMissingPickLinesInner
     */
    'itemNumber': string;
    /**
     * 
     * @type {string}
     * @memberof PostMRPPickResponseOneOfDataMissingPickLinesInner
     */
    'itemType': PostMRPPickResponseOneOfDataMissingPickLinesInnerItemTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PostMRPPickResponseOneOfDataMissingPickLinesInner
     */
    'serialNumber': string;
    /**
     * 
     * @type {string}
     * @memberof PostMRPPickResponseOneOfDataMissingPickLinesInner
     */
    'error': string;
}

export const PostMRPPickResponseOneOfDataMissingPickLinesInnerItemTypeEnum = {
    Indoor: 'Indoor',
    Outdoor: 'Outdoor'
} as const;

export type PostMRPPickResponseOneOfDataMissingPickLinesInnerItemTypeEnum = typeof PostMRPPickResponseOneOfDataMissingPickLinesInnerItemTypeEnum[keyof typeof PostMRPPickResponseOneOfDataMissingPickLinesInnerItemTypeEnum];

/**
 * 
 * @export
 * @interface PostMRPPickResponseOneOfInner
 */
export interface PostMRPPickResponseOneOfInner {
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof PostMRPPickResponseOneOfInner
     */
    'LineItemID': ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof PostMRPPickResponseOneOfInner
     */
    'SalesOrderID': ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {string}
     * @memberof PostMRPPickResponseOneOfInner
     */
    'IdentifiersToPick': string;
}
/**
 * 
 * @export
 * @interface PostMRPShipResponse
 */
export interface PostMRPShipResponse {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof PostMRPShipResponse
     */
    'mrpSaleId': number;
}
/**
 * 
 * @export
 * @interface PostPublicHolidaysRequest
 */
export interface PostPublicHolidaysRequest extends Array<PostPublicHolidaysRequestInner> {
}
/**
 * 
 * @export
 * @interface PostPublicHolidaysRequestInner
 */
export interface PostPublicHolidaysRequestInner {
    /**
     * 
     * @type {number}
     * @memberof PostPublicHolidaysRequestInner
     */
    'år'?: number;
    /**
     * 
     * @type {string}
     * @memberof PostPublicHolidaysRequestInner
     */
    'navn'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostPublicHolidaysRequestInner
     */
    'dato': string;
    /**
     * 
     * @type {string}
     * @memberof PostPublicHolidaysRequestInner
     */
    'dag'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostPublicHolidaysRequestInner
     */
    'uke'?: string;
}
/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    'mamutId': number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'inventoryStatus': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'indoor24SOId': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'outdoor24SOId': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'indoorMRPItemNumber': string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    'outdoorMRPItemNumber': string;
    /**
     * 
     * @type {boolean}
     * @memberof Product
     */
    'deleted': boolean;
}
/**
 * 
 * @export
 * @interface ProductLine
 */
export interface ProductLine {
    [key: string]: any;

    /**
     * The text line for quote/orderconfirmation
     * @type {string}
     * @memberof ProductLine
     */
    'line': string;
    /**
     * 
     * @type {ProductLineProd}
     * @memberof ProductLine
     */
    'prod': ProductLineProd;
    /**
     * The total price with VAT (priceWithoutVat * qty * vat)
     * @type {number}
     * @memberof ProductLine
     */
    'price': number;
    /**
     * The discounted total price if there was a discount, -1 if no discount
     * @type {number}
     * @memberof ProductLine
     */
    'discount': number;
    /**
     * The original price without VAT per unit
     * @type {number}
     * @memberof ProductLine
     */
    'priceWithoutVat': number;
    /**
     * The discount rate in percentage (0-100), undefined = no discount
     * @type {number}
     * @memberof ProductLine
     */
    'discountRate'?: number;
    /**
     * The VAT factor (to multiply with priceWithoutVat)
     * @type {number}
     * @memberof ProductLine
     */
    'vatFactor': number;
    /**
     * 
     * @type {ProductLineProdData}
     * @memberof ProductLine
     */
    'prodData': ProductLineProdData;
    /**
     * 
     * @type {ProductLineCustomProduct}
     * @memberof ProductLine
     */
    'customProduct'?: ProductLineCustomProduct;
}
/**
 * If the product is a custom product, this has the name of the custom product
 * @export
 * @interface ProductLineCustomProduct
 */
export interface ProductLineCustomProduct {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProductLineCustomProduct
     */
    'name': string;
}
/**
 * @type ProductLineProd
 * The CPpro product
 * @export
 */
export type ProductLineProd = ItemProductInner | ProductLineProdOneOf;

/**
 * Data from the productline in the actual order, JSONparsed
 * @export
 * @interface ProductLineProdData
 */
export interface ProductLineProdData {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof ProductLineProdData
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ProductLineProdData
     */
    'qty': number;
    /**
     * 
     * @type {number}
     * @memberof ProductLineProdData
     */
    'discount'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductLineProdData
     */
    'note'?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductLineProdData
     */
    'price'?: number;
}
/**
 * 
 * @export
 * @interface ProductLineProdOneOf
 */
export interface ProductLineProdOneOf {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ProductLineProdOneOf
     */
    'deleted': string;
    /**
     * 
     * @type {number}
     * @memberof ProductLineProdOneOf
     */
    'id': number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof ProductLineProdOneOf
     */
    'properties': Array<AnswerPropertiesInner>;
}
/**
 * 
 * @export
 * @interface ProductPropertyCustomValue
 */
export interface ProductPropertyCustomValue {
    [key: string]: any;

    /**
     * Question Name for the Custom Product Text
     * @type {string}
     * @memberof ProductPropertyCustomValue
     */
    'text'?: string;
    /**
     * Question Name for the Custom Product Price
     * @type {string}
     * @memberof ProductPropertyCustomValue
     */
    'price'?: string;
    /**
     * Question Name for the Custom Product Discount
     * @type {string}
     * @memberof ProductPropertyCustomValue
     */
    'discount'?: string;
}
/**
 * 
 * @export
 * @enum {number}
 */

export const ProductPropertyType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_16: 16,
    NUMBER_17: 17,
    NUMBER_18: 18,
    NUMBER_19: 19,
    NUMBER_20: 20,
    NUMBER_21: 21,
    NUMBER_22: 22,
    NUMBER_23: 23
} as const;

export type ProductPropertyType = typeof ProductPropertyType[keyof typeof ProductPropertyType];


/**
 * 
 * @export
 * @interface ProjectListResponse
 */
export interface ProjectListResponse extends Array<ProjectListResponseInner> {
}
/**
 * 
 * @export
 * @interface ProjectListResponseInner
 */
export interface ProjectListResponseInner {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof ProjectListResponseInner
     */
    'ProjectID': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectListResponseInner
     */
    'ProjectNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectListResponseInner
     */
    'ProjectName': string;
    /**
     * 
     * @type {number}
     * @memberof ProjectListResponseInner
     */
    'ProjectManagerID': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectListResponseInner
     */
    'StatusID': number;
}
/**
 * 
 * @export
 * @interface ProjectListResponseItem
 */
export interface ProjectListResponseItem {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof ProjectListResponseItem
     */
    'ProjectID': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectListResponseItem
     */
    'ProjectNumber': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectListResponseItem
     */
    'ProjectName': string;
    /**
     * 
     * @type {number}
     * @memberof ProjectListResponseItem
     */
    'ProjectManagerID': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectListResponseItem
     */
    'StatusID': number;
}
/**
 * 
 * @export
 * @interface PropertyType
 */
export interface PropertyType {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof PropertyType
     */
    'id': number;
    /**
     * 
     * @type {string}
     * @memberof PropertyType
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface PubSubPushRequest
 */
export interface PubSubPushRequest {
    [key: string]: any;

    /**
     * 
     * @type {PubSubPushRequestMessage}
     * @memberof PubSubPushRequest
     */
    'message': PubSubPushRequestMessage;
    /**
     * 
     * @type {string}
     * @memberof PubSubPushRequest
     */
    'subscription': string;
    /**
     * 
     * @type {number}
     * @memberof PubSubPushRequest
     */
    'deliveryAttempt'?: number;
}
/**
 * 
 * @export
 * @interface PubSubPushRequestMessage
 */
export interface PubSubPushRequestMessage {
    /**
     * 
     * @type {object}
     * @memberof PubSubPushRequestMessage
     */
    'attributes'?: object;
    /**
     * 
     * @type {string}
     * @memberof PubSubPushRequestMessage
     */
    'orderingKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof PubSubPushRequestMessage
     */
    'data': string;
    /**
     * 
     * @type {string}
     * @memberof PubSubPushRequestMessage
     */
    'messageId': string;
    /**
     * 
     * @type {string}
     * @memberof PubSubPushRequestMessage
     */
    'message_id': string;
    /**
     * 
     * @type {string}
     * @memberof PubSubPushRequestMessage
     */
    'publishTime': string;
    /**
     * 
     * @type {string}
     * @memberof PubSubPushRequestMessage
     */
    'publish_time': string;
}
/**
 * 
 * @export
 * @interface PutInstallItemUsageAlternativeRequest
 */
export interface PutInstallItemUsageAlternativeRequest {
    /**
     * 
     * @type {string}
     * @memberof PutInstallItemUsageAlternativeRequest
     */
    'description'?: string;
    /**
     * 
     * @type {number}
     * @memberof PutInstallItemUsageAlternativeRequest
     */
    'questionId': number;
    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof PutInstallItemUsageAlternativeRequest
     */
    'cachedAt'?: InstallItemUsageAlternativeCreateInputCachedAt;
    /**
     * 
     * @type {boolean}
     * @memberof PutInstallItemUsageAlternativeRequest
     */
    'useSalesOrder'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PutInstallItemUsageAlternativeRequest
     */
    'quantityFactor'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PutInstallItemUsageAlternativeRequest
     */
    'flatAddition'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PutInstallItemUsageAlternativeRequest
     */
    'storageUnitConversion'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof PutInstallItemUsageAlternativeRequest
     */
    'itemNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PutInstallItemUsageAlternativeRequest
     */
    'questionName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PutInstallItemUsageAlternativeRequest
     */
    'answer'?: string | null;
}
/**
 * 
 * @export
 * @interface PutInstallItemUsageQuantityRequest
 */
export interface PutInstallItemUsageQuantityRequest {
    [key: string]: any;

    /**
     * 
     * @type {Array<InstallItemUsageWithAlternativesCreateInputQuantitiesInner>}
     * @memberof PutInstallItemUsageQuantityRequest
     */
    'quantities': Array<InstallItemUsageWithAlternativesCreateInputQuantitiesInner>;
}
/**
 * 
 * @export
 * @interface PutInstallItemUsageRequest
 */
export interface PutInstallItemUsageRequest {
    [key: string]: any;

    /**
     * 
     * @type {Array<PutInstallItemUsageRequestQuantitiesInner>}
     * @memberof PutInstallItemUsageRequest
     */
    'quantities': Array<PutInstallItemUsageRequestQuantitiesInner>;
    /**
     * 
     * @type {number}
     * @memberof PutInstallItemUsageRequest
     */
    'staticQuantity'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PutInstallItemUsageRequest
     */
    'discount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PutInstallItemUsageRequest
     */
    'flatAddition'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PutInstallItemUsageRequest
     */
    'storageUnitConversion'?: number | null;
}
/**
 * 
 * @export
 * @interface PutInstallItemUsageRequestQuantitiesInner
 */
export interface PutInstallItemUsageRequestQuantitiesInner {
    /**
     * 
     * @type {number}
     * @memberof PutInstallItemUsageRequestQuantitiesInner
     */
    'quantity': number;
    /**
     * 
     * @type {number}
     * @memberof PutInstallItemUsageRequestQuantitiesInner
     */
    'meter': number;
    /**
     * 
     * @type {number}
     * @memberof PutInstallItemUsageRequestQuantitiesInner
     */
    'id'?: number;
}
/**
 * 
 * @export
 * @interface PutMrpPickErrorRequest
 */
export interface PutMrpPickErrorRequest {
    /**
     * 
     * @type {number}
     * @memberof PutMrpPickErrorRequest
     */
    'deviationReasonId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PutMrpPickErrorRequest
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @interface PutMrpPickErrorResponse
 */
export interface PutMrpPickErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof PutMrpPickErrorResponse
     */
    'error'?: string;
    /**
     * 
     * @type {number}
     * @memberof PutMrpPickErrorResponse
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface PutMrpPickErrorsRequest
 */
export interface PutMrpPickErrorsRequest extends Array<PutMrpPickErrorsRequestInner> {
}
/**
 * 
 * @export
 * @interface PutMrpPickErrorsRequestInner
 */
export interface PutMrpPickErrorsRequestInner {
    /**
     * 
     * @type {number}
     * @memberof PutMrpPickErrorsRequestInner
     */
    'deviationReasonId'?: number;
    /**
     * 
     * @type {string}
     * @memberof PutMrpPickErrorsRequestInner
     */
    'comment'?: string;
    /**
     * 
     * @type {number}
     * @memberof PutMrpPickErrorsRequestInner
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface PutMrpPickErrorsResponse
 */
export interface PutMrpPickErrorsResponse extends Array<PutMrpPickErrorsResponseInner> {
}
/**
 * 
 * @export
 * @interface PutMrpPickErrorsResponseInner
 */
export interface PutMrpPickErrorsResponseInner {
    /**
     * 
     * @type {string}
     * @memberof PutMrpPickErrorsResponseInner
     */
    'error'?: string;
    /**
     * 
     * @type {number}
     * @memberof PutMrpPickErrorsResponseInner
     */
    'id': number;
}
/**
 * 
 * @export
 * @interface PutPostPickErrorDeviationReasonRequest
 */
export interface PutPostPickErrorDeviationReasonRequest {
    /**
     * 
     * @type {string}
     * @memberof PutPostPickErrorDeviationReasonRequest
     */
    'value': string;
}
/**
 * 
 * @export
 * @interface Question
 */
export interface Question {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof Question
     */
    '_seqno': number;
    /**
     * 
     * @type {string}
     * @memberof Question
     */
    '_checklist'?: string;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    '_checklistid'?: number;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    '_itemid'?: number;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    '_product'?: number;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    'parent_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    '_order_id'?: number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof Question
     */
    'properties': Array<AnswerPropertiesInner>;
    /**
     * 
     * @type {Array<QuestionAnswerInner>}
     * @memberof Question
     */
    'answer'?: Array<QuestionAnswerInner>;
    /**
     * 
     * @type {number}
     * @memberof Question
     */
    '_internal_use'?: number;
}
/**
 * 
 * @export
 * @interface QuestionAnswerInner
 */
export interface QuestionAnswerInner {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof QuestionAnswerInner
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof QuestionAnswerInner
     */
    'created': number;
    /**
     * 
     * @type {number}
     * @memberof QuestionAnswerInner
     */
    'parent_id': number;
    /**
     * 
     * @type {number}
     * @memberof QuestionAnswerInner
     */
    'item_id': number;
    /**
     * 
     * @type {number}
     * @memberof QuestionAnswerInner
     */
    'checklist_id': number;
    /**
     * 
     * @type {number}
     * @memberof QuestionAnswerInner
     */
    'seq_no': number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof QuestionAnswerInner
     */
    'properties': Array<AnswerPropertiesInner>;
}
/**
 * 
 * @export
 * @enum {number}
 */

export const QuestionPropertyType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_16: 16
} as const;

export type QuestionPropertyType = typeof QuestionPropertyType[keyof typeof QuestionPropertyType];


/**
 * 
 * @export
 * @enum {number}
 */

export const QuestionType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9,
    NUMBER_10: 10,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_16: 16,
    NUMBER_17: 17,
    NUMBER_18: 18,
    NUMBER_19: 19,
    NUMBER_20: 20,
    NUMBER_21: 21,
    NUMBER_22: 22,
    NUMBER_23: 23,
    NUMBER_24: 24,
    NUMBER_25: 25,
    NUMBER_26: 26,
    NUMBER_27: 27,
    NUMBER_28: 28,
    NUMBER_29: 29,
    NUMBER_30: 30,
    NUMBER_31: 31
} as const;

export type QuestionType = typeof QuestionType[keyof typeof QuestionType];


/**
 * 
 * @export
 * @interface QueueWithTasks
 */
export interface QueueWithTasks {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof QueueWithTasks
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof QueueWithTasks
     */
    'path': string;
    /**
     * 
     * @type {QueueWithTasksState}
     * @memberof QueueWithTasks
     */
    'state'?: QueueWithTasksState;
    /**
     * 
     * @type {QueueWithTasksRetryConfig}
     * @memberof QueueWithTasks
     */
    'retryConfig'?: QueueWithTasksRetryConfig;
    /**
     * 
     * @type {Array<QueueWithTasksTasksInner>}
     * @memberof QueueWithTasks
     */
    'tasks': Array<QueueWithTasksTasksInner>;
}
/**
 * 
 * @export
 * @interface QueueWithTasksResponse
 */
export interface QueueWithTasksResponse extends Array<QueueWithTasksResponseInner> {
}
/**
 * 
 * @export
 * @interface QueueWithTasksResponseInner
 */
export interface QueueWithTasksResponseInner {
    /**
     * 
     * @type {string}
     * @memberof QueueWithTasksResponseInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof QueueWithTasksResponseInner
     */
    'path': string;
    /**
     * 
     * @type {QueueWithTasksState}
     * @memberof QueueWithTasksResponseInner
     */
    'state'?: QueueWithTasksState;
    /**
     * 
     * @type {QueueWithTasksResponseInnerRetryConfig}
     * @memberof QueueWithTasksResponseInner
     */
    'retryConfig'?: QueueWithTasksResponseInnerRetryConfig;
    /**
     * 
     * @type {Array<QueueWithTasksResponseInnerTasksInner>}
     * @memberof QueueWithTasksResponseInner
     */
    'tasks': Array<QueueWithTasksResponseInnerTasksInner>;
}
/**
 * 
 * @export
 * @interface QueueWithTasksResponseInnerRetryConfig
 */
export interface QueueWithTasksResponseInnerRetryConfig {
    /**
     * 
     * @type {number}
     * @memberof QueueWithTasksResponseInnerRetryConfig
     */
    'maxAttempts'?: number;
    /**
     * 
     * @type {QueueWithTasksResponseInnerRetryConfigMaxRetryDuration}
     * @memberof QueueWithTasksResponseInnerRetryConfig
     */
    'maxRetryDuration'?: QueueWithTasksResponseInnerRetryConfigMaxRetryDuration;
    /**
     * 
     * @type {QueueWithTasksResponseInnerRetryConfigMaxRetryDuration}
     * @memberof QueueWithTasksResponseInnerRetryConfig
     */
    'minBackoff'?: QueueWithTasksResponseInnerRetryConfigMaxRetryDuration;
    /**
     * 
     * @type {QueueWithTasksResponseInnerRetryConfigMaxRetryDuration}
     * @memberof QueueWithTasksResponseInnerRetryConfig
     */
    'maxBackoff'?: QueueWithTasksResponseInnerRetryConfigMaxRetryDuration;
    /**
     * 
     * @type {number}
     * @memberof QueueWithTasksResponseInnerRetryConfig
     */
    'maxDoublings'?: number;
}
/**
 * 
 * @export
 * @interface QueueWithTasksResponseInnerRetryConfigMaxRetryDuration
 */
export interface QueueWithTasksResponseInnerRetryConfigMaxRetryDuration {
    /**
     * 
     * @type {number}
     * @memberof QueueWithTasksResponseInnerRetryConfigMaxRetryDuration
     */
    'seconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueueWithTasksResponseInnerRetryConfigMaxRetryDuration
     */
    'nanos'?: number;
}
/**
 * 
 * @export
 * @interface QueueWithTasksResponseInnerTasksInner
 */
export interface QueueWithTasksResponseInnerTasksInner {
    /**
     * 
     * @type {string}
     * @memberof QueueWithTasksResponseInnerTasksInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof QueueWithTasksResponseInnerTasksInner
     */
    'path': string;
    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof QueueWithTasksResponseInnerTasksInner
     */
    'createTime'?: InstallItemUsageAlternativeCreateInputCachedAt;
    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof QueueWithTasksResponseInnerTasksInner
     */
    'scheduleTime'?: InstallItemUsageAlternativeCreateInputCachedAt;
    /**
     * 
     * @type {number}
     * @memberof QueueWithTasksResponseInnerTasksInner
     */
    'dispatchCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueueWithTasksResponseInnerTasksInner
     */
    'responseCount'?: number;
    /**
     * 
     * @type {QueueWithTasksResponseInnerTasksInnerFirstAttempt}
     * @memberof QueueWithTasksResponseInnerTasksInner
     */
    'firstAttempt'?: QueueWithTasksResponseInnerTasksInnerFirstAttempt;
    /**
     * 
     * @type {QueueWithTasksResponseInnerTasksInnerFirstAttempt}
     * @memberof QueueWithTasksResponseInnerTasksInner
     */
    'lastAttempt'?: QueueWithTasksResponseInnerTasksInnerFirstAttempt;
    /**
     * 
     * @type {QueueWithTasksResponseInnerRetryConfigMaxRetryDuration}
     * @memberof QueueWithTasksResponseInnerTasksInner
     */
    'dispatchDeadline'?: QueueWithTasksResponseInnerRetryConfigMaxRetryDuration;
    /**
     * 
     * @type {QueueWithTasksResponseInnerTasksInnerHttpRequest}
     * @memberof QueueWithTasksResponseInnerTasksInner
     */
    'httpRequest'?: QueueWithTasksResponseInnerTasksInnerHttpRequest;
}
/**
 * 
 * @export
 * @interface QueueWithTasksResponseInnerTasksInnerFirstAttempt
 */
export interface QueueWithTasksResponseInnerTasksInnerFirstAttempt {
    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof QueueWithTasksResponseInnerTasksInnerFirstAttempt
     */
    'scheduleTime'?: InstallItemUsageAlternativeCreateInputCachedAt;
    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof QueueWithTasksResponseInnerTasksInnerFirstAttempt
     */
    'dispatchTime'?: InstallItemUsageAlternativeCreateInputCachedAt;
    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof QueueWithTasksResponseInnerTasksInnerFirstAttempt
     */
    'responseTime'?: InstallItemUsageAlternativeCreateInputCachedAt;
    /**
     * 
     * @type {QueueWithTasksResponseInnerTasksInnerFirstAttemptResponseStatus}
     * @memberof QueueWithTasksResponseInnerTasksInnerFirstAttempt
     */
    'responseStatus'?: QueueWithTasksResponseInnerTasksInnerFirstAttemptResponseStatus;
}
/**
 * 
 * @export
 * @interface QueueWithTasksResponseInnerTasksInnerFirstAttemptResponseStatus
 */
export interface QueueWithTasksResponseInnerTasksInnerFirstAttemptResponseStatus {
    /**
     * 
     * @type {number}
     * @memberof QueueWithTasksResponseInnerTasksInnerFirstAttemptResponseStatus
     */
    'code'?: number;
    /**
     * 
     * @type {string}
     * @memberof QueueWithTasksResponseInnerTasksInnerFirstAttemptResponseStatus
     */
    'message'?: string;
    /**
     * 
     * @type {Array<any>}
     * @memberof QueueWithTasksResponseInnerTasksInnerFirstAttemptResponseStatus
     */
    'details'?: Array<any>;
}
/**
 * 
 * @export
 * @interface QueueWithTasksResponseInnerTasksInnerHttpRequest
 */
export interface QueueWithTasksResponseInnerTasksInnerHttpRequest {
    /**
     * 
     * @type {string}
     * @memberof QueueWithTasksResponseInnerTasksInnerHttpRequest
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueWithTasksResponseInnerTasksInnerHttpRequest
     */
    'body'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueWithTasksResponseInnerTasksInnerHttpRequest
     */
    'httpMethod'?: QueueWithTasksResponseInnerTasksInnerHttpRequestHttpMethodEnum;
    /**
     * 
     * @type {object}
     * @memberof QueueWithTasksResponseInnerTasksInnerHttpRequest
     */
    'headers'?: object;
}

export const QueueWithTasksResponseInnerTasksInnerHttpRequestHttpMethodEnum = {
    Delete: 'DELETE',
    Get: 'GET',
    Head: 'HEAD',
    HttpMethodUnspecified: 'HTTP_METHOD_UNSPECIFIED',
    Options: 'OPTIONS',
    Patch: 'PATCH',
    Post: 'POST',
    Put: 'PUT'
} as const;

export type QueueWithTasksResponseInnerTasksInnerHttpRequestHttpMethodEnum = typeof QueueWithTasksResponseInnerTasksInnerHttpRequestHttpMethodEnum[keyof typeof QueueWithTasksResponseInnerTasksInnerHttpRequestHttpMethodEnum];

/**
 * 
 * @export
 * @interface QueueWithTasksRetryConfig
 */
export interface QueueWithTasksRetryConfig {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof QueueWithTasksRetryConfig
     */
    'maxAttempts'?: number;
    /**
     * 
     * @type {QueueWithTasksRetryConfigMaxRetryDuration}
     * @memberof QueueWithTasksRetryConfig
     */
    'maxRetryDuration'?: QueueWithTasksRetryConfigMaxRetryDuration;
    /**
     * 
     * @type {QueueWithTasksRetryConfigMaxRetryDuration}
     * @memberof QueueWithTasksRetryConfig
     */
    'minBackoff'?: QueueWithTasksRetryConfigMaxRetryDuration;
    /**
     * 
     * @type {QueueWithTasksRetryConfigMaxRetryDuration}
     * @memberof QueueWithTasksRetryConfig
     */
    'maxBackoff'?: QueueWithTasksRetryConfigMaxRetryDuration;
    /**
     * 
     * @type {number}
     * @memberof QueueWithTasksRetryConfig
     */
    'maxDoublings'?: number;
}
/**
 * 
 * @export
 * @interface QueueWithTasksRetryConfigMaxRetryDuration
 */
export interface QueueWithTasksRetryConfigMaxRetryDuration {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof QueueWithTasksRetryConfigMaxRetryDuration
     */
    'seconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueueWithTasksRetryConfigMaxRetryDuration
     */
    'nanos'?: number;
}
/**
 * @type QueueWithTasksState
 * @export
 */
export type QueueWithTasksState = number | string;

/**
 * 
 * @export
 * @interface QueueWithTasksTasksInner
 */
export interface QueueWithTasksTasksInner {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof QueueWithTasksTasksInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof QueueWithTasksTasksInner
     */
    'path': string;
    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof QueueWithTasksTasksInner
     */
    'createTime'?: InstallItemUsageAlternativeCreateInputCachedAt;
    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof QueueWithTasksTasksInner
     */
    'scheduleTime'?: InstallItemUsageAlternativeCreateInputCachedAt;
    /**
     * 
     * @type {number}
     * @memberof QueueWithTasksTasksInner
     */
    'dispatchCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof QueueWithTasksTasksInner
     */
    'responseCount'?: number;
    /**
     * 
     * @type {QueueWithTasksTasksInnerFirstAttempt}
     * @memberof QueueWithTasksTasksInner
     */
    'firstAttempt'?: QueueWithTasksTasksInnerFirstAttempt;
    /**
     * 
     * @type {QueueWithTasksTasksInnerFirstAttempt}
     * @memberof QueueWithTasksTasksInner
     */
    'lastAttempt'?: QueueWithTasksTasksInnerFirstAttempt;
    /**
     * 
     * @type {QueueWithTasksRetryConfigMaxRetryDuration}
     * @memberof QueueWithTasksTasksInner
     */
    'dispatchDeadline'?: QueueWithTasksRetryConfigMaxRetryDuration;
    /**
     * 
     * @type {QueueWithTasksTasksInnerHttpRequest}
     * @memberof QueueWithTasksTasksInner
     */
    'httpRequest'?: QueueWithTasksTasksInnerHttpRequest;
}
/**
 * 
 * @export
 * @interface QueueWithTasksTasksInnerFirstAttempt
 */
export interface QueueWithTasksTasksInnerFirstAttempt {
    [key: string]: any;

    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof QueueWithTasksTasksInnerFirstAttempt
     */
    'scheduleTime'?: InstallItemUsageAlternativeCreateInputCachedAt;
    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof QueueWithTasksTasksInnerFirstAttempt
     */
    'dispatchTime'?: InstallItemUsageAlternativeCreateInputCachedAt;
    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof QueueWithTasksTasksInnerFirstAttempt
     */
    'responseTime'?: InstallItemUsageAlternativeCreateInputCachedAt;
    /**
     * 
     * @type {AttemptResponseStatus}
     * @memberof QueueWithTasksTasksInnerFirstAttempt
     */
    'responseStatus'?: AttemptResponseStatus;
}
/**
 * 
 * @export
 * @interface QueueWithTasksTasksInnerHttpRequest
 */
export interface QueueWithTasksTasksInnerHttpRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof QueueWithTasksTasksInnerHttpRequest
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueWithTasksTasksInnerHttpRequest
     */
    'body'?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueWithTasksTasksInnerHttpRequest
     */
    'httpMethod'?: QueueWithTasksTasksInnerHttpRequestHttpMethodEnum;
    /**
     * 
     * @type {object}
     * @memberof QueueWithTasksTasksInnerHttpRequest
     */
    'headers'?: object;
}

export const QueueWithTasksTasksInnerHttpRequestHttpMethodEnum = {
    Delete: 'DELETE',
    Get: 'GET',
    Head: 'HEAD',
    HttpMethodUnspecified: 'HTTP_METHOD_UNSPECIFIED',
    Options: 'OPTIONS',
    Patch: 'PATCH',
    Post: 'POST',
    Put: 'PUT'
} as const;

export type QueueWithTasksTasksInnerHttpRequestHttpMethodEnum = typeof QueueWithTasksTasksInnerHttpRequestHttpMethodEnum[keyof typeof QueueWithTasksTasksInnerHttpRequestHttpMethodEnum];

/**
 * 
 * @export
 * @interface Quote
 */
export interface Quote {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof Quote
     */
    'QuoteID': number;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    'RFQDate': string;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    'Currency': string;
    /**
     * 
     * @type {number}
     * @memberof Quote
     */
    'SubTotal': number;
    /**
     * 
     * @type {number}
     * @memberof Quote
     */
    'Shipping': number;
    /**
     * 
     * @type {number}
     * @memberof Quote
     */
    'SalesTax': number;
    /**
     * 
     * @type {number}
     * @memberof Quote
     */
    'Total': number;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    'Term': string;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    'DeliveryMethod': string;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    'ValidityDate': string;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    'InsertionDateTime': string;
    /**
     * 
     * @type {number}
     * @memberof Quote
     */
    'ProjectID': number;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    'SoldToCompanyName': string;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    'SoldToContact': string;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    'SoldToAddress1': string;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    'SoldToAddress2': string;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    'SoldToCity': string;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    'SoldToState': string;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    'SoldToPostalCode': string;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    'ShipToCompanyName': string;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    'ShipToContact': string;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    'ShipToAddress1': string;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    'ShipToAddress2': string;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    'ShipToCity': string;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    'ShipToState': string;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    'ShipToPostalCode': string;
    /**
     * 
     * @type {number}
     * @memberof Quote
     */
    'StatusID': number;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    'SalesRep': string;
    /**
     * 
     * @type {string}
     * @memberof Quote
     */
    'Notes': string;
    /**
     * 
     * @type {Array<CreateQuoteRequestLineItemsInner>}
     * @memberof Quote
     */
    'LineItems': Array<CreateQuoteRequestLineItemsInner>;
    /**
     * 
     * @type {Array<ItemListResponseItemAdditionalDataValuesInner>}
     * @memberof Quote
     */
    'AdditionalDataValues': Array<ItemListResponseItemAdditionalDataValuesInner>;
}
/**
 * 
 * @export
 * @enum {number}
 */

export const QuoteLineStatus = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type QuoteLineStatus = typeof QuoteLineStatus[keyof typeof QuoteLineStatus];


/**
 * 
 * @export
 * @interface QuoteListRequest
 */
export interface QuoteListRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof QuoteListRequest
     */
    'QuoteNumber'?: string;
}
/**
 * 
 * @export
 * @interface QuoteListResponse
 */
export interface QuoteListResponse extends Array<QuoteListResponseInner> {
}
/**
 * 
 * @export
 * @interface QuoteListResponseInner
 */
export interface QuoteListResponseInner {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof QuoteListResponseInner
     */
    'QuoteID': number;
    /**
     * 
     * @type {string}
     * @memberof QuoteListResponseInner
     */
    'RFQDate': string;
    /**
     * 
     * @type {string}
     * @memberof QuoteListResponseInner
     */
    'Currency': string;
    /**
     * 
     * @type {number}
     * @memberof QuoteListResponseInner
     */
    'SubTotal': number;
    /**
     * 
     * @type {number}
     * @memberof QuoteListResponseInner
     */
    'Shipping': number;
    /**
     * 
     * @type {number}
     * @memberof QuoteListResponseInner
     */
    'SalesTax': number;
    /**
     * 
     * @type {number}
     * @memberof QuoteListResponseInner
     */
    'Total': number;
    /**
     * 
     * @type {string}
     * @memberof QuoteListResponseInner
     */
    'Term': string;
    /**
     * 
     * @type {string}
     * @memberof QuoteListResponseInner
     */
    'DeliveryMethod': string;
    /**
     * 
     * @type {string}
     * @memberof QuoteListResponseInner
     */
    'ValidityDate': string;
    /**
     * 
     * @type {string}
     * @memberof QuoteListResponseInner
     */
    'InsertionDateTime': string;
    /**
     * 
     * @type {number}
     * @memberof QuoteListResponseInner
     */
    'ProjectID': number;
    /**
     * 
     * @type {string}
     * @memberof QuoteListResponseInner
     */
    'SoldToCompanyName': string;
    /**
     * 
     * @type {string}
     * @memberof QuoteListResponseInner
     */
    'SoldToContact': string;
    /**
     * 
     * @type {string}
     * @memberof QuoteListResponseInner
     */
    'SoldToAddress1': string;
    /**
     * 
     * @type {string}
     * @memberof QuoteListResponseInner
     */
    'SoldToAddress2': string;
    /**
     * 
     * @type {string}
     * @memberof QuoteListResponseInner
     */
    'SoldToCity': string;
    /**
     * 
     * @type {string}
     * @memberof QuoteListResponseInner
     */
    'SoldToState': string;
    /**
     * 
     * @type {string}
     * @memberof QuoteListResponseInner
     */
    'SoldToPostalCode': string;
    /**
     * 
     * @type {string}
     * @memberof QuoteListResponseInner
     */
    'ShipToCompanyName': string;
    /**
     * 
     * @type {string}
     * @memberof QuoteListResponseInner
     */
    'ShipToContact': string;
    /**
     * 
     * @type {string}
     * @memberof QuoteListResponseInner
     */
    'ShipToAddress1': string;
    /**
     * 
     * @type {string}
     * @memberof QuoteListResponseInner
     */
    'ShipToAddress2': string;
    /**
     * 
     * @type {string}
     * @memberof QuoteListResponseInner
     */
    'ShipToCity': string;
    /**
     * 
     * @type {string}
     * @memberof QuoteListResponseInner
     */
    'ShipToState': string;
    /**
     * 
     * @type {string}
     * @memberof QuoteListResponseInner
     */
    'ShipToPostalCode': string;
    /**
     * 
     * @type {number}
     * @memberof QuoteListResponseInner
     */
    'StatusID': number;
    /**
     * 
     * @type {string}
     * @memberof QuoteListResponseInner
     */
    'SalesRep': string;
    /**
     * 
     * @type {string}
     * @memberof QuoteListResponseInner
     */
    'Notes': string;
    /**
     * 
     * @type {Array<CreateQuoteRequestLineItemsInner>}
     * @memberof QuoteListResponseInner
     */
    'LineItems': Array<CreateQuoteRequestLineItemsInner>;
    /**
     * 
     * @type {Array<ItemListResponseItemAdditionalDataValuesInner>}
     * @memberof QuoteListResponseInner
     */
    'AdditionalDataValues': Array<ItemListResponseItemAdditionalDataValuesInner>;
}
/**
 * 
 * @export
 * @interface QuotesLineItem
 */
export interface QuotesLineItem {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof QuotesLineItem
     */
    'LineItemID': number;
    /**
     * 
     * @type {number}
     * @memberof QuotesLineItem
     */
    'Quantity': number;
    /**
     * 
     * @type {string}
     * @memberof QuotesLineItem
     */
    'ItemNumber': string;
    /**
     * 
     * @type {string}
     * @memberof QuotesLineItem
     */
    'Revision': string;
    /**
     * 
     * @type {Array<CreateQuoteRequestLineItemsInnerConfiguratorDataInner>}
     * @memberof QuotesLineItem
     */
    'ConfiguratorData': Array<CreateQuoteRequestLineItemsInnerConfiguratorDataInner>;
}
/**
 * 
 * @export
 * @interface SalesOrderLineAddRequest
 */
export interface SalesOrderLineAddRequest {
    [key: string]: any;

    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof SalesOrderLineAddRequest
     */
    'LineItemID': ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof SalesOrderLineAddRequest
     */
    'SalesOrderID': ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof SalesOrderLineAddRequest
     */
    'Quantity': ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderLineAddRequest
     */
    'PromiseDate': string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderLineAddRequest
     */
    'RequestedDate': string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderLineAddRequest
     */
    'ItemNumber': string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderLineAddRequest
     */
    'CustomerSupplierItemNumber': string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderLineAddRequest
     */
    'Notes': string;
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof SalesOrderLineAddRequest
     */
    'UnitPrice': ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof SalesOrderLineAddRequest
     */
    'DiscountPercent'?: ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderLineAddRequest
     */
    'AdditionalDataValues': string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderLineAddRequest
     */
    'TaxCode': string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderLineAddRequest
     */
    'Warehouse': string;
}
/**
 * 
 * @export
 * @interface SalesOrderLineAddResponse
 */
export interface SalesOrderLineAddResponse {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof SalesOrderLineAddResponse
     */
    'LineItemID': string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderLineAddResponse
     */
    'SalesOrderID': string;
}
/**
 * 
 * @export
 * @interface SalesOrderLineDeleteRequest
 */
export interface SalesOrderLineDeleteRequest {
    [key: string]: any;

    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof SalesOrderLineDeleteRequest
     */
    'LineItemID': ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof SalesOrderLineDeleteRequest
     */
    'SalesOrderID': ItemListResponseItemAdditionalDataValuesInnerID;
}
/**
 * 
 * @export
 * @interface SalesOrderLineDeleteResponse
 */
export interface SalesOrderLineDeleteResponse {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof SalesOrderLineDeleteResponse
     */
    'LineItemID': string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderLineDeleteResponse
     */
    'SalesOrderID': string;
}
/**
 * 
 * @export
 * @interface SalesOrderLineUpdateRequest
 */
export interface SalesOrderLineUpdateRequest {
    [key: string]: any;

    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof SalesOrderLineUpdateRequest
     */
    'LineItemID': ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof SalesOrderLineUpdateRequest
     */
    'SalesOrderID': ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof SalesOrderLineUpdateRequest
     */
    'Quantity': ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderLineUpdateRequest
     */
    'PromiseDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderLineUpdateRequest
     */
    'RequestedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderLineUpdateRequest
     */
    'Warehouse'?: string;
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof SalesOrderLineUpdateRequest
     */
    'UnitPrice'?: ItemListResponseItemAdditionalDataValuesInnerID;
    /**
     * 
     * @type {ItemListResponseItemAdditionalDataValuesInnerID}
     * @memberof SalesOrderLineUpdateRequest
     */
    'DiscountPercent'?: ItemListResponseItemAdditionalDataValuesInnerID;
}
/**
 * 
 * @export
 * @interface SalesOrderLineUpdateResponse
 */
export interface SalesOrderLineUpdateResponse {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof SalesOrderLineUpdateResponse
     */
    'SalesOrderID': number;
    /**
     * 
     * @type {string}
     * @memberof SalesOrderLineUpdateResponse
     */
    'LineItemID': string;
}
/**
 * SaveInvoices
 * @export
 * @interface SaveInvoices
 */
export interface SaveInvoices {
    [key: string]: any;

    /**
     * 
     * @type {SaveInvoicesInvoices}
     * @memberof SaveInvoices
     */
    'invoices'?: SaveInvoicesInvoices;
}
/**
 * invoices
 * @export
 * @interface SaveInvoicesInvoices
 */
export interface SaveInvoicesInvoices {
    [key: string]: any;

    /**
     * InvoiceOrder[]
     * @type {Array<GetInvoicesResponseGetInvoicesResultInvoiceOrderInner>}
     * @memberof SaveInvoicesInvoices
     */
    'InvoiceOrder'?: Array<GetInvoicesResponseGetInvoicesResultInvoiceOrderInner>;
}
/**
 * SaveInvoicesResponse
 * @export
 * @interface SaveInvoicesResponse
 */
export interface SaveInvoicesResponse {
    [key: string]: any;

    /**
     * 
     * @type {SaveInvoicesResponseSaveInvoicesResult}
     * @memberof SaveInvoicesResponse
     */
    'SaveInvoicesResult'?: SaveInvoicesResponseSaveInvoicesResult;
}
/**
 * SaveInvoicesResult
 * @export
 * @interface SaveInvoicesResponseSaveInvoicesResult
 */
export interface SaveInvoicesResponseSaveInvoicesResult {
    [key: string]: any;

    /**
     * InvoiceOrder[]
     * @type {Array<GetInvoicesResponseGetInvoicesResultInvoiceOrderInner>}
     * @memberof SaveInvoicesResponseSaveInvoicesResult
     */
    'InvoiceOrder'?: Array<GetInvoicesResponseGetInvoicesResultInvoiceOrderInner>;
}
/**
 * searchParams
 * @export
 * @interface SearchParams
 */
export interface SearchParams {
    [key: string]: any;

    /**
     * 
     * @type {SearchParamsCustomerIds}
     * @memberof SearchParams
     */
    'CustomerIds'?: SearchParamsCustomerIds;
    /**
     * 
     * @type {GetInvoicesSearchParamsChangedAfter}
     * @memberof SearchParams
     */
    'ChangedAfter'?: GetInvoicesSearchParamsChangedAfter;
    /**
     * 
     * @type {GetInvoicesSearchParamsOrderStates}
     * @memberof SearchParams
     */
    'OrderStates'?: GetInvoicesSearchParamsOrderStates;
    /**
     * 
     * @type {GetInvoicesSearchParamsInvoiceIds}
     * @memberof SearchParams
     */
    'InvoiceIds'?: GetInvoicesSearchParamsInvoiceIds;
    /**
     * 
     * @type {GetInvoicesSearchParamsOrderIds}
     * @memberof SearchParams
     */
    'OrderIds'?: GetInvoicesSearchParamsOrderIds;
    /**
     * 
     * @type {GetInvoicesSearchParamsExternalStatus}
     * @memberof SearchParams
     */
    'ExternalStatus'?: GetInvoicesSearchParamsExternalStatus;
}
/**
 * CustomerIds
 * @export
 * @interface SearchParamsCustomerIds
 */
export interface SearchParamsCustomerIds {
    [key: string]: any;

    /**
     * s:int
     * @type {Array<number>}
     * @memberof SearchParamsCustomerIds
     */
    'int'?: Array<number>;
}
/**
 * 
 * @export
 * @interface SendSMSAPIRequest
 */
export interface SendSMSAPIRequest {
    [key: string]: any;

    /**
     * This is ignored using `reply: true`, and `to` is an array of `{ phone: string, customerId: number }`
     * @type {number}
     * @memberof SendSMSAPIRequest
     */
    'customerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SendSMSAPIRequest
     */
    'type'?: string;
    /**
     * 
     * @type {SendSMSAPIRequestTo}
     * @memberof SendSMSAPIRequest
     */
    'to': SendSMSAPIRequestTo;
    /**
     * 
     * @type {string}
     * @memberof SendSMSAPIRequest
     */
    'from': SendSMSAPIRequestFromEnum;
    /**
     * 
     * @type {string}
     * @memberof SendSMSAPIRequest
     */
    'deleteDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SendSMSAPIRequest
     */
    'retry'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SendSMSAPIRequest
     */
    'reply'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SendSMSAPIRequest
     */
    'msg': string;
    /**
     * 
     * @type {number}
     * @memberof SendSMSAPIRequest
     */
    'cpproOrderId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SendSMSAPIRequest
     */
    'ignoreDeleted'?: boolean;
    /**
     * 
     * @type {SendSMSAPIRequestEnableLock}
     * @memberof SendSMSAPIRequest
     */
    'enableLock'?: SendSMSAPIRequestEnableLock;
    /**
     * 
     * @type {boolean}
     * @memberof SendSMSAPIRequest
     */
    'insertCustomerLog'?: boolean;
}

export const SendSMSAPIRequestFromEnum = {
    ACsenteret: 'ACsenteret',
    FjKlima: 'FJ Klima'
} as const;

export type SendSMSAPIRequestFromEnum = typeof SendSMSAPIRequestFromEnum[keyof typeof SendSMSAPIRequestFromEnum];

/**
 * 
 * @export
 * @interface SendSMSAPIRequestEnableLock
 */
export interface SendSMSAPIRequestEnableLock {
    /**
     * >= 0
     * @type {number}
     * @memberof SendSMSAPIRequestEnableLock
     */
    'hours': number;
}
/**
 * @type SendSMSAPIRequestTo
 * @export
 */
export type SendSMSAPIRequestTo = Array<SendSMSAPIRequestToOneOfInner> | Array<string> | string;

/**
 * 
 * @export
 * @interface SendSMSAPIRequestToOneOfInner
 */
export interface SendSMSAPIRequestToOneOfInner {
    /**
     * 
     * @type {string}
     * @memberof SendSMSAPIRequestToOneOfInner
     */
    'phone': string;
    /**
     * 
     * @type {number}
     * @memberof SendSMSAPIRequestToOneOfInner
     */
    'customerId': number;
}
/**
 * 
 * @export
 * @interface SendSMSRequest
 */
export interface SendSMSRequest {
    [key: string]: any;

    /**
     * This is ignored using `reply: true`, and `to` is an array of `{ phone: string, customerId: number }`
     * @type {number}
     * @memberof SendSMSRequest
     */
    'customerId'?: number;
    /**
     * 
     * @type {string}
     * @memberof SendSMSRequest
     */
    'type'?: string;
    /**
     * 
     * @type {SendSMSRequestTo}
     * @memberof SendSMSRequest
     */
    'to': SendSMSRequestTo;
    /**
     * 
     * @type {string}
     * @memberof SendSMSRequest
     */
    'from': SendSMSRequestFromEnum;
    /**
     * 
     * @type {string}
     * @memberof SendSMSRequest
     */
    'deleteDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SendSMSRequest
     */
    'retry'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SendSMSRequest
     */
    'reply'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SendSMSRequest
     */
    'msg': string;
    /**
     * 
     * @type {number}
     * @memberof SendSMSRequest
     */
    'cpproOrderId'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SendSMSRequest
     */
    'ignoreDeleted'?: boolean;
}

export const SendSMSRequestFromEnum = {
    ACsenteret: 'ACsenteret',
    FjKlima: 'FJ Klima'
} as const;

export type SendSMSRequestFromEnum = typeof SendSMSRequestFromEnum[keyof typeof SendSMSRequestFromEnum];

/**
 * @type SendSMSRequestTo
 * @export
 */
export type SendSMSRequestTo = Array<SendSMSRequestToOneOfInner> | Array<string> | string;

/**
 * 
 * @export
 * @interface SendSMSRequestToOneOfInner
 */
export interface SendSMSRequestToOneOfInner {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof SendSMSRequestToOneOfInner
     */
    'phone': string;
    /**
     * 
     * @type {number}
     * @memberof SendSMSRequestToOneOfInner
     */
    'customerId': number;
}
/**
 * 
 * @export
 * @interface SendSmsOptions
 */
export interface SendSmsOptions {
    [key: string]: any;

    /**
     * 
     * @type {SendSmsOptionsTo}
     * @memberof SendSmsOptions
     */
    'to': SendSmsOptionsTo;
    /**
     * 
     * @type {string}
     * @memberof SendSmsOptions
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof SendSmsOptions
     */
    'from': SendSmsOptionsFromEnum;
    /**
     * 
     * @type {boolean}
     * @memberof SendSmsOptions
     */
    'test'?: boolean;
}

export const SendSmsOptionsFromEnum = {
    ACsenteret: 'ACsenteret',
    FjKlima: 'FJ Klima'
} as const;

export type SendSmsOptionsFromEnum = typeof SendSmsOptionsFromEnum[keyof typeof SendSmsOptionsFromEnum];

/**
 * @type SendSmsOptionsTo
 * @export
 */
export type SendSmsOptionsTo = Array<string> | string;

/**
 * 
 * @export
 * @interface Service
 */
export interface Service {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof Service
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof Service
     */
    'parent_id': number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof Service
     */
    'properties': Array<AnswerPropertiesInner>;
}
/**
 * 
 * @export
 * @enum {number}
 */

export const ServicePropertyType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8
} as const;

export type ServicePropertyType = typeof ServicePropertyType[keyof typeof ServicePropertyType];


/**
 * 
 * @export
 * @enum {string}
 */

export const SmsUser = {
    ACsenteret: 'ACsenteret',
    FjKlima: 'FJ Klima'
} as const;

export type SmsUser = typeof SmsUser[keyof typeof SmsUser];


/**
 * 
 * @export
 * @interface SupplierListRequest
 */
export interface SupplierListRequest {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof SupplierListRequest
     */
    'SupplierName'?: string;
}
/**
 * 
 * @export
 * @interface SupplierListResponse
 */
export interface SupplierListResponse extends Array<CustomerListResponseInner> {
}
/**
 * 
 * @export
 * @interface SupplierListResponseItem
 */
export interface SupplierListResponseItem {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof SupplierListResponseItem
     */
    'CompanyID': number;
    /**
     * 
     * @type {string}
     * @memberof SupplierListResponseItem
     */
    'AccountNumber': string;
    /**
     * 
     * @type {string}
     * @memberof SupplierListResponseItem
     */
    'CompanyName': string;
    /**
     * 
     * @type {string}
     * @memberof SupplierListResponseItem
     */
    'Address': string;
    /**
     * 
     * @type {string}
     * @memberof SupplierListResponseItem
     */
    'City': string;
    /**
     * 
     * @type {string}
     * @memberof SupplierListResponseItem
     */
    'State': string;
    /**
     * 
     * @type {string}
     * @memberof SupplierListResponseItem
     */
    'PostalCode': string;
    /**
     * 
     * @type {string}
     * @memberof SupplierListResponseItem
     */
    'Country': string;
    /**
     * 
     * @type {number}
     * @memberof SupplierListResponseItem
     */
    'CompanyType': number;
    /**
     * 
     * @type {number}
     * @memberof SupplierListResponseItem
     */
    'StatusID': number;
}
/**
 * 
 * @export
 * @interface SveveBody
 */
export interface SveveBody {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof SveveBody
     */
    'user': string;
    /**
     * 
     * @type {string}
     * @memberof SveveBody
     */
    'passwd': string;
    /**
     * 
     * @type {SveveBodyTo}
     * @memberof SveveBody
     */
    'to': SveveBodyTo;
    /**
     * 
     * @type {string}
     * @memberof SveveBody
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof SveveBody
     */
    'from'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SveveBody
     */
    'reply'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SveveBody
     */
    'reply_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof SveveBody
     */
    'date_time'?: string;
    /**
     * 
     * @type {string}
     * @memberof SveveBody
     */
    'reoccurrence'?: string;
    /**
     * 
     * @type {string}
     * @memberof SveveBody
     */
    'reoccurrence_ends'?: string;
    /**
     * 
     * @type {string}
     * @memberof SveveBody
     */
    'ends_after'?: string;
    /**
     * 
     * @type {string}
     * @memberof SveveBody
     */
    'ends_on'?: string;
    /**
     * 
     * @type {string}
     * @memberof SveveBody
     */
    'ref'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SveveBody
     */
    'test'?: boolean;
}
/**
 * @type SveveBodyTo
 * @export
 */
export type SveveBodyTo = Array<string> | string;

/**
 * 
 * @export
 * @interface SveveResponseData
 */
export interface SveveResponseData {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof SveveResponseData
     */
    'msgOkCount': number;
    /**
     * 
     * @type {number}
     * @memberof SveveResponseData
     */
    'stdSMSCount': number;
    /**
     * 
     * @type {Array<number>}
     * @memberof SveveResponseData
     */
    'ids': Array<number>;
    /**
     * 
     * @type {string}
     * @memberof SveveResponseData
     */
    'fatalError'?: string;
    /**
     * 
     * @type {Array<SveveResponseDataErrorsInner>}
     * @memberof SveveResponseData
     */
    'errors'?: Array<SveveResponseDataErrorsInner>;
    /**
     * 
     * @type {boolean}
     * @memberof SveveResponseData
     */
    'test'?: boolean;
}
/**
 * 
 * @export
 * @interface SveveResponseDataErrorsInner
 */
export interface SveveResponseDataErrorsInner {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof SveveResponseDataErrorsInner
     */
    'number': string;
    /**
     * 
     * @type {string}
     * @memberof SveveResponseDataErrorsInner
     */
    'message': string;
}
/**
 * syncParams
 * @export
 * @interface SyncParams
 */
export interface SyncParams {
    [key: string]: any;

    /**
     * s:dateTime
     * @type {string}
     * @memberof SyncParams
     */
    'ChangedAfter'?: string;
    /**
     * s:dateTime
     * @type {string}
     * @memberof SyncParams
     */
    'RegisteredAfter'?: string;
}
/**
 * 
 * @export
 * @interface SyncSalesOrders24SOResponse
 */
export interface SyncSalesOrders24SOResponse {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof SyncSalesOrders24SOResponse
     */
    'SO Sync for company code': string;
    /**
     * 
     * @type {string}
     * @memberof SyncSalesOrders24SOResponse
     */
    '2SO Sync for company code': string;
}
/**
 * 
 * @export
 * @interface TaskPathCommand
 */
export interface TaskPathCommand {
    /**
     * 
     * @type {string}
     * @memberof TaskPathCommand
     */
    'taskPath': string;
}
/**
 * TaxSettings
 * @export
 * @interface TaxSettings
 */
export interface TaxSettings {
    [key: string]: any;

    /**
     * s:int
     * @type {number}
     * @memberof TaxSettings
     */
    'TaxAccount'?: number;
    /**
     * s:int
     * @type {number}
     * @memberof TaxSettings
     */
    'TaxCode'?: number;
    /**
     * s:decimal
     * @type {number}
     * @memberof TaxSettings
     */
    'TaxRate'?: number;
}
/**
 * TemplateModel
 * @export
 * @interface TemplateModel
 */
export interface TemplateModel {
    [key: string]: any;

    /**
     * s:string
     * @type {string}
     * @memberof TemplateModel
     */
    'FileName'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof TemplateModel
     */
    'Name'?: string;
    /**
     * guid|s:string|pattern
     * @type {string}
     * @memberof TemplateModel
     */
    'Id'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof TemplateModel
     */
    'Language'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof TemplateModel
     */
    'Country'?: string;
    /**
     * s:boolean
     * @type {boolean}
     * @memberof TemplateModel
     */
    'IsSystemTemplate'?: boolean;
    /**
     * s:boolean
     * @type {boolean}
     * @memberof TemplateModel
     */
    'IsDefault'?: boolean;
    /**
     * s:dateTime
     * @type {string}
     * @memberof TemplateModel
     */
    'Modified'?: string;
    /**
     * 
     * @type {GetInvoiceTemplatesResponseGetInvoiceTemplatesResultTemplateModelInnerCustomers}
     * @memberof TemplateModel
     */
    'Customers'?: GetInvoiceTemplatesResponseGetInvoiceTemplatesResultTemplateModelInnerCustomers;
}
/**
 * 
 * @export
 * @interface UpdateAlertPolicyCommand
 */
export interface UpdateAlertPolicyCommand {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof UpdateAlertPolicyCommand
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAlertPolicyCommand
     */
    'documentation': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAlertPolicyCommand
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateAlertPolicyCommand
     */
    'created': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAlertPolicyCommand
     */
    'displayName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAlertPolicyCommand
     */
    'modified': string;
    /**
     * 
     * @type {AlertPoliciesResponseInnerConditions}
     * @memberof UpdateAlertPolicyCommand
     */
    'conditions': AlertPoliciesResponseInnerConditions;
    /**
     * 
     * @type {string}
     * @memberof UpdateAlertPolicyCommand
     */
    'validity': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAlertPolicyCommand
     */
    'combiner': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAlertPolicyCommand
     */
    'severity': string;
    /**
     * 
     * @type {AlertPoliciesResponseInnerConditions}
     * @memberof UpdateAlertPolicyCommand
     */
    'notificationChannels': AlertPoliciesResponseInnerConditions;
    /**
     * 
     * @type {string}
     * @memberof UpdateAlertPolicyCommand
     */
    'notificationRateLimit': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAlertPolicyCommand
     */
    'autoClose': string;
}
/**
 * 
 * @export
 * @interface UpdateAlertPolicyIncidentCommand
 */
export interface UpdateAlertPolicyIncidentCommand {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof UpdateAlertPolicyIncidentCommand
     */
    'summary': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAlertPolicyIncidentCommand
     */
    'date': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAlertPolicyIncidentCommand
     */
    'documentation': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAlertPolicyIncidentCommand
     */
    'status': UpdateAlertPolicyIncidentCommandStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateAlertPolicyIncidentCommand
     */
    'severity': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAlertPolicyIncidentCommand
     */
    'alertPolicyId': number;
    /**
     * 
     * @type {string}
     * @memberof UpdateAlertPolicyIncidentCommand
     */
    'incidentId': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAlertPolicyIncidentCommand
     */
    'incidentUrl': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAlertPolicyIncidentCommand
     */
    'resourceName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAlertPolicyIncidentCommand
     */
    'serviceName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAlertPolicyIncidentCommand
     */
    'revisionName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAlertPolicyIncidentCommand
     */
    'conditionName': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAlertPolicyIncidentCommand
     */
    'logFilter': string;
    /**
     * 
     * @type {AlertPoliciesResponseInnerConditions}
     * @memberof UpdateAlertPolicyIncidentCommand
     */
    'logs': AlertPoliciesResponseInnerConditions;
    /**
     * 
     * @type {string}
     * @memberof UpdateAlertPolicyIncidentCommand
     */
    'notified': string;
}

export const UpdateAlertPolicyIncidentCommandStatusEnum = {
    Acknowledged: 'ACKNOWLEDGED',
    Muted: 'MUTED',
    Open: 'OPEN',
    Resolved: 'RESOLVED'
} as const;

export type UpdateAlertPolicyIncidentCommandStatusEnum = typeof UpdateAlertPolicyIncidentCommandStatusEnum[keyof typeof UpdateAlertPolicyIncidentCommandStatusEnum];

/**
 * UpdateInvoices
 * @export
 * @interface UpdateInvoices
 */
export interface UpdateInvoices {
    [key: string]: any;

    /**
     * 
     * @type {SaveInvoicesInvoices}
     * @memberof UpdateInvoices
     */
    'invoices'?: SaveInvoicesInvoices;
}
/**
 * UpdateInvoicesResponse
 * @export
 * @interface UpdateInvoicesResponse
 */
export interface UpdateInvoicesResponse {
    [key: string]: any;

    /**
     * 
     * @type {UpdateInvoicesResponseUpdateInvoicesResult}
     * @memberof UpdateInvoicesResponse
     */
    'UpdateInvoicesResult'?: UpdateInvoicesResponseUpdateInvoicesResult;
}
/**
 * UpdateInvoicesResult
 * @export
 * @interface UpdateInvoicesResponseUpdateInvoicesResult
 */
export interface UpdateInvoicesResponseUpdateInvoicesResult {
    [key: string]: any;

    /**
     * InvoiceOrder[]
     * @type {Array<GetInvoicesResponseGetInvoicesResultInvoiceOrderInner>}
     * @memberof UpdateInvoicesResponseUpdateInvoicesResult
     */
    'InvoiceOrder'?: Array<GetInvoicesResponseGetInvoicesResultInvoiceOrderInner>;
}
/**
 * 
 * @export
 * @interface UpdateNotificationChannelCommand
 */
export interface UpdateNotificationChannelCommand {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof UpdateNotificationChannelCommand
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateNotificationChannelCommand
     */
    'type': UpdateNotificationChannelCommandTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof UpdateNotificationChannelCommand
     */
    'rateLimit': number;
    /**
     * 
     * @type {AlertPoliciesResponseInnerConditions}
     * @memberof UpdateNotificationChannelCommand
     */
    'extraConfig': AlertPoliciesResponseInnerConditions;
}

export const UpdateNotificationChannelCommandTypeEnum = {
    Calendar: 'CALENDAR',
    Email: 'EMAIL',
    Sms: 'SMS',
    Webhook: 'WEBHOOK'
} as const;

export type UpdateNotificationChannelCommandTypeEnum = typeof UpdateNotificationChannelCommandTypeEnum[keyof typeof UpdateNotificationChannelCommandTypeEnum];

/**
 * 
 * @export
 * @interface UpdatePolicyNotificationCommand
 */
export interface UpdatePolicyNotificationCommand {
    [key: string]: any;

    /**
     * 
     * @type {UpdatePolicyNotificationCommandLastTriggered}
     * @memberof UpdatePolicyNotificationCommand
     */
    'lastTriggered'?: UpdatePolicyNotificationCommandLastTriggered;
    /**
     * 
     * @type {UpdatePolicyNotificationCommandRateLimit}
     * @memberof UpdatePolicyNotificationCommand
     */
    'rateLimit'?: UpdatePolicyNotificationCommandRateLimit;
}
/**
 * @type UpdatePolicyNotificationCommandLastTriggered
 * @export
 */
export type UpdatePolicyNotificationCommandLastTriggered = UpdatePolicyNotificationCommandLastTriggeredOneOf | string;

/**
 * 
 * @export
 * @interface UpdatePolicyNotificationCommandLastTriggeredOneOf
 */
export interface UpdatePolicyNotificationCommandLastTriggeredOneOf {
    /**
     * 
     * @type {InstallItemUsageAlternativeCreateInputCachedAt}
     * @memberof UpdatePolicyNotificationCommandLastTriggeredOneOf
     */
    'set'?: InstallItemUsageAlternativeCreateInputCachedAt;
}
/**
 * @type UpdatePolicyNotificationCommandRateLimit
 * @export
 */
export type UpdatePolicyNotificationCommandRateLimit = UpdatePolicyNotificationCommandRateLimitOneOf | number;

/**
 * 
 * @export
 * @interface UpdatePolicyNotificationCommandRateLimitOneOf
 */
export interface UpdatePolicyNotificationCommandRateLimitOneOf {
    /**
     * 
     * @type {number}
     * @memberof UpdatePolicyNotificationCommandRateLimitOneOf
     */
    'set'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdatePolicyNotificationCommandRateLimitOneOf
     */
    'increment'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdatePolicyNotificationCommandRateLimitOneOf
     */
    'decrement'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdatePolicyNotificationCommandRateLimitOneOf
     */
    'multiply'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdatePolicyNotificationCommandRateLimitOneOf
     */
    'divide'?: number;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof User
     */
    'id': number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof User
     */
    'properties': Array<AnswerPropertiesInner>;
}
/**
 * 
 * @export
 * @interface UserAccessRoleProperty
 */
export interface UserAccessRoleProperty {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof UserAccessRoleProperty
     */
    'role': UserAccessRolePropertyRoleEnum;
    /**
     * 
     * @type {number}
     * @memberof UserAccessRoleProperty
     */
    'level': UserAccessRolePropertyLevelEnum;
    /**
     * 
     * @type {number}
     * @memberof UserAccessRoleProperty
     */
    'userId': number;
    /**
     * 
     * @type {number}
     * @memberof UserAccessRoleProperty
     */
    'id': number;
}

export const UserAccessRolePropertyRoleEnum = {
    Constant: 'CONSTANT',
    Erp: 'ERP',
    Monitor: 'MONITOR',
    MrpPick: 'MRP_PICK'
} as const;

export type UserAccessRolePropertyRoleEnum = typeof UserAccessRolePropertyRoleEnum[keyof typeof UserAccessRolePropertyRoleEnum];
export const UserAccessRolePropertyLevelEnum = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type UserAccessRolePropertyLevelEnum = typeof UserAccessRolePropertyLevelEnum[keyof typeof UserAccessRolePropertyLevelEnum];

/**
 * 
 * @export
 * @interface UserAccessRoles
 */
export interface UserAccessRoles {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof UserAccessRoles
     */
    'MONITOR': number;
    /**
     * 
     * @type {number}
     * @memberof UserAccessRoles
     */
    'ERP': number;
    /**
     * 
     * @type {number}
     * @memberof UserAccessRoles
     */
    'MRP_PICK': number;
    /**
     * 
     * @type {number}
     * @memberof UserAccessRoles
     */
    'CONSTANT': number;
}
/**
 * UserDefinedDimension
 * @export
 * @interface UserDefinedDimension
 */
export interface UserDefinedDimension {
    [key: string]: any;

    /**
     * s:int
     * @type {number}
     * @memberof UserDefinedDimension
     */
    'TypeId'?: number;
    /**
     * s:string
     * @type {string}
     * @memberof UserDefinedDimension
     */
    'Name'?: string;
    /**
     * s:string
     * @type {string}
     * @memberof UserDefinedDimension
     */
    'Value'?: string;
}
/**
 * UserDefinedDimensions
 * @export
 * @interface UserDefinedDimensions
 */
export interface UserDefinedDimensions {
    [key: string]: any;

    /**
     * UserDefinedDimension[]
     * @type {Array<GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerUserDefinedDimensionsUserDefinedDimensionInner>}
     * @memberof UserDefinedDimensions
     */
    'UserDefinedDimension'?: Array<GetInvoicesResponseGetInvoicesResultInvoiceOrderInnerInvoiceRowsInvoiceRowInnerUserDefinedDimensionsUserDefinedDimensionInner>;
}
/**
 * 
 * @export
 * @enum {number}
 */

export const UserPropertyType = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5,
    NUMBER_6: 6,
    NUMBER_7: 7,
    NUMBER_8: 8,
    NUMBER_9: 9
} as const;

export type UserPropertyType = typeof UserPropertyType[keyof typeof UserPropertyType];


/**
 * 
 * @export
 * @interface Webhook
 */
export interface Webhook {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof Webhook
     */
    'id': number;
    /**
     * 
     * @type {Array<AnswerPropertiesInner>}
     * @memberof Webhook
     */
    'properties': Array<AnswerPropertiesInner>;
}
/**
 * 
 * @export
 * @enum {number}
 */

export const WebhookPropertyType = {
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_11: 11,
    NUMBER_12: 12,
    NUMBER_13: 13,
    NUMBER_14: 14,
    NUMBER_15: 15,
    NUMBER_16: 16,
    NUMBER_17: 17,
    NUMBER_18: 18,
    NUMBER_19: 19,
    NUMBER_20: 20
} as const;

export type WebhookPropertyType = typeof WebhookPropertyType[keyof typeof WebhookPropertyType];


/**
 * 
 * @export
 * @interface WebhookQueueItem
 */
export interface WebhookQueueItem {
    [key: string]: any;

    /**
     * 
     * @type {number}
     * @memberof WebhookQueueItem
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof WebhookQueueItem
     */
    'created': number;
    /**
     * 
     * @type {number}
     * @memberof WebhookQueueItem
     */
    'retrycounter': number;
    /**
     * 
     * @type {number}
     * @memberof WebhookQueueItem
     */
    'dataId': number;
    /**
     * 
     * @type {string}
     * @memberof WebhookQueueItem
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface ZipArea
 */
export interface ZipArea {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof ZipArea
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ZipArea
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof ZipArea
     */
    'fromZip': number;
    /**
     * 
     * @type {number}
     * @memberof ZipArea
     */
    'toZip': number;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} customerId 
         * @param {PostCRMCustomerAquireLockRequest} postCRMCustomerAquireLockRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCRMCustomerCustomerIdAquireLockPost: async (customerId: string, postCRMCustomerAquireLockRequest: PostCRMCustomerAquireLockRequest, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('apiCRMCustomerCustomerIdAquireLockPost', 'customerId', customerId)
            // verify required parameter 'postCRMCustomerAquireLockRequest' is not null or undefined
            assertParamExists('apiCRMCustomerCustomerIdAquireLockPost', 'postCRMCustomerAquireLockRequest', postCRMCustomerAquireLockRequest)
            const localVarPath = `/api/CRM/customer/{customerId}/aquireLock`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postCRMCustomerAquireLockRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customerId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCRMCustomerCustomerIdReleaseLockDelete: async (customerId: string, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('apiCRMCustomerCustomerIdReleaseLockDelete', 'customerId', customerId)
            const localVarPath = `/api/CRM/customer/{customerId}/releaseLock`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCRMCustomerLocksGet: async (authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CRM/customer/locks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCRMDepartmentAreasGet: async (authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CRM/department/areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCRMSMSTemplatesGet: async (authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/CRM/SMS/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateNotificationChannelRequest} createNotificationChannelRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCalendarNotificationChannelPost: async (createNotificationChannelRequest: CreateNotificationChannelRequest, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createNotificationChannelRequest' is not null or undefined
            assertParamExists('apiCalendarNotificationChannelPost', 'createNotificationChannelRequest', createNotificationChannelRequest)
            const localVarPath = `/api/calendar/notification/channel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNotificationChannelRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConstantGet: async (authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/constant/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConstantKeyDelete: async (key: string, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('apiConstantKeyDelete', 'key', key)
            const localVarPath = `/api/constant/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConstantKeyGet: async (key: string, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('apiConstantKeyGet', 'key', key)
            const localVarPath = `/api/constant/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {PostConstantRequest} postConstantRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConstantKeyVersionPost: async (key: string, postConstantRequest: PostConstantRequest, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('apiConstantKeyVersionPost', 'key', key)
            // verify required parameter 'postConstantRequest' is not null or undefined
            assertParamExists('apiConstantKeyVersionPost', 'postConstantRequest', postConstantRequest)
            const localVarPath = `/api/constant/{key}/version`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postConstantRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {string} version 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConstantKeyVersionVersionDelete: async (key: string, version: string, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('apiConstantKeyVersionVersionDelete', 'key', key)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiConstantKeyVersionVersionDelete', 'version', version)
            const localVarPath = `/api/constant/{key}/version/{version}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {string} version 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConstantKeyVersionVersionDisablePost: async (key: string, version: string, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('apiConstantKeyVersionVersionDisablePost', 'key', key)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiConstantKeyVersionVersionDisablePost', 'version', version)
            const localVarPath = `/api/constant/{key}/version/{version}/disable`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {string} version 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConstantKeyVersionVersionEnablePost: async (key: string, version: string, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('apiConstantKeyVersionVersionEnablePost', 'key', key)
            // verify required parameter 'version' is not null or undefined
            assertParamExists('apiConstantKeyVersionVersionEnablePost', 'version', version)
            const localVarPath = `/api/constant/{key}/version/{version}/enable`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)))
                .replace(`{${"version"}}`, encodeURIComponent(String(version)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostConstantRequest} postConstantRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConstantPost: async (postConstantRequest: PostConstantRequest, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postConstantRequest' is not null or undefined
            assertParamExists('apiConstantPost', 'postConstantRequest', postConstantRequest)
            const localVarPath = `/api/constant/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postConstantRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} acErpId 
         * @param {string} [authorization] 
         * @param {string} [client] 
         * @param {string} [demo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCppro24soAcErpIdCreateOrderPost: async (acErpId: string, authorization?: string, client?: string, demo?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'acErpId' is not null or undefined
            assertParamExists('apiCppro24soAcErpIdCreateOrderPost', 'acErpId', acErpId)
            const localVarPath = `/api/cppro/24so/{acErpId}/createOrder`
                .replace(`{${"acErpId"}}`, encodeURIComponent(String(acErpId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (client !== undefined) {
                localVarQueryParameter['client'] = client;
            }

            if (demo !== undefined) {
                localVarQueryParameter['demo'] = demo;
            }


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderid 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCpproCreateorderOrderOrderidPost: async (orderid: string, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderid' is not null or undefined
            assertParamExists('apiCpproCreateorderOrderOrderidPost', 'orderid', orderid)
            const localVarPath = `/api/cppro/createorder/order/{orderid}`
                .replace(`{${"orderid"}}`, encodeURIComponent(String(orderid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} [authorization] 
         * @param {string} [client] 
         * @param {string} [demo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCpproMamutOrderIdCreateOrderPost: async (orderId: string, authorization?: string, client?: string, demo?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('apiCpproMamutOrderIdCreateOrderPost', 'orderId', orderId)
            const localVarPath = `/api/cppro/mamut/{orderId}/createOrder`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (client !== undefined) {
                localVarQueryParameter['client'] = client;
            }

            if (demo !== undefined) {
                localVarQueryParameter['demo'] = demo;
            }


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateSalesOrderChecklistRequest} createSalesOrderChecklistRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCpproOrderCreateSalesOrderChecklistPost: async (createSalesOrderChecklistRequest: CreateSalesOrderChecklistRequest, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSalesOrderChecklistRequest' is not null or undefined
            assertParamExists('apiCpproOrderCreateSalesOrderChecklistPost', 'createSalesOrderChecklistRequest', createSalesOrderChecklistRequest)
            const localVarPath = `/api/cppro/order/createSalesOrderChecklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSalesOrderChecklistRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateServiceOrderChecklistRequest} createServiceOrderChecklistRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCpproOrderCreateServiceOrderChecklistPost: async (createServiceOrderChecklistRequest: CreateServiceOrderChecklistRequest, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createServiceOrderChecklistRequest' is not null or undefined
            assertParamExists('apiCpproOrderCreateServiceOrderChecklistPost', 'createServiceOrderChecklistRequest', createServiceOrderChecklistRequest)
            const localVarPath = `/api/cppro/order/createServiceOrderChecklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createServiceOrderChecklistRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {string} [all] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDatePublicHolidaysGet: async (authorization?: string, all?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/date/publicHolidays`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (all !== undefined) {
                localVarQueryParameter['all'] = all;
            }


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostPublicHolidaysRequest} postPublicHolidaysRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDatePublicHolidaysPost: async (postPublicHolidaysRequest: PostPublicHolidaysRequest, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postPublicHolidaysRequest' is not null or undefined
            assertParamExists('apiDatePublicHolidaysPost', 'postPublicHolidaysRequest', postPublicHolidaysRequest)
            const localVarPath = `/api/date/publicHolidays`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postPublicHolidaysRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocsInternalJsonGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api-docs-internal.json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocsLeadJsonGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api-docs-lead.json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {string} [externalId] 
         * @param {string} [name] 
         * @param {string} [isACT] 
         * @param {string} [test] 
         * @param {string} [search24SOAC] 
         * @param {string} [returnProps] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErp24soForhandlerSearchGet: async (authorization?: string, externalId?: string, name?: string, isACT?: string, test?: string, search24SOAC?: string, returnProps?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/erp/24so/forhandler/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (externalId !== undefined) {
                localVarQueryParameter['externalId'] = externalId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (isACT !== undefined) {
                localVarQueryParameter['isACT'] = isACT;
            }

            if (test !== undefined) {
                localVarQueryParameter['test'] = test;
            }

            if (search24SOAC !== undefined) {
                localVarQueryParameter['search24SOAC'] = search24SOAC;
            }

            if (returnProps !== undefined) {
                localVarQueryParameter['returnProps'] = returnProps;
            }


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {ApiErpAcCalendarDeliveryDateChangePostRequest} [apiErpAcCalendarDeliveryDateChangePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpAcCalendarDeliveryDateChangePost: async (authorization?: string, apiErpAcCalendarDeliveryDateChangePostRequest?: ApiErpAcCalendarDeliveryDateChangePostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/erp/ac/calendar/deliveryDateChange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiErpAcCalendarDeliveryDateChangePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [authorization] 
         * @param {string} [dryRun] 
         * @param {string} [test] 
         * @param {string} [shouldDeleteTasks] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpAcOrderCpproIdInactivatePost: async (cpproId: string, authorization?: string, dryRun?: string, test?: string, shouldDeleteTasks?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproId' is not null or undefined
            assertParamExists('apiErpAcOrderCpproIdInactivatePost', 'cpproId', cpproId)
            const localVarPath = `/api/erp/ac/order/{cpproId}/inactivate`
                .replace(`{${"cpproId"}}`, encodeURIComponent(String(cpproId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (test !== undefined) {
                localVarQueryParameter['test'] = test;
            }

            if (shouldDeleteTasks !== undefined) {
                localVarQueryParameter['shouldDeleteTasks'] = shouldDeleteTasks;
            }


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {GetErpSyncOrderCommand} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpErpSyncOrderGet: async (authorization?: string, query?: GetErpSyncOrderCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/erp/erpSyncOrder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                for (const [key, value] of Object.entries(query)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {string} [startTime] 
         * @param {string} [endTime] 
         * @param {DateCommand} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpErpSyncOrdersGet: async (authorization?: string, startTime?: string, endTime?: string, query?: DateCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/erp/erpSyncOrders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = endTime;
            }

            if (query !== undefined) {
                for (const [key, value] of Object.entries(query)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {string} [ignoreCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpInstallItemUsageGet: async (authorization?: string, ignoreCache?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/erp/install/itemUsage/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ignoreCache !== undefined) {
                localVarQueryParameter['ignoreCache'] = ignoreCache;
            }


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} itemNumber 
         * @param {PostInstallItemUsageAlternativeRequest} postInstallItemUsageAlternativeRequest 
         * @param {string} [authorization] 
         * @param {string} [ignoreCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpInstallItemUsageItemNumberAlternativePost: async (itemNumber: string, postInstallItemUsageAlternativeRequest: PostInstallItemUsageAlternativeRequest, authorization?: string, ignoreCache?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemNumber' is not null or undefined
            assertParamExists('apiErpInstallItemUsageItemNumberAlternativePost', 'itemNumber', itemNumber)
            // verify required parameter 'postInstallItemUsageAlternativeRequest' is not null or undefined
            assertParamExists('apiErpInstallItemUsageItemNumberAlternativePost', 'postInstallItemUsageAlternativeRequest', postInstallItemUsageAlternativeRequest)
            const localVarPath = `/api/erp/install/itemUsage/{itemNumber}/alternative`
                .replace(`{${"itemNumber"}}`, encodeURIComponent(String(itemNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ignoreCache !== undefined) {
                localVarQueryParameter['ignoreCache'] = ignoreCache;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postInstallItemUsageAlternativeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} itemNumber 
         * @param {string} [authorization] 
         * @param {string} [ignoreCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpInstallItemUsageItemNumberDelete: async (itemNumber: string, authorization?: string, ignoreCache?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemNumber' is not null or undefined
            assertParamExists('apiErpInstallItemUsageItemNumberDelete', 'itemNumber', itemNumber)
            const localVarPath = `/api/erp/install/itemUsage/{itemNumber}`
                .replace(`{${"itemNumber"}}`, encodeURIComponent(String(itemNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ignoreCache !== undefined) {
                localVarQueryParameter['ignoreCache'] = ignoreCache;
            }


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} itemNumber 
         * @param {PutInstallItemUsageRequest} putInstallItemUsageRequest 
         * @param {string} [authorization] 
         * @param {string} [ignoreCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpInstallItemUsageItemNumberPut: async (itemNumber: string, putInstallItemUsageRequest: PutInstallItemUsageRequest, authorization?: string, ignoreCache?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemNumber' is not null or undefined
            assertParamExists('apiErpInstallItemUsageItemNumberPut', 'itemNumber', itemNumber)
            // verify required parameter 'putInstallItemUsageRequest' is not null or undefined
            assertParamExists('apiErpInstallItemUsageItemNumberPut', 'putInstallItemUsageRequest', putInstallItemUsageRequest)
            const localVarPath = `/api/erp/install/itemUsage/{itemNumber}`
                .replace(`{${"itemNumber"}}`, encodeURIComponent(String(itemNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ignoreCache !== undefined) {
                localVarQueryParameter['ignoreCache'] = ignoreCache;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putInstallItemUsageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} itemUsageId 
         * @param {string} alternativeId 
         * @param {string} [authorization] 
         * @param {string} [ignoreCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdDelete: async (itemUsageId: string, alternativeId: string, authorization?: string, ignoreCache?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemUsageId' is not null or undefined
            assertParamExists('apiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdDelete', 'itemUsageId', itemUsageId)
            // verify required parameter 'alternativeId' is not null or undefined
            assertParamExists('apiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdDelete', 'alternativeId', alternativeId)
            const localVarPath = `/api/erp/install/itemUsage/{itemUsageId}/alternative/{alternativeId}`
                .replace(`{${"itemUsageId"}}`, encodeURIComponent(String(itemUsageId)))
                .replace(`{${"alternativeId"}}`, encodeURIComponent(String(alternativeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ignoreCache !== undefined) {
                localVarQueryParameter['ignoreCache'] = ignoreCache;
            }


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} itemUsageId 
         * @param {string} alternativeId 
         * @param {PutInstallItemUsageAlternativeRequest} putInstallItemUsageAlternativeRequest 
         * @param {string} [authorization] 
         * @param {string} [ignoreCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdPut: async (itemUsageId: string, alternativeId: string, putInstallItemUsageAlternativeRequest: PutInstallItemUsageAlternativeRequest, authorization?: string, ignoreCache?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemUsageId' is not null or undefined
            assertParamExists('apiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdPut', 'itemUsageId', itemUsageId)
            // verify required parameter 'alternativeId' is not null or undefined
            assertParamExists('apiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdPut', 'alternativeId', alternativeId)
            // verify required parameter 'putInstallItemUsageAlternativeRequest' is not null or undefined
            assertParamExists('apiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdPut', 'putInstallItemUsageAlternativeRequest', putInstallItemUsageAlternativeRequest)
            const localVarPath = `/api/erp/install/itemUsage/{itemUsageId}/alternative/{alternativeId}`
                .replace(`{${"itemUsageId"}}`, encodeURIComponent(String(itemUsageId)))
                .replace(`{${"alternativeId"}}`, encodeURIComponent(String(alternativeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ignoreCache !== undefined) {
                localVarQueryParameter['ignoreCache'] = ignoreCache;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putInstallItemUsageAlternativeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostInstallItemUsageRequest} postInstallItemUsageRequest 
         * @param {string} [authorization] 
         * @param {string} [ignoreCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpInstallItemUsagePost: async (postInstallItemUsageRequest: PostInstallItemUsageRequest, authorization?: string, ignoreCache?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postInstallItemUsageRequest' is not null or undefined
            assertParamExists('apiErpInstallItemUsagePost', 'postInstallItemUsageRequest', postInstallItemUsageRequest)
            const localVarPath = `/api/erp/install/itemUsage/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (ignoreCache !== undefined) {
                localVarQueryParameter['ignoreCache'] = ignoreCache;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postInstallItemUsageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} installCPproId 
         * @param {string} [authorization] 
         * @param {string} [continueProcess] 
         * @param {string} [dryRun] 
         * @param {string} [manual] 
         * @param {string} [enableDelete] 
         * @param {CommonQueryParams} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpInstallOrderInstallCPproIdChecklistInstalledPost: async (installCPproId: string, authorization?: string, continueProcess?: string, dryRun?: string, manual?: string, enableDelete?: string, query?: CommonQueryParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'installCPproId' is not null or undefined
            assertParamExists('apiErpInstallOrderInstallCPproIdChecklistInstalledPost', 'installCPproId', installCPproId)
            const localVarPath = `/api/erp/installOrder/{installCPproId}/checklistInstalled`
                .replace(`{${"installCPproId"}}`, encodeURIComponent(String(installCPproId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (continueProcess !== undefined) {
                localVarQueryParameter['continueProcess'] = continueProcess;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (manual !== undefined) {
                localVarQueryParameter['manual'] = manual;
            }

            if (enableDelete !== undefined) {
                localVarQueryParameter['enableDelete'] = enableDelete;
            }

            if (query !== undefined) {
                for (const [key, value] of Object.entries(query)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} itemNumber 
         * @param {string} [authorization] 
         * @param {string} [test] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpMrpItemItemNumberStockGet: async (itemNumber: string, authorization?: string, test?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'itemNumber' is not null or undefined
            assertParamExists('apiErpMrpItemItemNumberStockGet', 'itemNumber', itemNumber)
            const localVarPath = `/api/erp/mrp/item/{itemNumber}/stock`
                .replace(`{${"itemNumber"}}`, encodeURIComponent(String(itemNumber)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (test !== undefined) {
                localVarQueryParameter['test'] = test;
            }


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproInstallId 
         * @param {string} [authorization] 
         * @param {string} [questionId] 
         * @param {string} [serialNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpMrpOrderCpproInstallIdCheckSerialNumberGet: async (cpproInstallId: string, authorization?: string, questionId?: string, serialNumber?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproInstallId' is not null or undefined
            assertParamExists('apiErpMrpOrderCpproInstallIdCheckSerialNumberGet', 'cpproInstallId', cpproInstallId)
            const localVarPath = `/api/erp/mrp/order/{cpproInstallId}/checkSerialNumber`
                .replace(`{${"cpproInstallId"}}`, encodeURIComponent(String(cpproInstallId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (questionId !== undefined) {
                localVarQueryParameter['questionId'] = questionId;
            }

            if (serialNumber !== undefined) {
                localVarQueryParameter['serialNumber'] = serialNumber;
            }


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpMrpOrderOrderIdPickErrorsGet: async (orderId: string, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('apiErpMrpOrderOrderIdPickErrorsGet', 'orderId', orderId)
            const localVarPath = `/api/erp/mrp/order/{orderId}/pickErrors`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {string} [startTime] 
         * @param {string} [endTime] 
         * @param {DateCommand} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpMrpOrderStatisticsGet: async (authorization?: string, startTime?: string, endTime?: string, query?: DateCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/erp/mrp/order/statistics`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = endTime;
            }

            if (query !== undefined) {
                for (const [key, value] of Object.entries(query)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} deviationReasonId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpMrpPickErrorDeviationReasonDeviationReasonIdDelete: async (deviationReasonId: string, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviationReasonId' is not null or undefined
            assertParamExists('apiErpMrpPickErrorDeviationReasonDeviationReasonIdDelete', 'deviationReasonId', deviationReasonId)
            const localVarPath = `/api/erp/mrp/pickError/deviationReason/{deviationReasonId}`
                .replace(`{${"deviationReasonId"}}`, encodeURIComponent(String(deviationReasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} deviationReasonId 
         * @param {PutPostPickErrorDeviationReasonRequest} putPostPickErrorDeviationReasonRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpMrpPickErrorDeviationReasonDeviationReasonIdPut: async (deviationReasonId: string, putPostPickErrorDeviationReasonRequest: PutPostPickErrorDeviationReasonRequest, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviationReasonId' is not null or undefined
            assertParamExists('apiErpMrpPickErrorDeviationReasonDeviationReasonIdPut', 'deviationReasonId', deviationReasonId)
            // verify required parameter 'putPostPickErrorDeviationReasonRequest' is not null or undefined
            assertParamExists('apiErpMrpPickErrorDeviationReasonDeviationReasonIdPut', 'putPostPickErrorDeviationReasonRequest', putPostPickErrorDeviationReasonRequest)
            const localVarPath = `/api/erp/mrp/pickError/deviationReason/{deviationReasonId}`
                .replace(`{${"deviationReasonId"}}`, encodeURIComponent(String(deviationReasonId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putPostPickErrorDeviationReasonRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PutPostPickErrorDeviationReasonRequest} putPostPickErrorDeviationReasonRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpMrpPickErrorDeviationReasonPost: async (putPostPickErrorDeviationReasonRequest: PutPostPickErrorDeviationReasonRequest, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'putPostPickErrorDeviationReasonRequest' is not null or undefined
            assertParamExists('apiErpMrpPickErrorDeviationReasonPost', 'putPostPickErrorDeviationReasonRequest', putPostPickErrorDeviationReasonRequest)
            const localVarPath = `/api/erp/mrp/pickError/deviationReason`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putPostPickErrorDeviationReasonRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpMrpPickErrorDeviationReasonsGet: async (authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/erp/mrp/pickError/deviationReasons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} pickErrorId 
         * @param {PutMrpPickErrorRequest} putMrpPickErrorRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpMrpPickErrorPickErrorIdPut: async (pickErrorId: string, putMrpPickErrorRequest: PutMrpPickErrorRequest, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pickErrorId' is not null or undefined
            assertParamExists('apiErpMrpPickErrorPickErrorIdPut', 'pickErrorId', pickErrorId)
            // verify required parameter 'putMrpPickErrorRequest' is not null or undefined
            assertParamExists('apiErpMrpPickErrorPickErrorIdPut', 'putMrpPickErrorRequest', putMrpPickErrorRequest)
            const localVarPath = `/api/erp/mrp/pickError/{pickErrorId}`
                .replace(`{${"pickErrorId"}}`, encodeURIComponent(String(pickErrorId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putMrpPickErrorRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {string} [status] 
         * @param {string} [startTime] 
         * @param {string} [endTime] 
         * @param {GetMrpPickErrorsRequest} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpMrpPickErrorsGet: async (authorization?: string, status?: string, startTime?: string, endTime?: string, query?: GetMrpPickErrorsRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/erp/mrp/pickErrors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (startTime !== undefined) {
                localVarQueryParameter['startTime'] = startTime;
            }

            if (endTime !== undefined) {
                localVarQueryParameter['endTime'] = endTime;
            }

            if (query !== undefined) {
                for (const [key, value] of Object.entries(query)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PutMrpPickErrorsRequest} putMrpPickErrorsRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpMrpPickErrorsPut: async (putMrpPickErrorsRequest: PutMrpPickErrorsRequest, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'putMrpPickErrorsRequest' is not null or undefined
            assertParamExists('apiErpMrpPickErrorsPut', 'putMrpPickErrorsRequest', putMrpPickErrorsRequest)
            const localVarPath = `/api/erp/mrp/pickErrors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putMrpPickErrorsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostCreateOrderRequest} postCreateOrderRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpOrder24soCreateOrderTaskPost: async (postCreateOrderRequest: PostCreateOrderRequest, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postCreateOrderRequest' is not null or undefined
            assertParamExists('apiErpOrder24soCreateOrderTaskPost', 'postCreateOrderRequest', postCreateOrderRequest)
            const localVarPath = `/api/erp/order/24so/createOrderTask`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postCreateOrderRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [authorization] 
         * @param {string} [dryRun] 
         * @param {string} [manual] 
         * @param {string} [enableDelete] 
         * @param {CommonQueryParams} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpOrderCpproId24soACInvoicePost: async (cpproId: string, authorization?: string, dryRun?: string, manual?: string, enableDelete?: string, query?: CommonQueryParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproId' is not null or undefined
            assertParamExists('apiErpOrderCpproId24soACInvoicePost', 'cpproId', cpproId)
            const localVarPath = `/api/erp/order/{cpproId}/24so/AC/invoice`
                .replace(`{${"cpproId"}}`, encodeURIComponent(String(cpproId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (manual !== undefined) {
                localVarQueryParameter['manual'] = manual;
            }

            if (enableDelete !== undefined) {
                localVarQueryParameter['enableDelete'] = enableDelete;
            }

            if (query !== undefined) {
                for (const [key, value] of Object.entries(query)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [authorization] 
         * @param {string} [dryRun] 
         * @param {string} [manual] 
         * @param {string} [enableDelete] 
         * @param {CommonQueryParams} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpOrderCpproId24soInactivateFJAndInvoiceACPost: async (cpproId: string, authorization?: string, dryRun?: string, manual?: string, enableDelete?: string, query?: CommonQueryParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproId' is not null or undefined
            assertParamExists('apiErpOrderCpproId24soInactivateFJAndInvoiceACPost', 'cpproId', cpproId)
            const localVarPath = `/api/erp/order/{cpproId}/24so/inactivateFJAndInvoiceAC`
                .replace(`{${"cpproId"}}`, encodeURIComponent(String(cpproId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (manual !== undefined) {
                localVarQueryParameter['manual'] = manual;
            }

            if (enableDelete !== undefined) {
                localVarQueryParameter['enableDelete'] = enableDelete;
            }

            if (query !== undefined) {
                for (const [key, value] of Object.entries(query)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpOrderCpproIdCheckEnTurTilOrderInvoicedGet: async (cpproId: string, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproId' is not null or undefined
            assertParamExists('apiErpOrderCpproIdCheckEnTurTilOrderInvoicedGet', 'cpproId', cpproId)
            const localVarPath = `/api/erp/order/{cpproId}/checkEnTurTilOrderInvoiced`
                .replace(`{${"cpproId"}}`, encodeURIComponent(String(cpproId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [authorization] 
         * @param {string} [dryRun] 
         * @param {string} [manual] 
         * @param {string} [enableDelete] 
         * @param {CommonQueryParams} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpOrderCpproIdMrpFindMRPOrderPost: async (cpproId: string, authorization?: string, dryRun?: string, manual?: string, enableDelete?: string, query?: CommonQueryParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproId' is not null or undefined
            assertParamExists('apiErpOrderCpproIdMrpFindMRPOrderPost', 'cpproId', cpproId)
            const localVarPath = `/api/erp/order/{cpproId}/mrp/findMRPOrder`
                .replace(`{${"cpproId"}}`, encodeURIComponent(String(cpproId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (manual !== undefined) {
                localVarQueryParameter['manual'] = manual;
            }

            if (enableDelete !== undefined) {
                localVarQueryParameter['enableDelete'] = enableDelete;
            }

            if (query !== undefined) {
                for (const [key, value] of Object.entries(query)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [authorization] 
         * @param {string} [dryRun] 
         * @param {string} [manual] 
         * @param {string} [enableDelete] 
         * @param {CommonQueryParams} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpOrderCpproIdMrpInvoicePost: async (cpproId: string, authorization?: string, dryRun?: string, manual?: string, enableDelete?: string, query?: CommonQueryParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproId' is not null or undefined
            assertParamExists('apiErpOrderCpproIdMrpInvoicePost', 'cpproId', cpproId)
            const localVarPath = `/api/erp/order/{cpproId}/mrp/invoice`
                .replace(`{${"cpproId"}}`, encodeURIComponent(String(cpproId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (manual !== undefined) {
                localVarQueryParameter['manual'] = manual;
            }

            if (enableDelete !== undefined) {
                localVarQueryParameter['enableDelete'] = enableDelete;
            }

            if (query !== undefined) {
                for (const [key, value] of Object.entries(query)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {PostMRPManuallyInvoicedRequest} postMRPManuallyInvoicedRequest 
         * @param {string} [authorization] 
         * @param {CommonQueryParams} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpOrderCpproIdMrpManuallyInvoicedPost: async (cpproId: string, postMRPManuallyInvoicedRequest: PostMRPManuallyInvoicedRequest, authorization?: string, query?: CommonQueryParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproId' is not null or undefined
            assertParamExists('apiErpOrderCpproIdMrpManuallyInvoicedPost', 'cpproId', cpproId)
            // verify required parameter 'postMRPManuallyInvoicedRequest' is not null or undefined
            assertParamExists('apiErpOrderCpproIdMrpManuallyInvoicedPost', 'postMRPManuallyInvoicedRequest', postMRPManuallyInvoicedRequest)
            const localVarPath = `/api/erp/order/{cpproId}/mrp/manuallyInvoiced`
                .replace(`{${"cpproId"}}`, encodeURIComponent(String(cpproId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                for (const [key, value] of Object.entries(query)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postMRPManuallyInvoicedRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [authorization] 
         * @param {string} [dryRun] 
         * @param {string} [manual] 
         * @param {string} [enableDelete] 
         * @param {CommonQueryParams} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpOrderCpproIdMrpPickPost: async (cpproId: string, authorization?: string, dryRun?: string, manual?: string, enableDelete?: string, query?: CommonQueryParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproId' is not null or undefined
            assertParamExists('apiErpOrderCpproIdMrpPickPost', 'cpproId', cpproId)
            const localVarPath = `/api/erp/order/{cpproId}/mrp/pick`
                .replace(`{${"cpproId"}}`, encodeURIComponent(String(cpproId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (manual !== undefined) {
                localVarQueryParameter['manual'] = manual;
            }

            if (enableDelete !== undefined) {
                localVarQueryParameter['enableDelete'] = enableDelete;
            }

            if (query !== undefined) {
                for (const [key, value] of Object.entries(query)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [authorization] 
         * @param {CommonQueryParams} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpOrderCpproIdMrpSalesOrderGet: async (cpproId: string, authorization?: string, query?: CommonQueryParams, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproId' is not null or undefined
            assertParamExists('apiErpOrderCpproIdMrpSalesOrderGet', 'cpproId', cpproId)
            const localVarPath = `/api/erp/order/{cpproId}/mrp/salesOrder`
                .replace(`{${"cpproId"}}`, encodeURIComponent(String(cpproId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                for (const [key, value] of Object.entries(query)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpOrderCpproIdMrpShipPost: async (cpproId: string, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproId' is not null or undefined
            assertParamExists('apiErpOrderCpproIdMrpShipPost', 'cpproId', cpproId)
            const localVarPath = `/api/erp/order/{cpproId}/mrp/ship`
                .replace(`{${"cpproId"}}`, encodeURIComponent(String(cpproId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [authorization] 
         * @param {string} [deliveryDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpOrderCpproIdRecreateOrderPost: async (cpproId: string, authorization?: string, deliveryDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproId' is not null or undefined
            assertParamExists('apiErpOrderCpproIdRecreateOrderPost', 'cpproId', cpproId)
            const localVarPath = `/api/erp/order/{cpproId}/recreateOrder`
                .replace(`{${"cpproId"}}`, encodeURIComponent(String(cpproId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deliveryDate !== undefined) {
                localVarQueryParameter['deliveryDate'] = deliveryDate;
            }


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {string} [event] 
         * @param {string} [optionalNow] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpSmsSendCalendarRemindersPost: async (authorization?: string, event?: string, optionalNow?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/erp/sms/sendCalendarReminders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (event !== undefined) {
                localVarQueryParameter['event'] = event;
            }

            if (optionalNow !== undefined) {
                localVarQueryParameter['optionalNow'] = optionalNow;
            }


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customerId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSmsCustomerCustomerIdMessagesGet: async (customerId: string, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerId' is not null or undefined
            assertParamExists('apiSmsCustomerCustomerIdMessagesGet', 'customerId', customerId)
            const localVarPath = `/api/sms/customer/{customerId}/messages`
                .replace(`{${"customerId"}}`, encodeURIComponent(String(customerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSmsIdHandledReplyPut: async (id: string, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiSmsIdHandledReplyPut', 'id', id)
            const localVarPath = `/api/sms/{id}/handledReply`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {GetSMSRepliesCommand} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSmsRepliesGet: async (authorization?: string, query?: GetSMSRepliesCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/sms/replies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                for (const [key, value] of Object.entries(query)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SendSMSAPIRequest} sendSMSAPIRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSmsSendPost: async (sendSMSAPIRequest: SendSMSAPIRequest, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sendSMSAPIRequest' is not null or undefined
            assertParamExists('apiSmsSendPost', 'sendSMSAPIRequest', sendSMSAPIRequest)
            const localVarPath = `/api/sms/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sendSMSAPIRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CalendarNotificationChannelPostRequest} [calendarNotificationChannelPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarNotificationChannelDelete: async (calendarNotificationChannelPostRequest?: CalendarNotificationChannelPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/calendar/notification/channel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calendarNotificationChannelPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarNotificationChannelInitialCreateAllPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/calendar/notification/channel/initialCreateAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CalendarNotificationChannelPostRequest} [calendarNotificationChannelPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarNotificationChannelPost: async (calendarNotificationChannelPostRequest?: CalendarNotificationChannelPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/calendar/notification/channel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calendarNotificationChannelPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarNotificationChannelRefreshAllPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/calendar/notification/channel/refreshAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CalendarNotificationChannelPostRequest} [calendarNotificationChannelPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarNotificationChannelRefreshPost: async (calendarNotificationChannelPostRequest?: CalendarNotificationChannelPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/calendar/notification/channel/refresh`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calendarNotificationChannelPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CalendarNotificationHandleUpdatedEventPostRequest} [calendarNotificationHandleUpdatedEventPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarNotificationHandleEventNotificationPost: async (calendarNotificationHandleUpdatedEventPostRequest?: CalendarNotificationHandleUpdatedEventPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/calendar/notification/handleEventNotification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calendarNotificationHandleUpdatedEventPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CalendarNotificationHandleUpdatedEventPostRequest} [calendarNotificationHandleUpdatedEventPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarNotificationHandleUpdatedEventPost: async (calendarNotificationHandleUpdatedEventPostRequest?: CalendarNotificationHandleUpdatedEventPostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/calendar/notification/handleUpdatedEvent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(calendarNotificationHandleUpdatedEventPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cpproEnTurTilOrderOrderidGet: async (orderid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderid' is not null or undefined
            assertParamExists('cpproEnTurTilOrderOrderidGet', 'orderid', orderid)
            const localVarPath = `/cppro/enTurTil/order/{orderid}`
                .replace(`{${"orderid"}}`, encodeURIComponent(String(orderid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cpproGdprOrderOrderidDelete: async (orderid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderid' is not null or undefined
            assertParamExists('cpproGdprOrderOrderidDelete', 'orderid', orderid)
            const localVarPath = `/cppro/gdpr/order/{orderid}`
                .replace(`{${"orderid"}}`, encodeURIComponent(String(orderid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cpproGdprOrderOrderidPdfsGet: async (orderid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderid' is not null or undefined
            assertParamExists('cpproGdprOrderOrderidPdfsGet', 'orderid', orderid)
            const localVarPath = `/cppro/gdpr/order/{orderid}/pdfs`
                .replace(`{${"orderid"}}`, encodeURIComponent(String(orderid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cpproGdprOrdersDelete: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cppro/gdpr/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cpproGdprOrdersGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cppro/gdpr/orders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cpproGdprOrdersPdfsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cppro/gdpr/orders/pdfs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderid 
         * @param {string} [dryRun] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cpproLostOrderOrderidGet: async (orderid: string, dryRun?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderid' is not null or undefined
            assertParamExists('cpproLostOrderOrderidGet', 'orderid', orderid)
            const localVarPath = `/cppro/lost/order/{orderid}`
                .replace(`{${"orderid"}}`, encodeURIComponent(String(orderid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cpproNotificationOrderOrderidGet: async (orderid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderid' is not null or undefined
            assertParamExists('cpproNotificationOrderOrderidGet', 'orderid', orderid)
            const localVarPath = `/cppro/notification/order/{orderid}`
                .replace(`{${"orderid"}}`, encodeURIComponent(String(orderid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cpproOrderOrderidPricesGet: async (orderid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderid' is not null or undefined
            assertParamExists('cpproOrderOrderidPricesGet', 'orderid', orderid)
            const localVarPath = `/cppro/order/{orderid}/prices`
                .replace(`{${"orderid"}}`, encodeURIComponent(String(orderid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cpproProsjektGodkjentOrderOrderidGet: async (orderid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderid' is not null or undefined
            assertParamExists('cpproProsjektGodkjentOrderOrderidGet', 'orderid', orderid)
            const localVarPath = `/cppro/prosjektGodkjent/order/{orderid}`
                .replace(`{${"orderid"}}`, encodeURIComponent(String(orderid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cpproSyncGoogleUsersGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cppro/syncGoogleUsers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderid 
         * @param {string} [pdfType] 
         * @param {string} [dryRun] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cpproUpdateBefaringEventOrderidGet: async (orderid: string, pdfType?: string, dryRun?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderid' is not null or undefined
            assertParamExists('cpproUpdateBefaringEventOrderidGet', 'orderid', orderid)
            const localVarPath = `/cppro/updateBefaringEvent/{orderid}`
                .replace(`{${"orderid"}}`, encodeURIComponent(String(orderid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pdfType !== undefined) {
                localVarQueryParameter['pdfType'] = pdfType;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} calendarId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cpproUpdateSaleCalendarsCalendarIdPost: async (calendarId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'calendarId' is not null or undefined
            assertParamExists('cpproUpdateSaleCalendarsCalendarIdPost', 'calendarId', calendarId)
            const localVarPath = `/cppro/updateSaleCalendars/{calendarId}`
                .replace(`{${"calendarId"}}`, encodeURIComponent(String(calendarId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cpproUpdateSaleCalendarsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cppro/updateSaleCalendars`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cpproWebhookCheckQuestionsPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cppro/webhook/checkQuestions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createorderOrderOrderidGet: async (orderid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderid' is not null or undefined
            assertParamExists('createorderOrderOrderidGet', 'orderid', orderid)
            const localVarPath = `/createorder/order/{orderid}`
                .replace(`{${"orderid"}}`, encodeURIComponent(String(orderid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailInsertPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/email/insert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} mailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailSendMailIdPost: async (mailId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'mailId' is not null or undefined
            assertParamExists('emailSendMailIdPost', 'mailId', mailId)
            const localVarPath = `/email/send/{mailId}`
                .replace(`{${"mailId"}}`, encodeURIComponent(String(mailId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} acErpId 
         * @param {string} [client] 
         * @param {string} [demo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erp24soAcErpIdCreateCPProOrderPost: async (acErpId: string, client?: string, demo?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'acErpId' is not null or undefined
            assertParamExists('erp24soAcErpIdCreateCPProOrderPost', 'acErpId', acErpId)
            const localVarPath = `/erp/24so/{acErpId}/createCPProOrder`
                .replace(`{${"acErpId"}}`, encodeURIComponent(String(acErpId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (client !== undefined) {
                localVarQueryParameter['client'] = client;
            }

            if (demo !== undefined) {
                localVarQueryParameter['demo'] = demo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [dryRun] 
         * @param {string} [manual] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erp24soFindFJOrderAfterMRPInvoicedCpproIdPost: async (cpproId: string, dryRun?: string, manual?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproId' is not null or undefined
            assertParamExists('erp24soFindFJOrderAfterMRPInvoicedCpproIdPost', 'cpproId', cpproId)
            const localVarPath = `/erp/24so/findFJOrderAfterMRPInvoiced/{cpproId}`
                .replace(`{${"cpproId"}}`, encodeURIComponent(String(cpproId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (manual !== undefined) {
                localVarQueryParameter['manual'] = manual;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [externalId] 
         * @param {string} [name] 
         * @param {string} [isACT] 
         * @param {string} [test] 
         * @param {string} [search24SOAC] 
         * @param {string} [returnProps] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erp24soForhandlerSearchGet: async (externalId?: string, name?: string, isACT?: string, test?: string, search24SOAC?: string, returnProps?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/erp/24so/forhandler/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (externalId !== undefined) {
                localVarQueryParameter['externalId'] = externalId;
            }

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (isACT !== undefined) {
                localVarQueryParameter['isACT'] = isACT;
            }

            if (test !== undefined) {
                localVarQueryParameter['test'] = test;
            }

            if (search24SOAC !== undefined) {
                localVarQueryParameter['search24SOAC'] = search24SOAC;
            }

            if (returnProps !== undefined) {
                localVarQueryParameter['returnProps'] = returnProps;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ApiErpAcCalendarDeliveryDateChangePostRequest} [apiErpAcCalendarDeliveryDateChangePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpAcCalendarDeliveryDateChangePost: async (apiErpAcCalendarDeliveryDateChangePostRequest?: ApiErpAcCalendarDeliveryDateChangePostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/erp/ac/calendar/deliveryDateChange`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiErpAcCalendarDeliveryDateChangePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} eventId 
         * @param {string} [skipDeliveryDateUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpAcCalendarOrderCpproIdEventDeliveryDateChangeEventEventIdPost: async (cpproId: string, eventId: string, skipDeliveryDateUpdate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproId' is not null or undefined
            assertParamExists('erpAcCalendarOrderCpproIdEventDeliveryDateChangeEventEventIdPost', 'cpproId', cpproId)
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('erpAcCalendarOrderCpproIdEventDeliveryDateChangeEventEventIdPost', 'eventId', eventId)
            const localVarPath = `/erp/ac/calendar/order/{cpproId}/eventDeliveryDateChange/event/{eventId}`
                .replace(`{${"cpproId"}}`, encodeURIComponent(String(cpproId)))
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skipDeliveryDateUpdate !== undefined) {
                localVarQueryParameter['skipDeliveryDateUpdate'] = skipDeliveryDateUpdate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [disableDelete] 
         * @param {string} [dryRun] 
         * @param {string} [manual] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpAcChecklistInstalledOrderCpproIdGet: async (cpproId: string, disableDelete?: string, dryRun?: string, manual?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproId' is not null or undefined
            assertParamExists('erpAcChecklistInstalledOrderCpproIdGet', 'cpproId', cpproId)
            const localVarPath = `/erp/ac/checklistInstalled/order/{cpproId}`
                .replace(`{${"cpproId"}}`, encodeURIComponent(String(cpproId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (disableDelete !== undefined) {
                localVarQueryParameter['disableDelete'] = disableDelete;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (manual !== undefined) {
                localVarQueryParameter['manual'] = manual;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} [deliveryDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpAcCreateOrderOrderIdPost: async (orderId: string, deliveryDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('erpAcCreateOrderOrderIdPost', 'orderId', orderId)
            const localVarPath = `/erp/ac/createOrder/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deliveryDate !== undefined) {
                localVarQueryParameter['deliveryDate'] = deliveryDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [changedAfter] 
         * @param {string} [test] 
         * @param {string} [dryRun] 
         * @param {string} [includeAlreadyInactivated] 
         * @param {string} [shouldDeleteTasks] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpAcOrderInactivatePost: async (changedAfter?: string, test?: string, dryRun?: string, includeAlreadyInactivated?: string, shouldDeleteTasks?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/erp/ac/order/inactivate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (changedAfter !== undefined) {
                localVarQueryParameter['changedAfter'] = changedAfter;
            }

            if (test !== undefined) {
                localVarQueryParameter['test'] = test;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (includeAlreadyInactivated !== undefined) {
                localVarQueryParameter['includeAlreadyInactivated'] = includeAlreadyInactivated;
            }

            if (shouldDeleteTasks !== undefined) {
                localVarQueryParameter['shouldDeleteTasks'] = shouldDeleteTasks;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpAcRecreateOrderCpproIdPost: async (cpproId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproId' is not null or undefined
            assertParamExists('erpAcRecreateOrderCpproIdPost', 'cpproId', cpproId)
            const localVarPath = `/erp/ac/recreateOrder/{cpproId}`
                .replace(`{${"cpproId"}}`, encodeURIComponent(String(cpproId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpCreditSaleInvoiceACOrderCpproIdGet: async (cpproId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproId' is not null or undefined
            assertParamExists('erpCreditSaleInvoiceACOrderCpproIdGet', 'cpproId', cpproId)
            const localVarPath = `/erp/creditSale/invoiceAC/order/{cpproId}`
                .replace(`{${"cpproId"}}`, encodeURIComponent(String(cpproId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [email] 
         * @param {string} [phone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpCustomerGet: async (name?: string, email?: string, phone?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/erp/customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [external] 
         * @param {string} [identity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpCustomerIdGet: async (id: string, external?: string, identity?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('erpCustomerIdGet', 'id', id)
            const localVarPath = `/erp/customer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (external !== undefined) {
                localVarQueryParameter['external'] = external;
            }

            if (identity !== undefined) {
                localVarQueryParameter['identity'] = identity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [external] 
         * @param {string} [identity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpCustomerIdPatch: async (id: string, external?: string, identity?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('erpCustomerIdPatch', 'id', id)
            const localVarPath = `/erp/customer/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (external !== undefined) {
                localVarQueryParameter['external'] = external;
            }

            if (identity !== undefined) {
                localVarQueryParameter['identity'] = identity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [email] 
         * @param {string} [phone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpCustomerPatch: async (name?: string, email?: string, phone?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/erp/customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpCustomerPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/erp/customer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} [deliveryDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpFjCreateOrderFromACOrderIdPost: async (orderId: string, deliveryDate?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('erpFjCreateOrderFromACOrderIdPost', 'orderId', orderId)
            const localVarPath = `/erp/fj/createOrderFromAC/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deliveryDate !== undefined) {
                localVarQueryParameter['deliveryDate'] = deliveryDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpFjpartnerCreateOrderCpproIdPost: async (cpproId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproId' is not null or undefined
            assertParamExists('erpFjpartnerCreateOrderCpproIdPost', 'cpproId', cpproId)
            const localVarPath = `/erp/fjpartner/createOrder/{cpproId}`
                .replace(`{${"cpproId"}}`, encodeURIComponent(String(cpproId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpFjpartnerMigrateDemoOrderToLiveCpproIdPost: async (cpproId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproId' is not null or undefined
            assertParamExists('erpFjpartnerMigrateDemoOrderToLiveCpproIdPost', 'cpproId', cpproId)
            const localVarPath = `/erp/fjpartner/migrateDemoOrderToLive/{cpproId}`
                .replace(`{${"cpproId"}}`, encodeURIComponent(String(cpproId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [test] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpFjpartnerOrderCpproIdCreateExtraACOrderPost: async (cpproId: string, test?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproId' is not null or undefined
            assertParamExists('erpFjpartnerOrderCpproIdCreateExtraACOrderPost', 'cpproId', cpproId)
            const localVarPath = `/erp/fjpartner/order/{cpproId}/createExtraACOrder`
                .replace(`{${"cpproId"}}`, encodeURIComponent(String(cpproId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (test !== undefined) {
                localVarQueryParameter['test'] = test;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [test] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpFjpartnerOrderCpproIdInvoiceExtraACOrderPost: async (cpproId: string, test?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproId' is not null or undefined
            assertParamExists('erpFjpartnerOrderCpproIdInvoiceExtraACOrderPost', 'cpproId', cpproId)
            const localVarPath = `/erp/fjpartner/order/{cpproId}/invoiceExtraACOrder`
                .replace(`{${"cpproId"}}`, encodeURIComponent(String(cpproId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (test !== undefined) {
                localVarQueryParameter['test'] = test;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproPartnerProgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpFjpartnerOrderCpproPartnerProgIdCreateCPProSalesOrderPost: async (cpproPartnerProgId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproPartnerProgId' is not null or undefined
            assertParamExists('erpFjpartnerOrderCpproPartnerProgIdCreateCPProSalesOrderPost', 'cpproPartnerProgId', cpproPartnerProgId)
            const localVarPath = `/erp/fjpartner/order/{cpproPartnerProgId}/createCPProSalesOrder`
                .replace(`{${"cpproPartnerProgId"}}`, encodeURIComponent(String(cpproPartnerProgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproPartnerProgId 
         * @param {string} [acCPProId] 
         * @param {string} [dryRun] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpFjpartnerOrderCpproPartnerProgIdFindMRPOrderPost: async (cpproPartnerProgId: string, acCPProId?: string, dryRun?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproPartnerProgId' is not null or undefined
            assertParamExists('erpFjpartnerOrderCpproPartnerProgIdFindMRPOrderPost', 'cpproPartnerProgId', cpproPartnerProgId)
            const localVarPath = `/erp/fjpartner/order/{cpproPartnerProgId}/findMRPOrder`
                .replace(`{${"cpproPartnerProgId"}}`, encodeURIComponent(String(cpproPartnerProgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (acCPProId !== undefined) {
                localVarQueryParameter['acCPProId'] = acCPProId;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproPartnerProgId 
         * @param {string} [acCPProId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpFjpartnerOrderCpproPartnerProgIdInstallCompleteWhenInstallerIsACPost: async (cpproPartnerProgId: string, acCPProId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproPartnerProgId' is not null or undefined
            assertParamExists('erpFjpartnerOrderCpproPartnerProgIdInstallCompleteWhenInstallerIsACPost', 'cpproPartnerProgId', cpproPartnerProgId)
            const localVarPath = `/erp/fjpartner/order/{cpproPartnerProgId}/installCompleteWhenInstallerIsAC`
                .replace(`{${"cpproPartnerProgId"}}`, encodeURIComponent(String(cpproPartnerProgId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (acCPProId !== undefined) {
                localVarQueryParameter['acCPProId'] = acCPProId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpFjpartnerSyncPost: async (orderId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/erp/fjpartner/sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} [test] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpManualOrderOrderIdAutomateOrderPost: async (orderId: string, test?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('erpManualOrderOrderIdAutomateOrderPost', 'orderId', orderId)
            const localVarPath = `/erp/manualOrder/{orderId}/automateOrder`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (test !== undefined) {
                localVarQueryParameter['test'] = test;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} [test] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpManualOrderOrderIdInvoice24SOPost: async (orderId: string, test?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('erpManualOrderOrderIdInvoice24SOPost', 'orderId', orderId)
            const localVarPath = `/erp/manualOrder/{orderId}/invoice24SO`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (test !== undefined) {
                localVarQueryParameter['test'] = test;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpMrpFindMRPOrderCpproIdGet: async (cpproId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproId' is not null or undefined
            assertParamExists('erpMrpFindMRPOrderCpproIdGet', 'cpproId', cpproId)
            const localVarPath = `/erp/mrp/findMRPOrder/{cpproId}`
                .replace(`{${"cpproId"}}`, encodeURIComponent(String(cpproId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpMrpInvoiceOrderCpproIdPost: async (cpproId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproId' is not null or undefined
            assertParamExists('erpMrpInvoiceOrderCpproIdPost', 'cpproId', cpproId)
            const localVarPath = `/erp/mrp/invoiceOrder/{cpproId}`
                .replace(`{${"cpproId"}}`, encodeURIComponent(String(cpproId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [dryRun] 
         * @param {string} [manual] 
         * @param {string} [xCloudtasksTaskretrycount] 
         * @param {string} [xCloudtasksTaskexecutioncount] 
         * @param {string} [xCloudtasksTasketa] 
         * @param {string} [xCloudtasksQueuename] 
         * @param {string} [xCloudtasksTaskname] 
         * @param {string} [xCloudtasksTaskpreviousresponse] 
         * @param {string} [xCloudtasksTaskretryreason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpMrpPickOrderCpproIdPost: async (cpproId: string, dryRun?: string, manual?: string, xCloudtasksTaskretrycount?: string, xCloudtasksTaskexecutioncount?: string, xCloudtasksTasketa?: string, xCloudtasksQueuename?: string, xCloudtasksTaskname?: string, xCloudtasksTaskpreviousresponse?: string, xCloudtasksTaskretryreason?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproId' is not null or undefined
            assertParamExists('erpMrpPickOrderCpproIdPost', 'cpproId', cpproId)
            const localVarPath = `/erp/mrp/pickOrder/{cpproId}`
                .replace(`{${"cpproId"}}`, encodeURIComponent(String(cpproId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (manual !== undefined) {
                localVarQueryParameter['manual'] = manual;
            }


    
            if (xCloudtasksTaskretrycount != null) {
                localVarHeaderParameter['x-cloudtasks-taskretrycount'] = String(xCloudtasksTaskretrycount);
            }
            if (xCloudtasksTaskexecutioncount != null) {
                localVarHeaderParameter['x-cloudtasks-taskexecutioncount'] = String(xCloudtasksTaskexecutioncount);
            }
            if (xCloudtasksTasketa != null) {
                localVarHeaderParameter['x-cloudtasks-tasketa'] = String(xCloudtasksTasketa);
            }
            if (xCloudtasksQueuename != null) {
                localVarHeaderParameter['x-cloudtasks-queuename'] = String(xCloudtasksQueuename);
            }
            if (xCloudtasksTaskname != null) {
                localVarHeaderParameter['x-cloudtasks-taskname'] = String(xCloudtasksTaskname);
            }
            if (xCloudtasksTaskpreviousresponse != null) {
                localVarHeaderParameter['x-cloudtasks-taskpreviousresponse'] = String(xCloudtasksTaskpreviousresponse);
            }
            if (xCloudtasksTaskretryreason != null) {
                localVarHeaderParameter['x-cloudtasks-taskretryreason'] = String(xCloudtasksTaskretryreason);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpMrpShipOrderCpproIdPost: async (cpproId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproId' is not null or undefined
            assertParamExists('erpMrpShipOrderCpproIdPost', 'cpproId', cpproId)
            const localVarPath = `/erp/mrp/shipOrder/{cpproId}`
                .replace(`{${"cpproId"}}`, encodeURIComponent(String(cpproId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpOrderCpproIdActivityhelperlocalInsertCustomerAndOrderPost: async (cpproId: string, type?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproId' is not null or undefined
            assertParamExists('erpOrderCpproIdActivityhelperlocalInsertCustomerAndOrderPost', 'cpproId', cpproId)
            const localVarPath = `/erp/order/{cpproId}/activityhelperlocal/insertCustomerAndOrder`
                .replace(`{${"cpproId"}}`, encodeURIComponent(String(cpproId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [type] 
         * @param {string} [dryRun] 
         * @param {string} [fjOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpOrderCpproIdCRMAddCustomerAndOrderPost: async (cpproId: string, type?: string, dryRun?: string, fjOrder?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproId' is not null or undefined
            assertParamExists('erpOrderCpproIdCRMAddCustomerAndOrderPost', 'cpproId', cpproId)
            const localVarPath = `/erp/order/{cpproId}/CRM/addCustomerAndOrder`
                .replace(`{${"cpproId"}}`, encodeURIComponent(String(cpproId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fjOrder !== undefined) {
                localVarQueryParameter['fjOrder'] = fjOrder;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpOrderCpproIdCalendarCreateServiceEventPost: async (cpproId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproId' is not null or undefined
            assertParamExists('erpOrderCpproIdCalendarCreateServiceEventPost', 'cpproId', cpproId)
            const localVarPath = `/erp/order/{cpproId}/calendar/createServiceEvent`
                .replace(`{${"cpproId"}}`, encodeURIComponent(String(cpproId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [dryRun] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpOrderCpproIdCreditSaleMrpInvoicedPost: async (cpproId: string, dryRun?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproId' is not null or undefined
            assertParamExists('erpOrderCpproIdCreditSaleMrpInvoicedPost', 'cpproId', cpproId)
            const localVarPath = `/erp/order/{cpproId}/creditSale/mrpInvoiced`
                .replace(`{${"cpproId"}}`, encodeURIComponent(String(cpproId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpOrderCpproIdInvoiceFJFinalOrderPost: async (cpproId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproId' is not null or undefined
            assertParamExists('erpOrderCpproIdInvoiceFJFinalOrderPost', 'cpproId', cpproId)
            const localVarPath = `/erp/order/{cpproId}/invoiceFJFinalOrder`
                .replace(`{${"cpproId"}}`, encodeURIComponent(String(cpproId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [type] 
         * @param {string} [dryRun] 
         * @param {string} [fjOrder] 
         * @param {string} [includeVAT] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpOrderCpproIdLegacyServerAddOrderPost: async (cpproId: string, type?: string, dryRun?: string, fjOrder?: string, includeVAT?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproId' is not null or undefined
            assertParamExists('erpOrderCpproIdLegacyServerAddOrderPost', 'cpproId', cpproId)
            const localVarPath = `/erp/order/{cpproId}/legacyServer/addOrder`
                .replace(`{${"cpproId"}}`, encodeURIComponent(String(cpproId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (fjOrder !== undefined) {
                localVarQueryParameter['fjOrder'] = fjOrder;
            }

            if (includeVAT !== undefined) {
                localVarQueryParameter['includeVAT'] = includeVAT;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpOrderCpproIdRepairMrpInvoicedPost: async (cpproId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproId' is not null or undefined
            assertParamExists('erpOrderCpproIdRepairMrpInvoicedPost', 'cpproId', cpproId)
            const localVarPath = `/erp/order/{cpproId}/repair/mrpInvoiced`
                .replace(`{${"cpproId"}}`, encodeURIComponent(String(cpproId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpOrderCpproIdSpreadsheetUpdate20502038Post: async (cpproId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproId' is not null or undefined
            assertParamExists('erpOrderCpproIdSpreadsheetUpdate20502038Post', 'cpproId', cpproId)
            const localVarPath = `/erp/order/{cpproId}/spreadsheet/update20502038`
                .replace(`{${"cpproId"}}`, encodeURIComponent(String(cpproId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [external] 
         * @param {string} [identity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpOrderIdOrderlinesPost: async (id: string, external?: string, identity?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('erpOrderIdOrderlinesPost', 'id', id)
            const localVarPath = `/erp/order/{id}/orderlines`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (external !== undefined) {
                localVarQueryParameter['external'] = external;
            }

            if (identity !== undefined) {
                localVarQueryParameter['identity'] = identity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} [isACErpId] 
         * @param {string} [ignoreSMS] 
         * @param {string} [isACT] 
         * @param {string} [test] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpOrderOrderIdSmsSendInvoiceLinkPost: async (orderId: string, isACErpId?: string, ignoreSMS?: string, isACT?: string, test?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('erpOrderOrderIdSmsSendInvoiceLinkPost', 'orderId', orderId)
            const localVarPath = `/erp/order/{orderId}/sms/sendInvoiceLink`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (isACErpId !== undefined) {
                localVarQueryParameter['isACErpId'] = isACErpId;
            }

            if (ignoreSMS !== undefined) {
                localVarQueryParameter['ignoreSMS'] = ignoreSMS;
            }

            if (isACT !== undefined) {
                localVarQueryParameter['isACT'] = isACT;
            }

            if (test !== undefined) {
                localVarQueryParameter['test'] = test;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpOrderPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/erp/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpRepairInvoiceACOrderCpproIdGet: async (cpproId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproId' is not null or undefined
            assertParamExists('erpRepairInvoiceACOrderCpproIdGet', 'cpproId', cpproId)
            const localVarPath = `/erp/repair/invoiceAC/order/{cpproId}`
                .replace(`{${"cpproId"}}`, encodeURIComponent(String(cpproId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [changedAfter] 
         * @param {string} [fjOrderId] 
         * @param {string} [test] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpScheduleFjFindNewOrdersPost: async (changedAfter?: string, fjOrderId?: string, test?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/erp/schedule/fj/findNewOrders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (changedAfter !== undefined) {
                localVarQueryParameter['changedAfter'] = changedAfter;
            }

            if (fjOrderId !== undefined) {
                localVarQueryParameter['fjOrderId'] = fjOrderId;
            }

            if (test !== undefined) {
                localVarQueryParameter['test'] = test;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [test] 
         * @param {string} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpScheduleManualOrderFindManualOrdersPost: async (test?: string, orderId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/erp/schedule/manualOrder/findManualOrders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (test !== undefined) {
                localVarQueryParameter['test'] = test;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['orderId'] = orderId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [disableDelete] 
         * @param {string} [dryRun] 
         * @param {string} [itemNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpScheduleMrpSyncProductsToCPProPost: async (disableDelete?: string, dryRun?: string, itemNumber?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/erp/schedule/mrp/syncProductsToCPPro`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (disableDelete !== undefined) {
                localVarQueryParameter['disableDelete'] = disableDelete;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (itemNumber !== undefined) {
                localVarQueryParameter['itemNumber'] = itemNumber;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [demo] 
         * @param {string} [act] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpScheduleOrderFindInvoicedOrdersPost: async (demo?: string, act?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/erp/schedule/order/findInvoicedOrders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (demo !== undefined) {
                localVarQueryParameter['demo'] = demo;
            }

            if (act !== undefined) {
                localVarQueryParameter['act'] = act;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [cpproId] 
         * @param {string} [dryRun] 
         * @param {string} [manual] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpScheduleOrderWaitingForChecklistPost: async (cpproId?: string, dryRun?: string, manual?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/erp/schedule/order/waitingForChecklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (cpproId !== undefined) {
                localVarQueryParameter['cpproId'] = cpproId;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (manual !== undefined) {
                localVarQueryParameter['manual'] = manual;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [event] 
         * @param {string} [optionalNow] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpScheduleSmsSendCalendarSMSRemindersPost: async (event?: string, optionalNow?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/erp/schedule/sms/sendCalendarSMSReminders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (event !== undefined) {
                localVarQueryParameter['event'] = event;
            }

            if (optionalNow !== undefined) {
                localVarQueryParameter['optionalNow'] = optionalNow;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dryRun] 
         * @param {string} [userEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpScheduleUsersSyncEmployeeInfoToCPProPost: async (dryRun?: string, userEmail?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/erp/schedule/users/syncEmployeeInfoToCPPro`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (userEmail !== undefined) {
                localVarQueryParameter['userEmail'] = userEmail;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [env] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpSync24SOToMRPGet: async (env?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/erp/sync/24SOToMRP`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (env !== undefined) {
                localVarQueryParameter['env'] = env;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fjordersErpAgreedInstallationDateOrderOrderidGet: async (orderid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderid' is not null or undefined
            assertParamExists('fjordersErpAgreedInstallationDateOrderOrderidGet', 'orderid', orderid)
            const localVarPath = `/fjorders/erp/agreedInstallationDate/order/{orderid}`
                .replace(`{${"orderid"}}`, encodeURIComponent(String(orderid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fjordersErpDeliveryaddressOrderOrderidGet: async (orderid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderid' is not null or undefined
            assertParamExists('fjordersErpDeliveryaddressOrderOrderidGet', 'orderid', orderid)
            const localVarPath = `/fjorders/erp/deliveryaddress/order/{orderid}`
                .replace(`{${"orderid"}}`, encodeURIComponent(String(orderid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fjordersErpInstallerJobAcceptedOrderOrderidGet: async (orderid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderid' is not null or undefined
            assertParamExists('fjordersErpInstallerJobAcceptedOrderOrderidGet', 'orderid', orderid)
            const localVarPath = `/fjorders/erp/installerJobAccepted/order/{orderid}`
                .replace(`{${"orderid"}}`, encodeURIComponent(String(orderid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fjordersErpInstallerOrderOrderidGet: async (orderid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderid' is not null or undefined
            assertParamExists('fjordersErpInstallerOrderOrderidGet', 'orderid', orderid)
            const localVarPath = `/fjorders/erp/installer/order/{orderid}`
                .replace(`{${"orderid"}}`, encodeURIComponent(String(orderid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fjordersErpUpdateDeliveryDateOrderOrderidGet: async (orderid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderid' is not null or undefined
            assertParamExists('fjordersErpUpdateDeliveryDateOrderOrderidGet', 'orderid', orderid)
            const localVarPath = `/fjorders/erp/updateDeliveryDate/order/{orderid}`
                .replace(`{${"orderid"}}`, encodeURIComponent(String(orderid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} messageid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fjordersGmailMessageMessageidGet: async (messageid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'messageid' is not null or undefined
            assertParamExists('fjordersGmailMessageMessageidGet', 'messageid', messageid)
            const localVarPath = `/fjorders/gmail/message/{messageid}`
                .replace(`{${"messageid"}}`, encodeURIComponent(String(messageid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fjordersInstallationCompleteConfirmationOrderOrderidGet: async (orderid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderid' is not null or undefined
            assertParamExists('fjordersInstallationCompleteConfirmationOrderOrderidGet', 'orderid', orderid)
            const localVarPath = `/fjorders/installationCompleteConfirmation/order/{orderid}`
                .replace(`{${"orderid"}}`, encodeURIComponent(String(orderid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fjordersOrderAllFixEmailMessageIdsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/fjorders/order/all/fixEmailMessageIds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fjordersOrderAllInstallationCompleteConfirmationGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/fjorders/order/all/installationCompleteConfirmation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fjordersOrderOrderidFixEmailMessageIdGet: async (orderid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderid' is not null or undefined
            assertParamExists('fjordersOrderOrderidFixEmailMessageIdGet', 'orderid', orderid)
            const localVarPath = `/fjorders/order/{orderid}/fixEmailMessageId`
                .replace(`{${"orderid"}}`, encodeURIComponent(String(orderid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fjordersOrderOrderidGet: async (orderid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderid' is not null or undefined
            assertParamExists('fjordersOrderOrderidGet', 'orderid', orderid)
            const localVarPath = `/fjorders/order/{orderid}`
                .replace(`{${"orderid"}}`, encodeURIComponent(String(orderid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fjordersOrderReferenceReferenceIdGet: async (referenceId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'referenceId' is not null or undefined
            assertParamExists('fjordersOrderReferenceReferenceIdGet', 'referenceId', referenceId)
            const localVarPath = `/fjorders/order/reference/{referenceId}`
                .replace(`{${"referenceId"}}`, encodeURIComponent(String(referenceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [includeFinished] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fjordersOrdersCpproSyncGet: async (includeFinished?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/fjorders/orders/cpproSync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeFinished !== undefined) {
                localVarQueryParameter['includeFinished'] = includeFinished;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fjordersOrdersCpproSyncOnlyDataGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/fjorders/orders/cpproSync/onlyData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fjordersReferenceReferenceFiksMonteringUtfortPost: async (reference: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reference' is not null or undefined
            assertParamExists('fjordersReferenceReferenceFiksMonteringUtfortPost', 'reference', reference)
            const localVarPath = `/fjorders/reference/{reference}/fiksMonteringUtfort`
                .replace(`{${"reference"}}`, encodeURIComponent(String(reference)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fjordersUpdateProductOrderOrderidGet: async (orderid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderid' is not null or undefined
            assertParamExists('fjordersUpdateProductOrderOrderidGet', 'orderid', orderid)
            const localVarPath = `/fjorders/updateProduct/order/{orderid}`
                .replace(`{${"orderid"}}`, encodeURIComponent(String(orderid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleCloudVisionTextDetectionPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/google/cloudVision/textDetection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [test] 
         * @param {string} [dryRun] 
         * @param {string} [dryrun] 
         * @param {string} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadManagementScheduleMoveLeadsPost: async (test?: string, dryRun?: string, dryrun?: string, limit?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/leadManagement/schedule/moveLeads`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (test !== undefined) {
                localVarQueryParameter['test'] = test;
            }

            if (dryRun !== undefined) {
                localVarQueryParameter['dryRun'] = dryRun;
            }

            if (dryrun !== undefined) {
                localVarQueryParameter['dryrun'] = dryrun;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadManagementVendorPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/leadManagement/vendor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadManagementVendorUserIdDelete: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('leadManagementVendorUserIdDelete', 'userId', userId)
            const localVarPath = `/leadManagement/vendor/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadManagementVendorUserIdPatch: async (userId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('leadManagementVendorUserIdPatch', 'userId', userId)
            const localVarPath = `/leadManagement/vendor/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadManagementVendorsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/leadManagement/vendors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {GetIncidentsCommand} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertIncidentGet: async (authorization?: string, query?: GetIncidentsCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/monitor/alert/incident`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                for (const [key, value] of Object.entries(query)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertIncidentIdDelete: async (id: string, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('monitorAlertIncidentIdDelete', 'id', id)
            const localVarPath = `/monitor/alert/incident/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAlertPolicyIncidentCommand} updateAlertPolicyIncidentCommand 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertIncidentIdPut: async (id: string, updateAlertPolicyIncidentCommand: UpdateAlertPolicyIncidentCommand, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('monitorAlertIncidentIdPut', 'id', id)
            // verify required parameter 'updateAlertPolicyIncidentCommand' is not null or undefined
            assertParamExists('monitorAlertIncidentIdPut', 'updateAlertPolicyIncidentCommand', updateAlertPolicyIncidentCommand)
            const localVarPath = `/monitor/alert/incident/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAlertPolicyIncidentCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PubSubPushRequest} pubSubPushRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertIncidentPubsubPost: async (pubSubPushRequest: PubSubPushRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pubSubPushRequest' is not null or undefined
            assertParamExists('monitorAlertIncidentPubsubPost', 'pubSubPushRequest', pubSubPushRequest)
            const localVarPath = `/monitor/alert/incident/pubsub`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pubSubPushRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertNotificationChannelGet: async (authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/monitor/alert/notificationChannel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertNotificationChannelIdDelete: async (id: string, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('monitorAlertNotificationChannelIdDelete', 'id', id)
            const localVarPath = `/monitor/alert/notificationChannel/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateNotificationChannelCommand} updateNotificationChannelCommand 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertNotificationChannelIdPut: async (id: string, updateNotificationChannelCommand: UpdateNotificationChannelCommand, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('monitorAlertNotificationChannelIdPut', 'id', id)
            // verify required parameter 'updateNotificationChannelCommand' is not null or undefined
            assertParamExists('monitorAlertNotificationChannelIdPut', 'updateNotificationChannelCommand', updateNotificationChannelCommand)
            const localVarPath = `/monitor/alert/notificationChannel/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNotificationChannelCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateNotificationChannelCommand} createNotificationChannelCommand 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertNotificationChannelPost: async (createNotificationChannelCommand: CreateNotificationChannelCommand, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createNotificationChannelCommand' is not null or undefined
            assertParamExists('monitorAlertNotificationChannelPost', 'createNotificationChannelCommand', createNotificationChannelCommand)
            const localVarPath = `/monitor/alert/notificationChannel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNotificationChannelCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertPolicyGet: async (authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/monitor/alert/policy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertPolicyIdDelete: async (id: string, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('monitorAlertPolicyIdDelete', 'id', id)
            const localVarPath = `/monitor/alert/policy/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAlertPolicyCommand} updateAlertPolicyCommand 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertPolicyIdPut: async (id: string, updateAlertPolicyCommand: UpdateAlertPolicyCommand, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('monitorAlertPolicyIdPut', 'id', id)
            // verify required parameter 'updateAlertPolicyCommand' is not null or undefined
            assertParamExists('monitorAlertPolicyIdPut', 'updateAlertPolicyCommand', updateAlertPolicyCommand)
            const localVarPath = `/monitor/alert/policy/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAlertPolicyCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} policyId 
         * @param {string} channelId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertPolicyPolicyIdChannelChannelIdDelete: async (policyId: string, channelId: string, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'policyId' is not null or undefined
            assertParamExists('monitorAlertPolicyPolicyIdChannelChannelIdDelete', 'policyId', policyId)
            // verify required parameter 'channelId' is not null or undefined
            assertParamExists('monitorAlertPolicyPolicyIdChannelChannelIdDelete', 'channelId', channelId)
            const localVarPath = `/monitor/alert/policy/{policyId}/channel/{channelId}`
                .replace(`{${"policyId"}}`, encodeURIComponent(String(policyId)))
                .replace(`{${"channelId"}}`, encodeURIComponent(String(channelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} policyId 
         * @param {string} channelId 
         * @param {CreatePolicyNotificationCommand} createPolicyNotificationCommand 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertPolicyPolicyIdChannelChannelIdPost: async (policyId: string, channelId: string, createPolicyNotificationCommand: CreatePolicyNotificationCommand, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'policyId' is not null or undefined
            assertParamExists('monitorAlertPolicyPolicyIdChannelChannelIdPost', 'policyId', policyId)
            // verify required parameter 'channelId' is not null or undefined
            assertParamExists('monitorAlertPolicyPolicyIdChannelChannelIdPost', 'channelId', channelId)
            // verify required parameter 'createPolicyNotificationCommand' is not null or undefined
            assertParamExists('monitorAlertPolicyPolicyIdChannelChannelIdPost', 'createPolicyNotificationCommand', createPolicyNotificationCommand)
            const localVarPath = `/monitor/alert/policy/{policyId}/channel/{channelId}`
                .replace(`{${"policyId"}}`, encodeURIComponent(String(policyId)))
                .replace(`{${"channelId"}}`, encodeURIComponent(String(channelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPolicyNotificationCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} policyId 
         * @param {string} channelId 
         * @param {UpdatePolicyNotificationCommand} updatePolicyNotificationCommand 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertPolicyPolicyIdChannelChannelIdPut: async (policyId: string, channelId: string, updatePolicyNotificationCommand: UpdatePolicyNotificationCommand, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'policyId' is not null or undefined
            assertParamExists('monitorAlertPolicyPolicyIdChannelChannelIdPut', 'policyId', policyId)
            // verify required parameter 'channelId' is not null or undefined
            assertParamExists('monitorAlertPolicyPolicyIdChannelChannelIdPut', 'channelId', channelId)
            // verify required parameter 'updatePolicyNotificationCommand' is not null or undefined
            assertParamExists('monitorAlertPolicyPolicyIdChannelChannelIdPut', 'updatePolicyNotificationCommand', updatePolicyNotificationCommand)
            const localVarPath = `/monitor/alert/policy/{policyId}/channel/{channelId}`
                .replace(`{${"policyId"}}`, encodeURIComponent(String(policyId)))
                .replace(`{${"channelId"}}`, encodeURIComponent(String(channelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePolicyNotificationCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} policyId 
         * @param {string} [authorization] 
         * @param {GetIncidentsCommand} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertPolicyPolicyIdIncidentGet: async (policyId: string, authorization?: string, query?: GetIncidentsCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'policyId' is not null or undefined
            assertParamExists('monitorAlertPolicyPolicyIdIncidentGet', 'policyId', policyId)
            const localVarPath = `/monitor/alert/policy/{policyId}/incident`
                .replace(`{${"policyId"}}`, encodeURIComponent(String(policyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                for (const [key, value] of Object.entries(query)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} policyId 
         * @param {CreateAlertPolicyIncidentCommand} createAlertPolicyIncidentCommand 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertPolicyPolicyIdIncidentPost: async (policyId: string, createAlertPolicyIncidentCommand: CreateAlertPolicyIncidentCommand, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'policyId' is not null or undefined
            assertParamExists('monitorAlertPolicyPolicyIdIncidentPost', 'policyId', policyId)
            // verify required parameter 'createAlertPolicyIncidentCommand' is not null or undefined
            assertParamExists('monitorAlertPolicyPolicyIdIncidentPost', 'createAlertPolicyIncidentCommand', createAlertPolicyIncidentCommand)
            const localVarPath = `/monitor/alert/policy/{policyId}/incident`
                .replace(`{${"policyId"}}`, encodeURIComponent(String(policyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAlertPolicyIncidentCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAlertPolicyCommand} createAlertPolicyCommand 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertPolicyPost: async (createAlertPolicyCommand: CreateAlertPolicyCommand, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAlertPolicyCommand' is not null or undefined
            assertParamExists('monitorAlertPolicyPost', 'createAlertPolicyCommand', createAlertPolicyCommand)
            const localVarPath = `/monitor/alert/policy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAlertPolicyCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertSyncPolicyPost: async (authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/monitor/alert/sync/policy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {GetEmailsCommand} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorEmailsGet: async (authorization?: string, query?: GetEmailsCommand, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/monitor/emails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                for (const [key, value] of Object.entries(query)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DateCommand} dateCommand 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorErpApiErrorsGet: async (dateCommand: DateCommand, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateCommand' is not null or undefined
            assertParamExists('monitorErpApiErrorsGet', 'dateCommand', dateCommand)
            const localVarPath = `/monitor/erp/apiErrors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {GetErpErrorRequestQuery} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorErpErrorsGet: async (authorization?: string, query?: GetErpErrorRequestQuery, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/monitor/erp/errors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                for (const [key, value] of Object.entries(query)) {
                    localVarQueryParameter[key] = value;
                }
            }


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DateCommand} dateCommand 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorLeadLogsValidationErrorsGet: async (dateCommand: DateCommand, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateCommand' is not null or undefined
            assertParamExists('monitorLeadLogsValidationErrorsGet', 'dateCommand', dateCommand)
            const localVarPath = `/monitor/lead/logs/validationErrors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DateCommand} dateCommand 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorLeadRequestStatusCodesGet: async (dateCommand: DateCommand, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateCommand' is not null or undefined
            assertParamExists('monitorLeadRequestStatusCodesGet', 'dateCommand', dateCommand)
            const localVarPath = `/monitor/lead/requestStatusCodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DateCommand} dateCommand 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorLeadVendorsGet: async (dateCommand: DateCommand, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateCommand' is not null or undefined
            assertParamExists('monitorLeadVendorsGet', 'dateCommand', dateCommand)
            const localVarPath = `/monitor/lead/vendors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetSMSesCommand} getSMSesCommand 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorSmsGet: async (getSMSesCommand: GetSMSesCommand, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getSMSesCommand' is not null or undefined
            assertParamExists('monitorSmsGet', 'getSMSesCommand', getSMSesCommand)
            const localVarPath = `/monitor/sms`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getSMSesCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorTasksQueuesWithTasksGet: async (authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/monitor/tasks/queuesWithTasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TaskPathCommand} taskPathCommand 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorTasksTaskDelete: async (taskPathCommand: TaskPathCommand, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskPathCommand' is not null or undefined
            assertParamExists('monitorTasksTaskDelete', 'taskPathCommand', taskPathCommand)
            const localVarPath = `/monitor/tasks/task`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskPathCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TaskPathCommand} taskPathCommand 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorTasksTaskRunPost: async (taskPathCommand: TaskPathCommand, authorization?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'taskPathCommand' is not null or undefined
            assertParamExists('monitorTasksTaskRunPost', 'taskPathCommand', taskPathCommand)
            const localVarPath = `/monitor/tasks/task/run`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            if (authorization != null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(taskPathCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderconfirmationOrderOrderidGet: async (orderid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderid' is not null or undefined
            assertParamExists('orderconfirmationOrderOrderidGet', 'orderid', orderid)
            const localVarPath = `/orderconfirmation/order/{orderid}`
                .replace(`{${"orderid"}}`, encodeURIComponent(String(orderid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerCreateOrderPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partner/createOrder`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerHealthGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/partner/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} partnerReference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerOrdersPartnerReferenceGet: async (partnerReference: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerReference' is not null or undefined
            assertParamExists('partnerOrdersPartnerReferenceGet', 'partnerReference', partnerReference)
            const localVarPath = `/partner/orders/{partnerReference}`
                .replace(`{${"partnerReference"}}`, encodeURIComponent(String(partnerReference)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} partnerReference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerWebhooksPartnerReferenceDelete: async (partnerReference: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerReference' is not null or undefined
            assertParamExists('partnerWebhooksPartnerReferenceDelete', 'partnerReference', partnerReference)
            const localVarPath = `/partner/webhooks/{partnerReference}`
                .replace(`{${"partnerReference"}}`, encodeURIComponent(String(partnerReference)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} partnerReference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerWebhooksPartnerReferencePost: async (partnerReference: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerReference' is not null or undefined
            assertParamExists('partnerWebhooksPartnerReferencePost', 'partnerReference', partnerReference)
            const localVarPath = `/partner/webhooks/{partnerReference}`
                .replace(`{${"partnerReference"}}`, encodeURIComponent(String(partnerReference)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} partnerReference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerWebhooksPartnerReferencePut: async (partnerReference: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'partnerReference' is not null or undefined
            assertParamExists('partnerWebhooksPartnerReferencePut', 'partnerReference', partnerReference)
            const localVarPath = `/partner/webhooks/{partnerReference}`
                .replace(`{${"partnerReference"}}`, encodeURIComponent(String(partnerReference)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xGoogResourceState] 
         * @param {string} [xGoogChannelExpiration] 
         * @param {string} [xGoogChannelToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicCalendarNotificationHandlePost: async (xGoogResourceState?: string, xGoogChannelExpiration?: string, xGoogChannelToken?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/calendar/notification/handle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            if (xGoogResourceState != null) {
                localVarHeaderParameter['x-goog-resource-state'] = String(xGoogResourceState);
            }
            if (xGoogChannelExpiration != null) {
                localVarHeaderParameter['x-goog-channel-expiration'] = String(xGoogChannelExpiration);
            }
            if (xGoogChannelToken != null) {
                localVarHeaderParameter['x-goog-channel-token'] = String(xGoogChannelToken);
            }
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicSMSHandleReplyPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/public/SMS/handleReply`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        quoteOrderOrderidGet: async (orderid: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderid' is not null or undefined
            assertParamExists('quoteOrderOrderidGet', 'orderid', orderid)
            const localVarPath = `/quote/order/{orderid}`
                .replace(`{${"orderid"}}`, encodeURIComponent(String(orderid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smsInsertPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sms/insert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} guid 
         * @param {string} [test] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smsSendMultipleGuidPost: async (guid: string, test?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'guid' is not null or undefined
            assertParamExists('smsSendMultipleGuidPost', 'guid', guid)
            const localVarPath = `/sms/send/multiple/{guid}`
                .replace(`{${"guid"}}`, encodeURIComponent(String(guid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (test !== undefined) {
                localVarQueryParameter['test'] = test;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} smsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smsSendSmsIdPost: async (smsId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'smsId' is not null or undefined
            assertParamExists('smsSendSmsIdPost', 'smsId', smsId)
            const localVarPath = `/sms/send/{smsId}`
                .replace(`{${"smsId"}}`, encodeURIComponent(String(smsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swaggerJsonGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/swagger.json`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAllCPProGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sync/allCPPro`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAllGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sync/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncCampaignCodeGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sync/campaignCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncConstantGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sync/constant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncCpproGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sync/cppro`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncInstallerGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sync/installer`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncLeadGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sync/lead`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} cpproOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncOrderCpproOrderIdPost: async (cpproOrderId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'cpproOrderId' is not null or undefined
            assertParamExists('syncOrderCpproOrderIdPost', 'cpproOrderId', cpproOrderId)
            const localVarPath = `/sync/order/{cpproOrderId}`
                .replace(`{${"cpproOrderId"}}`, encodeURIComponent(String(cpproOrderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncOrderGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sync/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncOrdersAllGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sync/orders/all`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncOrdersAllInstallersGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sync/orders/all/installers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncOrdersAllMasterGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sync/orders/all/master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncOrdersAllPartnersGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sync/orders/all/partners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncOrdersCompletedGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sync/orders/completed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncOrdersCompletedInstallersGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sync/orders/completed/installers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncOrdersCompletedMasterGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sync/orders/completed/master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncOrdersCompletedPartnersGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sync/orders/completed/partners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncOrdersInstallersGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sync/orders/installers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncOrdersMasterGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sync/orders/master`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncOrdersPartnersGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sync/orders/partners`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncParameterGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sync/parameter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncPartnerGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sync/partner`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncProductGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sync/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncShareInstallerSheetsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/sync/shareInstallerSheets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksCheckTasksPost: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/tasks/checkTasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testRequestipsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/test/requestips`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testerrorGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/testerror`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testerrornotifyGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/testerrornotify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} customerId 
         * @param {PostCRMCustomerAquireLockRequest} postCRMCustomerAquireLockRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCRMCustomerCustomerIdAquireLockPost(customerId: string, postCRMCustomerAquireLockRequest: PostCRMCustomerAquireLockRequest, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CRMCustomerLockSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCRMCustomerCustomerIdAquireLockPost(customerId, postCRMCustomerAquireLockRequest, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiCRMCustomerCustomerIdAquireLockPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} customerId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCRMCustomerCustomerIdReleaseLockDelete(customerId: string, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CRMCustomerLockSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCRMCustomerCustomerIdReleaseLockDelete(customerId, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiCRMCustomerCustomerIdReleaseLockDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCRMCustomerLocksGet(authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCRMCustomerLocksResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCRMCustomerLocksGet(authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiCRMCustomerLocksGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCRMDepartmentAreasGet(authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDepartmentZipAreasResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCRMDepartmentAreasGet(authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiCRMDepartmentAreasGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCRMSMSTemplatesGet(authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCRMSMSTemplatesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCRMSMSTemplatesGet(authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiCRMSMSTemplatesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateNotificationChannelRequest} createNotificationChannelRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCalendarNotificationChannelPost(createNotificationChannelRequest: CreateNotificationChannelRequest, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCalendarNotificationChannelPost(createNotificationChannelRequest, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiCalendarNotificationChannelPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiConstantGet(authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConstantsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiConstantGet(authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiConstantGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} key 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiConstantKeyDelete(key: string, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiConstantKeyDelete(key, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiConstantKeyDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} key 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiConstantKeyGet(key: string, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetConstantResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiConstantKeyGet(key, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiConstantKeyGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} key 
         * @param {PostConstantRequest} postConstantRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiConstantKeyVersionPost(key: string, postConstantRequest: PostConstantRequest, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiConstantKeyVersionPost(key, postConstantRequest, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiConstantKeyVersionPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} key 
         * @param {string} version 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiConstantKeyVersionVersionDelete(key: string, version: string, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiConstantKeyVersionVersionDelete(key, version, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiConstantKeyVersionVersionDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} key 
         * @param {string} version 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiConstantKeyVersionVersionDisablePost(key: string, version: string, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiConstantKeyVersionVersionDisablePost(key, version, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiConstantKeyVersionVersionDisablePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} key 
         * @param {string} version 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiConstantKeyVersionVersionEnablePost(key: string, version: string, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiConstantKeyVersionVersionEnablePost(key, version, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiConstantKeyVersionVersionEnablePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PostConstantRequest} postConstantRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiConstantPost(postConstantRequest: PostConstantRequest, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiConstantPost(postConstantRequest, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiConstantPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} acErpId 
         * @param {string} [authorization] 
         * @param {string} [client] 
         * @param {string} [demo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCppro24soAcErpIdCreateOrderPost(acErpId: string, authorization?: string, client?: string, demo?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCppro24soAcErpIdCreateOrderPost(acErpId, authorization, client, demo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiCppro24soAcErpIdCreateOrderPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderid 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCpproCreateorderOrderOrderidPost(orderid: string, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCpproCreateorderOrderOrderidPost(orderid, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiCpproCreateorderOrderOrderidPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} [authorization] 
         * @param {string} [client] 
         * @param {string} [demo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCpproMamutOrderIdCreateOrderPost(orderId: string, authorization?: string, client?: string, demo?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCpproMamutOrderIdCreateOrderPost(orderId, authorization, client, demo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiCpproMamutOrderIdCreateOrderPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateSalesOrderChecklistRequest} createSalesOrderChecklistRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCpproOrderCreateSalesOrderChecklistPost(createSalesOrderChecklistRequest: CreateSalesOrderChecklistRequest, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCpproOrderCreateSalesOrderChecklistPost(createSalesOrderChecklistRequest, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiCpproOrderCreateSalesOrderChecklistPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateServiceOrderChecklistRequest} createServiceOrderChecklistRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCpproOrderCreateServiceOrderChecklistPost(createServiceOrderChecklistRequest: CreateServiceOrderChecklistRequest, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCpproOrderCreateServiceOrderChecklistPost(createServiceOrderChecklistRequest, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiCpproOrderCreateServiceOrderChecklistPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {string} [all] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDatePublicHolidaysGet(authorization?: string, all?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPublicHolidaysResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDatePublicHolidaysGet(authorization, all, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiDatePublicHolidaysGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PostPublicHolidaysRequest} postPublicHolidaysRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDatePublicHolidaysPost(postPublicHolidaysRequest: PostPublicHolidaysRequest, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDatePublicHolidaysPost(postPublicHolidaysRequest, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiDatePublicHolidaysPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocsInternalJsonGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocsInternalJsonGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiDocsInternalJsonGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDocsLeadJsonGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDocsLeadJsonGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiDocsLeadJsonGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {string} [externalId] 
         * @param {string} [name] 
         * @param {string} [isACT] 
         * @param {string} [test] 
         * @param {string} [search24SOAC] 
         * @param {string} [returnProps] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErp24soForhandlerSearchGet(authorization?: string, externalId?: string, name?: string, isACT?: string, test?: string, search24SOAC?: string, returnProps?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErp24soForhandlerSearchGet(authorization, externalId, name, isACT, test, search24SOAC, returnProps, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErp24soForhandlerSearchGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {ApiErpAcCalendarDeliveryDateChangePostRequest} [apiErpAcCalendarDeliveryDateChangePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErpAcCalendarDeliveryDateChangePost(authorization?: string, apiErpAcCalendarDeliveryDateChangePostRequest?: ApiErpAcCalendarDeliveryDateChangePostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErpAcCalendarDeliveryDateChangePost(authorization, apiErpAcCalendarDeliveryDateChangePostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErpAcCalendarDeliveryDateChangePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [authorization] 
         * @param {string} [dryRun] 
         * @param {string} [test] 
         * @param {string} [shouldDeleteTasks] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErpAcOrderCpproIdInactivatePost(cpproId: string, authorization?: string, dryRun?: string, test?: string, shouldDeleteTasks?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErpAcOrderCpproIdInactivatePost(cpproId, authorization, dryRun, test, shouldDeleteTasks, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErpAcOrderCpproIdInactivatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {GetErpSyncOrderCommand} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErpErpSyncOrderGet(authorization?: string, query?: GetErpSyncOrderCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetErpSyncOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErpErpSyncOrderGet(authorization, query, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErpErpSyncOrderGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {string} [startTime] 
         * @param {string} [endTime] 
         * @param {DateCommand} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErpErpSyncOrdersGet(authorization?: string, startTime?: string, endTime?: string, query?: DateCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetErpSyncOrdersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErpErpSyncOrdersGet(authorization, startTime, endTime, query, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErpErpSyncOrdersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {string} [ignoreCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErpInstallItemUsageGet(authorization?: string, ignoreCache?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetInstallItemUsageResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErpInstallItemUsageGet(authorization, ignoreCache, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErpInstallItemUsageGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} itemNumber 
         * @param {PostInstallItemUsageAlternativeRequest} postInstallItemUsageAlternativeRequest 
         * @param {string} [authorization] 
         * @param {string} [ignoreCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErpInstallItemUsageItemNumberAlternativePost(itemNumber: string, postInstallItemUsageAlternativeRequest: PostInstallItemUsageAlternativeRequest, authorization?: string, ignoreCache?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErpInstallItemUsageItemNumberAlternativePost(itemNumber, postInstallItemUsageAlternativeRequest, authorization, ignoreCache, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErpInstallItemUsageItemNumberAlternativePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} itemNumber 
         * @param {string} [authorization] 
         * @param {string} [ignoreCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErpInstallItemUsageItemNumberDelete(itemNumber: string, authorization?: string, ignoreCache?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErpInstallItemUsageItemNumberDelete(itemNumber, authorization, ignoreCache, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErpInstallItemUsageItemNumberDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} itemNumber 
         * @param {PutInstallItemUsageRequest} putInstallItemUsageRequest 
         * @param {string} [authorization] 
         * @param {string} [ignoreCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErpInstallItemUsageItemNumberPut(itemNumber: string, putInstallItemUsageRequest: PutInstallItemUsageRequest, authorization?: string, ignoreCache?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErpInstallItemUsageItemNumberPut(itemNumber, putInstallItemUsageRequest, authorization, ignoreCache, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErpInstallItemUsageItemNumberPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} itemUsageId 
         * @param {string} alternativeId 
         * @param {string} [authorization] 
         * @param {string} [ignoreCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdDelete(itemUsageId: string, alternativeId: string, authorization?: string, ignoreCache?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdDelete(itemUsageId, alternativeId, authorization, ignoreCache, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} itemUsageId 
         * @param {string} alternativeId 
         * @param {PutInstallItemUsageAlternativeRequest} putInstallItemUsageAlternativeRequest 
         * @param {string} [authorization] 
         * @param {string} [ignoreCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdPut(itemUsageId: string, alternativeId: string, putInstallItemUsageAlternativeRequest: PutInstallItemUsageAlternativeRequest, authorization?: string, ignoreCache?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdPut(itemUsageId, alternativeId, putInstallItemUsageAlternativeRequest, authorization, ignoreCache, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PostInstallItemUsageRequest} postInstallItemUsageRequest 
         * @param {string} [authorization] 
         * @param {string} [ignoreCache] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErpInstallItemUsagePost(postInstallItemUsageRequest: PostInstallItemUsageRequest, authorization?: string, ignoreCache?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErpInstallItemUsagePost(postInstallItemUsageRequest, authorization, ignoreCache, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErpInstallItemUsagePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} installCPproId 
         * @param {string} [authorization] 
         * @param {string} [continueProcess] 
         * @param {string} [dryRun] 
         * @param {string} [manual] 
         * @param {string} [enableDelete] 
         * @param {CommonQueryParams} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErpInstallOrderInstallCPproIdChecklistInstalledPost(installCPproId: string, authorization?: string, continueProcess?: string, dryRun?: string, manual?: string, enableDelete?: string, query?: CommonQueryParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostChecklistInstalledResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErpInstallOrderInstallCPproIdChecklistInstalledPost(installCPproId, authorization, continueProcess, dryRun, manual, enableDelete, query, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErpInstallOrderInstallCPproIdChecklistInstalledPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} itemNumber 
         * @param {string} [authorization] 
         * @param {string} [test] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErpMrpItemItemNumberStockGet(itemNumber: string, authorization?: string, test?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMRPStockResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErpMrpItemItemNumberStockGet(itemNumber, authorization, test, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErpMrpItemItemNumberStockGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproInstallId 
         * @param {string} [authorization] 
         * @param {string} [questionId] 
         * @param {string} [serialNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErpMrpOrderCpproInstallIdCheckSerialNumberGet(cpproInstallId: string, authorization?: string, questionId?: string, serialNumber?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErpMrpOrderCpproInstallIdCheckSerialNumberGet(cpproInstallId, authorization, questionId, serialNumber, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErpMrpOrderCpproInstallIdCheckSerialNumberGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErpMrpOrderOrderIdPickErrorsGet(orderId: string, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMrpPickErrorsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErpMrpOrderOrderIdPickErrorsGet(orderId, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErpMrpOrderOrderIdPickErrorsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {string} [startTime] 
         * @param {string} [endTime] 
         * @param {DateCommand} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErpMrpOrderStatisticsGet(authorization?: string, startTime?: string, endTime?: string, query?: DateCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMrpOrderStatisticsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErpMrpOrderStatisticsGet(authorization, startTime, endTime, query, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErpMrpOrderStatisticsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} deviationReasonId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErpMrpPickErrorDeviationReasonDeviationReasonIdDelete(deviationReasonId: string, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErpMrpPickErrorDeviationReasonDeviationReasonIdDelete(deviationReasonId, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErpMrpPickErrorDeviationReasonDeviationReasonIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} deviationReasonId 
         * @param {PutPostPickErrorDeviationReasonRequest} putPostPickErrorDeviationReasonRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErpMrpPickErrorDeviationReasonDeviationReasonIdPut(deviationReasonId: string, putPostPickErrorDeviationReasonRequest: PutPostPickErrorDeviationReasonRequest, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErpMrpPickErrorDeviationReasonDeviationReasonIdPut(deviationReasonId, putPostPickErrorDeviationReasonRequest, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErpMrpPickErrorDeviationReasonDeviationReasonIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PutPostPickErrorDeviationReasonRequest} putPostPickErrorDeviationReasonRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErpMrpPickErrorDeviationReasonPost(putPostPickErrorDeviationReasonRequest: PutPostPickErrorDeviationReasonRequest, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErpMrpPickErrorDeviationReasonPost(putPostPickErrorDeviationReasonRequest, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErpMrpPickErrorDeviationReasonPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErpMrpPickErrorDeviationReasonsGet(authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPickErrorDeviationReasonsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErpMrpPickErrorDeviationReasonsGet(authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErpMrpPickErrorDeviationReasonsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} pickErrorId 
         * @param {PutMrpPickErrorRequest} putMrpPickErrorRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErpMrpPickErrorPickErrorIdPut(pickErrorId: string, putMrpPickErrorRequest: PutMrpPickErrorRequest, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutMrpPickErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErpMrpPickErrorPickErrorIdPut(pickErrorId, putMrpPickErrorRequest, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErpMrpPickErrorPickErrorIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {string} [status] 
         * @param {string} [startTime] 
         * @param {string} [endTime] 
         * @param {GetMrpPickErrorsRequest} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErpMrpPickErrorsGet(authorization?: string, status?: string, startTime?: string, endTime?: string, query?: GetMrpPickErrorsRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMrpPickErrorsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErpMrpPickErrorsGet(authorization, status, startTime, endTime, query, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErpMrpPickErrorsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PutMrpPickErrorsRequest} putMrpPickErrorsRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErpMrpPickErrorsPut(putMrpPickErrorsRequest: PutMrpPickErrorsRequest, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PutMrpPickErrorsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErpMrpPickErrorsPut(putMrpPickErrorsRequest, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErpMrpPickErrorsPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PostCreateOrderRequest} postCreateOrderRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErpOrder24soCreateOrderTaskPost(postCreateOrderRequest: PostCreateOrderRequest, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErpOrder24soCreateOrderTaskPost(postCreateOrderRequest, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErpOrder24soCreateOrderTaskPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [authorization] 
         * @param {string} [dryRun] 
         * @param {string} [manual] 
         * @param {string} [enableDelete] 
         * @param {CommonQueryParams} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErpOrderCpproId24soACInvoicePost(cpproId: string, authorization?: string, dryRun?: string, manual?: string, enableDelete?: string, query?: CommonQueryParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostACInvoiceOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErpOrderCpproId24soACInvoicePost(cpproId, authorization, dryRun, manual, enableDelete, query, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErpOrderCpproId24soACInvoicePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [authorization] 
         * @param {string} [dryRun] 
         * @param {string} [manual] 
         * @param {string} [enableDelete] 
         * @param {CommonQueryParams} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErpOrderCpproId24soInactivateFJAndInvoiceACPost(cpproId: string, authorization?: string, dryRun?: string, manual?: string, enableDelete?: string, query?: CommonQueryParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostInactivateFJAndInvoiceACResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErpOrderCpproId24soInactivateFJAndInvoiceACPost(cpproId, authorization, dryRun, manual, enableDelete, query, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErpOrderCpproId24soInactivateFJAndInvoiceACPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErpOrderCpproIdCheckEnTurTilOrderInvoicedGet(cpproId: string, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErpOrderCpproIdCheckEnTurTilOrderInvoicedGet(cpproId, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErpOrderCpproIdCheckEnTurTilOrderInvoicedGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [authorization] 
         * @param {string} [dryRun] 
         * @param {string} [manual] 
         * @param {string} [enableDelete] 
         * @param {CommonQueryParams} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErpOrderCpproIdMrpFindMRPOrderPost(cpproId: string, authorization?: string, dryRun?: string, manual?: string, enableDelete?: string, query?: CommonQueryParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostFindMRPOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErpOrderCpproIdMrpFindMRPOrderPost(cpproId, authorization, dryRun, manual, enableDelete, query, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErpOrderCpproIdMrpFindMRPOrderPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [authorization] 
         * @param {string} [dryRun] 
         * @param {string} [manual] 
         * @param {string} [enableDelete] 
         * @param {CommonQueryParams} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErpOrderCpproIdMrpInvoicePost(cpproId: string, authorization?: string, dryRun?: string, manual?: string, enableDelete?: string, query?: CommonQueryParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostMRPInvoiceResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErpOrderCpproIdMrpInvoicePost(cpproId, authorization, dryRun, manual, enableDelete, query, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErpOrderCpproIdMrpInvoicePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {PostMRPManuallyInvoicedRequest} postMRPManuallyInvoicedRequest 
         * @param {string} [authorization] 
         * @param {CommonQueryParams} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErpOrderCpproIdMrpManuallyInvoicedPost(cpproId: string, postMRPManuallyInvoicedRequest: PostMRPManuallyInvoicedRequest, authorization?: string, query?: CommonQueryParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErpOrderCpproIdMrpManuallyInvoicedPost(cpproId, postMRPManuallyInvoicedRequest, authorization, query, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErpOrderCpproIdMrpManuallyInvoicedPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [authorization] 
         * @param {string} [dryRun] 
         * @param {string} [manual] 
         * @param {string} [enableDelete] 
         * @param {CommonQueryParams} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErpOrderCpproIdMrpPickPost(cpproId: string, authorization?: string, dryRun?: string, manual?: string, enableDelete?: string, query?: CommonQueryParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostMRPPickResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErpOrderCpproIdMrpPickPost(cpproId, authorization, dryRun, manual, enableDelete, query, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErpOrderCpproIdMrpPickPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [authorization] 
         * @param {CommonQueryParams} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErpOrderCpproIdMrpSalesOrderGet(cpproId: string, authorization?: string, query?: CommonQueryParams, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetMRPSalesOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErpOrderCpproIdMrpSalesOrderGet(cpproId, authorization, query, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErpOrderCpproIdMrpSalesOrderGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErpOrderCpproIdMrpShipPost(cpproId: string, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErpOrderCpproIdMrpShipPost(cpproId, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErpOrderCpproIdMrpShipPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [authorization] 
         * @param {string} [deliveryDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErpOrderCpproIdRecreateOrderPost(cpproId: string, authorization?: string, deliveryDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErpOrderCpproIdRecreateOrderPost(cpproId, authorization, deliveryDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErpOrderCpproIdRecreateOrderPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {string} [event] 
         * @param {string} [optionalNow] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiErpSmsSendCalendarRemindersPost(authorization?: string, event?: string, optionalNow?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiErpSmsSendCalendarRemindersPost(authorization, event, optionalNow, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiErpSmsSendCalendarRemindersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} customerId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSmsCustomerCustomerIdMessagesGet(customerId: string, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSMSMessagesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSmsCustomerCustomerIdMessagesGet(customerId, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiSmsCustomerCustomerIdMessagesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSmsIdHandledReplyPut(id: string, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSmsIdHandledReplyPut(id, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiSmsIdHandledReplyPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {GetSMSRepliesCommand} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSmsRepliesGet(authorization?: string, query?: GetSMSRepliesCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSMSMessagesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSmsRepliesGet(authorization, query, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiSmsRepliesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SendSMSAPIRequest} sendSMSAPIRequest 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSmsSendPost(sendSMSAPIRequest: SendSMSAPIRequest, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSmsSendPost(sendSMSAPIRequest, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.apiSmsSendPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CalendarNotificationChannelPostRequest} [calendarNotificationChannelPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendarNotificationChannelDelete(calendarNotificationChannelPostRequest?: CalendarNotificationChannelPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendarNotificationChannelDelete(calendarNotificationChannelPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.calendarNotificationChannelDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendarNotificationChannelInitialCreateAllPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendarNotificationChannelInitialCreateAllPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.calendarNotificationChannelInitialCreateAllPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CalendarNotificationChannelPostRequest} [calendarNotificationChannelPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendarNotificationChannelPost(calendarNotificationChannelPostRequest?: CalendarNotificationChannelPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendarNotificationChannelPost(calendarNotificationChannelPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.calendarNotificationChannelPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendarNotificationChannelRefreshAllPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendarNotificationChannelRefreshAllPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.calendarNotificationChannelRefreshAllPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CalendarNotificationChannelPostRequest} [calendarNotificationChannelPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendarNotificationChannelRefreshPost(calendarNotificationChannelPostRequest?: CalendarNotificationChannelPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendarNotificationChannelRefreshPost(calendarNotificationChannelPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.calendarNotificationChannelRefreshPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CalendarNotificationHandleUpdatedEventPostRequest} [calendarNotificationHandleUpdatedEventPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendarNotificationHandleEventNotificationPost(calendarNotificationHandleUpdatedEventPostRequest?: CalendarNotificationHandleUpdatedEventPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendarNotificationHandleEventNotificationPost(calendarNotificationHandleUpdatedEventPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.calendarNotificationHandleEventNotificationPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CalendarNotificationHandleUpdatedEventPostRequest} [calendarNotificationHandleUpdatedEventPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calendarNotificationHandleUpdatedEventPost(calendarNotificationHandleUpdatedEventPostRequest?: CalendarNotificationHandleUpdatedEventPostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calendarNotificationHandleUpdatedEventPost(calendarNotificationHandleUpdatedEventPostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.calendarNotificationHandleUpdatedEventPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cpproEnTurTilOrderOrderidGet(orderid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cpproEnTurTilOrderOrderidGet(orderid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.cpproEnTurTilOrderOrderidGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cpproGdprOrderOrderidDelete(orderid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cpproGdprOrderOrderidDelete(orderid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.cpproGdprOrderOrderidDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cpproGdprOrderOrderidPdfsGet(orderid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cpproGdprOrderOrderidPdfsGet(orderid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.cpproGdprOrderOrderidPdfsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cpproGdprOrdersDelete(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cpproGdprOrdersDelete(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.cpproGdprOrdersDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cpproGdprOrdersGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cpproGdprOrdersGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.cpproGdprOrdersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cpproGdprOrdersPdfsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cpproGdprOrdersPdfsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.cpproGdprOrdersPdfsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderid 
         * @param {string} [dryRun] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cpproLostOrderOrderidGet(orderid: string, dryRun?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cpproLostOrderOrderidGet(orderid, dryRun, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.cpproLostOrderOrderidGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cpproNotificationOrderOrderidGet(orderid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cpproNotificationOrderOrderidGet(orderid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.cpproNotificationOrderOrderidGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cpproOrderOrderidPricesGet(orderid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cpproOrderOrderidPricesGet(orderid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.cpproOrderOrderidPricesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cpproProsjektGodkjentOrderOrderidGet(orderid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cpproProsjektGodkjentOrderOrderidGet(orderid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.cpproProsjektGodkjentOrderOrderidGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cpproSyncGoogleUsersGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cpproSyncGoogleUsersGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.cpproSyncGoogleUsersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderid 
         * @param {string} [pdfType] 
         * @param {string} [dryRun] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cpproUpdateBefaringEventOrderidGet(orderid: string, pdfType?: string, dryRun?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cpproUpdateBefaringEventOrderidGet(orderid, pdfType, dryRun, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.cpproUpdateBefaringEventOrderidGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} calendarId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cpproUpdateSaleCalendarsCalendarIdPost(calendarId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cpproUpdateSaleCalendarsCalendarIdPost(calendarId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.cpproUpdateSaleCalendarsCalendarIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cpproUpdateSaleCalendarsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cpproUpdateSaleCalendarsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.cpproUpdateSaleCalendarsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cpproWebhookCheckQuestionsPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cpproWebhookCheckQuestionsPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.cpproWebhookCheckQuestionsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createorderOrderOrderidGet(orderid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createorderOrderOrderidGet(orderid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.createorderOrderOrderidGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailInsertPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailInsertPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.emailInsertPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} mailId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async emailSendMailIdPost(mailId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.emailSendMailIdPost(mailId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.emailSendMailIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} acErpId 
         * @param {string} [client] 
         * @param {string} [demo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erp24soAcErpIdCreateCPProOrderPost(acErpId: string, client?: string, demo?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erp24soAcErpIdCreateCPProOrderPost(acErpId, client, demo, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erp24soAcErpIdCreateCPProOrderPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [dryRun] 
         * @param {string} [manual] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erp24soFindFJOrderAfterMRPInvoicedCpproIdPost(cpproId: string, dryRun?: string, manual?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erp24soFindFJOrderAfterMRPInvoicedCpproIdPost(cpproId, dryRun, manual, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erp24soFindFJOrderAfterMRPInvoicedCpproIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [externalId] 
         * @param {string} [name] 
         * @param {string} [isACT] 
         * @param {string} [test] 
         * @param {string} [search24SOAC] 
         * @param {string} [returnProps] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erp24soForhandlerSearchGet(externalId?: string, name?: string, isACT?: string, test?: string, search24SOAC?: string, returnProps?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erp24soForhandlerSearchGet(externalId, name, isACT, test, search24SOAC, returnProps, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erp24soForhandlerSearchGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ApiErpAcCalendarDeliveryDateChangePostRequest} [apiErpAcCalendarDeliveryDateChangePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpAcCalendarDeliveryDateChangePost(apiErpAcCalendarDeliveryDateChangePostRequest?: ApiErpAcCalendarDeliveryDateChangePostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpAcCalendarDeliveryDateChangePost(apiErpAcCalendarDeliveryDateChangePostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpAcCalendarDeliveryDateChangePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} eventId 
         * @param {string} [skipDeliveryDateUpdate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpAcCalendarOrderCpproIdEventDeliveryDateChangeEventEventIdPost(cpproId: string, eventId: string, skipDeliveryDateUpdate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpAcCalendarOrderCpproIdEventDeliveryDateChangeEventEventIdPost(cpproId, eventId, skipDeliveryDateUpdate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpAcCalendarOrderCpproIdEventDeliveryDateChangeEventEventIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [disableDelete] 
         * @param {string} [dryRun] 
         * @param {string} [manual] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpAcChecklistInstalledOrderCpproIdGet(cpproId: string, disableDelete?: string, dryRun?: string, manual?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpAcChecklistInstalledOrderCpproIdGet(cpproId, disableDelete, dryRun, manual, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpAcChecklistInstalledOrderCpproIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} [deliveryDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpAcCreateOrderOrderIdPost(orderId: string, deliveryDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpAcCreateOrderOrderIdPost(orderId, deliveryDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpAcCreateOrderOrderIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [changedAfter] 
         * @param {string} [test] 
         * @param {string} [dryRun] 
         * @param {string} [includeAlreadyInactivated] 
         * @param {string} [shouldDeleteTasks] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpAcOrderInactivatePost(changedAfter?: string, test?: string, dryRun?: string, includeAlreadyInactivated?: string, shouldDeleteTasks?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpAcOrderInactivatePost(changedAfter, test, dryRun, includeAlreadyInactivated, shouldDeleteTasks, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpAcOrderInactivatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpAcRecreateOrderCpproIdPost(cpproId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpAcRecreateOrderCpproIdPost(cpproId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpAcRecreateOrderCpproIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpCreditSaleInvoiceACOrderCpproIdGet(cpproId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpCreditSaleInvoiceACOrderCpproIdGet(cpproId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpCreditSaleInvoiceACOrderCpproIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [email] 
         * @param {string} [phone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpCustomerGet(name?: string, email?: string, phone?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpCustomerGet(name, email, phone, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpCustomerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [external] 
         * @param {string} [identity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpCustomerIdGet(id: string, external?: string, identity?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpCustomerIdGet(id, external, identity, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpCustomerIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [external] 
         * @param {string} [identity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpCustomerIdPatch(id: string, external?: string, identity?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpCustomerIdPatch(id, external, identity, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpCustomerIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {string} [email] 
         * @param {string} [phone] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpCustomerPatch(name?: string, email?: string, phone?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpCustomerPatch(name, email, phone, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpCustomerPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpCustomerPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpCustomerPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpCustomerPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} [deliveryDate] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpFjCreateOrderFromACOrderIdPost(orderId: string, deliveryDate?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpFjCreateOrderFromACOrderIdPost(orderId, deliveryDate, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpFjCreateOrderFromACOrderIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpFjpartnerCreateOrderCpproIdPost(cpproId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpFjpartnerCreateOrderCpproIdPost(cpproId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpFjpartnerCreateOrderCpproIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpFjpartnerMigrateDemoOrderToLiveCpproIdPost(cpproId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpFjpartnerMigrateDemoOrderToLiveCpproIdPost(cpproId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpFjpartnerMigrateDemoOrderToLiveCpproIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [test] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpFjpartnerOrderCpproIdCreateExtraACOrderPost(cpproId: string, test?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpFjpartnerOrderCpproIdCreateExtraACOrderPost(cpproId, test, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpFjpartnerOrderCpproIdCreateExtraACOrderPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [test] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpFjpartnerOrderCpproIdInvoiceExtraACOrderPost(cpproId: string, test?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpFjpartnerOrderCpproIdInvoiceExtraACOrderPost(cpproId, test, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpFjpartnerOrderCpproIdInvoiceExtraACOrderPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproPartnerProgId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpFjpartnerOrderCpproPartnerProgIdCreateCPProSalesOrderPost(cpproPartnerProgId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpFjpartnerOrderCpproPartnerProgIdCreateCPProSalesOrderPost(cpproPartnerProgId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpFjpartnerOrderCpproPartnerProgIdCreateCPProSalesOrderPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproPartnerProgId 
         * @param {string} [acCPProId] 
         * @param {string} [dryRun] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpFjpartnerOrderCpproPartnerProgIdFindMRPOrderPost(cpproPartnerProgId: string, acCPProId?: string, dryRun?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpFjpartnerOrderCpproPartnerProgIdFindMRPOrderPost(cpproPartnerProgId, acCPProId, dryRun, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpFjpartnerOrderCpproPartnerProgIdFindMRPOrderPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproPartnerProgId 
         * @param {string} [acCPProId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpFjpartnerOrderCpproPartnerProgIdInstallCompleteWhenInstallerIsACPost(cpproPartnerProgId: string, acCPProId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpFjpartnerOrderCpproPartnerProgIdInstallCompleteWhenInstallerIsACPost(cpproPartnerProgId, acCPProId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpFjpartnerOrderCpproPartnerProgIdInstallCompleteWhenInstallerIsACPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpFjpartnerSyncPost(orderId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpFjpartnerSyncPost(orderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpFjpartnerSyncPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} [test] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpManualOrderOrderIdAutomateOrderPost(orderId: string, test?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpManualOrderOrderIdAutomateOrderPost(orderId, test, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpManualOrderOrderIdAutomateOrderPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} [test] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpManualOrderOrderIdInvoice24SOPost(orderId: string, test?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpManualOrderOrderIdInvoice24SOPost(orderId, test, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpManualOrderOrderIdInvoice24SOPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpMrpFindMRPOrderCpproIdGet(cpproId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpMrpFindMRPOrderCpproIdGet(cpproId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpMrpFindMRPOrderCpproIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpMrpInvoiceOrderCpproIdPost(cpproId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpMrpInvoiceOrderCpproIdPost(cpproId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpMrpInvoiceOrderCpproIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [dryRun] 
         * @param {string} [manual] 
         * @param {string} [xCloudtasksTaskretrycount] 
         * @param {string} [xCloudtasksTaskexecutioncount] 
         * @param {string} [xCloudtasksTasketa] 
         * @param {string} [xCloudtasksQueuename] 
         * @param {string} [xCloudtasksTaskname] 
         * @param {string} [xCloudtasksTaskpreviousresponse] 
         * @param {string} [xCloudtasksTaskretryreason] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpMrpPickOrderCpproIdPost(cpproId: string, dryRun?: string, manual?: string, xCloudtasksTaskretrycount?: string, xCloudtasksTaskexecutioncount?: string, xCloudtasksTasketa?: string, xCloudtasksQueuename?: string, xCloudtasksTaskname?: string, xCloudtasksTaskpreviousresponse?: string, xCloudtasksTaskretryreason?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpMrpPickOrderCpproIdPost(cpproId, dryRun, manual, xCloudtasksTaskretrycount, xCloudtasksTaskexecutioncount, xCloudtasksTasketa, xCloudtasksQueuename, xCloudtasksTaskname, xCloudtasksTaskpreviousresponse, xCloudtasksTaskretryreason, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpMrpPickOrderCpproIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpMrpShipOrderCpproIdPost(cpproId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpMrpShipOrderCpproIdPost(cpproId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpMrpShipOrderCpproIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpOrderCpproIdActivityhelperlocalInsertCustomerAndOrderPost(cpproId: string, type?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpOrderCpproIdActivityhelperlocalInsertCustomerAndOrderPost(cpproId, type, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpOrderCpproIdActivityhelperlocalInsertCustomerAndOrderPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [type] 
         * @param {string} [dryRun] 
         * @param {string} [fjOrder] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpOrderCpproIdCRMAddCustomerAndOrderPost(cpproId: string, type?: string, dryRun?: string, fjOrder?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpOrderCpproIdCRMAddCustomerAndOrderPost(cpproId, type, dryRun, fjOrder, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpOrderCpproIdCRMAddCustomerAndOrderPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpOrderCpproIdCalendarCreateServiceEventPost(cpproId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpOrderCpproIdCalendarCreateServiceEventPost(cpproId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpOrderCpproIdCalendarCreateServiceEventPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [dryRun] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpOrderCpproIdCreditSaleMrpInvoicedPost(cpproId: string, dryRun?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpOrderCpproIdCreditSaleMrpInvoicedPost(cpproId, dryRun, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpOrderCpproIdCreditSaleMrpInvoicedPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpOrderCpproIdInvoiceFJFinalOrderPost(cpproId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpOrderCpproIdInvoiceFJFinalOrderPost(cpproId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpOrderCpproIdInvoiceFJFinalOrderPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {string} [type] 
         * @param {string} [dryRun] 
         * @param {string} [fjOrder] 
         * @param {string} [includeVAT] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpOrderCpproIdLegacyServerAddOrderPost(cpproId: string, type?: string, dryRun?: string, fjOrder?: string, includeVAT?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpOrderCpproIdLegacyServerAddOrderPost(cpproId, type, dryRun, fjOrder, includeVAT, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpOrderCpproIdLegacyServerAddOrderPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpOrderCpproIdRepairMrpInvoicedPost(cpproId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpOrderCpproIdRepairMrpInvoicedPost(cpproId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpOrderCpproIdRepairMrpInvoicedPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpOrderCpproIdSpreadsheetUpdate20502038Post(cpproId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpOrderCpproIdSpreadsheetUpdate20502038Post(cpproId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpOrderCpproIdSpreadsheetUpdate20502038Post']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [external] 
         * @param {string} [identity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpOrderIdOrderlinesPost(id: string, external?: string, identity?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpOrderIdOrderlinesPost(id, external, identity, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpOrderIdOrderlinesPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderId 
         * @param {string} [isACErpId] 
         * @param {string} [ignoreSMS] 
         * @param {string} [isACT] 
         * @param {string} [test] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpOrderOrderIdSmsSendInvoiceLinkPost(orderId: string, isACErpId?: string, ignoreSMS?: string, isACT?: string, test?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpOrderOrderIdSmsSendInvoiceLinkPost(orderId, isACErpId, ignoreSMS, isACT, test, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpOrderOrderIdSmsSendInvoiceLinkPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpOrderPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpOrderPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpOrderPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpRepairInvoiceACOrderCpproIdGet(cpproId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpRepairInvoiceACOrderCpproIdGet(cpproId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpRepairInvoiceACOrderCpproIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [changedAfter] 
         * @param {string} [fjOrderId] 
         * @param {string} [test] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpScheduleFjFindNewOrdersPost(changedAfter?: string, fjOrderId?: string, test?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpScheduleFjFindNewOrdersPost(changedAfter, fjOrderId, test, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpScheduleFjFindNewOrdersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [test] 
         * @param {string} [orderId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpScheduleManualOrderFindManualOrdersPost(test?: string, orderId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpScheduleManualOrderFindManualOrdersPost(test, orderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpScheduleManualOrderFindManualOrdersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [disableDelete] 
         * @param {string} [dryRun] 
         * @param {string} [itemNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpScheduleMrpSyncProductsToCPProPost(disableDelete?: string, dryRun?: string, itemNumber?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpScheduleMrpSyncProductsToCPProPost(disableDelete, dryRun, itemNumber, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpScheduleMrpSyncProductsToCPProPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [demo] 
         * @param {string} [act] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpScheduleOrderFindInvoicedOrdersPost(demo?: string, act?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpScheduleOrderFindInvoicedOrdersPost(demo, act, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpScheduleOrderFindInvoicedOrdersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [cpproId] 
         * @param {string} [dryRun] 
         * @param {string} [manual] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpScheduleOrderWaitingForChecklistPost(cpproId?: string, dryRun?: string, manual?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpScheduleOrderWaitingForChecklistPost(cpproId, dryRun, manual, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpScheduleOrderWaitingForChecklistPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [event] 
         * @param {string} [optionalNow] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpScheduleSmsSendCalendarSMSRemindersPost(event?: string, optionalNow?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpScheduleSmsSendCalendarSMSRemindersPost(event, optionalNow, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpScheduleSmsSendCalendarSMSRemindersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [dryRun] 
         * @param {string} [userEmail] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpScheduleUsersSyncEmployeeInfoToCPProPost(dryRun?: string, userEmail?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpScheduleUsersSyncEmployeeInfoToCPProPost(dryRun, userEmail, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpScheduleUsersSyncEmployeeInfoToCPProPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [env] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async erpSync24SOToMRPGet(env?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.erpSync24SOToMRPGet(env, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.erpSync24SOToMRPGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fjordersErpAgreedInstallationDateOrderOrderidGet(orderid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fjordersErpAgreedInstallationDateOrderOrderidGet(orderid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.fjordersErpAgreedInstallationDateOrderOrderidGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fjordersErpDeliveryaddressOrderOrderidGet(orderid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fjordersErpDeliveryaddressOrderOrderidGet(orderid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.fjordersErpDeliveryaddressOrderOrderidGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fjordersErpInstallerJobAcceptedOrderOrderidGet(orderid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fjordersErpInstallerJobAcceptedOrderOrderidGet(orderid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.fjordersErpInstallerJobAcceptedOrderOrderidGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fjordersErpInstallerOrderOrderidGet(orderid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fjordersErpInstallerOrderOrderidGet(orderid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.fjordersErpInstallerOrderOrderidGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fjordersErpUpdateDeliveryDateOrderOrderidGet(orderid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fjordersErpUpdateDeliveryDateOrderOrderidGet(orderid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.fjordersErpUpdateDeliveryDateOrderOrderidGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} messageid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fjordersGmailMessageMessageidGet(messageid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fjordersGmailMessageMessageidGet(messageid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.fjordersGmailMessageMessageidGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fjordersInstallationCompleteConfirmationOrderOrderidGet(orderid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fjordersInstallationCompleteConfirmationOrderOrderidGet(orderid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.fjordersInstallationCompleteConfirmationOrderOrderidGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fjordersOrderAllFixEmailMessageIdsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fjordersOrderAllFixEmailMessageIdsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.fjordersOrderAllFixEmailMessageIdsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fjordersOrderAllInstallationCompleteConfirmationGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fjordersOrderAllInstallationCompleteConfirmationGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.fjordersOrderAllInstallationCompleteConfirmationGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fjordersOrderOrderidFixEmailMessageIdGet(orderid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fjordersOrderOrderidFixEmailMessageIdGet(orderid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.fjordersOrderOrderidFixEmailMessageIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fjordersOrderOrderidGet(orderid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fjordersOrderOrderidGet(orderid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.fjordersOrderOrderidGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} referenceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fjordersOrderReferenceReferenceIdGet(referenceId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fjordersOrderReferenceReferenceIdGet(referenceId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.fjordersOrderReferenceReferenceIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [includeFinished] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fjordersOrdersCpproSyncGet(includeFinished?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fjordersOrdersCpproSyncGet(includeFinished, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.fjordersOrdersCpproSyncGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fjordersOrdersCpproSyncOnlyDataGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fjordersOrdersCpproSyncOnlyDataGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.fjordersOrdersCpproSyncOnlyDataGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} reference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fjordersReferenceReferenceFiksMonteringUtfortPost(reference: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fjordersReferenceReferenceFiksMonteringUtfortPost(reference, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.fjordersReferenceReferenceFiksMonteringUtfortPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fjordersUpdateProductOrderOrderidGet(orderid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fjordersUpdateProductOrderOrderidGet(orderid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.fjordersUpdateProductOrderOrderidGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async googleCloudVisionTextDetectionPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.googleCloudVisionTextDetectionPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.googleCloudVisionTextDetectionPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.healthGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [test] 
         * @param {string} [dryRun] 
         * @param {string} [dryrun] 
         * @param {string} [limit] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadManagementScheduleMoveLeadsPost(test?: string, dryRun?: string, dryrun?: string, limit?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadManagementScheduleMoveLeadsPost(test, dryRun, dryrun, limit, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.leadManagementScheduleMoveLeadsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadManagementVendorPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadManagementVendorPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.leadManagementVendorPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadManagementVendorUserIdDelete(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadManagementVendorUserIdDelete(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.leadManagementVendorUserIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadManagementVendorUserIdPatch(userId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadManagementVendorUserIdPatch(userId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.leadManagementVendorUserIdPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async leadManagementVendorsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.leadManagementVendorsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.leadManagementVendorsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {GetIncidentsCommand} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorAlertIncidentGet(authorization?: string, query?: GetIncidentsCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlertPolicyIncidentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorAlertIncidentGet(authorization, query, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.monitorAlertIncidentGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorAlertIncidentIdDelete(id: string, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorAlertIncidentIdDelete(id, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.monitorAlertIncidentIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAlertPolicyIncidentCommand} updateAlertPolicyIncidentCommand 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorAlertIncidentIdPut(id: string, updateAlertPolicyIncidentCommand: UpdateAlertPolicyIncidentCommand, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorAlertIncidentIdPut(id, updateAlertPolicyIncidentCommand, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.monitorAlertIncidentIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PubSubPushRequest} pubSubPushRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorAlertIncidentPubsubPost(pubSubPushRequest: PubSubPushRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorAlertIncidentPubsubPost(pubSubPushRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.monitorAlertIncidentPubsubPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorAlertNotificationChannelGet(authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NotificationChannelsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorAlertNotificationChannelGet(authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.monitorAlertNotificationChannelGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorAlertNotificationChannelIdDelete(id: string, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorAlertNotificationChannelIdDelete(id, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.monitorAlertNotificationChannelIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateNotificationChannelCommand} updateNotificationChannelCommand 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorAlertNotificationChannelIdPut(id: string, updateNotificationChannelCommand: UpdateNotificationChannelCommand, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorAlertNotificationChannelIdPut(id, updateNotificationChannelCommand, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.monitorAlertNotificationChannelIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateNotificationChannelCommand} createNotificationChannelCommand 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorAlertNotificationChannelPost(createNotificationChannelCommand: CreateNotificationChannelCommand, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorAlertNotificationChannelPost(createNotificationChannelCommand, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.monitorAlertNotificationChannelPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorAlertPolicyGet(authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlertPoliciesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorAlertPolicyGet(authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.monitorAlertPolicyGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorAlertPolicyIdDelete(id: string, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorAlertPolicyIdDelete(id, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.monitorAlertPolicyIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateAlertPolicyCommand} updateAlertPolicyCommand 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorAlertPolicyIdPut(id: string, updateAlertPolicyCommand: UpdateAlertPolicyCommand, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorAlertPolicyIdPut(id, updateAlertPolicyCommand, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.monitorAlertPolicyIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} policyId 
         * @param {string} channelId 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorAlertPolicyPolicyIdChannelChannelIdDelete(policyId: string, channelId: string, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorAlertPolicyPolicyIdChannelChannelIdDelete(policyId, channelId, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.monitorAlertPolicyPolicyIdChannelChannelIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} policyId 
         * @param {string} channelId 
         * @param {CreatePolicyNotificationCommand} createPolicyNotificationCommand 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorAlertPolicyPolicyIdChannelChannelIdPost(policyId: string, channelId: string, createPolicyNotificationCommand: CreatePolicyNotificationCommand, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorAlertPolicyPolicyIdChannelChannelIdPost(policyId, channelId, createPolicyNotificationCommand, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.monitorAlertPolicyPolicyIdChannelChannelIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} policyId 
         * @param {string} channelId 
         * @param {UpdatePolicyNotificationCommand} updatePolicyNotificationCommand 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorAlertPolicyPolicyIdChannelChannelIdPut(policyId: string, channelId: string, updatePolicyNotificationCommand: UpdatePolicyNotificationCommand, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorAlertPolicyPolicyIdChannelChannelIdPut(policyId, channelId, updatePolicyNotificationCommand, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.monitorAlertPolicyPolicyIdChannelChannelIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} policyId 
         * @param {string} [authorization] 
         * @param {GetIncidentsCommand} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorAlertPolicyPolicyIdIncidentGet(policyId: string, authorization?: string, query?: GetIncidentsCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AlertPolicyIncidentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorAlertPolicyPolicyIdIncidentGet(policyId, authorization, query, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.monitorAlertPolicyPolicyIdIncidentGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} policyId 
         * @param {CreateAlertPolicyIncidentCommand} createAlertPolicyIncidentCommand 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorAlertPolicyPolicyIdIncidentPost(policyId: string, createAlertPolicyIncidentCommand: CreateAlertPolicyIncidentCommand, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorAlertPolicyPolicyIdIncidentPost(policyId, createAlertPolicyIncidentCommand, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.monitorAlertPolicyPolicyIdIncidentPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateAlertPolicyCommand} createAlertPolicyCommand 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorAlertPolicyPost(createAlertPolicyCommand: CreateAlertPolicyCommand, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorAlertPolicyPost(createAlertPolicyCommand, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.monitorAlertPolicyPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorAlertSyncPolicyPost(authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorAlertSyncPolicyPost(authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.monitorAlertSyncPolicyPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {GetEmailsCommand} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorEmailsGet(authorization?: string, query?: GetEmailsCommand, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetEmailsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorEmailsGet(authorization, query, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.monitorEmailsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DateCommand} dateCommand 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorErpApiErrorsGet(dateCommand: DateCommand, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogEntriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorErpApiErrorsGet(dateCommand, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.monitorErpApiErrorsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {GetErpErrorRequestQuery} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorErpErrorsGet(authorization?: string, query?: GetErpErrorRequestQuery, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetErpErrorResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorErpErrorsGet(authorization, query, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.monitorErpErrorsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DateCommand} dateCommand 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorLeadLogsValidationErrorsGet(dateCommand: DateCommand, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogEntriesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorLeadLogsValidationErrorsGet(dateCommand, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.monitorLeadLogsValidationErrorsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DateCommand} dateCommand 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorLeadRequestStatusCodesGet(dateCommand: DateCommand, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LeadAPIRequests>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorLeadRequestStatusCodesGet(dateCommand, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.monitorLeadRequestStatusCodesGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DateCommand} dateCommand 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorLeadVendorsGet(dateCommand: DateCommand, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorLeadVendorsGet(dateCommand, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.monitorLeadVendorsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GetSMSesCommand} getSMSesCommand 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorSmsGet(getSMSesCommand: GetSMSesCommand, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSMSesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorSmsGet(getSMSesCommand, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.monitorSmsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorTasksQueuesWithTasksGet(authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QueueWithTasksResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorTasksQueuesWithTasksGet(authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.monitorTasksQueuesWithTasksGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {TaskPathCommand} taskPathCommand 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorTasksTaskDelete(taskPathCommand: TaskPathCommand, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorTasksTaskDelete(taskPathCommand, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.monitorTasksTaskDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {TaskPathCommand} taskPathCommand 
         * @param {string} [authorization] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async monitorTasksTaskRunPost(taskPathCommand: TaskPathCommand, authorization?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.monitorTasksTaskRunPost(taskPathCommand, authorization, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.monitorTasksTaskRunPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async orderconfirmationOrderOrderidGet(orderid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.orderconfirmationOrderOrderidGet(orderid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.orderconfirmationOrderOrderidGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerCreateOrderPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerCreateOrderPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.partnerCreateOrderPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerHealthGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerHealthGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.partnerHealthGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} partnerReference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerOrdersPartnerReferenceGet(partnerReference: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerOrdersPartnerReferenceGet(partnerReference, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.partnerOrdersPartnerReferenceGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} partnerReference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerWebhooksPartnerReferenceDelete(partnerReference: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerWebhooksPartnerReferenceDelete(partnerReference, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.partnerWebhooksPartnerReferenceDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} partnerReference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerWebhooksPartnerReferencePost(partnerReference: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerWebhooksPartnerReferencePost(partnerReference, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.partnerWebhooksPartnerReferencePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} partnerReference 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async partnerWebhooksPartnerReferencePut(partnerReference: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.partnerWebhooksPartnerReferencePut(partnerReference, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.partnerWebhooksPartnerReferencePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xGoogResourceState] 
         * @param {string} [xGoogChannelExpiration] 
         * @param {string} [xGoogChannelToken] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicCalendarNotificationHandlePost(xGoogResourceState?: string, xGoogChannelExpiration?: string, xGoogChannelToken?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicCalendarNotificationHandlePost(xGoogResourceState, xGoogChannelExpiration, xGoogChannelToken, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.publicCalendarNotificationHandlePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publicSMSHandleReplyPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publicSMSHandleReplyPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.publicSMSHandleReplyPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} orderid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async quoteOrderOrderidGet(orderid: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.quoteOrderOrderidGet(orderid, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.quoteOrderOrderidGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async smsInsertPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smsInsertPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.smsInsertPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} guid 
         * @param {string} [test] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async smsSendMultipleGuidPost(guid: string, test?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smsSendMultipleGuidPost(guid, test, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.smsSendMultipleGuidPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} smsId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async smsSendSmsIdPost(smsId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.smsSendSmsIdPost(smsId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.smsSendSmsIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async swaggerJsonGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.swaggerJsonGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.swaggerJsonGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncAllCPProGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncAllCPProGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.syncAllCPProGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncAllGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncAllGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.syncAllGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncCampaignCodeGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncCampaignCodeGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.syncCampaignCodeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncConstantGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncConstantGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.syncConstantGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncCpproGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncCpproGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.syncCpproGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncInstallerGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncInstallerGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.syncInstallerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncLeadGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncLeadGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.syncLeadGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} cpproOrderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncOrderCpproOrderIdPost(cpproOrderId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncOrderCpproOrderIdPost(cpproOrderId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.syncOrderCpproOrderIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncOrderGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncOrderGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.syncOrderGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncOrdersAllGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncOrdersAllGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.syncOrdersAllGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncOrdersAllInstallersGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncOrdersAllInstallersGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.syncOrdersAllInstallersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncOrdersAllMasterGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncOrdersAllMasterGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.syncOrdersAllMasterGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncOrdersAllPartnersGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncOrdersAllPartnersGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.syncOrdersAllPartnersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncOrdersCompletedGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncOrdersCompletedGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.syncOrdersCompletedGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncOrdersCompletedInstallersGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncOrdersCompletedInstallersGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.syncOrdersCompletedInstallersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncOrdersCompletedMasterGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncOrdersCompletedMasterGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.syncOrdersCompletedMasterGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncOrdersCompletedPartnersGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncOrdersCompletedPartnersGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.syncOrdersCompletedPartnersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncOrdersInstallersGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncOrdersInstallersGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.syncOrdersInstallersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncOrdersMasterGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncOrdersMasterGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.syncOrdersMasterGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncOrdersPartnersGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncOrdersPartnersGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.syncOrdersPartnersGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncParameterGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncParameterGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.syncParameterGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncPartnerGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncPartnerGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.syncPartnerGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncProductGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncProductGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.syncProductGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncShareInstallerSheetsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncShareInstallerSheetsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.syncShareInstallerSheetsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tasksCheckTasksPost(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tasksCheckTasksPost(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.tasksCheckTasksPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.testGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testRequestipsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testRequestipsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.testRequestipsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testerrorGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testerrorGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.testerrorGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async testerrornotifyGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.testerrornotifyGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DefaultApi.testerrornotifyGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {DefaultApiApiCRMCustomerCustomerIdAquireLockPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCRMCustomerCustomerIdAquireLockPost(requestParameters: DefaultApiApiCRMCustomerCustomerIdAquireLockPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<CRMCustomerLockSuccessResponse> {
            return localVarFp.apiCRMCustomerCustomerIdAquireLockPost(requestParameters.customerId, requestParameters.postCRMCustomerAquireLockRequest, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiCRMCustomerCustomerIdReleaseLockDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCRMCustomerCustomerIdReleaseLockDelete(requestParameters: DefaultApiApiCRMCustomerCustomerIdReleaseLockDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<CRMCustomerLockSuccessResponse> {
            return localVarFp.apiCRMCustomerCustomerIdReleaseLockDelete(requestParameters.customerId, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiCRMCustomerLocksGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCRMCustomerLocksGet(requestParameters: DefaultApiApiCRMCustomerLocksGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<GetCRMCustomerLocksResponse> {
            return localVarFp.apiCRMCustomerLocksGet(requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiCRMDepartmentAreasGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCRMDepartmentAreasGet(requestParameters: DefaultApiApiCRMDepartmentAreasGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<GetDepartmentZipAreasResponse> {
            return localVarFp.apiCRMDepartmentAreasGet(requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiCRMSMSTemplatesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCRMSMSTemplatesGet(requestParameters: DefaultApiApiCRMSMSTemplatesGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<GetCRMSMSTemplatesResponse> {
            return localVarFp.apiCRMSMSTemplatesGet(requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiCalendarNotificationChannelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCalendarNotificationChannelPost(requestParameters: DefaultApiApiCalendarNotificationChannelPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiCalendarNotificationChannelPost(requestParameters.createNotificationChannelRequest, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiConstantGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConstantGet(requestParameters: DefaultApiApiConstantGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<GetConstantsResponse> {
            return localVarFp.apiConstantGet(requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiConstantKeyDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConstantKeyDelete(requestParameters: DefaultApiApiConstantKeyDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiConstantKeyDelete(requestParameters.key, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiConstantKeyGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConstantKeyGet(requestParameters: DefaultApiApiConstantKeyGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetConstantResponse> {
            return localVarFp.apiConstantKeyGet(requestParameters.key, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiConstantKeyVersionPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConstantKeyVersionPost(requestParameters: DefaultApiApiConstantKeyVersionPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiConstantKeyVersionPost(requestParameters.key, requestParameters.postConstantRequest, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiConstantKeyVersionVersionDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConstantKeyVersionVersionDelete(requestParameters: DefaultApiApiConstantKeyVersionVersionDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiConstantKeyVersionVersionDelete(requestParameters.key, requestParameters.version, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiConstantKeyVersionVersionDisablePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConstantKeyVersionVersionDisablePost(requestParameters: DefaultApiApiConstantKeyVersionVersionDisablePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiConstantKeyVersionVersionDisablePost(requestParameters.key, requestParameters.version, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiConstantKeyVersionVersionEnablePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConstantKeyVersionVersionEnablePost(requestParameters: DefaultApiApiConstantKeyVersionVersionEnablePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiConstantKeyVersionVersionEnablePost(requestParameters.key, requestParameters.version, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiConstantPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiConstantPost(requestParameters: DefaultApiApiConstantPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiConstantPost(requestParameters.postConstantRequest, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiCppro24soAcErpIdCreateOrderPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCppro24soAcErpIdCreateOrderPost(requestParameters: DefaultApiApiCppro24soAcErpIdCreateOrderPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiCppro24soAcErpIdCreateOrderPost(requestParameters.acErpId, requestParameters.authorization, requestParameters.client, requestParameters.demo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiCpproCreateorderOrderOrderidPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCpproCreateorderOrderOrderidPost(requestParameters: DefaultApiApiCpproCreateorderOrderOrderidPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiCpproCreateorderOrderOrderidPost(requestParameters.orderid, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiCpproMamutOrderIdCreateOrderPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCpproMamutOrderIdCreateOrderPost(requestParameters: DefaultApiApiCpproMamutOrderIdCreateOrderPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiCpproMamutOrderIdCreateOrderPost(requestParameters.orderId, requestParameters.authorization, requestParameters.client, requestParameters.demo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiCpproOrderCreateSalesOrderChecklistPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCpproOrderCreateSalesOrderChecklistPost(requestParameters: DefaultApiApiCpproOrderCreateSalesOrderChecklistPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.apiCpproOrderCreateSalesOrderChecklistPost(requestParameters.createSalesOrderChecklistRequest, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiCpproOrderCreateServiceOrderChecklistPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCpproOrderCreateServiceOrderChecklistPost(requestParameters: DefaultApiApiCpproOrderCreateServiceOrderChecklistPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.apiCpproOrderCreateServiceOrderChecklistPost(requestParameters.createServiceOrderChecklistRequest, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiDatePublicHolidaysGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDatePublicHolidaysGet(requestParameters: DefaultApiApiDatePublicHolidaysGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<GetPublicHolidaysResponse> {
            return localVarFp.apiDatePublicHolidaysGet(requestParameters.authorization, requestParameters.all, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiDatePublicHolidaysPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDatePublicHolidaysPost(requestParameters: DefaultApiApiDatePublicHolidaysPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiDatePublicHolidaysPost(requestParameters.postPublicHolidaysRequest, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocsInternalJsonGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiDocsInternalJsonGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDocsLeadJsonGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiDocsLeadJsonGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErp24soForhandlerSearchGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErp24soForhandlerSearchGet(requestParameters: DefaultApiApiErp24soForhandlerSearchGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiErp24soForhandlerSearchGet(requestParameters.authorization, requestParameters.externalId, requestParameters.name, requestParameters.isACT, requestParameters.test, requestParameters.search24SOAC, requestParameters.returnProps, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErpAcCalendarDeliveryDateChangePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpAcCalendarDeliveryDateChangePost(requestParameters: DefaultApiApiErpAcCalendarDeliveryDateChangePostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiErpAcCalendarDeliveryDateChangePost(requestParameters.authorization, requestParameters.apiErpAcCalendarDeliveryDateChangePostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErpAcOrderCpproIdInactivatePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpAcOrderCpproIdInactivatePost(requestParameters: DefaultApiApiErpAcOrderCpproIdInactivatePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiErpAcOrderCpproIdInactivatePost(requestParameters.cpproId, requestParameters.authorization, requestParameters.dryRun, requestParameters.test, requestParameters.shouldDeleteTasks, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErpErpSyncOrderGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpErpSyncOrderGet(requestParameters: DefaultApiApiErpErpSyncOrderGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<GetErpSyncOrderResponse> {
            return localVarFp.apiErpErpSyncOrderGet(requestParameters.authorization, requestParameters.query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErpErpSyncOrdersGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpErpSyncOrdersGet(requestParameters: DefaultApiApiErpErpSyncOrdersGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<GetErpSyncOrdersResponse> {
            return localVarFp.apiErpErpSyncOrdersGet(requestParameters.authorization, requestParameters.startTime, requestParameters.endTime, requestParameters.query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErpInstallItemUsageGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpInstallItemUsageGet(requestParameters: DefaultApiApiErpInstallItemUsageGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<GetInstallItemUsageResponse> {
            return localVarFp.apiErpInstallItemUsageGet(requestParameters.authorization, requestParameters.ignoreCache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErpInstallItemUsageItemNumberAlternativePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpInstallItemUsageItemNumberAlternativePost(requestParameters: DefaultApiApiErpInstallItemUsageItemNumberAlternativePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiErpInstallItemUsageItemNumberAlternativePost(requestParameters.itemNumber, requestParameters.postInstallItemUsageAlternativeRequest, requestParameters.authorization, requestParameters.ignoreCache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErpInstallItemUsageItemNumberDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpInstallItemUsageItemNumberDelete(requestParameters: DefaultApiApiErpInstallItemUsageItemNumberDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiErpInstallItemUsageItemNumberDelete(requestParameters.itemNumber, requestParameters.authorization, requestParameters.ignoreCache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErpInstallItemUsageItemNumberPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpInstallItemUsageItemNumberPut(requestParameters: DefaultApiApiErpInstallItemUsageItemNumberPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiErpInstallItemUsageItemNumberPut(requestParameters.itemNumber, requestParameters.putInstallItemUsageRequest, requestParameters.authorization, requestParameters.ignoreCache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdDelete(requestParameters: DefaultApiApiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdDelete(requestParameters.itemUsageId, requestParameters.alternativeId, requestParameters.authorization, requestParameters.ignoreCache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdPut(requestParameters: DefaultApiApiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdPut(requestParameters.itemUsageId, requestParameters.alternativeId, requestParameters.putInstallItemUsageAlternativeRequest, requestParameters.authorization, requestParameters.ignoreCache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErpInstallItemUsagePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpInstallItemUsagePost(requestParameters: DefaultApiApiErpInstallItemUsagePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiErpInstallItemUsagePost(requestParameters.postInstallItemUsageRequest, requestParameters.authorization, requestParameters.ignoreCache, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErpInstallOrderInstallCPproIdChecklistInstalledPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpInstallOrderInstallCPproIdChecklistInstalledPost(requestParameters: DefaultApiApiErpInstallOrderInstallCPproIdChecklistInstalledPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PostChecklistInstalledResponse> {
            return localVarFp.apiErpInstallOrderInstallCPproIdChecklistInstalledPost(requestParameters.installCPproId, requestParameters.authorization, requestParameters.continueProcess, requestParameters.dryRun, requestParameters.manual, requestParameters.enableDelete, requestParameters.query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErpMrpItemItemNumberStockGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpMrpItemItemNumberStockGet(requestParameters: DefaultApiApiErpMrpItemItemNumberStockGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetMRPStockResponse> {
            return localVarFp.apiErpMrpItemItemNumberStockGet(requestParameters.itemNumber, requestParameters.authorization, requestParameters.test, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErpMrpOrderCpproInstallIdCheckSerialNumberGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpMrpOrderCpproInstallIdCheckSerialNumberGet(requestParameters: DefaultApiApiErpMrpOrderCpproInstallIdCheckSerialNumberGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiErpMrpOrderCpproInstallIdCheckSerialNumberGet(requestParameters.cpproInstallId, requestParameters.authorization, requestParameters.questionId, requestParameters.serialNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErpMrpOrderOrderIdPickErrorsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpMrpOrderOrderIdPickErrorsGet(requestParameters: DefaultApiApiErpMrpOrderOrderIdPickErrorsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetMrpPickErrorsResponse> {
            return localVarFp.apiErpMrpOrderOrderIdPickErrorsGet(requestParameters.orderId, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErpMrpOrderStatisticsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpMrpOrderStatisticsGet(requestParameters: DefaultApiApiErpMrpOrderStatisticsGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<GetMrpOrderStatisticsResponse> {
            return localVarFp.apiErpMrpOrderStatisticsGet(requestParameters.authorization, requestParameters.startTime, requestParameters.endTime, requestParameters.query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErpMrpPickErrorDeviationReasonDeviationReasonIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpMrpPickErrorDeviationReasonDeviationReasonIdDelete(requestParameters: DefaultApiApiErpMrpPickErrorDeviationReasonDeviationReasonIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiErpMrpPickErrorDeviationReasonDeviationReasonIdDelete(requestParameters.deviationReasonId, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErpMrpPickErrorDeviationReasonDeviationReasonIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpMrpPickErrorDeviationReasonDeviationReasonIdPut(requestParameters: DefaultApiApiErpMrpPickErrorDeviationReasonDeviationReasonIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiErpMrpPickErrorDeviationReasonDeviationReasonIdPut(requestParameters.deviationReasonId, requestParameters.putPostPickErrorDeviationReasonRequest, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErpMrpPickErrorDeviationReasonPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpMrpPickErrorDeviationReasonPost(requestParameters: DefaultApiApiErpMrpPickErrorDeviationReasonPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiErpMrpPickErrorDeviationReasonPost(requestParameters.putPostPickErrorDeviationReasonRequest, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErpMrpPickErrorDeviationReasonsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpMrpPickErrorDeviationReasonsGet(requestParameters: DefaultApiApiErpMrpPickErrorDeviationReasonsGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<GetPickErrorDeviationReasonsResponse> {
            return localVarFp.apiErpMrpPickErrorDeviationReasonsGet(requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErpMrpPickErrorPickErrorIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpMrpPickErrorPickErrorIdPut(requestParameters: DefaultApiApiErpMrpPickErrorPickErrorIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<PutMrpPickErrorResponse> {
            return localVarFp.apiErpMrpPickErrorPickErrorIdPut(requestParameters.pickErrorId, requestParameters.putMrpPickErrorRequest, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErpMrpPickErrorsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpMrpPickErrorsGet(requestParameters: DefaultApiApiErpMrpPickErrorsGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<GetMrpPickErrorsResponse> {
            return localVarFp.apiErpMrpPickErrorsGet(requestParameters.authorization, requestParameters.status, requestParameters.startTime, requestParameters.endTime, requestParameters.query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErpMrpPickErrorsPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpMrpPickErrorsPut(requestParameters: DefaultApiApiErpMrpPickErrorsPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<PutMrpPickErrorsResponse> {
            return localVarFp.apiErpMrpPickErrorsPut(requestParameters.putMrpPickErrorsRequest, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErpOrder24soCreateOrderTaskPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpOrder24soCreateOrderTaskPost(requestParameters: DefaultApiApiErpOrder24soCreateOrderTaskPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiErpOrder24soCreateOrderTaskPost(requestParameters.postCreateOrderRequest, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErpOrderCpproId24soACInvoicePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpOrderCpproId24soACInvoicePost(requestParameters: DefaultApiApiErpOrderCpproId24soACInvoicePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PostACInvoiceOrderResponse> {
            return localVarFp.apiErpOrderCpproId24soACInvoicePost(requestParameters.cpproId, requestParameters.authorization, requestParameters.dryRun, requestParameters.manual, requestParameters.enableDelete, requestParameters.query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErpOrderCpproId24soInactivateFJAndInvoiceACPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpOrderCpproId24soInactivateFJAndInvoiceACPost(requestParameters: DefaultApiApiErpOrderCpproId24soInactivateFJAndInvoiceACPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PostInactivateFJAndInvoiceACResponse> {
            return localVarFp.apiErpOrderCpproId24soInactivateFJAndInvoiceACPost(requestParameters.cpproId, requestParameters.authorization, requestParameters.dryRun, requestParameters.manual, requestParameters.enableDelete, requestParameters.query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErpOrderCpproIdCheckEnTurTilOrderInvoicedGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpOrderCpproIdCheckEnTurTilOrderInvoicedGet(requestParameters: DefaultApiApiErpOrderCpproIdCheckEnTurTilOrderInvoicedGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiErpOrderCpproIdCheckEnTurTilOrderInvoicedGet(requestParameters.cpproId, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErpOrderCpproIdMrpFindMRPOrderPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpOrderCpproIdMrpFindMRPOrderPost(requestParameters: DefaultApiApiErpOrderCpproIdMrpFindMRPOrderPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PostFindMRPOrderResponse> {
            return localVarFp.apiErpOrderCpproIdMrpFindMRPOrderPost(requestParameters.cpproId, requestParameters.authorization, requestParameters.dryRun, requestParameters.manual, requestParameters.enableDelete, requestParameters.query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErpOrderCpproIdMrpInvoicePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpOrderCpproIdMrpInvoicePost(requestParameters: DefaultApiApiErpOrderCpproIdMrpInvoicePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PostMRPInvoiceResponse> {
            return localVarFp.apiErpOrderCpproIdMrpInvoicePost(requestParameters.cpproId, requestParameters.authorization, requestParameters.dryRun, requestParameters.manual, requestParameters.enableDelete, requestParameters.query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErpOrderCpproIdMrpManuallyInvoicedPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpOrderCpproIdMrpManuallyInvoicedPost(requestParameters: DefaultApiApiErpOrderCpproIdMrpManuallyInvoicedPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiErpOrderCpproIdMrpManuallyInvoicedPost(requestParameters.cpproId, requestParameters.postMRPManuallyInvoicedRequest, requestParameters.authorization, requestParameters.query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErpOrderCpproIdMrpPickPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpOrderCpproIdMrpPickPost(requestParameters: DefaultApiApiErpOrderCpproIdMrpPickPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<PostMRPPickResponse> {
            return localVarFp.apiErpOrderCpproIdMrpPickPost(requestParameters.cpproId, requestParameters.authorization, requestParameters.dryRun, requestParameters.manual, requestParameters.enableDelete, requestParameters.query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErpOrderCpproIdMrpSalesOrderGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpOrderCpproIdMrpSalesOrderGet(requestParameters: DefaultApiApiErpOrderCpproIdMrpSalesOrderGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetMRPSalesOrderResponse> {
            return localVarFp.apiErpOrderCpproIdMrpSalesOrderGet(requestParameters.cpproId, requestParameters.authorization, requestParameters.query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErpOrderCpproIdMrpShipPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpOrderCpproIdMrpShipPost(requestParameters: DefaultApiApiErpOrderCpproIdMrpShipPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiErpOrderCpproIdMrpShipPost(requestParameters.cpproId, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErpOrderCpproIdRecreateOrderPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpOrderCpproIdRecreateOrderPost(requestParameters: DefaultApiApiErpOrderCpproIdRecreateOrderPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiErpOrderCpproIdRecreateOrderPost(requestParameters.cpproId, requestParameters.authorization, requestParameters.deliveryDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiErpSmsSendCalendarRemindersPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiErpSmsSendCalendarRemindersPost(requestParameters: DefaultApiApiErpSmsSendCalendarRemindersPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiErpSmsSendCalendarRemindersPost(requestParameters.authorization, requestParameters.event, requestParameters.optionalNow, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiSmsCustomerCustomerIdMessagesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSmsCustomerCustomerIdMessagesGet(requestParameters: DefaultApiApiSmsCustomerCustomerIdMessagesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetSMSMessagesResponse> {
            return localVarFp.apiSmsCustomerCustomerIdMessagesGet(requestParameters.customerId, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiSmsIdHandledReplyPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSmsIdHandledReplyPut(requestParameters: DefaultApiApiSmsIdHandledReplyPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiSmsIdHandledReplyPut(requestParameters.id, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiSmsRepliesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSmsRepliesGet(requestParameters: DefaultApiApiSmsRepliesGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<GetSMSMessagesResponse> {
            return localVarFp.apiSmsRepliesGet(requestParameters.authorization, requestParameters.query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiApiSmsSendPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSmsSendPost(requestParameters: DefaultApiApiSmsSendPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.apiSmsSendPost(requestParameters.sendSMSAPIRequest, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiCalendarNotificationChannelDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarNotificationChannelDelete(requestParameters: DefaultApiCalendarNotificationChannelDeleteRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.calendarNotificationChannelDelete(requestParameters.calendarNotificationChannelPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarNotificationChannelInitialCreateAllPost(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.calendarNotificationChannelInitialCreateAllPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiCalendarNotificationChannelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarNotificationChannelPost(requestParameters: DefaultApiCalendarNotificationChannelPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.calendarNotificationChannelPost(requestParameters.calendarNotificationChannelPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarNotificationChannelRefreshAllPost(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.calendarNotificationChannelRefreshAllPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiCalendarNotificationChannelRefreshPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarNotificationChannelRefreshPost(requestParameters: DefaultApiCalendarNotificationChannelRefreshPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.calendarNotificationChannelRefreshPost(requestParameters.calendarNotificationChannelPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiCalendarNotificationHandleEventNotificationPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarNotificationHandleEventNotificationPost(requestParameters: DefaultApiCalendarNotificationHandleEventNotificationPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.calendarNotificationHandleEventNotificationPost(requestParameters.calendarNotificationHandleUpdatedEventPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiCalendarNotificationHandleUpdatedEventPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calendarNotificationHandleUpdatedEventPost(requestParameters: DefaultApiCalendarNotificationHandleUpdatedEventPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.calendarNotificationHandleUpdatedEventPost(requestParameters.calendarNotificationHandleUpdatedEventPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiCpproEnTurTilOrderOrderidGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cpproEnTurTilOrderOrderidGet(requestParameters: DefaultApiCpproEnTurTilOrderOrderidGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.cpproEnTurTilOrderOrderidGet(requestParameters.orderid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiCpproGdprOrderOrderidDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cpproGdprOrderOrderidDelete(requestParameters: DefaultApiCpproGdprOrderOrderidDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.cpproGdprOrderOrderidDelete(requestParameters.orderid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiCpproGdprOrderOrderidPdfsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cpproGdprOrderOrderidPdfsGet(requestParameters: DefaultApiCpproGdprOrderOrderidPdfsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.cpproGdprOrderOrderidPdfsGet(requestParameters.orderid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cpproGdprOrdersDelete(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.cpproGdprOrdersDelete(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cpproGdprOrdersGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.cpproGdprOrdersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cpproGdprOrdersPdfsGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.cpproGdprOrdersPdfsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiCpproLostOrderOrderidGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cpproLostOrderOrderidGet(requestParameters: DefaultApiCpproLostOrderOrderidGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.cpproLostOrderOrderidGet(requestParameters.orderid, requestParameters.dryRun, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiCpproNotificationOrderOrderidGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cpproNotificationOrderOrderidGet(requestParameters: DefaultApiCpproNotificationOrderOrderidGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.cpproNotificationOrderOrderidGet(requestParameters.orderid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiCpproOrderOrderidPricesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cpproOrderOrderidPricesGet(requestParameters: DefaultApiCpproOrderOrderidPricesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.cpproOrderOrderidPricesGet(requestParameters.orderid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiCpproProsjektGodkjentOrderOrderidGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cpproProsjektGodkjentOrderOrderidGet(requestParameters: DefaultApiCpproProsjektGodkjentOrderOrderidGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.cpproProsjektGodkjentOrderOrderidGet(requestParameters.orderid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cpproSyncGoogleUsersGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.cpproSyncGoogleUsersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiCpproUpdateBefaringEventOrderidGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cpproUpdateBefaringEventOrderidGet(requestParameters: DefaultApiCpproUpdateBefaringEventOrderidGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.cpproUpdateBefaringEventOrderidGet(requestParameters.orderid, requestParameters.pdfType, requestParameters.dryRun, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiCpproUpdateSaleCalendarsCalendarIdPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cpproUpdateSaleCalendarsCalendarIdPost(requestParameters: DefaultApiCpproUpdateSaleCalendarsCalendarIdPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.cpproUpdateSaleCalendarsCalendarIdPost(requestParameters.calendarId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cpproUpdateSaleCalendarsGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.cpproUpdateSaleCalendarsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cpproWebhookCheckQuestionsPost(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.cpproWebhookCheckQuestionsPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiCreateorderOrderOrderidGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createorderOrderOrderidGet(requestParameters: DefaultApiCreateorderOrderOrderidGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.createorderOrderOrderidGet(requestParameters.orderid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailInsertPost(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.emailInsertPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiEmailSendMailIdPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        emailSendMailIdPost(requestParameters: DefaultApiEmailSendMailIdPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.emailSendMailIdPost(requestParameters.mailId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErp24soAcErpIdCreateCPProOrderPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erp24soAcErpIdCreateCPProOrderPost(requestParameters: DefaultApiErp24soAcErpIdCreateCPProOrderPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erp24soAcErpIdCreateCPProOrderPost(requestParameters.acErpId, requestParameters.client, requestParameters.demo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErp24soFindFJOrderAfterMRPInvoicedCpproIdPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erp24soFindFJOrderAfterMRPInvoicedCpproIdPost(requestParameters: DefaultApiErp24soFindFJOrderAfterMRPInvoicedCpproIdPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erp24soFindFJOrderAfterMRPInvoicedCpproIdPost(requestParameters.cpproId, requestParameters.dryRun, requestParameters.manual, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErp24soForhandlerSearchGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erp24soForhandlerSearchGet(requestParameters: DefaultApiErp24soForhandlerSearchGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erp24soForhandlerSearchGet(requestParameters.externalId, requestParameters.name, requestParameters.isACT, requestParameters.test, requestParameters.search24SOAC, requestParameters.returnProps, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpAcCalendarDeliveryDateChangePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpAcCalendarDeliveryDateChangePost(requestParameters: DefaultApiErpAcCalendarDeliveryDateChangePostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpAcCalendarDeliveryDateChangePost(requestParameters.apiErpAcCalendarDeliveryDateChangePostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpAcCalendarOrderCpproIdEventDeliveryDateChangeEventEventIdPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpAcCalendarOrderCpproIdEventDeliveryDateChangeEventEventIdPost(requestParameters: DefaultApiErpAcCalendarOrderCpproIdEventDeliveryDateChangeEventEventIdPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpAcCalendarOrderCpproIdEventDeliveryDateChangeEventEventIdPost(requestParameters.cpproId, requestParameters.eventId, requestParameters.skipDeliveryDateUpdate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpAcChecklistInstalledOrderCpproIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpAcChecklistInstalledOrderCpproIdGet(requestParameters: DefaultApiErpAcChecklistInstalledOrderCpproIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpAcChecklistInstalledOrderCpproIdGet(requestParameters.cpproId, requestParameters.disableDelete, requestParameters.dryRun, requestParameters.manual, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpAcCreateOrderOrderIdPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpAcCreateOrderOrderIdPost(requestParameters: DefaultApiErpAcCreateOrderOrderIdPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpAcCreateOrderOrderIdPost(requestParameters.orderId, requestParameters.deliveryDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpAcOrderInactivatePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpAcOrderInactivatePost(requestParameters: DefaultApiErpAcOrderInactivatePostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpAcOrderInactivatePost(requestParameters.changedAfter, requestParameters.test, requestParameters.dryRun, requestParameters.includeAlreadyInactivated, requestParameters.shouldDeleteTasks, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpAcRecreateOrderCpproIdPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpAcRecreateOrderCpproIdPost(requestParameters: DefaultApiErpAcRecreateOrderCpproIdPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpAcRecreateOrderCpproIdPost(requestParameters.cpproId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpCreditSaleInvoiceACOrderCpproIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpCreditSaleInvoiceACOrderCpproIdGet(requestParameters: DefaultApiErpCreditSaleInvoiceACOrderCpproIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpCreditSaleInvoiceACOrderCpproIdGet(requestParameters.cpproId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpCustomerGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpCustomerGet(requestParameters: DefaultApiErpCustomerGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpCustomerGet(requestParameters.name, requestParameters.email, requestParameters.phone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpCustomerIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpCustomerIdGet(requestParameters: DefaultApiErpCustomerIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpCustomerIdGet(requestParameters.id, requestParameters.external, requestParameters.identity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpCustomerIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpCustomerIdPatch(requestParameters: DefaultApiErpCustomerIdPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpCustomerIdPatch(requestParameters.id, requestParameters.external, requestParameters.identity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpCustomerPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpCustomerPatch(requestParameters: DefaultApiErpCustomerPatchRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpCustomerPatch(requestParameters.name, requestParameters.email, requestParameters.phone, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpCustomerPost(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpCustomerPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpFjCreateOrderFromACOrderIdPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpFjCreateOrderFromACOrderIdPost(requestParameters: DefaultApiErpFjCreateOrderFromACOrderIdPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpFjCreateOrderFromACOrderIdPost(requestParameters.orderId, requestParameters.deliveryDate, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpFjpartnerCreateOrderCpproIdPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpFjpartnerCreateOrderCpproIdPost(requestParameters: DefaultApiErpFjpartnerCreateOrderCpproIdPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpFjpartnerCreateOrderCpproIdPost(requestParameters.cpproId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpFjpartnerMigrateDemoOrderToLiveCpproIdPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpFjpartnerMigrateDemoOrderToLiveCpproIdPost(requestParameters: DefaultApiErpFjpartnerMigrateDemoOrderToLiveCpproIdPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpFjpartnerMigrateDemoOrderToLiveCpproIdPost(requestParameters.cpproId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpFjpartnerOrderCpproIdCreateExtraACOrderPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpFjpartnerOrderCpproIdCreateExtraACOrderPost(requestParameters: DefaultApiErpFjpartnerOrderCpproIdCreateExtraACOrderPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpFjpartnerOrderCpproIdCreateExtraACOrderPost(requestParameters.cpproId, requestParameters.test, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpFjpartnerOrderCpproIdInvoiceExtraACOrderPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpFjpartnerOrderCpproIdInvoiceExtraACOrderPost(requestParameters: DefaultApiErpFjpartnerOrderCpproIdInvoiceExtraACOrderPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpFjpartnerOrderCpproIdInvoiceExtraACOrderPost(requestParameters.cpproId, requestParameters.test, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpFjpartnerOrderCpproPartnerProgIdCreateCPProSalesOrderPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpFjpartnerOrderCpproPartnerProgIdCreateCPProSalesOrderPost(requestParameters: DefaultApiErpFjpartnerOrderCpproPartnerProgIdCreateCPProSalesOrderPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpFjpartnerOrderCpproPartnerProgIdCreateCPProSalesOrderPost(requestParameters.cpproPartnerProgId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpFjpartnerOrderCpproPartnerProgIdFindMRPOrderPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpFjpartnerOrderCpproPartnerProgIdFindMRPOrderPost(requestParameters: DefaultApiErpFjpartnerOrderCpproPartnerProgIdFindMRPOrderPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpFjpartnerOrderCpproPartnerProgIdFindMRPOrderPost(requestParameters.cpproPartnerProgId, requestParameters.acCPProId, requestParameters.dryRun, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpFjpartnerOrderCpproPartnerProgIdInstallCompleteWhenInstallerIsACPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpFjpartnerOrderCpproPartnerProgIdInstallCompleteWhenInstallerIsACPost(requestParameters: DefaultApiErpFjpartnerOrderCpproPartnerProgIdInstallCompleteWhenInstallerIsACPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpFjpartnerOrderCpproPartnerProgIdInstallCompleteWhenInstallerIsACPost(requestParameters.cpproPartnerProgId, requestParameters.acCPProId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpFjpartnerSyncPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpFjpartnerSyncPost(requestParameters: DefaultApiErpFjpartnerSyncPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpFjpartnerSyncPost(requestParameters.orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpManualOrderOrderIdAutomateOrderPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpManualOrderOrderIdAutomateOrderPost(requestParameters: DefaultApiErpManualOrderOrderIdAutomateOrderPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpManualOrderOrderIdAutomateOrderPost(requestParameters.orderId, requestParameters.test, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpManualOrderOrderIdInvoice24SOPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpManualOrderOrderIdInvoice24SOPost(requestParameters: DefaultApiErpManualOrderOrderIdInvoice24SOPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpManualOrderOrderIdInvoice24SOPost(requestParameters.orderId, requestParameters.test, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpMrpFindMRPOrderCpproIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpMrpFindMRPOrderCpproIdGet(requestParameters: DefaultApiErpMrpFindMRPOrderCpproIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpMrpFindMRPOrderCpproIdGet(requestParameters.cpproId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpMrpInvoiceOrderCpproIdPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpMrpInvoiceOrderCpproIdPost(requestParameters: DefaultApiErpMrpInvoiceOrderCpproIdPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpMrpInvoiceOrderCpproIdPost(requestParameters.cpproId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpMrpPickOrderCpproIdPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpMrpPickOrderCpproIdPost(requestParameters: DefaultApiErpMrpPickOrderCpproIdPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpMrpPickOrderCpproIdPost(requestParameters.cpproId, requestParameters.dryRun, requestParameters.manual, requestParameters.xCloudtasksTaskretrycount, requestParameters.xCloudtasksTaskexecutioncount, requestParameters.xCloudtasksTasketa, requestParameters.xCloudtasksQueuename, requestParameters.xCloudtasksTaskname, requestParameters.xCloudtasksTaskpreviousresponse, requestParameters.xCloudtasksTaskretryreason, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpMrpShipOrderCpproIdPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpMrpShipOrderCpproIdPost(requestParameters: DefaultApiErpMrpShipOrderCpproIdPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpMrpShipOrderCpproIdPost(requestParameters.cpproId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpOrderCpproIdActivityhelperlocalInsertCustomerAndOrderPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpOrderCpproIdActivityhelperlocalInsertCustomerAndOrderPost(requestParameters: DefaultApiErpOrderCpproIdActivityhelperlocalInsertCustomerAndOrderPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpOrderCpproIdActivityhelperlocalInsertCustomerAndOrderPost(requestParameters.cpproId, requestParameters.type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpOrderCpproIdCRMAddCustomerAndOrderPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpOrderCpproIdCRMAddCustomerAndOrderPost(requestParameters: DefaultApiErpOrderCpproIdCRMAddCustomerAndOrderPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpOrderCpproIdCRMAddCustomerAndOrderPost(requestParameters.cpproId, requestParameters.type, requestParameters.dryRun, requestParameters.fjOrder, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpOrderCpproIdCalendarCreateServiceEventPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpOrderCpproIdCalendarCreateServiceEventPost(requestParameters: DefaultApiErpOrderCpproIdCalendarCreateServiceEventPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpOrderCpproIdCalendarCreateServiceEventPost(requestParameters.cpproId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpOrderCpproIdCreditSaleMrpInvoicedPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpOrderCpproIdCreditSaleMrpInvoicedPost(requestParameters: DefaultApiErpOrderCpproIdCreditSaleMrpInvoicedPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpOrderCpproIdCreditSaleMrpInvoicedPost(requestParameters.cpproId, requestParameters.dryRun, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpOrderCpproIdInvoiceFJFinalOrderPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpOrderCpproIdInvoiceFJFinalOrderPost(requestParameters: DefaultApiErpOrderCpproIdInvoiceFJFinalOrderPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpOrderCpproIdInvoiceFJFinalOrderPost(requestParameters.cpproId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpOrderCpproIdLegacyServerAddOrderPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpOrderCpproIdLegacyServerAddOrderPost(requestParameters: DefaultApiErpOrderCpproIdLegacyServerAddOrderPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpOrderCpproIdLegacyServerAddOrderPost(requestParameters.cpproId, requestParameters.type, requestParameters.dryRun, requestParameters.fjOrder, requestParameters.includeVAT, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpOrderCpproIdRepairMrpInvoicedPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpOrderCpproIdRepairMrpInvoicedPost(requestParameters: DefaultApiErpOrderCpproIdRepairMrpInvoicedPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpOrderCpproIdRepairMrpInvoicedPost(requestParameters.cpproId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpOrderCpproIdSpreadsheetUpdate20502038PostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpOrderCpproIdSpreadsheetUpdate20502038Post(requestParameters: DefaultApiErpOrderCpproIdSpreadsheetUpdate20502038PostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpOrderCpproIdSpreadsheetUpdate20502038Post(requestParameters.cpproId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpOrderIdOrderlinesPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpOrderIdOrderlinesPost(requestParameters: DefaultApiErpOrderIdOrderlinesPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpOrderIdOrderlinesPost(requestParameters.id, requestParameters.external, requestParameters.identity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpOrderOrderIdSmsSendInvoiceLinkPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpOrderOrderIdSmsSendInvoiceLinkPost(requestParameters: DefaultApiErpOrderOrderIdSmsSendInvoiceLinkPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpOrderOrderIdSmsSendInvoiceLinkPost(requestParameters.orderId, requestParameters.isACErpId, requestParameters.ignoreSMS, requestParameters.isACT, requestParameters.test, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpOrderPost(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpOrderPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpRepairInvoiceACOrderCpproIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpRepairInvoiceACOrderCpproIdGet(requestParameters: DefaultApiErpRepairInvoiceACOrderCpproIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpRepairInvoiceACOrderCpproIdGet(requestParameters.cpproId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpScheduleFjFindNewOrdersPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpScheduleFjFindNewOrdersPost(requestParameters: DefaultApiErpScheduleFjFindNewOrdersPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpScheduleFjFindNewOrdersPost(requestParameters.changedAfter, requestParameters.fjOrderId, requestParameters.test, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpScheduleManualOrderFindManualOrdersPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpScheduleManualOrderFindManualOrdersPost(requestParameters: DefaultApiErpScheduleManualOrderFindManualOrdersPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpScheduleManualOrderFindManualOrdersPost(requestParameters.test, requestParameters.orderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpScheduleMrpSyncProductsToCPProPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpScheduleMrpSyncProductsToCPProPost(requestParameters: DefaultApiErpScheduleMrpSyncProductsToCPProPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpScheduleMrpSyncProductsToCPProPost(requestParameters.disableDelete, requestParameters.dryRun, requestParameters.itemNumber, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpScheduleOrderFindInvoicedOrdersPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpScheduleOrderFindInvoicedOrdersPost(requestParameters: DefaultApiErpScheduleOrderFindInvoicedOrdersPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpScheduleOrderFindInvoicedOrdersPost(requestParameters.demo, requestParameters.act, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpScheduleOrderWaitingForChecklistPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpScheduleOrderWaitingForChecklistPost(requestParameters: DefaultApiErpScheduleOrderWaitingForChecklistPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpScheduleOrderWaitingForChecklistPost(requestParameters.cpproId, requestParameters.dryRun, requestParameters.manual, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpScheduleSmsSendCalendarSMSRemindersPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpScheduleSmsSendCalendarSMSRemindersPost(requestParameters: DefaultApiErpScheduleSmsSendCalendarSMSRemindersPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpScheduleSmsSendCalendarSMSRemindersPost(requestParameters.event, requestParameters.optionalNow, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpScheduleUsersSyncEmployeeInfoToCPProPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpScheduleUsersSyncEmployeeInfoToCPProPost(requestParameters: DefaultApiErpScheduleUsersSyncEmployeeInfoToCPProPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpScheduleUsersSyncEmployeeInfoToCPProPost(requestParameters.dryRun, requestParameters.userEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiErpSync24SOToMRPGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        erpSync24SOToMRPGet(requestParameters: DefaultApiErpSync24SOToMRPGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.erpSync24SOToMRPGet(requestParameters.env, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiFjordersErpAgreedInstallationDateOrderOrderidGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fjordersErpAgreedInstallationDateOrderOrderidGet(requestParameters: DefaultApiFjordersErpAgreedInstallationDateOrderOrderidGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.fjordersErpAgreedInstallationDateOrderOrderidGet(requestParameters.orderid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiFjordersErpDeliveryaddressOrderOrderidGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fjordersErpDeliveryaddressOrderOrderidGet(requestParameters: DefaultApiFjordersErpDeliveryaddressOrderOrderidGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.fjordersErpDeliveryaddressOrderOrderidGet(requestParameters.orderid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiFjordersErpInstallerJobAcceptedOrderOrderidGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fjordersErpInstallerJobAcceptedOrderOrderidGet(requestParameters: DefaultApiFjordersErpInstallerJobAcceptedOrderOrderidGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.fjordersErpInstallerJobAcceptedOrderOrderidGet(requestParameters.orderid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiFjordersErpInstallerOrderOrderidGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fjordersErpInstallerOrderOrderidGet(requestParameters: DefaultApiFjordersErpInstallerOrderOrderidGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.fjordersErpInstallerOrderOrderidGet(requestParameters.orderid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiFjordersErpUpdateDeliveryDateOrderOrderidGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fjordersErpUpdateDeliveryDateOrderOrderidGet(requestParameters: DefaultApiFjordersErpUpdateDeliveryDateOrderOrderidGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.fjordersErpUpdateDeliveryDateOrderOrderidGet(requestParameters.orderid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiFjordersGmailMessageMessageidGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fjordersGmailMessageMessageidGet(requestParameters: DefaultApiFjordersGmailMessageMessageidGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.fjordersGmailMessageMessageidGet(requestParameters.messageid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiFjordersInstallationCompleteConfirmationOrderOrderidGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fjordersInstallationCompleteConfirmationOrderOrderidGet(requestParameters: DefaultApiFjordersInstallationCompleteConfirmationOrderOrderidGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.fjordersInstallationCompleteConfirmationOrderOrderidGet(requestParameters.orderid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fjordersOrderAllFixEmailMessageIdsGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.fjordersOrderAllFixEmailMessageIdsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fjordersOrderAllInstallationCompleteConfirmationGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.fjordersOrderAllInstallationCompleteConfirmationGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiFjordersOrderOrderidFixEmailMessageIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fjordersOrderOrderidFixEmailMessageIdGet(requestParameters: DefaultApiFjordersOrderOrderidFixEmailMessageIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.fjordersOrderOrderidFixEmailMessageIdGet(requestParameters.orderid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiFjordersOrderOrderidGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fjordersOrderOrderidGet(requestParameters: DefaultApiFjordersOrderOrderidGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.fjordersOrderOrderidGet(requestParameters.orderid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiFjordersOrderReferenceReferenceIdGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fjordersOrderReferenceReferenceIdGet(requestParameters: DefaultApiFjordersOrderReferenceReferenceIdGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.fjordersOrderReferenceReferenceIdGet(requestParameters.referenceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiFjordersOrdersCpproSyncGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fjordersOrdersCpproSyncGet(requestParameters: DefaultApiFjordersOrdersCpproSyncGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.fjordersOrdersCpproSyncGet(requestParameters.includeFinished, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fjordersOrdersCpproSyncOnlyDataGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.fjordersOrdersCpproSyncOnlyDataGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiFjordersReferenceReferenceFiksMonteringUtfortPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fjordersReferenceReferenceFiksMonteringUtfortPost(requestParameters: DefaultApiFjordersReferenceReferenceFiksMonteringUtfortPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.fjordersReferenceReferenceFiksMonteringUtfortPost(requestParameters.reference, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiFjordersUpdateProductOrderOrderidGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fjordersUpdateProductOrderOrderidGet(requestParameters: DefaultApiFjordersUpdateProductOrderOrderidGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.fjordersUpdateProductOrderOrderidGet(requestParameters.orderid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleCloudVisionTextDetectionPost(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.googleCloudVisionTextDetectionPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.healthGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiLeadManagementScheduleMoveLeadsPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadManagementScheduleMoveLeadsPost(requestParameters: DefaultApiLeadManagementScheduleMoveLeadsPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.leadManagementScheduleMoveLeadsPost(requestParameters.test, requestParameters.dryRun, requestParameters.dryrun, requestParameters.limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadManagementVendorPost(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.leadManagementVendorPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiLeadManagementVendorUserIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadManagementVendorUserIdDelete(requestParameters: DefaultApiLeadManagementVendorUserIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.leadManagementVendorUserIdDelete(requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiLeadManagementVendorUserIdPatchRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadManagementVendorUserIdPatch(requestParameters: DefaultApiLeadManagementVendorUserIdPatchRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.leadManagementVendorUserIdPatch(requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        leadManagementVendorsGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.leadManagementVendorsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiMonitorAlertIncidentGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertIncidentGet(requestParameters: DefaultApiMonitorAlertIncidentGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AlertPolicyIncidentsResponse> {
            return localVarFp.monitorAlertIncidentGet(requestParameters.authorization, requestParameters.query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiMonitorAlertIncidentIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertIncidentIdDelete(requestParameters: DefaultApiMonitorAlertIncidentIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.monitorAlertIncidentIdDelete(requestParameters.id, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiMonitorAlertIncidentIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertIncidentIdPut(requestParameters: DefaultApiMonitorAlertIncidentIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.monitorAlertIncidentIdPut(requestParameters.id, requestParameters.updateAlertPolicyIncidentCommand, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiMonitorAlertIncidentPubsubPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertIncidentPubsubPost(requestParameters: DefaultApiMonitorAlertIncidentPubsubPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.monitorAlertIncidentPubsubPost(requestParameters.pubSubPushRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiMonitorAlertNotificationChannelGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertNotificationChannelGet(requestParameters: DefaultApiMonitorAlertNotificationChannelGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<NotificationChannelsResponse> {
            return localVarFp.monitorAlertNotificationChannelGet(requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiMonitorAlertNotificationChannelIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertNotificationChannelIdDelete(requestParameters: DefaultApiMonitorAlertNotificationChannelIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.monitorAlertNotificationChannelIdDelete(requestParameters.id, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiMonitorAlertNotificationChannelIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertNotificationChannelIdPut(requestParameters: DefaultApiMonitorAlertNotificationChannelIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.monitorAlertNotificationChannelIdPut(requestParameters.id, requestParameters.updateNotificationChannelCommand, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiMonitorAlertNotificationChannelPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertNotificationChannelPost(requestParameters: DefaultApiMonitorAlertNotificationChannelPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.monitorAlertNotificationChannelPost(requestParameters.createNotificationChannelCommand, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiMonitorAlertPolicyGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertPolicyGet(requestParameters: DefaultApiMonitorAlertPolicyGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AlertPoliciesResponse> {
            return localVarFp.monitorAlertPolicyGet(requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiMonitorAlertPolicyIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertPolicyIdDelete(requestParameters: DefaultApiMonitorAlertPolicyIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.monitorAlertPolicyIdDelete(requestParameters.id, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiMonitorAlertPolicyIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertPolicyIdPut(requestParameters: DefaultApiMonitorAlertPolicyIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.monitorAlertPolicyIdPut(requestParameters.id, requestParameters.updateAlertPolicyCommand, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiMonitorAlertPolicyPolicyIdChannelChannelIdDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertPolicyPolicyIdChannelChannelIdDelete(requestParameters: DefaultApiMonitorAlertPolicyPolicyIdChannelChannelIdDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.monitorAlertPolicyPolicyIdChannelChannelIdDelete(requestParameters.policyId, requestParameters.channelId, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiMonitorAlertPolicyPolicyIdChannelChannelIdPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertPolicyPolicyIdChannelChannelIdPost(requestParameters: DefaultApiMonitorAlertPolicyPolicyIdChannelChannelIdPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.monitorAlertPolicyPolicyIdChannelChannelIdPost(requestParameters.policyId, requestParameters.channelId, requestParameters.createPolicyNotificationCommand, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiMonitorAlertPolicyPolicyIdChannelChannelIdPutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertPolicyPolicyIdChannelChannelIdPut(requestParameters: DefaultApiMonitorAlertPolicyPolicyIdChannelChannelIdPutRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.monitorAlertPolicyPolicyIdChannelChannelIdPut(requestParameters.policyId, requestParameters.channelId, requestParameters.updatePolicyNotificationCommand, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiMonitorAlertPolicyPolicyIdIncidentGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertPolicyPolicyIdIncidentGet(requestParameters: DefaultApiMonitorAlertPolicyPolicyIdIncidentGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<AlertPolicyIncidentsResponse> {
            return localVarFp.monitorAlertPolicyPolicyIdIncidentGet(requestParameters.policyId, requestParameters.authorization, requestParameters.query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiMonitorAlertPolicyPolicyIdIncidentPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertPolicyPolicyIdIncidentPost(requestParameters: DefaultApiMonitorAlertPolicyPolicyIdIncidentPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.monitorAlertPolicyPolicyIdIncidentPost(requestParameters.policyId, requestParameters.createAlertPolicyIncidentCommand, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiMonitorAlertPolicyPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertPolicyPost(requestParameters: DefaultApiMonitorAlertPolicyPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.monitorAlertPolicyPost(requestParameters.createAlertPolicyCommand, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiMonitorAlertSyncPolicyPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorAlertSyncPolicyPost(requestParameters: DefaultApiMonitorAlertSyncPolicyPostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.monitorAlertSyncPolicyPost(requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiMonitorEmailsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorEmailsGet(requestParameters: DefaultApiMonitorEmailsGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<GetEmailsResponse> {
            return localVarFp.monitorEmailsGet(requestParameters.authorization, requestParameters.query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiMonitorErpApiErrorsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorErpApiErrorsGet(requestParameters: DefaultApiMonitorErpApiErrorsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<LogEntriesResponse> {
            return localVarFp.monitorErpApiErrorsGet(requestParameters.dateCommand, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiMonitorErpErrorsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorErpErrorsGet(requestParameters: DefaultApiMonitorErpErrorsGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<GetErpErrorResponse> {
            return localVarFp.monitorErpErrorsGet(requestParameters.authorization, requestParameters.query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiMonitorLeadLogsValidationErrorsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorLeadLogsValidationErrorsGet(requestParameters: DefaultApiMonitorLeadLogsValidationErrorsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<LogEntriesResponse> {
            return localVarFp.monitorLeadLogsValidationErrorsGet(requestParameters.dateCommand, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiMonitorLeadRequestStatusCodesGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorLeadRequestStatusCodesGet(requestParameters: DefaultApiMonitorLeadRequestStatusCodesGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<LeadAPIRequests> {
            return localVarFp.monitorLeadRequestStatusCodesGet(requestParameters.dateCommand, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiMonitorLeadVendorsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorLeadVendorsGet(requestParameters: DefaultApiMonitorLeadVendorsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.monitorLeadVendorsGet(requestParameters.dateCommand, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiMonitorSmsGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorSmsGet(requestParameters: DefaultApiMonitorSmsGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<GetSMSesResponse> {
            return localVarFp.monitorSmsGet(requestParameters.getSMSesCommand, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiMonitorTasksQueuesWithTasksGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorTasksQueuesWithTasksGet(requestParameters: DefaultApiMonitorTasksQueuesWithTasksGetRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<QueueWithTasksResponse> {
            return localVarFp.monitorTasksQueuesWithTasksGet(requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiMonitorTasksTaskDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorTasksTaskDelete(requestParameters: DefaultApiMonitorTasksTaskDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.monitorTasksTaskDelete(requestParameters.taskPathCommand, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiMonitorTasksTaskRunPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        monitorTasksTaskRunPost(requestParameters: DefaultApiMonitorTasksTaskRunPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.monitorTasksTaskRunPost(requestParameters.taskPathCommand, requestParameters.authorization, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiOrderconfirmationOrderOrderidGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        orderconfirmationOrderOrderidGet(requestParameters: DefaultApiOrderconfirmationOrderOrderidGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.orderconfirmationOrderOrderidGet(requestParameters.orderid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerCreateOrderPost(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.partnerCreateOrderPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerHealthGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.partnerHealthGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiPartnerOrdersPartnerReferenceGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerOrdersPartnerReferenceGet(requestParameters: DefaultApiPartnerOrdersPartnerReferenceGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.partnerOrdersPartnerReferenceGet(requestParameters.partnerReference, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiPartnerWebhooksPartnerReferenceDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerWebhooksPartnerReferenceDelete(requestParameters: DefaultApiPartnerWebhooksPartnerReferenceDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.partnerWebhooksPartnerReferenceDelete(requestParameters.partnerReference, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiPartnerWebhooksPartnerReferencePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerWebhooksPartnerReferencePost(requestParameters: DefaultApiPartnerWebhooksPartnerReferencePostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.partnerWebhooksPartnerReferencePost(requestParameters.partnerReference, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiPartnerWebhooksPartnerReferencePutRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partnerWebhooksPartnerReferencePut(requestParameters: DefaultApiPartnerWebhooksPartnerReferencePutRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.partnerWebhooksPartnerReferencePut(requestParameters.partnerReference, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiPublicCalendarNotificationHandlePostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicCalendarNotificationHandlePost(requestParameters: DefaultApiPublicCalendarNotificationHandlePostRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.publicCalendarNotificationHandlePost(requestParameters.xGoogResourceState, requestParameters.xGoogChannelExpiration, requestParameters.xGoogChannelToken, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publicSMSHandleReplyPost(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.publicSMSHandleReplyPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiQuoteOrderOrderidGetRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        quoteOrderOrderidGet(requestParameters: DefaultApiQuoteOrderOrderidGetRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.quoteOrderOrderidGet(requestParameters.orderid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smsInsertPost(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.smsInsertPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiSmsSendMultipleGuidPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smsSendMultipleGuidPost(requestParameters: DefaultApiSmsSendMultipleGuidPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.smsSendMultipleGuidPost(requestParameters.guid, requestParameters.test, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiSmsSendSmsIdPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        smsSendSmsIdPost(requestParameters: DefaultApiSmsSendSmsIdPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.smsSendSmsIdPost(requestParameters.smsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        swaggerJsonGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.swaggerJsonGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAllCPProGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.syncAllCPProGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncAllGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.syncAllGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncCampaignCodeGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.syncCampaignCodeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncConstantGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.syncConstantGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncCpproGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.syncCpproGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncInstallerGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.syncInstallerGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncLeadGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.syncLeadGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DefaultApiSyncOrderCpproOrderIdPostRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncOrderCpproOrderIdPost(requestParameters: DefaultApiSyncOrderCpproOrderIdPostRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.syncOrderCpproOrderIdPost(requestParameters.cpproOrderId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncOrderGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.syncOrderGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncOrdersAllGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.syncOrdersAllGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncOrdersAllInstallersGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.syncOrdersAllInstallersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncOrdersAllMasterGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.syncOrdersAllMasterGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncOrdersAllPartnersGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.syncOrdersAllPartnersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncOrdersCompletedGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.syncOrdersCompletedGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncOrdersCompletedInstallersGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.syncOrdersCompletedInstallersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncOrdersCompletedMasterGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.syncOrdersCompletedMasterGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncOrdersCompletedPartnersGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.syncOrdersCompletedPartnersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncOrdersInstallersGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.syncOrdersInstallersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncOrdersMasterGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.syncOrdersMasterGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncOrdersPartnersGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.syncOrdersPartnersGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncParameterGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.syncParameterGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncPartnerGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.syncPartnerGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncProductGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.syncProductGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncShareInstallerSheetsGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.syncShareInstallerSheetsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tasksCheckTasksPost(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.tasksCheckTasksPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.testGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testRequestipsGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.testRequestipsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testerrorGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.testerrorGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testerrornotifyGet(options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.testerrornotifyGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiCRMCustomerCustomerIdAquireLockPost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiCRMCustomerCustomerIdAquireLockPostRequest
 */
export interface DefaultApiApiCRMCustomerCustomerIdAquireLockPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiCRMCustomerCustomerIdAquireLockPost
     */
    readonly customerId: string

    /**
     * 
     * @type {PostCRMCustomerAquireLockRequest}
     * @memberof DefaultApiApiCRMCustomerCustomerIdAquireLockPost
     */
    readonly postCRMCustomerAquireLockRequest: PostCRMCustomerAquireLockRequest

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiCRMCustomerCustomerIdAquireLockPost
     */
    readonly authorization?: string
}

/**
 * Request parameters for apiCRMCustomerCustomerIdReleaseLockDelete operation in DefaultApi.
 * @export
 * @interface DefaultApiApiCRMCustomerCustomerIdReleaseLockDeleteRequest
 */
export interface DefaultApiApiCRMCustomerCustomerIdReleaseLockDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiCRMCustomerCustomerIdReleaseLockDelete
     */
    readonly customerId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiCRMCustomerCustomerIdReleaseLockDelete
     */
    readonly authorization?: string
}

/**
 * Request parameters for apiCRMCustomerLocksGet operation in DefaultApi.
 * @export
 * @interface DefaultApiApiCRMCustomerLocksGetRequest
 */
export interface DefaultApiApiCRMCustomerLocksGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiCRMCustomerLocksGet
     */
    readonly authorization?: string
}

/**
 * Request parameters for apiCRMDepartmentAreasGet operation in DefaultApi.
 * @export
 * @interface DefaultApiApiCRMDepartmentAreasGetRequest
 */
export interface DefaultApiApiCRMDepartmentAreasGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiCRMDepartmentAreasGet
     */
    readonly authorization?: string
}

/**
 * Request parameters for apiCRMSMSTemplatesGet operation in DefaultApi.
 * @export
 * @interface DefaultApiApiCRMSMSTemplatesGetRequest
 */
export interface DefaultApiApiCRMSMSTemplatesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiCRMSMSTemplatesGet
     */
    readonly authorization?: string
}

/**
 * Request parameters for apiCalendarNotificationChannelPost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiCalendarNotificationChannelPostRequest
 */
export interface DefaultApiApiCalendarNotificationChannelPostRequest {
    /**
     * 
     * @type {CreateNotificationChannelRequest}
     * @memberof DefaultApiApiCalendarNotificationChannelPost
     */
    readonly createNotificationChannelRequest: CreateNotificationChannelRequest

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiCalendarNotificationChannelPost
     */
    readonly authorization?: string
}

/**
 * Request parameters for apiConstantGet operation in DefaultApi.
 * @export
 * @interface DefaultApiApiConstantGetRequest
 */
export interface DefaultApiApiConstantGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiConstantGet
     */
    readonly authorization?: string
}

/**
 * Request parameters for apiConstantKeyDelete operation in DefaultApi.
 * @export
 * @interface DefaultApiApiConstantKeyDeleteRequest
 */
export interface DefaultApiApiConstantKeyDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiConstantKeyDelete
     */
    readonly key: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiConstantKeyDelete
     */
    readonly authorization?: string
}

/**
 * Request parameters for apiConstantKeyGet operation in DefaultApi.
 * @export
 * @interface DefaultApiApiConstantKeyGetRequest
 */
export interface DefaultApiApiConstantKeyGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiConstantKeyGet
     */
    readonly key: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiConstantKeyGet
     */
    readonly authorization?: string
}

/**
 * Request parameters for apiConstantKeyVersionPost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiConstantKeyVersionPostRequest
 */
export interface DefaultApiApiConstantKeyVersionPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiConstantKeyVersionPost
     */
    readonly key: string

    /**
     * 
     * @type {PostConstantRequest}
     * @memberof DefaultApiApiConstantKeyVersionPost
     */
    readonly postConstantRequest: PostConstantRequest

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiConstantKeyVersionPost
     */
    readonly authorization?: string
}

/**
 * Request parameters for apiConstantKeyVersionVersionDelete operation in DefaultApi.
 * @export
 * @interface DefaultApiApiConstantKeyVersionVersionDeleteRequest
 */
export interface DefaultApiApiConstantKeyVersionVersionDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiConstantKeyVersionVersionDelete
     */
    readonly key: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiConstantKeyVersionVersionDelete
     */
    readonly version: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiConstantKeyVersionVersionDelete
     */
    readonly authorization?: string
}

/**
 * Request parameters for apiConstantKeyVersionVersionDisablePost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiConstantKeyVersionVersionDisablePostRequest
 */
export interface DefaultApiApiConstantKeyVersionVersionDisablePostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiConstantKeyVersionVersionDisablePost
     */
    readonly key: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiConstantKeyVersionVersionDisablePost
     */
    readonly version: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiConstantKeyVersionVersionDisablePost
     */
    readonly authorization?: string
}

/**
 * Request parameters for apiConstantKeyVersionVersionEnablePost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiConstantKeyVersionVersionEnablePostRequest
 */
export interface DefaultApiApiConstantKeyVersionVersionEnablePostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiConstantKeyVersionVersionEnablePost
     */
    readonly key: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiConstantKeyVersionVersionEnablePost
     */
    readonly version: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiConstantKeyVersionVersionEnablePost
     */
    readonly authorization?: string
}

/**
 * Request parameters for apiConstantPost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiConstantPostRequest
 */
export interface DefaultApiApiConstantPostRequest {
    /**
     * 
     * @type {PostConstantRequest}
     * @memberof DefaultApiApiConstantPost
     */
    readonly postConstantRequest: PostConstantRequest

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiConstantPost
     */
    readonly authorization?: string
}

/**
 * Request parameters for apiCppro24soAcErpIdCreateOrderPost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiCppro24soAcErpIdCreateOrderPostRequest
 */
export interface DefaultApiApiCppro24soAcErpIdCreateOrderPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiCppro24soAcErpIdCreateOrderPost
     */
    readonly acErpId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiCppro24soAcErpIdCreateOrderPost
     */
    readonly authorization?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiCppro24soAcErpIdCreateOrderPost
     */
    readonly client?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiCppro24soAcErpIdCreateOrderPost
     */
    readonly demo?: string
}

/**
 * Request parameters for apiCpproCreateorderOrderOrderidPost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiCpproCreateorderOrderOrderidPostRequest
 */
export interface DefaultApiApiCpproCreateorderOrderOrderidPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiCpproCreateorderOrderOrderidPost
     */
    readonly orderid: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiCpproCreateorderOrderOrderidPost
     */
    readonly authorization?: string
}

/**
 * Request parameters for apiCpproMamutOrderIdCreateOrderPost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiCpproMamutOrderIdCreateOrderPostRequest
 */
export interface DefaultApiApiCpproMamutOrderIdCreateOrderPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiCpproMamutOrderIdCreateOrderPost
     */
    readonly orderId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiCpproMamutOrderIdCreateOrderPost
     */
    readonly authorization?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiCpproMamutOrderIdCreateOrderPost
     */
    readonly client?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiCpproMamutOrderIdCreateOrderPost
     */
    readonly demo?: string
}

/**
 * Request parameters for apiCpproOrderCreateSalesOrderChecklistPost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiCpproOrderCreateSalesOrderChecklistPostRequest
 */
export interface DefaultApiApiCpproOrderCreateSalesOrderChecklistPostRequest {
    /**
     * 
     * @type {CreateSalesOrderChecklistRequest}
     * @memberof DefaultApiApiCpproOrderCreateSalesOrderChecklistPost
     */
    readonly createSalesOrderChecklistRequest: CreateSalesOrderChecklistRequest

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiCpproOrderCreateSalesOrderChecklistPost
     */
    readonly authorization?: string
}

/**
 * Request parameters for apiCpproOrderCreateServiceOrderChecklistPost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiCpproOrderCreateServiceOrderChecklistPostRequest
 */
export interface DefaultApiApiCpproOrderCreateServiceOrderChecklistPostRequest {
    /**
     * 
     * @type {CreateServiceOrderChecklistRequest}
     * @memberof DefaultApiApiCpproOrderCreateServiceOrderChecklistPost
     */
    readonly createServiceOrderChecklistRequest: CreateServiceOrderChecklistRequest

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiCpproOrderCreateServiceOrderChecklistPost
     */
    readonly authorization?: string
}

/**
 * Request parameters for apiDatePublicHolidaysGet operation in DefaultApi.
 * @export
 * @interface DefaultApiApiDatePublicHolidaysGetRequest
 */
export interface DefaultApiApiDatePublicHolidaysGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiDatePublicHolidaysGet
     */
    readonly authorization?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiDatePublicHolidaysGet
     */
    readonly all?: string
}

/**
 * Request parameters for apiDatePublicHolidaysPost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiDatePublicHolidaysPostRequest
 */
export interface DefaultApiApiDatePublicHolidaysPostRequest {
    /**
     * 
     * @type {PostPublicHolidaysRequest}
     * @memberof DefaultApiApiDatePublicHolidaysPost
     */
    readonly postPublicHolidaysRequest: PostPublicHolidaysRequest

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiDatePublicHolidaysPost
     */
    readonly authorization?: string
}

/**
 * Request parameters for apiErp24soForhandlerSearchGet operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErp24soForhandlerSearchGetRequest
 */
export interface DefaultApiApiErp24soForhandlerSearchGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErp24soForhandlerSearchGet
     */
    readonly authorization?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErp24soForhandlerSearchGet
     */
    readonly externalId?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErp24soForhandlerSearchGet
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErp24soForhandlerSearchGet
     */
    readonly isACT?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErp24soForhandlerSearchGet
     */
    readonly test?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErp24soForhandlerSearchGet
     */
    readonly search24SOAC?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErp24soForhandlerSearchGet
     */
    readonly returnProps?: string
}

/**
 * Request parameters for apiErpAcCalendarDeliveryDateChangePost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErpAcCalendarDeliveryDateChangePostRequest
 */
export interface DefaultApiApiErpAcCalendarDeliveryDateChangePostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpAcCalendarDeliveryDateChangePost
     */
    readonly authorization?: string

    /**
     * 
     * @type {ApiErpAcCalendarDeliveryDateChangePostRequest}
     * @memberof DefaultApiApiErpAcCalendarDeliveryDateChangePost
     */
    readonly apiErpAcCalendarDeliveryDateChangePostRequest?: ApiErpAcCalendarDeliveryDateChangePostRequest
}

/**
 * Request parameters for apiErpAcOrderCpproIdInactivatePost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErpAcOrderCpproIdInactivatePostRequest
 */
export interface DefaultApiApiErpAcOrderCpproIdInactivatePostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpAcOrderCpproIdInactivatePost
     */
    readonly cpproId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpAcOrderCpproIdInactivatePost
     */
    readonly authorization?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpAcOrderCpproIdInactivatePost
     */
    readonly dryRun?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpAcOrderCpproIdInactivatePost
     */
    readonly test?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpAcOrderCpproIdInactivatePost
     */
    readonly shouldDeleteTasks?: string
}

/**
 * Request parameters for apiErpErpSyncOrderGet operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErpErpSyncOrderGetRequest
 */
export interface DefaultApiApiErpErpSyncOrderGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpErpSyncOrderGet
     */
    readonly authorization?: string

    /**
     * 
     * @type {GetErpSyncOrderCommand}
     * @memberof DefaultApiApiErpErpSyncOrderGet
     */
    readonly query?: GetErpSyncOrderCommand
}

/**
 * Request parameters for apiErpErpSyncOrdersGet operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErpErpSyncOrdersGetRequest
 */
export interface DefaultApiApiErpErpSyncOrdersGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpErpSyncOrdersGet
     */
    readonly authorization?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpErpSyncOrdersGet
     */
    readonly startTime?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpErpSyncOrdersGet
     */
    readonly endTime?: string

    /**
     * 
     * @type {DateCommand}
     * @memberof DefaultApiApiErpErpSyncOrdersGet
     */
    readonly query?: DateCommand
}

/**
 * Request parameters for apiErpInstallItemUsageGet operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErpInstallItemUsageGetRequest
 */
export interface DefaultApiApiErpInstallItemUsageGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpInstallItemUsageGet
     */
    readonly authorization?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpInstallItemUsageGet
     */
    readonly ignoreCache?: string
}

/**
 * Request parameters for apiErpInstallItemUsageItemNumberAlternativePost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErpInstallItemUsageItemNumberAlternativePostRequest
 */
export interface DefaultApiApiErpInstallItemUsageItemNumberAlternativePostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpInstallItemUsageItemNumberAlternativePost
     */
    readonly itemNumber: string

    /**
     * 
     * @type {PostInstallItemUsageAlternativeRequest}
     * @memberof DefaultApiApiErpInstallItemUsageItemNumberAlternativePost
     */
    readonly postInstallItemUsageAlternativeRequest: PostInstallItemUsageAlternativeRequest

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpInstallItemUsageItemNumberAlternativePost
     */
    readonly authorization?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpInstallItemUsageItemNumberAlternativePost
     */
    readonly ignoreCache?: string
}

/**
 * Request parameters for apiErpInstallItemUsageItemNumberDelete operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErpInstallItemUsageItemNumberDeleteRequest
 */
export interface DefaultApiApiErpInstallItemUsageItemNumberDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpInstallItemUsageItemNumberDelete
     */
    readonly itemNumber: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpInstallItemUsageItemNumberDelete
     */
    readonly authorization?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpInstallItemUsageItemNumberDelete
     */
    readonly ignoreCache?: string
}

/**
 * Request parameters for apiErpInstallItemUsageItemNumberPut operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErpInstallItemUsageItemNumberPutRequest
 */
export interface DefaultApiApiErpInstallItemUsageItemNumberPutRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpInstallItemUsageItemNumberPut
     */
    readonly itemNumber: string

    /**
     * 
     * @type {PutInstallItemUsageRequest}
     * @memberof DefaultApiApiErpInstallItemUsageItemNumberPut
     */
    readonly putInstallItemUsageRequest: PutInstallItemUsageRequest

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpInstallItemUsageItemNumberPut
     */
    readonly authorization?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpInstallItemUsageItemNumberPut
     */
    readonly ignoreCache?: string
}

/**
 * Request parameters for apiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdDelete operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdDeleteRequest
 */
export interface DefaultApiApiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdDelete
     */
    readonly itemUsageId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdDelete
     */
    readonly alternativeId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdDelete
     */
    readonly authorization?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdDelete
     */
    readonly ignoreCache?: string
}

/**
 * Request parameters for apiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdPut operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdPutRequest
 */
export interface DefaultApiApiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdPut
     */
    readonly itemUsageId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdPut
     */
    readonly alternativeId: string

    /**
     * 
     * @type {PutInstallItemUsageAlternativeRequest}
     * @memberof DefaultApiApiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdPut
     */
    readonly putInstallItemUsageAlternativeRequest: PutInstallItemUsageAlternativeRequest

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdPut
     */
    readonly authorization?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdPut
     */
    readonly ignoreCache?: string
}

/**
 * Request parameters for apiErpInstallItemUsagePost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErpInstallItemUsagePostRequest
 */
export interface DefaultApiApiErpInstallItemUsagePostRequest {
    /**
     * 
     * @type {PostInstallItemUsageRequest}
     * @memberof DefaultApiApiErpInstallItemUsagePost
     */
    readonly postInstallItemUsageRequest: PostInstallItemUsageRequest

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpInstallItemUsagePost
     */
    readonly authorization?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpInstallItemUsagePost
     */
    readonly ignoreCache?: string
}

/**
 * Request parameters for apiErpInstallOrderInstallCPproIdChecklistInstalledPost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErpInstallOrderInstallCPproIdChecklistInstalledPostRequest
 */
export interface DefaultApiApiErpInstallOrderInstallCPproIdChecklistInstalledPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpInstallOrderInstallCPproIdChecklistInstalledPost
     */
    readonly installCPproId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpInstallOrderInstallCPproIdChecklistInstalledPost
     */
    readonly authorization?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpInstallOrderInstallCPproIdChecklistInstalledPost
     */
    readonly continueProcess?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpInstallOrderInstallCPproIdChecklistInstalledPost
     */
    readonly dryRun?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpInstallOrderInstallCPproIdChecklistInstalledPost
     */
    readonly manual?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpInstallOrderInstallCPproIdChecklistInstalledPost
     */
    readonly enableDelete?: string

    /**
     * 
     * @type {CommonQueryParams}
     * @memberof DefaultApiApiErpInstallOrderInstallCPproIdChecklistInstalledPost
     */
    readonly query?: CommonQueryParams
}

/**
 * Request parameters for apiErpMrpItemItemNumberStockGet operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErpMrpItemItemNumberStockGetRequest
 */
export interface DefaultApiApiErpMrpItemItemNumberStockGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpMrpItemItemNumberStockGet
     */
    readonly itemNumber: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpMrpItemItemNumberStockGet
     */
    readonly authorization?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpMrpItemItemNumberStockGet
     */
    readonly test?: string
}

/**
 * Request parameters for apiErpMrpOrderCpproInstallIdCheckSerialNumberGet operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErpMrpOrderCpproInstallIdCheckSerialNumberGetRequest
 */
export interface DefaultApiApiErpMrpOrderCpproInstallIdCheckSerialNumberGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpMrpOrderCpproInstallIdCheckSerialNumberGet
     */
    readonly cpproInstallId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpMrpOrderCpproInstallIdCheckSerialNumberGet
     */
    readonly authorization?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpMrpOrderCpproInstallIdCheckSerialNumberGet
     */
    readonly questionId?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpMrpOrderCpproInstallIdCheckSerialNumberGet
     */
    readonly serialNumber?: string
}

/**
 * Request parameters for apiErpMrpOrderOrderIdPickErrorsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErpMrpOrderOrderIdPickErrorsGetRequest
 */
export interface DefaultApiApiErpMrpOrderOrderIdPickErrorsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpMrpOrderOrderIdPickErrorsGet
     */
    readonly orderId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpMrpOrderOrderIdPickErrorsGet
     */
    readonly authorization?: string
}

/**
 * Request parameters for apiErpMrpOrderStatisticsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErpMrpOrderStatisticsGetRequest
 */
export interface DefaultApiApiErpMrpOrderStatisticsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpMrpOrderStatisticsGet
     */
    readonly authorization?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpMrpOrderStatisticsGet
     */
    readonly startTime?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpMrpOrderStatisticsGet
     */
    readonly endTime?: string

    /**
     * 
     * @type {DateCommand}
     * @memberof DefaultApiApiErpMrpOrderStatisticsGet
     */
    readonly query?: DateCommand
}

/**
 * Request parameters for apiErpMrpPickErrorDeviationReasonDeviationReasonIdDelete operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErpMrpPickErrorDeviationReasonDeviationReasonIdDeleteRequest
 */
export interface DefaultApiApiErpMrpPickErrorDeviationReasonDeviationReasonIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpMrpPickErrorDeviationReasonDeviationReasonIdDelete
     */
    readonly deviationReasonId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpMrpPickErrorDeviationReasonDeviationReasonIdDelete
     */
    readonly authorization?: string
}

/**
 * Request parameters for apiErpMrpPickErrorDeviationReasonDeviationReasonIdPut operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErpMrpPickErrorDeviationReasonDeviationReasonIdPutRequest
 */
export interface DefaultApiApiErpMrpPickErrorDeviationReasonDeviationReasonIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpMrpPickErrorDeviationReasonDeviationReasonIdPut
     */
    readonly deviationReasonId: string

    /**
     * 
     * @type {PutPostPickErrorDeviationReasonRequest}
     * @memberof DefaultApiApiErpMrpPickErrorDeviationReasonDeviationReasonIdPut
     */
    readonly putPostPickErrorDeviationReasonRequest: PutPostPickErrorDeviationReasonRequest

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpMrpPickErrorDeviationReasonDeviationReasonIdPut
     */
    readonly authorization?: string
}

/**
 * Request parameters for apiErpMrpPickErrorDeviationReasonPost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErpMrpPickErrorDeviationReasonPostRequest
 */
export interface DefaultApiApiErpMrpPickErrorDeviationReasonPostRequest {
    /**
     * 
     * @type {PutPostPickErrorDeviationReasonRequest}
     * @memberof DefaultApiApiErpMrpPickErrorDeviationReasonPost
     */
    readonly putPostPickErrorDeviationReasonRequest: PutPostPickErrorDeviationReasonRequest

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpMrpPickErrorDeviationReasonPost
     */
    readonly authorization?: string
}

/**
 * Request parameters for apiErpMrpPickErrorDeviationReasonsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErpMrpPickErrorDeviationReasonsGetRequest
 */
export interface DefaultApiApiErpMrpPickErrorDeviationReasonsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpMrpPickErrorDeviationReasonsGet
     */
    readonly authorization?: string
}

/**
 * Request parameters for apiErpMrpPickErrorPickErrorIdPut operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErpMrpPickErrorPickErrorIdPutRequest
 */
export interface DefaultApiApiErpMrpPickErrorPickErrorIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpMrpPickErrorPickErrorIdPut
     */
    readonly pickErrorId: string

    /**
     * 
     * @type {PutMrpPickErrorRequest}
     * @memberof DefaultApiApiErpMrpPickErrorPickErrorIdPut
     */
    readonly putMrpPickErrorRequest: PutMrpPickErrorRequest

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpMrpPickErrorPickErrorIdPut
     */
    readonly authorization?: string
}

/**
 * Request parameters for apiErpMrpPickErrorsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErpMrpPickErrorsGetRequest
 */
export interface DefaultApiApiErpMrpPickErrorsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpMrpPickErrorsGet
     */
    readonly authorization?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpMrpPickErrorsGet
     */
    readonly status?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpMrpPickErrorsGet
     */
    readonly startTime?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpMrpPickErrorsGet
     */
    readonly endTime?: string

    /**
     * 
     * @type {GetMrpPickErrorsRequest}
     * @memberof DefaultApiApiErpMrpPickErrorsGet
     */
    readonly query?: GetMrpPickErrorsRequest
}

/**
 * Request parameters for apiErpMrpPickErrorsPut operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErpMrpPickErrorsPutRequest
 */
export interface DefaultApiApiErpMrpPickErrorsPutRequest {
    /**
     * 
     * @type {PutMrpPickErrorsRequest}
     * @memberof DefaultApiApiErpMrpPickErrorsPut
     */
    readonly putMrpPickErrorsRequest: PutMrpPickErrorsRequest

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpMrpPickErrorsPut
     */
    readonly authorization?: string
}

/**
 * Request parameters for apiErpOrder24soCreateOrderTaskPost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErpOrder24soCreateOrderTaskPostRequest
 */
export interface DefaultApiApiErpOrder24soCreateOrderTaskPostRequest {
    /**
     * 
     * @type {PostCreateOrderRequest}
     * @memberof DefaultApiApiErpOrder24soCreateOrderTaskPost
     */
    readonly postCreateOrderRequest: PostCreateOrderRequest

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrder24soCreateOrderTaskPost
     */
    readonly authorization?: string
}

/**
 * Request parameters for apiErpOrderCpproId24soACInvoicePost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErpOrderCpproId24soACInvoicePostRequest
 */
export interface DefaultApiApiErpOrderCpproId24soACInvoicePostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproId24soACInvoicePost
     */
    readonly cpproId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproId24soACInvoicePost
     */
    readonly authorization?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproId24soACInvoicePost
     */
    readonly dryRun?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproId24soACInvoicePost
     */
    readonly manual?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproId24soACInvoicePost
     */
    readonly enableDelete?: string

    /**
     * 
     * @type {CommonQueryParams}
     * @memberof DefaultApiApiErpOrderCpproId24soACInvoicePost
     */
    readonly query?: CommonQueryParams
}

/**
 * Request parameters for apiErpOrderCpproId24soInactivateFJAndInvoiceACPost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErpOrderCpproId24soInactivateFJAndInvoiceACPostRequest
 */
export interface DefaultApiApiErpOrderCpproId24soInactivateFJAndInvoiceACPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproId24soInactivateFJAndInvoiceACPost
     */
    readonly cpproId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproId24soInactivateFJAndInvoiceACPost
     */
    readonly authorization?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproId24soInactivateFJAndInvoiceACPost
     */
    readonly dryRun?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproId24soInactivateFJAndInvoiceACPost
     */
    readonly manual?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproId24soInactivateFJAndInvoiceACPost
     */
    readonly enableDelete?: string

    /**
     * 
     * @type {CommonQueryParams}
     * @memberof DefaultApiApiErpOrderCpproId24soInactivateFJAndInvoiceACPost
     */
    readonly query?: CommonQueryParams
}

/**
 * Request parameters for apiErpOrderCpproIdCheckEnTurTilOrderInvoicedGet operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErpOrderCpproIdCheckEnTurTilOrderInvoicedGetRequest
 */
export interface DefaultApiApiErpOrderCpproIdCheckEnTurTilOrderInvoicedGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproIdCheckEnTurTilOrderInvoicedGet
     */
    readonly cpproId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproIdCheckEnTurTilOrderInvoicedGet
     */
    readonly authorization?: string
}

/**
 * Request parameters for apiErpOrderCpproIdMrpFindMRPOrderPost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErpOrderCpproIdMrpFindMRPOrderPostRequest
 */
export interface DefaultApiApiErpOrderCpproIdMrpFindMRPOrderPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproIdMrpFindMRPOrderPost
     */
    readonly cpproId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproIdMrpFindMRPOrderPost
     */
    readonly authorization?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproIdMrpFindMRPOrderPost
     */
    readonly dryRun?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproIdMrpFindMRPOrderPost
     */
    readonly manual?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproIdMrpFindMRPOrderPost
     */
    readonly enableDelete?: string

    /**
     * 
     * @type {CommonQueryParams}
     * @memberof DefaultApiApiErpOrderCpproIdMrpFindMRPOrderPost
     */
    readonly query?: CommonQueryParams
}

/**
 * Request parameters for apiErpOrderCpproIdMrpInvoicePost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErpOrderCpproIdMrpInvoicePostRequest
 */
export interface DefaultApiApiErpOrderCpproIdMrpInvoicePostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproIdMrpInvoicePost
     */
    readonly cpproId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproIdMrpInvoicePost
     */
    readonly authorization?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproIdMrpInvoicePost
     */
    readonly dryRun?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproIdMrpInvoicePost
     */
    readonly manual?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproIdMrpInvoicePost
     */
    readonly enableDelete?: string

    /**
     * 
     * @type {CommonQueryParams}
     * @memberof DefaultApiApiErpOrderCpproIdMrpInvoicePost
     */
    readonly query?: CommonQueryParams
}

/**
 * Request parameters for apiErpOrderCpproIdMrpManuallyInvoicedPost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErpOrderCpproIdMrpManuallyInvoicedPostRequest
 */
export interface DefaultApiApiErpOrderCpproIdMrpManuallyInvoicedPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproIdMrpManuallyInvoicedPost
     */
    readonly cpproId: string

    /**
     * 
     * @type {PostMRPManuallyInvoicedRequest}
     * @memberof DefaultApiApiErpOrderCpproIdMrpManuallyInvoicedPost
     */
    readonly postMRPManuallyInvoicedRequest: PostMRPManuallyInvoicedRequest

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproIdMrpManuallyInvoicedPost
     */
    readonly authorization?: string

    /**
     * 
     * @type {CommonQueryParams}
     * @memberof DefaultApiApiErpOrderCpproIdMrpManuallyInvoicedPost
     */
    readonly query?: CommonQueryParams
}

/**
 * Request parameters for apiErpOrderCpproIdMrpPickPost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErpOrderCpproIdMrpPickPostRequest
 */
export interface DefaultApiApiErpOrderCpproIdMrpPickPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproIdMrpPickPost
     */
    readonly cpproId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproIdMrpPickPost
     */
    readonly authorization?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproIdMrpPickPost
     */
    readonly dryRun?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproIdMrpPickPost
     */
    readonly manual?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproIdMrpPickPost
     */
    readonly enableDelete?: string

    /**
     * 
     * @type {CommonQueryParams}
     * @memberof DefaultApiApiErpOrderCpproIdMrpPickPost
     */
    readonly query?: CommonQueryParams
}

/**
 * Request parameters for apiErpOrderCpproIdMrpSalesOrderGet operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErpOrderCpproIdMrpSalesOrderGetRequest
 */
export interface DefaultApiApiErpOrderCpproIdMrpSalesOrderGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproIdMrpSalesOrderGet
     */
    readonly cpproId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproIdMrpSalesOrderGet
     */
    readonly authorization?: string

    /**
     * 
     * @type {CommonQueryParams}
     * @memberof DefaultApiApiErpOrderCpproIdMrpSalesOrderGet
     */
    readonly query?: CommonQueryParams
}

/**
 * Request parameters for apiErpOrderCpproIdMrpShipPost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErpOrderCpproIdMrpShipPostRequest
 */
export interface DefaultApiApiErpOrderCpproIdMrpShipPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproIdMrpShipPost
     */
    readonly cpproId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproIdMrpShipPost
     */
    readonly authorization?: string
}

/**
 * Request parameters for apiErpOrderCpproIdRecreateOrderPost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErpOrderCpproIdRecreateOrderPostRequest
 */
export interface DefaultApiApiErpOrderCpproIdRecreateOrderPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproIdRecreateOrderPost
     */
    readonly cpproId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproIdRecreateOrderPost
     */
    readonly authorization?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpOrderCpproIdRecreateOrderPost
     */
    readonly deliveryDate?: string
}

/**
 * Request parameters for apiErpSmsSendCalendarRemindersPost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiErpSmsSendCalendarRemindersPostRequest
 */
export interface DefaultApiApiErpSmsSendCalendarRemindersPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpSmsSendCalendarRemindersPost
     */
    readonly authorization?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpSmsSendCalendarRemindersPost
     */
    readonly event?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiErpSmsSendCalendarRemindersPost
     */
    readonly optionalNow?: string
}

/**
 * Request parameters for apiSmsCustomerCustomerIdMessagesGet operation in DefaultApi.
 * @export
 * @interface DefaultApiApiSmsCustomerCustomerIdMessagesGetRequest
 */
export interface DefaultApiApiSmsCustomerCustomerIdMessagesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiSmsCustomerCustomerIdMessagesGet
     */
    readonly customerId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiSmsCustomerCustomerIdMessagesGet
     */
    readonly authorization?: string
}

/**
 * Request parameters for apiSmsIdHandledReplyPut operation in DefaultApi.
 * @export
 * @interface DefaultApiApiSmsIdHandledReplyPutRequest
 */
export interface DefaultApiApiSmsIdHandledReplyPutRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiSmsIdHandledReplyPut
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiSmsIdHandledReplyPut
     */
    readonly authorization?: string
}

/**
 * Request parameters for apiSmsRepliesGet operation in DefaultApi.
 * @export
 * @interface DefaultApiApiSmsRepliesGetRequest
 */
export interface DefaultApiApiSmsRepliesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiSmsRepliesGet
     */
    readonly authorization?: string

    /**
     * 
     * @type {GetSMSRepliesCommand}
     * @memberof DefaultApiApiSmsRepliesGet
     */
    readonly query?: GetSMSRepliesCommand
}

/**
 * Request parameters for apiSmsSendPost operation in DefaultApi.
 * @export
 * @interface DefaultApiApiSmsSendPostRequest
 */
export interface DefaultApiApiSmsSendPostRequest {
    /**
     * 
     * @type {SendSMSAPIRequest}
     * @memberof DefaultApiApiSmsSendPost
     */
    readonly sendSMSAPIRequest: SendSMSAPIRequest

    /**
     * 
     * @type {string}
     * @memberof DefaultApiApiSmsSendPost
     */
    readonly authorization?: string
}

/**
 * Request parameters for calendarNotificationChannelDelete operation in DefaultApi.
 * @export
 * @interface DefaultApiCalendarNotificationChannelDeleteRequest
 */
export interface DefaultApiCalendarNotificationChannelDeleteRequest {
    /**
     * 
     * @type {CalendarNotificationChannelPostRequest}
     * @memberof DefaultApiCalendarNotificationChannelDelete
     */
    readonly calendarNotificationChannelPostRequest?: CalendarNotificationChannelPostRequest
}

/**
 * Request parameters for calendarNotificationChannelPost operation in DefaultApi.
 * @export
 * @interface DefaultApiCalendarNotificationChannelPostRequest
 */
export interface DefaultApiCalendarNotificationChannelPostRequest {
    /**
     * 
     * @type {CalendarNotificationChannelPostRequest}
     * @memberof DefaultApiCalendarNotificationChannelPost
     */
    readonly calendarNotificationChannelPostRequest?: CalendarNotificationChannelPostRequest
}

/**
 * Request parameters for calendarNotificationChannelRefreshPost operation in DefaultApi.
 * @export
 * @interface DefaultApiCalendarNotificationChannelRefreshPostRequest
 */
export interface DefaultApiCalendarNotificationChannelRefreshPostRequest {
    /**
     * 
     * @type {CalendarNotificationChannelPostRequest}
     * @memberof DefaultApiCalendarNotificationChannelRefreshPost
     */
    readonly calendarNotificationChannelPostRequest?: CalendarNotificationChannelPostRequest
}

/**
 * Request parameters for calendarNotificationHandleEventNotificationPost operation in DefaultApi.
 * @export
 * @interface DefaultApiCalendarNotificationHandleEventNotificationPostRequest
 */
export interface DefaultApiCalendarNotificationHandleEventNotificationPostRequest {
    /**
     * 
     * @type {CalendarNotificationHandleUpdatedEventPostRequest}
     * @memberof DefaultApiCalendarNotificationHandleEventNotificationPost
     */
    readonly calendarNotificationHandleUpdatedEventPostRequest?: CalendarNotificationHandleUpdatedEventPostRequest
}

/**
 * Request parameters for calendarNotificationHandleUpdatedEventPost operation in DefaultApi.
 * @export
 * @interface DefaultApiCalendarNotificationHandleUpdatedEventPostRequest
 */
export interface DefaultApiCalendarNotificationHandleUpdatedEventPostRequest {
    /**
     * 
     * @type {CalendarNotificationHandleUpdatedEventPostRequest}
     * @memberof DefaultApiCalendarNotificationHandleUpdatedEventPost
     */
    readonly calendarNotificationHandleUpdatedEventPostRequest?: CalendarNotificationHandleUpdatedEventPostRequest
}

/**
 * Request parameters for cpproEnTurTilOrderOrderidGet operation in DefaultApi.
 * @export
 * @interface DefaultApiCpproEnTurTilOrderOrderidGetRequest
 */
export interface DefaultApiCpproEnTurTilOrderOrderidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiCpproEnTurTilOrderOrderidGet
     */
    readonly orderid: string
}

/**
 * Request parameters for cpproGdprOrderOrderidDelete operation in DefaultApi.
 * @export
 * @interface DefaultApiCpproGdprOrderOrderidDeleteRequest
 */
export interface DefaultApiCpproGdprOrderOrderidDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiCpproGdprOrderOrderidDelete
     */
    readonly orderid: string
}

/**
 * Request parameters for cpproGdprOrderOrderidPdfsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiCpproGdprOrderOrderidPdfsGetRequest
 */
export interface DefaultApiCpproGdprOrderOrderidPdfsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiCpproGdprOrderOrderidPdfsGet
     */
    readonly orderid: string
}

/**
 * Request parameters for cpproLostOrderOrderidGet operation in DefaultApi.
 * @export
 * @interface DefaultApiCpproLostOrderOrderidGetRequest
 */
export interface DefaultApiCpproLostOrderOrderidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiCpproLostOrderOrderidGet
     */
    readonly orderid: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiCpproLostOrderOrderidGet
     */
    readonly dryRun?: string
}

/**
 * Request parameters for cpproNotificationOrderOrderidGet operation in DefaultApi.
 * @export
 * @interface DefaultApiCpproNotificationOrderOrderidGetRequest
 */
export interface DefaultApiCpproNotificationOrderOrderidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiCpproNotificationOrderOrderidGet
     */
    readonly orderid: string
}

/**
 * Request parameters for cpproOrderOrderidPricesGet operation in DefaultApi.
 * @export
 * @interface DefaultApiCpproOrderOrderidPricesGetRequest
 */
export interface DefaultApiCpproOrderOrderidPricesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiCpproOrderOrderidPricesGet
     */
    readonly orderid: string
}

/**
 * Request parameters for cpproProsjektGodkjentOrderOrderidGet operation in DefaultApi.
 * @export
 * @interface DefaultApiCpproProsjektGodkjentOrderOrderidGetRequest
 */
export interface DefaultApiCpproProsjektGodkjentOrderOrderidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiCpproProsjektGodkjentOrderOrderidGet
     */
    readonly orderid: string
}

/**
 * Request parameters for cpproUpdateBefaringEventOrderidGet operation in DefaultApi.
 * @export
 * @interface DefaultApiCpproUpdateBefaringEventOrderidGetRequest
 */
export interface DefaultApiCpproUpdateBefaringEventOrderidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiCpproUpdateBefaringEventOrderidGet
     */
    readonly orderid: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiCpproUpdateBefaringEventOrderidGet
     */
    readonly pdfType?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiCpproUpdateBefaringEventOrderidGet
     */
    readonly dryRun?: string
}

/**
 * Request parameters for cpproUpdateSaleCalendarsCalendarIdPost operation in DefaultApi.
 * @export
 * @interface DefaultApiCpproUpdateSaleCalendarsCalendarIdPostRequest
 */
export interface DefaultApiCpproUpdateSaleCalendarsCalendarIdPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiCpproUpdateSaleCalendarsCalendarIdPost
     */
    readonly calendarId: string
}

/**
 * Request parameters for createorderOrderOrderidGet operation in DefaultApi.
 * @export
 * @interface DefaultApiCreateorderOrderOrderidGetRequest
 */
export interface DefaultApiCreateorderOrderOrderidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiCreateorderOrderOrderidGet
     */
    readonly orderid: string
}

/**
 * Request parameters for emailSendMailIdPost operation in DefaultApi.
 * @export
 * @interface DefaultApiEmailSendMailIdPostRequest
 */
export interface DefaultApiEmailSendMailIdPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiEmailSendMailIdPost
     */
    readonly mailId: string
}

/**
 * Request parameters for erp24soAcErpIdCreateCPProOrderPost operation in DefaultApi.
 * @export
 * @interface DefaultApiErp24soAcErpIdCreateCPProOrderPostRequest
 */
export interface DefaultApiErp24soAcErpIdCreateCPProOrderPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErp24soAcErpIdCreateCPProOrderPost
     */
    readonly acErpId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErp24soAcErpIdCreateCPProOrderPost
     */
    readonly client?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErp24soAcErpIdCreateCPProOrderPost
     */
    readonly demo?: string
}

/**
 * Request parameters for erp24soFindFJOrderAfterMRPInvoicedCpproIdPost operation in DefaultApi.
 * @export
 * @interface DefaultApiErp24soFindFJOrderAfterMRPInvoicedCpproIdPostRequest
 */
export interface DefaultApiErp24soFindFJOrderAfterMRPInvoicedCpproIdPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErp24soFindFJOrderAfterMRPInvoicedCpproIdPost
     */
    readonly cpproId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErp24soFindFJOrderAfterMRPInvoicedCpproIdPost
     */
    readonly dryRun?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErp24soFindFJOrderAfterMRPInvoicedCpproIdPost
     */
    readonly manual?: string
}

/**
 * Request parameters for erp24soForhandlerSearchGet operation in DefaultApi.
 * @export
 * @interface DefaultApiErp24soForhandlerSearchGetRequest
 */
export interface DefaultApiErp24soForhandlerSearchGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErp24soForhandlerSearchGet
     */
    readonly externalId?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErp24soForhandlerSearchGet
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErp24soForhandlerSearchGet
     */
    readonly isACT?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErp24soForhandlerSearchGet
     */
    readonly test?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErp24soForhandlerSearchGet
     */
    readonly search24SOAC?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErp24soForhandlerSearchGet
     */
    readonly returnProps?: string
}

/**
 * Request parameters for erpAcCalendarDeliveryDateChangePost operation in DefaultApi.
 * @export
 * @interface DefaultApiErpAcCalendarDeliveryDateChangePostRequest
 */
export interface DefaultApiErpAcCalendarDeliveryDateChangePostRequest {
    /**
     * 
     * @type {ApiErpAcCalendarDeliveryDateChangePostRequest}
     * @memberof DefaultApiErpAcCalendarDeliveryDateChangePost
     */
    readonly apiErpAcCalendarDeliveryDateChangePostRequest?: ApiErpAcCalendarDeliveryDateChangePostRequest
}

/**
 * Request parameters for erpAcCalendarOrderCpproIdEventDeliveryDateChangeEventEventIdPost operation in DefaultApi.
 * @export
 * @interface DefaultApiErpAcCalendarOrderCpproIdEventDeliveryDateChangeEventEventIdPostRequest
 */
export interface DefaultApiErpAcCalendarOrderCpproIdEventDeliveryDateChangeEventEventIdPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpAcCalendarOrderCpproIdEventDeliveryDateChangeEventEventIdPost
     */
    readonly cpproId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpAcCalendarOrderCpproIdEventDeliveryDateChangeEventEventIdPost
     */
    readonly eventId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpAcCalendarOrderCpproIdEventDeliveryDateChangeEventEventIdPost
     */
    readonly skipDeliveryDateUpdate?: string
}

/**
 * Request parameters for erpAcChecklistInstalledOrderCpproIdGet operation in DefaultApi.
 * @export
 * @interface DefaultApiErpAcChecklistInstalledOrderCpproIdGetRequest
 */
export interface DefaultApiErpAcChecklistInstalledOrderCpproIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpAcChecklistInstalledOrderCpproIdGet
     */
    readonly cpproId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpAcChecklistInstalledOrderCpproIdGet
     */
    readonly disableDelete?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpAcChecklistInstalledOrderCpproIdGet
     */
    readonly dryRun?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpAcChecklistInstalledOrderCpproIdGet
     */
    readonly manual?: string
}

/**
 * Request parameters for erpAcCreateOrderOrderIdPost operation in DefaultApi.
 * @export
 * @interface DefaultApiErpAcCreateOrderOrderIdPostRequest
 */
export interface DefaultApiErpAcCreateOrderOrderIdPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpAcCreateOrderOrderIdPost
     */
    readonly orderId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpAcCreateOrderOrderIdPost
     */
    readonly deliveryDate?: string
}

/**
 * Request parameters for erpAcOrderInactivatePost operation in DefaultApi.
 * @export
 * @interface DefaultApiErpAcOrderInactivatePostRequest
 */
export interface DefaultApiErpAcOrderInactivatePostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpAcOrderInactivatePost
     */
    readonly changedAfter?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpAcOrderInactivatePost
     */
    readonly test?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpAcOrderInactivatePost
     */
    readonly dryRun?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpAcOrderInactivatePost
     */
    readonly includeAlreadyInactivated?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpAcOrderInactivatePost
     */
    readonly shouldDeleteTasks?: string
}

/**
 * Request parameters for erpAcRecreateOrderCpproIdPost operation in DefaultApi.
 * @export
 * @interface DefaultApiErpAcRecreateOrderCpproIdPostRequest
 */
export interface DefaultApiErpAcRecreateOrderCpproIdPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpAcRecreateOrderCpproIdPost
     */
    readonly cpproId: string
}

/**
 * Request parameters for erpCreditSaleInvoiceACOrderCpproIdGet operation in DefaultApi.
 * @export
 * @interface DefaultApiErpCreditSaleInvoiceACOrderCpproIdGetRequest
 */
export interface DefaultApiErpCreditSaleInvoiceACOrderCpproIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpCreditSaleInvoiceACOrderCpproIdGet
     */
    readonly cpproId: string
}

/**
 * Request parameters for erpCustomerGet operation in DefaultApi.
 * @export
 * @interface DefaultApiErpCustomerGetRequest
 */
export interface DefaultApiErpCustomerGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpCustomerGet
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpCustomerGet
     */
    readonly email?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpCustomerGet
     */
    readonly phone?: string
}

/**
 * Request parameters for erpCustomerIdGet operation in DefaultApi.
 * @export
 * @interface DefaultApiErpCustomerIdGetRequest
 */
export interface DefaultApiErpCustomerIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpCustomerIdGet
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpCustomerIdGet
     */
    readonly external?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpCustomerIdGet
     */
    readonly identity?: string
}

/**
 * Request parameters for erpCustomerIdPatch operation in DefaultApi.
 * @export
 * @interface DefaultApiErpCustomerIdPatchRequest
 */
export interface DefaultApiErpCustomerIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpCustomerIdPatch
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpCustomerIdPatch
     */
    readonly external?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpCustomerIdPatch
     */
    readonly identity?: string
}

/**
 * Request parameters for erpCustomerPatch operation in DefaultApi.
 * @export
 * @interface DefaultApiErpCustomerPatchRequest
 */
export interface DefaultApiErpCustomerPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpCustomerPatch
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpCustomerPatch
     */
    readonly email?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpCustomerPatch
     */
    readonly phone?: string
}

/**
 * Request parameters for erpFjCreateOrderFromACOrderIdPost operation in DefaultApi.
 * @export
 * @interface DefaultApiErpFjCreateOrderFromACOrderIdPostRequest
 */
export interface DefaultApiErpFjCreateOrderFromACOrderIdPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpFjCreateOrderFromACOrderIdPost
     */
    readonly orderId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpFjCreateOrderFromACOrderIdPost
     */
    readonly deliveryDate?: string
}

/**
 * Request parameters for erpFjpartnerCreateOrderCpproIdPost operation in DefaultApi.
 * @export
 * @interface DefaultApiErpFjpartnerCreateOrderCpproIdPostRequest
 */
export interface DefaultApiErpFjpartnerCreateOrderCpproIdPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpFjpartnerCreateOrderCpproIdPost
     */
    readonly cpproId: string
}

/**
 * Request parameters for erpFjpartnerMigrateDemoOrderToLiveCpproIdPost operation in DefaultApi.
 * @export
 * @interface DefaultApiErpFjpartnerMigrateDemoOrderToLiveCpproIdPostRequest
 */
export interface DefaultApiErpFjpartnerMigrateDemoOrderToLiveCpproIdPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpFjpartnerMigrateDemoOrderToLiveCpproIdPost
     */
    readonly cpproId: string
}

/**
 * Request parameters for erpFjpartnerOrderCpproIdCreateExtraACOrderPost operation in DefaultApi.
 * @export
 * @interface DefaultApiErpFjpartnerOrderCpproIdCreateExtraACOrderPostRequest
 */
export interface DefaultApiErpFjpartnerOrderCpproIdCreateExtraACOrderPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpFjpartnerOrderCpproIdCreateExtraACOrderPost
     */
    readonly cpproId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpFjpartnerOrderCpproIdCreateExtraACOrderPost
     */
    readonly test?: string
}

/**
 * Request parameters for erpFjpartnerOrderCpproIdInvoiceExtraACOrderPost operation in DefaultApi.
 * @export
 * @interface DefaultApiErpFjpartnerOrderCpproIdInvoiceExtraACOrderPostRequest
 */
export interface DefaultApiErpFjpartnerOrderCpproIdInvoiceExtraACOrderPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpFjpartnerOrderCpproIdInvoiceExtraACOrderPost
     */
    readonly cpproId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpFjpartnerOrderCpproIdInvoiceExtraACOrderPost
     */
    readonly test?: string
}

/**
 * Request parameters for erpFjpartnerOrderCpproPartnerProgIdCreateCPProSalesOrderPost operation in DefaultApi.
 * @export
 * @interface DefaultApiErpFjpartnerOrderCpproPartnerProgIdCreateCPProSalesOrderPostRequest
 */
export interface DefaultApiErpFjpartnerOrderCpproPartnerProgIdCreateCPProSalesOrderPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpFjpartnerOrderCpproPartnerProgIdCreateCPProSalesOrderPost
     */
    readonly cpproPartnerProgId: string
}

/**
 * Request parameters for erpFjpartnerOrderCpproPartnerProgIdFindMRPOrderPost operation in DefaultApi.
 * @export
 * @interface DefaultApiErpFjpartnerOrderCpproPartnerProgIdFindMRPOrderPostRequest
 */
export interface DefaultApiErpFjpartnerOrderCpproPartnerProgIdFindMRPOrderPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpFjpartnerOrderCpproPartnerProgIdFindMRPOrderPost
     */
    readonly cpproPartnerProgId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpFjpartnerOrderCpproPartnerProgIdFindMRPOrderPost
     */
    readonly acCPProId?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpFjpartnerOrderCpproPartnerProgIdFindMRPOrderPost
     */
    readonly dryRun?: string
}

/**
 * Request parameters for erpFjpartnerOrderCpproPartnerProgIdInstallCompleteWhenInstallerIsACPost operation in DefaultApi.
 * @export
 * @interface DefaultApiErpFjpartnerOrderCpproPartnerProgIdInstallCompleteWhenInstallerIsACPostRequest
 */
export interface DefaultApiErpFjpartnerOrderCpproPartnerProgIdInstallCompleteWhenInstallerIsACPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpFjpartnerOrderCpproPartnerProgIdInstallCompleteWhenInstallerIsACPost
     */
    readonly cpproPartnerProgId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpFjpartnerOrderCpproPartnerProgIdInstallCompleteWhenInstallerIsACPost
     */
    readonly acCPProId?: string
}

/**
 * Request parameters for erpFjpartnerSyncPost operation in DefaultApi.
 * @export
 * @interface DefaultApiErpFjpartnerSyncPostRequest
 */
export interface DefaultApiErpFjpartnerSyncPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpFjpartnerSyncPost
     */
    readonly orderId?: string
}

/**
 * Request parameters for erpManualOrderOrderIdAutomateOrderPost operation in DefaultApi.
 * @export
 * @interface DefaultApiErpManualOrderOrderIdAutomateOrderPostRequest
 */
export interface DefaultApiErpManualOrderOrderIdAutomateOrderPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpManualOrderOrderIdAutomateOrderPost
     */
    readonly orderId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpManualOrderOrderIdAutomateOrderPost
     */
    readonly test?: string
}

/**
 * Request parameters for erpManualOrderOrderIdInvoice24SOPost operation in DefaultApi.
 * @export
 * @interface DefaultApiErpManualOrderOrderIdInvoice24SOPostRequest
 */
export interface DefaultApiErpManualOrderOrderIdInvoice24SOPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpManualOrderOrderIdInvoice24SOPost
     */
    readonly orderId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpManualOrderOrderIdInvoice24SOPost
     */
    readonly test?: string
}

/**
 * Request parameters for erpMrpFindMRPOrderCpproIdGet operation in DefaultApi.
 * @export
 * @interface DefaultApiErpMrpFindMRPOrderCpproIdGetRequest
 */
export interface DefaultApiErpMrpFindMRPOrderCpproIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpMrpFindMRPOrderCpproIdGet
     */
    readonly cpproId: string
}

/**
 * Request parameters for erpMrpInvoiceOrderCpproIdPost operation in DefaultApi.
 * @export
 * @interface DefaultApiErpMrpInvoiceOrderCpproIdPostRequest
 */
export interface DefaultApiErpMrpInvoiceOrderCpproIdPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpMrpInvoiceOrderCpproIdPost
     */
    readonly cpproId: string
}

/**
 * Request parameters for erpMrpPickOrderCpproIdPost operation in DefaultApi.
 * @export
 * @interface DefaultApiErpMrpPickOrderCpproIdPostRequest
 */
export interface DefaultApiErpMrpPickOrderCpproIdPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpMrpPickOrderCpproIdPost
     */
    readonly cpproId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpMrpPickOrderCpproIdPost
     */
    readonly dryRun?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpMrpPickOrderCpproIdPost
     */
    readonly manual?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpMrpPickOrderCpproIdPost
     */
    readonly xCloudtasksTaskretrycount?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpMrpPickOrderCpproIdPost
     */
    readonly xCloudtasksTaskexecutioncount?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpMrpPickOrderCpproIdPost
     */
    readonly xCloudtasksTasketa?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpMrpPickOrderCpproIdPost
     */
    readonly xCloudtasksQueuename?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpMrpPickOrderCpproIdPost
     */
    readonly xCloudtasksTaskname?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpMrpPickOrderCpproIdPost
     */
    readonly xCloudtasksTaskpreviousresponse?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpMrpPickOrderCpproIdPost
     */
    readonly xCloudtasksTaskretryreason?: string
}

/**
 * Request parameters for erpMrpShipOrderCpproIdPost operation in DefaultApi.
 * @export
 * @interface DefaultApiErpMrpShipOrderCpproIdPostRequest
 */
export interface DefaultApiErpMrpShipOrderCpproIdPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpMrpShipOrderCpproIdPost
     */
    readonly cpproId: string
}

/**
 * Request parameters for erpOrderCpproIdActivityhelperlocalInsertCustomerAndOrderPost operation in DefaultApi.
 * @export
 * @interface DefaultApiErpOrderCpproIdActivityhelperlocalInsertCustomerAndOrderPostRequest
 */
export interface DefaultApiErpOrderCpproIdActivityhelperlocalInsertCustomerAndOrderPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpOrderCpproIdActivityhelperlocalInsertCustomerAndOrderPost
     */
    readonly cpproId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpOrderCpproIdActivityhelperlocalInsertCustomerAndOrderPost
     */
    readonly type?: string
}

/**
 * Request parameters for erpOrderCpproIdCRMAddCustomerAndOrderPost operation in DefaultApi.
 * @export
 * @interface DefaultApiErpOrderCpproIdCRMAddCustomerAndOrderPostRequest
 */
export interface DefaultApiErpOrderCpproIdCRMAddCustomerAndOrderPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpOrderCpproIdCRMAddCustomerAndOrderPost
     */
    readonly cpproId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpOrderCpproIdCRMAddCustomerAndOrderPost
     */
    readonly type?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpOrderCpproIdCRMAddCustomerAndOrderPost
     */
    readonly dryRun?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpOrderCpproIdCRMAddCustomerAndOrderPost
     */
    readonly fjOrder?: string
}

/**
 * Request parameters for erpOrderCpproIdCalendarCreateServiceEventPost operation in DefaultApi.
 * @export
 * @interface DefaultApiErpOrderCpproIdCalendarCreateServiceEventPostRequest
 */
export interface DefaultApiErpOrderCpproIdCalendarCreateServiceEventPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpOrderCpproIdCalendarCreateServiceEventPost
     */
    readonly cpproId: string
}

/**
 * Request parameters for erpOrderCpproIdCreditSaleMrpInvoicedPost operation in DefaultApi.
 * @export
 * @interface DefaultApiErpOrderCpproIdCreditSaleMrpInvoicedPostRequest
 */
export interface DefaultApiErpOrderCpproIdCreditSaleMrpInvoicedPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpOrderCpproIdCreditSaleMrpInvoicedPost
     */
    readonly cpproId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpOrderCpproIdCreditSaleMrpInvoicedPost
     */
    readonly dryRun?: string
}

/**
 * Request parameters for erpOrderCpproIdInvoiceFJFinalOrderPost operation in DefaultApi.
 * @export
 * @interface DefaultApiErpOrderCpproIdInvoiceFJFinalOrderPostRequest
 */
export interface DefaultApiErpOrderCpproIdInvoiceFJFinalOrderPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpOrderCpproIdInvoiceFJFinalOrderPost
     */
    readonly cpproId: string
}

/**
 * Request parameters for erpOrderCpproIdLegacyServerAddOrderPost operation in DefaultApi.
 * @export
 * @interface DefaultApiErpOrderCpproIdLegacyServerAddOrderPostRequest
 */
export interface DefaultApiErpOrderCpproIdLegacyServerAddOrderPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpOrderCpproIdLegacyServerAddOrderPost
     */
    readonly cpproId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpOrderCpproIdLegacyServerAddOrderPost
     */
    readonly type?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpOrderCpproIdLegacyServerAddOrderPost
     */
    readonly dryRun?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpOrderCpproIdLegacyServerAddOrderPost
     */
    readonly fjOrder?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpOrderCpproIdLegacyServerAddOrderPost
     */
    readonly includeVAT?: string
}

/**
 * Request parameters for erpOrderCpproIdRepairMrpInvoicedPost operation in DefaultApi.
 * @export
 * @interface DefaultApiErpOrderCpproIdRepairMrpInvoicedPostRequest
 */
export interface DefaultApiErpOrderCpproIdRepairMrpInvoicedPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpOrderCpproIdRepairMrpInvoicedPost
     */
    readonly cpproId: string
}

/**
 * Request parameters for erpOrderCpproIdSpreadsheetUpdate20502038Post operation in DefaultApi.
 * @export
 * @interface DefaultApiErpOrderCpproIdSpreadsheetUpdate20502038PostRequest
 */
export interface DefaultApiErpOrderCpproIdSpreadsheetUpdate20502038PostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpOrderCpproIdSpreadsheetUpdate20502038Post
     */
    readonly cpproId: string
}

/**
 * Request parameters for erpOrderIdOrderlinesPost operation in DefaultApi.
 * @export
 * @interface DefaultApiErpOrderIdOrderlinesPostRequest
 */
export interface DefaultApiErpOrderIdOrderlinesPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpOrderIdOrderlinesPost
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpOrderIdOrderlinesPost
     */
    readonly external?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpOrderIdOrderlinesPost
     */
    readonly identity?: string
}

/**
 * Request parameters for erpOrderOrderIdSmsSendInvoiceLinkPost operation in DefaultApi.
 * @export
 * @interface DefaultApiErpOrderOrderIdSmsSendInvoiceLinkPostRequest
 */
export interface DefaultApiErpOrderOrderIdSmsSendInvoiceLinkPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpOrderOrderIdSmsSendInvoiceLinkPost
     */
    readonly orderId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpOrderOrderIdSmsSendInvoiceLinkPost
     */
    readonly isACErpId?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpOrderOrderIdSmsSendInvoiceLinkPost
     */
    readonly ignoreSMS?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpOrderOrderIdSmsSendInvoiceLinkPost
     */
    readonly isACT?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpOrderOrderIdSmsSendInvoiceLinkPost
     */
    readonly test?: string
}

/**
 * Request parameters for erpRepairInvoiceACOrderCpproIdGet operation in DefaultApi.
 * @export
 * @interface DefaultApiErpRepairInvoiceACOrderCpproIdGetRequest
 */
export interface DefaultApiErpRepairInvoiceACOrderCpproIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpRepairInvoiceACOrderCpproIdGet
     */
    readonly cpproId: string
}

/**
 * Request parameters for erpScheduleFjFindNewOrdersPost operation in DefaultApi.
 * @export
 * @interface DefaultApiErpScheduleFjFindNewOrdersPostRequest
 */
export interface DefaultApiErpScheduleFjFindNewOrdersPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpScheduleFjFindNewOrdersPost
     */
    readonly changedAfter?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpScheduleFjFindNewOrdersPost
     */
    readonly fjOrderId?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpScheduleFjFindNewOrdersPost
     */
    readonly test?: string
}

/**
 * Request parameters for erpScheduleManualOrderFindManualOrdersPost operation in DefaultApi.
 * @export
 * @interface DefaultApiErpScheduleManualOrderFindManualOrdersPostRequest
 */
export interface DefaultApiErpScheduleManualOrderFindManualOrdersPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpScheduleManualOrderFindManualOrdersPost
     */
    readonly test?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpScheduleManualOrderFindManualOrdersPost
     */
    readonly orderId?: string
}

/**
 * Request parameters for erpScheduleMrpSyncProductsToCPProPost operation in DefaultApi.
 * @export
 * @interface DefaultApiErpScheduleMrpSyncProductsToCPProPostRequest
 */
export interface DefaultApiErpScheduleMrpSyncProductsToCPProPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpScheduleMrpSyncProductsToCPProPost
     */
    readonly disableDelete?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpScheduleMrpSyncProductsToCPProPost
     */
    readonly dryRun?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpScheduleMrpSyncProductsToCPProPost
     */
    readonly itemNumber?: string
}

/**
 * Request parameters for erpScheduleOrderFindInvoicedOrdersPost operation in DefaultApi.
 * @export
 * @interface DefaultApiErpScheduleOrderFindInvoicedOrdersPostRequest
 */
export interface DefaultApiErpScheduleOrderFindInvoicedOrdersPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpScheduleOrderFindInvoicedOrdersPost
     */
    readonly demo?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpScheduleOrderFindInvoicedOrdersPost
     */
    readonly act?: string
}

/**
 * Request parameters for erpScheduleOrderWaitingForChecklistPost operation in DefaultApi.
 * @export
 * @interface DefaultApiErpScheduleOrderWaitingForChecklistPostRequest
 */
export interface DefaultApiErpScheduleOrderWaitingForChecklistPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpScheduleOrderWaitingForChecklistPost
     */
    readonly cpproId?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpScheduleOrderWaitingForChecklistPost
     */
    readonly dryRun?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpScheduleOrderWaitingForChecklistPost
     */
    readonly manual?: string
}

/**
 * Request parameters for erpScheduleSmsSendCalendarSMSRemindersPost operation in DefaultApi.
 * @export
 * @interface DefaultApiErpScheduleSmsSendCalendarSMSRemindersPostRequest
 */
export interface DefaultApiErpScheduleSmsSendCalendarSMSRemindersPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpScheduleSmsSendCalendarSMSRemindersPost
     */
    readonly event?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpScheduleSmsSendCalendarSMSRemindersPost
     */
    readonly optionalNow?: string
}

/**
 * Request parameters for erpScheduleUsersSyncEmployeeInfoToCPProPost operation in DefaultApi.
 * @export
 * @interface DefaultApiErpScheduleUsersSyncEmployeeInfoToCPProPostRequest
 */
export interface DefaultApiErpScheduleUsersSyncEmployeeInfoToCPProPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpScheduleUsersSyncEmployeeInfoToCPProPost
     */
    readonly dryRun?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpScheduleUsersSyncEmployeeInfoToCPProPost
     */
    readonly userEmail?: string
}

/**
 * Request parameters for erpSync24SOToMRPGet operation in DefaultApi.
 * @export
 * @interface DefaultApiErpSync24SOToMRPGetRequest
 */
export interface DefaultApiErpSync24SOToMRPGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiErpSync24SOToMRPGet
     */
    readonly env?: string
}

/**
 * Request parameters for fjordersErpAgreedInstallationDateOrderOrderidGet operation in DefaultApi.
 * @export
 * @interface DefaultApiFjordersErpAgreedInstallationDateOrderOrderidGetRequest
 */
export interface DefaultApiFjordersErpAgreedInstallationDateOrderOrderidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiFjordersErpAgreedInstallationDateOrderOrderidGet
     */
    readonly orderid: string
}

/**
 * Request parameters for fjordersErpDeliveryaddressOrderOrderidGet operation in DefaultApi.
 * @export
 * @interface DefaultApiFjordersErpDeliveryaddressOrderOrderidGetRequest
 */
export interface DefaultApiFjordersErpDeliveryaddressOrderOrderidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiFjordersErpDeliveryaddressOrderOrderidGet
     */
    readonly orderid: string
}

/**
 * Request parameters for fjordersErpInstallerJobAcceptedOrderOrderidGet operation in DefaultApi.
 * @export
 * @interface DefaultApiFjordersErpInstallerJobAcceptedOrderOrderidGetRequest
 */
export interface DefaultApiFjordersErpInstallerJobAcceptedOrderOrderidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiFjordersErpInstallerJobAcceptedOrderOrderidGet
     */
    readonly orderid: string
}

/**
 * Request parameters for fjordersErpInstallerOrderOrderidGet operation in DefaultApi.
 * @export
 * @interface DefaultApiFjordersErpInstallerOrderOrderidGetRequest
 */
export interface DefaultApiFjordersErpInstallerOrderOrderidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiFjordersErpInstallerOrderOrderidGet
     */
    readonly orderid: string
}

/**
 * Request parameters for fjordersErpUpdateDeliveryDateOrderOrderidGet operation in DefaultApi.
 * @export
 * @interface DefaultApiFjordersErpUpdateDeliveryDateOrderOrderidGetRequest
 */
export interface DefaultApiFjordersErpUpdateDeliveryDateOrderOrderidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiFjordersErpUpdateDeliveryDateOrderOrderidGet
     */
    readonly orderid: string
}

/**
 * Request parameters for fjordersGmailMessageMessageidGet operation in DefaultApi.
 * @export
 * @interface DefaultApiFjordersGmailMessageMessageidGetRequest
 */
export interface DefaultApiFjordersGmailMessageMessageidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiFjordersGmailMessageMessageidGet
     */
    readonly messageid: string
}

/**
 * Request parameters for fjordersInstallationCompleteConfirmationOrderOrderidGet operation in DefaultApi.
 * @export
 * @interface DefaultApiFjordersInstallationCompleteConfirmationOrderOrderidGetRequest
 */
export interface DefaultApiFjordersInstallationCompleteConfirmationOrderOrderidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiFjordersInstallationCompleteConfirmationOrderOrderidGet
     */
    readonly orderid: string
}

/**
 * Request parameters for fjordersOrderOrderidFixEmailMessageIdGet operation in DefaultApi.
 * @export
 * @interface DefaultApiFjordersOrderOrderidFixEmailMessageIdGetRequest
 */
export interface DefaultApiFjordersOrderOrderidFixEmailMessageIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiFjordersOrderOrderidFixEmailMessageIdGet
     */
    readonly orderid: string
}

/**
 * Request parameters for fjordersOrderOrderidGet operation in DefaultApi.
 * @export
 * @interface DefaultApiFjordersOrderOrderidGetRequest
 */
export interface DefaultApiFjordersOrderOrderidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiFjordersOrderOrderidGet
     */
    readonly orderid: string
}

/**
 * Request parameters for fjordersOrderReferenceReferenceIdGet operation in DefaultApi.
 * @export
 * @interface DefaultApiFjordersOrderReferenceReferenceIdGetRequest
 */
export interface DefaultApiFjordersOrderReferenceReferenceIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiFjordersOrderReferenceReferenceIdGet
     */
    readonly referenceId: string
}

/**
 * Request parameters for fjordersOrdersCpproSyncGet operation in DefaultApi.
 * @export
 * @interface DefaultApiFjordersOrdersCpproSyncGetRequest
 */
export interface DefaultApiFjordersOrdersCpproSyncGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiFjordersOrdersCpproSyncGet
     */
    readonly includeFinished?: string
}

/**
 * Request parameters for fjordersReferenceReferenceFiksMonteringUtfortPost operation in DefaultApi.
 * @export
 * @interface DefaultApiFjordersReferenceReferenceFiksMonteringUtfortPostRequest
 */
export interface DefaultApiFjordersReferenceReferenceFiksMonteringUtfortPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiFjordersReferenceReferenceFiksMonteringUtfortPost
     */
    readonly reference: string
}

/**
 * Request parameters for fjordersUpdateProductOrderOrderidGet operation in DefaultApi.
 * @export
 * @interface DefaultApiFjordersUpdateProductOrderOrderidGetRequest
 */
export interface DefaultApiFjordersUpdateProductOrderOrderidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiFjordersUpdateProductOrderOrderidGet
     */
    readonly orderid: string
}

/**
 * Request parameters for leadManagementScheduleMoveLeadsPost operation in DefaultApi.
 * @export
 * @interface DefaultApiLeadManagementScheduleMoveLeadsPostRequest
 */
export interface DefaultApiLeadManagementScheduleMoveLeadsPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiLeadManagementScheduleMoveLeadsPost
     */
    readonly test?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiLeadManagementScheduleMoveLeadsPost
     */
    readonly dryRun?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiLeadManagementScheduleMoveLeadsPost
     */
    readonly dryrun?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiLeadManagementScheduleMoveLeadsPost
     */
    readonly limit?: string
}

/**
 * Request parameters for leadManagementVendorUserIdDelete operation in DefaultApi.
 * @export
 * @interface DefaultApiLeadManagementVendorUserIdDeleteRequest
 */
export interface DefaultApiLeadManagementVendorUserIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiLeadManagementVendorUserIdDelete
     */
    readonly userId: string
}

/**
 * Request parameters for leadManagementVendorUserIdPatch operation in DefaultApi.
 * @export
 * @interface DefaultApiLeadManagementVendorUserIdPatchRequest
 */
export interface DefaultApiLeadManagementVendorUserIdPatchRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiLeadManagementVendorUserIdPatch
     */
    readonly userId: string
}

/**
 * Request parameters for monitorAlertIncidentGet operation in DefaultApi.
 * @export
 * @interface DefaultApiMonitorAlertIncidentGetRequest
 */
export interface DefaultApiMonitorAlertIncidentGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorAlertIncidentGet
     */
    readonly authorization?: string

    /**
     * 
     * @type {GetIncidentsCommand}
     * @memberof DefaultApiMonitorAlertIncidentGet
     */
    readonly query?: GetIncidentsCommand
}

/**
 * Request parameters for monitorAlertIncidentIdDelete operation in DefaultApi.
 * @export
 * @interface DefaultApiMonitorAlertIncidentIdDeleteRequest
 */
export interface DefaultApiMonitorAlertIncidentIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorAlertIncidentIdDelete
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorAlertIncidentIdDelete
     */
    readonly authorization?: string
}

/**
 * Request parameters for monitorAlertIncidentIdPut operation in DefaultApi.
 * @export
 * @interface DefaultApiMonitorAlertIncidentIdPutRequest
 */
export interface DefaultApiMonitorAlertIncidentIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorAlertIncidentIdPut
     */
    readonly id: string

    /**
     * 
     * @type {UpdateAlertPolicyIncidentCommand}
     * @memberof DefaultApiMonitorAlertIncidentIdPut
     */
    readonly updateAlertPolicyIncidentCommand: UpdateAlertPolicyIncidentCommand

    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorAlertIncidentIdPut
     */
    readonly authorization?: string
}

/**
 * Request parameters for monitorAlertIncidentPubsubPost operation in DefaultApi.
 * @export
 * @interface DefaultApiMonitorAlertIncidentPubsubPostRequest
 */
export interface DefaultApiMonitorAlertIncidentPubsubPostRequest {
    /**
     * 
     * @type {PubSubPushRequest}
     * @memberof DefaultApiMonitorAlertIncidentPubsubPost
     */
    readonly pubSubPushRequest: PubSubPushRequest
}

/**
 * Request parameters for monitorAlertNotificationChannelGet operation in DefaultApi.
 * @export
 * @interface DefaultApiMonitorAlertNotificationChannelGetRequest
 */
export interface DefaultApiMonitorAlertNotificationChannelGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorAlertNotificationChannelGet
     */
    readonly authorization?: string
}

/**
 * Request parameters for monitorAlertNotificationChannelIdDelete operation in DefaultApi.
 * @export
 * @interface DefaultApiMonitorAlertNotificationChannelIdDeleteRequest
 */
export interface DefaultApiMonitorAlertNotificationChannelIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorAlertNotificationChannelIdDelete
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorAlertNotificationChannelIdDelete
     */
    readonly authorization?: string
}

/**
 * Request parameters for monitorAlertNotificationChannelIdPut operation in DefaultApi.
 * @export
 * @interface DefaultApiMonitorAlertNotificationChannelIdPutRequest
 */
export interface DefaultApiMonitorAlertNotificationChannelIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorAlertNotificationChannelIdPut
     */
    readonly id: string

    /**
     * 
     * @type {UpdateNotificationChannelCommand}
     * @memberof DefaultApiMonitorAlertNotificationChannelIdPut
     */
    readonly updateNotificationChannelCommand: UpdateNotificationChannelCommand

    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorAlertNotificationChannelIdPut
     */
    readonly authorization?: string
}

/**
 * Request parameters for monitorAlertNotificationChannelPost operation in DefaultApi.
 * @export
 * @interface DefaultApiMonitorAlertNotificationChannelPostRequest
 */
export interface DefaultApiMonitorAlertNotificationChannelPostRequest {
    /**
     * 
     * @type {CreateNotificationChannelCommand}
     * @memberof DefaultApiMonitorAlertNotificationChannelPost
     */
    readonly createNotificationChannelCommand: CreateNotificationChannelCommand

    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorAlertNotificationChannelPost
     */
    readonly authorization?: string
}

/**
 * Request parameters for monitorAlertPolicyGet operation in DefaultApi.
 * @export
 * @interface DefaultApiMonitorAlertPolicyGetRequest
 */
export interface DefaultApiMonitorAlertPolicyGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorAlertPolicyGet
     */
    readonly authorization?: string
}

/**
 * Request parameters for monitorAlertPolicyIdDelete operation in DefaultApi.
 * @export
 * @interface DefaultApiMonitorAlertPolicyIdDeleteRequest
 */
export interface DefaultApiMonitorAlertPolicyIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorAlertPolicyIdDelete
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorAlertPolicyIdDelete
     */
    readonly authorization?: string
}

/**
 * Request parameters for monitorAlertPolicyIdPut operation in DefaultApi.
 * @export
 * @interface DefaultApiMonitorAlertPolicyIdPutRequest
 */
export interface DefaultApiMonitorAlertPolicyIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorAlertPolicyIdPut
     */
    readonly id: string

    /**
     * 
     * @type {UpdateAlertPolicyCommand}
     * @memberof DefaultApiMonitorAlertPolicyIdPut
     */
    readonly updateAlertPolicyCommand: UpdateAlertPolicyCommand

    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorAlertPolicyIdPut
     */
    readonly authorization?: string
}

/**
 * Request parameters for monitorAlertPolicyPolicyIdChannelChannelIdDelete operation in DefaultApi.
 * @export
 * @interface DefaultApiMonitorAlertPolicyPolicyIdChannelChannelIdDeleteRequest
 */
export interface DefaultApiMonitorAlertPolicyPolicyIdChannelChannelIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorAlertPolicyPolicyIdChannelChannelIdDelete
     */
    readonly policyId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorAlertPolicyPolicyIdChannelChannelIdDelete
     */
    readonly channelId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorAlertPolicyPolicyIdChannelChannelIdDelete
     */
    readonly authorization?: string
}

/**
 * Request parameters for monitorAlertPolicyPolicyIdChannelChannelIdPost operation in DefaultApi.
 * @export
 * @interface DefaultApiMonitorAlertPolicyPolicyIdChannelChannelIdPostRequest
 */
export interface DefaultApiMonitorAlertPolicyPolicyIdChannelChannelIdPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorAlertPolicyPolicyIdChannelChannelIdPost
     */
    readonly policyId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorAlertPolicyPolicyIdChannelChannelIdPost
     */
    readonly channelId: string

    /**
     * 
     * @type {CreatePolicyNotificationCommand}
     * @memberof DefaultApiMonitorAlertPolicyPolicyIdChannelChannelIdPost
     */
    readonly createPolicyNotificationCommand: CreatePolicyNotificationCommand

    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorAlertPolicyPolicyIdChannelChannelIdPost
     */
    readonly authorization?: string
}

/**
 * Request parameters for monitorAlertPolicyPolicyIdChannelChannelIdPut operation in DefaultApi.
 * @export
 * @interface DefaultApiMonitorAlertPolicyPolicyIdChannelChannelIdPutRequest
 */
export interface DefaultApiMonitorAlertPolicyPolicyIdChannelChannelIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorAlertPolicyPolicyIdChannelChannelIdPut
     */
    readonly policyId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorAlertPolicyPolicyIdChannelChannelIdPut
     */
    readonly channelId: string

    /**
     * 
     * @type {UpdatePolicyNotificationCommand}
     * @memberof DefaultApiMonitorAlertPolicyPolicyIdChannelChannelIdPut
     */
    readonly updatePolicyNotificationCommand: UpdatePolicyNotificationCommand

    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorAlertPolicyPolicyIdChannelChannelIdPut
     */
    readonly authorization?: string
}

/**
 * Request parameters for monitorAlertPolicyPolicyIdIncidentGet operation in DefaultApi.
 * @export
 * @interface DefaultApiMonitorAlertPolicyPolicyIdIncidentGetRequest
 */
export interface DefaultApiMonitorAlertPolicyPolicyIdIncidentGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorAlertPolicyPolicyIdIncidentGet
     */
    readonly policyId: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorAlertPolicyPolicyIdIncidentGet
     */
    readonly authorization?: string

    /**
     * 
     * @type {GetIncidentsCommand}
     * @memberof DefaultApiMonitorAlertPolicyPolicyIdIncidentGet
     */
    readonly query?: GetIncidentsCommand
}

/**
 * Request parameters for monitorAlertPolicyPolicyIdIncidentPost operation in DefaultApi.
 * @export
 * @interface DefaultApiMonitorAlertPolicyPolicyIdIncidentPostRequest
 */
export interface DefaultApiMonitorAlertPolicyPolicyIdIncidentPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorAlertPolicyPolicyIdIncidentPost
     */
    readonly policyId: string

    /**
     * 
     * @type {CreateAlertPolicyIncidentCommand}
     * @memberof DefaultApiMonitorAlertPolicyPolicyIdIncidentPost
     */
    readonly createAlertPolicyIncidentCommand: CreateAlertPolicyIncidentCommand

    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorAlertPolicyPolicyIdIncidentPost
     */
    readonly authorization?: string
}

/**
 * Request parameters for monitorAlertPolicyPost operation in DefaultApi.
 * @export
 * @interface DefaultApiMonitorAlertPolicyPostRequest
 */
export interface DefaultApiMonitorAlertPolicyPostRequest {
    /**
     * 
     * @type {CreateAlertPolicyCommand}
     * @memberof DefaultApiMonitorAlertPolicyPost
     */
    readonly createAlertPolicyCommand: CreateAlertPolicyCommand

    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorAlertPolicyPost
     */
    readonly authorization?: string
}

/**
 * Request parameters for monitorAlertSyncPolicyPost operation in DefaultApi.
 * @export
 * @interface DefaultApiMonitorAlertSyncPolicyPostRequest
 */
export interface DefaultApiMonitorAlertSyncPolicyPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorAlertSyncPolicyPost
     */
    readonly authorization?: string
}

/**
 * Request parameters for monitorEmailsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiMonitorEmailsGetRequest
 */
export interface DefaultApiMonitorEmailsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorEmailsGet
     */
    readonly authorization?: string

    /**
     * 
     * @type {GetEmailsCommand}
     * @memberof DefaultApiMonitorEmailsGet
     */
    readonly query?: GetEmailsCommand
}

/**
 * Request parameters for monitorErpApiErrorsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiMonitorErpApiErrorsGetRequest
 */
export interface DefaultApiMonitorErpApiErrorsGetRequest {
    /**
     * 
     * @type {DateCommand}
     * @memberof DefaultApiMonitorErpApiErrorsGet
     */
    readonly dateCommand: DateCommand

    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorErpApiErrorsGet
     */
    readonly authorization?: string
}

/**
 * Request parameters for monitorErpErrorsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiMonitorErpErrorsGetRequest
 */
export interface DefaultApiMonitorErpErrorsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorErpErrorsGet
     */
    readonly authorization?: string

    /**
     * 
     * @type {GetErpErrorRequestQuery}
     * @memberof DefaultApiMonitorErpErrorsGet
     */
    readonly query?: GetErpErrorRequestQuery
}

/**
 * Request parameters for monitorLeadLogsValidationErrorsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiMonitorLeadLogsValidationErrorsGetRequest
 */
export interface DefaultApiMonitorLeadLogsValidationErrorsGetRequest {
    /**
     * 
     * @type {DateCommand}
     * @memberof DefaultApiMonitorLeadLogsValidationErrorsGet
     */
    readonly dateCommand: DateCommand

    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorLeadLogsValidationErrorsGet
     */
    readonly authorization?: string
}

/**
 * Request parameters for monitorLeadRequestStatusCodesGet operation in DefaultApi.
 * @export
 * @interface DefaultApiMonitorLeadRequestStatusCodesGetRequest
 */
export interface DefaultApiMonitorLeadRequestStatusCodesGetRequest {
    /**
     * 
     * @type {DateCommand}
     * @memberof DefaultApiMonitorLeadRequestStatusCodesGet
     */
    readonly dateCommand: DateCommand

    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorLeadRequestStatusCodesGet
     */
    readonly authorization?: string
}

/**
 * Request parameters for monitorLeadVendorsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiMonitorLeadVendorsGetRequest
 */
export interface DefaultApiMonitorLeadVendorsGetRequest {
    /**
     * 
     * @type {DateCommand}
     * @memberof DefaultApiMonitorLeadVendorsGet
     */
    readonly dateCommand: DateCommand

    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorLeadVendorsGet
     */
    readonly authorization?: string
}

/**
 * Request parameters for monitorSmsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiMonitorSmsGetRequest
 */
export interface DefaultApiMonitorSmsGetRequest {
    /**
     * 
     * @type {GetSMSesCommand}
     * @memberof DefaultApiMonitorSmsGet
     */
    readonly getSMSesCommand: GetSMSesCommand

    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorSmsGet
     */
    readonly authorization?: string
}

/**
 * Request parameters for monitorTasksQueuesWithTasksGet operation in DefaultApi.
 * @export
 * @interface DefaultApiMonitorTasksQueuesWithTasksGetRequest
 */
export interface DefaultApiMonitorTasksQueuesWithTasksGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorTasksQueuesWithTasksGet
     */
    readonly authorization?: string
}

/**
 * Request parameters for monitorTasksTaskDelete operation in DefaultApi.
 * @export
 * @interface DefaultApiMonitorTasksTaskDeleteRequest
 */
export interface DefaultApiMonitorTasksTaskDeleteRequest {
    /**
     * 
     * @type {TaskPathCommand}
     * @memberof DefaultApiMonitorTasksTaskDelete
     */
    readonly taskPathCommand: TaskPathCommand

    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorTasksTaskDelete
     */
    readonly authorization?: string
}

/**
 * Request parameters for monitorTasksTaskRunPost operation in DefaultApi.
 * @export
 * @interface DefaultApiMonitorTasksTaskRunPostRequest
 */
export interface DefaultApiMonitorTasksTaskRunPostRequest {
    /**
     * 
     * @type {TaskPathCommand}
     * @memberof DefaultApiMonitorTasksTaskRunPost
     */
    readonly taskPathCommand: TaskPathCommand

    /**
     * 
     * @type {string}
     * @memberof DefaultApiMonitorTasksTaskRunPost
     */
    readonly authorization?: string
}

/**
 * Request parameters for orderconfirmationOrderOrderidGet operation in DefaultApi.
 * @export
 * @interface DefaultApiOrderconfirmationOrderOrderidGetRequest
 */
export interface DefaultApiOrderconfirmationOrderOrderidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiOrderconfirmationOrderOrderidGet
     */
    readonly orderid: string
}

/**
 * Request parameters for partnerOrdersPartnerReferenceGet operation in DefaultApi.
 * @export
 * @interface DefaultApiPartnerOrdersPartnerReferenceGetRequest
 */
export interface DefaultApiPartnerOrdersPartnerReferenceGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiPartnerOrdersPartnerReferenceGet
     */
    readonly partnerReference: string
}

/**
 * Request parameters for partnerWebhooksPartnerReferenceDelete operation in DefaultApi.
 * @export
 * @interface DefaultApiPartnerWebhooksPartnerReferenceDeleteRequest
 */
export interface DefaultApiPartnerWebhooksPartnerReferenceDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiPartnerWebhooksPartnerReferenceDelete
     */
    readonly partnerReference: string
}

/**
 * Request parameters for partnerWebhooksPartnerReferencePost operation in DefaultApi.
 * @export
 * @interface DefaultApiPartnerWebhooksPartnerReferencePostRequest
 */
export interface DefaultApiPartnerWebhooksPartnerReferencePostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiPartnerWebhooksPartnerReferencePost
     */
    readonly partnerReference: string
}

/**
 * Request parameters for partnerWebhooksPartnerReferencePut operation in DefaultApi.
 * @export
 * @interface DefaultApiPartnerWebhooksPartnerReferencePutRequest
 */
export interface DefaultApiPartnerWebhooksPartnerReferencePutRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiPartnerWebhooksPartnerReferencePut
     */
    readonly partnerReference: string
}

/**
 * Request parameters for publicCalendarNotificationHandlePost operation in DefaultApi.
 * @export
 * @interface DefaultApiPublicCalendarNotificationHandlePostRequest
 */
export interface DefaultApiPublicCalendarNotificationHandlePostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiPublicCalendarNotificationHandlePost
     */
    readonly xGoogResourceState?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiPublicCalendarNotificationHandlePost
     */
    readonly xGoogChannelExpiration?: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiPublicCalendarNotificationHandlePost
     */
    readonly xGoogChannelToken?: string
}

/**
 * Request parameters for quoteOrderOrderidGet operation in DefaultApi.
 * @export
 * @interface DefaultApiQuoteOrderOrderidGetRequest
 */
export interface DefaultApiQuoteOrderOrderidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiQuoteOrderOrderidGet
     */
    readonly orderid: string
}

/**
 * Request parameters for smsSendMultipleGuidPost operation in DefaultApi.
 * @export
 * @interface DefaultApiSmsSendMultipleGuidPostRequest
 */
export interface DefaultApiSmsSendMultipleGuidPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiSmsSendMultipleGuidPost
     */
    readonly guid: string

    /**
     * 
     * @type {string}
     * @memberof DefaultApiSmsSendMultipleGuidPost
     */
    readonly test?: string
}

/**
 * Request parameters for smsSendSmsIdPost operation in DefaultApi.
 * @export
 * @interface DefaultApiSmsSendSmsIdPostRequest
 */
export interface DefaultApiSmsSendSmsIdPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiSmsSendSmsIdPost
     */
    readonly smsId: string
}

/**
 * Request parameters for syncOrderCpproOrderIdPost operation in DefaultApi.
 * @export
 * @interface DefaultApiSyncOrderCpproOrderIdPostRequest
 */
export interface DefaultApiSyncOrderCpproOrderIdPostRequest {
    /**
     * 
     * @type {string}
     * @memberof DefaultApiSyncOrderCpproOrderIdPost
     */
    readonly cpproOrderId: string
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {DefaultApiApiCRMCustomerCustomerIdAquireLockPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiCRMCustomerCustomerIdAquireLockPost(requestParameters: DefaultApiApiCRMCustomerCustomerIdAquireLockPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiCRMCustomerCustomerIdAquireLockPost(requestParameters.customerId, requestParameters.postCRMCustomerAquireLockRequest, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiCRMCustomerCustomerIdReleaseLockDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiCRMCustomerCustomerIdReleaseLockDelete(requestParameters: DefaultApiApiCRMCustomerCustomerIdReleaseLockDeleteRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiCRMCustomerCustomerIdReleaseLockDelete(requestParameters.customerId, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiCRMCustomerLocksGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiCRMCustomerLocksGet(requestParameters: DefaultApiApiCRMCustomerLocksGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiCRMCustomerLocksGet(requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiCRMDepartmentAreasGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiCRMDepartmentAreasGet(requestParameters: DefaultApiApiCRMDepartmentAreasGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiCRMDepartmentAreasGet(requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiCRMSMSTemplatesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiCRMSMSTemplatesGet(requestParameters: DefaultApiApiCRMSMSTemplatesGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiCRMSMSTemplatesGet(requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiCalendarNotificationChannelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiCalendarNotificationChannelPost(requestParameters: DefaultApiApiCalendarNotificationChannelPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiCalendarNotificationChannelPost(requestParameters.createNotificationChannelRequest, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiConstantGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiConstantGet(requestParameters: DefaultApiApiConstantGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiConstantGet(requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiConstantKeyDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiConstantKeyDelete(requestParameters: DefaultApiApiConstantKeyDeleteRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiConstantKeyDelete(requestParameters.key, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiConstantKeyGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiConstantKeyGet(requestParameters: DefaultApiApiConstantKeyGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiConstantKeyGet(requestParameters.key, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiConstantKeyVersionPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiConstantKeyVersionPost(requestParameters: DefaultApiApiConstantKeyVersionPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiConstantKeyVersionPost(requestParameters.key, requestParameters.postConstantRequest, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiConstantKeyVersionVersionDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiConstantKeyVersionVersionDelete(requestParameters: DefaultApiApiConstantKeyVersionVersionDeleteRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiConstantKeyVersionVersionDelete(requestParameters.key, requestParameters.version, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiConstantKeyVersionVersionDisablePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiConstantKeyVersionVersionDisablePost(requestParameters: DefaultApiApiConstantKeyVersionVersionDisablePostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiConstantKeyVersionVersionDisablePost(requestParameters.key, requestParameters.version, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiConstantKeyVersionVersionEnablePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiConstantKeyVersionVersionEnablePost(requestParameters: DefaultApiApiConstantKeyVersionVersionEnablePostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiConstantKeyVersionVersionEnablePost(requestParameters.key, requestParameters.version, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiConstantPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiConstantPost(requestParameters: DefaultApiApiConstantPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiConstantPost(requestParameters.postConstantRequest, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiCppro24soAcErpIdCreateOrderPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiCppro24soAcErpIdCreateOrderPost(requestParameters: DefaultApiApiCppro24soAcErpIdCreateOrderPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiCppro24soAcErpIdCreateOrderPost(requestParameters.acErpId, requestParameters.authorization, requestParameters.client, requestParameters.demo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiCpproCreateorderOrderOrderidPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiCpproCreateorderOrderOrderidPost(requestParameters: DefaultApiApiCpproCreateorderOrderOrderidPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiCpproCreateorderOrderOrderidPost(requestParameters.orderid, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiCpproMamutOrderIdCreateOrderPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiCpproMamutOrderIdCreateOrderPost(requestParameters: DefaultApiApiCpproMamutOrderIdCreateOrderPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiCpproMamutOrderIdCreateOrderPost(requestParameters.orderId, requestParameters.authorization, requestParameters.client, requestParameters.demo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiCpproOrderCreateSalesOrderChecklistPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiCpproOrderCreateSalesOrderChecklistPost(requestParameters: DefaultApiApiCpproOrderCreateSalesOrderChecklistPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiCpproOrderCreateSalesOrderChecklistPost(requestParameters.createSalesOrderChecklistRequest, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiCpproOrderCreateServiceOrderChecklistPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiCpproOrderCreateServiceOrderChecklistPost(requestParameters: DefaultApiApiCpproOrderCreateServiceOrderChecklistPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiCpproOrderCreateServiceOrderChecklistPost(requestParameters.createServiceOrderChecklistRequest, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiDatePublicHolidaysGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiDatePublicHolidaysGet(requestParameters: DefaultApiApiDatePublicHolidaysGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiDatePublicHolidaysGet(requestParameters.authorization, requestParameters.all, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiDatePublicHolidaysPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiDatePublicHolidaysPost(requestParameters: DefaultApiApiDatePublicHolidaysPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiDatePublicHolidaysPost(requestParameters.postPublicHolidaysRequest, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiDocsInternalJsonGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiDocsInternalJsonGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiDocsLeadJsonGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiDocsLeadJsonGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErp24soForhandlerSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErp24soForhandlerSearchGet(requestParameters: DefaultApiApiErp24soForhandlerSearchGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErp24soForhandlerSearchGet(requestParameters.authorization, requestParameters.externalId, requestParameters.name, requestParameters.isACT, requestParameters.test, requestParameters.search24SOAC, requestParameters.returnProps, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErpAcCalendarDeliveryDateChangePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErpAcCalendarDeliveryDateChangePost(requestParameters: DefaultApiApiErpAcCalendarDeliveryDateChangePostRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErpAcCalendarDeliveryDateChangePost(requestParameters.authorization, requestParameters.apiErpAcCalendarDeliveryDateChangePostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErpAcOrderCpproIdInactivatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErpAcOrderCpproIdInactivatePost(requestParameters: DefaultApiApiErpAcOrderCpproIdInactivatePostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErpAcOrderCpproIdInactivatePost(requestParameters.cpproId, requestParameters.authorization, requestParameters.dryRun, requestParameters.test, requestParameters.shouldDeleteTasks, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErpErpSyncOrderGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErpErpSyncOrderGet(requestParameters: DefaultApiApiErpErpSyncOrderGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErpErpSyncOrderGet(requestParameters.authorization, requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErpErpSyncOrdersGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErpErpSyncOrdersGet(requestParameters: DefaultApiApiErpErpSyncOrdersGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErpErpSyncOrdersGet(requestParameters.authorization, requestParameters.startTime, requestParameters.endTime, requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErpInstallItemUsageGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErpInstallItemUsageGet(requestParameters: DefaultApiApiErpInstallItemUsageGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErpInstallItemUsageGet(requestParameters.authorization, requestParameters.ignoreCache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErpInstallItemUsageItemNumberAlternativePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErpInstallItemUsageItemNumberAlternativePost(requestParameters: DefaultApiApiErpInstallItemUsageItemNumberAlternativePostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErpInstallItemUsageItemNumberAlternativePost(requestParameters.itemNumber, requestParameters.postInstallItemUsageAlternativeRequest, requestParameters.authorization, requestParameters.ignoreCache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErpInstallItemUsageItemNumberDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErpInstallItemUsageItemNumberDelete(requestParameters: DefaultApiApiErpInstallItemUsageItemNumberDeleteRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErpInstallItemUsageItemNumberDelete(requestParameters.itemNumber, requestParameters.authorization, requestParameters.ignoreCache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErpInstallItemUsageItemNumberPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErpInstallItemUsageItemNumberPut(requestParameters: DefaultApiApiErpInstallItemUsageItemNumberPutRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErpInstallItemUsageItemNumberPut(requestParameters.itemNumber, requestParameters.putInstallItemUsageRequest, requestParameters.authorization, requestParameters.ignoreCache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdDelete(requestParameters: DefaultApiApiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdDelete(requestParameters.itemUsageId, requestParameters.alternativeId, requestParameters.authorization, requestParameters.ignoreCache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdPut(requestParameters: DefaultApiApiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdPutRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdPut(requestParameters.itemUsageId, requestParameters.alternativeId, requestParameters.putInstallItemUsageAlternativeRequest, requestParameters.authorization, requestParameters.ignoreCache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErpInstallItemUsagePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErpInstallItemUsagePost(requestParameters: DefaultApiApiErpInstallItemUsagePostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErpInstallItemUsagePost(requestParameters.postInstallItemUsageRequest, requestParameters.authorization, requestParameters.ignoreCache, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErpInstallOrderInstallCPproIdChecklistInstalledPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErpInstallOrderInstallCPproIdChecklistInstalledPost(requestParameters: DefaultApiApiErpInstallOrderInstallCPproIdChecklistInstalledPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErpInstallOrderInstallCPproIdChecklistInstalledPost(requestParameters.installCPproId, requestParameters.authorization, requestParameters.continueProcess, requestParameters.dryRun, requestParameters.manual, requestParameters.enableDelete, requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErpMrpItemItemNumberStockGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErpMrpItemItemNumberStockGet(requestParameters: DefaultApiApiErpMrpItemItemNumberStockGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErpMrpItemItemNumberStockGet(requestParameters.itemNumber, requestParameters.authorization, requestParameters.test, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErpMrpOrderCpproInstallIdCheckSerialNumberGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErpMrpOrderCpproInstallIdCheckSerialNumberGet(requestParameters: DefaultApiApiErpMrpOrderCpproInstallIdCheckSerialNumberGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErpMrpOrderCpproInstallIdCheckSerialNumberGet(requestParameters.cpproInstallId, requestParameters.authorization, requestParameters.questionId, requestParameters.serialNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErpMrpOrderOrderIdPickErrorsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErpMrpOrderOrderIdPickErrorsGet(requestParameters: DefaultApiApiErpMrpOrderOrderIdPickErrorsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErpMrpOrderOrderIdPickErrorsGet(requestParameters.orderId, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErpMrpOrderStatisticsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErpMrpOrderStatisticsGet(requestParameters: DefaultApiApiErpMrpOrderStatisticsGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErpMrpOrderStatisticsGet(requestParameters.authorization, requestParameters.startTime, requestParameters.endTime, requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErpMrpPickErrorDeviationReasonDeviationReasonIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErpMrpPickErrorDeviationReasonDeviationReasonIdDelete(requestParameters: DefaultApiApiErpMrpPickErrorDeviationReasonDeviationReasonIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErpMrpPickErrorDeviationReasonDeviationReasonIdDelete(requestParameters.deviationReasonId, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErpMrpPickErrorDeviationReasonDeviationReasonIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErpMrpPickErrorDeviationReasonDeviationReasonIdPut(requestParameters: DefaultApiApiErpMrpPickErrorDeviationReasonDeviationReasonIdPutRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErpMrpPickErrorDeviationReasonDeviationReasonIdPut(requestParameters.deviationReasonId, requestParameters.putPostPickErrorDeviationReasonRequest, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErpMrpPickErrorDeviationReasonPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErpMrpPickErrorDeviationReasonPost(requestParameters: DefaultApiApiErpMrpPickErrorDeviationReasonPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErpMrpPickErrorDeviationReasonPost(requestParameters.putPostPickErrorDeviationReasonRequest, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErpMrpPickErrorDeviationReasonsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErpMrpPickErrorDeviationReasonsGet(requestParameters: DefaultApiApiErpMrpPickErrorDeviationReasonsGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErpMrpPickErrorDeviationReasonsGet(requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErpMrpPickErrorPickErrorIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErpMrpPickErrorPickErrorIdPut(requestParameters: DefaultApiApiErpMrpPickErrorPickErrorIdPutRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErpMrpPickErrorPickErrorIdPut(requestParameters.pickErrorId, requestParameters.putMrpPickErrorRequest, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErpMrpPickErrorsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErpMrpPickErrorsGet(requestParameters: DefaultApiApiErpMrpPickErrorsGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErpMrpPickErrorsGet(requestParameters.authorization, requestParameters.status, requestParameters.startTime, requestParameters.endTime, requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErpMrpPickErrorsPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErpMrpPickErrorsPut(requestParameters: DefaultApiApiErpMrpPickErrorsPutRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErpMrpPickErrorsPut(requestParameters.putMrpPickErrorsRequest, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErpOrder24soCreateOrderTaskPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErpOrder24soCreateOrderTaskPost(requestParameters: DefaultApiApiErpOrder24soCreateOrderTaskPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErpOrder24soCreateOrderTaskPost(requestParameters.postCreateOrderRequest, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErpOrderCpproId24soACInvoicePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErpOrderCpproId24soACInvoicePost(requestParameters: DefaultApiApiErpOrderCpproId24soACInvoicePostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErpOrderCpproId24soACInvoicePost(requestParameters.cpproId, requestParameters.authorization, requestParameters.dryRun, requestParameters.manual, requestParameters.enableDelete, requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErpOrderCpproId24soInactivateFJAndInvoiceACPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErpOrderCpproId24soInactivateFJAndInvoiceACPost(requestParameters: DefaultApiApiErpOrderCpproId24soInactivateFJAndInvoiceACPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErpOrderCpproId24soInactivateFJAndInvoiceACPost(requestParameters.cpproId, requestParameters.authorization, requestParameters.dryRun, requestParameters.manual, requestParameters.enableDelete, requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErpOrderCpproIdCheckEnTurTilOrderInvoicedGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErpOrderCpproIdCheckEnTurTilOrderInvoicedGet(requestParameters: DefaultApiApiErpOrderCpproIdCheckEnTurTilOrderInvoicedGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErpOrderCpproIdCheckEnTurTilOrderInvoicedGet(requestParameters.cpproId, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErpOrderCpproIdMrpFindMRPOrderPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErpOrderCpproIdMrpFindMRPOrderPost(requestParameters: DefaultApiApiErpOrderCpproIdMrpFindMRPOrderPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErpOrderCpproIdMrpFindMRPOrderPost(requestParameters.cpproId, requestParameters.authorization, requestParameters.dryRun, requestParameters.manual, requestParameters.enableDelete, requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErpOrderCpproIdMrpInvoicePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErpOrderCpproIdMrpInvoicePost(requestParameters: DefaultApiApiErpOrderCpproIdMrpInvoicePostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErpOrderCpproIdMrpInvoicePost(requestParameters.cpproId, requestParameters.authorization, requestParameters.dryRun, requestParameters.manual, requestParameters.enableDelete, requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErpOrderCpproIdMrpManuallyInvoicedPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErpOrderCpproIdMrpManuallyInvoicedPost(requestParameters: DefaultApiApiErpOrderCpproIdMrpManuallyInvoicedPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErpOrderCpproIdMrpManuallyInvoicedPost(requestParameters.cpproId, requestParameters.postMRPManuallyInvoicedRequest, requestParameters.authorization, requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErpOrderCpproIdMrpPickPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErpOrderCpproIdMrpPickPost(requestParameters: DefaultApiApiErpOrderCpproIdMrpPickPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErpOrderCpproIdMrpPickPost(requestParameters.cpproId, requestParameters.authorization, requestParameters.dryRun, requestParameters.manual, requestParameters.enableDelete, requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErpOrderCpproIdMrpSalesOrderGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErpOrderCpproIdMrpSalesOrderGet(requestParameters: DefaultApiApiErpOrderCpproIdMrpSalesOrderGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErpOrderCpproIdMrpSalesOrderGet(requestParameters.cpproId, requestParameters.authorization, requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErpOrderCpproIdMrpShipPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErpOrderCpproIdMrpShipPost(requestParameters: DefaultApiApiErpOrderCpproIdMrpShipPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErpOrderCpproIdMrpShipPost(requestParameters.cpproId, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErpOrderCpproIdRecreateOrderPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErpOrderCpproIdRecreateOrderPost(requestParameters: DefaultApiApiErpOrderCpproIdRecreateOrderPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErpOrderCpproIdRecreateOrderPost(requestParameters.cpproId, requestParameters.authorization, requestParameters.deliveryDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiErpSmsSendCalendarRemindersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiErpSmsSendCalendarRemindersPost(requestParameters: DefaultApiApiErpSmsSendCalendarRemindersPostRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiErpSmsSendCalendarRemindersPost(requestParameters.authorization, requestParameters.event, requestParameters.optionalNow, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiSmsCustomerCustomerIdMessagesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiSmsCustomerCustomerIdMessagesGet(requestParameters: DefaultApiApiSmsCustomerCustomerIdMessagesGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiSmsCustomerCustomerIdMessagesGet(requestParameters.customerId, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiSmsIdHandledReplyPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiSmsIdHandledReplyPut(requestParameters: DefaultApiApiSmsIdHandledReplyPutRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiSmsIdHandledReplyPut(requestParameters.id, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiSmsRepliesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiSmsRepliesGet(requestParameters: DefaultApiApiSmsRepliesGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiSmsRepliesGet(requestParameters.authorization, requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiApiSmsSendPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiSmsSendPost(requestParameters: DefaultApiApiSmsSendPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiSmsSendPost(requestParameters.sendSMSAPIRequest, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiCalendarNotificationChannelDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public calendarNotificationChannelDelete(requestParameters: DefaultApiCalendarNotificationChannelDeleteRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).calendarNotificationChannelDelete(requestParameters.calendarNotificationChannelPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public calendarNotificationChannelInitialCreateAllPost(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).calendarNotificationChannelInitialCreateAllPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiCalendarNotificationChannelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public calendarNotificationChannelPost(requestParameters: DefaultApiCalendarNotificationChannelPostRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).calendarNotificationChannelPost(requestParameters.calendarNotificationChannelPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public calendarNotificationChannelRefreshAllPost(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).calendarNotificationChannelRefreshAllPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiCalendarNotificationChannelRefreshPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public calendarNotificationChannelRefreshPost(requestParameters: DefaultApiCalendarNotificationChannelRefreshPostRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).calendarNotificationChannelRefreshPost(requestParameters.calendarNotificationChannelPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiCalendarNotificationHandleEventNotificationPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public calendarNotificationHandleEventNotificationPost(requestParameters: DefaultApiCalendarNotificationHandleEventNotificationPostRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).calendarNotificationHandleEventNotificationPost(requestParameters.calendarNotificationHandleUpdatedEventPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiCalendarNotificationHandleUpdatedEventPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public calendarNotificationHandleUpdatedEventPost(requestParameters: DefaultApiCalendarNotificationHandleUpdatedEventPostRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).calendarNotificationHandleUpdatedEventPost(requestParameters.calendarNotificationHandleUpdatedEventPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiCpproEnTurTilOrderOrderidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public cpproEnTurTilOrderOrderidGet(requestParameters: DefaultApiCpproEnTurTilOrderOrderidGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).cpproEnTurTilOrderOrderidGet(requestParameters.orderid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiCpproGdprOrderOrderidDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public cpproGdprOrderOrderidDelete(requestParameters: DefaultApiCpproGdprOrderOrderidDeleteRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).cpproGdprOrderOrderidDelete(requestParameters.orderid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiCpproGdprOrderOrderidPdfsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public cpproGdprOrderOrderidPdfsGet(requestParameters: DefaultApiCpproGdprOrderOrderidPdfsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).cpproGdprOrderOrderidPdfsGet(requestParameters.orderid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public cpproGdprOrdersDelete(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).cpproGdprOrdersDelete(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public cpproGdprOrdersGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).cpproGdprOrdersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public cpproGdprOrdersPdfsGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).cpproGdprOrdersPdfsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiCpproLostOrderOrderidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public cpproLostOrderOrderidGet(requestParameters: DefaultApiCpproLostOrderOrderidGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).cpproLostOrderOrderidGet(requestParameters.orderid, requestParameters.dryRun, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiCpproNotificationOrderOrderidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public cpproNotificationOrderOrderidGet(requestParameters: DefaultApiCpproNotificationOrderOrderidGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).cpproNotificationOrderOrderidGet(requestParameters.orderid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiCpproOrderOrderidPricesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public cpproOrderOrderidPricesGet(requestParameters: DefaultApiCpproOrderOrderidPricesGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).cpproOrderOrderidPricesGet(requestParameters.orderid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiCpproProsjektGodkjentOrderOrderidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public cpproProsjektGodkjentOrderOrderidGet(requestParameters: DefaultApiCpproProsjektGodkjentOrderOrderidGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).cpproProsjektGodkjentOrderOrderidGet(requestParameters.orderid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public cpproSyncGoogleUsersGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).cpproSyncGoogleUsersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiCpproUpdateBefaringEventOrderidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public cpproUpdateBefaringEventOrderidGet(requestParameters: DefaultApiCpproUpdateBefaringEventOrderidGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).cpproUpdateBefaringEventOrderidGet(requestParameters.orderid, requestParameters.pdfType, requestParameters.dryRun, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiCpproUpdateSaleCalendarsCalendarIdPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public cpproUpdateSaleCalendarsCalendarIdPost(requestParameters: DefaultApiCpproUpdateSaleCalendarsCalendarIdPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).cpproUpdateSaleCalendarsCalendarIdPost(requestParameters.calendarId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public cpproUpdateSaleCalendarsGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).cpproUpdateSaleCalendarsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public cpproWebhookCheckQuestionsPost(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).cpproWebhookCheckQuestionsPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiCreateorderOrderOrderidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createorderOrderOrderidGet(requestParameters: DefaultApiCreateorderOrderOrderidGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createorderOrderOrderidGet(requestParameters.orderid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public emailInsertPost(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).emailInsertPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiEmailSendMailIdPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public emailSendMailIdPost(requestParameters: DefaultApiEmailSendMailIdPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).emailSendMailIdPost(requestParameters.mailId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErp24soAcErpIdCreateCPProOrderPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erp24soAcErpIdCreateCPProOrderPost(requestParameters: DefaultApiErp24soAcErpIdCreateCPProOrderPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erp24soAcErpIdCreateCPProOrderPost(requestParameters.acErpId, requestParameters.client, requestParameters.demo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErp24soFindFJOrderAfterMRPInvoicedCpproIdPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erp24soFindFJOrderAfterMRPInvoicedCpproIdPost(requestParameters: DefaultApiErp24soFindFJOrderAfterMRPInvoicedCpproIdPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erp24soFindFJOrderAfterMRPInvoicedCpproIdPost(requestParameters.cpproId, requestParameters.dryRun, requestParameters.manual, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErp24soForhandlerSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erp24soForhandlerSearchGet(requestParameters: DefaultApiErp24soForhandlerSearchGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erp24soForhandlerSearchGet(requestParameters.externalId, requestParameters.name, requestParameters.isACT, requestParameters.test, requestParameters.search24SOAC, requestParameters.returnProps, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpAcCalendarDeliveryDateChangePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpAcCalendarDeliveryDateChangePost(requestParameters: DefaultApiErpAcCalendarDeliveryDateChangePostRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpAcCalendarDeliveryDateChangePost(requestParameters.apiErpAcCalendarDeliveryDateChangePostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpAcCalendarOrderCpproIdEventDeliveryDateChangeEventEventIdPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpAcCalendarOrderCpproIdEventDeliveryDateChangeEventEventIdPost(requestParameters: DefaultApiErpAcCalendarOrderCpproIdEventDeliveryDateChangeEventEventIdPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpAcCalendarOrderCpproIdEventDeliveryDateChangeEventEventIdPost(requestParameters.cpproId, requestParameters.eventId, requestParameters.skipDeliveryDateUpdate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpAcChecklistInstalledOrderCpproIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpAcChecklistInstalledOrderCpproIdGet(requestParameters: DefaultApiErpAcChecklistInstalledOrderCpproIdGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpAcChecklistInstalledOrderCpproIdGet(requestParameters.cpproId, requestParameters.disableDelete, requestParameters.dryRun, requestParameters.manual, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpAcCreateOrderOrderIdPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpAcCreateOrderOrderIdPost(requestParameters: DefaultApiErpAcCreateOrderOrderIdPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpAcCreateOrderOrderIdPost(requestParameters.orderId, requestParameters.deliveryDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpAcOrderInactivatePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpAcOrderInactivatePost(requestParameters: DefaultApiErpAcOrderInactivatePostRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpAcOrderInactivatePost(requestParameters.changedAfter, requestParameters.test, requestParameters.dryRun, requestParameters.includeAlreadyInactivated, requestParameters.shouldDeleteTasks, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpAcRecreateOrderCpproIdPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpAcRecreateOrderCpproIdPost(requestParameters: DefaultApiErpAcRecreateOrderCpproIdPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpAcRecreateOrderCpproIdPost(requestParameters.cpproId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpCreditSaleInvoiceACOrderCpproIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpCreditSaleInvoiceACOrderCpproIdGet(requestParameters: DefaultApiErpCreditSaleInvoiceACOrderCpproIdGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpCreditSaleInvoiceACOrderCpproIdGet(requestParameters.cpproId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpCustomerGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpCustomerGet(requestParameters: DefaultApiErpCustomerGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpCustomerGet(requestParameters.name, requestParameters.email, requestParameters.phone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpCustomerIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpCustomerIdGet(requestParameters: DefaultApiErpCustomerIdGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpCustomerIdGet(requestParameters.id, requestParameters.external, requestParameters.identity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpCustomerIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpCustomerIdPatch(requestParameters: DefaultApiErpCustomerIdPatchRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpCustomerIdPatch(requestParameters.id, requestParameters.external, requestParameters.identity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpCustomerPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpCustomerPatch(requestParameters: DefaultApiErpCustomerPatchRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpCustomerPatch(requestParameters.name, requestParameters.email, requestParameters.phone, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpCustomerPost(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpCustomerPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpFjCreateOrderFromACOrderIdPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpFjCreateOrderFromACOrderIdPost(requestParameters: DefaultApiErpFjCreateOrderFromACOrderIdPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpFjCreateOrderFromACOrderIdPost(requestParameters.orderId, requestParameters.deliveryDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpFjpartnerCreateOrderCpproIdPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpFjpartnerCreateOrderCpproIdPost(requestParameters: DefaultApiErpFjpartnerCreateOrderCpproIdPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpFjpartnerCreateOrderCpproIdPost(requestParameters.cpproId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpFjpartnerMigrateDemoOrderToLiveCpproIdPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpFjpartnerMigrateDemoOrderToLiveCpproIdPost(requestParameters: DefaultApiErpFjpartnerMigrateDemoOrderToLiveCpproIdPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpFjpartnerMigrateDemoOrderToLiveCpproIdPost(requestParameters.cpproId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpFjpartnerOrderCpproIdCreateExtraACOrderPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpFjpartnerOrderCpproIdCreateExtraACOrderPost(requestParameters: DefaultApiErpFjpartnerOrderCpproIdCreateExtraACOrderPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpFjpartnerOrderCpproIdCreateExtraACOrderPost(requestParameters.cpproId, requestParameters.test, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpFjpartnerOrderCpproIdInvoiceExtraACOrderPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpFjpartnerOrderCpproIdInvoiceExtraACOrderPost(requestParameters: DefaultApiErpFjpartnerOrderCpproIdInvoiceExtraACOrderPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpFjpartnerOrderCpproIdInvoiceExtraACOrderPost(requestParameters.cpproId, requestParameters.test, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpFjpartnerOrderCpproPartnerProgIdCreateCPProSalesOrderPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpFjpartnerOrderCpproPartnerProgIdCreateCPProSalesOrderPost(requestParameters: DefaultApiErpFjpartnerOrderCpproPartnerProgIdCreateCPProSalesOrderPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpFjpartnerOrderCpproPartnerProgIdCreateCPProSalesOrderPost(requestParameters.cpproPartnerProgId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpFjpartnerOrderCpproPartnerProgIdFindMRPOrderPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpFjpartnerOrderCpproPartnerProgIdFindMRPOrderPost(requestParameters: DefaultApiErpFjpartnerOrderCpproPartnerProgIdFindMRPOrderPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpFjpartnerOrderCpproPartnerProgIdFindMRPOrderPost(requestParameters.cpproPartnerProgId, requestParameters.acCPProId, requestParameters.dryRun, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpFjpartnerOrderCpproPartnerProgIdInstallCompleteWhenInstallerIsACPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpFjpartnerOrderCpproPartnerProgIdInstallCompleteWhenInstallerIsACPost(requestParameters: DefaultApiErpFjpartnerOrderCpproPartnerProgIdInstallCompleteWhenInstallerIsACPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpFjpartnerOrderCpproPartnerProgIdInstallCompleteWhenInstallerIsACPost(requestParameters.cpproPartnerProgId, requestParameters.acCPProId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpFjpartnerSyncPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpFjpartnerSyncPost(requestParameters: DefaultApiErpFjpartnerSyncPostRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpFjpartnerSyncPost(requestParameters.orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpManualOrderOrderIdAutomateOrderPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpManualOrderOrderIdAutomateOrderPost(requestParameters: DefaultApiErpManualOrderOrderIdAutomateOrderPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpManualOrderOrderIdAutomateOrderPost(requestParameters.orderId, requestParameters.test, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpManualOrderOrderIdInvoice24SOPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpManualOrderOrderIdInvoice24SOPost(requestParameters: DefaultApiErpManualOrderOrderIdInvoice24SOPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpManualOrderOrderIdInvoice24SOPost(requestParameters.orderId, requestParameters.test, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpMrpFindMRPOrderCpproIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpMrpFindMRPOrderCpproIdGet(requestParameters: DefaultApiErpMrpFindMRPOrderCpproIdGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpMrpFindMRPOrderCpproIdGet(requestParameters.cpproId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpMrpInvoiceOrderCpproIdPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpMrpInvoiceOrderCpproIdPost(requestParameters: DefaultApiErpMrpInvoiceOrderCpproIdPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpMrpInvoiceOrderCpproIdPost(requestParameters.cpproId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpMrpPickOrderCpproIdPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpMrpPickOrderCpproIdPost(requestParameters: DefaultApiErpMrpPickOrderCpproIdPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpMrpPickOrderCpproIdPost(requestParameters.cpproId, requestParameters.dryRun, requestParameters.manual, requestParameters.xCloudtasksTaskretrycount, requestParameters.xCloudtasksTaskexecutioncount, requestParameters.xCloudtasksTasketa, requestParameters.xCloudtasksQueuename, requestParameters.xCloudtasksTaskname, requestParameters.xCloudtasksTaskpreviousresponse, requestParameters.xCloudtasksTaskretryreason, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpMrpShipOrderCpproIdPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpMrpShipOrderCpproIdPost(requestParameters: DefaultApiErpMrpShipOrderCpproIdPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpMrpShipOrderCpproIdPost(requestParameters.cpproId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpOrderCpproIdActivityhelperlocalInsertCustomerAndOrderPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpOrderCpproIdActivityhelperlocalInsertCustomerAndOrderPost(requestParameters: DefaultApiErpOrderCpproIdActivityhelperlocalInsertCustomerAndOrderPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpOrderCpproIdActivityhelperlocalInsertCustomerAndOrderPost(requestParameters.cpproId, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpOrderCpproIdCRMAddCustomerAndOrderPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpOrderCpproIdCRMAddCustomerAndOrderPost(requestParameters: DefaultApiErpOrderCpproIdCRMAddCustomerAndOrderPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpOrderCpproIdCRMAddCustomerAndOrderPost(requestParameters.cpproId, requestParameters.type, requestParameters.dryRun, requestParameters.fjOrder, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpOrderCpproIdCalendarCreateServiceEventPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpOrderCpproIdCalendarCreateServiceEventPost(requestParameters: DefaultApiErpOrderCpproIdCalendarCreateServiceEventPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpOrderCpproIdCalendarCreateServiceEventPost(requestParameters.cpproId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpOrderCpproIdCreditSaleMrpInvoicedPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpOrderCpproIdCreditSaleMrpInvoicedPost(requestParameters: DefaultApiErpOrderCpproIdCreditSaleMrpInvoicedPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpOrderCpproIdCreditSaleMrpInvoicedPost(requestParameters.cpproId, requestParameters.dryRun, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpOrderCpproIdInvoiceFJFinalOrderPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpOrderCpproIdInvoiceFJFinalOrderPost(requestParameters: DefaultApiErpOrderCpproIdInvoiceFJFinalOrderPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpOrderCpproIdInvoiceFJFinalOrderPost(requestParameters.cpproId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpOrderCpproIdLegacyServerAddOrderPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpOrderCpproIdLegacyServerAddOrderPost(requestParameters: DefaultApiErpOrderCpproIdLegacyServerAddOrderPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpOrderCpproIdLegacyServerAddOrderPost(requestParameters.cpproId, requestParameters.type, requestParameters.dryRun, requestParameters.fjOrder, requestParameters.includeVAT, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpOrderCpproIdRepairMrpInvoicedPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpOrderCpproIdRepairMrpInvoicedPost(requestParameters: DefaultApiErpOrderCpproIdRepairMrpInvoicedPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpOrderCpproIdRepairMrpInvoicedPost(requestParameters.cpproId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpOrderCpproIdSpreadsheetUpdate20502038PostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpOrderCpproIdSpreadsheetUpdate20502038Post(requestParameters: DefaultApiErpOrderCpproIdSpreadsheetUpdate20502038PostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpOrderCpproIdSpreadsheetUpdate20502038Post(requestParameters.cpproId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpOrderIdOrderlinesPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpOrderIdOrderlinesPost(requestParameters: DefaultApiErpOrderIdOrderlinesPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpOrderIdOrderlinesPost(requestParameters.id, requestParameters.external, requestParameters.identity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpOrderOrderIdSmsSendInvoiceLinkPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpOrderOrderIdSmsSendInvoiceLinkPost(requestParameters: DefaultApiErpOrderOrderIdSmsSendInvoiceLinkPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpOrderOrderIdSmsSendInvoiceLinkPost(requestParameters.orderId, requestParameters.isACErpId, requestParameters.ignoreSMS, requestParameters.isACT, requestParameters.test, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpOrderPost(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpOrderPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpRepairInvoiceACOrderCpproIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpRepairInvoiceACOrderCpproIdGet(requestParameters: DefaultApiErpRepairInvoiceACOrderCpproIdGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpRepairInvoiceACOrderCpproIdGet(requestParameters.cpproId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpScheduleFjFindNewOrdersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpScheduleFjFindNewOrdersPost(requestParameters: DefaultApiErpScheduleFjFindNewOrdersPostRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpScheduleFjFindNewOrdersPost(requestParameters.changedAfter, requestParameters.fjOrderId, requestParameters.test, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpScheduleManualOrderFindManualOrdersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpScheduleManualOrderFindManualOrdersPost(requestParameters: DefaultApiErpScheduleManualOrderFindManualOrdersPostRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpScheduleManualOrderFindManualOrdersPost(requestParameters.test, requestParameters.orderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpScheduleMrpSyncProductsToCPProPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpScheduleMrpSyncProductsToCPProPost(requestParameters: DefaultApiErpScheduleMrpSyncProductsToCPProPostRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpScheduleMrpSyncProductsToCPProPost(requestParameters.disableDelete, requestParameters.dryRun, requestParameters.itemNumber, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpScheduleOrderFindInvoicedOrdersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpScheduleOrderFindInvoicedOrdersPost(requestParameters: DefaultApiErpScheduleOrderFindInvoicedOrdersPostRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpScheduleOrderFindInvoicedOrdersPost(requestParameters.demo, requestParameters.act, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpScheduleOrderWaitingForChecklistPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpScheduleOrderWaitingForChecklistPost(requestParameters: DefaultApiErpScheduleOrderWaitingForChecklistPostRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpScheduleOrderWaitingForChecklistPost(requestParameters.cpproId, requestParameters.dryRun, requestParameters.manual, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpScheduleSmsSendCalendarSMSRemindersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpScheduleSmsSendCalendarSMSRemindersPost(requestParameters: DefaultApiErpScheduleSmsSendCalendarSMSRemindersPostRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpScheduleSmsSendCalendarSMSRemindersPost(requestParameters.event, requestParameters.optionalNow, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpScheduleUsersSyncEmployeeInfoToCPProPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpScheduleUsersSyncEmployeeInfoToCPProPost(requestParameters: DefaultApiErpScheduleUsersSyncEmployeeInfoToCPProPostRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpScheduleUsersSyncEmployeeInfoToCPProPost(requestParameters.dryRun, requestParameters.userEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiErpSync24SOToMRPGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public erpSync24SOToMRPGet(requestParameters: DefaultApiErpSync24SOToMRPGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).erpSync24SOToMRPGet(requestParameters.env, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiFjordersErpAgreedInstallationDateOrderOrderidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fjordersErpAgreedInstallationDateOrderOrderidGet(requestParameters: DefaultApiFjordersErpAgreedInstallationDateOrderOrderidGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fjordersErpAgreedInstallationDateOrderOrderidGet(requestParameters.orderid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiFjordersErpDeliveryaddressOrderOrderidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fjordersErpDeliveryaddressOrderOrderidGet(requestParameters: DefaultApiFjordersErpDeliveryaddressOrderOrderidGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fjordersErpDeliveryaddressOrderOrderidGet(requestParameters.orderid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiFjordersErpInstallerJobAcceptedOrderOrderidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fjordersErpInstallerJobAcceptedOrderOrderidGet(requestParameters: DefaultApiFjordersErpInstallerJobAcceptedOrderOrderidGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fjordersErpInstallerJobAcceptedOrderOrderidGet(requestParameters.orderid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiFjordersErpInstallerOrderOrderidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fjordersErpInstallerOrderOrderidGet(requestParameters: DefaultApiFjordersErpInstallerOrderOrderidGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fjordersErpInstallerOrderOrderidGet(requestParameters.orderid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiFjordersErpUpdateDeliveryDateOrderOrderidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fjordersErpUpdateDeliveryDateOrderOrderidGet(requestParameters: DefaultApiFjordersErpUpdateDeliveryDateOrderOrderidGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fjordersErpUpdateDeliveryDateOrderOrderidGet(requestParameters.orderid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiFjordersGmailMessageMessageidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fjordersGmailMessageMessageidGet(requestParameters: DefaultApiFjordersGmailMessageMessageidGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fjordersGmailMessageMessageidGet(requestParameters.messageid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiFjordersInstallationCompleteConfirmationOrderOrderidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fjordersInstallationCompleteConfirmationOrderOrderidGet(requestParameters: DefaultApiFjordersInstallationCompleteConfirmationOrderOrderidGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fjordersInstallationCompleteConfirmationOrderOrderidGet(requestParameters.orderid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fjordersOrderAllFixEmailMessageIdsGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fjordersOrderAllFixEmailMessageIdsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fjordersOrderAllInstallationCompleteConfirmationGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fjordersOrderAllInstallationCompleteConfirmationGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiFjordersOrderOrderidFixEmailMessageIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fjordersOrderOrderidFixEmailMessageIdGet(requestParameters: DefaultApiFjordersOrderOrderidFixEmailMessageIdGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fjordersOrderOrderidFixEmailMessageIdGet(requestParameters.orderid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiFjordersOrderOrderidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fjordersOrderOrderidGet(requestParameters: DefaultApiFjordersOrderOrderidGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fjordersOrderOrderidGet(requestParameters.orderid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiFjordersOrderReferenceReferenceIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fjordersOrderReferenceReferenceIdGet(requestParameters: DefaultApiFjordersOrderReferenceReferenceIdGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fjordersOrderReferenceReferenceIdGet(requestParameters.referenceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiFjordersOrdersCpproSyncGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fjordersOrdersCpproSyncGet(requestParameters: DefaultApiFjordersOrdersCpproSyncGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fjordersOrdersCpproSyncGet(requestParameters.includeFinished, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fjordersOrdersCpproSyncOnlyDataGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fjordersOrdersCpproSyncOnlyDataGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiFjordersReferenceReferenceFiksMonteringUtfortPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fjordersReferenceReferenceFiksMonteringUtfortPost(requestParameters: DefaultApiFjordersReferenceReferenceFiksMonteringUtfortPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fjordersReferenceReferenceFiksMonteringUtfortPost(requestParameters.reference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiFjordersUpdateProductOrderOrderidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public fjordersUpdateProductOrderOrderidGet(requestParameters: DefaultApiFjordersUpdateProductOrderOrderidGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).fjordersUpdateProductOrderOrderidGet(requestParameters.orderid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public googleCloudVisionTextDetectionPost(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).googleCloudVisionTextDetectionPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public healthGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).healthGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiLeadManagementScheduleMoveLeadsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public leadManagementScheduleMoveLeadsPost(requestParameters: DefaultApiLeadManagementScheduleMoveLeadsPostRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).leadManagementScheduleMoveLeadsPost(requestParameters.test, requestParameters.dryRun, requestParameters.dryrun, requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public leadManagementVendorPost(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).leadManagementVendorPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiLeadManagementVendorUserIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public leadManagementVendorUserIdDelete(requestParameters: DefaultApiLeadManagementVendorUserIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).leadManagementVendorUserIdDelete(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiLeadManagementVendorUserIdPatchRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public leadManagementVendorUserIdPatch(requestParameters: DefaultApiLeadManagementVendorUserIdPatchRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).leadManagementVendorUserIdPatch(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public leadManagementVendorsGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).leadManagementVendorsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMonitorAlertIncidentGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public monitorAlertIncidentGet(requestParameters: DefaultApiMonitorAlertIncidentGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).monitorAlertIncidentGet(requestParameters.authorization, requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMonitorAlertIncidentIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public monitorAlertIncidentIdDelete(requestParameters: DefaultApiMonitorAlertIncidentIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).monitorAlertIncidentIdDelete(requestParameters.id, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMonitorAlertIncidentIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public monitorAlertIncidentIdPut(requestParameters: DefaultApiMonitorAlertIncidentIdPutRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).monitorAlertIncidentIdPut(requestParameters.id, requestParameters.updateAlertPolicyIncidentCommand, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMonitorAlertIncidentPubsubPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public monitorAlertIncidentPubsubPost(requestParameters: DefaultApiMonitorAlertIncidentPubsubPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).monitorAlertIncidentPubsubPost(requestParameters.pubSubPushRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMonitorAlertNotificationChannelGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public monitorAlertNotificationChannelGet(requestParameters: DefaultApiMonitorAlertNotificationChannelGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).monitorAlertNotificationChannelGet(requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMonitorAlertNotificationChannelIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public monitorAlertNotificationChannelIdDelete(requestParameters: DefaultApiMonitorAlertNotificationChannelIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).monitorAlertNotificationChannelIdDelete(requestParameters.id, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMonitorAlertNotificationChannelIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public monitorAlertNotificationChannelIdPut(requestParameters: DefaultApiMonitorAlertNotificationChannelIdPutRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).monitorAlertNotificationChannelIdPut(requestParameters.id, requestParameters.updateNotificationChannelCommand, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMonitorAlertNotificationChannelPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public monitorAlertNotificationChannelPost(requestParameters: DefaultApiMonitorAlertNotificationChannelPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).monitorAlertNotificationChannelPost(requestParameters.createNotificationChannelCommand, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMonitorAlertPolicyGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public monitorAlertPolicyGet(requestParameters: DefaultApiMonitorAlertPolicyGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).monitorAlertPolicyGet(requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMonitorAlertPolicyIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public monitorAlertPolicyIdDelete(requestParameters: DefaultApiMonitorAlertPolicyIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).monitorAlertPolicyIdDelete(requestParameters.id, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMonitorAlertPolicyIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public monitorAlertPolicyIdPut(requestParameters: DefaultApiMonitorAlertPolicyIdPutRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).monitorAlertPolicyIdPut(requestParameters.id, requestParameters.updateAlertPolicyCommand, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMonitorAlertPolicyPolicyIdChannelChannelIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public monitorAlertPolicyPolicyIdChannelChannelIdDelete(requestParameters: DefaultApiMonitorAlertPolicyPolicyIdChannelChannelIdDeleteRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).monitorAlertPolicyPolicyIdChannelChannelIdDelete(requestParameters.policyId, requestParameters.channelId, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMonitorAlertPolicyPolicyIdChannelChannelIdPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public monitorAlertPolicyPolicyIdChannelChannelIdPost(requestParameters: DefaultApiMonitorAlertPolicyPolicyIdChannelChannelIdPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).monitorAlertPolicyPolicyIdChannelChannelIdPost(requestParameters.policyId, requestParameters.channelId, requestParameters.createPolicyNotificationCommand, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMonitorAlertPolicyPolicyIdChannelChannelIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public monitorAlertPolicyPolicyIdChannelChannelIdPut(requestParameters: DefaultApiMonitorAlertPolicyPolicyIdChannelChannelIdPutRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).monitorAlertPolicyPolicyIdChannelChannelIdPut(requestParameters.policyId, requestParameters.channelId, requestParameters.updatePolicyNotificationCommand, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMonitorAlertPolicyPolicyIdIncidentGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public monitorAlertPolicyPolicyIdIncidentGet(requestParameters: DefaultApiMonitorAlertPolicyPolicyIdIncidentGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).monitorAlertPolicyPolicyIdIncidentGet(requestParameters.policyId, requestParameters.authorization, requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMonitorAlertPolicyPolicyIdIncidentPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public monitorAlertPolicyPolicyIdIncidentPost(requestParameters: DefaultApiMonitorAlertPolicyPolicyIdIncidentPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).monitorAlertPolicyPolicyIdIncidentPost(requestParameters.policyId, requestParameters.createAlertPolicyIncidentCommand, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMonitorAlertPolicyPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public monitorAlertPolicyPost(requestParameters: DefaultApiMonitorAlertPolicyPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).monitorAlertPolicyPost(requestParameters.createAlertPolicyCommand, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMonitorAlertSyncPolicyPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public monitorAlertSyncPolicyPost(requestParameters: DefaultApiMonitorAlertSyncPolicyPostRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).monitorAlertSyncPolicyPost(requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMonitorEmailsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public monitorEmailsGet(requestParameters: DefaultApiMonitorEmailsGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).monitorEmailsGet(requestParameters.authorization, requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMonitorErpApiErrorsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public monitorErpApiErrorsGet(requestParameters: DefaultApiMonitorErpApiErrorsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).monitorErpApiErrorsGet(requestParameters.dateCommand, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMonitorErpErrorsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public monitorErpErrorsGet(requestParameters: DefaultApiMonitorErpErrorsGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).monitorErpErrorsGet(requestParameters.authorization, requestParameters.query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMonitorLeadLogsValidationErrorsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public monitorLeadLogsValidationErrorsGet(requestParameters: DefaultApiMonitorLeadLogsValidationErrorsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).monitorLeadLogsValidationErrorsGet(requestParameters.dateCommand, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMonitorLeadRequestStatusCodesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public monitorLeadRequestStatusCodesGet(requestParameters: DefaultApiMonitorLeadRequestStatusCodesGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).monitorLeadRequestStatusCodesGet(requestParameters.dateCommand, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMonitorLeadVendorsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public monitorLeadVendorsGet(requestParameters: DefaultApiMonitorLeadVendorsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).monitorLeadVendorsGet(requestParameters.dateCommand, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMonitorSmsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public monitorSmsGet(requestParameters: DefaultApiMonitorSmsGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).monitorSmsGet(requestParameters.getSMSesCommand, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMonitorTasksQueuesWithTasksGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public monitorTasksQueuesWithTasksGet(requestParameters: DefaultApiMonitorTasksQueuesWithTasksGetRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).monitorTasksQueuesWithTasksGet(requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMonitorTasksTaskDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public monitorTasksTaskDelete(requestParameters: DefaultApiMonitorTasksTaskDeleteRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).monitorTasksTaskDelete(requestParameters.taskPathCommand, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiMonitorTasksTaskRunPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public monitorTasksTaskRunPost(requestParameters: DefaultApiMonitorTasksTaskRunPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).monitorTasksTaskRunPost(requestParameters.taskPathCommand, requestParameters.authorization, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiOrderconfirmationOrderOrderidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public orderconfirmationOrderOrderidGet(requestParameters: DefaultApiOrderconfirmationOrderOrderidGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).orderconfirmationOrderOrderidGet(requestParameters.orderid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partnerCreateOrderPost(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partnerCreateOrderPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partnerHealthGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partnerHealthGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiPartnerOrdersPartnerReferenceGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partnerOrdersPartnerReferenceGet(requestParameters: DefaultApiPartnerOrdersPartnerReferenceGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partnerOrdersPartnerReferenceGet(requestParameters.partnerReference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiPartnerWebhooksPartnerReferenceDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partnerWebhooksPartnerReferenceDelete(requestParameters: DefaultApiPartnerWebhooksPartnerReferenceDeleteRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partnerWebhooksPartnerReferenceDelete(requestParameters.partnerReference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiPartnerWebhooksPartnerReferencePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partnerWebhooksPartnerReferencePost(requestParameters: DefaultApiPartnerWebhooksPartnerReferencePostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partnerWebhooksPartnerReferencePost(requestParameters.partnerReference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiPartnerWebhooksPartnerReferencePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public partnerWebhooksPartnerReferencePut(requestParameters: DefaultApiPartnerWebhooksPartnerReferencePutRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).partnerWebhooksPartnerReferencePut(requestParameters.partnerReference, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiPublicCalendarNotificationHandlePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public publicCalendarNotificationHandlePost(requestParameters: DefaultApiPublicCalendarNotificationHandlePostRequest = {}, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).publicCalendarNotificationHandlePost(requestParameters.xGoogResourceState, requestParameters.xGoogChannelExpiration, requestParameters.xGoogChannelToken, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public publicSMSHandleReplyPost(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).publicSMSHandleReplyPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiQuoteOrderOrderidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public quoteOrderOrderidGet(requestParameters: DefaultApiQuoteOrderOrderidGetRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).quoteOrderOrderidGet(requestParameters.orderid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public smsInsertPost(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).smsInsertPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiSmsSendMultipleGuidPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public smsSendMultipleGuidPost(requestParameters: DefaultApiSmsSendMultipleGuidPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).smsSendMultipleGuidPost(requestParameters.guid, requestParameters.test, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiSmsSendSmsIdPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public smsSendSmsIdPost(requestParameters: DefaultApiSmsSendSmsIdPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).smsSendSmsIdPost(requestParameters.smsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public swaggerJsonGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).swaggerJsonGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public syncAllCPProGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).syncAllCPProGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public syncAllGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).syncAllGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public syncCampaignCodeGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).syncCampaignCodeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public syncConstantGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).syncConstantGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public syncCpproGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).syncCpproGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public syncInstallerGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).syncInstallerGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public syncLeadGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).syncLeadGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DefaultApiSyncOrderCpproOrderIdPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public syncOrderCpproOrderIdPost(requestParameters: DefaultApiSyncOrderCpproOrderIdPostRequest, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).syncOrderCpproOrderIdPost(requestParameters.cpproOrderId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public syncOrderGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).syncOrderGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public syncOrdersAllGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).syncOrdersAllGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public syncOrdersAllInstallersGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).syncOrdersAllInstallersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public syncOrdersAllMasterGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).syncOrdersAllMasterGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public syncOrdersAllPartnersGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).syncOrdersAllPartnersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public syncOrdersCompletedGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).syncOrdersCompletedGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public syncOrdersCompletedInstallersGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).syncOrdersCompletedInstallersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public syncOrdersCompletedMasterGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).syncOrdersCompletedMasterGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public syncOrdersCompletedPartnersGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).syncOrdersCompletedPartnersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public syncOrdersInstallersGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).syncOrdersInstallersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public syncOrdersMasterGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).syncOrdersMasterGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public syncOrdersPartnersGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).syncOrdersPartnersGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public syncParameterGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).syncParameterGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public syncPartnerGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).syncPartnerGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public syncProductGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).syncProductGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public syncShareInstallerSheetsGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).syncShareInstallerSheetsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public tasksCheckTasksPost(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).tasksCheckTasksPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public testGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).testGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public testRequestipsGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).testRequestipsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public testerrorGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).testerrorGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public testerrornotifyGet(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).testerrornotifyGet(options).then((request) => request(this.axios, this.basePath));
    }
}



