import type { UseMutationOptions } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { activityhelperApi } from '../apis/apis.js';
import type {
  DefaultApiApiConstantKeyVersionPostRequest, DefaultApiApiConstantKeyVersionVersionDisablePostRequest,
  DefaultApiApiConstantKeyVersionVersionEnablePostRequest, PostConstantRequest,
} from '../../generated/activityhelper/api.js';

export const addConstantMutation: UseMutationOptions<void, AxiosError, PostConstantRequest> = {
  mutationFn: async (data) => {
    await activityhelperApi.apiConstantPost({ postConstantRequest: data });
  },
};

export const addConstantVersionMutation: UseMutationOptions<void, AxiosError, Omit<DefaultApiApiConstantKeyVersionPostRequest, 'authorization'>> = {
  mutationFn: async (data) => {
    await activityhelperApi.apiConstantKeyVersionPost(data);
  },
};

export const disableConstantVersionMutation: UseMutationOptions<void, AxiosError, Omit<DefaultApiApiConstantKeyVersionVersionDisablePostRequest, 'authorization'>> = {
  mutationFn: async (data) => {
    await activityhelperApi.apiConstantKeyVersionVersionDisablePost(data);
  },
};

export const enableConstantVersionMutation: UseMutationOptions<void, AxiosError, Omit<DefaultApiApiConstantKeyVersionVersionEnablePostRequest, 'authorization'>> = {
  mutationFn: async (data) => {
    await activityhelperApi.apiConstantKeyVersionVersionEnablePost(data);
  },
};

export const deleteConstantMutation: UseMutationOptions<void, AxiosError, string> = {
  mutationFn: async (key) => {
    await activityhelperApi.apiConstantKeyDelete({ key });
  },
};

export const deleteConstantVersionMutation: UseMutationOptions<void, AxiosError, { key: string; version: number }> = {
  mutationFn: async (data) => {
    await activityhelperApi.apiConstantKeyVersionVersionDelete({ key: data.key, version: data.version.toString() });
  },
};
