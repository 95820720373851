import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import type { SortingState } from '@tanstack/react-table';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import MonitorPageTitle from '../../monitor/MonitorPageTitle.js';
import { ConstantQueries } from '../../../queries/index.js';
import GenericTable from '../../table/GenericTable.js';
import ConstantAddVersionDialogContent from './ConstantAddVersionDialogContent.js';
import ConstantInfoContent from './ConstantInfoContent.js';
import { ConstantMutations } from '../../../mutations/index.js';
import { mainConstantColumns } from './ConstantTableColumns.js';
import ConstantAddDialog from './ConstantAddDialog.js';

const initialSortingState: SortingState = [{ id: 'key', desc: false }];

const ConstantPage = () => {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const { data } = useQuery(ConstantQueries.constantsQuery);
  const { constants, expectedRecords } = data ?? {};

  const deleteMutation = useMutation({
    ...ConstantMutations.deleteConstantMutation,
    onSuccess: () => {
      queryClient.invalidateQueries(ConstantQueries.constantsQuery);
    },
  });

  return (
    <Box>
      <MonitorPageTitle title="Konstanter" />
      <Button onClick={() => setOpen(true)} variant="contained" color="success" sx={{ marginBottom: 2 }}>Legg til konstant</Button>
      <GenericTable
        id="constants"
        data={constants ?? []}
        columns={mainConstantColumns}
        initialSortingState={initialSortingState}
        editDialogProps={{ title: 'Legg til konstant versjon', maxWidth: 'md', Content: ConstantAddVersionDialogContent }}
        rowDialogProps={{ title: (info) => `Konstant info: ${info.row?.latest.key}`, maxWidth: 'xl', Content: ConstantInfoContent }}
        deleteDialogProps={{
          title: 'Slett konstant, er du sikker? (sletter alle versjoner)',
          onDelete: async (row, close) => {
            if (row) {
              await deleteMutation.mutateAsync(row.latest.key);
              close();
            }
          },
        }}
      />
      <ConstantAddDialog open={open} onClose={() => setOpen(false)} expectedRecords={expectedRecords ?? new Map()} />
    </Box>
  );
};

export default ConstantPage;
