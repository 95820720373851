import type { MutationOptions, UseMutationOptions } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import axios from 'axios';
import type { Customer, Property } from '../../CPpro/model/model.js';
import { UserPropertyType } from '../../CPpro/model/model.js';
import type { AccessTypes } from '../types/index.js';
import type { EditModelProperty } from '../types/cpproTypes.js';

export const addUserRole: UseMutationOptions<unknown, Error, AccessTypes.UserAccessRoleProperty[]> = {
  mutationFn: (roles) => {
    const data: Property[] = roles.map((r) => ({
      id: 0,
      parent_id: r.userId,
      type: UserPropertyType.AccessRole,
      value: `${r.role}:${r.level}`,
    }));
    return axios.post('/userProperties', data);
  },
};

export const deleteUserRole: MutationOptions<unknown, Error, AccessTypes.UserAccessRoleProperty[]> = {
  mutationFn: (roles) => {
    const data: Property[] = roles.map((r) => ({
      id: r.id,
      parent_id: r.userId,
      type: UserPropertyType.AccessRole,
      value: `${r.role}:${r.level}`,
    }));
    return axios.delete('/userProperties', { data });
  },
};

export const updateUserRole: UseMutationOptions<unknown, Error, AccessTypes.UserAccessRoleProperty[]> = {
  mutationFn: (roles) => {
    const data: Property[] = roles.map((r) => ({
      id: r.id,
      parent_id: r.userId,
      type: UserPropertyType.AccessRole,
      value: `${r.role}:${r.level}`,
    }));
    return axios.put('/userProperties', data);
  },
};

export const addCustomerProperties: UseMutationOptions<void, AxiosError, Property[]> = {
  mutationFn: async (data) => {
    await axios.post('/customerProperties', data);
  },
};

export const createCustomerMutation: UseMutationOptions<Customer | undefined, AxiosError, Customer[]> = {
  mutationFn: async (data) => {
    const response = await axios.post<Customer[][]>('/customers', data);
    return response.data.flatMap((c) => c).at(0);
  },
};

export const editCustomerMutation: UseMutationOptions<void, AxiosError, EditModelProperty[]> = {
  mutationFn: async (data) => {
    const createProps = data.filter((d) => d.type === 'create').map((d) => d.prop);
    const updateProps = data.filter((d) => d.type === 'update').map((d) => d.prop);
    const deleteProps = data.filter((d) => d.type === 'delete').map((d) => d.prop);

    if (createProps.length) {
      await axios.post('/customerProperties', createProps);
    }
    if (updateProps.length) {
      await axios.put('/customerProperties', updateProps);
    }
    if (deleteProps.length) {
      await axios.delete('/customerProperties', { data: deleteProps });
    }
  },
};
