import React, { useMemo } from 'react';
import { Box, Grid2 } from '@mui/material';
import type { GenericTableFilterProps } from '../../../table/GenericTable.js';
import type { CustomerContactLog } from '../../../../../CPpro/model/model.js';
import AutocompleteFilter from '../../../table/filters/AutocompleteFilter.js';
import DateRangeFilter from '../../../table/DateRangeFilter.js';
import DebouncedFilter from '../../../table/filters/DebouncedFilter.js';

type ContactLogTableFilterProps = GenericTableFilterProps<CustomerContactLog>;

const ContactLogTableFilter = (props: ContactLogTableFilterProps) => {
  const { table } = props;

  const whenColumn = table.getColumn('when');
  const whatColumn = table.getColumn('what');
  const whoFromColumn = table.getColumn('whoFrom');
  const messageColumn = table.getColumn('message');

  const whoFromUniqueValues = whoFromColumn?.getFacetedUniqueValues();
  const whoFromOptions = useMemo(() => whoFromUniqueValues?.keys().toArray() ?? [], [whoFromUniqueValues]);
  const whatUniqueValues = whatColumn?.getFacetedUniqueValues();
  const whatOptions = useMemo(() => whatUniqueValues?.keys().toArray() ?? [], [whatUniqueValues]);

  return (
    <Box sx={{ padding: 1 }}>
      <Grid2
        container
        spacing={1}
        sx={{
          alignItems: 'center',
        }}
      >
        {whenColumn ? (
          <Grid2>
            <DateRangeFilter column={whenColumn} />
          </Grid2>
        ) : null}
        {whatColumn ? (
          <Grid2>
            <AutocompleteFilter minWidth={100} label="What" column={whatColumn} options={whatOptions} />
          </Grid2>
        ) : null}
        {whoFromColumn ? (
          <Grid2>
            <AutocompleteFilter minWidth={160} label="Who from" column={whoFromColumn} options={whoFromOptions} />
          </Grid2>
        ) : null}
        {messageColumn ? (
          <Grid2>
            <DebouncedFilter minWidth={200} debounce={300} label="Message" column={messageColumn} initialValue="" />
          </Grid2>
        ) : null}
      </Grid2>
    </Box>
  );
};

export default ContactLogTableFilter;
