import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc.js';
import timezone from 'dayjs/plugin/timezone.js';
import customParseFormat from 'dayjs/plugin/customParseFormat.js';
import objectSupport from 'dayjs/plugin/objectSupport.js';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore.js';
import type { MonitorTypes } from '../react/components/monitor/index.js';
import 'dayjs/locale/nb.js';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.extend(objectSupport);
dayjs.extend(isSameOrBefore);

interface DateUtils {
  /**
   * Ex today is 16.01.xxxx outputs 01.12.xxxy, at 00:00
   * @returns
   */
  getDateFirstDayInLastMonth: () => Date;
  /**
   * Subtracts x days from the provided date (or if undefined, today) and sets the time to 00:00
   * @param date
   * @param days
   * @returns
   */
  subtractDateDays: (date?: Date | null, days?: number) => Date;
  subtractDateHours: (date?: Date | null, hours?: number, resetMinutes?: boolean) => Date;
  getDatePickerDate: (date?: Date | null) => string;
  fixDateCommand(dateCommand: MonitorTypes.DateCommand): MonitorTypes.DateCommand;
  getISODate: (date?: Date | string | null) => string | undefined;
  convertToMUI(date?: Date | string | null): string | undefined;
  convertToMUIDate(date?: Date | string | null): string | undefined;
  convertFromMUI(date?: string | null): string | undefined;
  getDate: (date: string | number | Date | null | undefined) => Date | null;
}

export const getDate: DateUtils['getDate'] = (date) => {
  if (!date) {
    return null;
  }
  const d = new Date(date);
  if (Number.isNaN(d.getTime())) {
    return null;
  }
  return d;
};

export const getDateFirstDayInLastMonth: DateUtils['getDateFirstDayInLastMonth'] = () => {
  const date = new Date();
  date.setDate(1);
  date.setMonth(date.getMonth() - 1);
  date.setHours(0, 0, 0, 0);
  return date;
};

export const subtractDateDays: DateUtils['subtractDateDays'] = (date, days) => {
  const d = date ? new Date(date) : new Date();
  if (!days) return d;
  d.setDate(d.getDate() - days);
  d.setHours(0, 0, 0, 0);
  return d;
};

export const subtractDateHours: DateUtils['subtractDateHours'] = (date, hours, resetMinutes) => {
  const d = date ? new Date(date) : new Date();
  if (!hours) return d;
  d.setHours(d.getHours() - hours);
  if (resetMinutes) d.setMinutes(0, 0, 0);
  return d;
};

export const addDateHours = (date: Date, hours: number): Date => {
  const newDate = new Date(date);
  newDate.setHours(newDate.getHours() + hours);
  return newDate;
};

export const addDateDays = (date: Date, days: number): Date => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + days);
  return newDate;
};

export const getLocalizedDateTime = (dateStr: string | Date | undefined | null): string => {
  if (!dateStr) return '';
  const date = new Date(dateStr);
  if (Number.isNaN(date.getTime())) return '';

  return date.toLocaleString('nb-NO', {
    day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit',
  });
};

export const getLocalizedDate = (dateStr: string | Date | number | undefined | null): string => {
  const date = getDate(dateStr);
  if (!date) return '';

  return date.toLocaleDateString('nb-NO', { day: '2-digit', month: '2-digit', year: 'numeric' });
};

export const getLocalizedTime = (dateStr: string): string => {
  const date = new Date(dateStr);
  if (Number.isNaN(date.getTime())) return '';

  return date.toLocaleTimeString('nb-NO', { hour: '2-digit', minute: '2-digit' });
};

export const getDatePickerDate: DateUtils['getDatePickerDate'] = (date) => {
  const d = date || new Date();

  return dayjs(d).format('YYYY-MM-DDTHH:mm');
};

export const compareDates = (a: Date, b: Date) => a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate();

export const getISODate: DateUtils['getISODate'] = (date) => {
  const d = date ? new Date(date) : new Date();
  if (Number.isNaN(d.getTime())) return undefined;
  return d.toISOString();
};

export const fixDateCommand: DateUtils['fixDateCommand'] = (dateCommand) => {
  const { startTime, endTime } = dateCommand;
  return {
    startTime: startTime ? getISODate(startTime) : undefined,
    endTime: endTime ? getISODate(endTime) : undefined,
  };
};

export const convertToMUI: DateUtils['convertToMUI'] = (date) => {
  const d = getDate(date);
  if (!d) return undefined;
  return dayjs(d).format('YYYY-MM-DDTHH:mm');
};

export const convertToMUIDate: DateUtils['convertToMUIDate'] = (date) => {
  const d = getDate(date);
  if (!d) return undefined;
  return dayjs(d).format('YYYY-MM-DD');
};

export const convertFromMUI: DateUtils['convertFromMUI'] = (date) => {
  const d = date ? new Date(date) : new Date();
  if (Number.isNaN(d.getTime())) return undefined;
  return d.toISOString();
};

export const getDayjs = (date: dayjs.ConfigType): dayjs.Dayjs | null => {
  if (!date) return null;

  const d = dayjs(date);
  if (!d.isValid()) return null;

  return d;
};

export const getUTCDayjs = (date: dayjs.ConfigType): dayjs.Dayjs | null => {
  if (!date) return null;

  const d = dayjs.utc(date);
  if (!d.isValid()) return null;

  return d;
};

export const dayjsSort = (a: Dayjs | null, b: Dayjs | null, desc = true) => {
  if (!a && !b) return 0;
  if (!a) return 1;
  if (!b) return -1;

  if (desc) {
    if (b.isBefore(a)) return -1;
    if (a.isAfter(b)) return 1;
    return 0;
  }
  if (a.isBefore(b)) return -1;
  if (b.isAfter(a)) return 1;
  return 0;
};
