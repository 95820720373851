import React from 'react';
import { z } from 'zod';
import { useForm } from '@tanstack/react-form';
import {
  Box, Grid2, IconButton, TextField,
  Tooltip,
} from '@mui/material';
import { Info, Refresh } from '@mui/icons-material';
import type { SortingState } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import LoadingButton from '../../common/buttons/LoadingButton.js';
import type { DialogRowElementProps } from '../../table/GenericTable.js';
import GenericTable from '../../table/GenericTable.js';
import type { GetAreaOpportunitiesResponse, GetAreaOpportunitiesResponseZipScoresInner } from '../../../../generated/cppro/api.js';

type ColumnType = { department: string, areaOpportunities: GetAreaOpportunitiesResponse };
const columnHelper = createColumnHelper<ColumnType>();

const padZip = (a: string) => a.padStart(4, '0');

const columns = [
  columnHelper.accessor((a) => a.department, {
    header: 'Avdeling/butikk',
    id: 'department',
  }),
  columnHelper.accessor((a) => a.areaOpportunities.totalCount, {
    header: 'Antall kunder over gitt score',
    id: 'totalCount',
  }),
  columnHelper.display({
    header: 'Actions',
    id: 'actions',
    cell: (info) => (
      <Tooltip title="Vis oversikt over postnr">
        <IconButton onClick={() => info.table.options.meta?.openRowDialog(info)}>
          <Info color="success" />
        </IconButton>
      </Tooltip>
    ),
  }),
];

const dialogColumnHelper = createColumnHelper<GetAreaOpportunitiesResponseZipScoresInner>();
const dialogColumns = [
  dialogColumnHelper.accessor((a) => a.zip, {
    header: 'Postnr',
    id: 'zip',
    cell: (info) => padZip(info.getValue()),
  }),
  dialogColumnHelper.accessor((a) => a.totalCount, {
    header: 'Antall kunder over gitt score',
    id: 'totalCount',
  }),
];

const initialSortingState: SortingState = [{ id: 'totalCount', desc: true }];
const initialSortingStateDialog: SortingState = [{ id: 'zip', desc: false }];

const AreaOpportunitiesRowDialog = ({ row }: DialogRowElementProps<ColumnType>) => (
  <Box>
    <GenericTable
      id="areaOpportunitiesOnDep"
      data={row?.areaOpportunities.zipScores ?? []}
      columns={dialogColumns}
      initialSortingState={initialSortingStateDialog}
    />
  </Box>
);

const schema = z.object({
  minScore: z.number(),
});

type FormType = { minScore: number };

type Props = {
  data: ColumnType[];
  search: (params: FormType | undefined) => void;
  refetch: () => Promise<void>;
  isLoading: boolean;
};

// TODO There is some state error on first load of this component?!?

const CRMAreaOpportunities = (props: Props) => {
  const {
    data, search, refetch, isLoading,
  } = props;

  const form = useForm<FormType>({
    defaultValues: {
      minScore: 10,
    },
    onSubmit: ({ value }) => {
      console.log('submit?');
      search({ minScore: value.minScore });
    },
    validators: {
      onChange: schema,
    },
  });
  return (
    <Box>
      <Box sx={{ marginBottom: 2 }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            form.handleSubmit();
          }}
        >
          <Grid2 container spacing={1} alignItems="center">
            <Grid2>
              <form.Field name="minScore">
                {(field) => (
                  <TextField
                    type="number"
                    label="Minimum poeng:"
                    value={field.state.value ?? ''}
                    onChange={(e) => field.handleChange(Number(e.target.value))}
                    variant="outlined"
                    size="small"
                  />
                )}
              </form.Field>
            </Grid2>
            <Grid2>
              <form.Subscribe selector={(state) => [state.canSubmit, state.isSubmitting]}>
                {([canSubmit, isSubmitting]) => (
                  <LoadingButton buttonProps={{
                    type: 'submit',
                    loading: isLoading,
                    disabled: !canSubmit || isSubmitting,
                  }}
                  >
                    Søk
                  </LoadingButton>
                )}
              </form.Subscribe>
            </Grid2>
            <Grid2>
              <form.Subscribe selector={(state) => [state.canSubmit, state.isSubmitting]}>
                {([canSubmit, isSubmitting]) => (
                  <IconButton
                    color="success"
                    disabled={!canSubmit || isSubmitting || isLoading}
                    title="Refresh"
                    onClick={refetch}
                  >
                    <Refresh />
                  </IconButton>
                )}
              </form.Subscribe>
            </Grid2>
          </Grid2>
        </form>
      </Box>
      <GenericTable
        id="areaOpportunities"
        size="small"
        columns={columns}
        data={data ?? []}
        initialSortingState={initialSortingState}
        rowDialogProps={{
          Content: AreaOpportunitiesRowDialog,
          title: (info) => `${info.row?.department ?? ''} - Postnr oversikt`,
          rowClickOpen: true,
        }}
      />
    </Box>
  );
};

export default CRMAreaOpportunities;
