import {
  Box, Button, Divider, Grid2, Typography,
} from '@mui/material';
import React from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import dayjs from 'dayjs';
import UploadButton from '../../common/buttons/UploadButton.js';
import { activityhelperApi } from '../../../apis/apis.js';
import type { GetPublicHolidaysResponsePublicHolidaysInner, PostPublicHolidaysRequest } from '../../../../generated/activityhelper/api.js';
import MonitorPageTitle from '../../monitor/MonitorPageTitle.js';
import GenericTable from '../../table/GenericTable.js';

const fileToJson = async (file: File): Promise<unknown> => new Promise((resolve, reject) => {
  const reader = new FileReader();

  reader.onload = (event) => {
    try {
      const json = JSON.parse(event.target?.result as string);
      resolve(json);
    } catch (error) {
      reject(error);
    }
  };

  reader.onerror = (error) => {
    reject(error);
  };

  reader.readAsText(file);
});

const columnHelper = createColumnHelper<GetPublicHolidaysResponsePublicHolidaysInner>();

const columns = [
  columnHelper.accessor((a) => a.name, {
    id: 'name',
    header: 'Navn',
  }),
  columnHelper.accessor((a) => a.day, {
    id: 'day',
    header: 'Dag',
  }),
  columnHelper.accessor((a) => a.date, {
    id: 'date',
    header: 'Dato',
    cell: (i) => dayjs(i.getValue(), 'YYYY-MM-DD').format('DD.MM.YYYY'),
  }),
];

const PublicHolidayPage = () => {
  const [file, setFile] = React.useState<File | null>(null);
  const onFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.item(0)) {
      setFile(event.target.files.item(0));
    }
  };

  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: ['publicHolidays'],
    queryFn: async () => {
      const response = await activityhelperApi.apiDatePublicHolidaysGet({});
      return response.data.publicHolidays ?? [];
    },
  });

  const mutation = useMutation({
    mutationFn: async (data: unknown) => {
      await activityhelperApi.apiDatePublicHolidaysPost({ postPublicHolidaysRequest: data as PostPublicHolidaysRequest });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(query);
    },
  });

  return (
    <Box>
      <MonitorPageTitle title="Hellig- og høytidsdager (upload)" />
      <Typography gutterBottom>
        Fil:
        {' '}
        {file?.name}
      </Typography>
      <Grid2 spacing={2} container sx={{ marginBottom: 2 }}>
        <Grid2>
          <UploadButton onChange={onFileUpload} />
        </Grid2>
        <Grid2>
          <Button variant="outlined" onClick={() => setFile(null)}>Clear</Button>
        </Grid2>
        <Grid2>
          {file ? (
            <Button variant="contained" color="success" onClick={async () => mutation.mutateAsync(await fileToJson(file))}>Upload</Button>
          ) : null}
        </Grid2>
      </Grid2>
      <Divider sx={{ marginBottom: 2 }} />
      <GenericTable id="publicHolidays" columns={columns} data={query.data ?? []} />
    </Box>
  );
};

export default PublicHolidayPage;
