import { Box, TextField } from '@mui/material';
import type { Column } from '@tanstack/react-table';
import React, { useEffect, useState } from 'react';

type DebouncedFilterProps<T> = {
  column: Column<T>,
  label: string,
  minWidth?: number,
  type?: React.HTMLInputTypeAttribute,
  debounce?: number,
  initialValue: string | number,
};

const DebouncedFilter = <T, >(props: DebouncedFilterProps<T>) => {
  const {
    column, label, minWidth, type, debounce,
    initialValue,
  } = props;
  const [value, setValue] = useState<string | number>(initialValue);
  const { setFilterValue } = column;

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setFilterValue(value);
    }, debounce ?? 500);

    return () => clearTimeout(timeout);
  }, [value, debounce, setFilterValue]);

  return (
    <Box>
      <TextField
        type={type ?? 'text'}
        label={label}
        value={value ?? ''}
        onChange={(e) => setValue(e.target.value)}
        fullWidth
        sx={{ minWidth: minWidth ?? 250 }}
      />
    </Box>
  );
};

export default DebouncedFilter;
