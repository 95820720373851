import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import type { GetCallListResponseItemsInner } from '../../generated/cppro/api.js';

interface CRMState {
  isBackdropActive: boolean;
  showBackdrop: () => void;
  closeBackdrop: () => void;
  callListSelectedItems: GetCallListResponseItemsInner[];
  setCallListSelectedItems: (items: GetCallListResponseItemsInner[]) => void;
  routeCustomer: number | string | undefined;
  setRouteCustomer: (id: number | string | undefined) => void;
}

export const useCRMStore = create<CRMState>()(
  devtools(
    (set) => ({
      isBackdropActive: false,
      showBackdrop: () => set({ isBackdropActive: true }),
      closeBackdrop: () => set({ isBackdropActive: false }),
      callListSelectedItems: [],
      setCallListSelectedItems: (items: GetCallListResponseItemsInner[]) => set({ callListSelectedItems: items }),
      routeCustomer: undefined,
      setRouteCustomer: (id: number | undefined) => set({ routeCustomer: id }),
    }),
  ),
);

export default useCRMStore;
