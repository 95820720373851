import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  Autocomplete, Box, Grid2, MenuItem, Select, TextField,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import type { Customer } from '../../../../CPpro/model/model.js';
import { CustomerPropertyType } from '../../../../CPpro/model/model.js';
import { CPproQueries } from '../../../queries/index.js';
import LoadingButton from '../../common/buttons/LoadingButton.js';

// search for: Name, Email, Phone, DeliveryAddress, DeliveryZIP, InvoiceAddress, InvoiceZIP, ERPCustomerId
const searchArray: { property: CustomerPropertyType | '' | 'orderNum', label: string }[] = [
  { property: '', label: 'Fritt søk' },
  { property: CustomerPropertyType.Name, label: 'Navn' },
  { property: 'orderNum', label: 'Ordrenr' },
  { property: CustomerPropertyType.Email, label: 'Epost' },
  { property: CustomerPropertyType.Phone, label: 'Telefon' },
  { property: CustomerPropertyType.DeliveryAddress, label: 'Leveringsadresse' },
  { property: CustomerPropertyType.DeliveryZIP, label: 'Leveringspostnr' },
  { property: CustomerPropertyType.InvoiceAddress, label: 'Faktureringsadresse' },
  { property: CustomerPropertyType.InvoiceZIP, label: 'Faktureringspostnr' },
  { property: CustomerPropertyType.ERPCustomerId, label: '24SO Kunde ID' },
];

const isDev = import.meta.env.MODE === 'development' || import.meta.env.MODE === 'test';

interface CRMCustomersSearchProps {
  onSearch: (search: { value: string, property?: CustomerPropertyType } | undefined) => void;
  loading: boolean;
}

/**
 * @param props
 * @returns
 */
const CRMCustomersList = (props: CRMCustomersSearchProps) => {
  const { onSearch, loading } = props;

  const { customerId } = useParams();
  const { data } = useQuery(CPproQueries.customersQuery);
  const navigate = useNavigate();

  const [selectedProperty, setSelectedProperty] = useState<CustomerPropertyType | '' | 'orderNum'>('');
  const [search, setSearch] = useState<string>('');
  const isSearchNum = useMemo(() => {
    if (selectedProperty !== 'orderNum') return true;
    const num = Number(search);
    return !Number.isNaN(num);
  }, [search, selectedProperty]);

  useEffect(() => {
    setSearch('');
  }, [customerId]);
  const mapped = useMemo(() => {
    if (!data) return [];
    return data.map((c) => ({ ...c, label: `${c.properties?.find((p) => p.type === CustomerPropertyType.Name)?.value} (${c.properties?.find((p) => p.type === CustomerPropertyType.Phone)?.value ?? '?'})` }));
  }, [data]);

  const selectedCustomer = useMemo(() => {
    if (customerId) {
      return mapped?.find((c) => c.id === Number(customerId)) ?? null;
    }
    return null;
  }, [customerId, mapped]);

  const onSelection = useCallback((customer: Customer | null) => {
    if (customer) {
      navigate(`/crm/customer/${customer.id}`);
    } else {
      navigate('/crm');
    }
  }, [navigate]);

  const handleSearch = useCallback(() => {
    let prop = selectedProperty === '' ? undefined : selectedProperty;
    prop = prop === 'orderNum' ? CustomerPropertyType.Name : prop;

    onSearch({ value: search, property: prop });
  }, [selectedProperty, onSearch, search]);

  return (
    <Box sx={{ marginTop: 2, marginBottom: 2 }}>
      <Grid2 container spacing={1} sx={{ alignItems: 'center' }}>
        {isDev ? (
          <Grid2>
            <Autocomplete
              sx={{ width: 400 }}
              options={mapped}
              value={selectedCustomer}
              onChange={(e, v) => onSelection(v as Customer | null)}
              freeSolo
              // eslint-disable-next-line react/jsx-props-no-spreading
              renderInput={(params) => <TextField {...params} label="Finn kontakt (kun i test)" />}
            />
          </Grid2>
        ) : null}
        <Grid2>
          <Select value={selectedProperty} onChange={(e) => setSelectedProperty(e.target.value as CustomerPropertyType | '')} displayEmpty>
            {searchArray.map((s) => <MenuItem key={s.property.toString() || 'empty'} value={s.property}>{s.label}</MenuItem>)}
          </Select>
        </Grid2>
        <Grid2>
          <TextField
            type={selectedProperty === 'orderNum' ? 'number' : 'text'}
            onChange={(e) => setSearch(e.target.value)}
            sx={{ minWidth: 400 }}
            label="Søk etter kontakt"
            error={!isSearchNum}
            helperText={!isSearchNum ? 'Ordrenr må vere nummer' : ''}
            title="Use % and _ for wildcard search"
            value={search}
            onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
          />
        </Grid2>
        <Grid2>
          <LoadingButton
            buttonProps={{
              variant: 'contained',
              color: 'success',
              loading,
              disabled: loading || !search,
              onClick: handleSearch,
            }}
          >
            Søk
          </LoadingButton>
        </Grid2>
        {/* <Grid2>
          <Autocomplete options={mapped} freeSolo initialValue={null} onChange={onDebouncedChange} />
        </Grid2> */}
      </Grid2>
    </Box>
  );
};

export default CRMCustomersList;
