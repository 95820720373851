import React, { useMemo } from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select,
} from '@mui/material';
import { CustomerPropertyType, type Customer } from '../../../../../CPpro/model/model.js';

type Props = {
  customers: Customer[];
  open: boolean;
  onSelect: (customer: Customer | undefined, type: 'selectConfirm' | 'backdrop') => void;
};

const CRMCustomerSearchDialog = (props: Props) => {
  const { customers, open, onSelect } = props;
  const mappedCustomers = useMemo(() => customers.map((c) => {
    const name = c.properties.find((p) => p.type === CustomerPropertyType.Name)?.value.toString() ?? '';
    const phone = c.properties.find((p) => p.type === CustomerPropertyType.Phone)?.value.toString() ?? '';

    return { ...c, label: `${name} (${phone})` };
  }), [customers]);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xs"
      disableEscapeKeyDown
      onClose={() => onSelect(undefined, 'backdrop')}
    >
      <DialogTitle>{mappedCustomers.length ? 'Velg kontakt' : 'Ingen kontakter funnet'}</DialogTitle>
      {mappedCustomers.length ? (
        <DialogContent>
          <Select
            displayEmpty
            value=""
            onChange={(e) => {
              if (e.target.value) {
                const num = Number(e.target.value);
                onSelect(mappedCustomers.find((c) => c.id === num), 'selectConfirm');
              }
            }}
            fullWidth
          >
            <MenuItem key="empty" value="" />
            {mappedCustomers.map((c) => (
              <MenuItem key={c.id} value={c.id}>{c.label}</MenuItem>
            ))}
          </Select>
        </DialogContent>
      ) : (
        <DialogActions>
          <Button
            onClick={() => onSelect(undefined, 'selectConfirm')}
            disabled={!!mappedCustomers.length}
          >
            {mappedCustomers.length ? 'Velg' : 'Ok'}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default CRMCustomerSearchDialog;
