import type { CustomerPropertyType } from '../../CPpro/model/model.js';
import type { GetCallListResponseItemsInner } from '../../generated/cppro/api.js';

export type CustomerInfo = Partial<Record<Exclude<Uncapitalize<keyof typeof CustomerPropertyType>, 'phone'>, string | number | null | undefined>> & {
  phone1?: string;
  phone2?: string;
  id: number,
};

export type CustomerForm = Pick<CustomerInfo, 'name' | 'email'> & {
  name: string;
  phone?: string;
  email?: string;
  street?: string;
  zip: string;
  city?: string;
};

export const addressSelects = ['delivery', 'invoice'] as const;
export type AddressSelect = typeof addressSelects[number];
// const mailSelects = ['Invoice mail', 'Order confirmation', 'Shipping information', 'Leads mail'];
// type MailSelect = typeof mailSelects[number];
export const phoneSelects = ['Phone 1', 'Phone 2'] as const;
export type PhoneSelect = typeof phoneSelects[number];

export interface CallListItem extends GetCallListResponseItemsInner {
  lockInfo: {
    lockedBy: string;
    lockedUntil: string;
    agentOwns: boolean;
  } | undefined,
}
