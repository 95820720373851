export const productBrands = [
  { NOR: 'Fujitsu', ENG: '', POL: '' },
  { NOR: 'Mitsubishi', ENG: '', POL: '' },
  { NOR: 'Daikin', ENG: '', POL: '' },
  { NOR: 'Haier', ENG: '', POL: '' },
  { NOR: 'Panasonic', ENG: '', POL: '' },
  { NOR: 'Sanyo', ENG: '', POL: '' },
  { NOR: 'Fuji', ENG: '', POL: '' },
  { NOR: 'General', ENG: '', POL: '' },
  { NOR: 'LG', ENG: '', POL: '' },
  { NOR: 'Toshiba', ENG: '', POL: '' },
  { NOR: 'Sharp', ENG: '', POL: '' },
  { NOR: 'NEX ', ENG: '', POL: '' },
  { NOR: 'Annet merke', ENG: 'Other brand', POL: '' },
];

export const productModels = [
  { NOR: 'Daikin RXG25 ', ENG: '', POL: '' },
  { NOR: 'Daikin RXG35 ', ENG: '', POL: '' },
  { NOR: 'Daikin RXG50 ', ENG: '', POL: '' },
  { NOR: 'Daikin RXL25 ', ENG: '', POL: '' },
  { NOR: 'Daikin RXL35 ', ENG: '', POL: '' },
  { NOR: 'Daikin RXL50 ', ENG: '', POL: '' },
  { NOR: 'Daikin RXZ25 ', ENG: '', POL: '' },
  { NOR: 'Daikin RXZ35 ', ENG: '', POL: '' },
  { NOR: 'Daikin RXZ50 ', ENG: '', POL: '' },
  { NOR: 'Daikin M RXTP35N', ENG: '', POL: '' },
  { NOR: 'Daikin S RXTM30N', ENG: '', POL: '' },
  { NOR: 'Daikin S RXTM40N', ENG: '', POL: '' },
  { NOR: 'Daikin gulv RXL25M3', ENG: '', POL: '' },
  { NOR: 'Daikin FVXS35F/RXL35M Nordic Gulv', ENG: '', POL: '' },
  { NOR: 'Daikin FVXG 35K Nexura Nordic Gulv', ENG: '', POL: '' },
  { NOR: 'Daikin FTXG/RXLG35M White Emura', ENG: '', POL: '' },
  { NOR: 'Daikin FTXZ/RXZ35N Ururu Sarara', ENG: '', POL: '' },
  { NOR: 'Daikin FTXLS35K/RXLS35M Synergi', ENG: '', POL: '' },
  { NOR: 'Daikin FTXG/RXLG35M Silver Emura', ENG: '', POL: '' },
  { NOR: 'Daikin FVXS25F/RXL25M Nordisk Gulv', ENG: '', POL: '' },
  { NOR: 'Daikin S FTXTM30M/RXTM30N', ENG: '', POL: '' },
  { NOR: 'Daikin M FTXTP35K/RXTP35N', ENG: '', POL: '' },
  { NOR: 'Daikin S FTXTM40M/RXTM40N', ENG: '', POL: '' },
  { NOR: 'Fuji 12LAC ', ENG: '', POL: '' },
  { NOR: 'Fuji 12LCC ', ENG: '', POL: '' },
  { NOR: 'Fuji 12LECAN ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 09LAC', ENG: '', POL: '' },
  { NOR: 'Fujitsu 09LDC ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 09LECAN ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 09LECN ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 09LGC', ENG: '', POL: '' },
  { NOR: 'Fujitsu 09LLCC ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 09LMCA ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 09LMCBN ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 09LMCE ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 09LTCN ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 09LUCB ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 09LVCN ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 09LZCAN ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 09KHCAN', ENG: '', POL: '' },
  { NOR: 'Fujitsu 09KMCBN', ENG: '', POL: '' },
  { NOR: 'Fujitsu 09KMTA', ENG: '', POL: '' },
  { NOR: 'Fujitsu 09KMCC', ENG: '', POL: '' },
  { NOR: 'Fujitsu 09KMCDN', ENG: '', POL: '' },
  { NOR: 'Fujitsu 09KGCA', ENG: '', POL: '' },
  { NOR: 'Fujitsu 09KVCBN', ENG: '', POL: '' },
  { NOR: 'Fujitsu 09LLCE', ENG: '', POL: '' },
  { NOR: 'Fujitsu 090LALBH', ENG: '', POL: '' },
  { NOR: 'Fujitsu 12LAC ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 12LALL ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 12LCC ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 12LDC ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 12LEC ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 12LECA ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 12LECN ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 12LGC ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 12LLCC ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 12LMCA ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 12LMCB ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 12LTC ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 12LTCN ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 12LUC ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 12LVCA ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 12LVCN ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 12LZCAN ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 12KHCAN', ENG: '', POL: '' },
  { NOR: 'Fujitsu 12KMCBN', ENG: '', POL: '' },
  { NOR: 'Fujitsu 12KMCC', ENG: '', POL: '' },
  { NOR: 'Fujitsu 12KMCDN', ENG: '', POL: '' },
  { NOR: 'Fujtsu 12KMTA', ENG: '', POL: '' },
  { NOR: 'Fujitsu 12KGCA', ENG: '', POL: '' },
  { NOR: 'Fujitsu 12KVCBN', ENG: '', POL: '' },
  { NOR: 'Fujitsu 12LLCE', ENG: '', POL: '' },
  { NOR: 'Fujitsu 12LMCE', ENG: '', POL: '' },
  { NOR: 'Fujitsu 144LALH ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 14LAC2 ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 14LAL ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 14LBC ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 14LEC', ENG: '', POL: '' },
  { NOR: 'Fujitsu 14LGC ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 14LMCA ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 14LMCB ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 14LTCN ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 14LUC ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 14LVCN ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 14LZCAN ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 14KHCAN', ENG: '', POL: '' },
  { NOR: 'Fujitsu 14KBTA2', ENG: '', POL: '' },
  { NOR: 'Fujitsu 14KMCBN', ENG: '', POL: '' },
  { NOR: 'Fujitsu 14LMCE', ENG: '', POL: '' },
  { NOR: 'Fujitsu 18LAC2 ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 18LALL ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 18LAT3 ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 18LATT ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 18LBL', ENG: '', POL: '' },
  { NOR: 'Fujitsu 18LFC ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 18KMTA', ENG: '', POL: '' },
  { NOR: 'Fujitsu 18KBTA2', ENG: '', POL: '' },
  { NOR: 'Fujitsu 18KBTB', ENG: '', POL: '' },
  { NOR: 'Fujitsu 18LBCB', ENG: '', POL: '' },
  { NOR: 'Fujitsu 24LALA ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 24LAT3 ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 24LBT ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 24LFCC ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 24LFL ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 24KBTB', ENG: '', POL: '' },
  { NOR: 'Fujitsu 24KMTA', ENG: '', POL: '' },
  { NOR: 'Fujitsu 24LBCB', ENG: '', POL: '' },
  { NOR: 'Fujitsu 30LAT4 ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 30LCT', ENG: '', POL: '' },
  { NOR: 'Fujitsu 30LETL ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 30LFT ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 30KBTB', ENG: '', POL: '' },
  { NOR: 'Fujitsu 30LMTA', ENG: '', POL: '' },
  { NOR: 'Fujitsu 35LBLA5', ENG: '', POL: '' },
  { NOR: 'Fujitsu 36LATT ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 36LETL', ENG: '', POL: '' },
  { NOR: 'Fujitsu 36KBTB', ENG: '', POL: '' },
  { NOR: 'Fujitsu 36LBLA5', ENG: '', POL: '' },
  { NOR: 'Fujitsu 36LMTA', ENG: '', POL: '' },
  { NOR: 'Fujitsu 36LBTB', ENG: '', POL: '' },
  { NOR: 'Fujitsu 45LETL ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 45KBTB', ENG: '', POL: '' },
  { NOR: 'Fujitsu 45LBLA6', ENG: '', POL: '' },
  { NOR: 'Fujitsu 45LATT', ENG: '', POL: '' },
  { NOR: 'Fujitsu 45LBT8', ENG: '', POL: '' },
  { NOR: 'Fujitsu 45LBTB', ENG: '', POL: '' },
  { NOR: 'Fujitsu 54LATT ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 54LETL ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 54LJBYL ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 54KBTB', ENG: '', POL: '' },
  { NOR: 'Fujitsu 54LBTB', ENG: '', POL: '' },
  { NOR: 'Fujitsu 60LATT', ENG: '', POL: '' },
  { NOR: 'Fujitsu 72LALT ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 72LRLA', ENG: '', POL: '' },
  { NOR: 'Fujitsu 90LALT ', ENG: '', POL: '' },
  { NOR: 'Fujitsu 90LRLA', ENG: '', POL: '' },
  { NOR: 'General 12LDC', ENG: '', POL: '' },
  { NOR: 'Haier Crystal', ENG: '', POL: '' },
  { NOR: 'LG (C12AWU) ', ENG: '', POL: '' },
  { NOR: 'LG (C12AWUUF2) ', ENG: '', POL: '' },
  { NOR: 'LG Prestige P09MN ', ENG: '', POL: '' },
  { NOR: 'LG Prestige P12MIN', ENG: '', POL: '' },
  { NOR: 'Mitsubishi 2B52 multi', ENG: '', POL: '' },
  { NOR: 'Mitsubishi 2D53 multi ', ENG: '', POL: '' },
  { NOR: 'Mitsubishi 3A54 multi ', ENG: '', POL: '' },
  { NOR: 'Mitsubishi 3C68 multi ', ENG: '', POL: '' },
  { NOR: 'Mitsubishi 3D54 multi', ENG: '', POL: '' },
  { NOR: 'Mitsubishi 4D72 multi ', ENG: '', POL: '' },
  { NOR: 'Mitsubishi 2F53 multi', ENG: '', POL: '' },
  { NOR: 'Mitsubishi AP25', ENG: '', POL: '' },
  { NOR: 'Mitsubishi AP35', ENG: '', POL: '' },
  { NOR: 'Mitsubishi EF35', ENG: '', POL: '' },
  { NOR: 'Mitsubishi FA25', ENG: '', POL: '' },
  { NOR: 'Mitsubishi FA35', ENG: '', POL: '' },
  { NOR: 'Mitsubishi FD25', ENG: '', POL: '' },
  { NOR: 'Mitsubishi FD35', ENG: '', POL: '' },
  { NOR: 'Mitsubishi FD50', ENG: '', POL: '' },
  { NOR: 'Mitsubishi FH25', ENG: '', POL: '' },
  { NOR: 'Mitsubishi FH35', ENG: '', POL: '' },
  { NOR: 'Mitsubishi FH50', ENG: '', POL: '' },
  { NOR: 'Mitsubishi GA25', ENG: '', POL: '' },
  { NOR: 'Mitsubishi GA35', ENG: '', POL: '' },
  { NOR: 'Mitsubishi GA60', ENG: '', POL: '' },
  { NOR: 'Mitsubishi GA71', ENG: '', POL: '' },
  { NOR: 'Mitsubishi GB50', ENG: '', POL: '' },
  { NOR: 'Mitsubishi GE25', ENG: '', POL: '' },
  { NOR: 'Mitsubishi GE35', ENG: '', POL: '' },
  { NOR: 'Mitsubishi GE71', ENG: '', POL: '' },
  { NOR: 'Mitsubishi KA25', ENG: '', POL: '' },
  { NOR: 'Mitsubishi KA35', ENG: '', POL: '' },
  { NOR: 'Mitsubishi KA50', ENG: '', POL: '' },
  { NOR: 'Mitsubishi KJ25', ENG: '', POL: '' },
  { NOR: 'Mitsubishi KJ35', ENG: '', POL: '' },
  { NOR: 'Mitsubishi LN25', ENG: '', POL: '' },
  { NOR: 'Mitsubishi LN35', ENG: '', POL: '' },
  { NOR: 'Mitsubishi LN50', ENG: '', POL: '' },
  { NOR: 'Mitsubishi SF25', ENG: '', POL: '' },
  { NOR: 'Mitsubishi SLZKA35VA Takkassett', ENG: '', POL: '' },
  { NOR: 'Mitsubishi SF35', ENG: '', POL: '' },
  { NOR: 'Mitsubishi VL50', ENG: '', POL: '' },
  { NOR: 'Mitsubishi VL100', ENG: '', POL: '' },
  { NOR: 'Nex 18HRDN1', ENG: '', POL: '' },
  { NOR: 'NEX 24ARN1', ENG: '', POL: '' },
  { NOR: 'NEX 24HRDN', ENG: '', POL: '' },
  { NOR: 'Nex 27HRDN1 ', ENG: '', POL: '' },
  { NOR: 'NEX 48ARN1', ENG: '', POL: '' },
  { NOR: 'NEX 48Di3N 230V, 1fase', ENG: '', POL: '' },
  { NOR: 'NEX 48Di3N3 ', ENG: '', POL: '' },
  { NOR: 'Nex S12', ENG: '', POL: '' },
  { NOR: 'Nex S12 ', ENG: '', POL: '' },
  { NOR: 'Nex S9i2N ', ENG: '', POL: '' },
  { NOR: 'Panasonic HZ25', ENG: '', POL: '' },
  { NOR: 'Panasonic HZ35', ENG: '', POL: '' },
  { NOR: 'Panasonic CE09 ', ENG: '', POL: '' },
  { NOR: 'Panasonic CE12 ', ENG: '', POL: '' },
  { NOR: 'Panasonic E12', ENG: '', POL: '' },
  { NOR: 'Panasonic E12 ', ENG: '', POL: '' },
  { NOR: 'Panasonic E18 ', ENG: '', POL: '' },
  { NOR: 'Panasonic E9', ENG: '', POL: '' },
  { NOR: 'Panasonic E9 ', ENG: '', POL: '' },
  { NOR: 'Panasonic HE09 ', ENG: '', POL: '' },
  { NOR: 'Panasonic HE12 ', ENG: '', POL: '' },
  { NOR: 'Panasonic HZ09', ENG: '', POL: '' },
  { NOR: 'Panasonic HZ12', ENG: '', POL: '' },
  { NOR: 'Panasonic NE09 ', ENG: '', POL: '' },
  { NOR: 'Panasonic NE12 ', ENG: '', POL: '' },
  { NOR: 'Panasonic NZ09', ENG: '', POL: '' },
  { NOR: 'Panasonic NZ12 ', ENG: '', POL: '' },
  { NOR: 'Panasonic QE12', ENG: '', POL: '' },
  { NOR: 'Panasonic QE9', ENG: '', POL: '' },
  { NOR: 'Panasonic RE12 ', ENG: '', POL: '' },
  { NOR: 'Panasonic RE9 ', ENG: '', POL: '' },
  { NOR: 'Panasonic TE12 ', ENG: '', POL: '' },
  { NOR: 'Panasonic TE9', ENG: '', POL: '' },
  { NOR: 'Panasonic VE12', ENG: '', POL: '' },
  { NOR: 'Panasonic VZ9', ENG: '', POL: '' },
  { NOR: 'Panasonic XE12 ', ENG: '', POL: '' },
  { NOR: 'Panasonic XE9 ', ENG: '', POL: '' },
  { NOR: 'Panasonic CU-Z25UFEA-1 Gulv', ENG: '', POL: '' },
  { NOR: 'Panasonic CU-Z35UFEA-1 Gulv', ENG: '', POL: '' },
  { NOR: 'Panasonic CU-CZ25', ENG: '', POL: '' },
  { NOR: 'Panasonic CU-CZ35', ENG: '', POL: '' },
  { NOR: 'Panasonic CU-HZ25', ENG: '', POL: '' },
  { NOR: 'Panasonic CU-HZ35', ENG: '', POL: '' },
  { NOR: 'Panasonic CU-LZ35', ENG: '', POL: '' },
  { NOR: 'Panasonic CU-NZ25', ENG: '', POL: '' },
  { NOR: 'Panasonic CU-NZ35', ENG: '', POL: '' },
  { NOR: 'Panasonic CU-CZ9', ENG: '', POL: '' },
  { NOR: 'Panasonic CU-CZ12', ENG: '', POL: '' },
  { NOR: 'Samsung AR09', ENG: '', POL: '' },
  { NOR: 'Samsung AR12', ENG: '', POL: '' },
  { NOR: 'Sharp AEX 12 ', ENG: '', POL: '' },
  { NOR: 'Sharp AEX 9', ENG: '', POL: '' },
  { NOR: 'Toshiba RAS B10', ENG: '', POL: '' },
  { NOR: 'Toshiba RAS B13', ENG: '', POL: '' },
  { NOR: 'Toshiba RAS-10', ENG: '', POL: '' },
  { NOR: 'Toshiba RAS-13', ENG: '', POL: '' },
  { NOR: 'Toshiba RAS-16', ENG: '', POL: '' },
  { NOR: 'Toshiba RAS-25', ENG: '', POL: '' },
  { NOR: 'Toshiba RAS-35', ENG: '', POL: '' },
  { NOR: 'Toshiba RAS25PAVPG-ND', ENG: '', POL: '' },
  { NOR: 'Toshiba RAS35PAVPG-ND', ENG: '', POL: '' },
  { NOR: 'Annen modell', ENG: 'Other model', POL: '' },
];
