import {
  Divider, Typography, Box,
  Grid2,
  TextField,
  Alert,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { PlayArrow } from '@mui/icons-material';
import { useMutation } from '@tanstack/react-query';
import LoadingButton from '../../common/buttons/LoadingButton.js';
import { useCPproUserContext } from '../../../contexts/CpproUserContext.js';
import { ERPMutations } from '../../../mutations/index.js';

const MonitorCalendarPage = () => {
  const { user } = useCPproUserContext();
  const [calendarId, setCalendarId] = useState<string>(user?.isUserAdmin ? '' : user?.email ?? '');

  useEffect(() => {
    if (user?.email) {
      setCalendarId(user.isUserAdmin ? '' : user.email ?? '');
    }
  }, [user?.email, user?.isUserAdmin]);

  const calendarMutation = useMutation({
    ...ERPMutations.createNotificationChannelMutation,
    onSuccess: () => {
      setCalendarId('');
    },
  });

  return (
    <Box>
      <Typography variant="h4" gutterBottom>Calendar</Typography>
      <Divider sx={{ mb: 2 }} />
      <Box>
        <Typography gutterBottom variant="h6">Opprett kalender-overvåking:</Typography>
        <Typography>
          Husk å dele kalender med denne eposten:
          <br />
          <br />
          <code>{import.meta.env.VITE_ACTIVITYHELPER_CALENDAR_EMAIL ?? 'activityhelper-node@cppro-316116.iam.gserviceaccount.com'}</code>
          <br />
          <br />
          Kalender må vere din epost med mindre du har
          {' '}
          <code>USERADMIN</code>
          {' '}
          tilgang i CPPro (du har tilgang til User Editor).
        </Typography>
        <Grid2
          container
          spacing={2}
          sx={{
            alignItems: 'center',
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          <Grid2>
            <TextField
              label="Kalender-ID / epost"
              title="Kalender-ID / epost"
              value={calendarId}
              onChange={(e) => setCalendarId(e.target.value)}
            />
          </Grid2>
          <Grid2>
            <LoadingButton
              customIcon={<PlayArrow />}
              buttonProps={{
                onClick: () => calendarMutation.mutateAsync({ calendarId }),
                variant: 'contained',
                loading: calendarMutation.isPending,
                disabled: calendarMutation.isPending,
              }}
            >
              Overvåk
            </LoadingButton>

          </Grid2>

        </Grid2>
        {calendarMutation.isSuccess ? (
          <Alert severity="success">Kalender blir overvåkt</Alert>
        ) : null}
        {calendarMutation.isError ? (
          <Alert severity="error">
            {JSON.stringify(calendarMutation.error.response?.data ?? { message: calendarMutation.error.message }, undefined, 2)}
          </Alert>
        ) : null}
      </Box>
    </Box>
  );
};

export default MonitorCalendarPage;
