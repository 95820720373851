import {
  Box, Button, Grid2, MenuItem, Select, Stack, TextField, Typography,
} from '@mui/material';
import React, {
  useCallback, useMemo, useState,
} from 'react';
import {
  Add, Check, Edit, EditNote, Remove,
} from '@mui/icons-material';
import { useForm } from '@tanstack/react-form';
import { z } from 'zod';
import { useNavigate } from 'react-router';
import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import type { CustomerWithData, GetCallListResponseItemsInner } from '../../../../generated/cppro/api.js';
import type { Property, Customer, CustomerContactLog } from '../../../../CPpro/model/model.js';
import { CustomerPropertyType } from '../../../../CPpro/model/model.js';
import { removeNulls } from '../../../../CPpro/Utils/tools.js';
import LoadingButton from '../../common/buttons/LoadingButton.js';
import { CPproMutations, CRMMutations } from '../../../mutations/index.js';
import { useCPproUserContext } from '../../../contexts/CpproUserContext.js';
import type { UserData } from '../../../providers/CpproUserProvider.js';
import {
  capitalize,
  getProperties, getProperty, getPropertyValue, mapInfoToForm, mapToNewCustomer,
} from '../../../utils/crmUtils.js';
import type { EditModelProperty } from '../../../types/cpproTypes.js';
import type {
  CustomerForm, CustomerInfo, AddressSelect, PhoneSelect,
} from '../../../types/crmTypes.js';
import { addressSelects, phoneSelects } from '../../../types/crmTypes.js';
import useCRMStore from '../../../stores/crmStore.js';

type CustomerInfoProps = {
  customer: CustomerWithData | undefined;
  onSearch: (search: { value: string, property?: CustomerPropertyType } | undefined) => void;
  refetchCustomer: () => Promise<void> | undefined;
  selectedCalllistItems: GetCallListResponseItemsInner[] | undefined;
};

type Mode = 'search' | 'edit' | 'new' | 'view';

const defaultFormValues: CustomerInfo = {
  id: 0,
};

const customerFormSchema = z.object({
  name: z.string().nonempty({ message: 'Name should not be empty' }),
  zip: z.string().regex(/^\d{4}$/, { message: 'Zip should not be empty' }),
  email: z.string().optional(),
  street: z.string().optional(),
  city: z.string().optional(),
  phone: z.string().optional(),
} satisfies Record<keyof Pick<CustomerInfo, 'name' | 'email'> | 'phone' | 'zip' | 'street' | 'city', unknown>);

const getModeText = (mode: Mode) => {
  switch (mode) {
    case 'edit': return ': Edit contact information';
    case 'new': return ': Create new contact';
    case 'search': return ': Search for contact or order';
    default: return '';
  }
};

const compareCustomer = (data: {
  customer: CustomerWithData,
  formData: CustomerForm,
  selectedAddress: AddressSelect,
  selectedPhone: PhoneSelect,
}): EditModelProperty[] => {
  // If any field is different (not empty/null/missing) -> update
  // If any field is now empty/null/missing but had content -> delete
  // If any field is not empty/null/missing but was empty/null/missing -> create
  const {
    customer, formData, selectedAddress, selectedPhone,
  } = data;

  const updateProps: Property[] = [];
  const createProps: Property[] = [];
  const deleteProps: Property[] = [];

  // name
  const nameProp = getProperty(customer, CustomerPropertyType.Name);
  const name = getPropertyValue(customer, CustomerPropertyType.Name)?.toString();
  if (nameProp && name !== formData.name) {
    if (!formData.name) {
      deleteProps.push({
        id: nameProp.id, parent_id: nameProp.parent_id, type: nameProp.PropertyType_id, value: name ?? '',
      });
    } else if (name === undefined) {
      createProps.push({
        id: 0, parent_id: customer.id, type: CustomerPropertyType.Name, value: formData.name,
      });
    } else {
      updateProps.push({
        id: nameProp.id, parent_id: nameProp.parent_id, type: nameProp.PropertyType_id, value: formData.name,
      });
    }
  } else if (!nameProp && formData.name) {
    createProps.push({
      id: 0, parent_id: customer.id, type: CustomerPropertyType.Name, value: formData.name,
    });
  }
  // email
  const emailProp = getProperty(customer, CustomerPropertyType.Email);
  const email = getPropertyValue(customer, CustomerPropertyType.Email)?.toString();
  if (emailProp && email !== formData.email) {
    if (!formData.email) {
      deleteProps.push({
        id: emailProp.id, parent_id: emailProp.parent_id, type: emailProp.PropertyType_id, value: email ?? '',
      });
    } else if (email === undefined) {
      createProps.push({
        id: 0, parent_id: customer.id, type: CustomerPropertyType.Email, value: formData.email,
      });
    } else {
      updateProps.push({
        id: emailProp.id, parent_id: emailProp.parent_id, type: emailProp.PropertyType_id, value: formData.email,
      });
    }
  } else if (!emailProp && formData.email) {
    createProps.push({
      id: 0, parent_id: customer.id, type: CustomerPropertyType.Email, value: formData.email,
    });
  }
  // phone
  const phoneProps = getProperties(customer, CustomerPropertyType.Phone);
  const phoneProp = phoneProps.at(selectedPhone === 'Phone 2' ? 1 : 0) ?? phoneProps.at(0);
  const phone = getPropertyValue(customer, CustomerPropertyType.Phone)?.toString();
  if (phoneProp && phone !== formData.phone) {
    if (!formData.phone) {
      deleteProps.push({
        id: phoneProp.id, parent_id: phoneProp.parent_id, type: phoneProp.PropertyType_id, value: phone ?? '',
      });
    } else if (phone === undefined) {
      createProps.push({
        id: 0, parent_id: customer.id, type: CustomerPropertyType.Phone, value: formData.phone,
      });
    } else {
      updateProps.push({
        id: phoneProp.id, parent_id: phoneProp.parent_id, type: phoneProp.PropertyType_id, value: formData.phone,
      });
    }
  } else if (!phoneProp && formData.phone) {
    createProps.push({
      id: 0, parent_id: customer.id, type: CustomerPropertyType.Phone, value: formData.phone,
    });
  }
  // zip
  const zipProp = getProperty(customer, CustomerPropertyType[`${capitalize(selectedAddress)}ZIP`]);
  const zip = getPropertyValue(customer, CustomerPropertyType[`${capitalize(selectedAddress)}ZIP`])?.toString();
  if (zipProp && zip !== formData.zip) {
    if (!formData.zip) {
      deleteProps.push({
        id: zipProp.id, parent_id: zipProp.parent_id, type: zipProp.PropertyType_id, value: zip ?? '',
      });
    } else if (zip === undefined) {
      createProps.push({
        id: 0, parent_id: customer.id, type: CustomerPropertyType[`${capitalize(selectedAddress)}ZIP`], value: formData.zip,
      });
    } else {
      updateProps.push({
        id: zipProp.id, parent_id: zipProp.parent_id, type: zipProp.PropertyType_id, value: formData.zip,
      });
    }
  } else if (!zipProp && formData.zip) {
    createProps.push({
      id: 0, parent_id: customer.id, type: CustomerPropertyType[`${capitalize(selectedAddress)}ZIP`], value: formData.zip,
    });
  }
  // street
  const streetProp = getProperty(customer, CustomerPropertyType[`${capitalize(selectedAddress)}Address`]);
  const street = getPropertyValue(customer, CustomerPropertyType[`${capitalize(selectedAddress)}Address`])?.toString();
  if (streetProp && street !== formData.street) {
    if (!formData.street) {
      deleteProps.push({
        id: streetProp.id, parent_id: streetProp.parent_id, type: streetProp.PropertyType_id, value: street ?? '',
      });
    } else if (street === undefined) {
      createProps.push({
        id: 0, parent_id: customer.id, type: CustomerPropertyType[`${capitalize(selectedAddress)}Address`], value: formData.street,
      });
    } else {
      updateProps.push({
        id: streetProp.id, parent_id: streetProp.parent_id, type: streetProp.PropertyType_id, value: formData.street,
      });
    }
  } else if (!streetProp && formData.street) {
    createProps.push({
      id: 0, parent_id: customer.id, type: CustomerPropertyType[`${capitalize(selectedAddress)}Address`], value: formData.street,
    });
  }
  // city
  const cityProp = getProperty(customer, CustomerPropertyType[`${capitalize(selectedAddress)}City`]);
  const city = getPropertyValue(customer, CustomerPropertyType[`${capitalize(selectedAddress)}City`])?.toString();
  if (cityProp && city !== formData.city) {
    if (!formData.city) {
      deleteProps.push({
        id: cityProp.id, parent_id: cityProp.parent_id, type: cityProp.PropertyType_id, value: city ?? '',
      });
    } else if (city === undefined) {
      createProps.push({
        id: 0, parent_id: customer.id, type: CustomerPropertyType[`${capitalize(selectedAddress)}City`], value: formData.city,
      });
    } else {
      updateProps.push({
        id: cityProp.id, parent_id: cityProp.parent_id, type: cityProp.PropertyType_id, value: formData.city,
      });
    }
  } else if (!cityProp && formData.city) {
    createProps.push({
      id: 0, parent_id: customer.id, type: CustomerPropertyType[`${capitalize(selectedAddress)}City`], value: formData.city,
    });
  }

  return createProps.map((p) => ({ type: 'create', prop: p } as EditModelProperty))
    .concat(updateProps.map((p) => ({ type: 'update', prop: p } as EditModelProperty)))
    .concat(deleteProps.map((p) => ({ type: 'delete', prop: p } as EditModelProperty)));
};

/**
 * When double-clicking: every field is enabled (not just one) + "we are in search-mode + edit-mode" (yellow background)
 * When pressing the new contact, fields are emptied and "we are in edit-mode" (orange background)
 * When pressing edit, we are in edit-mode (orange background)
 */

/**
 * TODO Missing:
 * - "Start creating new contact" / "save new contact" / "stop saving new contact" buttons in the header (after pressing the "plus")
 * - Editing contact ? / the 4 buttons under the customer info
 * - Use a form for the customer info to get create/edit to work more easily
 * - Differenciate between search and edits? (ex. phone needs to be editable on create/edit, but blur should NOT trigger a search)
 *
 * - clipboard copy when clicking the "header per prop"
 * - ADD more mails/phones/addresses to match what is in the original data
 * @param props
 * @returns
 */

const CRMCustomerInfo = (props: CustomerInfoProps) => {
  const {
    customer, onSearch, refetchCustomer, selectedCalllistItems,
  } = props;

  const { user } = useCPproUserContext();

  const showBackdrop = useCRMStore((state) => state.showBackdrop);
  const closeBackdrop = useCRMStore((state) => state.closeBackdrop);

  const navigate = useNavigate();
  const [savedCustomerId, setSavedCustomerId] = React.useState<number | undefined>(undefined);
  const [mode, setMode] = useState<Mode>('view');
  const [selectedAddress, setSelectedAddress] = useState<AddressSelect>('delivery');
  const [selectedPhone, setSelectedPhone] = useState<PhoneSelect>('Phone 1');
  const [fieldToFocus, setFieldToFocus] = useState<keyof CustomerForm | undefined>(undefined);
  const [contactTextArea, setContactTextArea] = useState<string>('');

  const customerInfo = useMemo<CustomerInfo | undefined>(() => {
    if (!customer) return undefined;

    const phoneProps = getProperties(customer, CustomerPropertyType.Phone).map((p) => p.strvalue ?? p.numvalue).filter(removeNulls);

    return {
      id: customer.id,
      name: getProperty(customer, CustomerPropertyType.Name)?.strvalue,
      deliveryAddress: getProperty(customer, CustomerPropertyType.DeliveryAddress)?.strvalue,
      deliveryCity: getProperty(customer, CustomerPropertyType.DeliveryCity)?.strvalue,
      deliveryZIP: getProperty(customer, CustomerPropertyType.DeliveryZIP)?.numvalue?.toString()?.padStart(4, '0'),
      email: getProperty(customer, CustomerPropertyType.Email)?.strvalue,
      invoiceAddress: getProperty(customer, CustomerPropertyType.InvoiceAddress)?.strvalue,
      invoiceCity: getProperty(customer, CustomerPropertyType.InvoiceCity)?.strvalue,
      invoiceZIP: getProperty(customer, CustomerPropertyType.InvoiceZIP)?.numvalue?.toString()?.padStart(4, '0'),
      serviceInterval: getProperty(customer, CustomerPropertyType.ServiceInterval)?.numvalue,
      // phone: phoneProps.at(0),
      phone1: phoneProps.at(0)?.toString(),
      phone2: phoneProps.at(1)?.toString(),
      // phones: phoneProps,
      serviceStatus: getProperty(customer, CustomerPropertyType.ServiceStatus)?.strvalue,
      // editingStates: structuredClone(defaultEditingStates),
      // selectedMail: 'invoiceMail',
    } satisfies CustomerInfo;
  }, [customer]);
  // const {
  //   name, email, phones, deliveryAddress, deliveryCity, deliveryZIP,
  //   invoiceAddress, invoiceCity, invoiceZIP,
  // } = customerInfo;

  /// Mutations
  const { mutateAsync: mutateCreateCustomer } = useMutation({
    ...CPproMutations.createCustomerMutation,
    onSuccess: (data: Customer | undefined) => {
      if (data) {
        navigate(`/crm/customer/${data.id}`);
      }
      setMode('view');
      setSavedCustomerId(undefined);
    },
  });
  const { mutateAsync: mutateEditCustomer } = useMutation({
    ...CPproMutations.editCustomerMutation,
    onSuccess: async () => {
      await refetchCustomer();
      setMode('view');
    },
  });

  const { mutateAsync: mutateCreateSalesOrder, isPending: isPendingCreateSalesOrder } = useMutation({
    ...CRMMutations.createSaleMutation,
    onMutate: () => {
      showBackdrop();
    },
    onSettled: () => {
      closeBackdrop();
    },
  });
  const { mutateAsync: mutateSendSMS, isPending: isPendingSendSMS } = useMutation({
    ...CRMMutations.sendSMSMutation,
    onMutate: () => {
      showBackdrop();
    },
    onSuccess: () => {
      setContactTextArea('');
    },
    onSettled: () => {
      closeBackdrop();
    },
  });
  const { mutateAsync: mutateAddCustomerProps, isPending: isPendingAddCustomerProps } = useMutation({
    ...CPproMutations.addCustomerProperties,
    onSuccess: async () => {
      await refetchCustomer();
      setContactTextArea('');
    },
  });

  const form = useForm<CustomerForm>({
    // defaultValues: defaultFormValues,
    onSubmit: async ({ value }) => {
      if (mode === 'edit' && customer) {
        // Compare and save changes!
        const compareResult = compareCustomer({
          customer, formData: value, selectedAddress, selectedPhone,
        });
        await mutateEditCustomer(compareResult);
      } else if (mode === 'new') {
        // Also navigate to new customer if created
        const newCustomer = mapToNewCustomer({ formData: value, selectedAddress, selectedPhone });
        await mutateCreateCustomer([newCustomer]);
      }
      console.log('onsubmit', value);
    },
    validators: {
      onSubmit: customerFormSchema,
    },
  });
  const {
    reset, setFieldValue, handleSubmit, getFieldValue,
  } = form;

  ///
  /// Form store / memoized values
  ///

  const showForm = mode !== 'view';
  const modeText = useMemo(() => getModeText(mode), [mode]);
  const backgroundColor = useMemo(() => {
    switch (mode) {
      case 'search': return 'yellow';
      case 'edit':
      case 'new': return 'orange';
      default: return undefined;
    }
  }, [mode]);

  ///
  /// Handlers and callbacks
  ///

  /**
   * Will not do anything if mode is not search
   */
  const handleBlur = useCallback((value: string, property: CustomerPropertyType) => {
    if (mode === 'search') {
      console.log('on blur', value);
      onSearch({ value, property });
      setMode('view');
    } else if (mode === 'edit') {
      handleSubmit();
    }
  }, [handleSubmit, mode, onSearch]);

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLDivElement>, value: string, property: CustomerPropertyType) => {
    if (event.key === 'Enter') {
      handleBlur(value, property);
    }
  }, [handleBlur]);

  const handleNewContact = useCallback(() => {
    setMode('new');
    if (customerInfo) {
      reset(mapInfoToForm(defaultFormValues, { selectedAddress, selectedPhone }));
      setSavedCustomerId(customerInfo.id);
      navigate('/crm');
    }
  }, [customerInfo, navigate, reset, selectedAddress, selectedPhone]);

  const handleCancelNewContact = useCallback(() => {
    setMode('view');
    reset(mapInfoToForm(defaultFormValues, { selectedAddress, selectedPhone }));
    if (savedCustomerId) {
      navigate(`/crm/customer/${savedCustomerId}`);
    }
  }, [navigate, reset, savedCustomerId, selectedAddress, selectedPhone]);

  const handleEditContact = useCallback(() => {
    setMode('edit');
    if (customerInfo) {
      reset(mapInfoToForm(customerInfo, { selectedAddress, selectedPhone }), { keepDefaultValues: false });
    } else {
      reset(mapInfoToForm(defaultFormValues, { selectedAddress, selectedPhone }), { keepDefaultValues: false });
    }
  }, [customerInfo, reset, selectedAddress, selectedPhone]);

  const handleDoubleClick = (field: keyof CustomerForm) => {
    setMode('search');
    setFieldToFocus(field);
    reset(mapInfoToForm(defaultFormValues, { selectedAddress, selectedPhone }), { keepDefaultValues: false });
  };

  const handleNewSale = useCallback((
    usr: UserData | null,
    custInfo: CustomerInfo | undefined,
    phoneSel: PhoneSelect,
    addressSel: AddressSelect,
  ) => {
    if (usr?.email && custInfo) {
      mutateCreateSalesOrder({
        advisorEmail: usr.email,
        customer: {
          name: custInfo.name?.toString() ?? '',
          email: custInfo.email?.toString() ?? '',
          address: (custInfo[`${addressSel}Address`] ?? custInfo.deliveryAddress ?? custInfo.invoiceAddress)?.toString() ?? '',
          city: (custInfo[`${addressSel}City`] ?? custInfo.deliveryCity ?? custInfo.invoiceCity)?.toString() ?? '',
          zip: (custInfo[`${addressSel}ZIP`] ?? custInfo.deliveryZIP ?? custInfo.invoiceZIP)?.toString() ?? '',
          phone: ((phoneSel === 'Phone 1' ? custInfo?.phone1 : custInfo?.phone2) ?? custInfo.phone1 ?? custInfo.phone2)?.toString() ?? '',
        },
      });
    }
  }, [mutateCreateSalesOrder]);

  const handleSendSMS = useCallback(async (text: string, custInfo: CustomerInfo | undefined) => {
    const phone = custInfo?.phone1 ?? custInfo?.phone2;
    if (text && selectedCalllistItems?.length) {
      // TODO This also has support to send SMS to the selected call list table row!
      for (const item of selectedCalllistItems) {
        if (item.phone) {
          // eslint-disable-next-line no-await-in-loop
          await mutateSendSMS({
            from: 'ACsenteret', to: item.phone, msg: text, type: 'CRM_CALLIST_SMS', ignoreDeleted: true,
          });
        }
      }
    } else if (text && custInfo && phone) {
      await mutateSendSMS({
        from: 'ACsenteret', to: phone, msg: text, type: 'CRM_SMS', ignoreDeleted: true,
      });
    }
  }, [mutateSendSMS, selectedCalllistItems]);

  const handleMakeNote = useCallback(async (
    usr: UserData | null,
    cust: CustomerWithData | undefined,
    text: string,
  ) => {
    if (cust && usr?.email && text) {
      const contactLog: CustomerContactLog = {
        message: text,
        whoFrom: usr.email,
        when: dayjs().toISOString(),
        what: 'Kommentar',
      };

      const property: Property = {
        id: 0,
        parent_id: cust.id,
        type: CustomerPropertyType.ContactLog,
        value: JSON.stringify(contactLog),
      };

      await mutateAddCustomerProps([property]);
    }
  }, [mutateAddCustomerProps]);

  const handleSelectedAddress = useCallback((address: AddressSelect) => {
    setSelectedAddress(address);
    if (mode === 'edit') {
      setFieldValue('street', (customerInfo?.[`${address}Address`]?.toString() ?? ''));
      setFieldValue('city', (customerInfo?.[`${address}City`]?.toString() ?? ''));
      setFieldValue('zip', (customerInfo?.[`${address}ZIP`]?.toString() ?? ''));
    }
  }, [customerInfo, mode, setFieldValue]);

  return (
    <Box sx={{ overflow: 'auto' }}>
      <form onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
      >
        <Stack direction="row" alignItems="center" sx={{ marginBottom: 2 }} spacing={2}>
          <Typography variant="h5">
            Contact information
            {modeText}
          </Typography>
          {mode === 'new' ? (
            <>
              <form.Subscribe selector={(state) => [state.canSubmit, state.isSubmitting]}>
                {([canSubmit, isSubmitting]) => (
                  <LoadingButton
                    buttonProps={{
                      variant: 'contained',
                      size: 'small',
                      title: 'Create new contact. Name and Zip code is minimum information',
                      loading: isSubmitting,
                      disabled: !canSubmit,
                      type: 'submit',
                    }}
                    disableStartIcon
                  >
                    <Check />
                  </LoadingButton>
                )}
              </form.Subscribe>
              <form.Subscribe selector={(state) => state.isSubmitting}>
                {(isSubmitting) => (
                  <Button
                    disabled={isSubmitting}
                    variant="contained"
                    size="small"
                    title="Cancel create new contact"
                    onClick={handleCancelNewContact}
                  >
                    <Remove />
                  </Button>
                )}
              </form.Subscribe>
            </>
          ) : (
            <Button
              variant="contained"
              size="small"
              title="Click to enter new contact details"
              onClick={handleNewContact}
            >
              <Add />
            </Button>
          )}
        </Stack>
        <Grid2 container direction="column" spacing={1}>
          <Grid2 container alignItems="center">
            <Grid2 size={3}>
              <Typography
                onClick={() => navigator.clipboard.writeText(showForm ? getFieldValue('name') ?? '' : customerInfo?.name?.toString() ?? '')}
                title="Click here to copy customer name to clipboard"
              >
                Name
              </Typography>
            </Grid2>
            <Grid2 size={9}>
              {mode === 'view' ? (
                <TextField
                  value={customerInfo?.name ?? ''}
                  // disabled
                  size="small"
                  variant="outlined"
                  fullWidth
                  title="Doubleclick to search for customer name or orderid. You can use % and _ for wildcard"
                  onDoubleClick={() => handleDoubleClick('name')}
                />
              ) : (
                <form.Field name="name">
                  {(field) => (
                    <TextField
                      key="name"
                      value={field.state.value ?? ''}
                      sx={{ backgroundColor }}
                      size="small"
                      variant="outlined"
                      fullWidth
                      autoFocus={fieldToFocus === 'name'}
                      onChange={(e) => {
                        field.handleChange(e.target.value);
                      }}
                      onKeyDown={(e) => handleKeyDown(e, field.state.value, CustomerPropertyType.Name)}
                      onBlur={() => handleBlur(field.state.value, CustomerPropertyType.Name)}
                      error={!!field.state.meta.errors}
                      helperText={field.state.meta.errors.join(', ')}
                    />
                  )}
                </form.Field>
              )}
            </Grid2>
          </Grid2>
          <Grid2 container alignItems="center">
            <Grid2 size={3}>
              <Typography
                onClick={() => navigator.clipboard.writeText(showForm ? getFieldValue('email') ?? '' : customerInfo?.email?.toString() ?? '')}
                title="Click here to copy email address to clipboard"
              >
                Email
              </Typography>
            </Grid2>
            <Grid2 size={9}>
              {mode === 'view' ? (
                <TextField
                  value={customerInfo?.email ?? ''}
                  size="small"
                  variant="outlined"
                  fullWidth
                  onDoubleClick={() => handleDoubleClick('email')}
                  title="Doubleclick to search for email. You can use % and _ for wildcard. To the left the type of email currently displayed is shown."
                />
              ) : (
                <form.Field name="email">
                  {(field) => (
                    <TextField
                      sx={{ backgroundColor }}
                      value={field.state.value ?? ''}
                      size="small"
                      variant="outlined"
                      fullWidth
                      autoFocus={fieldToFocus === 'email'}
                      onChange={(e) => {
                        field.handleChange(e.target.value);
                      }}
                      onKeyDown={(e) => handleKeyDown(e, field.state.value ?? '', CustomerPropertyType.Email)}
                      onBlur={() => handleBlur(field.state.value ?? '', CustomerPropertyType.Email)}
                      error={!!field.state.meta.errors}
                      helperText={field.state.meta.errors.join(', ')}
                    />
                  )}
                </form.Field>
              )}
            </Grid2>
          </Grid2>
          <Grid2 container alignItems="center">
            <Grid2 size={3}>
              <Typography
                onClick={() => {
                  const customerPhone = selectedPhone === 'Phone 1' ? customerInfo?.phone1?.toString() : customerInfo?.phone2?.toString();
                  navigator.clipboard.writeText(showForm ? getFieldValue('phone') ?? '' : customerPhone ?? '');
                }}
                title="Click here to copy phone number to clipboard"
              >
                {selectedPhone}
              </Typography>
            </Grid2>
            <Grid2 size={9}>
              {showForm ? (
                <form.Field name="phone">
                  {(field) => (showForm ? (
                    <TextField
                      size="small"
                      variant="outlined"
                      fullWidth
                      sx={{ backgroundColor }}
                      value={field.state.value ?? ''}
                      autoFocus={fieldToFocus === 'phone'}
                      onChange={(e) => {
                        field.handleChange(e.target.value);
                      }}
                      onKeyDown={(e) => handleKeyDown(e, field.state.value ?? '', CustomerPropertyType.Phone)}
                      onBlur={() => handleBlur(field.state.value ?? '', CustomerPropertyType.Phone)}
                      error={!!field.state.meta.errors}
                      helperText={field.state.meta.errors.join(', ')}
                    />
                  ) : null)}
                </form.Field>
              ) : (
                <Select
                  value={selectedPhone ?? ''}
                  onChange={(e) => setSelectedPhone(e.target.value as PhoneSelect)}
                  displayEmpty
                  onDoubleClick={() => handleDoubleClick('phone')}
                  size="small"
                >
                  {phoneSelects.map((p, i) => <MenuItem value={p} key={p}>{customerInfo?.[`phone${i + 1}`] ?? ''}</MenuItem>)}
                </Select>
              )}
            </Grid2>
          </Grid2>
          <Grid2 container alignItems="center">
            <Grid2 size={3}>
              <Typography
                onClick={() => {
                  const custStreet = showForm ? getFieldValue('street') ?? '' : customerInfo?.[`${selectedAddress}Street`] ?? '';
                  const custCity = showForm ? getFieldValue('city') ?? '' : customerInfo?.[`${selectedAddress}City`] ?? '';
                  const custZip = showForm ? getFieldValue('zip') ?? '' : customerInfo?.[`${selectedAddress}ZIP`] ?? '';
                  navigator.clipboard.writeText(`${custStreet}, ${custZip} ${custCity}`);
                }}
                title="Click here to copy customer address to clipboard"
              >
                Address
              </Typography>
            </Grid2>
            <Grid2 size={9}>
              <Select
                value={selectedAddress ?? ''}
                size="small"
                onChange={(e) => handleSelectedAddress(e.target.value as AddressSelect)}
              >
                {addressSelects.map((a) => (
                  <MenuItem value={a} key={a}>
                    {a.charAt(0).toUpperCase() + a.slice(1)}
                    {' '}
                    address
                  </MenuItem>
                ))}
              </Select>
            </Grid2>
          </Grid2>
          <Grid2 container alignItems="center">
            <Grid2 size={3}>
              <Typography
                onClick={() => navigator.clipboard.writeText(showForm ? getFieldValue('street') ?? '' : customerInfo?.[`${selectedAddress}Street`] ?? '')}
                title="Click here to copy customer street address to clipboard"
              >
                Street
              </Typography>
            </Grid2>
            <Grid2 size={9}>
              {showForm ? (
                <form.Field name="street">
                  {(field) => (
                    <TextField
                      sx={{ backgroundColor }}
                      value={field.state.value ?? ''}
                      size="small"
                      autoFocus={fieldToFocus === 'street'}
                      fullWidth
                      onChange={(e) => {
                        field.handleChange(e.target.value);
                      }}
                      onKeyDown={(e) => handleKeyDown(e, field.state.value ?? '', CustomerPropertyType[capitalize(`${selectedAddress}Street`)])}
                      onBlur={() => handleBlur(field.state.value ?? '', CustomerPropertyType[capitalize(`${selectedAddress}Street`)])}
                      error={!!field.state.meta.errors}
                      helperText={field.state.meta.errors.join(', ')}
                    />
                  )}
                </form.Field>
              ) : (
                <TextField
                  value={(customerInfo?.[`${selectedAddress}Address`] ?? '')}
                  size="small"
                  fullWidth
                  onDoubleClick={() => handleDoubleClick('street')}
                />
              )}
            </Grid2>
          </Grid2>
          <Grid2 container alignItems="center">
            <Grid2 size={3}>
              <Typography
                onClick={() => {
                  const custZip = showForm ? getFieldValue('zip') ?? '' : customerInfo?.[`${selectedAddress}ZIP`] ?? '';
                  const custCity = showForm ? getFieldValue('city') ?? '' : customerInfo?.[`${selectedAddress}City`] ?? '';
                  navigator.clipboard.writeText(`${custZip} ${custCity}`);
                }}
                title="Click here to copy customer zipcode and city to clipboard"
              >
                City
              </Typography>
            </Grid2>
            <Grid2 size={9} direction="row" justifyContent="start" alignItems="center">
              <Stack direction="row" spacing={1}>
                {showForm ? (
                  <>
                    <form.Field name="zip">
                      {(field) => (
                        <TextField
                          value={field.state.value ?? ''}
                          size="small"
                          // fullWidth
                          autoFocus={fieldToFocus === 'zip'}
                          sx={{ width: 90, backgroundColor }}
                          onChange={(e) => {
                            field.handleChange(e.target.value);
                          }}
                          onKeyDown={(e) => handleKeyDown(e, field.state.value?.padStart(4, '0') ?? '', CustomerPropertyType[capitalize(`${selectedAddress}ZIP`)])}
                          onBlur={() => handleBlur(field.state.value?.padStart(4, '0') ?? '', CustomerPropertyType[capitalize(`${selectedAddress}ZIP`)])}
                          error={!!field.state.meta.errors}
                          helperText={field.state.meta.errors.join(', ')}
                        />
                      )}
                    </form.Field>
                    <form.Field name="city">
                      {(field) => (
                        <TextField
                          value={field.state.value ?? ''}
                          size="small"
                          fullWidth
                          autoFocus={fieldToFocus === 'city'}
                          disabled={mode === 'search'}
                          sx={{ backgroundColor }}
                          onChange={(e) => {
                            field.handleChange(e.target.value);
                          }}
                          onKeyDown={(e) => handleKeyDown(e, field.state.value ?? '', CustomerPropertyType[capitalize(`${selectedAddress}City`)])}
                          onBlur={() => handleBlur(field.state.value ?? '', CustomerPropertyType[capitalize(`${selectedAddress}City`)])}
                          error={!!field.state.meta.errors}
                          helperText={field.state.meta.errors.join(', ')}
                        />
                      )}
                    </form.Field>
                  </>
                ) : (
                  <>
                    <TextField
                      value={customerInfo?.[`${selectedAddress}ZIP`] ?? ''}
                      size="small"
                      // fullWidth
                      sx={{ width: 90 }}
                      onDoubleClick={() => handleDoubleClick('zip')}
                    />
                    <TextField
                      value={customerInfo?.[`${selectedAddress}City`] ?? ''}
                      size="small"
                      fullWidth
                      sx={{ backgroundColor }}
                      onDoubleClick={() => handleDoubleClick('city')}
                    />
                  </>
                )}
              </Stack>
            </Grid2>
          </Grid2>
          <Grid2 container alignItems="center" justifyContent="space-evenly">
            <Grid2>
              <Button
                variant="contained"
                size="small"
                title="Edit contact"
                onClick={handleEditContact}
                disabled={!(customer && mode === 'view')} // only allow in view mode + customer exists
              >
                <EditNote />
              </Button>
            </Grid2>
            <Grid2>
              <LoadingButton
                disableStartIcon
                buttonProps={{
                  variant: 'contained',
                  size: 'small',
                  title: 'Create new CPpro sales order',
                  onClick: () => handleNewSale(user, customerInfo, selectedPhone, selectedAddress),
                  disabled: !(customer && mode === 'view'),
                  loading: isPendingCreateSalesOrder,
                }}
              >
                New Sale
              </LoadingButton>
            </Grid2>
            <Grid2>
              <LoadingButton
                disableStartIcon
                buttonProps={{
                  variant: 'contained',
                  size: 'small',
                  title: 'Send SMS with the text currently in the textbox below',
                  onClick: () => handleSendSMS(contactTextArea, customerInfo),
                  disabled: !(customer && mode === 'view') || !contactTextArea,
                  loading: isPendingSendSMS,
                }}
              >
                SMS
              </LoadingButton>
            </Grid2>
            <Grid2>
              <LoadingButton
                disableStartIcon
                buttonProps={{
                  variant: 'contained',
                  size: 'small',
                  title: 'Make note with the text currently in the textbox below',
                  onClick: () => handleMakeNote(user, customer, contactTextArea),
                  disabled: !(customer && mode === 'view') || !contactTextArea,
                  loading: isPendingAddCustomerProps,
                }}
              >
                <Edit />
              </LoadingButton>
            </Grid2>
          </Grid2>
          <Grid2>
            <TextField
              value={contactTextArea}
              onChange={(e) => setContactTextArea(e.target.value)}
              multiline
              minRows={1}
              maxRows={4}
              variant="outlined"
              fullWidth
            />
          </Grid2>
          <Grid2 container alignContent="center" spacing={1}>
            <Grid2>
              <Typography>
                Service status:
                {' '}
                {customerInfo?.serviceStatus ?? ''}
              </Typography>
            </Grid2>
            <Grid2>
              <Typography>
                Service intervall:
                {' '}
                <b>{customerInfo?.serviceInterval ?? 0}</b>
              </Typography>
            </Grid2>
          </Grid2>
        </Grid2>
      </form>
    </Box>
  );
};

export default CRMCustomerInfo;
