import {
  Autocomplete,
  Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid2, MenuItem, Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useForm, useStore } from '@tanstack/react-form';
import dayjs from 'dayjs';
import React, { useCallback, useMemo } from 'react';
import { z } from 'zod';
import { DatePicker } from '@mui/x-date-pickers';
import { Download } from '@mui/icons-material';
import { useMutation } from '@tanstack/react-query';
import type { CustomerWithData } from '../../../../generated/cppro/api.js';
import LoadingButton from '../../common/buttons/LoadingButton.js';
import type { CustomerContactLog } from '../../../../CPpro/model/model.js';
import { CustomerPropertyType, UserPropertyType } from '../../../../CPpro/model/model.js';
import { CPproMutations, CRMMutations } from '../../../mutations/index.js';
import { CRMUtils } from '../../../utils/index.js';
import { useCPproUserContext } from '../../../contexts/CpproUserContext.js';
import { removeNulls } from '../../../../CPpro/Utils/tools.js';
import { productBrands, productModels } from '../../../data/crmServiceToolsData.js';
import { useCPproDataContext } from '../../../contexts/CPproDataContext.js';
import type { EditModelProperty } from '../../../types/cpproTypes.js';
import type { UserData } from '../../../providers/CpproUserProvider.js';
import useCRMStore from '../../../stores/crmStore.js';

type Props = {
  customer: CustomerWithData | undefined;
  refetchCustomer: () => Promise<void>;
  refetchCallList: () => Promise<void>;
};

const maxDate = dayjs().add(9, 'years').endOf('year');
const minDate = dayjs().startOf('day');

const statuses = ['',
  'Ring', 'Kontakt senere', 'Manuell', 'Flyttet',
  'Flyttet, finner ikke ny eier',
  'Opprett ordre',
  'Nei',
  'Ta med på neste SMS runde',
  'Opprettet kunde og ordre manuelt',
  'SMS sendt',
  'Aldri kontakt denne kunden',
  'Opprett filter',
] as const;
const restStatuses = statuses.filter((s) => s !== '' && s !== 'Opprett ordre');
// eslint-disable-next-line @typescript-eslint/no-redeclare
type Status = typeof statuses[number];

const orderLineType = ['', 'Luft/luft',
  'dobbel',
  'trippel',
  'Service med trommelrens',
  'Luft/vann',
  'Service Bedrift',
  'BVentilasjon',
  'AVentilasjon',
  'Reparasjon',
  'DiagnoseTest',
  'Flytting komplett varmepumpe',
  'Flytting inne eller utedel',
] as const;
type OrderLineType = typeof orderLineType[number];
const orderLineTypeMap: { val: OrderLineType, label: string }[] = [
  { val: 'Luft/luft', label: 'luft/luft 1 innedel' },
  { val: 'dobbel', label: 'luft/luft 2 innedeler' },
  { val: 'trippel', label: 'luft/luft 3 innedeler' },
  { val: 'Service med trommelrens', label: 'Service med trommelrens' },
  { val: 'Luft/vann', label: 'luft/vann' },
  { val: 'Service Bedrift', label: 'Service Bedrift' },
  { val: 'BVentilasjon', label: 'Balansert ventilasjon rens' },
  { val: 'AVentilasjon', label: 'Avtrekk ventilasjon rens' },
  { val: 'Reparasjon', label: 'Reparasjon' },
  { val: 'DiagnoseTest', label: 'Diagnose/Test' },
  { val: 'Flytting komplett varmepumpe', label: 'Flytting komplett varmepumpe' },
  { val: 'Flytting inne eller utedel', label: 'Flytting inne eller utedel' },
];
const restOrderLineType = orderLineType.filter((t) => t !== '');

const timeslots = ['', '06:45', '08:00', '09:15', '10:30', '11:45', '13:00', '14:15', '15:30', '16:45', '18:00'] as const;
const timeslotsMap: { val: Timeslot; label: string }[] = [
  { val: '', label: '' },
  { val: '06:45', label: 'Service slot 0 (kl 0645)' },
  { val: '08:00', label: 'Service slot 1 (kl 0800)' },
  { val: '09:15', label: 'Service slot 2 (kl 0915)' },
  { val: '10:30', label: 'Service slot 3 (kl 1030)' },
  { val: '11:45', label: 'Service slot 4 (kl 1145)' },
  { val: '13:00', label: 'Service slot 5 (kl 1300)' },
  { val: '14:15', label: 'Service slot 6 (kl 1415)' },
  { val: '15:30', label: 'Service slot 7 (kl 1530)' },
  { val: '16:45', label: 'Service slot 8 (kl 1645)' },
  { val: '18:00', label: 'Service slot 9 (kl 1800)' },
  { val: '08:00', label: 'Montering slot 1 (kl 0800)' },
  { val: '11:45', label: 'Montering slot 2 (kl 1145)' },
  { val: '15:30', label: 'Montering slot 3 (kl 1530)' },
];
type Timeslot = typeof timeslots[number];

const slotLengths = ['', '01:15', '02:30', '03:45', '05:00', '06:15', '07:30', '08:45', '10:00', '11:15'] as const;
const slotLengthsMap: { val: SlotLength; label: string }[] = [
  { val: '', label: '' },
  { val: '01:15', label: '1 (1h 15min)' },
  { val: '02:30', label: '2 (2h 30min)' },
  { val: '03:45', label: '3 (3h 45min)' },
  { val: '05:00', label: '4 (5h 00min)' },
  { val: '06:15', label: '5 (6h 15min)' },
  { val: '07:30', label: '6 (7h 30min)' },
  { val: '08:45', label: '7 (8h 45min)' },
  { val: '10:00', label: '8 (10h 00min)' },
  { val: '11:15', label: '9 (11h 15min)' },
];
type SlotLength = typeof slotLengths[number];

const servicePlans = ['', 'SMS', 'Ring'] as const;
const servicePlansMap: { val: ServicePlan; label: string }[] = [
  { val: '', label: '' },
  { val: 'SMS', label: 'Ønsker fast avtale SMS annet hvert år' },
  { val: 'Ring', label: 'Ringes annet hvert år' },
];
type ServicePlan = typeof servicePlans[number];

// TODO Create schema from type instead!!

const orderLineRequiredSchema = z.object({
  product: z.custom<OrderLineType>((data) => restOrderLineType.includes(data)),
  discount: z.coerce.number().nonnegative().int().max(100)
    .optional(),
  pumpManufacturer: z.string().optional(),
  pumpModel: z.string().optional(),
});

const orderLineOptionalSchema = z.object({
  product: z.enum(orderLineType).optional(),
  discount: z.coerce.number().optional(),
  pumpManufacturer: z.string().optional(),
  pumpModel: z.string().optional(),
});

const serviceToolOrderSchema = z.object({
  newStatus: z.literal<Status>('Opprett ordre'),
  orderLine1: orderLineRequiredSchema,
  orderLine2: orderLineOptionalSchema.optional(),
  orderLine3: orderLineOptionalSchema.optional(),
  orderLine4: orderLineOptionalSchema.optional(),
  calendar: z.string(),
  serviceDate: z.string().optional(),
  timeslot: z.enum(timeslots),
  slots: z.enum(slotLengths),
  servicePlan: z.enum(servicePlans).optional(), // ?
  printPickList: z.boolean(),
  serviceOrderDesc: z.string().optional(),
});

const serviceToolUnionSchema = z.discriminatedUnion('newStatus', [
  serviceToolOrderSchema,
  // serviceToolOtherSchema,
  ...restStatuses.map((s) => z.object({
    newStatus: z.literal<Status>(s),
    followUpDate: z.string().nonempty(),
  })),
]);

// type ServiceToolForm = z.infer<typeof serviceToolSchema>;

interface OrderLine {
  product: OrderLineType; // or number?
  discount: number;
  pumpManufacturer: string;
  pumpModel: string;
}

type ServiceToolForm = {
  newStatus: Status; // union/enum
  followUpDate?: string;
  orderLine1?: Partial<OrderLine>,
  orderLine2?: Partial<OrderLine>;
  orderLine3?: Partial<OrderLine>;
  orderLine4?: Partial<OrderLine>;
  calendar?: string;
  serviceDate?: string;
  timeslot?: Timeslot;
  slots?: SlotLength;
  servicePlan?: ServicePlan;
  printPickList?: boolean;
  serviceOrderDesc?: string;
};

const createOrderMapData = (data: { value: ServiceToolForm, customer: CustomerWithData, user: UserData }) => {
  const { value, customer, user } = data;
  const statusProp = CRMUtils.getProperty(customer, CustomerPropertyType.ServiceStatus);

  if (!value.orderLine1) {
    return { properties: [] as EditModelProperty[], createService: null }; // TODO zod should validate correctly so this is just a "TS-hack"
  }
  const callCounterProp = CRMUtils.getProperty(customer, CustomerPropertyType.CallCounter);
  const servicePlanProp = CRMUtils.getProperty(customer, CustomerPropertyType.ServicePlan);
  const serviceDate = value.serviceDate ? dayjs(value.serviceDate) : dayjs({ year: 2038, date: 1, month: 0 });
  const installTime = value.serviceDate ? value.timeslot : '00:08';
  const serviceDateTime = `${serviceDate.format('YYYY-MM-DD')}T${installTime}:00`;

  /**
   * 1 name
    2 phone
    3 email
    4 phone2
    6 client00010001_custid
    7 client00010005_custid
    8 client00010009_custid
    9 client00010018_custid
    10 creditstop
    11 mamut_timestamp
    13 servicestatus
    14 createorder
    15 createorderdate
    16 createordertextfield
    17 callcounter
    18 endcustomer private
    19 endcustomer business
    20 endcustomer dealer
    21 supplier
    22 followupdate
    23 laststatusupdate
    24 serviceplan
    25 callattempted
   */

  const properties: (EditModelProperty | undefined)[] = [
    {
      type: callCounterProp ? 'update' : 'create',
      prop: {
        id: callCounterProp?.id ?? 0,
        parent_id: customer.id,
        type: CustomerPropertyType.CallCounter, // TODO What is this???
        value: 0,
      },
    },
    {
      type: servicePlanProp ? 'update' : 'create',
      prop: {
        id: servicePlanProp?.id ?? 0,
        parent_id: customer.id,
        type: CustomerPropertyType.ServicePlan,
        value: value.servicePlan ?? '',
      },
    },
    {
      type: statusProp ? 'update' : 'create',
      prop: {
        id: statusProp?.id ?? 0,
        parent_id: customer.id,
        type: CustomerPropertyType.ServiceStatus,
        value: '', // ???
      },
    },
    value.orderLine1.pumpManufacturer ? {
      type: 'create',
      prop: {
        id: 0,
        parent_id: customer.id,
        type: CustomerPropertyType.ContactLog,
        value: JSON.stringify({
          message: value.orderLine1.pumpManufacturer ?? '',
          when: dayjs().toISOString(),
          whoFrom: user.email,
          what: 'Pumpebrand',
        } satisfies CustomerContactLog),
      },
    } : undefined,
    value.orderLine1.pumpModel ? {
      type: 'create',
      prop: {
        id: 0,
        parent_id: customer.id,
        type: CustomerPropertyType.ContactLog,
        value: JSON.stringify({
          message: value.orderLine1.pumpModel,
          when: dayjs().toISOString(),
          whoFrom: user.email,
          what: 'Pumpemodell',
        } satisfies CustomerContactLog),
      },
    } : undefined,
  ];

  const customerInfo = CRMUtils.mapCustomerToInfo(customer);

  return {
    properties: properties.filter(removeNulls),
    createService: {
      customer: {
        address: (customerInfo.deliveryAddress ?? customerInfo.invoiceAddress)?.toString() ?? '',
        city: (customerInfo.deliveryCity ?? customerInfo.invoiceCity)?.toString() ?? '',
        zip: (customerInfo.deliveryZIP ?? customerInfo.invoiceZIP)?.toString() ?? '',
        name: customerInfo.name?.toString() ?? '',
        email: customerInfo.email?.toString() ?? '',
        phone: (customerInfo.phone1 ?? customerInfo.phone2)?.toString() ?? '',
      },
      calendar: value.calendar,
      dateAndClock: serviceDateTime,
      serviceText: value.serviceOrderDesc,
      slotLength: value.slots,
      serviceOrders: [
        value.orderLine1.product ? {
          product: value.orderLine1.product,
          discount: value.orderLine1.discount,
        } : undefined,
        value.orderLine2?.product ? {
          product: value.orderLine2.product,
          discount: value.orderLine2.discount,
        } : undefined,
        value.orderLine3?.product ? {
          product: value.orderLine3.product,
          discount: value.orderLine3.discount,
        } : undefined,
        value.orderLine4?.product ? {
          product: value.orderLine4.product,
          discount: value.orderLine4.discount,
        } : undefined,
      ].filter(removeNulls),
    },
  };
};

const statusUpdateMap = (data: { customer: CustomerWithData; value: ServiceToolForm, user: UserData }) => {
  const { value, customer, user } = data;
  const statusProp = CRMUtils.getProperty(customer, CustomerPropertyType.ServiceStatus);

  const followupProp = CRMUtils.getProperty(customer, CustomerPropertyType.FollowUpDate);
  const followUpDate = dayjs(value.followUpDate);
  const properties: EditModelProperty[] = [
    {
      type: followupProp ? 'update' : 'create',
      prop: {
        id: followupProp?.id ?? 0,
        parent_id: customer.id,
        type: CustomerPropertyType.FollowUpDate,
        value: value.followUpDate ?? '',
      },
    },
    {
      type: statusProp ? 'update' : 'create',
      prop: {
        id: statusProp?.id ?? 0,
        parent_id: customer.id,
        type: CustomerPropertyType.ServiceStatus,
        value: value.newStatus,
      },
    },
    { // only on status update
      type: 'create',
      prop: {
        id: 0,
        parent_id: customer.id,
        type: CustomerPropertyType.ContactLog,
        value: JSON.stringify({
          message: `${value.newStatus} ${followUpDate.format('YYYY-MM-DD')}`,
          when: dayjs().toISOString(),
          whoFrom: user.email,
          what: 'Statusendring',
        } satisfies CustomerContactLog),
      },
    },
  ];

  return properties;
};

const CRMServiceTools = (props: Props) => {
  const { customer, refetchCallList, refetchCustomer } = props;

  // Store
  const showBackdrop = useCRMStore((s) => s.showBackdrop);
  const closeBackdrop = useCRMStore((s) => s.closeBackdrop);

  const { user } = useCPproUserContext();
  const { users } = useCPproDataContext();
  const userEmails = useMemo(() => users.map((usr) => usr.properties.find((p) => p.type === UserPropertyType.Email)?.value.toString())
    .filter(removeNulls)
    .toSorted((a, b) => a.localeCompare(b)), [users]);

  const { mutateAsync: mutateCustomerProps } = useMutation({ ...CPproMutations.editCustomerMutation, onSuccess: refetchCustomer });
  const { mutateAsync: mutateCreateService } = useMutation({
    ...CRMMutations.createServiceMutation,
    onMutate: showBackdrop,
    onSettled: closeBackdrop,
  });

  const form = useForm<ServiceToolForm>({
    defaultValues: {
      calendar: '',
      followUpDate: '',
      newStatus: '',
      orderLine1: {
        discount: '' as unknown as number,
        product: 'Luft/luft',
        pumpManufacturer: '',
        pumpModel: '',
      },
      orderLine2: undefined,
      orderLine3: undefined,
      orderLine4: undefined,
      printPickList: false,
      serviceDate: '',
      servicePlan: '',
      slots: '',
      timeslot: '',
      serviceOrderDesc: '',
    },
    onSubmit: async ({ value, formApi }) => {
      if (customer && user) {
        console.log(value);
        if (value.newStatus === 'Opprett ordre') {
          const { properties, createService } = createOrderMapData({ value, customer, user });

          if (createService) {
            await mutateCreateService(createService);
            await mutateCustomerProps(properties);
          } else {
            console.error('Failed to map to createService');
          }
        } else {
          const properties = statusUpdateMap({ customer, value, user });
          await mutateCustomerProps(properties);
        }
      }

      formApi.reset();
    },
    onSubmitInvalid: ({ value }) => {
      console.log('invalid:', value);
    },
    validators: {
      onSubmit: serviceToolUnionSchema,
      // onChange: serviceToolUnionSchema,
    },
  });

  const status = useStore(form.store, (state) => state.values.newStatus);
  const isCreateOrder = useMemo(() => status === 'Opprett ordre', [status]);
  const pumpBrand1 = useStore(form.store, (state) => state.values.orderLine1?.pumpManufacturer);
  const pumpBrand2 = useStore(form.store, (state) => state.values.orderLine2?.pumpManufacturer);
  const pumpBrand3 = useStore(form.store, (state) => state.values.orderLine3?.pumpManufacturer);
  const pumpBrand4 = useStore(form.store, (state) => state.values.orderLine4?.pumpManufacturer);

  const pumpModels = useMemo(() => {
    const models1: string[] = [];
    const models2: string[] = [];
    const models3: string[] = [];
    const models4: string[] = [];

    let skipBrand1 = false;
    if (pumpBrand1 === 'Annet merke') {
      models1.push('Annen modell');
      skipBrand1 = true;
    } else if (!pumpBrand1) {
      models1.push('');
      skipBrand1 = true;
    }

    let skipBrand2 = false;
    if (pumpBrand2 === 'Annet merke') {
      models2.push('Annen modell');
      skipBrand2 = true;
    } else if (!pumpBrand2) {
      models2.push('');
      skipBrand2 = true;
    }

    let skipBrand3 = false;
    if (pumpBrand3 === 'Annet merke') {
      models3.push('Annen modell');
      skipBrand3 = true;
    } else if (!pumpBrand3) {
      models3.push('');
      skipBrand3 = true;
    }

    let skipBrand4 = false;
    if (pumpBrand4 === 'Annet merke') {
      models4.push('Annen modell');
      skipBrand4 = true;
    } else if (!pumpBrand4) {
      models4.push('');
      skipBrand4 = true;
    }

    if (!skipBrand1 || !skipBrand2 || !skipBrand3 || !skipBrand4) {
      for (const model of productModels) {
        if (pumpBrand1 && model.NOR.includes(pumpBrand1) && !skipBrand1) models1.push(model.NOR);
        if (pumpBrand2 && model.NOR.includes(pumpBrand2) && !skipBrand2) models2.push(model.NOR);
        if (pumpBrand3 && model.NOR.includes(pumpBrand3) && !skipBrand3) models3.push(model.NOR);
        if (pumpBrand4 && model.NOR.includes(pumpBrand4) && !skipBrand4) models4.push(model.NOR);
      }
    }

    return {
      models1, models2, models3, models4,
    };
  }, [pumpBrand1, pumpBrand2, pumpBrand3, pumpBrand4]);

  const isDisabled = useMemo(() => !customer, [customer]);
  // TODO Helpertext / error fields!

  // Callbacks
  const handleCallCounterInc = useCallback(async () => {
    // TODO This should also reset / update the call list? (remove the customer from the list?)
    if (customer && user?.email) {
      const counterProp = CRMUtils.getProperty(customer, CustomerPropertyType.CallCounter);
      const followupProp = CRMUtils.getProperty(customer, CustomerPropertyType.FollowUpDate);
      const nextCounter = counterProp ? (CRMUtils.getNumValueFromProperty(counterProp) ?? 0) + 1 : 1;
      const contactLogProp: CustomerContactLog = {
        message: `Forsøk nr ${nextCounter}`,
        whoFrom: user.email,
        when: dayjs().toISOString(),
        what: 'Forsøkt ringt',
      };
      await mutateCustomerProps([{
        type: counterProp ? 'update' : 'create',
        prop: {
          id: counterProp?.id ?? 0,
          parent_id: customer.id,
          type: CustomerPropertyType.CallCounter,
          value: nextCounter.toString(),
        },
      }, {
        type: 'create', // TODO Create x2?
        prop: {
          id: 0,
          parent_id: customer.id,
          type: CustomerPropertyType.ContactLog,
          value: JSON.stringify({
            message: 'Call attempted, no contact',
            whoFrom: user.email,
            when: dayjs().toISOString(),
            what: 'Forsøkt ringt',
          } satisfies CustomerContactLog),
        },
      }, {
        type: 'create',
        prop: {
          id: 0,
          parent_id: customer.id,
          type: CustomerPropertyType.ContactLog,
          value: JSON.stringify(contactLogProp),
        },
      }, {
        type: followupProp ? 'update' : 'create',
        prop: {
          id: followupProp?.id ?? 0,
          parent_id: customer.id,
          type: CustomerPropertyType.FollowUpDate,
          value: dayjs().add(7, 'days').format('YYYY-MM-DD'),
        },
      }], {
        onSuccess: async () => {
          // await refetchCustomer();
          await refetchCallList();
        },
      });
    }
  }, [customer, mutateCustomerProps, refetchCallList, user?.email]);

  return (
    <Box>
      <form onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
      >
        <Grid2 container direction="column" spacing={2}>
          <Grid2>
            <Button
              size="small"
              disabled={isDisabled}
              variant="contained"
              title="Adds 1 to call counter."
              onClick={handleCallCounterInc}
            >
              Call attempted, no answer
            </Button>
          </Grid2>
          <Grid2 container spacing={1} alignItems="center">
            <Grid2>
              <form.Field name="newStatus">
                {(field) => (
                  <FormControl>
                    <FormControlLabel
                      label="Select new status:"
                      labelPlacement="start"
                      sx={{ marginLeft: 0 }}
                      control={(
                        <Select
                          sx={{ marginLeft: 0.5, minWidth: 300 }}
                          value={field.state.value}
                          onChange={(e) => field.handleChange(e.target.value as Status)}
                          disabled={isDisabled}
                          size="small"
                          displayEmpty
                          error={!!field.state.meta.errors.length}
                        >
                          {Object.values(statuses).map((m) => <MenuItem key={m || 'empty'} value={m}>{m}</MenuItem>)}
                        </Select>
                      )}
                    />
                    {field.state.meta.errors.length > 0 ? <FormHelperText error>{field.state.meta.errors.join(', ')}</FormHelperText> : null}
                  </FormControl>
                )}
              </form.Field>
            </Grid2>
            <Grid2>
              <form.Field name="followUpDate">
                {(field) => (
                  <FormControlLabel
                    label="Follow up date:"
                    labelPlacement="start"
                    control={(
                      <DatePicker
                        sx={{ marginLeft: 0.5, width: 150 }}
                        disabled={isDisabled || isCreateOrder}
                        value={field.state.value ? dayjs(field.state.value) : null}
                        onChange={(val) => field.handleChange(val?.toISOString() ?? '')}
                        maxDate={maxDate}
                        minDate={minDate}
                        slotProps={{
                          textField: {
                            size: 'small',
                            error: !!field.state.meta.errors.length,
                            helperText: field.state.meta.errors.join(', '),
                          },
                        }}
                      />
                  )}
                  />
                )}
              </form.Field>
            </Grid2>
          </Grid2>
          <Grid2 container spacing={1} alignItems="center" sx={{ visibility: isCreateOrder ? undefined : 'hidden' }}>
            {Array.from({ length: 4 }).map((_, i) => (
              <Grid2 key={`grid_${i + 1}`} container size={3} direction="column" spacing={1}>
                <Grid2>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography>Discount (%)</Typography>
                    <form.Field name={`orderLine${(i + 1) as 1 | 2 | 3 | 4}.discount`}>
                      {(field) => (
                        <TextField
                          sx={{ width: 100 }}
                          disabled={isDisabled}
                          value={field.state.value ?? ''}
                          type="number"
                          size="small"
                          error={!!field.state.meta.errors.length}
                          onChange={(e) => field.handleChange(Number(e.target.value))}
                        />
                      )}
                    </form.Field>
                  </Stack>
                </Grid2>
                <Grid2>
                  <Typography>
                    Orderline
                    {' '}
                    {i + 1}
                    :
                  </Typography>
                  <form.Field name={`orderLine${(i + 1) as 1 | 2 | 3 | 4}.product`}>
                    {(field) => (
                      <Select
                        sx={{ minWidth: 270 }}
                        size="small"
                        disabled={isDisabled}
                        value={field.state.value ?? ''}
                        error={!!field.state.meta.errors.length}
                        onChange={(e) => field.handleChange(e.target.value as OrderLineType)}
                      >
                        {orderLineTypeMap.filter((v) => (i === 0 ? v.val !== '' : true)).map((v) => <MenuItem key={v.val} value={v.val}>{v.label}</MenuItem>)}
                      </Select>
                    )}
                  </form.Field>
                </Grid2>
                <Grid2>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography>Pump brand</Typography>
                    <form.Field name={`orderLine${(i + 1) as 1 | 2 | 3 | 4}.pumpManufacturer`}>
                      {(field) => (
                        <Select
                          sx={{ minWidth: 150 }}
                          size="small"
                          disabled={isDisabled}
                          displayEmpty
                          value={field.state.value ?? ''}
                          error={!!field.state.meta.errors.length}
                          onChange={(e) => field.handleChange(e.target.value)}
                        >
                          <MenuItem key="empty" value="" />
                          {productBrands.map((b) => <MenuItem key={b.NOR} value={b.NOR}>{b.NOR}</MenuItem>)}
                        </Select>
                      )}
                    </form.Field>
                  </Stack>
                </Grid2>
                <Grid2>
                  <Typography>Pump model</Typography>
                  <form.Field name={`orderLine${(i + 1) as 1 | 2 | 3 | 4}.pumpModel`}>
                    {(field) => (
                      <>
                        <Select
                          fullWidth
                          size="small"
                          disabled={isDisabled}
                          displayEmpty
                          value={field.state.value ?? ''}
                          error={!!field.state.meta.errors.length}
                          onChange={(e) => field.handleChange(e.target.value)}
                        >
                          <MenuItem key="empty" value="" />
                          {pumpModels[`models${(i + 1) as 1 | 2 | 3 | 4}`].map((m) => <MenuItem key={m || 'empty'} value={m}>{m}</MenuItem>)}
                        </Select>
                        {field.state.meta.errors.length ? (
                          <FormHelperText error>{field.state.meta.errors.join(', ')}</FormHelperText>
                        ) : null}
                      </>
                    )}
                  </form.Field>
                </Grid2>
                {/* <Grid2>
                    <Typography>Pump</Typography>
                    <Autocomplete
                      size="small"
                      disabled={isDisabled}
                      freeSolo
                      options={productOptions}
                      fullWidth
                      value={field.state.value?.pumpProductId ?? null as Product | null}
                      isOptionEqualToValue={(o, v) => o.id === v.id}
                      onChange={(__, v) => field.handleChange((u) => (u ? ({ ...u, pumpProductId: v as { id: number } }) : ({
                        product: '', discount: 0, pumpManufacturer: '', pumpModel: '', pumpProductId: v as { id: number },
                      })))}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Grid2> */}
              </Grid2>
            ))}
          </Grid2>
          <Grid2 container spacing={1} alignItems="center">
            <Grid2>
              <Typography>Calendar</Typography>
              <form.Field name="calendar">
                {(field) => (
                  <Autocomplete
                    freeSolo
                    options={userEmails}
                    sx={{ minWidth: 350 }}
                    size="small"
                    disabled={isDisabled || !isCreateOrder}
                    // displayEmpty
                    inputValue={field.state.value ?? ''}
                    onInputChange={(e, val) => field.handleChange(val ?? undefined)}
                    renderInput={(params) => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        error={!!field.state.meta.errors.length}
                        helperText={field.state.meta.errors.join(', ')}
                      />
                    )}
                    // error={!!field.state.meta.errors.length}
                  />
                )}
              </form.Field>
            </Grid2>
            <Grid2>
              <Typography>Date for service</Typography>
              <form.Field name="serviceDate">
                {(field) => (
                  <DatePicker
                    slotProps={{
                      textField: {
                        size: 'small',
                        error: !!field.state.meta.errors.length,
                        helperText: field.state.meta.errors.join(', '),
                      },
                    }}
                    // maxDate={maxDate}
                    minDate={minDate}
                    sx={{ width: 150 }}
                    disabled={isDisabled || !isCreateOrder}
                    value={field.state.value ? dayjs(field.state.value) : null}
                    onChange={(e) => field.handleChange(e?.toISOString() ?? '')}
                  />
                )}
              </form.Field>
            </Grid2>
            <Grid2>
              <Typography>Timeslot</Typography>
              <form.Field name="timeslot">
                {(field) => (
                  <FormControl>
                    <Select
                      sx={{ minWidth: 170 }}
                      size="small"
                      disabled={isDisabled || !isCreateOrder}
                      displayEmpty
                      value={field.state.value ?? ''}
                      onChange={(e) => field.handleChange(e.target.value as Timeslot)}
                      error={!!field.state.meta.errors.length}
                    >
                      {timeslotsMap.map((t, i) => <MenuItem key={`${t.val}_${i}` || 'empty'} value={t.val}>{t.label}</MenuItem>)}
                    </Select>
                    {field.state.meta.errors.length ? <FormHelperText error>{field.state.meta.errors.join(', ')}</FormHelperText> : null}
                  </FormControl>
                )}
              </form.Field>
            </Grid2>
            <Grid2>
              <Typography>Slots (1 slot=75min)</Typography>
              <form.Field name="slots">
                {(field) => (
                  <FormControl>
                    <Select
                      sx={{ minWidth: 150 }}
                      size="small"
                      disabled={isDisabled || !isCreateOrder}
                      displayEmpty
                      value={field.state.value ?? ''}
                      onChange={(e) => field.handleChange(e.target.value as SlotLength)}
                      error={!!field.state.meta.errors.length}
                    >
                      {slotLengthsMap.map((t) => <MenuItem key={t.val || 'empty'} value={t.val}>{t.label}</MenuItem>)}
                    </Select>
                    {field.state.meta.errors.length ? <FormHelperText error>{field.state.meta.errors.join(', ')}</FormHelperText> : null}
                  </FormControl>
                )}
              </form.Field>
            </Grid2>
            <Grid2>
              <Typography>Serviceplan</Typography>
              <form.Field name="servicePlan">
                {(field) => (
                  <FormControl>
                    <Select
                      sx={{ minWidth: 250 }}
                      size="small"
                      disabled={isDisabled || !isCreateOrder}
                      displayEmpty
                      value={field.state.value ?? ''}
                      onChange={(e) => field.handleChange(e.target.value as ServicePlan)}
                      error={!!field.state.meta.errors.length}
                    >
                      {servicePlansMap.map((v) => <MenuItem key={v.val || 'empty'} value={v.val}>{v.label}</MenuItem>)}
                    </Select>
                    {field.state.meta.errors.length ? <FormHelperText error>{field.state.meta.errors.join(', ')}</FormHelperText> : null}
                  </FormControl>
                )}
              </form.Field>
            </Grid2>
          </Grid2>
          <Grid2>
            <form.Field name="printPickList">
              {(field) => (
                <FormControlLabel
                  sx={{ marginLeft: 0 }}
                  label="Print plukkliste"
                  id="printPickListLabel"
                  labelPlacement="start"
                  control={(
                    <Checkbox
                      size="small"
                      id="printPickList"
                      disabled={isDisabled || !isCreateOrder}
                      checked={field.state.value ?? false}
                      onChange={(_, checked) => field.handleChange(checked)}
                    />
                )}
                />
              )}
            </form.Field>
          </Grid2>
          <Grid2 container spacing={1} alignItems="center">
            <Grid2>
              <form.Subscribe selector={(state) => [state.isSubmitting]}>
                {([isSubmitting]) => (
                  <Button
                    color="warning"
                    onClick={() => form.reset()}
                    size="small"
                    disabled={isSubmitting || isDisabled}
                    variant="contained"
                    title="Clear form"
                  >
                    Clear
                  </Button>
                )}
              </form.Subscribe>
            </Grid2>
            <Grid2>
              <form.Subscribe selector={(state) => [state.canSubmit, state.isSubmitting]}>
                {([canSubmit, isSubmitting]) => (
                  <LoadingButton
                    customIcon={<Download />}
                    buttonProps={{
                      size: 'small',
                      variant: 'contained',
                      type: 'submit',
                      loading: isSubmitting,
                      disabled: !canSubmit || isSubmitting || isDisabled,
                      title: 'Save service status',
                    }}
                  >
                    Lagre
                  </LoadingButton>
                )}
              </form.Subscribe>
            </Grid2>
            <Grid2 sx={{ visibility: isCreateOrder ? undefined : 'hidden' }}>
              <form.Field name="serviceOrderDesc">
                {(field) => (
                  <TextField
                    size="small"
                    minRows={3}
                    maxRows={5}
                    multiline
                    value={field.state.value ?? ''}
                    onChange={(e) => field.handleChange(e.target.value)}
                    fullWidth
                    sx={{ minWidth: 500 }}
                    title="Enter text to add to calendar event here"
                    error={!!field.state.meta.errors.length}
                    helperText={field.state.meta.errors.join(', ')}
                  />
                )}
              </form.Field>
            </Grid2>
          </Grid2>
        </Grid2>
      </form>
    </Box>
  );
};

export default CRMServiceTools;
