import type { UseQueryOptions } from '@tanstack/react-query';
import axios from 'axios';
import { cpproApi, activityhelperApi } from '../apis/apis.js';
import type {
  DefaultApiApiCRMAreaOpportunitiesGetRequest, GetAreaOpportunitiesResponse, GetCallListResponse, GetCustomerResponse,
} from '../../generated/cppro/api.js';
import type { Customer, OrderDetail } from '../../CPpro/model/model.js';
import { CustomerPropertyType, OrderDetailPropertyType } from '../../CPpro/model/model.js';
import type {
  GetCRMCustomerLocksResponse, GetCRMSMSTemplatesResponse, GetDepartmentZipAreasResponse, GetSMSMessagesResponseMessagesInner,
} from '../../generated/activityhelper/api.js';

export type CallListQueryParams = { fromZip: string, toZip: string } | undefined;

export const getCallListQuery = (params: CallListQueryParams): UseQueryOptions<GetCallListResponse['items']> => ({
  queryKey: ['callList', params],
  queryFn: async () => {
    if (!params) return [];
    const response = await cpproApi.apiCRMCallListGet({ fromZip: params.fromZip, toZip: params.toZip });
    return response.data.items;
  },
  enabled: !!params,
  // refetchOnMount: false,
  // refetchOnWindowFocus: false
});

export const getAreaOpportunitiesQuery = (params: Required<DefaultApiApiCRMAreaOpportunitiesGetRequest> & {
  department: GetDepartmentZipAreasResponse[number]
} | undefined): UseQueryOptions<{ department: GetDepartmentZipAreasResponse[number], response: GetAreaOpportunitiesResponse } | undefined> => ({
  queryKey: ['areaOpportunities', params],
  queryFn: async () => {
    if (!params) return undefined;
    const response = await cpproApi.apiCRMAreaOpportunitiesGet({ fromZip: params.fromZip, toZip: params.toZip, minScore: params.minScore });
    return {
      department: structuredClone(params.department),
      response: response.data,
    };
  },
  enabled: !!params,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
});

export const smsTemplatesQuery: UseQueryOptions<GetCRMSMSTemplatesResponse> = ({
  queryKey: ['crmSMSTemplates'],
  queryFn: async () => {
    const response = await activityhelperApi.apiCRMSMSTemplatesGet();
    return response.data;
  },
});

export const departmentZipAreasQuery: UseQueryOptions<GetDepartmentZipAreasResponse> = ({
  queryKey: ['departmentZipAreas'],
  queryFn: async () => {
    const response = await activityhelperApi.apiCRMDepartmentAreasGet();
    return response.data;
  },
});

export const getCustomerQuery = (customerId: number | undefined): UseQueryOptions<GetCustomerResponse['customer'] | undefined> => ({
  queryKey: ['customer', customerId],
  queryFn: async () => {
    if (!customerId) return undefined;
    const response = await cpproApi.apiCRMCustomerCustomerIdGet({ customerId: customerId.toString() });
    return response.data.customer;
  },
  enabled: !!customerId,
  // refetchOnMount: false,
  // refetchOnWindowFocus: false
});

export const getCustomerSearchQuery = (search: {
  value: string | number,
  property?: CustomerPropertyType } | undefined): UseQueryOptions<Customer[]> => ({
  queryKey: ['customer', search],
  queryFn: async () => {
    const searchCopy = search;
    if (!searchCopy || !searchCopy.value) return [];

    // If property is name and value is a number, search for orders instead!
    if (searchCopy.property === CustomerPropertyType.Name) {
      const numValue = Number(searchCopy.value);
      if (!Number.isNaN(numValue)) {
        const url = `/orderDetails/parameter/${OrderDetailPropertyType.OrderId}/find/${encodeURIComponent(numValue)}`;
        const response = await axios.get<OrderDetail[][]>(url);

        const orders = response.data.flatMap((o) => o);
        if (!orders.length) return [];

        const customerIds = new Set<number>();
        orders.forEach((o) => customerIds.add(o.parent_id));

        if (customerIds.size > 1) {
          const customers = await Promise.all(customerIds.values().toArray().filter((id) => !!id).map(async (id) => {
            const cust = await axios.get<Customer[][]>(`/customers/${id}`);
            return cust.data.flatMap((c) => c);
          }));
          return customers.flatMap((c) => c);
        }

        const custId = customerIds.values().toArray().at(0);
        if (!custId) return [];
        return [{ id: custId, properties: [] } satisfies Customer];
      }
    }

    const url = `/customers${searchCopy.property ? `/parameter/${searchCopy.property}` : ''}/find/${encodeURIComponent(searchCopy.value.toString())}`;
    const response = await axios.get<Customer[][]>(url);

    return response.data.flatMap((customer) => customer);
  },
  enabled: !!search,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
});

// eslint-disable-next-line max-len
export const getSMSRepliesQuery = (search: { yesSearch: true } | { search: string } | undefined): UseQueryOptions<GetSMSMessagesResponseMessagesInner[]> => ({
  queryKey: ['smsReplies', search],
  queryFn: async () => {
    if (!search) return [];
    const response = await activityhelperApi.apiSmsRepliesGet({ query: search });
    return response.data.messages;
  },
  enabled: !!search,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchInterval: 5 * 60 * 1000,
});

export const getSMSCustomerMessagesQuery = (customerId: number | undefined): UseQueryOptions<GetSMSMessagesResponseMessagesInner[]> => ({
  queryKey: ['smsCustomerMessages', customerId],
  queryFn: async () => {
    if (!customerId) return [];
    const response = await activityhelperApi.apiSmsCustomerCustomerIdMessagesGet({ customerId: customerId.toString() });
    return response.data.messages;
  },
  enabled: !!customerId,
  refetchOnMount: false,
});

export const customerLocksQuery: UseQueryOptions<GetCRMCustomerLocksResponse> = ({
  queryKey: ['customerLocks'],
  queryFn: async () => {
    const response = await activityhelperApi.apiCRMCustomerLocksGet();
    return response.data;
  },
});
