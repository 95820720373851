import type { UseQueryOptions } from '@tanstack/react-query';
import axios from 'axios';
import type {
  AccessLevel,
  Customer,
  OrderDetail,
  OrderDetailPropertyType,
  Product, User,
} from '../../CPpro/model/model.js';
import type {
  CompleteSalesOrder,
  CompleteSalesOrderRequest,
  OrderData, SalesData, SalesOrder,
} from '../types.js';
import type { GetOrderSearchRequest, GetOrderSearchResponse, GetOrdersOrderNameResponseInner } from '../../generated/cppro/api.js';
import { cpproApi } from '../apis/apis.js';

interface CPProQueries {
  getOrdersOrderNameQuery(ids: number[]): UseQueryOptions<GetOrdersOrderNameResponseInner[]>;
  getSearchOrdersQuery(query: GetOrderSearchRequest | undefined): UseQueryOptions<GetOrderSearchResponse>;
}

export const getOrdersOrderNameQuery: CPProQueries['getOrdersOrderNameQuery'] = (ids) => ({
  queryKey: ['orders-orderName', ids],
  queryFn: async () => {
    const { data } = await cpproApi.apiOrdersOrderNamePost({ getOrdersOrderNameRequest: ids });
    return data;
  },
  enabled: !!ids.length,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
});

export const getSearchOrdersQuery: CPProQueries['getSearchOrdersQuery'] = (query) => ({
  queryKey: ['search-orders', query],
  queryFn: async () => {
    const { data } = await cpproApi.apiOrderSearchGet({ query });
    return data;
  },
  enabled: !!query,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
});

export const getAccessLevelsQueryFn = async (): Promise<AccessLevel[]> => {
  const { data } = await axios.get<AccessLevel[][]>('/accesslevels');

  return data.flatMap((a) => a);
};

export const accessLevelsQuery: UseQueryOptions<AccessLevel[]> = {
  queryKey: ['accessLevels'],
  queryFn: getAccessLevelsQueryFn,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
};

export const getCompleteSalesOrdersQuery = (request: CompleteSalesOrderRequest): UseQueryOptions<CompleteSalesOrder[]> => ({
  queryKey: ['completeSalesOrders', request],
  queryFn: async (): Promise<CompleteSalesOrder[]> => {
    // const { data } = await cpproApi.salesOverviewCompleteSalesOrdersGet({ params: request });
    const { data } = await axios.get<CompleteSalesOrder[]>('/salesOverview/completeSalesOrders', { params: request });

    return data;
  },
  refetchOnMount: false,
  refetchOnWindowFocus: false,
});

export const salesDataQuery: UseQueryOptions<SalesData[]> = {
  queryKey: ['salesData'],
  queryFn: async (): Promise<SalesData[]> => {
    const { data } = await axios.get<SalesData[][]>('/salesdata');

    return data.flatMap((s) => s);
  },
  refetchOnMount: false,
  refetchOnWindowFocus: false,
};

export const orderDataQuery: UseQueryOptions<OrderData[]> = {
  queryKey: ['orderData'],
  queryFn: async (): Promise<OrderData[]> => {
    const { data } = await axios.get<OrderData[][]>('/orderdata');

    return data.flatMap((o) => o);
  },
  refetchOnMount: false,
  refetchOnWindowFocus: false,
};

export const salesOrdersQuery: UseQueryOptions<SalesOrder[]> = {
  queryKey: ['salesOrders'],
  queryFn: async (): Promise<SalesOrder[]> => {
    const { data } = await axios.get<SalesOrder[][]>('/salesorders');

    return data.flatMap((o) => o);
  },
  refetchOnMount: false,
  refetchOnWindowFocus: false,
};

export const productsQuery: UseQueryOptions<Product[]> = {
  queryKey: ['products'],
  queryFn: async (): Promise<Product[]> => {
    const { data } = await axios.get<Product[][]>('/products');

    return data.flatMap((p) => p);
  },
  refetchOnMount: false,
  refetchOnWindowFocus: false,
};

export const usersQuery: UseQueryOptions<User[]> = {
  queryKey: ['users'],
  queryFn: async (): Promise<User[]> => {
    const { data } = await axios.get<User[][]>('/users');

    return data.flatMap((u) => u);
  },
  refetchOnMount: false,
  refetchOnWindowFocus: false,
};

export const userQueryFn = async (): Promise<User | undefined> => {
  const { data } = await axios.get<User[][]>('/useremail');

  if (!data || !data[0] || !data[0][0]) return undefined;

  return data.flatMap((u) => u).at(0);
};

export const userQuery: UseQueryOptions<User | undefined> = {
  queryKey: ['user'],
  queryFn: userQueryFn,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
};

const isDev = import.meta.env.MODE === 'development' || import.meta.env.MODE === 'test';

export const customersQuery: UseQueryOptions<Customer[]> = {
  queryKey: ['customers'],
  queryFn: async () => {
    const response = await axios.get<Customer[][]>('/customers');

    return response.data.flatMap((customer) => customer);
  },
  enabled: isDev,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
};

export const getOrderDetailSearchQuery = (search: {
  value: string,
  property?: OrderDetailPropertyType
} | undefined): UseQueryOptions<OrderDetail[]> => ({
  queryKey: ['orderDetail', search],
  queryFn: async () => {
    if (!search || !search.value) return [];

    const url = `/orderdetails/${search.property ? `parameter/${search.property}` : ''}/find/${encodeURIComponent(search.value)}`;
    const response = await axios.get<OrderDetail[][]>(url);

    return response.data.flatMap((orderDetail) => orderDetail);
  },
  enabled: !!search,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
});
