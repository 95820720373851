import React, { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { OrderLinePropertyType } from '../../../../../CPpro/model/model.js';
import { CPProUtils } from '../../../../../sharedutils/index.js';
import GenericTable from '../../../table/GenericTable.js';
import type { GetCustomerResponseCustomerDeliveredProductsInnerServicesInner } from '../../../../../generated/cppro/api.js';

type Props = {
  orderLines: GetCustomerResponseCustomerDeliveredProductsInnerServicesInner[];
};

type OrderLine = Record<Uncapitalize<keyof typeof OrderLinePropertyType>, string | number | undefined | null>;

const columnHelper = createColumnHelper<OrderLine>();

const calcSum = (line: OrderLine) => {
  const price = line.price ? Number(line.price) : 0;
  const discount = line.discount ? Number(line.discount) : 0;
  const vAT = line.vAT ? Number(line.vAT) : 0;
  return (price * (1 - discount / 100) * (1 + vAT / 100)).toFixed(2);
};

const columns = [
  columnHelper.accessor((row) => row.productId, {
    header: 'Product ID',
    id: 'productId',
  }),
  columnHelper.accessor((row) => row.description, {
    header: 'Description',
    id: 'description',
  }),
  columnHelper.accessor((row) => row.text, {
    header: 'Text',
    id: 'text',
  }),
  columnHelper.accessor((row) => row.qtyOrdered, {
    header: 'QTY Ordered',
    id: 'qtyOrdered',
  }),
  columnHelper.accessor((row) => row.qtyDelivered, {
    header: 'QTY Delivered',
    id: 'qtyDelivered',
  }),
  columnHelper.accessor((row) => row.price, {
    header: 'Price',
    id: 'price',
  }),
  columnHelper.accessor((row) => row.discount, {
    header: 'Discount',
    id: 'discount',
  }),
  columnHelper.accessor((row) => row.vAT, {
    header: 'VAT',
    id: 'vat',
  }),
  columnHelper.display({
    header: 'Sum inc VAT',
    id: 'sumIncVat',
    cell: (ctx) => calcSum(ctx.row.original),
  }),
];

const OrderLineTable = (props: Props) => {
  const { orderLines } = props;

  const mapped = useMemo<OrderLine[]>(() => {
    const lines = orderLines.map((l) => {
      const map = CPProUtils.mapTypeWithProperty(l, OrderLinePropertyType);

      return {
        productId: map.get(OrderLinePropertyType.ProductId),
        description: map.get(OrderLinePropertyType.Description),
        text: map.get(OrderLinePropertyType.Text),
        qtyOrdered: map.get(OrderLinePropertyType.QtyOrdered),
        qtyDelivered: map.get(OrderLinePropertyType.QtyDelivered),
        price: map.get(OrderLinePropertyType.Price),
        discount: map.get(OrderLinePropertyType.Discount),
        vAT: map.get(OrderLinePropertyType.VAT),
      };
    });

    return lines;
  }, [orderLines]);

  return (
    <GenericTable size="small" columns={columns} data={mapped} />
  );
};

export default OrderLineTable;
