import {
  Dialog, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Select,
} from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import ConstantAddForm from './ConstantAddForm.js';
import { ConstantMutations } from '../../../mutations/index.js';
import { ConstantQueries } from '../../../queries/index.js';
import type { GetConstantsResponseRecordsInnerProps, PostConstantRequestTypeEnum } from '../../../../generated/activityhelper/api.js';

type Props = {
  open: boolean,
  onClose: () => void;
  addVersion?: { key: string, type: PostConstantRequestTypeEnum | undefined };
  expectedRecords: Map<string, GetConstantsResponseRecordsInnerProps>;
};

const ConstantAddDialog = (props: Props) => {
  const {
    open, onClose, addVersion, expectedRecords,
  } = props;
  const queryClient = useQueryClient();
  const addMutation = useMutation({
    ...ConstantMutations.addConstantMutation,
    onSuccess: () => {
      queryClient.invalidateQueries(ConstantQueries.constantsQuery);
      onClose();
    },
  });
  const addVersionMutation = useMutation({
    ...ConstantMutations.addConstantVersionMutation,
    onSuccess: () => {
      queryClient.invalidateQueries(ConstantQueries.constantsQuery);
      onClose();
    },
  });

  const [selectedKey, setSelectedKey] = React.useState('');
  const defaultConstantData = useMemo(() => {
    if (addVersion) return { key: addVersion.key, type: addVersion.type ?? 'STRING', value: '' };
    if (selectedKey) {
      return { key: selectedKey, value: '', type: expectedRecords.get(selectedKey)?.type as PostConstantRequestTypeEnum };
    }
    return undefined;
  }, [addVersion, expectedRecords, selectedKey]);

  const expectedProperties = useMemo(() => {
    if (addVersion) {
      const record = expectedRecords.get(addVersion.key);
      if (record && 'dynamicProperties' in record) return record.dynamicProperties ?? [];
    }
    if (selectedKey) {
      const record = expectedRecords.get(selectedKey);
      if (record && 'dynamicProperties' in record) return record.dynamicProperties ?? [];
    }
    return [];
  }, [addVersion, expectedRecords, selectedKey]);

  useEffect(() => {
    if (open) setSelectedKey('');
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        Legg til ny konstant
        {addVersion ? ' versjon' : ''}
      </DialogTitle>
      <DialogContent>
        {!addVersion && expectedRecords.size ? (
          <FormControl sx={{ marginY: 2 }}>
            <InputLabel id="select_constantkey_label" shrink sx={{ backgroundColor: 'white' }}>
              Velg forventa konstant-nøkkel
            </InputLabel>
            <Select
              label="Velg forventa konstant-nøkkel"
              labelId="select_constantkey_label"
              value={selectedKey}
              onChange={(e) => setSelectedKey(e.target.value)}
              sx={{ width: 300 }}
              displayEmpty
              fullWidth
            >
              {expectedRecords.keys().toArray().map((key) => <MenuItem key={key} value={key}>{key}</MenuItem>)}
            </Select>
          </FormControl>
        ) : null}
        <ConstantAddForm
          onSubmit={(data) => {
            if (addVersion) {
              addVersionMutation.mutateAsync({ key: data.key, postConstantRequest: data });
            } else {
              addMutation.mutateAsync(data);
            }
          }}
          lockKey={!!addVersion}
          defaultData={defaultConstantData}
          expectedProperties={expectedProperties}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ConstantAddDialog;
