import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

interface CRMPersistState {
  showServiceTools: boolean;
  toggleShowServiceTools: () => void;
  showOrderDetailTable: boolean;
  toggleShowOrderDetailTable: () => void;
}

export const useCRMPersistStore = create<CRMPersistState>()(
  devtools(
    persist(
      (set) => ({
        showServiceTools: true,
        toggleShowServiceTools: () => set((state) => ({ showServiceTools: !state.showServiceTools })),
        showOrderDetailTable: true,
        toggleShowOrderDetailTable: () => set((state) => ({ showOrderDetailTable: !state.showOrderDetailTable })),
      }),
      { name: 'crmStore' },
    ),
  ),
);

export default useCRMPersistStore;
