import React, { useCallback, useEffect } from 'react';
import {
  Backdrop,
  Box, Button, CircularProgress, Collapse, Grid2, Paper, Typography,
} from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router';
import { useQueryClient } from '@tanstack/react-query';
import CallListTable from './callList/CallListTable.js';
import CRMCustomersList from './CRMCustomersList.js';
import CRMContactLog from './CRMContactLog.js';
import { CRMQueries } from '../../../queries/index.js';
import useSearchQuery from '../../../hooks/useSearchQuery.js';
import CRMCustomerInfo from './CRMCustomerInfo.js';
import OrderDetailList from './orders/OrderDetailList.js';
import OrderDetailTable from './tables/OrderDetailTable.js';
import OrderLineTable from './tables/OrderLineTable.js';
import type { GetCustomerResponseCustomerOrderDetailsInner } from '../../../../generated/cppro/api.js';
import CRMServiceTools from './CRMServiceTools.js';
import type { Customer, CustomerPropertyType } from '../../../../CPpro/model/model.js';
import CRMCustomerSearchDialog from './dialogs/CRMCustomerSearchDialog.js';
import useCRMPersistStore from '../../../stores/crmPersistStore.js';
import CPproDataProvider from '../../../providers/CPproDataProvider.js';
import type { CallListQueryParams } from '../../../queries/crmQueries.js';
import useCRMStore from '../../../stores/crmStore.js';

const defaultCalllistData: CallListQueryParams = undefined as CallListQueryParams;

const defaultSearchData = undefined as { value: string, property?: CustomerPropertyType } | undefined;

const isDev = import.meta.env.MODE === 'development' || import.meta.env.MODE === 'test';

const CRMPage = () => {
  const queryClient = useQueryClient();
  // Navigation
  const navigate = useNavigate();
  const { customerId } = useParams();

  // Queries
  const { queryData, refetch, query: customerQuery } = useSearchQuery(CRMQueries.getCustomerQuery, undefined as number | undefined);
  const { data: customer } = queryData;
  const {
    queryData: customerSearchData, refetch: searchCustomer,
  } = useSearchQuery(CRMQueries.getCustomerSearchQuery, defaultSearchData);
  const { data: customerSearchResult, isLoading } = customerSearchData;
  const {
    queryData: callListQueryData, refetch: searchCallList,
    query: callListQuery,
  } = useSearchQuery(CRMQueries.getCallListQuery, defaultCalllistData);
  const { data: callListData, isLoading: callListLoading } = callListQueryData;

  // States
  const [showCustomerSearchDialog, setShowCustomerSearchDialog] = React.useState(false);
  const [selectedOrder, setSelectedOrder] = React.useState<GetCustomerResponseCustomerOrderDetailsInner | undefined>(undefined);

  // Store
  const showServiceTools = useCRMPersistStore((state) => state.showServiceTools);
  const toggleShowServiceTools = useCRMPersistStore((state) => state.toggleShowServiceTools);
  const showOrderDetailTable = useCRMPersistStore((state) => state.showOrderDetailTable);
  const toggleShowOrderDetailTable = useCRMPersistStore((state) => state.toggleShowOrderDetailTable);
  const isBackdropActive = useCRMStore((state) => state.isBackdropActive);

  // UseEffects
  useEffect(() => {
    const num = Number(customerId);
    if (customerId && !Number.isNaN(num)) {
      refetch(num);
    } else {
      setSelectedOrder(undefined);
      refetch(undefined);
    }
  }, [customerId, refetch]);

  useEffect(() => {
    if (customerSearchResult) {
      if (customerSearchResult.length === 1) navigate(`/crm/customer/${customerSearchResult[0].id}`);
      else {
        setShowCustomerSearchDialog(true);
      }
    }
  }, [customerSearchResult, navigate]);

  // Callbacks
  const onCustomerSearchSelect = useCallback((selectedCustomer: Customer | undefined, type: 'backdrop' | 'selectConfirm') => {
    if (type === 'backdrop') {
      return;
    }

    if (selectedCustomer) {
      navigate(`/crm/customer/${selectedCustomer.id}`);
    }
    setShowCustomerSearchDialog(false);
    searchCustomer(undefined); // reset search
  }, [navigate, searchCustomer]);

  const onCustomerSearch = useCallback((search: { value: string, property?: CustomerPropertyType } | undefined) => {
    if (!search || search.value === '' || search.value === undefined || search.value === null) {
      return; // do nothing
    }
    searchCustomer(search);
  }, [searchCustomer]);

  const refetchCustomer = useCallback(() => queryClient.invalidateQueries(customerQuery), [customerQuery, queryClient]);
  const refetchCallList = useCallback(() => queryClient.invalidateQueries(callListQuery), [callListQuery, queryClient]);

  return (
    <Box>
      <Grid2 container direction="row" alignItems="center">
        <Grid2>
          <Button component={Link} to="/crm/areas">Vis poeng-oversikt over butikker</Button>
        </Grid2>
        <Grid2>
          <Button component={Link} to="/crm/sms/replies">Vis SMS-svar</Button>
        </Grid2>
      </Grid2>
      <Grid2 container direction="column" justifyContent="flex-start" spacing={1}>
        {isDev ? (
          <Grid2 size={12}>
            <CRMCustomersList onSearch={onCustomerSearch} loading={isLoading} />
          </Grid2>
        ) : null}
        <Grid2 size={12} container direction="row" justifyContent="center" spacing={2} sx={{ marginBottom: 2, height: 500 }}>
          <Grid2 size={3}>
            <Paper sx={{
              paddingY: 1, paddingX: 1, height: '100%', overflow: 'auto',
            }}
            >
              <CRMCustomerInfo
                customer={customer ?? undefined}
                onSearch={onCustomerSearch}
                refetchCustomer={() => (customerId ? refetchCustomer() : undefined)}
                selectedCalllistItems={[]}
              />
            </Paper>
          </Grid2>
          <Grid2 size={5}>
            <Paper sx={{
              paddingY: 1, paddingX: 1, height: '100%', overflow: 'auto',
            }}
            >
              <CRMContactLog
                customer={customer ?? undefined}
                onContactLogSubmit={() => (customerId
                  ? refetchCustomer() : undefined)}
              />
            </Paper>
          </Grid2>
          <Grid2 size={4}>
            <Paper sx={{
              paddingY: 1, paddingX: 1, height: '100%',
            }}
            >
              <OrderDetailList orders={customer?.orderDetails ?? []} onOrderClicked={(order) => setSelectedOrder(order)} />
            </Paper>
          </Grid2>
        </Grid2>
        <Grid2>
          <Paper sx={{ paddingY: 1, paddingX: 1 }}>
            <Typography
              variant="h5"
              gutterBottom
              onClick={() => toggleShowOrderDetailTable()}
              sx={{ cursor: 'pointer' }}
              title={`Click to ${showOrderDetailTable ? 'hide' : 'show'} orderdetails`}
            >
              Orderdetail
            </Typography>
            <Collapse in={showOrderDetailTable} timeout="auto">
              <OrderDetailTable order={selectedOrder} />
              <Box sx={{ margin: 1 }} />
              <OrderLineTable orderLines={selectedOrder?.orderLines ?? []} />
            </Collapse>
          </Paper>
        </Grid2>
        <Grid2>
          <Paper sx={{ paddingY: 1, paddingX: 1 }}>
            <CPproDataProvider>
              <Typography
                variant="h5"
                gutterBottom
                onClick={() => toggleShowServiceTools()}
                sx={{ cursor: 'pointer' }}
                title={`Click to ${showServiceTools ? 'hide' : 'show'} servicetools`}
              >
                Servicetools
              </Typography>
              <Collapse in={showServiceTools} timeout="auto">
                <CRMServiceTools
                  refetchCallList={refetchCallList}
                  refetchCustomer={refetchCustomer}
                  customer={customer ?? undefined}
                />
              </Collapse>
            </CPproDataProvider>
          </Paper>
        </Grid2>
        <Grid2>
          <Paper sx={{ paddingY: 1, paddingX: 1 }}>
            <Typography variant="h5" gutterBottom>Call list</Typography>
            <CallListTable
              search={searchCallList}
              refetch={refetchCallList}
              data={callListData ?? []}
              isLoading={callListLoading}
            />
          </Paper>
        </Grid2>
      </Grid2>
      <CRMCustomerSearchDialog
        open={showCustomerSearchDialog}
        customers={customerSearchResult ?? []}
        onSelect={onCustomerSearchSelect}
      />
      <Backdrop open={isBackdropActive} sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default CRMPage;
