import React from 'react';
import { Link, useNavigate } from 'react-router';
import type { SortingState } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import {
  Box, Button, IconButton, Tooltip, Typography,
} from '@mui/material';
import { AccountCircle, ArrowBack, DoneAll } from '@mui/icons-material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useSearchQuery from '../../../../hooks/useSearchQuery.js';
import { CRMQueries } from '../../../../queries/index.js';
import type { GetSMSMessagesResponseMessagesInner } from '../../../../../generated/activityhelper/api.js';
import { DateUtils } from '../../../../../sharedutils/index.js';
import useCRMStore from '../../../../stores/crmStore.js';
import GenericTable from '../../../table/GenericTable.js';
import { CRMMutations } from '../../../../mutations/index.js';

const defaultSearch = { yesSearch: true } as {
  yesSearch: true;
} | {
  search: string;
} | undefined;

const columnHelper = createColumnHelper<GetSMSMessagesResponseMessagesInner>();

type CustomMeta = {
  handleClick: (params: { smsId: number }) => unknown | Promise<unknown>
  navigate: (url: string) => void;
};

// TODO Show locks for customer here as well

const columns = [
  columnHelper.accessor((a) => a.to, {
    header: 'Phone',
    id: 'to',
  }),
  columnHelper.accessor((a) => a.message, {
    header: 'Message',
    id: 'message',
  }),
  columnHelper.accessor((a) => a.sent, {
    header: 'Sent',
    id: 'sent',
    sortingFn: 'datetime',
    cell: (info) => DateUtils.getLocalizedDateTime(info.getValue()),
  }),
  columnHelper.accessor((a) => a.type, {
    header: 'Type',
    id: 'type',
  }),
  columnHelper.accessor((a) => a.replyMessage, {
    header: 'Reply',
    id: 'replyMessage',
  }),
  columnHelper.accessor((a) => a.replyDate, {
    header: 'Reply date',
    id: 'replyDate',
    sortingFn: 'datetime',
    cell: (info) => DateUtils.getLocalizedDateTime(info.getValue()),
  }),
  columnHelper.accessor((a) => a.customerId, {
    header: 'Customer ID',
    id: 'customerId',
  }),
  columnHelper.display({
    header: 'Actions',
    id: 'actions',
    cell: (info) => (
      <>
        <Tooltip title="Mark as handled (removes from list)">
          <IconButton color="success" onClick={() => (info.table.options.meta?.custom as CustomMeta)?.handleClick({ smsId: info.row.original.id })}>
            <DoneAll />
          </IconButton>
        </Tooltip>
        <Tooltip title="View customer">
          <IconButton color="warning" onClick={() => (info.table.options.meta?.custom as CustomMeta)?.navigate(`/crm/customer/${info.row.original.customerId}`)}>
            <AccountCircle />
          </IconButton>
        </Tooltip>
      </>
    ),
  }),
];

const initialSortingState: SortingState = [{ id: 'replyDate', desc: true }];

const CRMSMSRepliesPage = () => {
  const routeCustomer = useCRMStore((state) => state.routeCustomer);
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  // TODO Filter on type as well? (needs to include ex. "CRM")? frontend/backend?
  const { queryData, query } = useSearchQuery(CRMQueries.getSMSRepliesQuery, defaultSearch);
  const { data } = queryData;

  const { mutateAsync: handleClick } = useMutation({
    ...CRMMutations.handledSMSReplyMutation,
    onSuccess: () => queryClient.invalidateQueries(query),
  });

  return (
    <Box>
      <Button
        component={Link}
        to={routeCustomer ? `/crm/customer/${routeCustomer}` : '/crm'}
        startIcon={<ArrowBack />}
      >
        Tilbake
      </Button>
      <Typography variant="h5" gutterBottom>Send SMS</Typography>
      <GenericTable
        customMeta={{ handleClick, navigate } satisfies CustomMeta}
        id="smsreplies"
        data={data ?? []}
        columns={columns}
        initialSortingState={initialSortingState}
        pagination
      />
    </Box>
  );
};

export default CRMSMSRepliesPage;
