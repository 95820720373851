import type { Answer, Order, QuoteProperty } from '../CPpro/model/model.js';

export type JsonElement = Pick<Answer, 'id' | 'created'> & {
  properties: QuoteProperty[]
};

export type OrderData = Required<Omit<Answer, 'properties' | 'file'>> & {
  json: Array<JsonElement>
};

export type SalesData = OrderData;

export type SalesOrder = Omit<Order, 'item' | 'properties'> & { items: Array<{ id: number }>, properties: QuoteProperty[] };

export type DrizzleProperty = {
  id: number,
  parent_id: number,
  created: string,
  deleted: string,
  PropertyType_id: number,
  strvalue: string | null,
  numvalue: number | null,
};

export type DrizzleAnswer = {
  id: number,
  parent_id: number,
  created: string,
  checklist_id: number,
  seq_no: number,
  properties: DrizzleProperty[],
};

export type DrizzleItem = {
  id: number,
  answers: DrizzleAnswer[],
};

export type DrizzeOrder = {
  id: number,
  parent_id: number | null,
  created: string,
  deleted: string,
  items: DrizzleItem[],
  properties: DrizzleProperty[],
};

// TODO Instead of overloading existing types, use omit/pick to generate new ones!!!
export type CompleteSalesOrder = DrizzeOrder;

export type CompleteSalesOrderRequest = {
  to?: Date | string,
  from?: Date | string,
  orderStatus?: string,
  page?: number,
  size?: number
};

// OB = Ordrebekreftelse (solgt)
// TS = Tilbud Sendt
// NB = Ny befaring
export const saleTypes = ['OB', 'TS', 'Tapt'] as const;
type SaleType = typeof saleTypes[number];
// Tapt salg = svar på eit spørsmål og ikkje ein "status"???

export const statusToString = (status: SalesRow['status']) => {
  switch (status) {
    case 'OB':
      return 'Salg';
    case 'TS':
      return 'Tilbud';
    default:
      return status || 'Ukjent';
  }
};

export const DEFAULT_SALESROW_ORDERLINES_LENGTH = 10;

export type SalesRowRequired = {
  id: number,
  status: SaleType,
  date: Date,
  customerName: string,
  customerPhone: string,
  adviser: string,
  orderName: string, // Namn på ordren bruker for å linke til ordre
};

export type OrderLines = {
  orderLine_1?: string,
  orderLine_2?: string,
  orderLine_3?: string,
  orderLine_4?: string,
  orderLine_5?: string,
  orderLine_6?: string,
  orderLine_7?: string,
  orderLine_8?: string,
  orderLine_9?: string,
  orderLine_10?: string,
};

export type SalesRowExtended = OrderLines & {
  quoteLink?: string, // Mapper ordre med fleire tilbudslinker til mange salesrow
  originalPrice?: number, // veil. pris ink. tillegg
  price?: number,
  mamutOrderNr?: number | string,
  sevenOfficeOrderId?: number,
  db?: string,
  dg?: string,
  totalOrderLines?: number,
  pipeMetersAdviser?: number,
  pipeMetersInstaller?: number,
  pipeDifference?: number,
  resursBank?: boolean,
  saleRating?: number | string,
  saleRatingAnsweredBy?: string,
  customerRating?: number,
  notesForInstaller?: string,
  pumpChange?: string,
};

export const getSalesRowOrderLines = (data: string[]): OrderLines => {
  const orderLines: OrderLines = {};

  Array.from({ length: DEFAULT_SALESROW_ORDERLINES_LENGTH }).forEach((_, i) => {
    const key = `orderLine_${i + 1}`;
    if (data.length > i) {
      orderLines[key] = data[i];
    } else {
      orderLines[key] = undefined;
    }
  });

  return orderLines;
};

export type SalesRow = SalesRowRequired & SalesRowExtended;

// OLD Types
export type SalesQuote = {
  orderId: number,
  date?: string | Date,
  customerName?: string,
  department?: string,
  order: string,
  status?: string,
};

export type SalesProduct = {
  name: string,
  price?: number | string,
  discount?: number,
  costAfterDiscount?: number,
  originalCost?: number,
  quantity?: number,
};

export type OldSalesOrder = {
  id: number,
  name: string,
  products: SalesProduct[],
  totalPrice?: number,
  totalPriceAfterDiscount?: number,
  mamutOrder?: string,
};

export type SalesMamutOrder = {
  id: number,
  name: string,
  salesJobScore?: number | string,
  salesJobAnsweredBy?: string,
};

// #region HourReport

export const HourReportStatus = ['Inn', 'Ut', 'Ut Overtid'] as const;
export type LastHourReportStatus = typeof HourReportStatus[number];

export type HourReport = {
  email: string,
  type: string,
  absenceType: string,
  childBirthdate: string,
  date: string,
  /**
   * ISO Date
   */
  created: string,
  from: string,
  to: string,
  notes: string,
  finished: string,
};

export type HourReportOptions = {
  from?: string,
  to?: string,
  page?: number,
  size?: number,
};

// #endregion
