import React, { useEffect, useMemo } from 'react';
import {
  Box, Button, Collapse, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack,
} from '@mui/material';
import { ExpandLess, ExpandMore, HorizontalRule } from '@mui/icons-material';
import type { CustomerWithData } from '../../../../../generated/cppro/api.js';
import { CPProUtils, DateUtils } from '../../../../../sharedutils/index.js';
import { OrderDetailPropertyType, OrderLinePropertyType } from '../../../../../CPpro/model/model.js';
import { getCRMStatusName, getPropertyValue } from '../../../../utils/crmUtils.js';

type Props = {
  orders: CustomerWithData['orderDetails'],
  onOrderClicked: (order: CustomerWithData['orderDetails'][number]) => void
};

const calcSum = (map: CPProUtils.MapTypeWithPropertyResult<OrderDetailPropertyType>) => {
  const sum = map.get(OrderDetailPropertyType.Sum) || 0;
  const vAT = map.get(OrderDetailPropertyType.VAT) || 0;
  const total = (Number(sum) * (1 + Number(vAT) / 100)).toFixed(2);
  return total;
};

const OrderListItem = (params: {
  order: CustomerWithData['orderDetails'][number],
  onClick: (order: CustomerWithData['orderDetails'][number]) => void,
  onCollapse: (id: number) => void,
  collapseStates: { id: number, open: boolean }[],
}) => {
  const {
    order, onClick, onCollapse, collapseStates,
  } = params;

  const orderMap = useMemo(() => CPProUtils.mapTypeWithProperty(order, OrderDetailPropertyType), [order]);
  const state = useMemo(() => collapseStates.find((c) => c.id === order.id)?.open, [collapseStates, order]);
  const orderLines = useMemo(() => order.orderLines.map((l) => ({ ...l, map: CPProUtils.mapTypeWithProperty(l, OrderLinePropertyType) })), [order]);

  const orderDate = useMemo(() => {
    const invoiceDate = orderMap.get(OrderDetailPropertyType.InvoiceDate);
    const date = orderMap.get(OrderDetailPropertyType.OrderDate);
    return DateUtils.getDayjs(invoiceDate) ?? DateUtils.getDayjs(date) ?? DateUtils.getDayjs(order.created) ?? null;
  }, [order.created, orderMap]);

  return (
    <>
      <ListItem
        title="Show details"
        disablePadding
        secondaryAction={(
          <IconButton onClick={() => onCollapse(order.id)}>
            {state ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
    )}
      >
        <ListItemButton onClick={() => onClick(order)}>
          <ListItemIcon>
            <HorizontalRule />
          </ListItemIcon>
          <ListItemText>
            {`Order ${orderMap.get(OrderDetailPropertyType.OrderId)} `}
            <b>{orderDate?.format('DD/MM/YYYY')}</b>
            {`, ${calcSum(orderMap)} ${getCRMStatusName(orderMap.get(OrderDetailPropertyType.Status)) ?? ''} ${orderMap.get(OrderDetailPropertyType.Reference) ?? ''}`.trim()}
          </ListItemText>
        </ListItemButton>
      </ListItem>
      <Collapse in={state} timeout="auto" unmountOnExit>
        <List dense component="div" disablePadding>
          {orderLines.map((l) => (
            <ListItem key={l.id} sx={{ paddingLeft: 4 }}>
              <ListItemText inset>
                {l.map.get(OrderLinePropertyType.Description) ?? ''}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
};

const OrderDetailList = (props: Props) => {
  const { orders, onOrderClicked } = props;

  // If it has, sort by invoicedate, then orderdate, then created date
  // TODO Sort by order date property instead?
  const sortedOrders = useMemo(() => {
    const sorted = orders.toSorted((a, b) => {
      const aInvoiceDate = getPropertyValue(a, OrderDetailPropertyType.InvoiceDate);
      const bInvoiceDate = getPropertyValue(b, OrderDetailPropertyType.InvoiceDate);
      const aOrderDate = getPropertyValue(a, OrderDetailPropertyType.OrderDate);
      const bOrderDate = getPropertyValue(b, OrderDetailPropertyType.OrderDate);

      const aDayjs = DateUtils.getUTCDayjs(aInvoiceDate) ?? DateUtils.getUTCDayjs(aOrderDate) ?? DateUtils.getUTCDayjs(a.created) ?? null;
      const bDayjs = DateUtils.getUTCDayjs(bInvoiceDate) ?? DateUtils.getUTCDayjs(bOrderDate) ?? DateUtils.getUTCDayjs(b.created) ?? null;

      return DateUtils.dayjsSort(aDayjs, bDayjs);
    });
    return sorted;
  }, [orders]);
  const [collapseStates, setCollapseStates] = React.useState<{ id: number, open: boolean }[]>([]);

  useEffect(() => {
    setCollapseStates(sortedOrders.map((order) => ({ id: order.id, open: false })));
  }, [sortedOrders]);

  const onCollapse = (id: number) => {
    setCollapseStates((val) => val.map((c) => (c.id === id ? { ...c, open: !c.open } : c)));
  };

  const onExpandAll = () => {
    setCollapseStates((val) => val.map((c) => ({ ...c, open: true })));
  };

  const onCollapseAll = () => {
    setCollapseStates((val) => val.map((c) => ({ ...c, open: false })));
  };

  if (!sortedOrders.length) return null;

  return (
    <Box>
      <Stack direction="row" justifyContent="center" spacing={1}>
        <Button size="small" onClick={onExpandAll}>Utvid alle</Button>
        <Button size="small" onClick={onCollapseAll}>Lukk alle</Button>
      </Stack>
      <Box sx={{ overflow: 'auto', height: 440 }}>
        <List dense>
          {sortedOrders.map((o) => (
            <OrderListItem
              key={o.id}
              order={o}
              onClick={onOrderClicked}
              onCollapse={onCollapse}
              collapseStates={collapseStates}
            />
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default OrderDetailList;
