import React from 'react';
import type { SortingState } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import type { CustomerContactLog } from '../../../../../generated/cppro/api.js';
import { DateUtils } from '../../../../../sharedutils/index.js';
import GenericTable from '../../../table/GenericTable.js';
import ContactLogTableFilter from './ContactLogTableFilter.js';

const columnHelper = createColumnHelper<CustomerContactLog>();
const columns = [
  columnHelper.accessor((a) => a.when, {
    header: 'When',
    id: 'when',
    cell: (info) => DateUtils.getLocalizedDateTime(info.getValue()),
    filterFn: 'isWithinRange',
    sortingFn: 'datetime',
  }),
  columnHelper.accessor((a) => a.what, {
    header: 'What',
    id: 'what',
  }),
  columnHelper.accessor((a) => a.whoFrom, {
    header: 'Who/From',
    id: 'whoFrom',
  }),
  columnHelper.accessor((a) => a.message, {
    header: 'Message',
    id: 'message',
  }),
];

type Props = {
  contactLogs: CustomerContactLog[],
};

const defaultSortingState: SortingState = [{ id: 'when', desc: true }];

const ContactLogTable = (props: Props) => {
  const { contactLogs } = props;
  return (
    <GenericTable
      Filter={ContactLogTableFilter}
      id="contactLogs"
      size="small"
      columns={columns}
      data={contactLogs}
      initialSortingState={defaultSortingState}
    />
  );
};

export default ContactLogTable;
