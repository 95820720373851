import { createContext, useContext } from 'react';
import type { User } from '../../CPpro/model/model.js';

export interface ICPproDataContext {
  users: User[],
  refreshUsers(): void,
  // isLoading: boolean,
}

const defaultCPproDataContext: ICPproDataContext = {
  users: [],
  refreshUsers: () => {},
  // isLoading: false,
};

const CPproDataContext = createContext<ICPproDataContext>(defaultCPproDataContext);

export default CPproDataContext;

export const useCPproDataContext = () => useContext(CPproDataContext);
