import type { UseQueryOptions } from '@tanstack/react-query';
import { activityhelperApi } from '../apis/apis.js';
import type { GetConstantResponseConstant, GetConstantsResponse, GetConstantsResponseRecordsInnerProps } from '../../generated/activityhelper/api.js';
import type { LatestConstant } from '../types/constantTypes.js';
import { removeNulls } from '../../CPpro/Utils/tools.js';

export const constantsQuery: UseQueryOptions<{
  constants: LatestConstant[];
  records: Map<string, GetConstantsResponseRecordsInnerProps>;
  expectedKeys: string[];
  expectedRecords: Map<string, GetConstantsResponseRecordsInnerProps>;
} & Pick<GetConstantsResponse, 'keys'>> = {
  queryKey: ['constants'],
  queryFn: async () => {
    const { data } = await activityhelperApi.apiConstantGet({ });

    // map:
    const grouped = Map.groupBy(data.constants, (c) => c.key);
    const mapped: LatestConstant[] = grouped.values().toArray().map((v) => {
      const sortedByVersion = v.toSorted((a, b) => b.version - a.version); // desc
      if (sortedByVersion.length === 0) return null;
      const firstNotDisabled = sortedByVersion.find((c) => !c.disabledAt);
      return { latest: firstNotDisabled ?? sortedByVersion[0], versions: sortedByVersion };
    }).filter(removeNulls);

    const recordMap = new Map(data.records.map((r) => ([r.key, r.props])));
    const expectedRecords = new Map(data.records.filter((r) => !mapped.some((c) => c.latest.key === r.key)).map((r) => ([r.key, r.props])));

    return {
      constants: mapped,
      keys: data.keys,
      expectedKeys: data.keys.filter((k) => !mapped.some((c) => c.latest.key === k)),
      records: recordMap,
      expectedRecords,
    };
  },
};

export const getConstantQuery = (key: string): UseQueryOptions<GetConstantResponseConstant> => ({
  queryKey: ['constant', key],
  queryFn: async () => {
    const { data } = await activityhelperApi.apiConstantKeyGet({ key });

    return data.constant;
  },
});
