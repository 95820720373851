import ChecklistUtils from '../checklist/checklistutils.js';

export class CustomArray<T> extends Array<T> {
  private hasNewId(obj: unknown): boolean {
    if (typeof obj !== 'object' || obj === null) return false;

    if ('id' in obj && (obj as { id: number }).id <= 0) return true;

    for (const value of Object.values(obj)) {
      if (this.hasNewId(value)) return true;
    }
    return false;
  }

  push(...items: T[]): number {
    const item = super.push(...items);
    const dataReady = items.some((i) => this.hasNewId(i));
    if (dataReady) {
      const utils = new ChecklistUtils();
      utils.syncData();
    }
    return item;
  }

  assign(newValues: T[]): void {
    this.length = 0;
    this.push(...newValues);
  }

  splice(start: number, deleteCount?: number, ...items: T[]): T[] {
    let item;
    if (deleteCount === undefined) {
      item = super.splice(start);
    } else {
      item = super.splice(start, deleteCount, ...items);
    }
    if (items.some((i) => this.hasNewId(i))) {
      const utils = new ChecklistUtils();
      utils.syncData();
    }
    return item;
  }
}

export enum OrderPropertyType {
  Name = 1,
  CSS = 2,
  Organization = 3,
  ERPorderid = 4,
  Checklist = 5,
  Status = 6,
  Summary = 7,
  CustomerName = 8,
  CustomerPhone = 9,
  CustomerEmail = 10,
  CustomerAddress = 11,
  CustomerZip = 12,
  CustomerCity = 13,
  StemplingName = 14,
  StemplingDate = 15,
  OrderType = 16,
  DetailedOrderline = 17,
  DisableDuplicate = 18,
  SalesOrder = 19,
}

export enum ItemPropertyType {
  Deleted = 1,
  Product = 2,
  Checklist = 3,
  Question = 4,
  Name = 5,
  Orderline = 6,
  ProductQuestionRef = 7,
}

export enum ChecklistPropertyType {
  Name = 1,
  CSS = 2,
  PreRender = 3,
}

export enum QuestionPropertyType {
  Name = 1,
  CSS = 2,
  Category = 3,
  SequenceNumber = 4,
  PreRender = 5,
  PostRender = 6,
  OnAnswer = 7,
  Type = 8,
  Relevant = 9,
  WriteProtected = 10,
  NOR = 11,
  ENG = 12,
  Required = 13,
  OnChange = 14,
  Data = 15,
  SpecialHandling = 16,
}

export enum UserPropertyType {
  Name = 1,
  Email = 2,
  Accesslevel = 3,
  Group = 4,
  Signature = 5,
  Lastname = 6,
  Phone = 7,
  AccessRole = 8,
  Department = 9,
}

export enum AnswerPropertyType {
  Answer = 1,
  Timestamp = 2,
  AnsweredBy = 3,
  Qty = 4,
  CustomQuestionProperties = 5,
  Product = 6,
  Note = 7,
}

export enum ProductPropertyType {
  Name = 1,
  ERPprodid = 2,
  Stocklevel = 3,
  PriceExVAT = 4,
  CostExVAT = 5,
  VAT = 6,
  Picture = 7,
  Checklist = 8,
  Edit = 9,
  ProductType = 10,
  ProductSheet = 11,
  Unit = 12,
  QuestionReference = 14,
  Custom = 15,
  DeliveryTime = 16,
  MRP24SOProdid = 17,
  IndoorUnit = 18,
  SerialNumberPrefix = 19,
  SubCategory = 20,
  Description = 21,
  InternalNotes = 22,
  DepartmentStock = 23,
}

// The value of the ProductPropertType.Custom's value
export type ProductPropertyCustomValue = {
  text?: string,
  price?: string,
  discount?: string
};

export enum FilePropertyType {
  Name = 1,
  Type = 2,
  Link = 3,
  Thumbnail = 4,
}

export enum CustomerPropertyType {
  Name = 1,
  Email = 2,
  Phone = 3,
  ContactLog = 4,
  DeliveryAddress = 5,
  DeliveryZIP = 6,
  DeliveryCity = 7,
  InvoiceAddress = 8,
  InvoiceZIP = 9,
  InvoiceCity = 10,
  ServiceStatus = 11,
  ServiceInterval = 12,
  Score = 13,
  Lock = 14,
  CallCounter = 15,
  FollowUpDate = 16,
  ServicePlan = 17,
  ERPCustomerId = 18,
  ERPClient = 19,
}

export interface CustomerContactLog {
  when: string;
  what: string; // enum?
  whoFrom: string;
  message: string;
}

export enum LeadPropertyType {
  Name = 1,
  Email = 2,
  Phone = 3,
  Address = 4,
  ZIP = 5,
  Status = 6,
  AirToAir = 7,
  AirToWater = 8,
  WaterToWater = 9,
  Awning = 10,
  Service = 11,
  Repair = 12,
  Logentry = 13,
  Note = 14,
  ContactCounter = 15,
  Source = 16,
  Owner = 17,
  PartnerID = 18,
  Category = 19,
  City = 20,
}

export enum ServicePropertyType {
  Name = 1,
  Email = 2,
  Phone = 3,
  Status = 4,
  OrderId = 5,
  ServiceDate = 6,
  Type = 7,
  Score = 8,
}

export enum DeliveredProductPropertyType {
  Name = 1,
  Address = 2,
  Owner = 3,
  Type = 4,
  Model = 5,
  SerialNo = 6,
  MFGyear = 7,
  InstallDate = 8,
  ZIP = 9,
  City = 10,
  Status = 11,
  Score = 12,
  Accessibility = 13,
  InstallLocation = 14,
}

export enum WebhookPropertyType {
  Name = 1,
  CreatedBy = 2,
  CreatedDate = 3,
  CallbackURL = 4,
  IDofItemToMonitor = 5,
  TypeOfItemToMonitor = 6,
  CallBeforeRead = 7,
  CallBeforeCreate = 8,
  CallBeforeUpdate = 9,
  CallBeforeDelete = 10,
  CallAfterRead = 11,
  CallAfterCreate = 12,
  CallAfterUpdate = 13,
  CallAfterDelete = 14,
  MonitorUser = 15,
  MonitorChecklist = 16,
  MonitorQuestion = 17,
  MonitorAnswer = 18,
  MonitorFile = 19,
  MonitorService = 20,
  MonitorLead = 21,
  MonitorDeliveredProduct = 22,
  MonitorCustomer = 23,
}

export enum OrderDetailPropertyType {
  OrderId = 1,
  Status = 2,
  Reference = 3,
  OrderDate = 4,
  DeliveryDate = 5,
  PickDate = 6,
  ShipDate = 7,
  InvoiceDate = 8,
  Sum = 9,
  VAT = 10,
  Score = 11,
  Type = 12,
  ERPClient = 13,
}

export enum OrderLinePropertyType {
  ProductId = 1,
  Description = 2,
  Text = 3,
  QtyOrdered = 4,
  QtyDelivered = 5,
  Price = 6,
  Discount = 7,
  VAT = 8,
}

export enum QuestionType {
  Unknown = 0,
  Text = 1,
  Check = 2,
  Dropdown = 3,
  Info = 4,
  Header = 5,
  File = 6,
  HTMLlink = 7,
  HTMLlinks = 8,
  Date = 9,
  DateTime = 10,
  InstallPicture = 11,
  MultipleInstallPicture = 12,
  Email = 13,
  Signature = 14,
  Note = 15,
  Picture = 16,
  MultiplePicture = 17,
  Button = 18,
  Buttons = 19,
  YesNo = 20,
  Barcodescan = 21,
  Groupheading = 22,
  Orderheading = 23,
  YesNoMaybe = 24,
  FSM = 25,
  TableView = 26,
  LiveTable = 27,
  Approve = 28,
  HTMLnote = 29,
  ProductLine = 30,
  Number = 31,
}

export interface Dataset {
  orderid: string;
  itemid: string;
  productid: string;
  checklistid: string;
  checklist: string;
  questionid: string;
  seqno: string;
}
export interface PropertyType {
  id: number;
  type: string;
}

export interface Property {
  id: number;
  parent_id: number;
  type: number;
  value: string | number;
  _updated?: boolean;
  _delete?: boolean;
}

export interface Answer {
  id: number;
  created: number;
  parent_id: number;
  item_id: number;
  checklist_id: number;
  seq_no: number;
  properties: CustomArray<Property>;
  file?: FileList | never[];
}

export interface Question {
  _checklist?: string;
  _checklistid?: number;
  _itemid?: number;
  _product?: number;
  _seqno: number;
  id: number;
  parent_id?: number;
  _order_id?: number;
  properties: Property[];
  answer?: CustomArray<Answer>;
}

export interface Checklist {
  id: number;
  parent_id?: number;
  _orderid?: number;
  properties: Property[];
  question: Question[];
}

export interface User {
  id: number;
  properties: Property[];
}

export interface Order {
  id: number;
  parent_id?: number;
  properties: CustomArray<Property>;
  item: CustomArray<Item>;
}

export interface Item {
  id: number;
  parent_id: number;
  properties: CustomArray<Property>;
  product: CustomArray<Product>;
  _delete?: boolean;
}

export interface Webhook {
  id: number;
  properties: Property[];
}

export interface Customer {
  id: number;
  properties: Property[];
  /**
   * @deprecated Might not exist?
   */
  deliveredProduct?: DeliveredProduct[];
  /**
   * @deprecated Might not exist?
   */
  orderDetail?: OrderDetail[];
}

export interface DeliveredProduct {
  id: number;
  parent_id: number;
  properties: Property[];
  /**
   * @deprecated Might not exist?
   */
  service?: Service[];
}

export interface Product {
  id: number;
  properties: Property[];
  checklist: Checklist[];
}

export interface Lead {
  id: number;
  properties: Property[];
}

export interface Service {
  id: number;
  parent_id: number;
  properties: Property[];
}

export type QuoteProperty = Property & { created?: string };

export interface Quote {
  id: number,
  properties: QuoteProperty[]
}

export interface DB {
  order: CustomArray<Order>;
  checklist: Checklist[];
  user: Customer[];
  question: Question[];
  product: Product[];
  // answer: Answer[];
  questiontype: QuestionType[];
  quotes: Order[];
  submenu: string;
  users: User[];
}

export type AccessLevel = {
  level: number;
  accesslevel: string;
};

export type DepartmentStock = { department: string, stock: number };

export interface OrderDetail {
  id: number;
  parent_id: number;
  properties: Property[];
  /**
   * @deprecated Might not exist?
   */
  orderLine?: OrderLine[];
}

export interface OrderLine {
  id: number;
  parent_id: number;
  properties: Property[];
}
