import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Alert,
  Box, Button, Checkbox, FormControlLabel, Grid2, MenuItem, Select, TextField, Typography,
} from '@mui/material';
import { ArrowBack, Sms } from '@mui/icons-material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Link } from 'react-router';
import useCRMStore from '../../../../stores/crmStore.js';
import LoadingButton from '../../../common/buttons/LoadingButton.js';
import { CRMMutations } from '../../../../mutations/index.js';
import { CRMQueries } from '../../../../queries/index.js';
import type { GetCRMSMSTemplatesResponseInner } from '../../../../../generated/activityhelper/api.js';

const CRMSMSPage = () => {
  const [selectedText, setSelectedText] = React.useState<GetCRMSMSTemplatesResponseInner | undefined>(undefined);
  const [smsText, setSMSText] = React.useState<string>('');
  const [error, setError] = React.useState<string | undefined>(undefined);
  const [lockCustomer, setLockCustomer] = React.useState<boolean>(false);
  const [hoursLocked, setHoursLocked] = React.useState<number>(1);

  // Store
  const selectedItems = useCRMStore((state) => state.callListSelectedItems);
  const setSelectedItems = useCRMStore((state) => state.setCallListSelectedItems);
  const routeCustomer = useCRMStore((state) => state.routeCustomer);

  const actualCustomers = useMemo(() => selectedItems.filter((i) => !!i.phone), [selectedItems]);

  const mutation = useMutation({
    ...CRMMutations.sendSMSMutation,
    onSuccess: async () => {
      setSelectedItems([]);
      setError(undefined);
    },
  });
  const { mutateAsync, isPending: mutationIsPending, data } = mutation;

  const { data: smsTemplates, isLoading } = useQuery(CRMQueries.smsTemplatesQuery); // TODO
  const resultText = typeof data === 'string' ? data : JSON.stringify(data);

  const handleSendSMS = useCallback(() => {
    if (!smsText) {
      setError('No SMS text');
    } else if (smsText.length > 1071) {
      setError('SMS text is too long (max 1071 characters)');
    }
    if (smsText && actualCustomers.length > 0) {
      mutateAsync({
        // TODO Send customer id with phone to later easily retrieve the customer / connect it to a customer
        to: actualCustomers.map((item) => ({ customerId: item.id, phone: item.phone })),
        msg: smsText,
        from: 'ACsenteret', // Ignore on endpoint ??
        reply: true,
        type: 'CRM_SMS', // TODO save as const, or set this on the endpoint?
        insertCustomerLog: true,
        enableLock: lockCustomer ? { hours: hoursLocked } : undefined,
      });
    }
  }, [mutateAsync, actualCustomers, smsText, lockCustomer, hoursLocked]);

  useEffect(() => {
    if (error) {
      if (smsText && smsText.length < 1071) {
        setError(undefined);
      }
    }
  }, [error, smsText]);

  return (
    <Box>
      <Button
        component={Link}
        to={routeCustomer ? `/crm/customer/${routeCustomer}` : '/crm'}
        onClick={() => setSelectedItems([])}
        startIcon={<ArrowBack />}
      >
        Tilbake
      </Button>
      <Typography variant="h5" gutterBottom>Send SMS</Typography>
      <Typography gutterBottom>
        {/* TODO Move this as a side view that can scroll (same height as select + textfield) */}
        <b>
          Sender SMS til:
          {' '}
          {actualCustomers.map((item) => item.phone).join(', ')}
        </b>
      </Typography>
      <Grid2 container direction="column" spacing={1} sx={{ marginBottom: 2 }}>
        <Grid2>
          <Select
            sx={{ minWidth: 200 }}
            value={selectedText?.name ?? ''}
            onChange={(e) => {
              const selectedNew = smsTemplates?.find((item) => item.name === e.target.value);
              setSelectedText(selectedNew);
              setSMSText(selectedNew?.value ?? '');
            }}
          >
            {(smsTemplates ?? []).map((item) => (<MenuItem key={item.name} value={item.name}>{item.name}</MenuItem>))}
          </Select>
        </Grid2>
        <Grid2>
          <TextField
            sx={{ minWidth: 400 }}
            minRows={3}
            multiline
            value={smsText ?? ''}
            disabled={isLoading || mutationIsPending || (!!smsTemplates?.length && smsTemplates.length > 0)}
            onChange={(e) => setSMSText(e.target.value)}
            error={!smsText || !!error}
            helperText={error}
            label="Meldingstekst"
            required
          />
        </Grid2>
        <Grid2 container alignItems="center" direction="row" spacing={1}>
          <Grid2>
            <FormControlLabel
              control={<Checkbox checked={lockCustomer} onChange={(_, checked) => setLockCustomer(checked)} />}
              label="Lås kunde?"
              labelPlacement="start"
            />
          </Grid2>
          <Grid2>
            {lockCustomer ? (
              <TextField
                value={hoursLocked}
                type="number"
                onChange={(e) => setHoursLocked(Number(e.target.value))}
                error={hoursLocked < 0}
                helperText={hoursLocked < 0 ? 'Antall timer må være større enn 0' : ''}
                required
                label="Timer kunde er låst (forhindre SMS)"
              />
            ) : null}
          </Grid2>
          <Grid2>
            <LoadingButton
              customIcon={<Sms />}
              buttonProps={{
                variant: 'contained',
                loading: mutationIsPending,
                disabled: isLoading || mutationIsPending || (!smsText && !actualCustomers.length),
                onClick: handleSendSMS, // todo
                color: 'success',
              }}
            >
              Send
            </LoadingButton>
          </Grid2>
        </Grid2>
      </Grid2>
      {resultText ? (
        <Alert severity="success" variant="filled">{resultText}</Alert>
      ) : null}
    </Box>
  );
};

export default CRMSMSPage;
