import { Save, Search } from '@mui/icons-material';
import type { LoadingButtonProps as MuiLoadingButtonProps } from '@mui/lab';
import { Button } from '@mui/material';
import React from 'react';

type LoadingButtonProps = {
  save?: boolean;
  buttonProps?: Omit<MuiLoadingButtonProps, 'loadingPosition' | 'startIcon' | 'children'>
  children?: MuiLoadingButtonProps['children'];
  customIcon?: MuiLoadingButtonProps['startIcon'];
  disableStartIcon?: boolean;
};

const LoadingButton = (props: LoadingButtonProps) => {
  const {
    save, buttonProps, children, customIcon, disableStartIcon,
  } = props;
  const icon = customIcon ?? (save ? <Save /> : <Search />);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    (<Button {...buttonProps} variant={buttonProps?.variant || 'contained'} loadingPosition="start" startIcon={disableStartIcon ? undefined : icon}>{children}</Button>)
  );
};

export default LoadingButton;
