import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { getData, setData } from '../../CPpro/Utils/storage.js';
import type { User } from '../../CPpro/model/model.js';
import type { ICPproDataContext } from '../contexts/CPproDataContext.js';
import CPproDataContext from '../contexts/CPproDataContext.js';
import { CPproQueries } from '../queries/index.js';

const CPproDataProvider = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const [users, setUsers] = useState<User[]>([]);
  const queryClient = useQueryClient();

  const getUsers = useCallback(async (data?: User[]) => {
    const usersData = data ?? JSON.parse((await getData('Users')) ?? '[]') as User[];
    if (!usersData.length) {
      const queryData = await queryClient.fetchQuery(CPproQueries.usersQuery);
      setUsers(queryData);
      await setData('Users', JSON.stringify(queryData));
    } else {
      setUsers(usersData);
    }
  }, [queryClient]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const refreshUsers = useCallback(async () => {
    const queryData = await queryClient.fetchQuery(CPproQueries.usersQuery);

    getUsers(queryData);
    await setData('Users', JSON.stringify(queryData));
  }, [getUsers, queryClient]);

  const providerValue = useMemo<ICPproDataContext>(() => ({
    users,
    refreshUsers,
    // isLoading,
  }), [refreshUsers, users]);

  return (
    <CPproDataContext.Provider value={providerValue}>
      {children}
    </CPproDataContext.Provider>
  );
};

export default CPproDataProvider;
