import React from 'react';
import { Box } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { DialogRowElementProps } from '../../table/GenericTable.js';
import { ConstantMutations } from '../../../mutations/index.js';
import type { LatestConstant } from '../../../types/constantTypes.js';
import ConstantAddForm from './ConstantAddForm.js';
import { ConstantQueries } from '../../../queries/index.js';
import type { PostConstantRequestTypeEnum } from '../../../../generated/activityhelper/api.js';

type ConstantAddVersionDialogContentProps = DialogRowElementProps<LatestConstant>;

const ConstantAddVersionDialogContent = (props: ConstantAddVersionDialogContentProps) => {
  const { close, row } = props;

  const queryClient = useQueryClient();
  const addVersionMutation = useMutation({
    ...ConstantMutations.addConstantVersionMutation,
    onSuccess: () => {
      queryClient.invalidateQueries(ConstantQueries.constantsQuery);
      close();
    },
  });

  if (!row) return null;

  return (
    <Box>
      <ConstantAddForm
        onSubmit={(data) => addVersionMutation.mutateAsync({ key: row.latest.key, postConstantRequest: data })}
        lockKey
        expectedProperties={row.latest.expectedProps}
        defaultData={{ key: row.latest.key, value: '', type: (row.latest.expectedType as PostConstantRequestTypeEnum | undefined | null) ?? row.latest.type ?? 'STRING' }}
      />
    </Box>
  );
};

export default ConstantAddVersionDialogContent;
