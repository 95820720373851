import type { GetCustomerResponseCustomerDeliveredProductsInnerServicesInnerPropertiesInner } from '../generated/cppro/api.js';
import { DateUtils } from './index.js';

export type MapTypeWithPropertyResult<TPropertyType extends number> = Map<TPropertyType, string | number | undefined | null>;
export type MapTypeWithPropertiesResult<TPropertyType extends number> = Map<TPropertyType, (string | number | undefined | null)[]>;

type Item = { properties: GetCustomerResponseCustomerDeliveredProductsInnerServicesInnerPropertiesInner[] };
type Sortable = { created: string; deleted: string; };

export const sortModels = <TSortable extends Sortable>(models: TSortable[], desc?: boolean): TSortable[] => {
  const sorted = models.toSorted((a, b) => {
    const aFirst = desc ? 1 : -1;
    const bFirst = desc ? -1 : 1;
    const aDate = DateUtils.getUTCDayjs(a.created);
    const bDate = DateUtils.getUTCDayjs(b.created);
    if (aDate === null && bDate === null) return 0;
    if (aDate === null) return bFirst;
    if (bDate === null) return aFirst;
    if (aDate.isSame(bDate)) return 0;
    return aDate.isBefore(bDate) ? aFirst : bFirst;
  });

  return sorted;
};
/**
 * Sorts asc, so last is newest
 * @param type
 * @param item
 * @returns
 */
const filterProps = <TItem extends Item, TPropertyType extends number>(type: TPropertyType, item: TItem) => {
  const props = item.properties.filter((prop) => (prop.PropertyType_id === type
    && (prop.deleted
      ? !DateUtils.getUTCDayjs(prop.deleted)?.isAfter(DateUtils.getUTCDayjs(prop.created))
      : true)));

  return sortModels(props);
};

/**
 * Ignores deleted props, gets latest created prop (or last in list)
 *
 * @param items
 * @param propertyType
 * @returns
 */
// eslint-disable-next-line import/prefer-default-export, max-len
export const mapTypeWithProperty = <T extends Item, TPropertyType extends number>(
  item: T | undefined,
  propertyType: { [nu: number]: TPropertyType | string },
): MapTypeWithPropertyResult<TPropertyType> => {
  const map = new Map<TPropertyType, string | number | undefined | null>();
  if (!item) return map;
  for (const property of Object.values(propertyType).filter((val) => typeof val === 'number')) {
    const props = filterProps(property, item);
    const p = props.at(-1);
    const value = p?.strvalue ?? p?.numvalue;

    map.set(property, value);
  }

  return map;
};

/**
 * Ignores deleted props, gets latest created prop (or last in list)
 *
 * @param item
 * @param propertyType
 * @returns
 */
// eslint-disable-next-line import/prefer-default-export, max-len
export const mapTypeWithProperties = <T extends Item, TPropertyType extends number>(
  item: T,
  propertyType: { [nu: number]: TPropertyType | string },
): MapTypeWithPropertiesResult<TPropertyType> => {
  // eslint-disable-next-line no-spaced-func
  const map = new Map<TPropertyType, (string | number | undefined | null)[]>();
  for (const property of Object.values(propertyType).filter((val) => typeof val === 'number')) {
    const props = filterProps(property, item);

    map.set(property, props.map((p) => p.strvalue ?? p.numvalue));
  }

  return map;
};
