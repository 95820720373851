import type { UseMutationOptions } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import type { CreateSalesOrderChecklistRequest, CreateServiceOrderChecklistRequest, SendSMSAPIRequest } from '../../generated/activityhelper/api.js';
import { activityhelperApi } from '../apis/apis.js';
import { errorToast, infoToast } from '../../CPpro/Utils/toast.js';

export const createSaleMutation: UseMutationOptions<string, AxiosError<object | string>, CreateSalesOrderChecklistRequest> = {
  mutationFn: async (data) => {
    const response = await activityhelperApi.apiCpproOrderCreateSalesOrderChecklistPost({ createSalesOrderChecklistRequest: data });
    return response.data;
  },
  onSuccess: (result) => {
    if (result) {
      window.open(result, '_blank', 'noopener,noreferrer');
    }
  },
  onError: (error) => {
    // TODO we might have a toast on the global axios object for this, check for response.data there!?!
    errorToast(`Failed to create sales order:\n${typeof error.response?.data === 'string' ? error.response?.data : JSON.stringify(error.response?.data)}`, true);
    console.log('createSaleMutation error', error);
  },
};

export const createServiceMutation: UseMutationOptions<string, AxiosError<string>, CreateServiceOrderChecklistRequest> = {
  mutationFn: async (data) => {
    const response = await activityhelperApi.apiCpproOrderCreateServiceOrderChecklistPost({ createServiceOrderChecklistRequest: data });
    return response.data;
  },
  onSuccess: (result) => {
    if ('links' in (result as unknown as object) && Array.isArray((result as unknown as { links: string[] })?.links)) {
      const { links } = result as unknown as { links: string[] };
      if (links.length === 1) {
        window.open(links[0], '_blank', 'noopener,noreferrer');
      } else if (links.length > 1) {
        infoToast(`Created several orders\n\n${links.join('\n')}`, true, 20000);
      } else {
        infoToast('Failed to create order', true, 10000);
      }
    }
  },
};

export const sendSMSMutation: UseMutationOptions<unknown, AxiosError<object | string>, SendSMSAPIRequest> = {
  mutationFn: async (data) => {
    await activityhelperApi.apiSmsSendPost({ sendSMSAPIRequest: data });
  },
  onError: (error) => {
    errorToast(`Failed to send SMS:\n${typeof error.response?.data === 'string' ? error.response?.data : JSON.stringify(error.response?.data)}`, true);
    console.log('sendSMSMutation error', error);
  },
};

export const handledSMSReplyMutation: UseMutationOptions<unknown, AxiosError, { smsId: number }> = {
  mutationFn: async (data) => {
    await activityhelperApi.apiSmsIdHandledReplyPut({ id: data.smsId.toString() });
  },
};
