import type { UseMutationOptions } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import type {
  DefaultApiApiErpInstallItemUsageItemNumberAlternativePostRequest, DefaultApiApiErpInstallItemUsageItemNumberDeleteRequest,
  DefaultApiApiErpInstallItemUsageItemNumberPutRequest, DefaultApiApiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdDeleteRequest,
  DefaultApiApiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdPutRequest, PostInstallItemUsageRequest,
} from '../../generated/activityhelper/api.js';
import { activityhelperApi } from '../apis/apis.js';

export const addItemUsageMutation: UseMutationOptions<void, AxiosError, PostInstallItemUsageRequest> = {
  mutationFn: async (data) => {
    await activityhelperApi.apiErpInstallItemUsagePost({ postInstallItemUsageRequest: data });
  },
};

export const updateItemUsageMutation: UseMutationOptions<void, AxiosError, DefaultApiApiErpInstallItemUsageItemNumberPutRequest> = {
  mutationFn: async (data) => {
    await activityhelperApi.apiErpInstallItemUsageItemNumberPut(data);
  },
};

export const deleteItemUsageMutation: UseMutationOptions<void, AxiosError, DefaultApiApiErpInstallItemUsageItemNumberDeleteRequest> = {
  mutationFn: async (data) => {
    await activityhelperApi.apiErpInstallItemUsageItemNumberDelete(data);
  },
};

export const addItemUsageAlternativeMutation: UseMutationOptions<void, AxiosError,
DefaultApiApiErpInstallItemUsageItemNumberAlternativePostRequest> = {
  mutationFn: async (data) => {
    await activityhelperApi.apiErpInstallItemUsageItemNumberAlternativePost(data);
  },
};

export const updateItemUsageAlternativeMutation: UseMutationOptions<void, AxiosError,
DefaultApiApiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdPutRequest> = {
  mutationFn: async (data) => {
    await activityhelperApi.apiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdPut(data);
  },
};

export const deleteItemUsageAlternativeMutation: UseMutationOptions<void, AxiosError,
DefaultApiApiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdDeleteRequest> = {
  mutationFn: async (data) => {
    await activityhelperApi.apiErpInstallItemUsageItemUsageIdAlternativeAlternativeIdDelete(data);
  },
};
