import type { UseQueryOptions } from '@tanstack/react-query';
import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';
import type { MonitorTypes } from '../components/monitor/index.js';
import { activityhelperApi } from '../apis/apis.js';
import type {
  AlertPoliciesResponseInner, AlertPolicyIncidentsResponseInner, GetEmailsCommand, GetIncidentsCommand, NotificationChannelsResponseInner,
} from '../../generated/activityhelper/api.js';

interface MonitorQueries {
  getEmailsForMetricsQuery(params: MonitorTypes.GetEmailsCommand): UseQueryOptions<MonitorTypes.Email[]>;
  getSMSesForMetricsQuery(params: MonitorTypes.GetSMSesCommand): UseQueryOptions<MonitorTypes.Sms[]>;
  getLeadRequestsQuery(params: MonitorTypes.LogEntriesCommand): UseQueryOptions<MonitorTypes.LeadAPIRequests>;
  getLeadVendorsQuery(params: MonitorTypes.LogEntriesCommand): UseQueryOptions<MonitorTypes.LeadAPIVendors>;
  getErpErrorLogsQuery(params: MonitorTypes.LogEntriesCommand): UseQueryOptions<MonitorTypes.LogEntry<MonitorTypes.LogEntryErpError>[]>;
  getIncidentsQuery(params: GetIncidentsCommand): UseQueryOptions<AlertPolicyIncidentsResponseInner[]>;
}

const getCommonOptions = (params?: unknown): AxiosRequestConfig => (({
  baseURL: import.meta.env.VITE_ACTIVITYHELPER_URL,
  params,
}) as const);

// TODO For google auth see https://github.com/MomenSherif/react-oauth/issues/12#issuecomment-1131408898
// Need to implement auth on backend as well

// MSAL.js for google auth?
// https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/5713
// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
// https://github.com/AzureAD/microsoft-authentication-library-for-js/discussions/3554
// https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4562

// oidc-client-ts for google auth?
// https://www.npmjs.com/package/react-oidc-context
// https://www.npmjs.com/package/oidc-client-ts?activeTab=readme

export const getEmailsForMetricsQuery: MonitorQueries['getEmailsForMetricsQuery'] = (params) => ({
  queryKey: ['monitor-emails', params],
  queryFn: async () => {
    const { data } = await activityhelperApi.monitorEmailsGet({ query: params as GetEmailsCommand });

    return data as unknown as MonitorTypes.Email[] ?? [];
  },
});

export const getSMSesForMetricsQuery: MonitorQueries['getSMSesForMetricsQuery'] = (params) => ({
  queryKey: ['monitor-smses', params],
  queryFn: async () => {
    const { data } = await axios.get<MonitorTypes.Sms[]>('/monitor/sms', getCommonOptions(params));
    return data ?? [];
  },
});

export const getLeadRequestsQuery: MonitorQueries['getLeadRequestsQuery'] = (params) => ({
  queryKey: ['monitor-lead-requests', params],
  queryFn: async () => {
    const { data } = await axios.get<MonitorTypes.LeadAPIRequests>('/monitor/lead/requestStatusCodes', getCommonOptions(params));
    return data;
  },
  refetchOnWindowFocus: false,
  refetchOnMount: false,
});

export const getLeadVendorsQuery: MonitorQueries['getLeadVendorsQuery'] = (params) => ({
  queryKey: ['monitor-lead-vendors', params],
  queryFn: async () => {
    const { data } = await axios.get<MonitorTypes.LeadAPIVendors>('/monitor/lead/vendors', getCommonOptions(params));
    return data;
  },
  refetchOnWindowFocus: false,
  refetchOnMount: false,
});

export const getErpErrorLogsQuery: MonitorQueries['getErpErrorLogsQuery'] = (params) => ({
  queryKey: ['monitor-erp-error-logs', params],
  queryFn: async () => {
    const { data } = await axios.get<MonitorTypes.LogEntry<MonitorTypes.LogEntryErpError>[]>('/monitor/erp/apiErrors', getCommonOptions(params));
    return data;
  },
  refetchOnWindowFocus: false,
  refetchOnMount: false,
});

export const queuesWithTasksQuery: UseQueryOptions<MonitorTypes.QueueWithTasks[]> = {
  queryKey: ['monitor-queues-with-tasks'],
  queryFn: async () => {
    // const { data } = await monitorApi.monitorTasksQueuesWithTasksGet();
    // return data;
    const { data } = await axios.get<MonitorTypes.QueueWithTasks[]>('/monitor/tasks/queuesWithTasks', getCommonOptions());
    return data;
  },
  refetchOnWindowFocus: false,
  refetchOnMount: false,
};

export const incidentsQuery: UseQueryOptions<AlertPolicyIncidentsResponseInner[]> = {
  queryKey: ['monitor-incidents'],
  queryFn: async () => {
    const { data } = await activityhelperApi.monitorAlertIncidentGet();
    return data;
  },
};

export const getIncidentsQuery: MonitorQueries['getIncidentsQuery'] = (params) => ({
  queryKey: ['monitor-incidents', params],
  queryFn: async () => {
    const { data } = await activityhelperApi.monitorAlertIncidentGet({ query: params });
    return data;
  },
});

export const policiesQuery: UseQueryOptions<AlertPoliciesResponseInner[]> = {
  queryKey: ['monitor-policies'],
  queryFn: async () => {
    const { data } = await activityhelperApi.monitorAlertPolicyGet();
    return data;
  },
};

export const notificationChannelsQuery: UseQueryOptions<NotificationChannelsResponseInner[]> = {
  queryKey: ['monitor-notification-channels'],
  queryFn: async () => {
    const { data } = await activityhelperApi.monitorAlertNotificationChannelGet();
    return data;
  },
};
