import {
  Box, Grid2, MenuItem, Select, TextField, Typography,
} from '@mui/material';
import React, { useMemo } from 'react';
import { useForm } from '@tanstack/react-form';
import { z } from 'zod';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useMutation } from '@tanstack/react-query';
import type { CustomerWithData } from '../../../../generated/cppro/api.js';
import type { CustomerContactLog, Property } from '../../../../CPpro/model/model.js';
import { CustomerPropertyType } from '../../../../CPpro/model/model.js';
import { removeNulls } from '../../../../CPpro/Utils/tools.js';
import ContactLogTable from './tables/ContactLogTable.js';
import { CPproMutations } from '../../../mutations/index.js';
import LoadingButton from '../../common/buttons/LoadingButton.js';

type Props = {
  customer: CustomerWithData | undefined,
  /**
   * Calls this after saving contact log
   * @returns
   */
  onContactLogSubmit: () => void;
};

// const contactLogWhat = ['Clear', 'Pumpemodell', 'Kommentar', 'Forsøkt ringt']; // TODO More?!?

// const contactLogSchema: z.ZodType<CustomerContactLog> = z.object({
//   when: z.string().nonempty(),
//   what: z.string().nonempty(),
//   whoFrom: z.string().nonempty(),
//   message: z.string().nonempty(),
// });

const CRMContactLog = (props: Props) => {
  const { customer, onContactLogSubmit } = props;

  // TODO Check if this saves or is just a filter?!!?

  // const { mutateAsync } = useMutation(CPproMutations.addCustomerProperties);

  const contactLogs = useMemo<CustomerContactLog[]>(() => {
    if (!customer) return [];

    return customer.properties.map((p) => {
      if (p.PropertyType_id !== CustomerPropertyType.ContactLog || !p.strvalue) return undefined;
      try {
        const json = JSON.parse(p.strvalue) as CustomerContactLog; // zod validation?
        return json;
      } catch (_) {
        return undefined;
      }
    }).filter(removeNulls);
  }, [customer]);

  // const form = useForm<CustomerContactLog>({
  //   defaultValues: {
  //     when: dayjs().startOf('day').toISOString(), what: 'Clear', whoFrom: '', message: '',
  //   },
  //   validators: {
  //     onSubmit: contactLogSchema,
  //   },
  //   onSubmit: async ({ value, formApi }) => {
  //     if (customer) {
  //       const property: Property = {
  //         id: 0,
  //         parent_id: customer.id,
  //         type: CustomerPropertyType.ContactLog,
  //         value: JSON.stringify(value),
  //       };
  //       await mutateAsync([property]);
  //       onContactLogSubmit();
  //       formApi.reset();
  //     }
  //   },
  // });

  // const isFormDisabled = !customer;

  return (
    <Box>
      <Typography variant="h5" gutterBottom>Contact log</Typography>
      {/* <form onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
      >
        <Grid2 container direction="row" alignItems="center" gap={1} sx={{ marginBottom: 1 }}>
          <Grid2>
            <form.Field name="when">
              {(field) => (
                <DatePicker
                  label="When"
                  value={dayjs(field.state.value)}
                  onChange={(value) => field.handleChange(value?.toISOString() ?? '')}
                  disabled={isFormDisabled}
                  sx={{ width: 150 }}
                  slotProps={{
                    textField: {
                      size: 'small',
                    },
                  }}
                />
              )}
            </form.Field>
          </Grid2>
          <Grid2>
            <form.Field name="what">
              {(field) => (
                <Select
                  size="small"
                  label="What"
                  value={field.state.value}
                  onChange={(e) => field.handleChange(e.target.value)}
                  disabled={isFormDisabled}
                >
                  {contactLogWhat.map((w) => <MenuItem key={w} value={w}>{w}</MenuItem>)}
                </Select>
              )}
            </form.Field>
          </Grid2>
          <Grid2>
            <form.Field name="whoFrom">
              {(field) => (
                <TextField
                  size="small"
                  label="Who/From"
                  value={field.state.value}
                  onChange={(e) => field.handleChange(e.target.value)}
                  disabled={isFormDisabled}
                />
              )}
            </form.Field>
          </Grid2>
          <Grid2>
            <form.Field name="message">
              {(field) => (
                <TextField
                  size="small"
                  label="Message"
                  value={field.state.value}
                  onChange={(e) => field.handleChange(e.target.value)}
                  disabled={isFormDisabled}
                />
              )}
            </form.Field>
          </Grid2>
        </Grid2>
      </form> */}
      <ContactLogTable contactLogs={contactLogs} />
    </Box>
  );
};

export default CRMContactLog;
