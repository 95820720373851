import type { Customer, Property } from '../../CPpro/model/model.js';
import { CustomerPropertyType } from '../../CPpro/model/model.js';
import { removeNulls } from '../../CPpro/Utils/tools.js';
import type { CustomerWithData, GetCustomerResponseCustomerDeliveredProductsInnerServicesInnerPropertiesInner } from '../../generated/cppro/api.js';
import type {
  AddressSelect, CustomerForm, CustomerInfo, PhoneSelect,
} from '../types/crmTypes.js';

type CustomerProperty = GetCustomerResponseCustomerDeliveredProductsInnerServicesInnerPropertiesInner;

export const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

export const getValueFromProperty = (property: CustomerProperty) => property.strvalue ?? property.numvalue;
export const getNumValueFromProperty = (property: CustomerProperty) => {
  if (property.numvalue) return property.numvalue;
  if (!property.strvalue) return undefined;
  const num = Number(property.strvalue);
  if (Number.isNaN(num)) return undefined;
  return num;
};
export const getStrValueFromProperty = (property: CustomerProperty) => property.strvalue ?? property.numvalue?.toString();

export const getProperty = <TModel extends { properties: CustomerProperty[] }, T extends number, >(model: TModel, type: T) => model.properties.find(
  (p) => p.PropertyType_id === type,
);

// eslint-disable-next-line max-len
export const getProperties = <TModel extends { properties: CustomerProperty[] }, T extends number, >(model: TModel, type: T) => model.properties.filter(
  (p) => p.PropertyType_id === type,
);

export const getPropertyValue = <TModel extends { properties: CustomerProperty[] }, T extends number, >(model: TModel, type: T) => {
  const property = getProperty(model, type);
  return property ? getValueFromProperty(property) : undefined;
};

export const getStrPropertyValue = <TModel extends { properties: CustomerProperty[] }, T extends number, >(model: TModel, type: T) => {
  const property = getProperty(model, type);
  return property ? getStrValueFromProperty(property) : undefined;
};

export const getNumPropertyValue = <TModel extends { properties: CustomerProperty[] }, T extends number, >(model: TModel, type: T) => {
  const property = getProperty(model, type);
  return property ? getNumValueFromProperty(property) : undefined;
};

export const mapCustomerToInfo = (customer: CustomerWithData): CustomerInfo => {
  const phoneProps = getProperties(customer, CustomerPropertyType.Phone).map((p) => p.strvalue ?? p.numvalue).filter(removeNulls);

  return {
    id: customer.id,
    name: getProperty(customer, CustomerPropertyType.Name)?.strvalue,
    deliveryAddress: getProperty(customer, CustomerPropertyType.DeliveryAddress)?.strvalue,
    deliveryCity: getProperty(customer, CustomerPropertyType.DeliveryCity)?.strvalue,
    deliveryZIP: getProperty(customer, CustomerPropertyType.DeliveryZIP)?.numvalue?.toString().padStart(4, '0'),
    email: getProperty(customer, CustomerPropertyType.Email)?.strvalue,
    invoiceAddress: getProperty(customer, CustomerPropertyType.InvoiceAddress)?.strvalue,
    invoiceCity: getProperty(customer, CustomerPropertyType.InvoiceCity)?.strvalue,
    invoiceZIP: getProperty(customer, CustomerPropertyType.InvoiceZIP)?.numvalue?.toString().padStart(4, '0'),
    serviceInterval: getProperty(customer, CustomerPropertyType.ServiceInterval)?.numvalue,
    // phone: phoneProps.at(0),
    phone1: phoneProps.at(0)?.toString(),
    phone2: phoneProps.at(1)?.toString(),
    // phones: phoneProps,
    serviceStatus: getProperty(customer, CustomerPropertyType.ServiceStatus)?.strvalue,
    // editingStates: structuredClone(defaultEditingStates),
    // selectedMail: 'invoiceMail',
  } satisfies CustomerInfo;
};

export const mapInfoToForm = (customer: CustomerInfo, selected: { selectedAddress: AddressSelect, selectedPhone: PhoneSelect }): CustomerForm => {
  const { selectedAddress, selectedPhone } = selected;
  const formInfo: CustomerForm = {
    city: (selectedAddress === 'delivery' ? customer.deliveryCity : customer.invoiceCity)?.toString(),
    email: customer.email?.toString(),
    name: customer.name?.toString() ?? '',
    phone: (selectedPhone === 'Phone 1' ? customer.phone1 : customer.phone2)?.toString(),
    street: (selectedAddress === 'delivery' ? customer.deliveryAddress : customer.invoiceAddress)?.toString(),
    zip: (selectedAddress === 'delivery' ? customer.deliveryZIP : customer.invoiceZIP)?.toString() ?? '',
  };

  return formInfo;
};

export const mapToNewCustomer = (data: {
  formData: CustomerForm,
  selectedAddress: AddressSelect,
  selectedPhone: PhoneSelect,
}): Customer => {
  const { formData, selectedAddress } = data;
  const properties: Property[] = [];
  properties.push({
    id: 0, parent_id: 0, type: CustomerPropertyType.Name, value: formData.name,
  });
  if (formData.email) {
    properties.push({
      id: 0, parent_id: 0, type: CustomerPropertyType.Email, value: formData.email,
    });
  }
  if (formData.phone) {
    properties.push({
      id: 0, parent_id: 0, type: CustomerPropertyType.Phone, value: formData.phone,
    });
  }
  properties.push({
    id: 0, parent_id: 0, type: CustomerPropertyType[`${capitalize(selectedAddress)}ZIP`], value: formData.zip,
  });
  if (formData.street) {
    properties.push({
      id: 0, parent_id: 0, type: CustomerPropertyType[`${capitalize(selectedAddress)}Address`], value: formData.street,
    });
  }
  if (formData.city) {
    properties.push({
      id: 0, parent_id: 0, type: CustomerPropertyType[`${capitalize(selectedAddress)}City`], value: formData.city,
    });
  }

  return {
    id: 0,
    properties,
  };
};

export const getCRMStatusName = (status: number | string | null | undefined) => {
  if (status === null || status === undefined) return undefined;
  const num = Number(status);
  if (Number.isNaN(num)) return 'UNKNOWN';
  switch (num) {
    case 1:
      return 'NEW';
    case 6:
      return 'INVOICED';
    case 12:
      return 'CANCELLED';
    default:
      return 'UNKNOWN';
  }
};
