import React, { useMemo } from 'react';
import {
  Box, Button, IconButton, Tooltip,
} from '@mui/material';
import type { CellContext } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import { CheckCircle, Delete, StopCircle } from '@mui/icons-material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { LatestConstant } from '../../../types/constantTypes.js';
import GenericTable from '../../table/GenericTable.js';
import type { DialogRowElementProps } from '../../table/GenericTable.js';
import type { GetConstantsResponseConstantsInner, GetConstantsResponseRecordsInnerProps } from '../../../../generated/activityhelper/api.js';
import { ConstantQueries } from '../../../queries/index.js';
import { ConstantMutations } from '../../../mutations/index.js';
import { constantVersionsColumns } from './ConstantTableColumns.js';
import ConstantAddDialog from './ConstantAddDialog.js';

type ConstantInfoContentProps = DialogRowElementProps<LatestConstant>;

const columnHelper = createColumnHelper<GetConstantsResponseConstantsInner>();

type ActionsCellProps = {
  context: CellContext<GetConstantsResponseConstantsInner, unknown>,
};
const ActionsCell = (props: ActionsCellProps) => {
  const { context } = props;
  const { row } = context;

  const queryClient = useQueryClient();

  const enableVersionMutation = useMutation({
    ...ConstantMutations.enableConstantVersionMutation,
    onSuccess: () => {
      queryClient.invalidateQueries(ConstantQueries.constantsQuery);
      (context.table.options.meta?.custom as { close: () => void } | undefined)?.close(); // ???
    },
  });
  const { mutateAsync: enableVersionMutateAsync } = enableVersionMutation;

  const disableVersionMutation = useMutation({
    ...ConstantMutations.disableConstantVersionMutation,
    onSuccess: () => {
      queryClient.invalidateQueries(ConstantQueries.constantsQuery);
      (context.table.options.meta?.custom as { close: () => void } | undefined)?.close(); // ???
    },
  });
  const { mutateAsync: disableVersionMutateAsync } = disableVersionMutation;

  return (
    <>
      {row.original.disabledAt ? (
        <Tooltip title="Enable">
          <IconButton size="small" onClick={() => enableVersionMutateAsync({ key: row.original.key, version: row.original.version.toString() })}>
            <CheckCircle color="success" />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Disable">
          <IconButton size="small" onClick={() => disableVersionMutateAsync({ key: row.original.key, version: row.original.version.toString() })}>
            <StopCircle color="warning" />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Slett versjon">
        <IconButton size="small" onClick={() => context.table.options.meta?.openDeleteDialog(context)}>
          <Delete color="error" />
        </IconButton>
      </Tooltip>
    </>
  );
};

const dynamicColumn = columnHelper.display({
  header: 'Actions',
  id: 'actions',
  enableSorting: false,
  cell: (i) => (
    <ActionsCell context={i} />
  ),
});

const ConstantInfoContent = (props: ConstantInfoContentProps) => {
  const { row, close } = props;
  const [open, setOpen] = React.useState(false);
  const queryClient = useQueryClient();
  const deleteVersionMutation = useMutation({
    ...ConstantMutations.deleteConstantVersionMutation,
    onSuccess: () => {
      queryClient.invalidateQueries(ConstantQueries.constantsQuery);
    },
  });

  const expectedRecords = useMemo(() => {
    const map = new Map<string, GetConstantsResponseRecordsInnerProps>();
    if (row) {
      map.set(row.latest.key, { type: row.latest.type, dynamicProperties: row.latest.expectedProps });
    }
    return map;
  }, [row]);

  console.log(expectedRecords);

  if (!row) return null;
  return (
    <Box>
      <GenericTable
        id="constant-versions"
        data={row.versions}
        columns={[...constantVersionsColumns, dynamicColumn]}
        customMeta={{ close }}
        deleteDialogProps={{
          title: 'Slett versjon, er du sikker?',
          onDelete: async (r, c) => {
            if (r) {
              await deleteVersionMutation.mutateAsync({ key: r.key, version: r.version });
              c();
              close();
            }
          },
        }}
      />
      <Button sx={{ marginTop: 3 }} variant="contained" color="success" onClick={() => setOpen(true)}>Legg til ny versjon</Button>
      <ConstantAddDialog
        open={open}
        onClose={() => { setOpen(false); close(); }}
        addVersion={{ key: row.latest.key, type: row.latest.type }}
        expectedRecords={expectedRecords}
      />
    </Box>
  );
};

export default ConstantInfoContent;
