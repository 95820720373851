import React, { useCallback, useMemo } from 'react';
import type { SortingState } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import {
  Box, Grid2, IconButton, TextField,
  Tooltip,
} from '@mui/material';
import { useForm } from '@tanstack/react-form';
import { z } from 'zod';
import {
  LockClock, LockOpen, LockPerson, Refresh,
} from '@mui/icons-material';
import { useNavigate } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import type { GetCallListResponseItemsInner } from '../../../../../generated/cppro/api.js';
import { DateUtils } from '../../../../../sharedutils/index.js';
import GenericTable from '../../../table/GenericTable.js';
import LoadingButton from '../../../common/buttons/LoadingButton.js';
import type { CallListQueryParams } from '../../../../queries/crmQueries.js';
import { createRowSelectionColumn } from '../../../table/columns/columnHelpers.js';
import CallListTableFilters from './CallListTableFilters.js';
import { CRMQueries } from '../../../../queries/index.js';
import type { CallListItem } from '../../../../types/crmTypes.js';

const columnHelper = createColumnHelper<CallListItem>();

const padZip = (a: string) => a.padStart(4, '0');

const getLockedInfo = (lockInfo: CallListItem['lockInfo']) => {
  if (!lockInfo) {
    return (
      <Tooltip title="Ulåst">
        <LockOpen color="primary" />
      </Tooltip>
    );
  }
  if (lockInfo.agentOwns) {
    return (
      <Tooltip
        title={(
          <span>
            {`Låst av ${lockInfo.lockedBy}`}
            <br />
            {`Låst til: ${DateUtils.getLocalizedDateTime(lockInfo.lockedUntil)}`}
          </span>
        )}
      >
        <LockPerson color="success" />
      </Tooltip>
    );
  }
  return (
    <Tooltip
      title={(
        <span>
          {`Låst av ${lockInfo.lockedBy}`}
          <br />
          {`Låst til: ${DateUtils.getLocalizedDateTime(lockInfo.lockedUntil)}`}
        </span>
      )}
    >
      <LockClock color="error" />
    </Tooltip>
  );
};

const columns = [
  createRowSelectionColumn(columnHelper),
  columnHelper.accessor((a) => a.phone, {
    header: 'Telefon',
    id: 'phone',
  }),
  columnHelper.accessor((a) => a.name, {
    header: 'Kontakt',
    id: 'name',
  }),
  columnHelper.accessor((a) => a.address, {
    header: 'Adresse',
    id: 'address',
  }),
  columnHelper.accessor((a) => a.zip, {
    header: 'Postnr',
    id: 'zip',
    cell: (info) => padZip(info.getValue()),
  }),
  columnHelper.accessor((a) => a.city, {
    header: 'Sted',
    id: 'city',
  }),
  columnHelper.accessor((a) => a.id, {
    header: 'ID',
    id: 'id',
  }),
  columnHelper.accessor((a) => a.date, {
    header: 'Dato',
    id: 'date',
    sortingFn: 'datetime',
    cell: (info) => DateUtils.getLocalizedDate(info.getValue()),
  }),
  // Send score as sum of order scores
  columnHelper.accessor((a) => a.totalScore, {
    header: 'Poeng',
    id: 'totalScore',
    // sortingFn: 'alphanumeric',
    // cell: (info) => info.getValue().reduce((sum, num) => sum + num, 0),
  }),
  columnHelper.accessor((a) => a.note, {
    header: 'Note',
    id: 'note', // TODO
  }),
  columnHelper.accessor((a) => a.counter, {
    header: 'Counter',
    id: 'counter',
  }),
  columnHelper.accessor((a) => a.lockInfo, {
    header: 'Låst?',
    id: 'locked',
    cell: (info) => getLockedInfo(info.getValue()),
  }),
];

const defaultSortingState: SortingState = [{ id: 'totalScore', desc: true }];

const schema = z.object({
  fromZip: z.string().regex(/^\d{1,4}$/),
  toZip: z.string().regex(/^\d{1,4}$/),
});

interface CallListTableProps {
  data: GetCallListResponseItemsInner[];
  search: (params: CallListQueryParams) => void;
  refetch: () => Promise<void>;
  isLoading: boolean;
}

const CallListTable = (props: CallListTableProps) => {
  const {
    data, search, isLoading, refetch,
  } = props;
  const navigate = useNavigate();

  const { data: customerLocks } = useQuery(CRMQueries.customerLocksQuery);
  const formData = useMemo<CallListItem[]>(() => data.map((row) => {
    const foundAgentLock = customerLocks?.agentLocks.find((l) => l.customerId === row.id);
    if (foundAgentLock) {
      return {
        ...row,
        lockInfo: {
          lockedBy: foundAgentLock.agent,
          lockedUntil: foundAgentLock.locked,
          agentOwns: true,
        },
      };
    }
    const foundLock = customerLocks?.locks.find((l) => l.customerId === row.id);
    return {
      ...row,
      lockInfo: foundLock ? {
        lockedBy: foundLock.agent,
        lockedUntil: foundLock.locked,
        agentOwns: false,
      } : undefined,
    };
  }), [data, customerLocks]);

  const form = useForm<CallListQueryParams>({
    onSubmit: ({ value }) => {
      if (value?.fromZip !== undefined && value?.toZip !== undefined) {
        search(value);
      }
    },
    validators: {
      onChange: schema,
    },
  });

  const handleRowClick = useCallback((ctx: { row: { original: CallListItem } }) => {
    navigate(`/crm/customer/${ctx.row.original.id}`);
  }, [navigate]);

  return (
    <Box>
      <Box sx={{ marginBottom: 2 }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            form.handleSubmit();
          }}
        >
          <Grid2 container spacing={1} alignItems="center">
            <Grid2>
              <form.Field name="fromZip">
                {(field) => (
                  <TextField
                    type="string"
                    inputMode="numeric"
                    label="From zip:"
                    value={field.state.value ?? ''}
                    onChange={(e) => field.handleChange(e.target.value)}
                    variant="outlined"
                    size="small"
                    error={!!field.state.meta.errors.length}
                    helperText={field.state.meta.errors.join(', ')}
                  />
                )}
              </form.Field>
            </Grid2>
            <Grid2>
              <form.Field name="toZip">
                {(field) => (
                  <TextField
                    type="string"
                    inputMode="numeric"
                    label="To zip:"
                    value={field.state.value ?? ''}
                    onChange={(e) => field.handleChange(e.target.value)}
                    variant="outlined"
                    size="small"
                  />
                )}
              </form.Field>
            </Grid2>
            <Grid2>
              <form.Subscribe selector={(state) => [state.canSubmit, state.isSubmitting, state.isTouched]}>
                {([canSubmit, isSubmitting, isTouched]) => (
                  <LoadingButton buttonProps={{
                    type: 'submit',
                    loading: isLoading,
                    disabled: !canSubmit || isSubmitting || !isTouched,
                  }}
                  >
                    Søk
                  </LoadingButton>
                )}
              </form.Subscribe>
            </Grid2>
            <Grid2>
              <form.Subscribe selector={(state) => [state.canSubmit, state.isSubmitting]}>
                {([canSubmit, isSubmitting]) => (
                  <IconButton
                    color="success"
                    disabled={!canSubmit || isSubmitting}
                    title="Refresh"
                    onClick={refetch}
                  >
                    <Refresh />
                  </IconButton>
                )}
              </form.Subscribe>
            </Grid2>
          </Grid2>
        </form>
      </Box>
      <GenericTable
        rowSelect={{
          getRowId: (row) => row.id.toString(),
          // enableRowClick: true,
        }}
        size="small"
        columns={columns}
        data={formData ?? []}
        initialSortingState={defaultSortingState}
        Filter={CallListTableFilters}
        pagination
        customOnRowClick={handleRowClick}
      />
    </Box>
  );
};

export default CallListTable;
