import React, { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import type { GetCustomerResponseCustomerOrderDetailsInner } from '../../../../../generated/cppro/api.js';
import { OrderDetailPropertyType } from '../../../../../CPpro/model/model.js';
import { CPProUtils, DateUtils } from '../../../../../sharedutils/index.js';
import GenericTable from '../../../table/GenericTable.js';
import { getCRMStatusName } from '../../../../utils/crmUtils.js';

type Props = {
  order: GetCustomerResponseCustomerOrderDetailsInner | undefined;
};

type OrderDetail = Record<Uncapitalize<keyof typeof OrderDetailPropertyType>, string | number | undefined | null>;

const calcSum = (order: OrderDetail) => {
  const price = order.sum ? Number(order.sum) : 0;
  const vAT = order.vAT ? Number(order.vAT) : 0;
  return (price * (1 + vAT / 100)).toFixed(2);
};

const columnHelper = createColumnHelper<OrderDetail>();

const columns = [
  columnHelper.accessor((row) => row.orderId, {
    header: 'Order ID',
    id: 'orderId',
  }),
  columnHelper.accessor((row) => row.status, {
    header: 'Status',
    id: 'status',
    cell: (info) => getCRMStatusName(info.getValue()) ?? '',
  }),
  columnHelper.accessor((row) => row.reference, {
    header: 'Reference',
    id: 'reference',
  }),
  columnHelper.accessor((row) => row.orderDate, {
    header: 'Order Date',
    id: 'orderDate',
    cell: (info) => DateUtils.getLocalizedDate(info.getValue()),
  }),
  columnHelper.accessor((row) => row.deliveryDate, {
    header: 'Delivery Date',
    id: 'deliveryDate',
    cell: (info) => DateUtils.getLocalizedDate(info.getValue()),
  }),
  columnHelper.accessor((row) => row.pickDate, {
    header: 'Pick Date',
    id: 'pickDate',
    cell: (info) => DateUtils.getLocalizedDate(info.getValue()),
  }),
  columnHelper.accessor((row) => row.shipDate, {
    header: 'Ship Date',
    id: 'shipDate',
    cell: (info) => DateUtils.getLocalizedDate(info.getValue()),
  }),
  columnHelper.accessor((row) => row.invoiceDate, {
    header: 'Invoice Date',
    id: 'invoiceDate',
    cell: (info) => DateUtils.getLocalizedDate(info.getValue()),
  }),
  columnHelper.accessor((row) => row.score, {
    header: 'Score',
    id: 'score',
  }),
  columnHelper.accessor((row) => row.type, {
    header: 'Type',
    id: 'type',
  }),
  columnHelper.accessor((row) => row.sum, {
    header: 'Price',
    id: 'price',
  }),
  columnHelper.accessor((row) => row.vAT, {
    header: 'VAT',
    id: 'vat',
  }),
  columnHelper.display({
    header: 'Sum inc VAT',
    id: 'sumIncVat',
    cell: (ctx) => calcSum(ctx.row.original),
  }),
];

const OrderDetailTable = (props: Props) => {
  const { order } = props;

  const mapped = useMemo<OrderDetail[]>(() => {
    if (!order) return [];
    const map = CPProUtils.mapTypeWithProperty(order, OrderDetailPropertyType);

    return [{
      deliveryDate: map.get(OrderDetailPropertyType.DeliveryDate),
      invoiceDate: map.get(OrderDetailPropertyType.InvoiceDate),
      orderId: map.get(OrderDetailPropertyType.OrderId),
      orderDate: map.get(OrderDetailPropertyType.OrderDate),
      pickDate: map.get(OrderDetailPropertyType.PickDate),
      reference: map.get(OrderDetailPropertyType.Reference),
      shipDate: map.get(OrderDetailPropertyType.ShipDate),
      status: map.get(OrderDetailPropertyType.Status),
      sum: map.get(OrderDetailPropertyType.Sum),
      vAT: map.get(OrderDetailPropertyType.VAT),
      score: map.get(OrderDetailPropertyType.Score),
      type: map.get(OrderDetailPropertyType.Type),
      eRPClient: map.get(OrderDetailPropertyType.ERPClient),
    }];
  }, [order]);

  return (
    <GenericTable size="small" columns={columns} data={mapped} />
  );
};

export default OrderDetailTable;
