import { z } from 'zod';
import punycode from 'punycode.js';

export const emailSchema = z.string().transform(punycode.toASCII).pipe(z.string().min(1).email('Invalid email'));

export const stringSchema = z.union([z.string(), z.number()]).pipe(z.coerce.string());
export const numberSchema = z.union([z.string(), z.number()]).pipe(z.coerce.number());

export const stringIsJSONSchema = z.string().pipe(z.custom((str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (error) {
    return false;
  }
}, 'Invalid JSON'));
export const stringToJSONSchema = z.string()
  .transform((str, ctx) => {
    try {
      return JSON.parse(str);
    } catch (error) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Invalid JSON',
      });
      return z.NEVER;
    }
  });
export const jsonObjectSchema = stringToJSONSchema.pipe(z.object({}));
export const jsonObjectArraySchema = stringToJSONSchema.pipe(z.array(z.object({})));

/**
 * Create object using this and satisfies, then use `const schema: z.ZodType<T> = z.object(...);` with z.object() of the original object.
 *
 * ```
 * const initialSchema = {
 *  ...
 * } satisfies TypeToZod<T>;
 *
 * const schema: z.ZodType<T> = z.object(initialSchema);
 * ```
 */
export type TypeToZod<T> = {
  [K in keyof T]: T[K] extends (string | number | boolean | null | undefined | number[] | string[] | Date | object[])
    ? (undefined extends T[K] ? z.ZodOptional<z.ZodType<Exclude<T[K], undefined>>> : z.ZodType<T[K]>)
    : z.ZodObject<TypeToZod<T[K]>>
};
