import {
  Button, Divider, Typography, Box, Grid2,
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router';

const mode = import.meta.env.MODE;
const isProduction = mode === 'production';

const MonitorOverviewPage = () => (
  <Box>
    <Typography variant="h4" gutterBottom>Overview</Typography>
    <Divider sx={{ mb: 2 }} />
    <Typography variant="h6">Check out the sidebar</Typography>
    <Typography>If there are only 2 items, you do not have the correct role and/or level</Typography>
    <Divider sx={{ mb: 2, mt: 2 }} />
    <Typography variant="h6">Andre sider:</Typography>
    <Grid2
      container
      spacing={2}
      sx={{
        alignItems: 'center',
      }}
    >
      <Grid2>
        {isProduction ? (
          <Button href="https://report.cppro.no" variant="contained">Salgsoversikt</Button>
        ) : (
          <Button component={Link} to="/sales" variant="contained">Salgsoversikt</Button>
        )}
      </Grid2>
      <Grid2>
        <Button component={Link} to="/hourReport/overview" variant="contained">Stemplingsoversikt</Button>
      </Grid2>
    </Grid2>
  </Box>
);

export default MonitorOverviewPage;
