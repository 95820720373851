import React from 'react';
import { Checkbox } from '@mui/material';
import type { ColumnHelper } from '@tanstack/react-table';

export const empty = () => {};

export const createRowSelectionColumn = <TRow, >(columnHelper: ColumnHelper<TRow>) => {
  const column = columnHelper.display({
    id: 'rowSelection',
    enableSorting: false,
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllRowsSelected()}
        indeterminate={table.getIsSomeRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()} // or getToggleAllPageRowsSelectedHandler
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        disabled={!row.getCanSelect()}
        onClick={(e) => e.stopPropagation()}
        onChange={row.getToggleSelectedHandler()}
      />
    ),
  });

  return column;
};
