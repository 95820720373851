import type { UseMutationOptions } from '@tanstack/react-query';
import { activityhelperApi } from '../apis/apis.js';
import type {
  DefaultApiMonitorAlertIncidentIdPutRequest,
  DefaultApiMonitorAlertNotificationChannelIdDeleteRequest, DefaultApiMonitorAlertNotificationChannelIdPutRequest,
  DefaultApiMonitorAlertNotificationChannelPostRequest, DefaultApiMonitorAlertPolicyPolicyIdChannelChannelIdDeleteRequest,
  DefaultApiMonitorAlertPolicyPolicyIdChannelChannelIdPostRequest, DefaultApiMonitorAlertPolicyPolicyIdChannelChannelIdPutRequest,
  DefaultApiMonitorTasksTaskDeleteRequest, DefaultApiMonitorTasksTaskRunPostRequest,
} from '../../generated/activityhelper/api.js';

export const runTaskMutation: UseMutationOptions<unknown, Error, DefaultApiMonitorTasksTaskRunPostRequest> = {
  mutationFn: async (vars) => {
    await activityhelperApi.monitorTasksTaskRunPost(vars);
  },
};

export const deleteTaskMutation: UseMutationOptions<unknown, Error, DefaultApiMonitorTasksTaskDeleteRequest> = {
  mutationFn: async (vars) => {
    await activityhelperApi.monitorTasksTaskDelete(vars);
  },
};

export const updateNotificationChannelMutation: UseMutationOptions<unknown, Error, DefaultApiMonitorAlertNotificationChannelIdPutRequest> = {
  mutationFn: async (vars) => {
    await activityhelperApi.monitorAlertNotificationChannelIdPut(vars);
  },
};

export const deleteNotificationChannelMutation: UseMutationOptions<unknown, Error, DefaultApiMonitorAlertNotificationChannelIdDeleteRequest> = {
  mutationFn: async (vars) => {
    await activityhelperApi.monitorAlertNotificationChannelIdDelete(vars);
  },
};

export const createNotificationChannelMutation: UseMutationOptions<unknown, Error, DefaultApiMonitorAlertNotificationChannelPostRequest> = {
  mutationFn: async (vars) => {
    await activityhelperApi.monitorAlertNotificationChannelPost(vars);
  },
};

export const createPolicyNotificationMutation: UseMutationOptions<unknown, Error, DefaultApiMonitorAlertPolicyPolicyIdChannelChannelIdPostRequest> = {
  mutationFn: async (vars) => {
    await activityhelperApi.monitorAlertPolicyPolicyIdChannelChannelIdPost(vars);
  },
};

export const updatePolicyNotificationMutation: UseMutationOptions<unknown, Error, DefaultApiMonitorAlertPolicyPolicyIdChannelChannelIdPutRequest> = {
  mutationFn: async (vars) => {
    await activityhelperApi.monitorAlertPolicyPolicyIdChannelChannelIdPut(vars);
  },
};

export const deletePolicyNotificationMutation: UseMutationOptions<
unknown,
Error,
DefaultApiMonitorAlertPolicyPolicyIdChannelChannelIdDeleteRequest> = {
  mutationFn: async (vars) => {
    await activityhelperApi.monitorAlertPolicyPolicyIdChannelChannelIdDelete(vars);
  },
};

export const deleteAlertPolicyIncident: UseMutationOptions<unknown, Error, number | string> = {
  mutationFn: async (vars) => {
    await activityhelperApi.monitorAlertIncidentIdDelete({ id: vars.toString() });
  },
};

export const updateAlertPolicyIncident: UseMutationOptions<unknown, Error, DefaultApiMonitorAlertIncidentIdPutRequest> = {
  mutationFn: async (vars) => {
    await activityhelperApi.monitorAlertIncidentIdPut(vars);
  },
};
