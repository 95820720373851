import React, { useCallback, useMemo } from 'react';
import { useForm, useStore } from '@tanstack/react-form';
import {
  Box, Button, FormControl, Grid2, IconButton, InputLabel, MenuItem, Select, Stack, TextField,
  Tooltip,
} from '@mui/material';
import { zodValidator } from '@tanstack/zod-form-adapter';
import { z } from 'zod';
import { Add, Info, Remove } from '@mui/icons-material';
import type { APIConstantTypeEnum, PostConstantRequest } from '../../../../generated/activityhelper/api.js';
import { ZodValidation } from '../../../utils/index.js';
import LoadingButton from '../../common/buttons/LoadingButton.js';

interface ConstantAddFormProps {
  defaultData?: PostConstantRequest & Required<Pick<PostConstantRequest, 'type'>>;
  onSubmit: (data: PostConstantRequest) => void;
  lockKey?: boolean;
  expectedProperties?: string[];
}

type FormConstant = Pick<PostConstantRequest, 'key' | 'value'> & Required<Pick<PostConstantRequest, 'type'>> & {
  numValue: number | undefined;
  jsonValue: string | undefined;
  listValue: (number | string)[];
  listLength: number;
};

const valueTypes: readonly { label: string, value: APIConstantTypeEnum }[] = [
  { label: 'Tekst', value: 'STRING' },
  { label: 'Tall', value: 'NUMBER' },
  { label: 'Liste av tekst', value: 'STRINGARRAY' },
  { label: 'Liste av tall', value: 'NUMBERARRAY' },
  { label: 'JSON-objekt*', value: 'OBJECT' },
  { label: 'Liste av JSON-objekt*', value: 'OBJECTARRAY' },
] as const;

const getDynamicPropertyTemplate = (property: string) => `<%= ${property} %>`;

const ConstantAddForm = (props: ConstantAddFormProps) => {
  const [selectedDynamicProp, setSelectedDynamicProp] = React.useState<string>('');
  const {
    defaultData, onSubmit, lockKey, expectedProperties,
  } = props;

  const onSubmitHandler = useCallback((value: FormConstant) => {
    if (value.numValue && value.type === 'NUMBER') {
      onSubmit({
        key: value.key,
        value: value.numValue.toString(),
        json: value.numValue,
        type: value.type,
      });
    } else if (value.type === 'OBJECT' || value.type === 'OBJECTARRAY') {
      const parsed = ZodValidation.stringToJSONSchema.safeParse(value.jsonValue);
      if (parsed.success) {
        onSubmit({
          key: value.key,
          value: '',
          json: parsed.data,
          type: value.type,
        });
      } else {
        console.log('Failed to parse JSON:', parsed.error.format());
        // onSubmit({ key: value.key, value: value.value });
      }
    } else if (value.type === 'NUMBERARRAY' || value.type === 'STRINGARRAY') {
      onSubmit({
        key: value.key,
        value: '',
        json: value.listValue,
        type: value.type,
      });
    } else if (value.type === 'BOOLEAN') {
      // TODO Add this
    } else {
      onSubmit({
        key: value.key,
        value: value.value,
        type: value.type,
      });
    }
  }, [onSubmit]);

  const form = useForm<FormConstant>({
    defaultValues: defaultData ? {
      ...defaultData, numValue: undefined, jsonValue: undefined, listValue: [''], listLength: 1,
    } : {
      listValue: [''], listLength: 1, key: '', value: '', jsonValue: undefined, numValue: undefined, type: 'STRING',
    },
    onSubmit: ({ value }) => {
      onSubmitHandler(value);
    },
  });

  const valueString = useStore(form.store, (state) => state.values.value);
  const valueNum = useStore(form.store, (state) => state.values.numValue);
  const valueJson = useStore(form.store, (state) => state.values.jsonValue);
  const listLength = useStore(form.store, (state) => state.values.listLength);
  const type = useStore(form.store, (state) => state.values.type);

  const dynamicPropertiesToSelect = useMemo(() => {
    if (!expectedProperties?.length) return [];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const properties = valueString.matchAll(/<%= (\w+) %>/gm).toArray().map(([_, match]) => match);
    return expectedProperties.filter((prop) => !properties.includes(prop));
  }, [expectedProperties, valueString]);

  return (
    <Box>
      <form onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        form.handleSubmit();
      }}
      >
        <Grid2 container spacing={2} direction="column">
          <Grid2 container alignItems="center">
            <form.Field name="key" validatorAdapter={zodValidator()} validators={{ onChange: z.string().min(1) }}>
              {(field) => (
                <TextField
                  label="Nøkkel"
                  value={field.state.value ?? ''}
                  onChange={(e) => field.handleChange(e.target.value)}
                  disabled={lockKey}
                  sx={{ width: 400 }}
                  error={!!field.state.meta.errors.length}
                  helperText={field.state.meta.errors.join(', ')}
                  required
                />
              )}
            </form.Field>
            {!lockKey ? (
              <Tooltip title="For CRM SMS-maler må nøkkel begynne med 'CRM_SMS_MAL'">
                <Info color="info" />
              </Tooltip>
            ) : null}
          </Grid2>
          <Grid2>
            <form.Field name="type">
              {(field) => (
                <FormControl>
                  <InputLabel id="select_type_label" shrink sx={{ backgroundColor: 'white' }}>
                    Verditype *
                  </InputLabel>
                  <Select
                    id="select_type"
                    labelId="select_type_label"
                    label="Verditype"
                    value={field.state.value ?? 'STRING'}
                    onChange={(e) => field.handleChange(e.target.value as APIConstantTypeEnum)}
                    fullWidth
                    required
                  >
                    {valueTypes.map((v) => <MenuItem key={v.value} value={v.value}>{v.label}</MenuItem>)}
                  </Select>
                </FormControl>
              )}
            </form.Field>
          </Grid2>
          {type === 'STRING' ? (
            <form.Field
              name="value"
              validatorAdapter={zodValidator()}
              validators={{ onChange: z.string().min(1) }}
            >
              {(field) => (
                <>
                  <Grid2>
                    <TextField
                      label="Verdi"
                      value={field.state.value ?? ''}
                      onChange={(e) => field.handleChange(e.target.value)}
                      fullWidth
                      error={!!field.state.meta.errors.length}
                      helperText={field.state.meta.errors.join(', ')}
                      required
                      multiline
                      minRows={2}
                      maxRows={10}
                    />
                  </Grid2>
                  <Grid2>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      {dynamicPropertiesToSelect.length ? (
                        <FormControl sx={{ marginY: 2 }}>
                          <InputLabel id="select_constantdyn_label" shrink sx={{ backgroundColor: 'white' }}>
                            Velg navn på dynamisk verdi *
                          </InputLabel>
                          <Select
                            label="Velg navn på dynamisk verdi"
                            labelId="select_constantdyn_label"
                            value={selectedDynamicProp}
                            onChange={(e) => setSelectedDynamicProp(e.target.value)}
                            displayEmpty
                            sx={{ width: 200 }}
                            required
                          >
                            {dynamicPropertiesToSelect.map((val) => <MenuItem key={val} value={val}>{val}</MenuItem>)}
                          </Select>
                        </FormControl>
                      ) : (
                        <TextField
                          value={selectedDynamicProp}
                          onChange={(e) => setSelectedDynamicProp(e.target.value)}
                          label="Navn på dynamisk verdi"
                        />
                      )}
                      <Button
                        variant="contained"
                        onClick={() => {
                          field.handleChange((val) => val + getDynamicPropertyTemplate(selectedDynamicProp));
                          setSelectedDynamicProp('');
                        }}
                      >
                        Legg til dynamisk verdi
                      </Button>
                    </Stack>
                  </Grid2>
                </>
              )}
            </form.Field>
          ) : null}
          {type === 'STRINGARRAY' || type === 'NUMBERARRAY' ? (
            <>
              <Grid2 container direction="column" spacing={1}>
                <form.Field
                  name="listValue"
                  validators={{ onChange: (type === 'NUMBERARRAY' ? z.number().array() : z.string().array()) as z.ZodArray<z.ZodString | z.ZodNumber> }}
                >

                  {(field) => Array.from({ length: listLength }).map((_, i) => (
                    <Grid2 key={`value-${i + 1}`}>
                      <TextField
                        label={`Verdi ${i + 1}`}
                        value={field.state.value.at(i) ?? ''}
                        onChange={(e) => field.handleChange((val) => {
                          const arr = [...val];
                          if (arr.length >= i) {
                            arr[i] = e.target.value;
                          } else arr.push(e.target.value);
                          return arr;
                        })}
                        fullWidth
                        error={!!field.state.meta.errors.length}
                        helperText={field.state.meta.errors.join(', ')}
                        required
                        type={type === 'STRINGARRAY' ? 'text' : 'number'}
                      />
                    </Grid2>
                  ))}
                </form.Field>
              </Grid2>
              <Grid2>
                <form.Field name="listLength">
                  {(field) => (
                    <Stack direction="row" alignItems="center">
                      <Tooltip title="Legg til verdi">
                        <IconButton onClick={() => {
                          field.handleChange((val) => val + 1);
                          form.setFieldValue('listValue', (val) => [...val, '']);
                        }}
                        >
                          <Add />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Fjern verdi">
                        <IconButton
                          onClick={() => {
                            field.handleChange((val) => val - 1);
                            form.setFieldValue('listValue', (val) => val.slice(0, val.length - 1));
                          }}
                          disabled={field.state.value === 1}
                        >
                          <Remove />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  )}
                </form.Field>
              </Grid2>
            </>
          ) : null}
          {type === 'NUMBER' ? (
            <Grid2>
              <form.Field
                name="numValue"
                validatorAdapter={zodValidator()}
                validators={{
                  onChange: z.number(),
                }}
              >
                {(field) => (
                  <TextField
                    type="number"
                    label="Verdi (tall)"
                    value={field.state.value ?? ''}
                    onChange={(e) => field.handleChange(Number(e.target.value))}
                    disabled={!!valueJson}
                    error={!!field.state.meta.errors.length}
                    helperText={field.state.meta.errors.join(', ')}
                    required
                  />
                )}
              </form.Field>
            </Grid2>
          ) : null}
          {type === 'OBJECT' || type === 'OBJECTARRAY' ? (
            <Grid2>
              <form.Field
                name="jsonValue"
                validatorAdapter={zodValidator()}
                validators={{
                  onChange: ZodValidation.stringIsJSONSchema,
                }}
              >
                {(field) => (
                  <TextField
                    multiline
                    minRows={2}
                    maxRows={10}
                    label="Verdi (json)"
                    value={valueJson ?? ''}
                    onChange={(e) => field.handleChange(e.target.value)}
                    disabled={valueNum !== undefined}
                    fullWidth
                    error={!!field.state.meta.errors.length}
                    helperText={field.state.meta.errors.join(', ')}
                    required
                  />
                )}
              </form.Field>
            </Grid2>
          ) : null}
          <Grid2 container sx={{ justifyContent: 'flex-end' }}>
            <form.Subscribe selector={(state) => [state.canSubmit, state.isSubmitting]}>
              {([canSubmit, isSubmitting]) => (
                <LoadingButton
                  save
                  buttonProps={{
                    variant: 'contained',
                    type: 'submit',
                    loading: isSubmitting,
                    disabled: !canSubmit || isSubmitting,
                    color: 'success',
                  }}
                >
                  Lagre
                </LoadingButton>
              )}
            </form.Subscribe>
          </Grid2>

        </Grid2>
      </form>
    </Box>
  );
};

export default ConstantAddForm;
