import React, { useState } from 'react';
import {
  Tab, Typography, Box,
  Button,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useMutation } from '@tanstack/react-query';
import { PlayArrow } from '@mui/icons-material';
import { Link } from 'react-router';
import { useCPproUserContext } from '../../../contexts/CpproUserContext.js';
import MonitorPageTitle from '../../monitor/MonitorPageTitle.js';
import CreateServiceChecklistForm from '../../cppro/CreateServiceChecklistForm.js';
import type { CreateServiceOrderChecklistRequest } from '../../../../generated/activityhelper/api.js';
import LoadingButton from '../../common/buttons/LoadingButton.js';
import { ERPMutations } from '../../../mutations/index.js';
import CreateMamutOrderForm from '../../cppro/CreateMamutOrderForm.js';

const defaultServiceData: CreateServiceOrderChecklistRequest = {
  calendar: '',
  customer: {
    name: '',
    phone: '',
    address: '',
    zip: '',
    city: '',
    email: '',
  },
  dateAndClock: undefined,
  serviceText: '',
  slotLength: '',
  serviceOrders: [],
};

const MigrationHelpersPage = () => {
  const { user } = useCPproUserContext();

  const [tab, setTab] = useState<string>('0');

  const smsMutation = useMutation(ERPMutations.sendCalendarSMSReminderMutation);

  return (
    (
      <Box>
        <MonitorPageTitle title="Hjelpefunksjoner for Mamut -> 24SO migrering" />
        <TabContext value={tab}>
          <TabList onChange={(_, value) => setTab(value)}>
            <Tab label="Service" value="0" />
            <Tab label="SMS" value="1" />
            <Tab label="Mamut" value="2" />
            <Tab label="Kalender" value="3" />
          </TabList>
          <TabPanel value="0">
            <Box>
              <Typography gutterBottom variant="h6">Opprett service ordre:</Typography>
              <CreateServiceChecklistForm defaultData={{ ...defaultServiceData, advisorEmail: user?.email ?? '' }} />
            </Box>
          </TabPanel>
          <TabPanel value="1">
            <Box>
              <Typography gutterBottom variant="h6">Kjør SMS påminnelse for kalender-aktiviteter:</Typography>
              <LoadingButton
                customIcon={<PlayArrow />}
                buttonProps={{
                  onClick: () => smsMutation.mutateAsync({}),
                  variant: 'contained',
                  color: 'success',
                  loading: smsMutation.isPending,
                  disabled: smsMutation.isPending,
                }}
              >
                Send kalender SMS-påminnelser
              </LoadingButton>
            </Box>
          </TabPanel>
          <TabPanel value="2">
            <Box>
              <Typography gutterBottom variant="h6">Opprett Mamut ordre for eksisterene ordre:</Typography>
              <CreateMamutOrderForm />
            </Box>
          </TabPanel>
          <TabPanel value="3">
            <Box>
              <Typography gutterBottom variant="h6">Opprett kalender-overvåking:</Typography>
              <Button component={Link} to="/monitor/calendar" variant="contained" color="warning">Flyttet til &quot;Calendar&quot;</Button>
            </Box>
          </TabPanel>
        </TabContext>
      </Box>
    )
  );
};

export default MigrationHelpersPage;
