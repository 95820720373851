import { Box, Button, Typography } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useQueries, useQuery, useQueryClient } from '@tanstack/react-query';
import { ArrowBack } from '@mui/icons-material';
import { Link } from 'react-router';
import CRMAreaOpportunities from './CRMAreaOpportunities.js';
import { CRMQueries } from '../../../queries/index.js';
import type { GetAreaOpportunitiesResponse } from '../../../../generated/cppro/api.js';

// const defaultAreaOpportunitiesData = undefined as Required<DefaultApiApiCRMAreaOpportunitiesGetRequest> | undefined;

const CRMAreaOpportunitiesPage = () => {
  const [minScore, setMinScore] = React.useState<number | undefined>(undefined);
  const queryClient = useQueryClient();
  const { data: departmentZipAreas, isLoading: departmentZipAreasLoading } = useQuery(CRMQueries.departmentZipAreasQuery);

  const queries = useMemo(() => {
    if (!departmentZipAreas || minScore === undefined) return [];
    return departmentZipAreas.flatMap((area) => area.zips.map((zip) => CRMQueries.getAreaOpportunitiesQuery({
      fromZip: zip.toString(),
      toZip: zip.toString(),
      minScore: minScore.toString(),
      department: area,
    })).concat(area.zipAreas.map((zipArea) => CRMQueries.getAreaOpportunitiesQuery({
      fromZip: zipArea.from.toString(),
      toZip: zipArea.to.toString(),
      minScore: minScore.toString(),
      department: area,
    }))));
  }, [departmentZipAreas, minScore]);

  const queryData = useQueries({
    queries,
    combine: (qs) => {
      const isLoading = qs.some((q) => q.isLoading || q.isFetching || q.isPending || q.isRefetching);
      if (isLoading) return { isLoading, data: [] };
      const map = new Map<string, { department: string, areaOpportunities: GetAreaOpportunitiesResponse }>();

      for (const q of qs) {
        if (q.data) {
          const item = map.get(q.data.department.department);
          if (item) {
            item.areaOpportunities.totalCount += q.data.response.totalCount;
            item.areaOpportunities.zipScores.push(...q.data.response.zipScores);
          } else {
            map.set(q.data.department.department, { department: q.data.department.department, areaOpportunities: structuredClone(q.data.response) });
          }
        }
      }

      return {
        isLoading,
        data: map.values().toArray(),
      };
    },
  });

  const { isLoading, data } = queryData;

  const onSearch = useCallback((search: { minScore: number } | undefined) => {
    setMinScore(search?.minScore);
  }, []);

  const refetch = useCallback(async () => {
    await queryClient.invalidateQueries(CRMQueries.departmentZipAreasQuery);
    queryClient.refetchQueries({ queryKey: ['areaOpportunities'] });
  }, [queryClient]);

  return (
    <Box>
      <Button
        component={Link}
        to="/crm"
        startIcon={<ArrowBack />}
      >
        Tilbake
      </Button>
      <Typography variant="h5" gutterBottom>Area opportunities</Typography>
      <CRMAreaOpportunities
        data={data}
        search={onSearch}
        refetch={refetch}
        isLoading={isLoading || departmentZipAreasLoading}
      />
    </Box>
  );
};

export default CRMAreaOpportunitiesPage;
