import { CssBaseline, Box } from '@mui/material';

import React, { useState } from 'react';
import { Outlet } from 'react-router';
import MonitorHeader from './MonitorHeader.js';
import MonitorSidebar, { DrawerHeader } from './MonitorSidebar.js';

// TODO Make the layout "generic" to support other headers/sidebars
// Or make the sidebar accept some state for the routes+icons

const MonitorLayout = () => {
  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    (
      <Box sx={{
        display: 'flex',
      }}
      >
        <CssBaseline />
        <MonitorHeader open={open} handleOpen={handleDrawerOpen} />
        <MonitorSidebar open={open} handleClose={handleDrawerClose} />
        <Box
          component="main"
          sx={{
            // TODO Fix this, p: 3 should work, see https://mui.com/material-ui/react-drawer/#mini-variant-drawer
            flexGrow: 1,
            paddingRight: 3,
            paddingBottom: 3,
            paddingTop: 3,
          }}
        >
          <DrawerHeader />
          <Outlet />
        </Box>
      </Box>
    )
  );
};

export default MonitorLayout;
