import React from 'react';
import { Box, Button, Grid2 } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { Sms } from '@mui/icons-material';
import type { GenericTableFilterProps } from '../../../table/GenericTable.js';
import useCRMStore from '../../../../stores/crmStore.js';
import type { CallListItem } from '../../../../types/crmTypes.js';

type CallListTableFiltersProps = GenericTableFilterProps<CallListItem>;

const CallListTableFilters = (props: CallListTableFiltersProps) => {
  const { table } = props;
  const { customerId } = useParams();
  const navigate = useNavigate();
  const rowSelection = table.getSelectedRowModel();
  const someSelected = rowSelection.rows.length > 0;

  const setCallListSelectedItems = useCRMStore((state) => state.setCallListSelectedItems);
  const setRouteCustomer = useCRMStore((state) => state.setRouteCustomer); // TODO Move to persisted store?

  const onSMSClick = () => {
    if (customerId && !Number.isNaN(Number(customerId))) {
      setRouteCustomer(customerId);
    }
    setCallListSelectedItems(rowSelection.rows.map((row) => row.original));
    navigate('/crm/sms');
  };

  if (!someSelected) return null;

  return (
    <Box sx={{ padding: 1 }}>
      <Grid2 container alignItems="center" spacing={1}>
        <Grid2>
          <Button size="small" onClick={onSMSClick} startIcon={<Sms />}>
            Send SMS til valgte
            {` (${rowSelection.rows.length})`}
          </Button>
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default CallListTableFilters;
